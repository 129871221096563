import React, { FC, memo, useState, useCallback } from 'react';
import styled from 'styled-components';
import logger from 'src/helpers/logger';
import { Upload as AntUpload, UploadProps } from 'antd';
import type { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import photoIcon from 'static/images/photo.svg';
import Spinner from '../spinner';
import Dragger from './dragger';

//
const Upload = styled(AntUpload)`
  .ant-upload {
    .anticon {
      font-size: 18px;
      border-radius: 0.25rem;
      display: inline-flex;
    }
    .ant-btn-icon-only {
      border-radius: 0.25rem;
    }

    .anticon + .ant-upload-text {
      margin-left: 8px;
    }
  }
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    border-radius: 0.25rem;
    border-color: #dee2e6;
    background-color: #fbfbfb;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info {
    border-radius: 0.125rem;
  }

  .ant-upload-list-item-image {
    border-radius: 0.125rem;
  }

  .ant-upload-list-item-card-actions {
    right: 5px;
  }

  // custom style
  &.upload-list-inline .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    > div {
      flex: 0 0 50%;
      min-width: 240px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  &.upload-image {
    display: flex;
    flex-direction: column;
    max-width: 420px;

    .ant-upload-select {
      position: relative;
      border-radius: 0.625rem;
      border: 1px dashed #d9d9d9;
      background-color: #fbfbfb;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      float: none;
      margin: 0;
      width: auto;
      height: auto;
      transition: border-color 0.3s;

      > .ant-upload {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;
      }

      &:before {
        display: block;
        content: '';
        padding-top: 42%;
      }

      .ant-upload[role='button'] {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        > span,
        > div {
          position: relative;
        }
      }

      .anticon {
        font-size: 28px;
        color: #6a75ca;
      }

      .ant-upload-text {
        font-size: 16px;
      }
    }

    .ant-upload-title {
      color: #000000;
    }

    .ant-upload-desc {
      color: rgba(0, 0, 0, 0.4);
    }

    .ant-upload-list-item-list-type {
      &-picture-card {
        &.ant-upload-list-item {
          padding: 0;
          margin-bottom: 20px;
          border-radius: 20px;
          border-width: 0;

          &:before {
            display: block;
            content: '';
            padding-top: 50%;
          }
        }

        .ant-upload-list-item-info {
          border-radius: 10px;
          overflow: hidden;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          &:before {
            bottom: 50px;
          }
        }
      }

      &-text .ant-upload-list-item-info {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        height: 50px;
        padding: 0;
      }
    }

    .ant-upload-list {
      margin-top: 0.7rem;
    }

    .ant-upload-list-picture-card-container,
    .ant-upload-list-item {
      float: none;
      width: 100%;
      height: auto;
      margin: 0.1rem 0;
      padding: 0;
      border-radius: 10px;
      overflow: hidden;

      > .ant-upload-list-item-actions,
      .ant-upload-list-item-card-actions {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 15px;
        top: auto;
        left: auto;
        right: 0;
        bottom: 0;
        border-bottom-right-radius: 20px;
        transform: translate(0, 0);
        position: absolute;

        .ant-btn,
        .ant-btn > .ant-btn-icon,
        .ant-btn > .ant-btn-icon > .anticon {
          color: #fff;
          opacity: 0.7;
          font-size: 17px;

          &:hover {
            opacity: 1;
          }
        }

        a {
          display: none;
        }
      }

      > .ant-upload-icon {
        display: none;
      }

      .ant-upload-list-item-card-actions {
        position: absolute;
      }

      > .ant-upload-list-item-name {
        width: 100%;
        display: block;
        font-weight: bold;
        color: #ffffff;
        font-size: 16px;
        text-align: left;
        padding: 0 60px 0 40px;
        height: 50px;
        line-height: 50px;
        background: rgba(0, 0, 0, 0.9) url(${photoIcon}) no-repeat 15px center;
      }
    }

    &_minimal {
      .ant-upload-list-item-card-actions-btn {
        opacity: 0.4;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
`;

export { Dragger };

export type FileUploaderList = UploadFile[];

export type FileUploaderProps = Omit<UploadProps, 'onChange' | 'onRemove'> & {
  uploadFileModalStatus?: boolean;
  openUploadModal?: () => void;
  closeUploadModal?: () => void;
  beforeUpload?: (_fileList: UploadFile[]) => unknown;
  onChange: (_fileList: UploadFile[]) => void;
  onRemove: (_fileList: UploadFile[]) => void;
  multiple: boolean;
  disabled?: boolean;
  showPopup?: boolean;
  children: React.ReactElement;
};

export const FileUploader: FC<FileUploaderProps> = p => {
  const {
    onRemove: outerOnRemoveFn,
    beforeUpload: outerOnBeforeUpload,
    onChange: outerOnChange,
    // uploadFileModalStatus,
    // closeUploadModal,
    // openUploadModal,
    children,
    // renderSubmitButton,
    multiple = false,
    showPopup = false,
    disabled = false,
    ...props
  } = p;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const onChange = useCallback((data: UploadChangeParam<UploadFile>) => {
    const { file, fileList: list, event } = data;
    logger('FileUploader.onChange', { file, list, event });
    // if (typeof props.onChange === 'function') {
    //   props.onChange(data);
    // }
  }, []);
  // const previewFile = useCallback(async file => {
  //   logger('FileUploader.previewFile', { file });
  //   //
  //   return getBase64FromFile(file);
  // });
  const onRemove = useCallback(
    (file: UploadFile) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      logger('FileUploader.onRemove', { file, index, newFileList });
      newFileList.splice(index, 1);
      if (typeof outerOnRemoveFn === 'function') {
        outerOnRemoveFn(newFileList);
      }
      setFileList(newFileList);
    },
    [fileList, outerOnRemoveFn],
  );
  const beforeUpload = useCallback(
    (file: UploadFile, list: FileList) => {
      const newFileList = [...fileList];
      logger('FileUploader.beforeUpload', { file, list, fileList });
      //
      if (Array.isArray(list) && list.length > 0) {
        Promise.all(
          list
            .filter(item => !fileList.some(f => f.uid === item.uid))
            .map(async item => {
              setLoading(true);
              //
              // if (!item.url) {
              //   // eslint-disable-next-line no-param-reassign
              //   item.url = await previewFile(item);
              // }
              //
              newFileList.push(item);
            }),
        )
          .then(() => {
            if (typeof outerOnBeforeUpload === 'function') {
              outerOnBeforeUpload(newFileList);
            }
            setFileList(newFileList);
            //
            if (typeof outerOnChange === 'function') {
              outerOnChange(newFileList);
            }
          })
          .catch(logger)
          .then(() => setLoading(false));
      }

      return false;
    },
    [fileList, outerOnChange, outerOnBeforeUpload],
  );
  //
  return (
    <Spinner spinning={loading}>
      <Upload
        {...props}
        multiple={multiple}
        supportServerRender
        fileList={fileList}
        beforeUpload={beforeUpload as unknown as UploadProps['beforeUpload']}
        onRemove={onRemove}
        onChange={onChange}>
        {children}
      </Upload>
    </Spinner>
  );
};

export default memo(FileUploader);
