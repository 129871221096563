/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { memo, useMemo, useEffect, useState, useRef, useContext, useCallback } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import omit from 'lodash/omit';
import * as Types from '../types/main';

import { SettingsContext, TemplateContext } from '../context';

import Clock from '../widgets/clock/index';
import DateTime from '../widgets/datetime';
import YouTubePlayer from '../widgets/youtube';
import Weather from '../widgets/weather';
import Events from '../widgets/events';
import FlightsWidget from '../widgets/flights';
//
import useInterval from '../hooks/use-interval';

type MediaItemComponentProps = {
  data?: Types.MediaItem;
  style?: { [key: string]: string };
  loopVideo?: boolean;
  onPlaybackEnd?: () => void;
  getMediaFileFromLocalDB?: (id: string) => Types.MediaFileType;
};

const DEFAULT_VIDEO_EXTENSION = 'mp4';

export const MediaItemComponent: React.FC<MediaItemComponentProps> = props => {
  const settings = useContext(SettingsContext);
  const template = useContext(TemplateContext);
  const device = null;
  const fullscreen = !template;
  const { orientation, invertVideoRotation } = settings || {};
  const { data, style, onPlaybackEnd, loopVideo, getMediaFileFromLocalDB } = props;
  const isDownloadableFile = ['video', 'image'].includes(data?.type || '');
  const containerRef = useRef();
  const [mediaFile, setMediaFile] = useState<Types.MediaFileType | null>(null);

  useEffect(() => {
    setMediaFile(null);
    if (typeof getMediaFileFromLocalDB === 'function' && isDownloadableFile && data?.id) {
      setMediaFile(getMediaFileFromLocalDB(data.id));
    }
  }, [data?.id]);

  // Check each 0.5 seconds if file has been downloaded (if during previous render it's not)
  useInterval(
    async () => {
      if (typeof getMediaFileFromLocalDB === 'function' && isDownloadableFile && data?.id) {
        setMediaFile(getMediaFileFromLocalDB(data.id));
      }
    },
    // Delay in milliseconds or null to stop it
    !mediaFile ? 500 : null,
  );

  const { type, id, title } = data || {};
  const { deviceType } = settings || {};

  useEffect(() => {
    if (deviceType === 'arm') {
      device.socket.on('video-ended', () => {
        if (!loopVideo && typeof onPlaybackEnd === 'function') {
          onPlaybackEnd();
        }
      });
      return () => {
        device.socket.off('video-ended');
      };
    }
    return () => {};
  }, [loopVideo, onPlaybackEnd]);

  useEffect(() => {
    if (!device) {
      return;
    }
    device.player.stopVideo().catch(console.error);
  }, [data?.id, typeof device?.player?.stopVideo]);

  const component = useMemo(() => {
    if (!data || !settings) {
      return null;
    }
    if (isDownloadableFile) {
      // If we failed to get media-file from local-db
      // or if media-file doesn't match current mediaItem's id
      // we should return null instead of media-item's content
      if (!mediaFile || mediaFile.id !== id) {
        return null;
      }
    }
    const { url } = mediaFile || data;
    const { extension = DEFAULT_VIDEO_EXTENSION } = mediaFile || {};

    switch (type) {
      case Types.MediaItemType.Video: {
        if (deviceType === 'arm' || deviceType === 'tizen') {
          device.player
            .playVideo({
              url,
              orientation,
              invertVideoRotation,
              args: {
                nativeLoop: loopVideo,
              },
              style: style || {},
              parentElement: containerRef.current,
            })
            // This promise will be resolved with some result
            // only on Tizen/WebOS/Chrome devices
            .then((res: any) => {
              if (res === 'ended') {
                if (!loopVideo && typeof onPlaybackEnd === 'function') {
                  onPlaybackEnd();
                }
              }
              if (res === 'error') {
                setTimeout(() => {
                  if (typeof onPlaybackEnd === 'function') {
                    onPlaybackEnd();
                  }
                }, 20000);
              }
            })
            .catch(console.error);
          //
          return null;
        }
        return (
          <video
            id={id}
            autoPlay
            onEnded={onPlaybackEnd}
            loop={loopVideo}
            src={url || ''}
            // @ts-ignore
            type={`video/${extension}`}
            className={`${deviceType}-${fullscreen ? 'fullscreen' : 'no-fullscreen'}-${settings.orientation}`}
          />
        );
      }
      case Types.MediaItemType.Icon:
      case Types.MediaItemType.Logo:
      case Types.MediaItemType.Image: {
        return (
          <img
            id={id}
            src={url || ''}
            alt={title || ''}
            className={`${deviceType}-${fullscreen ? 'fullscreen' : ''}-${settings.orientation}`}
            style={
              deviceType === 'arm' && (settings.orientation === 90 || settings.orientation === 270)
                ? { objectFit: 'unset', maxWidth: '100%' }
                : {}
            }
          />
        );
      }
      case Types.MediaItemType.Iframe: {
        return <iframe id={id} scrolling="no" src={url || ''} />;
      }
      case Types.MediaItemType.Hls:
      case Types.MediaItemType.Multicast:
        if (deviceType === 'arm' || deviceType === 'tizen') {
          device.player
            .playVideo({
              url,
              orientation,
              args: {},
              style: style || {},
              type,
              parentElement: containerRef.current,
            })
            .then((res: any) => {
              if (res === 'ended') {
                const videoElement = document.querySelector('video')!;
                if (!loopVideo) {
                  videoElement.dispatchEvent(new Event(res));
                }
              }
              if (res === 'error') {
                const videoElement = document.querySelector('video')!;
                setTimeout(() => {
                  videoElement.dispatchEvent(new Event('ended'));
                }, 20000);
              }
            })
            .catch(console.error);
          return null;
        }
        return (
          <div id={id} style={fullscreen ? {} : { width: 'inherit', height: 'inherit', objectFit: 'fill' }}>
            <video muted autoPlay loop={loopVideo} src={url || ''} />
          </div>
        );

      case Types.MediaItemType.Youtube: {
        return (
          <div style={{ objectFit: 'contain' }}>
            <YouTubePlayer item={data} style={style} />
          </div>
        );
      }
      case Types.MediaItemType.Datetime: {
        return <DateTime item={data} />;
      }
      case Types.MediaItemType.Clock: {
        return (
          <div style={{ objectFit: 'contain' }}>
            <Clock item={data} style={style} />
          </div>
        );
      }
      case Types.MediaItemType.Weather: {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Weather item={data} />
          </div>
        );
      }
      case Types.MediaItemType.LocationWayfinding:
      case Types.MediaItemType.LocationEvents:
      case Types.MediaItemType.LocationHeader:
      case Types.MediaItemType.LocationEventLogo: {
        return <Events data={data} />;
      }
      case Types.MediaItemType.Flights:
        return <FlightsWidget data={data} />;
      default:
        return <div>Unknown media-item type</div>;
    }
  }, [type, data, mediaFile, isDownloadableFile, onPlaybackEnd]);

  const handleItemTransition = useCallback((node: any, done: any) => {
    node.addEventListener('transitionend', done, false);
  }, []);

  const blacklistStyle = ['backgroundColor'];
  const filteredStyle = omit(style, blacklistStyle);

  const innerContent = (
    <div
      id={`container-${id}`}
      style={filteredStyle}
      className={`${type}-${orientation} wrap-${type}${
        fullscreen ? '-fullscreen' : ''
      } ${deviceType} main-item-container`}>
      {component}
    </div>
  );
  // We don't need rotation animation if previous/next mediaItem has 'video' type
  if (type === Types.MediaItemType.Video || !fullscreen) {
    return innerContent;
  }

  return (
    <SwitchTransition mode="in-out">
      <CSSTransition key={props.data?.id} addEndListener={handleItemTransition} classNames="fade">
        {innerContent}
      </CSSTransition>
    </SwitchTransition>
  );
};
MediaItemComponent.displayName = 'MediaItemComponent';

export default memo(MediaItemComponent) as typeof MediaItemComponent;
