import React, { FC, memo, Fragment, useCallback, useState } from 'react';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
// GraphQL Queries
import { useMutation, updateAfterMutation, useApolloError, logger } from '@fjedi/graphql-react-components';
import getUserRolesQuery from 'src/graphql/queries/get-user-roles.graphql';
import createUserRoleMutation from 'src/graphql/mutations/create-user-role.graphql';
import updateUserRoleMutation from 'src/graphql/mutations/update-user-role.graphql';
import removeUserRoleMutation from 'src/graphql/mutations/remove-user-role.graphql';
//
import userRoleCreatedSubscription from 'src/graphql/subscriptions/user-role-created.graphql';
import userRoleChangedSubscription from 'src/graphql/subscriptions/user-role-changed.graphql';
import userRoleRemovedSubscription from 'src/graphql/subscriptions/user-role-removed.graphql';
//
import { Divider } from 'antd';
import Table from 'src/components/ui-kit/table';
import RoleSelector from 'src/components/common/role-selector';
import { ProjectSelector } from 'src/components/common/project-selector';
import CompanySelector from 'src/components/common/company-selector';
import { Form, FormItem as FormItemCustom } from 'src/components/ui-kit/form';
import Button from 'src/components/ui-kit/buttons';
import { Company, Project, UserRole, UserRoleRole, Viewer } from 'src/graphql/generated';

const Container = styled.div``;

const FormItem = styled(FormItemCustom)`
  flex-direction: column;
  &.ant-form-item {
    flex: 1 0 auto;
  }
  &.form-item-btn {
    flex: none;
    padding-top: 2.125rem;
  }
  .ant-form-item-label {
    text-align: left;
  }
`;

const UserRoles: FC<{ userId: string }> = memo(({ userId }) => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const [form] = Form.useForm();
  const { resetFields, setFieldsValue } = form;
  //
  const onCompleted = useCallback(() => {
    resetFields();
  }, [resetFields]);
  const [selectedCompany, setSelectedCompany] = useState<Viewer['primaryCompanyId']>();
  const [updateUserRole] = useMutation(updateUserRoleMutation, { onError });
  //
  const [createUserRole, { loading: addingUser }] = useMutation(createUserRoleMutation, {
    onCompleted,
    onError,
    update: updateAfterMutation('UserRole', 'getUserRoles'),
  });
  const onSubmit = useCallback(
    (input: any) => {
      logger('UserRoles.createUserRole', {
        input,
      });
      //
      createUserRole({
        variables: {
          input: { ...input, userId },
        },
      }).catch(logger);
    },
    [createUserRole, userId],
  );
  const onValuesChange = useCallback(
    (changedValues: any) => {
      //
      logger('UserRoles.onValuesChange', {
        changedValues,
      });
      if (changedValues.companyId) {
        setSelectedCompany(changedValues.companyId);
        if (selectedCompany) {
          setFieldsValue({ projectId: null });
        }
      }
    },
    [setFieldsValue, setSelectedCompany, selectedCompany],
  );
  const onChangeField = useCallback(
    (id: string, input: Partial<UserRole>) => {
      //
      logger('UserRoles.onChangeField', {
        input,
      });
      if (Object.keys(input).length > 0) {
        updateUserRole({
          variables: { id, input },
        }).catch(logger);
      }
    },
    [updateUserRole],
  );

  return (
    <Fragment>
      <Container>
        <Divider>{t('Roles')}</Divider>
        <Form
          form={form}
          layout="inline"
          onFinish={onSubmit}
          onValuesChange={onValuesChange}
          initialValues={{
            role: 'USER',
          }}>
          <FormItem
            name="companyId"
            label={t('Company')}
            rules={[
              {
                required: true,
                message: t('Please fill this field'),
              },
            ]}>
            <CompanySelector />
          </FormItem>
          <FormItem name="projectId" label={t('Project')} dependencies={['companyId']}>
            <ProjectSelector disabled={!selectedCompany} companyId={selectedCompany} />
          </FormItem>
          <FormItem
            name="role"
            label={t('Role')}
            rules={[
              {
                required: true,
                message: t('Please fill this field'),
              },
            ]}>
            <RoleSelector />
          </FormItem>
          <FormItem className="form-item-btn">
            <Button style={{ marginTop: 0 }} type="primary" htmlType="submit" loading={addingUser}>
              {t('Add')}
            </Button>
          </FormItem>
        </Form>
      </Container>
      <Container style={{ marginTop: '1rem' }}>
        <Table
          pagination
          pageSize={5}
          dataType="UserRole"
          query={getUserRolesQuery}
          subscriptionQueries={[userRoleCreatedSubscription, userRoleChangedSubscription, userRoleRemovedSubscription]}
          removeRowMutation={removeUserRoleMutation}
          removalConfirmationMessage={`${t('Remove')}?`}
          variables={{ filter: { userId } }}
          rowKey="id"
          columns={[
            {
              title: t('Company'),
              dataIndex: 'company',
              width: 180,
              render(company: Company) {
                return company?.name || '';
              },
            },
            {
              title: t('Project'),
              dataIndex: 'project',
              width: 180,
              render(project: Project) {
                return project?.name || t('All');
              },
            },
            {
              title: t('Role'),
              dataIndex: 'role',
              width: 200,
              onChangeField,
              editable(record: UserRole) {
                return record?.role !== 'OWNER';
              },
              inputAlwaysVisible: true,
              input: RoleSelector,
              inputProps(record: UserRole) {
                return {
                  initialValue: record?.role,
                };
              },
              render(role: UserRoleRole) {
                return t(capitalize(role));
              },
            },
            // {
            //   title: t('Status'),
            //   dataIndex: 'status',
            //   width: 180,
            //   render(status) {
            //     return t(capitalize(status));
            //   },
            // },
            // {
            //   title: t('Date'),
            //   dataIndex: 'createdAt',
            //   sorterKey: 'createdAt',
            //   width: 180,
            //   sorter: (a, b) => a - b,
            //   render(createdAt) {
            //     return formatDate(createdAt, 'DD.MM.YYYY HH:mm');
            //   },
            // },
          ]}
        />
      </Container>
    </Fragment>
  );
});

UserRoles.displayName = 'UserRoles';

export default UserRoles;
