import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { API_URL } from 'src/constants';
//
import guesttekLogo from './guesttek-logo.svg';
import smarteqLogo from './smarteq-logo.svg';
import hotezaLogo from './hoteza-logo.svg';

const Brand = styled.div`
  .hoteza {
    width: 15.625rem;
  }
`;

const Image = styled.img`
  display: block;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

export const Logo = memo(() => {
  const { logo, brand } = useMemo(() => {
    switch (new URL(API_URL).hostname) {
      case 'st-unstable.fjedi.com':
        return {
          logo: smarteqLogo,
          brand: 'smarteq',
        };
      case 'hotsign.hoteza.com':
      case 'hotsign.hoteza.ru':
        return {
          logo: hotezaLogo,
          brand: 'hoteza',
        };
      case 'ovds.gtkcentral.net':
      case 'st.fjedi.com':
      case 'localhost':
        return {
          logo: guesttekLogo,
          brand: 'guesttek',
        };
      default:
        return {
          logo: smarteqLogo,
          brand: 'smarteq',
        };
    }
  }, [API_URL]);
  //
  return (
    <Brand className="brand">
      <Image className={brand} alt="" src={logo} />
    </Brand>
  );
});

export default Logo;
