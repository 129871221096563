import React, { memo, useContext } from 'react';
import styled from 'styled-components';
import image from 'src/components/ui-kit/layout/select_house.svg';
import { ViewerContext } from '@fjedi/react-router-helpers';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

const Title = styled.div`
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0;
  line-height: 1.2;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 1.125rem;
  color: rgba(0, 0, 0, 0.2);
  line-height: 1.2;
  text-align: center;
  max-width: 38.75rem;
  margin: 10px auto 2.875rem;
`;

const Image = styled.img`
  width: 18.125rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

export const NoAccess = memo(() => {
  const viewer = useContext(ViewerContext);
  const { t } = useTranslation();
  return (
    <>
      {/* eslint-disable-next-line prettier/prettier,react/jsx-one-expression-per-line */}
      <Title>{t('Welcome, {{fullName}}!', { fullName: get(viewer, 'fullName', '') })}</Title>
      <Title>{t('Please select your project by clicking the dropdown menu at the top.')}</Title>
      <SubTitle>{t('If no projects are accessible, please contact the administrator to link your profile.')}</SubTitle>
      <Image alt="" src={image} />
    </>
  );
});

export default NoAccess;
