import React, { memo, useMemo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  RiDeleteBinLine,
  RiSettings5Line,
  RiImageLine,
  RiFilmLine,
  RiLinksLine,
  RiCalendarEventLine,
  RiNavigationLine,
  RiPlayList2Line,
  RiMapPinLine,
} from 'react-icons/ri';
import { AiOutlineMedium, AiOutlineYoutube } from 'react-icons/ai';
import { MdLiveTv, MdFlight } from 'react-icons/md';
import { TiWeatherPartlySunny } from 'react-icons/ti';
import { WiTime4 } from 'react-icons/wi';

import { IDType } from 'src/store/prop-types';
//
import Tooltip from 'src/components/ui-kit/tooltip';
import { colorTheme } from 'src/components/ui-kit/theme';
import { DateTimeWidgetIcon, HLSWidgetIcon, LogoWidgetIcon } from 'src/components/ui-kit/icons';
import Button from 'src/components/ui-kit/buttons';
import Popconfirm from 'src/components/ui-kit/popconfirm';

import { stopEventPropagation } from 'src/functions';
import { EditorContext } from './context';
import { TEMPLATE_TYPES } from './constants';

export const TemplateAreaListItemType = PropTypes.shape({
  id: IDType.isRequired,
});

const Item = styled.div`
  padding: 1rem 2rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  cursor: move;
  transition: border-color 0.3s;
  user-select: none;

  &:hover {
    border-color: #eee;
  }

  &.selected {
    border-color: ${colorTheme.primary};
  }

  &.selected ~ &:hover {
    border-top-color: transparent;
    border-bottom-color: transparent;
  }
`;

const ItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;

  svg {
    position: relative;
    top: -0.0625rem;
    font-size: 1.25rem;
    color: rgba(0, 0, 0, 0.2);
  }
`;

export const TextHidden = styled.div`
  overflow: hidden;
  flex-grow: 1;
  padding-right: 0.25rem;
`;

const ItemTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  flex: 1 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemAction = styled.div`
  margin-right: -0.3125rem;
  display: flex;

  .ant-btn {
    background-color: transparent;
    box-shadow: none;
    border-width: 0;
    color: rgba(0, 0, 0, 0.2);
    padding: 5px 5px;
    height: auto;

    svg {
      position: static;
      font-size: 1.25rem;
    }

    &:hover,
    &:focus {
      color: #6a75ca;
      background-color: transparent;
    }
  }
`;

const TemplateAreaListItem = memo(({ className, style, data, onSettingsOpen, onRemove }) => {
  const { t } = useTranslation();
  const { selectedArea, setSelectedArea } = useContext(EditorContext);
  const { id, title, item, deletedAt, playlist, playlistId } = data;
  const Icon = useMemo(() => {
    if (playlist && playlistId) return RiPlayList2Line;

    switch (item?.type) {
      case TEMPLATE_TYPES.IMAGE:
        return RiImageLine;
      case TEMPLATE_TYPES.VIDEO:
        return RiFilmLine;
      case TEMPLATE_TYPES.IFRAME:
        return RiLinksLine;
      case TEMPLATE_TYPES.CLOCK:
        return WiTime4;
      case TEMPLATE_TYPES.DATETIME:
        return DateTimeWidgetIcon;
      case TEMPLATE_TYPES.WEATHER:
        return TiWeatherPartlySunny;
      case TEMPLATE_TYPES.YOUTUBE:
        return AiOutlineYoutube;
      case TEMPLATE_TYPES.HLS:
        return HLSWidgetIcon;
      case TEMPLATE_TYPES.MULTICAST:
        return MdLiveTv;
      case TEMPLATE_TYPES.LOCATION_EVENT_LOGO:
        return LogoWidgetIcon;
      case TEMPLATE_TYPES.LOCATION_HEADER:
        return RiMapPinLine;
      case TEMPLATE_TYPES.LOCATION_EVENTS:
        return RiCalendarEventLine;
      case TEMPLATE_TYPES.LOCATION_WAYFINDING:
        return RiNavigationLine;
      case TEMPLATE_TYPES.FLIGHTS:
        return MdFlight;
      case TEMPLATE_TYPES.TEXT:
      default:
        return AiOutlineMedium;
    }
  }, [item?.type, playlist, playlistId]);

  const onClick = useCallback(() => {
    setSelectedArea(id);
  }, [id, setSelectedArea]);
  //
  return (
    <Item
      style={style}
      className={classNames(className, {
        selected: selectedArea === id,
      })}
      onClick={onClick}>
      <ItemIcon>
        <Icon size="1.25rem" />
      </ItemIcon>
      <TextHidden>
        <ItemTitle style={{ textDecoration: deletedAt ? 'line-through' : 'none' }}>{title}</ItemTitle>
      </TextHidden>
      <ItemAction>
        <Tooltip title={t('Item settings')} align={{ offset: [0, 5] }}>
          <Button>
            <RiSettings5Line onClick={onSettingsOpen} />
          </Button>
        </Tooltip>
        <Popconfirm
          placement="topRight"
          title={`${t('Remove')}?`}
          onClick={stopEventPropagation}
          onConfirm={onRemove}
          okText={t('Yes')}
          cancelText={t('No')}>
          <Tooltip title={t('Remove')} placement="bottom" align={{ offset: [0, -5] }}>
            <Button>
              <RiDeleteBinLine />
            </Button>
          </Tooltip>
        </Popconfirm>
      </ItemAction>
    </Item>
  );
});
TemplateAreaListItem.propTypes = {
  data: TemplateAreaListItemType.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onSettingsOpen: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
TemplateAreaListItem.defaultProps = { className: '', style: undefined };
TemplateAreaListItem.displayName = 'TemplateAreaListItem';

export default TemplateAreaListItem;
