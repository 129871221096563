import React, { FC } from 'react';
import styled from 'styled-components';
import { TimeslotType } from 'src/graphql/generated';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  margin-bottom: 10px;
`;

const TypeTag = styled.div`
  background-color: #d2d5eb;
  color: #6773be;
  border: 1px solid #6773be;
  padding: 3px 10px;
  border-radius: 30px;
`;

type ScheduleInfoPlateProps = {
  name?: string | null;
  timeslotType?: TimeslotType;
  defaultTimeslot?: boolean;
};

const typeText = (type?: TimeslotType, defaultTimeslot?: boolean) => {
  switch (type) {
    case TimeslotType.Day:
      return 'Day';
    case TimeslotType.Date:
      return 'Date';
    case TimeslotType.Events:
      return 'Events';
    case TimeslotType.Weekday:
      return 'Weekday';
    default:
      return defaultTimeslot ? 'Default' : 'No content';
  }
};

const ScheduleInfoPlate: FC<ScheduleInfoPlateProps> = ({ name = '', timeslotType, defaultTimeslot }) => {
  return (
    <Wrapper>
      <div>
        Schedule:
        {name}
      </div>
      <TypeTag>{typeText(timeslotType, defaultTimeslot)}</TypeTag>
    </Wrapper>
  );
};

export default ScheduleInfoPlate;
