/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { pure, compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Form /* message */ } from 'antd';
import {
  RiImageLine,
  RiImageAddLine,
  RiFilmLine,
  RiLayoutMasonryLine,
  RiFilterLine,
  RiSearchLine,
  RiFolderAddLine,
  RiPlayList2Line,
  RiPlayListAddLine,
  RiUpload2Line,
} from 'react-icons/ri';
import { Tabs, TabPane } from 'src/components/ui-kit/tabs';

import { RadioButton, RadioGroup } from 'src/components/ui-kit/radio';
import { Input } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';
import ButtonModalPopupComponent from 'src/components/ui-kit/modal-popup/button-modal';
import FileUploader from 'src/components/ui-kit/file-uploader';
import ScrollbarCustom from 'src/components/ui-kit/scrollbar';

export const AsideWrapper = styled.div`
  background-color: #ffffff;
  width: 20rem;
  height: 100%;
  position: relative;
  border-radius: 0.625rem;
  padding-bottom: 3.25rem;
  box-shadow: 0 0.125rem 0.875rem rgba(0, 0, 0, 0.08);

  display: flex;
  flex-direction: column;

  .ant-tabs {
    flex-grow: 1;
    overflow: hidden;
    flex-direction: column;

    .ant-tabs-content-holder {
      flex-grow: 1;
      overflow: hidden;
    }

    .ant-tabs-content {
      height: 100%;
    }

    .ant-tabs-tabpane {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .ant-collapse {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .ant-spin-nested-loading + .ant-spin-nested-loading {
    margin-top: 0.625rem;
  }
`;

export const Header = styled.div`
  padding: 1.25rem 1.875rem 0.625rem;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.2;
`;

export const SubTitle = styled.div`
  font-size: 0.875rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5rem;
`;

const SwitchGroup = styled(RadioGroup)`
  &.ant-radio-group {
    display: flex;
  }
`;

const SwitchButton = styled(RadioButton)`
  &.ant-radio-button-wrapper {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
`;

export const Search = styled(Input)`
  &.ant-input-affix-wrapper {
    height: 2.25rem;
    min-height: 2.25rem;
    width: auto;
    display: flex;
    margin: 0 1.875rem 1.25rem;
  }
`;

export const Divider = styled.div`
  height: 0.5rem;
  min-height: 0.5rem;
  margin-top: -0.5rem;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    top: -0.5rem;
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  box-shadow: 0px -0.125rem 1.5rem rgba(0, 0, 0, 0.08);

  .ant-btn {
    height: 3.125rem;
    font-size: 0.875rem;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    + .ant-btn {
      &:after {
        content: '';
        position: absolute;
        left: -0.0625rem;
        width: 0.0625rem;
        height: 1.625rem;
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }
`;

const FooterModal = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.875rem;

  .ant-btn {
    width: 8.75rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
`;

export const Scrollbar = styled(ScrollbarCustom)`
  min-height: auto !important;
`;

function onChange(e) {
  console.log(`radio checked:${e.target.value}`);
}

const Aside = ({ t, tabImages, tabVideos, tabTemplates, tabPlaylists, ...props }) => (
  <AsideWrapper {...props}>
    <Header>
      <Title>{t('Library')}</Title>
      <SubTitle>{t('Choose content for screens')}</SubTitle>
      <SwitchGroup onChange={onChange} defaultValue="a">
        <SwitchButton value="a">{t('Schedules')}</SwitchButton>
        <SwitchButton value="b">{t('Library')}</SwitchButton>
      </SwitchGroup>
    </Header>
    <Tabs defaultActiveKey="1" type="card">
      <TabPane tab={<RiImageLine />} key="images">
        <Search placeholder={t('Search')} prefix={<RiSearchLine />} suffix={<RiFilterLine />} />
        <Divider />
        <Scrollbar>{tabImages}</Scrollbar>
        <Footer>
          <ButtonModalPopupComponent
            buttonType="link"
            tooltip={{ title: t('Create folder') }}
            title={t('Add new folder')}
            width={480}
            buttonIcon={<RiFolderAddLine />}>
            <Form layout="vertical">
              <Form.Item
                label={t('Folder Name')}
                name="folder-name"
                rules={[
                  {
                    required: true,
                    message: t('Enter folder name'),
                  },
                ]}>
                <Input placeholder={t('Enter folder name')} />
              </Form.Item>
              <FooterModal>
                <Button type="default" htmlType="button">
                  {t('Cancel')}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t('Add')}
                </Button>
              </FooterModal>
            </Form>
          </ButtonModalPopupComponent>

          <ButtonModalPopupComponent
            buttonType="link"
            buttonText={t('Add Image')}
            title={t('Add new image')}
            width={480}
            buttonIcon={<RiImageAddLine />}>
            <Form>
              <FileUploader listType="text" className="upload-image">
                <span className="anticon">
                  <RiUpload2Line />
                </span>
                <div className="ant-upload-text">{t('Upload new images')}</div>
              </FileUploader>
              <FooterModal>
                <Button type="default" htmlType="button">
                  {t('Cancel')}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t('Upload')}
                </Button>
              </FooterModal>
            </Form>
          </ButtonModalPopupComponent>
        </Footer>
      </TabPane>
      <TabPane tab={<RiFilmLine />} key="videos">
        <Search placeholder={t('Search')} prefix={<RiSearchLine />} suffix={<RiFilterLine />} />
        <Divider />
        <Scrollbar>{tabVideos}</Scrollbar>
        <Footer>
          <ButtonModalPopupComponent
            buttonType="link"
            tooltip={{ title: t('Create folder') }}
            title={t('Add new folder')}
            width={480}
            buttonIcon={<RiFolderAddLine />}>
            <Form layout="vertical">
              <Form.Item
                label={t('Folder Name')}
                name="folder-name"
                rules={[
                  {
                    required: true,
                    message: t('Enter folder name'),
                  },
                ]}>
                <Input placeholder={t('Enter folder name')} />
              </Form.Item>
              <FooterModal>
                <Button type="default" htmlType="submit">
                  {t('Cancel')}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t('Add')}
                </Button>
              </FooterModal>
            </Form>
          </ButtonModalPopupComponent>
          <ButtonModalPopupComponent
            buttonType="link"
            buttonText={t('Add video')}
            title={t('Add new video')}
            width={480}
            buttonIcon={<RiFilmLine />}>
            <Form>
              <FileUploader listType="picture-card" className="upload-image">
                <span className="anticon">
                  <RiUpload2Line />
                </span>
                <div className="ant-upload-text">{t('Upload new video')}</div>
              </FileUploader>
              <FooterModal>
                <Button type="default" htmlType="button">
                  {t('Cancel')}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t('Upload')}
                </Button>
              </FooterModal>
            </Form>
          </ButtonModalPopupComponent>
        </Footer>
      </TabPane>
      <TabPane tab={<RiLayoutMasonryLine />} key="templates">
        <Search placeholder={t('Search')} prefix={<RiSearchLine />} suffix={<RiFilterLine />} />
        <Divider />
        <Scrollbar>{tabTemplates}</Scrollbar>
        <Footer>
          <ButtonModalPopupComponent
            buttonType="link"
            tooltip={{ title: t('Create folder') }}
            title={t('Add new folder')}
            width={480}
            buttonIcon={<RiFolderAddLine />}>
            <Form layout="vertical">
              <Form.Item
                label={t('Folder Name')}
                name="folder-name"
                rules={[
                  {
                    required: true,
                    message: t('Enter folder name'),
                  },
                ]}>
                <Input placeholder={t('Enter folder name')} />
              </Form.Item>
              <FooterModal>
                <Button type="default" htmlType="submit">
                  {t('Cancel')}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t('Add')}
                </Button>
              </FooterModal>
            </Form>
          </ButtonModalPopupComponent>
          <ButtonModalPopupComponent
            buttonType="link"
            buttonText={t('Add template')}
            title={t('Add new template')}
            width={480}
            buttonIcon={<RiLayoutMasonryLine />}>
            <Form>
              <FileUploader listType="picture-card" className="upload-image">
                <span className="anticon">
                  <RiUpload2Line />
                </span>
                <div className="ant-upload-text">{t('Upload new template')}</div>
              </FileUploader>
              <FooterModal>
                <Button type="default" htmlType="button">
                  {t('Cancel')}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t('Upload')}
                </Button>
              </FooterModal>
            </Form>
          </ButtonModalPopupComponent>
        </Footer>
      </TabPane>
      <TabPane tab={<RiPlayList2Line />} key="playlists">
        <Search placeholder={t('Search')} prefix={<RiSearchLine />} suffix={<RiFilterLine />} />
        <Divider />
        <Scrollbar>{tabPlaylists}</Scrollbar>
        <Footer>
          <ButtonModalPopupComponent
            buttonType="link"
            tooltip={{ title: t('Create folder') }}
            title={t('Add new folder')}
            width={480}
            buttonIcon={<RiFolderAddLine />}>
            <Form layout="vertical">
              <Form.Item
                label={t('Folder Name')}
                name="folder-name"
                rules={[
                  {
                    required: true,
                    message: t('Enter folder name'),
                  },
                ]}>
                <Input placeholder={t('Enter folder name')} />
              </Form.Item>
              <FooterModal>
                <Button type="default" htmlType="submit">
                  {t('Cancel')}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t('Add')}
                </Button>
              </FooterModal>
            </Form>
          </ButtonModalPopupComponent>
          <ButtonModalPopupComponent
            buttonType="link"
            buttonText={t('Add Playlist')}
            title={t('Add new playlist')}
            width={480}
            buttonIcon={<RiPlayListAddLine />}>
            <Form>
              <FileUploader listType="picture-card" className="upload-image">
                <span className="anticon">
                  <RiUpload2Line />
                </span>
                <div className="ant-upload-text">{t('Upload new playlist')}</div>
              </FileUploader>
              <FooterModal>
                <Button type="default" htmlType="button">
                  {t('Cancel')}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t('Upload')}
                </Button>
              </FooterModal>
            </Form>
          </ButtonModalPopupComponent>
        </Footer>
      </TabPane>
    </Tabs>
  </AsideWrapper>
);

Aside.propTypes = {
  tabImages: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  tabVideos: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  tabTemplates: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  tabPlaylists: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  t: PropTypes.func.isRequired,
};

Aside.defaultProps = {
  tabImages: undefined,
  tabVideos: undefined,
  tabTemplates: undefined,
  tabPlaylists: undefined,
};

export default compose(withTranslation(), pure)(Aside);
