import React, { memo, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Modal as AntModal } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { time, TimeInstance } from 'src/helpers/time';
import {
  CreateTimeslotInput,
  GetEventsQuery,
  GetTimeslotsQuery,
  MediaItem,
  Playlist,
  Template,
  TimeslotType,
  useCreateTimeslotMutation,
  useUpdateTimeslotMutation,
} from 'src/graphql/generated';
import { updateAfterMutation, logger, useApolloError } from '@fjedi/graphql-react-components';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop, {
  EventInteractionArgs,
  DragFromOutsideItemArgs,
} from 'react-big-calendar/lib/addons/dragAndDrop';
import Spinner from 'src/components/ui-kit/spinner';
import { ThumbnailCard } from 'src/components/ui-kit/thumbnail/media-thumbnail';
import TimeslotComponent, { EventButtons } from './calendar-timeslot';
import { avoidEventTimeslotToBeEditedDirectly, getTimeslotUpdates } from './helpers';
import { CalendarContext, CalendarContextType } from './context';
import { ScheduleItem } from '../schedules.d';

const localizer = momentLocalizer(dayjs);

const DnDCalendar = withDragAndDrop<ScheduleItem>(BigCalendar);

const CalendarContainer = styled.div`
  .rbc-label {
    font-size: 0.85rem;
    margin-top: 0.5rem;
    display: block;
  }

  .rbc-calendar,
  .rbc-addons-dnd.rbc-calendar {
    .rbc-addons-dnd-dragged-event {
      opacity: 0;
    }

    .rbc-event {
      border-radius: 1.2rem;
      border-color: #333;
      background-color: #333;
      margin-left: 1rem;
      max-width: calc(100% - 1.3rem);
    }

    .rbc-event-label {
      font-size: 0.9rem;
      padding: 0.75rem;
    }

    .rbc-addons-dnd-resize-ns-anchor {
      height: 1rem;
      top: -0.75rem;

      &:last-of-type {
        z-index: 100;
        top: auto;
        bottom: 0;
      }

      .rbc-addons-dnd-resize-ns-icon {
        position: absolute;
        width: 40px;
        height: 4px;
        left: calc(50% - 40px / 2);
        top: calc(50% - 4px / 2);

        background: rgba(255, 255, 255, 0.4);
        border-radius: 20px;
        cursor: ns-resize;
        border: 0;
        margin: 0;

        &:focus,
        &:hover {
          background: rgba(255, 255, 255, 0.8);
        }
      }
    }

    .rbc-addons-dnd-drag-preview {
      &.rbc-event {
        /* left: 1rem; */
        /* right: 5px; */
        /* width: auto !important; */
      }
      ${EventButtons} {
        top: 2px;
        right: 5px;
      }
    }
    .rbc-addons-dnd-resizable {
      display: flex;
      flex-direction: column;
    }
    .rbc-event-content {
      display: flex;
      z-index: 99;
    }

    .ant-spin-nested-loading {
      max-height: 100%;
      border-radius: 1.25rem;
    }

    .ant-spin-container {
      max-height: 100%;
    }

    ${ThumbnailCard} {
      max-height: 100%;
      min-height: 3rem;
    }
  }
  .rbc-addons-dnd-is-dragging {
    //
  }
`;

const disableInnerNavigation = () => null;

const EventWrapper: React.FC = props => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div {...props} />;
};

export type CalendarProps = {
  draggedItem: MediaItem | Template | Playlist | null;
  selectedDate: TimeInstance;
  weekday: number;
  zoom: number;
  scheduleId: string;
  timeslotType: TimeslotType;
  timeslotsRes?: GetTimeslotsQuery;
  eventsRes?: GetEventsQuery;
  deviceId?: string;
  events: ScheduleItem[];
  onDropFromOutside?: () => void;
};

const Calendar: React.FC<CalendarProps> = props => {
  const {
    draggedItem,
    selectedDate,
    weekday,
    zoom,
    scheduleId,
    timeslotType,
    timeslotsRes,
    eventsRes,
    deviceId,
    events,
  } = props;
  const onError = useApolloError();
  const { t } = useTranslation();
  const currentDate = useMemo(() => {
    if (timeslotType === 'DATE' || timeslotType === 'EVENTS') {
      return selectedDate.toDate();
    }
    if (timeslotType === 'WEEKDAY') {
      return time().weekday(weekday).toDate();
    }
    return time().toDate();
  }, [timeslotType, weekday, selectedDate]);
  //
  const getNow = useCallback(() => currentDate, [currentDate]);
  //
  const [createTimeslot] = useCreateTimeslotMutation({
    update: updateAfterMutation('Timeslot', 'getTimeslots'),
    onError,
    onCompleted() {
      // eslint-disable-next-line react/destructuring-assignment
      if (typeof props.onDropFromOutside === 'function') {
        // eslint-disable-next-line react/destructuring-assignment
        props.onDropFromOutside();
      }
    },
  });
  const [updateTimeslot] = useUpdateTimeslotMutation({
    onError,
  });
  const onEventResize = useCallback(
    (event: EventInteractionArgs<ScheduleItem>) => {
      const isEventTimeslot = avoidEventTimeslotToBeEditedDirectly(event?.event?.resource, t);
      if (isEventTimeslot) {
        return;
      }
      getTimeslotUpdates({
        mutation: updateTimeslot,
        events,
        event,
        onError,
        currentDate,
      });
    },
    [t, onError, events, updateTimeslot, currentDate],
  );
  const onEventDrop = useCallback(
    (event: EventInteractionArgs<ScheduleItem>) => {
      getTimeslotUpdates({
        mutation: updateTimeslot,
        events,
        event,
        onError,
        currentDate,
      });
    },
    [onError, events, updateTimeslot, currentDate],
  );

  const onDropFromOutside = useCallback(
    (event: DragFromOutsideItemArgs) => {
      if (!draggedItem) {
        return;
      }

      const { id, __typename: typeName } = draggedItem;

      if (typeName === 'Template') {
        const { areas } = draggedItem;

        if (!areas || !areas.length) {
          AntModal.warning({
            title: t('Warning!'),
            content: t('You can not add empty template to timeslot!'),
          });
          return;
        }
      }

      if (typeName === 'Playlist') {
        const { items } = draggedItem;

        if (!items || !items.length) {
          AntModal.warning({
            title: t('Warning!'),
            content: t('You can not add empty playlist to timeslot!'),
          });
          return;
        }
      }

      const { start: s } = event;
      const projectBasedOffset = time().tz().utcOffset();
      const defaultTimeslotPeriod = Math.max(Math.floor((zoom / 24) * 4), 1);
      const startMoment = time(s);
      const endMoment = startMoment.add(defaultTimeslotPeriod, 'hours');
      const now = time(currentDate);
      // Do not allow timeslot to go behind selected day
      const start = startMoment.isSame(now, 'day') ? startMoment : now.clone().startOf('day').add(1, 'second');
      const end = endMoment.isSame(now, 'day') ? endMoment : now.clone().endOf('day').subtract(1, 'second');

      const associatedEvent = (eventsRes?.getEvents?.rows ?? []).find(e =>
        start.add(-projectBasedOffset, 'minute').utc(true).isBetween(time(e.start), time(e.end)),
      );
      //
      const firstItem: CreateTimeslotInput['firstItem'] = {};
      switch (typeName) {
        case 'MediaItem':
          firstItem.mediaItemId = id;
          break;
        case 'Template':
          firstItem.templateId = id;
          break;
        case 'Playlist':
          firstItem.playlistId = id;
          break;
        default:
          logger(`Dragged element has unknown __typename: ${typeName}`, { event, draggedItem });
      }

      const input: CreateTimeslotInput = {
        start: start.add(-projectBasedOffset, 'minute').utc(true),
        end: end.add(-projectBasedOffset, 'minute').utc(true),
        // !TODO: Need to change that?
        type: timeslotType,
        scheduleId,
        firstItem,
        eventId: associatedEvent?.id,
        deviceId,
      };
      //
      createTimeslot({
        variables: {
          input,
        },
      }).catch(logger);
    },
    [draggedItem, zoom, currentDate, eventsRes?.getEvents?.rows, timeslotType, scheduleId, deviceId, createTimeslot, t],
  );
  //
  const calendarComponents = useMemo(
    () => ({
      toolbar: () => null,
      header: () => null,
      event: TimeslotComponent,
      // eslint-disable-next-line react/jsx-props-no-spreading
      eventWrapper: EventWrapper,
      // timeGutterHeader: ({ children }) => <div className="timeGutterHeader">{children}</div>,
      // timeGutterWrapper: ({ children }) => <div className="timeGutterWrapper">{children}</div>,
      // timeSlotWrapper: ({ children }) => <div className="timeSlotWrapper">{children}</div>,
      // dateCellWrapper: ({ children }) => <div className="dateCellWrapper">{children}</div>,
    }),
    [],
  );
  const calendarContext: CalendarContextType = useMemo(
    () => ({
      currentDate,
      updateTimeslot,
      createTimeslot,
      onEventResize,
    }),
    [currentDate, createTimeslot, updateTimeslot, onEventResize],
  );

  return (
    <CalendarContext.Provider value={calendarContext}>
      <CalendarContainer>
        <Spinner spinning={!timeslotsRes?.getTimeslots && !eventsRes?.getEvents && !events}>
          <DnDCalendar
            localizer={localizer}
            getNow={getNow}
            onNavigate={disableInnerNavigation}
            startAccessor="start"
            endAccessor="end"
            date={currentDate}
            defaultView="day"
            views={['day']}
            events={events}
            components={calendarComponents}
            onEventDrop={onEventDrop}
            onEventResize={onEventResize}
            onDropFromOutside={onDropFromOutside}
            resizable={timeslotType !== 'EVENTS'}
            selectable={timeslotType !== 'EVENTS'}
            step={5}
            timeslots={zoom}
            style={{ height: '100%' }}
          />
        </Spinner>
      </CalendarContainer>
    </CalendarContext.Provider>
  );
};

Calendar.displayName = 'Calendar';

export default memo(Calendar);
