import React, { FC, memo, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/ui-kit/buttons';
import ModalPopupComponent from 'src/components/ui-kit/modal-popup';
import MediaItemUsageContext from 'src/components/ui-kit/thumbnail/context';
import MediaItemUsageListItem from './media-item-usage-list-item';

const ErrorDescription = styled.span`
  font-weight: bold;
  font-size: 19px;
  text-align: center;
  display: block;
`;

const FooterModal = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.875rem;

  .ant-btn {
    width: 8.75rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
`;

const MediaItemUsageDialog: FC<{ onCancel: () => void }> = props => {
  const { onCancel } = props;
  const { t } = useTranslation();
  //
  const { isUsageErrorVisible, mediaUsages } = useContext(MediaItemUsageContext);
  //
  return (
    <ModalPopupComponent title="Error" isVisible={isUsageErrorVisible} onCancel={onCancel}>
      <>
        <ErrorDescription>{`${t('This media could not be removed because it is used at')}:`}</ErrorDescription>
        {mediaUsages.map(u => (
          <MediaItemUsageListItem key={u.title} usage={u} />
        ))}

        <FooterModal>
          <Button type="primary" htmlType="button" onClick={onCancel}>
            {t('OK')}
          </Button>
        </FooterModal>
      </>
    </ModalPopupComponent>
  );
};
MediaItemUsageDialog.displayName = 'MediaItemUsageDialog';

export default memo(MediaItemUsageDialog);
