import { useCallback, useEffect, useReducer, useRef } from 'react';
import Bluebird from 'bluebird';
import { throttle } from 'lodash';

export type RequestHeaders = Record<string, unknown>;

// State & hook output
export interface State<T> {
  status: 'init' | 'fetching' | 'error' | 'fetched';
  data?: T;
  error?: (Error & { status?: number; headers?: RequestHeaders }) | undefined;
  refetch: (opts?: FetchOptions) => void;
}

interface Cache<T> {
  [url: string]: T;
}

export type FetchOptions = {
  headers: Record<string, string>;
  method: string;
  data?: any;
  skip?: boolean;
  useCache?: boolean;
  responseType?: 'json' | 'blob';
  throttleDelay?: number;
};

// discriminated union type
type Action<T> =
  | { type: 'request' }
  | { type: 'success'; payload: T }
  | { type: 'failure'; payload: (Error & { status?: number; headers?: RequestHeaders }) | undefined };

const DEFAULT_HEADERS = {};
const DEFAULT_METHOD = 'GET';

const makeRequest = (href: string, opt?: FetchOptions) => {
  return new Bluebird((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const headers = opt?.headers ?? DEFAULT_HEADERS;
    xhr.open(opt?.method || DEFAULT_METHOD, href);

    Object.keys(headers).forEach(key => {
      xhr.setRequestHeader(`${key}`, headers[key]);
    });

    // Add auth-cookies to each request
    xhr.withCredentials = true;

    xhr.responseType = opt?.responseType || 'json';
    const payload = opt?.data ? JSON.stringify(opt.data) : null;
    xhr.send(payload);

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
          headers,
        });
      }
    };

    xhr.onerror = () => {
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
        headers,
      });
    };
  });
};

const nullRefetch = (opts?: FetchOptions) => {};

function useFetch<T = unknown>(url?: string, options?: FetchOptions): State<T> {
  const initialState: State<T> = {
    status: 'init',
    error: undefined,
    data: undefined,
    refetch: nullRefetch,
  };

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'request':
        return { ...initialState, status: 'fetching' };
      case 'success':
        return { ...initialState, status: 'fetched', data: action.payload };
      case 'failure':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const fetchData = useRef(
    throttle((requestURL: string, opts?: FetchOptions) => {
      dispatch({ type: 'request' });
      //
      makeRequest(requestURL, opts)
        .then((response: any) => {
          dispatch({ type: 'success', payload: response });
        })
        .catch(err => {
          dispatch({ type: 'failure', payload: err });
        });
    }, options?.throttleDelay ?? 1000),
  );

  const refetch = useCallback(
    (opts: FetchOptions | undefined = options) => {
      if (!url) {
        return;
      }
      console.log('Init refetch...', { url, opts });
      fetchData.current(url, opts);
    },
    [url],
  );

  useEffect(() => {
    if (!url || options?.skip) {
      return () => null;
    }
    fetchData.current(url, options);

    return () => {
      console.warn(`Request to ${url} has been canceled...`);
      fetchData.current.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, options?.skip, options?.headers?.Authorization]);

  return { ...state, refetch };
}

export default useFetch;
