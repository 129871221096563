import { useParams } from '@fjedi/react-router-helpers';
import { Modal as AntModal } from 'antd';
import compact from 'lodash/compact';
import merge from 'lodash/merge';
import sortBy from 'lodash/sortBy';
import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { logger, useApolloError } from '@fjedi/graphql-react-components';
import { getMediaItemsDuration } from 'src/functions';
import { arrayMove } from 'src/helpers/array-move';
import { uuid } from 'src/helpers/uuid';
// GraphQL Queries
import {
  GetPlaylistQueryVariables,
  MediaFolderItem,
  PlaylistItem,
  UpdatePlaylistInput,
  UpdatePlaylistMutationOptions,
  useGetPlaylistQuery,
  usePlaylistChangedSubscription,
  useUpdatePlaylistMutation,
} from 'src/graphql/generated';
// Components
import { ContentCard } from 'src/components/ui-kit/admin-layout/content-card';
import RightSider from 'src/components/ui-kit/admin-layout/right-sider';
import MediaManager from 'src/components/ui-kit/aside/aside-media-manager';
import { BreadcrumbContext } from 'src/components/ui-kit/breadcrumb';
import { DragDropContext, DraggableItem, DroppableArea, DropResult } from 'src/components/ui-kit/drag-n-drop';
import { Col, Row } from 'src/components/ui-kit/grid';
import { Scrollbar } from 'src/components/ui-kit/scrollbar';
import MediaThumbnail from 'src/components/ui-kit/thumbnail/media-thumbnail';
import UploadIcon from 'static/images/photo-re.svg';
import PlaylistItemDuration, {
  PlaylistItemDurationProps,
} from 'src/components/routes/private/playlist-editor/playlist-item-duration-input';
import { MediaFolderItemProfile } from 'src/components/ui-kit/media-item/media-item';
import { PlaylistContext } from './context';

const Content = styled(ContentCard)`
  max-width: 914px;
  min-width: 100%;
  overflow-x: hidden;

  &.ant-card .ant-card-body {
    padding-right: 0;

    .ant-row:has(.ant-col) {
      padding-right: 1.5rem;

      & > .ant-col:last-of-type {
        margin-bottom: 1.5rem;
      }
    }

    & > div:has(+ div) {
      margin-bottom: 1.5rem;

      & > div:first-of-type {
        margin-bottom: 0 !important;
      }
    }
  }
`;

const ContainerFooter = styled.div`
  display: flex;
  align-items: center;

  min-height: 3rem;
  padding: 1rem 1.5rem;
  margin: 0 0 -1.5rem -1.5rem;
  flex-grow: 0 !important;

  color: #000000;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);

  .container__footer_full {
    margin-left: auto;
  }

  .container__footer-bold {
    font-weight: bold;
  }
`;

const Stub = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  pointer-events: none;
  transition: opacity 0.2s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StubImage = styled.div`
  background: #ffffff url(${UploadIcon}) no-repeat center;
  background-position: calc(50% + 2.5rem) 0;
  background-size: 15.25rem auto;
  width: 100%;
  height: 12.125rem;
`;

const StubTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
  margin-bottom: 0.25rem;
`;

const StubDesc = styled.div`
  max-width: 21rem;
  color: rgba(0, 0, 0, 0.3);
`;

function onDragOver(event: DragEvent) {
  // Disable dragover to allow onDrop event to work properly
  // https://stackoverflow.com/questions/51385349/ondrop-event-not-fired-in-react-app
  event.preventDefault();
  event.stopPropagation();
  return false;
}

const PlaylistEditorPage: FC = () => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const { playlistId } = useParams();
  //
  const variables = useMemo<GetPlaylistQueryVariables>(() => ({ id: playlistId! }), [playlistId]);
  const { data: res } = useGetPlaylistQuery({
    variables,
    skip: !playlistId,
    fetchPolicy: 'cache-and-network',
  });
  usePlaylistChangedSubscription({
    variables: {
      filter: { id: [playlistId!] },
    },
    skip: !playlistId,
  });
  const { title, items: unsortedItems } = res?.getPlaylist ?? {};
  const items = useMemo(() => {
    const sortedItems = sortBy(unsortedItems ?? [], 'sequence');
    return sortedItems.map(item => {
      const duration = item?.duration ?? 0;
      return { ...item, duration: duration > 0 ? duration : 60 };
    });
  }, [unsortedItems]);

  const header = useMemo(
    () => ({
      title: title || undefined,
    }),
    [title],
  );
  //
  const [updatePlaylist] = useUpdatePlaylistMutation({
    onError,
    onCompleted() {
      setDraggedItem(null);
    },
  });
  const onPlaylistItemChange = useCallback(
    (item: Pick<PlaylistItem, 'id' | 'sequence'>): PlaylistItemDurationProps['onChange'] =>
      duration => {
        if (!playlistId || !res?.getPlaylist) {
          console.error('Cannot change playlist item without fetching its data from api');
          return;
        }
        const updatedItems = [
          {
            id: item.id,
            sequence: item.sequence,
            duration,
            playlistId,
          },
        ];
        //
        updatePlaylist({
          variables: {
            id: playlistId,
            input: {
              updatedItems,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updatePlaylist: {
              ...res?.getPlaylist,
              items: items.map(i => {
                if (i.id === item.id) {
                  return merge(i, { duration });
                }
                return i;
              }),
            },
          },
        }).catch(logger);
      },
    [updatePlaylist, playlistId, items, res?.getPlaylist],
  );
  //
  const [draggedItem, setDraggedItem] = useState<MediaFolderItem | null>(null);
  const onItemDragStart = useCallback(
    (item: MediaFolderItem) => (event: DragEvent) => {
      logger('Item started to drag', {
        item,
        event,
      });
      setDraggedItem(item);
    },
    [setDraggedItem],
  );
  const onItemRemove = useCallback(
    (id: PlaylistItem['id']) => () => {
      if (!playlistId) {
        console.log('Cannot remove playlist-item item without playlist id');
        return;
      }
      updatePlaylist({
        variables: {
          id: playlistId,
          input: {
            removedItems: [id],
          },
        },
      }).catch(logger);
    },
    [playlistId, updatePlaylist],
  );
  const onDrop = useCallback(
    (event: DropResult & DragEvent) => {
      if (!playlistId || !res?.getPlaylist || !event || (!draggedItem && !event.draggableId)) {
        return;
      }
      const droppedItem = draggedItem || items.find(i => i.id === event.draggableId);
      if (!droppedItem) {
        return;
      }

      if (typeof event.stopPropagation === 'function') {
        event.stopPropagation();
        event.preventDefault();
      }

      const { __typename: typeName } = droppedItem;
      if (typeName === 'Template' && (!('areas' in droppedItem) || !droppedItem.areas || !droppedItem.areas.length)) {
        AntModal.warning({
          title: t('Warning!'),
          content: t('You can not add empty template to playlist!'),
        });
        return;
      }

      let optimisticResponse: UpdatePlaylistMutationOptions['optimisticResponse'];
      const newItems: UpdatePlaylistInput['newItems'] = [];
      const updatedItems: UpdatePlaylistInput['updatedItems'] = [];

      if (event.destination || event.source) {
        const { destination, source } = event;

        if (source.droppableId !== destination?.droppableId || !destination) {
          return;
        }

        updatedItems.push(
          ...arrayMove(items, source.index - 1, destination.index - 1).map((item, itemIndex) => ({
            id: item.id,
            sequence: itemIndex + 1,
            playlistId,
          })),
        );

        optimisticResponse = {
          __typename: 'Mutation',
          updatePlaylist: {
            ...res.getPlaylist,
            items: updatedItems.map(i => ({ ...i, duration: i.duration ?? 60, __typename: 'PlaylistItem' })),
          },
        };
      } else {
        newItems.push({
          id: uuid(1),
          playlistId,
          duration: 'duration' in droppedItem && droppedItem.duration ? droppedItem.duration : 60,
          sequence: items.length + 1,
          // eslint-disable-next-line no-underscore-dangle
          [droppedItem.__typename === 'MediaItem' ? 'mediaItemId' : 'templateId']: droppedItem.id,
        });
      }

      updatePlaylist({
        variables: {
          id: playlistId,
          input: {
            newItems,
            updatedItems,
          },
        },
        optimisticResponse,
      }).catch(logger);
    },
    [draggedItem, updatePlaylist, playlistId, t, items, res?.getPlaylist],
  );
  const parentBreadcrumbNameMap = useContext(BreadcrumbContext);
  const breadcrumbNameMap = useMemo(
    () => ({ ...parentBreadcrumbNameMap, [`/playlist/${playlistId}`]: title, '/playlist': t('Playlist editor') }),
    [parentBreadcrumbNameMap, playlistId, title, t],
  );
  const totalDuration = useMemo(() => getMediaItemsDuration(items), [items]);

  return (
    <PlaylistContext.Provider value={res?.getPlaylist}>
      <BreadcrumbContext.Provider value={breadcrumbNameMap}>
        <Content header={header} showBreadcrumbs showBackButton>
          <Scrollbar>
            <DragDropContext onDragEnd={onDrop}>
              <DroppableArea style={{ height: '100%' }} droppableId="playlist-items" direction="horizontal">
                <Row
                  style={{ height: '100%', alignContent: 'flex-start' }}
                  gutter={[16, 16]}
                  onDragOver={onDragOver}
                  onDrop={onDrop}>
                  {items.length === 0 && (
                    <Stub>
                      <StubImage />
                      <StubTitle>{t('Select an item')}</StubTitle>
                      <StubDesc>{t('Drag-n-drop the item from library to work with a playlist')}</StubDesc>
                    </Stub>
                  )}
                  {items.map(item => {
                    const playlistItemActions = [
                      {
                        key: 'duration',
                        // eslint-disable-next-line react/no-unstable-nested-components
                        component: () => (
                          <PlaylistItemDuration
                            value={item.duration ?? 60}
                            onChange={onPlaylistItemChange(item)}
                            disabled={item.mediaItem?.type === 'VIDEO'}
                          />
                        ),
                      },
                    ];
                    return (
                      <Col key={item.id} span={8}>
                        <DraggableItem draggableId={item.id} index={item.sequence}>
                          <MediaThumbnail
                            actions={playlistItemActions}
                            onRemove={onItemRemove(item.id)}
                            data={(item.template || item.mediaItem) as MediaFolderItemProfile}
                          />
                        </DraggableItem>
                      </Col>
                    );
                  })}
                </Row>
              </DroppableArea>
            </DragDropContext>
          </Scrollbar>
          <ContainerFooter>
            <div>
              {`${t('Total duration')}: `}
              <span className="container__footer-bold">{`${totalDuration} seconds`}</span>
            </div>
            <div className="container__footer_full">
              {`${t('Total items')}: `}
              <span className="container__footer-bold">{items?.length || 0}</span>
            </div>
          </ContainerFooter>
        </Content>

        <RightSider>
          <MediaManager onDragStart={onItemDragStart} />
        </RightSider>
      </BreadcrumbContext.Provider>
    </PlaylistContext.Provider>
  );
};

PlaylistEditorPage.displayName = 'PlaylistEditorPage';

export default memo(PlaylistEditorPage);
