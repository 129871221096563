import clearSkyDay from './01d.svg';
import clearSkyNight from './01n.svg';
import fewCloudsDay from './02d.svg';
import fewCloudsNight from './02n.svg';
import scatteredCloudsDay from './03d.svg';
import scatteredCloudsNight from './03n.svg';
import brokenCloudsDay from './04d.svg';
import brokenCloudsNight from './04n.svg';
import showerRainDay from './09d.svg';
import showerRainNight from './09n.svg';
import rainDay from './10d.svg';
import rainNight from './10n.svg';
import thunderstormDay from './11d.svg';
import thunderstormNight from './11n.svg';
import snowDay from './13d.svg';
import snowNight from './13n.svg';
import mistDay from './50d.svg';
import mistNight from './50n.svg';

import type { IconType } from '../index';

const simpleBlackIcons: Record<IconType, string> = {
  '01d': clearSkyDay,
  '01n': clearSkyNight,
  '02d': fewCloudsDay,
  '02n': fewCloudsNight,
  '03d': scatteredCloudsDay,
  '03n': scatteredCloudsNight,
  '04d': brokenCloudsDay,
  '04n': brokenCloudsNight,
  '09d': showerRainDay,
  '09n': showerRainNight,
  '10d': rainDay,
  '10n': rainNight,
  '11d': thunderstormDay,
  '11n': thunderstormNight,
  '13d': snowDay,
  '13n': snowNight,
  '50d': mistDay,
  '50n': mistNight,
};
export default simpleBlackIcons;
