import React, { FC, memo, useCallback } from 'react';
import { IoMdCheckboxOutline, IoMdSquareOutline } from 'react-icons/io';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import Empty from 'antd/lib/empty';
import styled from 'styled-components';
import { stopEventPropagation } from 'src/functions';
import Button from 'src/components/ui-kit/buttons';
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';
import { Image } from 'src/components/ui-kit/thumbnail';
import Tooltip from 'src/components/ui-kit/tooltip';
import CustomPopconfirm, { PopconfirmProps } from 'src/components/ui-kit/popconfirm';

import { WayfindingItemCardProps, WayfindingItemCardContainerProps } from './wayfinding.d';

const Popconfirm: FC<PopconfirmProps> = CustomPopconfirm;

const WayfindingLocationContainer = styled.div.attrs({
  className: 'wayfinding-location-container',
})<WayfindingItemCardContainerProps>`
  min-width: 205px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  outline: 0.5px solid ${colorGrays.gray900} inset;
  background: ${colorGrays.gray500};

  & > .ant-empty {
    position: relative;
    top: -0.5rem;

    & > .ant-empty-image {
      height: 2.5rem;
    }
  }

  & > .ant-image {
    display: flex;
    position: relative;
    top: -0.5rem;

    align-items: center;
    justify-content: center;

    & > .ant-image-img {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  &::after {
    display: block;
    position: relative;
    top: -0.375rem;
    content: '${({ iconTitle }) => iconTitle}';
  }

  & > button.action-delete {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    padding: 0;
    border: 0;
    height: auto !important;
    color: ${colorTheme.light};
  }
`;

const LocationFooter = styled.footer`
  background: hsl(0, 0%, 0%);

  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  padding: 0 0.625rem;
  color: ${colorTheme.light};

  & > button.action-select {
    z-index: 10;
    padding-right: 0;
    margin-left: auto;
    color: hsl(255, 100%, 100%);

    &:disabled {
      color: hsl(255, 100%, 100%);

      &:hover {
        color: hsl(255, 100%, 100%);
      }
    }
  }
`;

export const WayfindingItemCard: FC<WayfindingItemCardProps> = ({
  locationData,
  iconData,
  deviceLocationId,
  isVisible,
  showVisibilityIndicator,
  loading,
  onRemove,
  onVisibilityChange,
}) => {
  const { t } = useTranslation();

  const handleDeviceLocationRemove = useCallback(() => {
    if (typeof onRemove === 'function' && deviceLocationId) onRemove(deviceLocationId);
  }, [onRemove, deviceLocationId]);

  const handleVisibilityIndicatorClick = useCallback(() => {
    if (typeof onVisibilityChange === 'function')
      onVisibilityChange(deviceLocationId ?? null, locationData.id, !isVisible);
  }, [onVisibilityChange, locationData, deviceLocationId, isVisible]);

  return (
    <WayfindingLocationContainer iconTitle={iconData ? iconData.title : ''}>
      {!iconData && <Empty />}
      {!!iconData && <Image src={iconData?.url} preview={false} style={{ objectFit: 'contain' }} />}
      {!!iconData && (
        <Popconfirm
          title={`${t('Remove')}?`}
          onClick={stopEventPropagation}
          onConfirm={handleDeviceLocationRemove}
          placement="bottomRight"
          okText={t('Yes')}
          cancelText={t('No')}>
          <Tooltip title={t('Remove')}>
            <Button type="link" className="action-delete" disabled={loading}>
              <RiDeleteBinLine />
            </Button>
          </Tooltip>
        </Popconfirm>
      )}
      <LocationFooter>
        {locationData.name}
        {showVisibilityIndicator && (
          <Button type="link" onClick={handleVisibilityIndicatorClick} className="action-select" disabled={loading}>
            {isVisible ? <IoMdCheckboxOutline /> : <IoMdSquareOutline />}
          </Button>
        )}
      </LocationFooter>
    </WayfindingLocationContainer>
  );
};

export default memo(WayfindingItemCard);
