import styled from 'styled-components';
import { Collapse as C } from 'antd';

export const Collapse = styled(C)`
  &.ant-collapse.ant-collapse-icon-position-end {
    overflow-x: hidden;
  }

  &.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;

    > .ant-collapse-header {
      padding: 0.75rem 2rem 0.75rem;
      display: flex;
      align-items: center;
      height: 60px;
      user-select: none;

      &.ant-collapse-header-collapsible-only {
        .ant-collapse-header-text {
          max-width: 80%;
          transition: max-width 0.3s;
        }
      }

      &:hover,
      &:focus {
        &.ant-collapse-header-collapsible-only {
          .ant-collapse-header-text {
            max-width: 58%;
          }
        }
        .ant-collapse-extra {
          opacity: 1;
          display: flex;
        }
      }

      .ant-collapse-expand-icon {
        padding-inline-start: 0;
        padding: 1.5rem;
        cursor: pointer;
        margin-right: -1.5rem;
        margin-left: -1.5rem;
      }

      .ant-collapse-arrow {
        top: auto;
        transform: rotate(180deg);
        transition: transform 0.2s;
      }

      > .ant-collapse-extra {
        display: none;
        float: none;
        margin-left: auto;
        opacity: 0;
        transition: opacity 0.4s;
        //margin-right: 2rem;
      }
    }
    > .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 1.25rem 2rem;
      }
    }

    &.ant-collapse-no-gutters {
      .ant-collapse-content-box {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.ant-collapse-item-active {
      &:last-child {
        border-bottom: 0;
      }

      .ant-collapse-header {
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
      }

      .ant-collapse-arrow {
        transform: translateY(0%) rotate(0);
      }
    }

    &.ant-collapse-item-disabled {
      > .ant-collapse-header .ant-collapse-extra {
        display: none;
      }
    }

    &:last-child {
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
    }
  }

  &.ant-collapse-icon-position-end {
    > .ant-collapse-item {
      > .ant-collapse-header {
        .ant-collapse-arrow {
          right: 1.65rem;
          font-size: 1.5rem;
          padding: 0;
        }
      }
    }
  }

  .ant-collapse-extra .ant-btn {
    background-color: transparent;
    box-shadow: none;
    border-width: 0;
    color: rgba(0, 0, 0, 0.2);
    padding: 5px 5px;
    height: auto;

    svg {
      font-size: 1.25rem;
    }

    &:hover,
    &:focus {
      color: #6a75ca;
      background-color: transparent;
    }
  }
` as typeof C;

export const Panel = styled(Collapse.Panel)`
  background-color: #ffffff;
  border-bottom-color: rgba(0, 0, 0, 0.2);

  .ant-collapse-header {
    font-weight: 600;
  }

  &:hover {
    .ant-typography-edit {
      opacity: 1;
    }
  }

  .ant-typography-edit-content {
    textarea.ant-input {
      padding-right: 0.75rem !important ;
    }
  }
`;

export default Collapse;
