import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
// Recompose
import { pure, compose, getContext, withContext, withProps, lifecycle } from 'recompose';
import { routerMatchType, ViewerType } from 'src/store/prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import logger from 'src/helpers/logger';
// Child React components.
import { Redirect, Route, Routes } from '@fjedi/react-router-helpers';
import { WhenNotFound, setHTMLTitle } from 'src/components/service-routes';

// GraphQL
import { Mutation } from '@fjedi/graphql-react-components';
// GraphQL Queries
import createCompanyMutation from 'src/graphql/mutations/create-company.graphql';
//
import { Modal, Divider, Tag } from 'antd';
import Card from 'src/components/ui-kit/card';
import TabNavigation from 'src/components/ui-kit/tab-navigation';
import { Title, Paragraph } from 'src/components/ui-kit/typography';
import { Row, Col } from 'src/components/ui-kit/grid';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input, MaskedInput } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';
//
import CompanyMainPage from './main';
import CompanyUsersPage from './users';

const CompanyRoot = props => {
  const {
    viewer,
    match: { path, url },
  } = props;
  //
  const { t } = useTranslation();
  const onSubmit = useCallback(mutation => input => {
    //
    logger(input);
    //
    mutation({
      variables: { input },
    });
  });
  //
  if (!viewer?.primaryCompany) {
    return (
      <Card>
        <Row>
          <Col md={{ span: 10, offset: 7 }}>
            <Title centered level={3}>
              {t('Register new company')}
            </Title>
            <Paragraph centered>{t('You are not a member of any company, but you can create new one')}</Paragraph>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col style={{ textAlign: 'center' }} md={{ span: 8, offset: 8 }}>
            <Mutation mutation={createCompanyMutation}>
              {(addUser, { loading }) => (
                <Form style={{ display: 'inline-flex' }} layout="inline" onFinish={onSubmit(addUser)}>
                  <FormItem
                    name="name"
                    label={t('Name')}
                    rules={[{ required: true, message: t('Please fill this field') }]}>
                    <Input />
                  </FormItem>
                  <FormItem>
                    <Button style={{ marginTop: 0 }} type="primary" htmlType="submit" loading={loading}>
                      {t('Create')}
                    </Button>
                  </FormItem>
                </Form>
              )}
            </Mutation>
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Routes>
      <Route path="general" component={TabNavigation} />
      <Route path="users" component={TabNavigation} />
      <Redirect from={url} to={`${url}/general`} />
      <Route component={WhenNotFound} />
    </Routes>
  );
};

CompanyRoot.propTypes = {
  match: routerMatchType.isRequired,
  viewer: ViewerType.isRequired,
};

export default compose(
  withTranslation(),
  withProps(({ t }) => ({
    titlePrefix: t('My company'),
  })),
  withContext(
    {
      titlePrefix: PropTypes.string.isRequired,
      tabsPosition: PropTypes.string,
      tabs: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
        }),
      ).isRequired,
    },
    ({ t, titlePrefix, match: { url } }) => ({
      titlePrefix,
      tabsPosition: 'center',
      tabs: [
        {
          title: t('General'),
          link: `${url}/general`,
          component: CompanyMainPage,
        },
        {
          title: t('Users'),
          link: `${url}/users`,
          component: CompanyUsersPage,
        },
      ],
    }),
  ),
  pure,
)(CompanyRoot);
