import React, { memo, useState, useEffect, useCallback, CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Select, { Option } from 'src/components/ui-kit/select';

export const RoleSelectorElement = styled(Select)`
  width: 100%;
  min-width: 7rem;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.5rem;
    height: 2rem;
  }

  .ant-select-selector {
    &:before {
      content: '';
      /* width: 20px; */
      /* height: 20px; */
      display: block;
      margin-right: 0.25rem;
    }
  }
`;

export const RoleList = [
  { label: 'Admin', value: 'ADMIN' },
  // { label: 'Moderator', value: 'MODERATOR' },
  { label: 'User', value: 'USER' },
];

export type RoleSelectorProps = {
  value?: string;
  onChange?(_value: string): void;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
};

const RoleSelector: FC<RoleSelectorProps> = memo(props => {
  const { t } = useTranslation();
  const { value: initialValue, className, style, disabled } = props;
  const [selectedRole, setRole] = useState(initialValue);
  //
  useEffect(() => {
    if (initialValue !== selectedRole) {
      setRole(initialValue);
    }
  }, [initialValue]);
  //
  const onChange = useCallback(
    (v: string) => {
      //
      if (typeof props.onChange === 'function') {
        props.onChange(v);
      } else {
        setRole(v);
      }
    },
    [selectedRole],
  );
  //
  return (
    <RoleSelectorElement
      disabled={disabled}
      value={selectedRole}
      onChange={onChange}
      style={style}
      className={className}>
      {RoleList.map(({ value, label }) => (
        <Option key={value} value={value}>
          {label}
        </Option>
      ))}
    </RoleSelectorElement>
  );
});

export default memo(RoleSelector);
