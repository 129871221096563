import React, { FC, memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { RiAddCircleLine, RiDeleteBinLine, RiSettings5Fill } from 'react-icons/ri';

import Button from 'src/components/ui-kit/buttons';
import { colorTheme } from 'src/components/ui-kit/theme';
import CustomPopconfirm from 'src/components/ui-kit/popconfirm';
import Tooltip from 'src/components/ui-kit/tooltip';
import { DroppableArea as CustomDroppableArea, DraggableItem } from 'src/components/ui-kit/drag-n-drop';

import type { TimeInstance } from 'src/helpers/time';

import { stopEventPropagation } from 'src/functions';
import EventCard from './event-card';

import type { LocationCardProps } from './events';

const Popconfirm = CustomPopconfirm;
const DroppableArea = CustomDroppableArea as FC<Partial<Parameters<typeof CustomDroppableArea>['0']>>;

const Card = styled.div<{ eventsCount: number }>`
  grid-column: 2;
  grid-row-end: span ${({ eventsCount }) => ([0, 1].includes(eventsCount) ? eventsCount + 1 : eventsCount)};

  &:nth-of-type(2n + 1) {
    grid-column: 1;
  }

  &:last-of-type {
    grid-column: auto;
  }

  & > div {
    height: 100% !important;

    display: grid;
    grid-template: repeat(${({ eventsCount }) => (eventsCount > 0 ? 3 : 2)}, auto) / 65% 1fr;
    gap: 0;
    align-items: end;

    padding: 1.25rem 1.25rem;

    background: hsl(0, 0%, 0%);
    border-radius: 0.625rem;
  }
`;

const Title = styled.h3`
  grid-column: 1;
  grid-row: 1;

  color: ${colorTheme.light};
`;

const SubTitle = styled.h4`
  grid-column: 1;
  grid-row: 2;
  align-self: start;

  color: ${colorTheme.secondary};
`;

const Actions = styled.div`
  grid-column: 2;
  grid-row-end: span 2;
  align-self: center;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-btn {
    color: ${colorTheme.secondary};
  }
`;

const Events = styled.div`
  grid-row: 3;
  grid-column: 1 / span 2;
  margin-top: 0.5rem;
  align-self: end;
`;

export const LocationCard: FC<LocationCardProps> = ({
  id,
  title,
  operaFunctionSpaceCode,
  events,
  devices,
  occupiedTime,
  onRemove,
  onEditClick,
  onAddEventClick,
}) => {
  const { t } = useTranslation();

  const removeLocation = useCallback(() => onRemove(id), [id, onRemove]);

  const isEmpty = useMemo(() => !events || !events.length, [events]);
  const eventsCount = useMemo(() => events.length, [events]);
  const subTitle = useMemo(() => {
    let count: string;

    if (isEmpty) count = 'No';
    else count = `${eventsCount > 1 ? 'There are' : 'There is'} ${eventsCount > 1 ? eventsCount : 'an'}`;

    return t(`${count} event${eventsCount > 1 ? 's' : ''} for this location`);
  }, [t, isEmpty, eventsCount]);

  return (
    <Card eventsCount={eventsCount}>
      <DroppableArea key={id} droppableId={`${id}.eventsList`} direction="vertical" isDropDisabled={false}>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <Actions>
          <Popconfirm
            title={`${t('Remove')}?`}
            onClick={stopEventPropagation}
            onConfirm={removeLocation}
            placement="bottomRight"
            okText={t('Yes')}
            cancelText={t('No')}>
            <Tooltip title={t('Remove')} placement="left">
              <Button type="link" icon={<RiDeleteBinLine />} />
            </Tooltip>
          </Popconfirm>
          <Tooltip title={t('Location settings')} placement="bottom">
            <Button
              type="link"
              icon={<RiSettings5Fill />}
              onClick={onEditClick({ id, name: title, devices, operaFunctionSpaceCode })}
            />
          </Tooltip>
          <Tooltip title={t('Add event')} placement="bottom">
            <Button type="link" icon={<RiAddCircleLine />} onClick={onAddEventClick(id, occupiedTime)} />
          </Tooltip>
        </Actions>
        {!isEmpty && (
          <Events>
            {events.map(
              (
                {
                  title: eventTitle,
                  description,
                  start,
                  end,
                  id: eventId,
                  logo,
                  offsetForShowingBefore,
                  offsetForShowingAfter,
                  templateId,
                },
                dIndex,
              ) => {
                const {
                  id: logoId,
                  url: logoUrl,
                  title: logoTitle,
                } = logo ?? { id: undefined, url: undefined, title: '' };

                return (
                  <DraggableItem key={eventId} draggableId={eventId!} index={dIndex}>
                    <EventCard
                      id={eventId as string}
                      title={eventTitle as string}
                      description={description}
                      start={start as TimeInstance}
                      end={end as TimeInstance}
                      logoUrl={logoUrl}
                      logoId={logoId}
                      logoTitle={logoTitle}
                      locationId={id}
                      templateId={templateId}
                      occupiedTime={occupiedTime}
                      offsetForShowingBefore={offsetForShowingBefore}
                      offsetForShowingAfter={offsetForShowingAfter}
                    />
                  </DraggableItem>
                );
              },
            )}
          </Events>
        )}
      </DroppableArea>
    </Card>
  );
};

export default memo(LocationCard);
