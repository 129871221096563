/* eslint-disable react/prop-types */
import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Modal from 'src/components/ui-kit/modal-popup';
import Button from 'src/components/ui-kit/buttons';
import type { EventTransitionModalProps } from './events';

const Text = styled.p`
  margin: 1.25rem 0 0;
  padding: 0;
  font-size: 1.125rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 3rem;

  & > .ant-btn {
    flex-basis: calc(100% / 3 - 20px / 3);
  }
`;

export const EventTransitionModal: FC<EventTransitionModalProps> = ({ isVisible, handleTransition }) => {
  const { t } = useTranslation();

  const cancel = useCallback(() => handleTransition('cancel'), [handleTransition]);
  const move = useCallback(() => handleTransition('move'), [handleTransition]);
  const copy = useCallback(() => handleTransition('copy'), [handleTransition]);

  return (
    <Modal isVisible={isVisible} onCancel={cancel} title={t('Transfer event')} closeOnBgClick={false}>
      <Text>{t('Move event or make a copy?')}</Text>
      <Actions>
        <Button type="default" onClick={cancel}>
          {t('Cancel')}
        </Button>
        <Button onClick={copy}>{t('Copy')}</Button>
        <Button onClick={move}>{t('Move')}</Button>
      </Actions>
    </Modal>
  );
};

export default memo(EventTransitionModal);
