import React, { FC, memo, useRef, useCallback, useEffect, useState } from 'react';
import { InputNumber, InputNumberProps } from 'src/components/ui-kit/input';
import styled from 'styled-components';

export interface PlaylistItemDurationProps extends InputNumberProps {}

const PlaylistItemDurationInput = styled(InputNumber).attrs({ type: 'number', min: 1, max: 999 })`
  &.ant-input-number {
    background: #000;

    border: 0;
    outline: none;
    line-height: 2;
    font-size: 0.95rem;
    text-align: center;
    border-radius: 0.5rem;
    //padding: 0 0.5rem;
    width: auto;
    display: inline-block;
    //max-width: 110px;
    //min-width: 40px;

    .ant-input-number-input {
      color: #fff;
    }
  }

  > .ant-input-number-input-wrap > .ant-input-number-input {
    height: auto;
    padding: 0 0.25rem 0 0.7rem;
  }

  input[readonly] {
    cursor: default;
  }
`;

const PlaylistItemDuration: FC<PlaylistItemDurationProps> = props => {
  const { value: initialValue = 0, onChange: parentOnChange, disabled = false } = props;
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<PlaylistItemDurationProps['value']>(initialValue || 0);
  const onStep = useCallback(
    (val: PlaylistItemDurationProps['value']) => {
      ref.current?.focus?.();
      setValue(val);
    },
    [ref, setValue],
  );
  const onChange = useCallback(() => {
    if (typeof parentOnChange === 'function') {
      parentOnChange(value ?? 0);
    }
  }, [value, parentOnChange]);
  //
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <PlaylistItemDurationInput
      style={{ maxWidth: `${`${value}`.length * 10 + 40}px` }}
      ref={ref}
      value={value}
      onChange={setValue}
      onStep={onStep}
      onPressEnter={onChange}
      onBlur={onChange}
      readOnly={disabled}
    />
  );
};

export default memo(PlaylistItemDuration);
