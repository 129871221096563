import React, { memo, useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logger from 'src/helpers/logger';
import { Airport, useGetAirportsQuery } from 'src/graphql/generated';

import Select, { Option, SelectProps } from 'src/components/ui-kit/select';
import searchBgIcon from 'static/images/proportions.svg';

export const AirportSelectorElement = styled(Select)`
  min-width: 10rem;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.5rem;
    height: 2.25rem;
  }

  .ant-select-selector {
    &:before {
      content: '';
      display: block;
      margin-right: 10px;
    }
  }

  &.ant-select-show-search {
    &.ant-select-single .ant-select-selector {
      background-color: #ffffff;
      border-color: #d9d9d9;

      &:before {
        background: url(${searchBgIcon}) no-repeat;
      }
    }
    &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #d9d9d9;
    }

    &.ant-select .ant-select-selector > .ant-select-selection-item {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: auto;
      max-width: 12rem;
    }
  }
`;

export type AirportSelectorProps = SelectProps & {
  value?: string;
  disabled?: boolean;
  onChange?: (_v: string, _d: Airport) => void;
};

const AirportSelector: React.FC<AirportSelectorProps> = props => {
  const { t } = useTranslation();
  const {
    // Get value provided by parent component (if any)
    value,
    // Handle default react input props to be able to disable selectbox and/or set custom styles
    style,
    className,
    disabled,
    onBlur,
  } = props;
  const [selectedAirport, setAirportId] = useState(value);
  //
  const { data: queryResult } = useGetAirportsQuery({ fetchPolicy: 'cache-and-network' });
  const airports = queryResult?.getAirports || [];

  // if 'value' provided by parent component has been changed
  // we should update value stored inside inner state of the AirportSelector
  useEffect(() => {
    if (value !== selectedAirport) {
      setAirportId(value);
    }
  }, [value]);

  // Handle 'onChange' event and bypass it to 'onChange' handler from props (if any)
  // if not parent 'onChange' handler provided, just save new value to the inner state of the component
  const onChange = useCallback(
    v => {
      logger('AirportSelector.onChange', v);
      //
      if (typeof props.onChange === 'function') {
        const airport = airports.find(d => d.id === v) as Airport;
        props.onChange(v, airport);
      } else {
        setAirportId(v);
      }
    },
    [selectedAirport, props.onChange, airports],
  );

  return (
    <AirportSelectorElement
      disabled={disabled}
      style={style}
      className={className}
      value={selectedAirport}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={t('Select an airport')}
      showSearch
      filterOption={(input: string, option) =>
        (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }>
      {airports.map(airport => (
        <Option key={airport.id} value={airport.id}>
          {t(airport.name!)}
        </Option>
      ))}
    </AirportSelectorElement>
  );
};

AirportSelector.displayName = 'AirportSelector';

export default memo(AirportSelector);
