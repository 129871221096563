/* eslint-disable import/no-cycle */
import React, { memo, useContext } from 'react';
import orderBy from 'lodash/orderBy';
import * as Types from '../types/main';
import { getStyleObjectFromString } from '../utils/string';
import { TemplateContext, SettingsContext } from '../context';
import { TemplateAreaComponent } from './template-area';

interface TemplateComponentProps {
  data: Types.TemplateType;
}

export const TemplateComponent: React.FC<TemplateComponentProps> = memo(props => {
  const { data } = props;
  const { bodyStyle = {} } = data;
  const settings = useContext(SettingsContext);
  const { orientation } = settings || {};
  const defaultStyle = {
    minHeight: orientation === 90 || orientation === 270 ? '100vw' : '100vh',
    position: 'relative',
    backgroundColor: '#e5e5e5',
    fontSize: '64px',
    width: '100%',
    height: '100%',
  };

  Object.assign(defaultStyle, bodyStyle);

  if (data?.title) {
    const style = {
      fontSize: '32px',
      color: '#000',
    };
    Object.assign(defaultStyle, style);
  }

  return (
    <TemplateContext.Provider value={data}>
      <div className="template-wrapper" style={getStyleObjectFromString(defaultStyle)}>
        {orderBy(data.areas, 'sequence', 'asc').map(area => (
          <TemplateAreaComponent key={area.id} data={area} />
        ))}
      </div>
    </TemplateContext.Provider>
  );
});

TemplateComponent.displayName = 'TemplateComponent';

export default TemplateComponent;
