import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { IDType } from 'src/store/prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from '@fjedi/react-router-helpers';
// GraphQL Queries
import { useQuery, useMutation, logger, useApolloError } from '@fjedi/graphql-react-components';
import getScheduleQuery from 'src/graphql/queries/get-schedule.graphql';
import updateScheduleMutation from 'src/graphql/mutations/update-schedule.graphql';
//
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ContentCard } from 'src/components/ui-kit/admin-layout/content-card';
import MediaThumbnail, { ThumbnailCard } from 'src/components/ui-kit/thumbnail/media-thumbnail';
import { FixedScrollbar } from 'src/components/ui-kit/scrollbar';
import UploadIcon from 'static/images/photo-re.svg';

const Content = styled(ContentCard)`
  &.ant-card {
    height: 100%;
    /* max-width: 100%; */
    > .ant-card-body {
      display: flex;
      flex-direction: column;
      padding: 0;

      > div:first-child {
        box-shadow: none;
        margin: 0 0 0.5rem;
        padding: 0;
        min-height: 330px;
      }
    }
  }
`;

const DropArea = styled.div`
  flex-grow: 1;
  cursor: pointer;
  border: 2px dashed transparent;

  background: #ffffff url(${UploadIcon}) no-repeat center;
  background-position: 57% 40%;
  background-size: 15.25rem auto;
  box-sizing: border-box;
  border-radius: 1.4rem;
  width: 100%;
  max-width: 100%;
  display: inline-block;
  transition: all 0.4s;
  z-index: 2;
  position: relative;

  &:empty {
    border-color: ${colorGrays.gray300};

    &:hover {
      border-color: ${colorTheme.primary};
    }
  }

  ${ThumbnailCard} {
    .ant-image {
      width: auto;
      height: auto;
    }
  }
`;

const DefaultMediaItem = ({ draggedItem }) => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const { scheduleId } = useParams();
  const variables = useMemo(() => ({ id: scheduleId }), [scheduleId]);
  const { data: scheduleRes } = useQuery(getScheduleQuery, {
    variables,
  });
  // const subscriptionProps = useMemo(
  //   () => ({
  //     dataType: 'Schedule',
  //     subscriptionQueries: [
  //       //
  //       // scheduleChangedSubscription,
  //     ],
  //     subscribeToMore,
  //     variables,
  //   }),
  //   [variables],
  // );
  // useSubscribeToMore(subscriptionProps);
  const [updateSchedule] = useMutation(updateScheduleMutation, {
    onError,
  });
  logger('DefaultMediaItem.render', { draggedItem });
  const onDropItem = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      logger('DefaultMediaItem.onDropItem', { draggedItem });

      updateSchedule({
        variables: {
          id: scheduleId,
          input: {
            defaultMediaItemId: draggedItem.id,
          },
        },
      });
    },
    [draggedItem, scheduleId, updateSchedule],
  );
  //
  const header = useMemo(
    () => ({
      title: t('Default content'),
      subtitle: t(
        'Drag-n-drop media item from the right menu to set default content that will be shown if no other daily/weekly/calendar content has been set',
      ),
      actions: [
        {
          icon: <RiDeleteBinLine />,
          onConfirm() {
            updateSchedule({
              variables: {
                id: scheduleId,
                input: {
                  defaultMediaItemId: null,
                },
              },
            });
          },
        },
      ],
    }),
    [t, scheduleId, updateSchedule],
  );
  const mediaItem = scheduleRes?.getSchedule?.defaultMediaItem;
  //
  return (
    <FixedScrollbar>
      <Content header={header}>
        <DropArea onDragOver={onDropItem}>
          {mediaItem ? <MediaThumbnail isRemovable={false} width={800} data={mediaItem} /> : null}
        </DropArea>
      </Content>
    </FixedScrollbar>
  );
};

DefaultMediaItem.propTypes = { draggedItem: PropTypes.shape({ id: IDType }) };
DefaultMediaItem.defaultProps = { draggedItem: undefined };
DefaultMediaItem.displayName = 'DefaultMediaItem';

export default memo(DefaultMediaItem);
