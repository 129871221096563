/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useCallback, useMemo, useContext, Fragment, createElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import omit from 'lodash/omit';
import { Rnd } from 'react-rnd';
import HLSPlayer from 'react-hls-player';
import YoutubePlayer from 'react-player/youtube';
import logger from 'src/helpers/logger';
import { compareIds, formatPropForReact, stopEventPropagation } from 'src/functions';
import { colorTheme } from 'src/components/ui-kit/theme';
import { ContextMenuTrigger } from 'src/components/ui-kit/context-menu';
import MediaThumbnail, { ThumbnailCard } from 'src/components/ui-kit/thumbnail/media-thumbnail';
import { EditorContext, EditorSizeContext } from './context';
import { getRatioStyle } from './helpers';
import { Multicast } from './widgets/multicast';
import DateTime from './widgets/datetime';
import Heading from './widgets/events/heading';
import EventsWidget from './widgets/events/events';
import { Weather } from './widgets/weather/weather';
import Clock from './widgets/clock';
import { TEMPLATE_TYPES } from './constants';
import WayfindingWidget from './widgets/events/wayfinding';
import FlightWidget from './widgets/flights';

const Control = styled.span`
  position: absolute;
  display: block;
  width: 0.375rem;
  height: 0.375rem;
  background-color: #fff;
  opacity: 0;
  transition: opacity 0.4s;
  z-index: 100;

  &.top {
    top: calc(100% - 0.15rem);
  }

  &.left {
    left: calc(100% - 0.15rem);
  }

  &.right {
    right: calc(100% - 0.15rem);
  }

  &.bottom {
    bottom: calc(100% - 0.15rem);
  }
`;

const Area = styled(Rnd)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  /* border: 2px solid transparent; */
  box-sizing: border-box;
  transition: border-color 0.4s;

  &.selected {
    &:before {
      content: ' ';
      z-index: 9999;
      border: 6px solid ${colorTheme.primary};
      width: 100%;
      height: 100%;
      position: absolute;
    }

    ${Control} {
      opacity: 1;
    }
  }

  ${ThumbnailCard} {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
    pointer-events: none;

    height: inherit;
    background-color: inherit;

    .ant-image {
      width: auto;
      height: inherit;
      max-width: 100%;
      max-height: 100%;
    }

    > video {
      /* height: auto; */
      max-height: 100%;
    }
  }

  .react-contextmenu-wrapper,
  .template-area-content {
    /* pointer-events: none; */
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    user-select: none;
    width: 100%;
    height: 100%;

    > h1 {
      width: 100%;
    }

    &.template-area-content {
      overflow: hidden;
    }

    .ant-spin-nested-loading {
      width: 100%;
      height: 100%;

      > .ant-spin-container {
        height: 100%;
      }
    }

    > div {
      flex-grow: 1;
    }
  }
`;

const sizeAndPositionProps = ['width', 'height', 'top', 'left'];

const TemplateArea = ({ id }) => {
  const { onChange, unsavedChanges, ratio, selectedArea, setSelectedArea, gridConfig } = useContext(EditorContext);
  const { scale } = useContext(EditorSizeContext);
  const isSelected = compareIds(selectedArea, id);
  //
  const area = useMemo(() => unsavedChanges?.areas?.find(a => compareIds(a.id, id)) || {}, [unsavedChanges, id]);
  const { title, item, deletedAt, playlist } = area;
  const type = item?.type || (playlist ? TEMPLATE_TYPES.PLAYLIST : 'TEXT');
  const url = item?.url || null;
  const ratioId = ratio?.id;
  const ratioStyle = getRatioStyle(area, ratioId);
  const { width, height, top, left, position: _pp, ...visualStyle } = ratioStyle;
  //
  const reactFormattedStyle = formatPropForReact({
    ...omit(ratioStyle, sizeAndPositionProps),
    opacity: deletedAt ? 0.5 : 1,
  });
  //
  const size = useMemo(() => ({ width, height }), [width, height]);
  const position = useMemo(() => ({ x: left || 0, y: top || 0 }), [top, left]);

  const { backgroundOpacity, borderRadius, backgroundColor } = reactFormattedStyle;

  reactFormattedStyle.backgroundOpacity = `${backgroundOpacity ?? 0}%`;
  reactFormattedStyle.borderRadius = `${borderRadius ?? 0}${/[0-9]+$/.test(String(borderRadius)) ? 'px' : ''}`;
  reactFormattedStyle.backgroundColor = `${backgroundColor ?? 'transparent'}`;

  const initialStyles = useMemo(
    () => ({
      ...omit(reactFormattedStyle, [
        'overflow',
        'position',
        ...(reactFormattedStyle.backgroundEnabled ? [] : ['backgroundColor', 'borderRadius', 'backgroundOpacity']),
      ]),
      width: '100%',
    }),
    [reactFormattedStyle],
  );

  const content = useMemo(() => {
    switch (type) {
      case TEMPLATE_TYPES.PLAYLIST:
      case TEMPLATE_TYPES.IMAGE:
      case TEMPLATE_TYPES.VIDEO:
        return (
          <MediaThumbnail
            data={item ?? playlist}
            width={parseInt(width, 10)}
            objectFit={reactFormattedStyle.objectFit ? reactFormattedStyle.objectFit : 'cover'}
            isRemovable={false}
            showFooter={false}
          />
        );
      case TEMPLATE_TYPES.IFRAME:
        return (
          <iframe
            src={url}
            style={{ ...omit(reactFormattedStyle, ['overflow', 'position']), pointerEvents: 'none' }}
            name={title}
            title={title}
            scrolling="no"
            frameBorder="0px"
            marginHeight="0px"
            marginWidth="0px"
            height={height}
            width={width}
          />
        );
      case TEMPLATE_TYPES.DATETIME:
        return <DateTime item={item} style={{ width, height, ...visualStyle }} />;
      case TEMPLATE_TYPES.CLOCK:
        return <Clock item={item} style={{ width, height, ...visualStyle }} />;
      case TEMPLATE_TYPES.MULTICAST:
        return <Multicast item={item} style={{ width, height, ...visualStyle }} />;
      case TEMPLATE_TYPES.WEATHER:
        return <Weather item={item} style={{ width, height, ...visualStyle }} />;
      case TEMPLATE_TYPES.HLS:
        return <HLSPlayer src={url} autoPlay={false} controls style={{ width, height, ...visualStyle }} />;
      case TEMPLATE_TYPES.YOUTUBE:
        return (
          <YoutubePlayer
            light
            url={item.props?.originalURL || url}
            width={width}
            height={height}
            style={{
              width,
              height,
              pointerEvents: 'none',
              ...visualStyle,
            }}
          />
        );
      case TEMPLATE_TYPES.LOCATION_EVENT_LOGO:
        return url ? (
          <MediaThumbnail
            data={item || playlist}
            width={parseInt(width, 10)}
            objectFit={reactFormattedStyle.objectFit ? reactFormattedStyle.objectFit : 'cover'}
            isRemovable={false}
            showFooter={false}
            style={omit(initialStyles, ['backgroundColor'])}
          />
        ) : (
          <Heading data={item} style={initialStyles} />
        );
      case TEMPLATE_TYPES.LOCATION_HEADER:
        return <Heading data={item || playlist} style={initialStyles} />;
      case TEMPLATE_TYPES.LOCATION_EVENTS:
        return <EventsWidget {...item.props} style={initialStyles} />;
      case TEMPLATE_TYPES.LOCATION_WAYFINDING:
        return <WayfindingWidget {...item} style={initialStyles} />;
      case TEMPLATE_TYPES.FLIGHTS:
        return <FlightWidget {...item} style={initialStyles} />;
      default: {
        const strings = title.replace(/\n/g, '[[[linebreak]]]').split('[[[linebreak]]]');
        const textContent = strings.map((s, sIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={sIndex}>
            {s}
            {sIndex !== strings.length - 1 ? createElement('br') : null}
          </Fragment>
        ));
        return (
          <div style={{ ...initialStyles, display: 'flex', alignItems: 'center' }}>
            <h1 style={omit(initialStyles, ['backgroundColor', 'backgroundOpacity', 'border-radius'])}>
              {textContent}
            </h1>
          </div>
        );
      }
    }
  }, [type, url, width, height, title, playlist, item, reactFormattedStyle, initialStyles, visualStyle]);
  //
  const onDragStart = useCallback(() => {
    setSelectedArea(id);
  }, [id, setSelectedArea]);
  const onDragStop = useCallback(
    (e, d) => {
      let y = Math.floor(d.y);
      let x = Math.floor(d.x);
      if (gridConfig.currentStyle.snap) {
        let gridSize = gridConfig.currentStyle.size;
        if (gridSize == null) {
          gridSize = 200;
        }

        y = Math.floor(d.y / gridSize) * gridSize;
        x = Math.floor(d.x / gridSize) * gridSize;
        const xW = Math.floor((d.x + width) / gridSize) * gridSize;
        const yH = Math.floor((d.y + height) / gridSize) * gridSize;
        const snapThreshold = gridSize / 3;
        const nextSnapThreshold = gridSize / 3 + gridSize;

        let calculatedX = d.x;
        let calculatedY = d.y;

        if (d.y - y < snapThreshold) {
          calculatedY = y;
        } else if (d.y + height - yH < snapThreshold) {
          calculatedY = yH - height;
        } else if (d.y + height - yH < nextSnapThreshold) {
          calculatedY = yH - height + gridSize;
        }

        if (d.x - x < snapThreshold) {
          calculatedX = x;
        } else if (d.x + width - xW < snapThreshold) {
          calculatedX = xW - width;
        } else if (d.x + width - xW < nextSnapThreshold) {
          calculatedX = xW - width + gridSize;
        }

        y = Math.floor(calculatedY);
        x = Math.floor(calculatedX);
      }

      onChange(id, { style: { [ratioId]: { overflow: 'hidden', position: 'absolute', top: y, left: x } } });
    },
    [id, onChange, ratioId, gridConfig, height, width],
  );
  const onResizeStop = useCallback(
    (e, direction, ref, delta, p) => {
      //
      const u = {
        position: 'absolute',
        overflow: 'hidden',
        width: ref.style.width,
        height: ref.style.height,
        top: p.y,
        left: p.x,
      };
      onChange(id, { style: { [ratioId]: u } });
    },
    [id, onChange, ratioId],
  );
  const onClick = useCallback(
    event => {
      logger('TemplateArea.onClick', event.target.id);
      stopEventPropagation(event);
      setSelectedArea(id);
    },
    [id, setSelectedArea],
  );
  //
  return (
    <Area
      key={id}
      id={id}
      className={isSelected ? 'selected' : ''}
      style={omit(reactFormattedStyle, ['overflow', 'backgroundColor', 'borderRadius', 'paddingTop'])}
      bounds="parent"
      scale={scale ?? 1}
      size={size}
      position={position}
      onClick={onClick}
      onDragStart={onDragStart}
      onDragStop={onDragStop}
      onResizeStop={onResizeStop}>
      <Control className="top left" />
      <Control className="top right" />
      <Control className="bottom right" />
      <Control className="bottom left" />
      <ContextMenuTrigger id={id} holdToDisplay={-1}>
        <div className="template-area-content">{content}</div>
      </ContextMenuTrigger>
    </Area>
  );
};

TemplateArea.propTypes = {
  id: PropTypes.string.isRequired,
};
TemplateArea.defaultProps = {};
TemplateArea.displayName = 'TemplateArea';

export default memo(TemplateArea);
