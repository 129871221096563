/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { ChangeEventHandler, FC, memo, useCallback, useEffect, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { RiFilterLine, RiSearchLine } from 'react-icons/ri';
import styled from 'styled-components';
import { MediaFolderItem, MediaFolderType } from 'src/graphql/generated';
import logger from 'src/helpers/logger';
import ButtonModal from 'src/components/ui-kit/modal-popup/button-modal';
import CustomScrollbar from 'src/components/ui-kit/scrollbar';
import { Input } from 'src/components/ui-kit/input';
import {
  List as AsideList,
  Footer as AsideFooter,
  ListScrollContainer,
} from 'src/components/ui-kit/aside/tabs/components';

export const List = AsideList as FC<Parameters<typeof AsideList>[0]>;
export const Footer = AsideFooter as FC<Parameters<typeof AsideFooter>[0]>;
export const Scrollbar = CustomScrollbar as FC<Parameters<typeof CustomScrollbar>[0]>;
export const Modal = styled(ButtonModal as FC<Partial<Parameters<typeof ButtonModal>[0]>>)`
  .modal-content {
    align-self: flex-start;
    margin-top: 2rem;
  }

  .modal-popup__body {
    padding: 0;
  }

  .ant-collapse-borderless > .ant-collapse-item:last-child {
    border-bottom-color: transparent;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-content .ant-collapse-content-box {
    padding: 1.125rem 1.6875rem;
    display: flex;
    flex-wrap: wrap;
  }

  .logos-scrollbar > div > .ant-spin-nested-loading,
  .templates-scrollbar > div > .ant-spin-nested-loading {
    min-height: 200px;
    padding: 0;
    max-width: 100%;
    flex: auto;
  }

  .ant-collapse .ant-spin-nested-loading {
    flex: 1 0 50%;
    max-width: 50%;
    padding: 0.3125rem;
  }

  .thumbnail-card {
    border-radius: 0.625rem;
  }

  ${ListScrollContainer} {
    min-height: 100%;
  }
`;

export const SearchWrapper = styled.div`
  padding: 1.25rem 2rem;
  border-bottom: 1px solid #d9d9d9;
`;

export const Search = styled(Input)`
  &.ant-input-affix-wrapper {
    height: 2.25rem;
    min-height: 2.25rem;
    width: auto;
    display: flex;
  }
`;

export const Body = styled.div`
  max-height: calc(100vh - 15.5rem) !important;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 18rem;

  & > .logos-scrollbar > div:first-child {
    margin-bottom: 0 !important;
  }
`;

export const FooterContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);

  .ant-btn {
    height: 3.125rem;
    font-size: 0.875rem;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    + .ant-btn {
      &:after {
        content: '';
        position: absolute;
        left: -0.0625rem;
        width: 0.0625rem;
        height: 1.625rem;
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }
`;

export type MediaItemListModalProps = {
  contentType: MediaFolderType;
  title: string;
  className?: string;
  okButtonText: string;
  buttonType?: 'link' | 'primary';
  buttonSize?: 'small' | 'medium' | 'large';
  onSelectItem?: (_item: MediaFolderItem, _event: MouseEvent<Element, MouseEvent>) => void;
  onOpen?: () => void;
  onClose?: () => void;
  isOpened?: boolean;
};

export const MediaItemListModal: FC<MediaItemListModalProps> = ({
  contentType,
  onSelectItem,
  okButtonText,
  title,
  className,
  buttonType = 'primary',
  buttonSize = 'medium',
  onOpen,
  onClose,
  isOpened,
  ...props
}) => {
  const [modalIsVisible, setModalVisibility] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const { t } = useTranslation();

  const openModal = useCallback(() => {
    if (typeof onOpen === 'function') {
      onOpen();
    } else {
      setModalVisibility(true);
    }
  }, [onOpen]);

  const closeModal = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose();
    } else {
      setModalVisibility(false);
    }
  }, [onClose]);

  useEffect(() => {
    if (typeof isOpened !== 'boolean') {
      return;
    }
    setModalVisibility(isOpened);
  }, [isOpened]);

  const onFilterChange = useCallback<ChangeEventHandler<HTMLInputElement>>(event => {
    setFilterValue(event.target.value);
  }, []);

  const onItemClick = useCallback(
    (item: MediaFolderItem, event: MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation?.();
      event.preventDefault?.();

      logger('MediaItemListModal.onItemClick', {
        item,
        event,
      });

      if (typeof onSelectItem === 'function') {
        onSelectItem(item, event);
      }

      closeModal();
    },
    [onSelectItem, closeModal],
  );

  return (
    <Modal
      isVisible={modalIsVisible}
      destroyContentOnRemount
      title={t(title)}
      buttonType={buttonType}
      buttonSize={buttonSize}
      buttonText={t(`${okButtonText}`)}
      beforeOpen={openModal}
      beforeClose={closeModal}
      zIndex={modalIsVisible ? 502 : -1}
      {...props}>
      <SearchWrapper>
        <Search
          onChange={onFilterChange}
          placeholder={t('Search')}
          prefix={<RiSearchLine />}
          suffix={<RiFilterLine />}
        />
      </SearchWrapper>

      <Body>
        <Scrollbar className={className}>
          <List
            filterValue={filterValue}
            contentType={contentType}
            onItemClick={onItemClick}
            listType="preview"
            isEditable={false}
          />
        </Scrollbar>
      </Body>
      <FooterContainer>
        <Footer contentType={contentType} />
      </FooterContainer>
    </Modal>
  );
};

export default memo(MediaItemListModal);
