import React, { memo, useEffect, useCallback, FC } from 'react';
import time, { formatDate } from '../utils/time';
//
const h1Style = {
  color: 'inherit',
  fontSize: 'inherit',
  background: 'transparent',
};
//
type DateTimeProps = {
  item?: {
    props?: {
      format: string;
      timezone: string;
    };
    [key: string]: any;
  };
};

export const DateTime: FC<DateTimeProps> = ({ item }) => {
  const [date, setDate] = React.useState(time());
  const timezone = item?.props?.timezone;
  const format = item?.props?.format || 'DD.MM.YYYY';

  const tick = useCallback(() => {
    if (timezone) {
      setDate(time().tz(timezone));
    } else {
      setDate(time());
    }
  }, [timezone, setDate]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, [tick]);

  return <h1 style={h1Style}>{formatDate(date, format)}</h1>;
};

export default memo(DateTime) as typeof DateTime;
