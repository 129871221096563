import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Scrollbars } from 'react-custom-scrollbars';

function renderThumbHorizontal() {
  return <div />;
}

export const StyledScrollbar = styled(Scrollbars)`
  flex-grow: 1;
  // <Not sure if this styles is required o_O>
  display: flex;
  flex-direction: column;
  // </Not sure if this styles is required o_O>

  > div:first-child {
    overflow-y: scroll;
    overflow-x: hidden !important;
    margin-bottom: 0 !important;
  }
  &.overflowXscroll {
    > div:first-child {
      overflow-x: scroll !important;
      padding-bottom: 0;
    }
    > div:nth-child(2) {
      > div {
        height: 100%;
        position: relative;
        display: block;
        cursor: pointer;
        border-radius: inherit;
        background-color: rgba(0, 0, 0, 0.2);
        transform: translateY(261px);
      }
    }
  }
`;

export const Scrollbar = (props: FixedScrollbarProps['scrollbarProps']) => <StyledScrollbar {...props} />;

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  autoHeight: PropTypes.bool,
  autoHeightMin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autoHeightMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  universal: PropTypes.bool,
  // This will activate auto hide
  autoHide: PropTypes.bool,
  // Hide delay in ms
  autoHideTimeout: PropTypes.number,
  thumbMinSize: PropTypes.number,
  renderThumbHorizontal: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};
Scrollbar.defaultProps = {
  className: '',
  style: { height: '100%', paddingBottom: '0' },
  autoHeight: false,
  autoHeightMin: '80%',
  autoHeightMax: '100%',
  universal: true,
  // This will activate auto hide
  autoHide: true,
  // Hide delay in ms
  autoHideTimeout: 1000,
  thumbMinSize: 100,
  renderThumbHorizontal,
};

export const FixScrollbarContainer = styled.div`
  overflow: hidden;
  margin-right: -1.5rem;
  margin-left: -1rem;

  display: flex;
  flex-direction: column;
`;

export const FixScrollbar = styled(Scrollbar)`
  flex-grow: 1;

  > div:first-child {
    padding-left: 1rem;
    padding-bottom: 0;
    min-height: 100% !important;
    max-height: 100% !important;
  }
`;

export const FixScrollbarChild = styled.div`
  padding-right: 1.5rem;
  position: relative;
  height: 100%;

  > div:last-child > div {
    margin-bottom: 0;
  }
`;

export type ScrollContainerUpdateEvent = {
  clientHeight: number;
  clientWidth: number;
  left: number;
  top: number;
  scrollWidth: number;
  scrollHeight: number;
  scrollTop: number;
};

export type FixedScrollbarProps = {
  children?: React.ReactNode;
  className?: string;
  scrollbarProps?: {
    children?: React.ReactNode;
    onScrollFrame?: (_ev: { top: number }) => unknown;
    onUpdate?: (_ev: ScrollContainerUpdateEvent) => void;
    style?: CSSStyleDeclaration;
    className?: string;
  };
};

export const FixedScrollbar = memo<FixedScrollbarProps>(props => {
  const { className, children, scrollbarProps } = props;
  return (
    <FixScrollbarContainer className={className}>
      <FixScrollbar {...scrollbarProps}>
        <FixScrollbarChild>{children}</FixScrollbarChild>
      </FixScrollbar>
    </FixScrollbarContainer>
  );
});
FixedScrollbar.displayName = 'FixedScrollbar';

export default Scrollbar;
