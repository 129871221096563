import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const StubWrap = styled.div`
  background: radial-gradient(50% 146.94% at 50% 50%, #6473c4 0%, #333333 100%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #ffffff;
  flex-direction: column;

  svg {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }

  &:before {
    content: '';
    background: linear-gradient(180deg, rgba(51, 51, 51, 0) 68.21%, #333333 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  + .thumbnail-body {
    background-color: transparent;
  }
`;

const Stub = () => {
  const { t } = useTranslation();
  return (
    <StubWrap>
      <LoadingOutlined />
      {t('Converting file...')}
    </StubWrap>
  );
};

export default Stub;
