/* eslint-disable import/no-cycle */
import React, { memo, useMemo, useState } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import useFetch from '../../hooks/use-fetch'
import * as Types from '../../types/main'
import time from '../../utils/time'

import MediaItem from '../../components/media-item'
import Wayfinding from './wayfinding'
import EventsComponent from './events'

interface EventsProps {
  data: Types.MediaItem
}

const Separator = styled.hr<{ size: string; color: string }>`
  grid-row: 2 / auto;
  width: 80%;
  height: ${(props) => props.size}px;
  border-color: ${(props) => props.color};
  background-color: ${(props) => props.color};
`

export type ActiveEvent = {
  activeEvent?: Types.EventType
  nextEvent?: Types.EventType
}

function getActiveEvent(events: Array<Types.EventType>): ActiveEvent {
  if (!Array.isArray(events) || events.length === 0) {
    return {}
  }
  const result: ActiveEvent = {}
  const currentTime = time()

  orderBy(events, (event) => time(event.start).unix(), ['asc']).forEach((event) => {
    const timeStart = time(event.start).local()
    const timeStartHour = timeStart.hour()
    const timeStartMinute = timeStart.minute()
    const eventStart = currentTime.clone().hour(timeStartHour).minute(timeStartMinute).second(0)

    const timeEnd = time(event.end).local()
    const timeEndHour = timeEnd.hour()
    const timeEndMinute = timeEnd.minute()
    const eventEnd = currentTime.clone().hour(timeEndHour).minute(timeEndMinute).second(0)

    if (!result.activeEvent && currentTime.isBetween(eventStart, eventEnd)) result.activeEvent = event
    if (!result.nextEvent && currentTime.isBefore(eventStart)) result.nextEvent = event
  })

  return { ...result }
}

const Events: React.FC<EventsProps> = memo((props) => {
  const [token, setToken] = useState<string | null>(null)
  useMemo(() => {
    setToken(window.localStorage.getItem('token'))
  }, [setToken])
  //
  const headers = useMemo(
    () => ({
      'Content-Type': 'application/json',
      Authorization: token || '',
      'Apollo-Require-Preflight': 'true',
    }),
    [token]
  )
  const body = useMemo(
    () => ({
      query: `query getEvents($filter: EventFilter!) {
        getEvents(filter:$filter) {
          __typename
          count
          rows {
            __typename
            id
            title
            description
            start
            end
            locationId
            logo {
                __typename
                id
                url
                type
                props
            }
            location {
              name
            }
          }
        }
      }`,
      operationName: 'getEvents',
      variables: {
        filter: {
          startAt: { from: time().startOf('day').toISOString(), to: time().add(10, 'day').toISOString() },
        },
      },
    }),
    []
  )

  const url = new URL(`https://${process.env.API_HOST}/api`)
  const { data } = useFetch<any>(url.href, {
    headers,
    method: 'POST',
    data: body,
  })

  const eventsList: Array<Types.EventType> = get(data, 'data.getEvents.rows', [])
  const eventsProps = get(props, 'data.props', {})

  const sortEventsList = eventsList.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
  const { activeEvent, nextEvent } = getActiveEvent(sortEventsList) || {}
  const { separatorColor = '#fff', separatorSize = '1', separatorEnabled = true, nextEvent: showNext = false } = eventsProps || {}
  // logger('props?.data?.type', { type: props?.data?.type, props });
  switch (props?.data?.type) {
    case Types.MediaItemType.LocationWayfinding: {
      return <Wayfinding eventsProps={eventsProps} />
    }
    case Types.MediaItemType.LocationEvents: {
      return (
        <>
          {activeEvent && <EventsComponent data={activeEvent} widgetProps={eventsProps} />}
          {showNext && nextEvent && (
            <>
              {separatorEnabled && <Separator color={separatorColor} size={separatorSize} />}
              <EventsComponent data={nextEvent} widgetProps={eventsProps} />
            </>
          )}
        </>
      )
    }
    case Types.MediaItemType.LocationHeader: {
      return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>{activeEvent?.location?.name}</div>
    }
    case Types.MediaItemType.LocationEventLogo: {
      return <MediaItem data={activeEvent?.logo} style={{ display: 'flex', height: 'inherit', alignItems: 'center' }} />
    }
    default:
      return <div>Events</div>
  }
})

Events.displayName = 'Events'
export default Events
