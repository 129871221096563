import React, { memo, FC } from 'react';
import { Link as ReactLink } from '@fjedi/react-router-helpers';
import omit from 'lodash/omit';
import styled from 'styled-components';
import { ButtonProps } from 'src/components/ui-kit/buttons/index';
//
const StyledA = styled.a``;

export type LinkProps = {
  to: ButtonProps['href'];
  children: React.ReactNode;
  forceSSR?: boolean;
  target?: ButtonProps['target'];
};

const Link: FC<LinkProps> = ({ to, children, forceSSR = false, ...props }) => {
  const ssr = props.target === '_blank' || forceSSR;

  if (ssr) {
    return (
      <StyledA {...props} href={to}>
        {children}
      </StyledA>
    );
  }

  return (
    <ReactLink {...omit(props, ['target', 'title'])} to={to!}>
      {children}
    </ReactLink>
  );
};

export default memo(Link);
