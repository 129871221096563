import React, { FC, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// GraphQL
import { AuthModalContext, useNavigate } from '@fjedi/react-router-helpers';
import { useApolloError } from '@fjedi/graphql-react-components';

import { Form, FormItem } from 'src/components/ui-kit/form';
import Link from 'src/components/ui-kit/buttons/link';
import Button, { StyledButton } from 'src/components/ui-kit/buttons';
import { Input, PasswordInput } from 'src/components/ui-kit/input';
import { useLogInMutation, ViewerCredentialsInput, ViewerDocument } from 'src/graphql/generated';

const PooledForm = styled.div`
  position: relative;
`;

export const StyledForm = styled(Form)`
  > ${PooledForm} > ${FormItem}, > ${FormItem} {
    ${StyledButton},
    ${PasswordInput},
    ${Input} {
      min-height: 2.75rem;
      font-size: 1rem;
    }

    ${StyledButton} {
      font-size: 1.1rem;
    }
  }
`;

const ForgotPassword = styled(Link)`
  color: #bdbdbd;
  position: absolute;
  right: 0;
  top: 0;
`;

export type LoginProps = {
  email?: string;
};

const Login: FC<LoginProps> = props => {
  const { setStatus: setAuthModalStatus } = useContext(AuthModalContext);
  const { email } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { resetFields } = form;
  const onError = useApolloError();

  const [login, { loading }] = useLogInMutation({
    onError,
    onCompleted() {
      resetFields();
      if (typeof setAuthModalStatus === 'function') {
        setAuthModalStatus(false);
      }
    },
    update(cache, { data }) {
      if (!data?.logIn) {
        return;
      }
      cache.writeQuery({
        query: ViewerDocument,
        data: { viewer: data.logIn },
      });
      navigate('/');
    },
  });
  const onSubmit = useCallback(
    (credentials: unknown) => {
      login({
        variables: {
          credentials: credentials as ViewerCredentialsInput,
        },
      });
    },
    [login],
  );

  return (
    <StyledForm
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      initialValues={{
        email,
      }}>
      <FormItem name="email" label={t('Login')} rules={[{ required: true, message: t('Please fill this field') }]}>
        <Input type="email" placeholder="Email" />
      </FormItem>

      <PooledForm>
        <FormItem
          name="password"
          label={t('Password')}
          rules={[{ required: true, message: t('Please fill this field') }]}>
          <PasswordInput placeholder={t('Password')} />
        </FormItem>
        <ForgotPassword to="/forgot-password">{t('Forgot password?')}</ForgotPassword>
      </PooledForm>

      <FormItem>
        <Button block loading={loading} type="primary" htmlType="submit">
          {t('Sign In')}
        </Button>
      </FormItem>
    </StyledForm>
  );
};

Login.displayName = 'Login';

export default memo(Login);
