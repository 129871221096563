import React, { useMemo, FC } from 'react';
import DisplayText from './components/display-text';
import { MediaItemComponent } from './components/media-item';
import Timeslot from './components/timeslot';
import { SettingsContext } from './context';
import { MediaItem, SettingsType, Timeslot as TimeslotType } from './types/main';

const SchedulePlayer: FC<
  SettingsType & { activeTimeslot: TimeslotType | null; defaultMediaItem?: MediaItem | null }
> = props => {
  const {
    ratio,
    deviceName,
    deviceType,
    orientation,
    invertVideoRotation,
    locationId,
    absTzOffset,
    resolution,
    activeTimeslot,
    defaultMediaItem,
  } = props;

  const settings = useMemo(
    () => ({
      deviceName,
      deviceType,
      orientation,
      invertVideoRotation,
      locationId,
      absTzOffset,
      resolution,
      ratio,
      defaultMediaItem,
    }),
    [props],
  );

  const component = useMemo(() => {
    if (activeTimeslot) {
      return <Timeslot data={activeTimeslot} />;
    }

    if (defaultMediaItem) {
      return <MediaItemComponent data={defaultMediaItem} loopVideo />;
    }

    return <DisplayText className="no-content">No content.</DisplayText>;
  }, [activeTimeslot, defaultMediaItem]);

  return <SettingsContext.Provider value={settings}>{component}</SettingsContext.Provider>;
};

SchedulePlayer.displayName = 'SchedulePlayer';
export default SchedulePlayer;
