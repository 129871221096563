/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { FC, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'src/components/ui-kit/tooltip';
import { stopEventPropagation } from 'src/functions';
import styled from 'styled-components';
import { RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';

import { colorTheme } from 'src/components/ui-kit/theme';
import CustomPopconfirm, { PopconfirmProps } from 'src/components/ui-kit/popconfirm';
import Button from 'src/components/ui-kit/buttons';
import { Image } from 'src/components/ui-kit/thumbnail';
import { formatDate } from 'src/helpers/time';
import { EventCardProps } from './events';
import { EventsContext } from './events-context';

const Popconfirm: FC<PopconfirmProps> = CustomPopconfirm;

const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 6.25rem auto;
  grid-template-rows: 1fr 1fr;
  gap: 0 0.625rem;
  justify-items: start;
  align-items: center;

  margin-bottom: 0.3125rem;
  padding: 0.625rem;
  background: hsl(0, 100%, 100%);
  border-radius: 0.625rem;

  .ant-image {
    grid-column: 2;
    grid-row: 1 / span 2;
    justify-self: end;

    height: 2.375rem;
    border-radius: 9px;
    overflow: hidden;

    & > .ant-image-img {
      height: 100%;
      object-fit: contain;
    }
  }
`;

const Title = styled.h4`
  grid-column: 1;
  grid-row: 1;

  color: ${colorTheme.dark};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SubTitle = styled.h5`
  grid-column: 1;
  grid-row: 2;

  color: ${colorTheme.secondary};
`;

const Actions = styled.div`
  grid-column: 3;
  grid-row: 1 / span 2;

  .ant-btn {
    color: ${colorTheme.secondary};
  }
`;

export const EventCard: FC<EventCardProps> = ({
  id,
  title,
  description,
  logoUrl,
  logoId,
  logoTitle,
  start,
  end,
  locationId,
  occupiedTime,
  offsetForShowingBefore,
  offsetForShowingAfter,
  templateId,
}) => {
  const { removeEvent, editEvent } = useContext(EventsContext);

  const { t } = useTranslation();

  const onRemove = useCallback(
    () =>
      removeEvent({
        variables: { id },
      }),
    [id, removeEvent],
  );

  const subTitle = `${formatDate(start, 'HH:mm')} - ${formatDate(end, 'HH:mm')}`;

  return (
    <Card>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      {logoUrl && <Image src={logoUrl} preview={false} />}
      <Actions>
        <Tooltip title={t('Edit')} placement="left" align={{ offset: [7.5, 0] }}>
          <Button
            type="link"
            icon={<RiEdit2Line />}
            onClick={editEvent({
              id,
              location: { id: locationId, occupiedTime },
              title,
              description,
              logoId,
              logoUrl,
              logoTitle,
              start,
              end,
              offsetForShowingAfter,
              offsetForShowingBefore,
              templateId,
            })}
          />
        </Tooltip>
        <Popconfirm
          title={`${t('Remove')}?`}
          onClick={stopEventPropagation}
          onConfirm={onRemove}
          placement="bottomRight"
          okText={t('Yes')}
          cancelText={t('No')}>
          <Tooltip title={t('Remove')} placement="topRight" align={{ offset: [0, 5] }}>
            <Button type="link" size="small" icon={<RiDeleteBinLine />} />
          </Tooltip>
        </Popconfirm>
      </Actions>
    </Card>
  );
};

export default memo(EventCard);
