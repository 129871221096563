import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import AuthBox from 'src/components/ui-kit/auth-box';
import Login from 'src/components/common/login';

const LoginPage = () => {
  const { t } = useTranslation();
  return (
    <AuthBox title={t('Sign In')} subTitle={t('Enter your credentials to proceed')}>
      <Login />
    </AuthBox>
  );
};

LoginPage.displayName = 'LoginPage';

export default memo(LoginPage);
