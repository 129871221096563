import React, { memo, useMemo, useEffect, useState, useCallback, useLayoutEffect } from 'react';
import orderBy from 'lodash/orderBy';
import * as Types from '../types/main';
import { getNextItem } from '../utils/carousel-item';
//
import { MediaItemComponent } from './media-item';
import { TemplateComponent } from './template';
import { PlaylistComponent } from './playlist';
import { DEFAULT_TIMESLOT_ITEM_DURATION, MIN_TIMESLOT_ITEM_DURATION } from '../constants';
import '../styles/main.css';

export interface TimeslotComponentProps {
  data: Types.Timeslot | null;
  loopVideo?: boolean;
  fullscreen?: boolean;
  style?: { [key: string]: string };
}

export const TimeslotComponent: React.FC<TimeslotComponentProps> = memo(props => {
  const { data } = props;
  const [activeItem, setActiveItem] = useState<Types.TimeslotItemType>();

  const timeslotItems = data?.items || [];

  const listMedia = useMemo(() => orderBy(timeslotItems, 'sequence', 'asc'), [timeslotItems]);
  const isLoop = listMedia.length === 1;

  const nextItemGetter = useMemo(() => getNextItem<Types.TimeslotItemType>(listMedia), [listMedia]);
  const onPlaybackEnd = useCallback(() => {
    const nextItem = nextItemGetter();
    setActiveItem(nextItem);
  }, [nextItemGetter]);

  const content = useMemo(() => {
    if (activeItem?.template) {
      return <TemplateComponent data={activeItem?.template} />;
    }
    if (activeItem?.playlist) {
      return <PlaylistComponent data={activeItem?.playlist} onPlaybackEnd={onPlaybackEnd} />;
    }
    if (activeItem?.mediaItem) {
      return <MediaItemComponent data={activeItem?.mediaItem} loopVideo={isLoop} onPlaybackEnd={onPlaybackEnd} />;
    }

    return null;
  }, [activeItem, onPlaybackEnd]);

  // For handling duration and changing of templates and media-items with type 'IMAGE'
  useLayoutEffect(() => {
    if (listMedia?.length === 0) {
      return () => {};
    }
    if (!activeItem) {
      setActiveItem(listMedia[0]);
      return () => {};
    }
    // !This useEffect must NOT handle video and playlists because their rotation must be handled by 'onPlaybackEnd' callback
    if (activeItem.mediaItem?.type === Types.MediaItemType.Video || activeItem?.playlist) {
      return () => {};
    }

    const timeoutId = setTimeout(() => {
      setActiveItem(nextItemGetter());
    }, Math.max(activeItem.duration * 1000 || DEFAULT_TIMESLOT_ITEM_DURATION, MIN_TIMESLOT_ITEM_DURATION));

    return () => clearTimeout(timeoutId);
  }, [listMedia, activeItem, setActiveItem]);

  useEffect(() => {
    setActiveItem(listMedia[0]);
  }, [data]);

  return content;
});
TimeslotComponent.displayName = 'TimeslotComponent';
TimeslotComponent.propTypes = {};
TimeslotComponent.defaultProps = {};

export default TimeslotComponent;
