import React, { memo, useMemo, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import useFetch from '../../hooks/use-fetch'
import chunk from 'lodash/chunk'
import useInterval from '../../hooks/use-interval'
import * as Types from '../../types/main'
import time, { formatDate } from '../../utils/time'

interface WayfindingProps {
  eventsProps: {
    separatorColor?: string
    separatorSize?: number
    eventTimeColor?: string
    eventTime?: boolean
    logo?: boolean
    onlyEvents?: boolean
    visibleRows?: number
    duration?: number
    separatorEnabled?: boolean
    eventLocationColor?: string
    eventTitleColor?: string
    eventLocation?: boolean
    eventLogo?: boolean
    paginationEnabled: boolean
    pagination: {
      fontColor?: string
      fontFamily: string
      fontSize: number
      horizontalAlignment: string
      interval: number
      verticalAlignment: string
    }
  }
}

type CustomEventType = {
  id: string
  icon: Types.MediaItemType
  name: string
  event: Types.EventType
}

const Container = styled.ul`
  /* display: flex; */
  width: 100%;
`

const Item = styled.li<{ separatorColor: string; separatorSize: number }>`
  display: grid;
  grid-template-columns: 8rem 1fr 8rem;
  /* grid-template-rows: repeat(2, 4rem); */
  gap: 0 1.25rem;
  padding: 1.25rem;
  align-items: center;

  & > img {
    max-height: 8rem;
    width: 8rem;

    text-align: center;
    line-height: 8rem;

    &.event-logo {
      grid-column: 1;
      grid-row: 1 / span 2;

      background-color: #313a46;
      color: #f1f3fa;
      font-weight: normal;
      font-size: 2rem;
      font-family: 'Lato';
    }
  }

  & > .wayfinding-icon {
    grid-column: 3;
    grid-row: 1 / span 2;
    justify-self: center;

    background-color: transparent;

    height: 6.25rem;
    width: 6.25rem;
    line-height: 8rem;
  }
  .wayfinding-location-name {
    font-size: 0.7em;

    & > hr {
      width: 0.5rem;
      margin: 0 0.625rem;
    }

    & > h3 {
      margin-left: 0.75rem;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
    }

    & > .event-time {
      display: flex;
      padding: 0;
      margin: 0;
    }
  }

  & + hr {
    width: 80%;
    height: ${({ separatorSize }) => separatorSize - 1}px;
    border-color: ${({ separatorColor }) => separatorColor};
    background-color: ${({ separatorColor }) => separatorColor};
  }
`

const Title = styled.span`
  color: ${({ color }) => color};
`

const Time = styled.span`
  color: ${({ color }) => color};
`

const Location = styled.span`
  color: ${({ color }) => color};
`

const Pagination = styled.div<{
  fontColor: string
  fontSize: number
  fontFamily: string
  verticalAlignment: string
  horizontalAlignment: string
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  grid-column: ${({ horizontalAlignment }) => {
    switch (horizontalAlignment) {
      case 'left':
        return 1
      case 'right':
        return 3
      case 'center':
      default:
        return 2
    }
  }};

  grid-row: ${({ verticalAlignment }) => {
    switch (verticalAlignment) {
      case 'top':
        return 1
      case 'center':
        return 2
      case 'bottom':
      default:
        return 3
    }
  }};
  color: ${({ fontColor }) => fontColor};
  font-size: ${({ fontSize }) => fontSize}px;
  font-family: ${({ fontFamily }) => fontFamily};
`

const Wayfinding: React.FC<WayfindingProps> = memo((props) => {
  const { eventsProps } = props
  const {
    separatorColor = '#fff',
    separatorSize = 1,
    separatorEnabled = true,
    eventLocation = false,
    eventLocationColor = '#fff',
    eventTitleColor = '#fff',
    eventTimeColor = '#fff',
    eventTime = true,
    logo = true,
    onlyEvents = false,
    visibleRows = 5,
    eventLogo = true,
    pagination,
    paginationEnabled,
  } = eventsProps

  const { fontColor = '#fff', fontFamily, fontSize = 64, horizontalAlignment, interval = 15, verticalAlignment } = pagination || {}
  //
  const [activeList, setActiveList] = useState<CustomEventType[] | []>([])
  const [roomsEvents, setRoomsEvent] = useState<Types.DeviceLocationType[]>([])
  const [counter, setCounter] = useState<number>(0)
  const [token, setToken] = useState<string | null>(null)
  useMemo(() => {
    setToken(window.localStorage.getItem('token'))
  }, [setToken])
  //
  const headers = useMemo(
    () => ({
      'Content-Type': 'application/json',
      Authorization: token || '',
      'Apollo-Require-Preflight': 'true',
    }),
    [token]
  )
  const body = useMemo(
    () => ({
      query: `query getDeviceLocations {
        getDeviceLocations {
          rows {
            id
            isVisible
            icon {
              __typename
              id
              url
              type
              props
            }
            destinationLocation {
              id
              name
              events(filter: { overlapDateRange: { from: "${time().toISOString()}", to: "${time().add(7, 'day').toISOString()}" } }) {
                id
                logo {
                  __typename
                  id
                  type
                  url
                  props
                }
                start
                end
                title
              }
            }
          }
        }
      }`,
      operationName: 'getDeviceLocations',
    }),
    []
  )
  const url = new URL(`https://${process.env.API_HOST}/api`)
  const { data } = useFetch<any>(url.href, {
    headers,
    method: 'POST',
    data: body,
  })
  const { data: { getDeviceLocations = {} } = {} } = data || {}
  const { rows = [] }: { rows: Types.DeviceLocationType[] } = getDeviceLocations || {}

  const roomsHasEvents = useMemo(() => rows.filter((row) => row.destinationLocation.events.length), [rows])

  const filterEvents = useCallback((room: Types.DeviceLocationType) => {
    const currentTime = time()
    const { events } = room.destinationLocation

    const filteredEvents = events.filter((event) => {
      const startTime = time(event.start).local()
      const endTime = time(event.end).local()

      if (!currentTime.isSame(startTime, 'day')) {
        return false
      }

      return currentTime.isBetween(startTime, endTime) || currentTime.isBefore(startTime)
    })

    room.destinationLocation.events = filteredEvents

    return room
  }, [])

  const filterRooms = useCallback(() => {
    const rooms = roomsHasEvents.map(filterEvents)
    const filteredRooms = rooms.filter((room) => room.destinationLocation.events.length)
    setRoomsEvent(filteredRooms)
  }, [roomsHasEvents])

  useEffect(() => {
    filterRooms()
  }, [data])

  useInterval(() => {
    filterRooms()
  }, 60000)

  const eventsList = useMemo(() => {
    const result: Array<CustomEventType> = []

    ;(roomsEvents ?? []).forEach((row) => {
      const { id, destinationLocation, icon } = row
      const { events = [], name } = destinationLocation
      events.forEach((event) => {
        result.push({
          id,
          icon,
          name,
          event,
        })
      })
    })

    return result
  }, [roomsEvents])

  const sortedEvents = useMemo(
    () =>
      eventsList.sort((a, b) => {
        return new Date(a.event.start).getTime() - new Date(b.event.start).getTime()
      }),
    [eventsList]
  )

  const chunkEventsList = useMemo(() => chunk(sortedEvents, visibleRows), [sortedEvents, visibleRows])

  useEffect(() => {
    setActiveList(chunkEventsList[counter])
  }, [setActiveList, chunkEventsList, counter])

  useInterval(
    () => {
      if (counter >= chunkEventsList.length - 1) {
        setCounter(0)
      } else {
        setCounter((prev) => (prev += 1))
      }
    },
    chunkEventsList.length > 1 ? interval * 1000 : null
  )

  return (
    <Container>
      {(activeList ?? []).map((row, index) => {
        const { name, icon, event } = row

        const timeStart = formatDate(time(event.start).local(), 'HH:mm')
        const timeEnd = formatDate(time(event.end).local(), 'HH:mm')

        return (
          <div key={event?.id}>
            <Item separatorSize={separatorSize} separatorColor={separatorColor}>
              <div>{eventLogo && logo && <img src={event && event?.logo?.url} />}</div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Title color={eventTitleColor}>{event && event?.title}</Title>
                <span className="wayfinding-location-name">
                  {eventTime && !onlyEvents && (
                    <Time color={eventTimeColor}>
                      {timeStart}-{timeEnd}
                    </Time>
                  )}{' '}
                  {eventLocation && <Location color={eventLocationColor}>{name}</Location>}
                </span>
              </div>
              <div className="wayfinding-icon">
                <img src={icon?.url} />
              </div>
            </Item>
            {activeList.length - 1 !== index && separatorEnabled && <hr />}
          </div>
        )
      })}
      {paginationEnabled && chunkEventsList.length > 1 && (
        <Pagination
          fontColor={fontColor}
          fontFamily={fontFamily}
          fontSize={fontSize}
          horizontalAlignment={horizontalAlignment}
          verticalAlignment={verticalAlignment}
        >
          Page {counter + 1} of {chunkEventsList.length}
        </Pagination>
      )}
    </Container>
  )
})

Wayfinding.displayName = 'Wayfinding'
export default Wayfinding
