import omit from 'lodash/omit';
import { ReactEventHandler } from 'react';
import { stopEventPropagation } from 'src/functions';
import { DEFAULT_AREA_STYLE, DEFAULT_RATIO } from './constants';

export interface IRatioStyle {
  [k: string]: unknown;
}

export function getRatioStyle(area: any, ratioId: string, propName?: string): IRatioStyle | string {
  // We omit 'top' and 'left' values to move area to topLeft corner of the canvas
  const defaultRatioStyleWithoutPosition = omit(area?.style?.[DEFAULT_RATIO as keyof typeof area.style] ?? {}, [
    'top',
    'left',
  ]);
  const ratioStyle = {
    ...DEFAULT_AREA_STYLE,
    ...defaultRatioStyleWithoutPosition,
    ...(area?.style?.[ratioId as keyof typeof area.style] || {}),
  };
  if (propName) {
    return ratioStyle[propName as keyof typeof ratioStyle];
  }
  return ratioStyle;
}

type CollapseHandlerEventTargetElement = HTMLDivElement & HTMLSpanElement & HTMLButtonElement & SVGSVGElement;
type CollapseHandlerEventType = Parameters<ReactEventHandler<CollapseHandlerEventTargetElement>>['0'];
interface CollapseHandlerEvent extends CollapseHandlerEventType {
  target: CollapseHandlerEvent['currentTarget'];
}
type CollapseHandler = (_event: CollapseHandlerEvent) => boolean | void;
type CollapseHandlerEventTarget = CollapseHandlerEvent['target'] & { onclick: () => void };

export const collapsePanel: CollapseHandler = event => {
  if (event && event.target) {
    const { ariaChecked, offsetParent, parentElement, nodeName } = event.target as CollapseHandlerEventTarget;
    const { ariaExpanded, onclick: defaultClick } = offsetParent as CollapseHandlerEventTarget;
    const {
      ariaExpanded: parentAriaExpanded,
      ariaChecked: parentAriaChecked,
      onclick: parentClick,
    } = parentElement as CollapseHandlerEventTarget;

    const isExpanded = (parentAriaExpanded ?? ariaExpanded) === 'true';
    const isChecked = (parentAriaChecked ?? ariaChecked) === 'true';

    // switch click
    if (nodeName.toLowerCase() === 'button') {
      if (isExpanded && isChecked) {
        return defaultClick();
      }

      return stopEventPropagation(event);
    }

    if (nodeName.toLowerCase() === 'span') {
      return parentClick();
    }
  }

  return stopEventPropagation(event);
};
