import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
html {
  height: 100%;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  > body {
    background-color: #F8F8FA;
  }

  @media screen and (min-device-width: 1301px) and (max-device-width: 1400px) {
    font-size: 15px;
  }

  @media screen and (min-device-width: 1101px) and (max-device-width: 1300px) {
    font-size: 14px;
  }

  @media screen and (min-device-width: 1001px) and (max-device-width: 1100px) {
    font-size: 13px;
  }

  @media screen and (max-device-width: 1000px) {
    font-size: 12px;
  }
}
body {
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #191919;
  font-size: 16px;
  line-height: 1.44;
  font-weight: normal;
  font-style: normal;
  height: 100%;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  background-size: cover;
}
* {
  box-sizing: border-box;
}

figure, menu, ul, li, h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  -webkit-padding-end: 0;
}

ul li {
  list-style: none;
}

#root {
  font-family: 'Nunito', sans-serif;

  &,
  > div:first-child {
    min-height: 100vh;
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
}

`;

export default GlobalStyles;
