import React, { memo, useMemo, useState, useEffect, useCallback, FC, CSSProperties } from 'react';
import styled from 'styled-components';
import Select, { OptGroup, Option } from 'src/components/ui-kit/select';
import searchBgIcon from 'static/images/proportions.svg';
import { GetScreenRatiosQueryVariables, useGetScreenRatiosQuery } from 'src/graphql/generated';

export const RatioSelectorElement = styled(Select)`
  //margin: 0 1.875rem 1.25rem;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.5rem;
    height: 2.25rem;
  }

  .ant-select-selector {
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 10px;
      background: url(${searchBgIcon}) no-repeat;
    }
  }
`;

export type ScreenRatioSelectorProps = {
  value?: string;
  onChange?(_value: string): void;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  groupLabel?: false | string;
  queryVariables?: GetScreenRatiosQueryVariables;
};

export const DEFAULT_OPTION_GROUP_LABEL = 'Popular Dimensions';

export const ScreenRatioSelector: FC<ScreenRatioSelectorProps> = props => {
  const { value, style, className, disabled, queryVariables, groupLabel = DEFAULT_OPTION_GROUP_LABEL } = props;
  const [selectedRatio, setRatio] = useState(value);
  //
  useEffect(() => {
    if (value !== selectedRatio) {
      setRatio(value);
    }
  }, [value]);
  //
  const onChange = useCallback(
    (v: string) => {
      //
      if (typeof props.onChange === 'function') {
        props.onChange(v);
      } else {
        setRatio(v);
      }
    },
    [selectedRatio],
  );

  const { data: queryResult } = useGetScreenRatiosQuery({ variables: queryVariables });
  const ratios = queryResult?.getScreenRatios ?? [];
  const options = useMemo(() => {
    const ops = ratios.map(({ id, label }) => (
      <Option key={id} value={id}>
        {label}
      </Option>
    ));
    if (!groupLabel || queryVariables?.filter?.orientation) {
      return ops;
    }
    return <OptGroup label={groupLabel}>{ops}</OptGroup>;
  }, [ratios, queryVariables, groupLabel]);
  //
  return (
    <RatioSelectorElement
      disabled={disabled}
      style={style}
      className={className}
      value={selectedRatio}
      onChange={onChange}>
      {options}
    </RatioSelectorElement>
  );
};

export default memo(ScreenRatioSelector) as typeof ScreenRatioSelector;
