import React, { memo, useState, useCallback } from 'react';
import type { ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { RiFilterLine, RiSearchLine } from 'react-icons/ri';
import { MediaFolderItem, MediaFolderType } from 'src/graphql/generated';
import type { MediaFolderProfile } from 'src/components/ui-kit/media-item/media-item.d';
import { AsideWrapper, Divider, Header, Search, Title } from 'src/components/ui-kit/aside';
import {
  Footer as AsideFooter,
  List as AsideList,
  MediaListProps,
  ListScrollContainer,
} from 'src/components/ui-kit/aside/tabs/components';
import { colorGrays } from 'src/components/ui-kit/theme';
import MediaThumbnail from 'src/components/ui-kit/thumbnail/media-thumbnail';
import { DraggableItem, DroppableArea } from 'src/components/ui-kit/drag-n-drop';
import { AsideFooterProps, AsideIconsManagerProps, IconProps, MediaThumbnailProps } from './wayfinding.d';

const Footer = AsideFooter as FC<AsideFooterProps>;
const List = styled(AsideList as FC<MediaListProps>)`
  .thumbnail-card {
    background: ${colorGrays.gray500};
    margin-bottom: 1.25rem;

    & > .ant-image {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: inherit;

      & > .ant-image-img {
        position: relative;
        top: -0.5rem;
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }
`;

const IconThumbnail = MediaThumbnail as FC<MediaThumbnailProps>;

const FooterComponent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  box-shadow: 0px -0.125rem 1.5rem rgba(0, 0, 0, 0.08);

  .ant-btn {
    height: 3.125rem;
    font-size: 0.875rem;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    + .ant-btn {
      &:after {
        content: '';
        position: absolute;
        left: -0.0625rem;
        width: 0.0625rem;
        height: 1.625rem;
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }
`;

const ScrollbarWithDraggableArea = styled(ListScrollContainer)`
  .draggable-icons {
    height: 100%;
  }
`;

export const AsideIconsManager: FC<AsideIconsManagerProps> = () => {
  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState('');
  const onFilterChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setFilterValue(event?.currentTarget?.value ?? '');
  }, []);

  const renderIconThumb: MediaListProps['renderItem'] = useCallback(
    (item: MediaFolderItem, itemIndex: number, folder: MediaFolderProfile | null) => {
      return (
        <DraggableItem key={item.id} draggableId={`${item.id}.wayfindingItem`} index={itemIndex}>
          <IconThumbnail data={item as IconProps} isEditable={!folder?.isDefault} isRemovable={!folder?.isDefault} />
        </DraggableItem>
      );
    },
    [],
  );

  return (
    <AsideWrapper>
      <Header>
        <Title>{t('Icons')}</Title>
      </Header>

      <Search
        value={filterValue}
        onChange={onFilterChange}
        placeholder={t('Search')}
        prefix={<RiSearchLine />}
        suffix={<RiFilterLine />}
        allowClear
      />
      <Divider />

      <ScrollbarWithDraggableArea>
        <DroppableArea
          type="REORDER"
          placeholder=""
          className="draggable-icons"
          droppableId="list.wayfindingItems"
          direction="vertical"
          isDropDisabled={false}>
          <List
            contentType={MediaFolderType.Icon}
            listType="preview"
            filterValue={filterValue}
            isEditable
            isRemovable
            renderItem={renderIconThumb}
          />
        </DroppableArea>
      </ScrollbarWithDraggableArea>

      <FooterComponent>
        <Footer contentType={MediaFolderType.Icon} />
      </FooterComponent>
    </AsideWrapper>
  );
};

export default memo(AsideIconsManager);
