/* eslint-disable import/prefer-default-export */
import { time } from 'src/helpers/time';
import { compareIds } from 'src/functions';
import logger from 'src/helpers/logger';
import { Modal } from 'antd';
import { ApolloError } from '@fjedi/graphql-react-components';
import { UpdateTimeslotMutation, UpdateTimeslotMutationFn } from 'src/graphql/generated';
import type { TFunction } from 'react-i18next';
import { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import type { ScheduleItem, ScheduleItemResource } from '../schedules';

export type TimeslotUpdateParams = {
  mutation: UpdateTimeslotMutationFn;
  events: ScheduleItem[];
  event: EventInteractionArgs<ScheduleItem>;
  onError: (_e: ApolloError) => void;
  currentDate: Date;
};

export const EVENT_TIMESLOT_COULD_NOT_BE_EDITED_DIRECTLY = `Timeslots related to an event can't be modified directly as their start-time and finish-time depends on event's one`;

export function avoidEventTimeslotToBeEditedDirectly(timeslot: ScheduleItemResource, t: TFunction): boolean {
  if (!timeslot?.eventId) {
    return false;
  }
  Modal.warning({
    title: t('Warning!'),
    content: t(EVENT_TIMESLOT_COULD_NOT_BE_EDITED_DIRECTLY),
  });
  return true;
}

export function getTimeslotUpdates(d: TimeslotUpdateParams) {
  const {
    mutation,
    events,
    event: { event, start: s, end: e },
    onError: showErrorModal,
    currentDate,
  } = d;
  //
  const now = time(currentDate);
  const startMoment = time(s);
  const endMoment = time(e);
  // Do not allow timeslot to go behind selected day
  const start = startMoment.isSame(now, 'day') ? startMoment : now.clone().startOf('day').add(1, 'second');
  const end = endMoment.isSame(now, 'day') ? endMoment : now.clone().endOf('day').subtract(1, 'second');
  const isAllDay = end.diff(start, 'seconds') >= 86395;
  const isMultiDay = end.diff(start, 'seconds') >= 86402;
  //
  const overlapsAnotherEvent = events.find(ev => {
    if (compareIds(ev.resource.id, event.resource.id)) {
      return false;
    }
    return startMoment.isBetween(time(ev.start), time(ev.end)) || endMoment.isBetween(time(ev.start), time(ev.end));
  });

  if (overlapsAnotherEvent) {
    showErrorModal(new Error('Timeslots can not overlap each other') as ApolloError);
    return;
  }
  const projectBasedOffset = time().tz().utcOffset();
  logger('getTimeslotUpdates', {
    projectBasedOffset,
    start,
    end,
    now,
    isAllDay,
  });

  const utcStart = start.add(-projectBasedOffset, 'minute').utc(true);
  const utcEnd = end.add(-projectBasedOffset, 'minute').utc(true);

  const optimisticResponse = {
    __typename: 'Mutation',
    updateTimeslot: {
      ...event.resource,
      start: utcStart,
      end: utcEnd,
      isAllDay,
      isMultiDay,
    },
  } as UpdateTimeslotMutation;
  //
  mutation({
    variables: {
      id: event.id,
      input: {
        start: utcStart,
        end: utcEnd,
      },
    },
    optimisticResponse,
  }).catch(logger);
}
