import { Context, createContext } from 'react';
import type { EventModalData } from './events';

export interface EventModalContextValue extends EventModalData {}
export type EventModalContextType = Context<EventModalContextValue>;

export const EventModalContext: EventModalContextType = createContext({} as EventModalContextValue);

const { Provider: EventModalContextProvider } = EventModalContext;

export default EventModalContextProvider;
