// React
import React, { memo, useMemo } from 'react';
import Helmet from 'react-helmet';
import { API_URL } from 'src/constants';
//
import guesttekIcon from './guesttek-favicon.ico';
import smarteqIcon from './smarteq-favicon.ico';
import hotezaIcon from './hoteza-favicon.png';

const PROJECT_NAME = import.meta.env.VITE_PROJECT_NAME || 'fjedi app';
const author = 'fjedi';
const authorUrl = 'https://fjedi.com';
const description = '';
const twitterUsername = '';
const ogImage = '';
const defaultColor = '#ffffff';
const altColor = '#000';
const ogImageHeight = '200';
const ogImageWidth = '200';
const lang = 'en';

const HelmetHeadComponent = ({ children, title, ...props }) => {
  const { favicon, appName, keywords, baseURL } = useMemo(() => {
    const url = new URL(API_URL);
    switch (url.hostname) {
      case 'st-unstable.fjedi.com':
        return {
          favicon: smarteqIcon,
          appName: 'Hoteza',
          keywords: [...new Set([PROJECT_NAME]).values()].join(','),
          baseURL: url.origin,
        };
      case 'hotsign.hoteza.com':
      case 'hotsign.hoteza.ru':
        return {
          favicon: hotezaIcon,
          appName: 'Hoteza',
          keywords: [...new Set([PROJECT_NAME]).values()].join(','),
          baseURL: url.origin,
        };
      case 'ovds.gtkcentral.net':
      case 'st.fjedi.com':
      case 'localhost':
        return {
          favicon: guesttekIcon,
          appName: 'GuestTek',
          keywords: [...new Set([PROJECT_NAME]).values()].join(','),
          baseURL: url.origin,
        };
      default:
        return {
          favicon: hotezaIcon,
          appName: 'Hoteza',
          keywords: [...new Set([PROJECT_NAME]).values()].join(','),
          baseURL: url.origin,
        };
    }
  }, [API_URL]);
  //
  return (
    <Helmet titleTemplate={`%s | ${PROJECT_NAME}`} defaultTitle={PROJECT_NAME} {...props}>
      <meta httpEquiv="Content-Language" content={lang} />
      <link rel="icon" href={favicon} sizes="any" type="image/svg+xml" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0" />
      <meta property="description" name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="application-name" content={appName} />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      {/* <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" /> */}
      {/* <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" /> */}
      <link rel="manifest" href="/manifest.json" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color={altColor} />
      {/* Common settings */}
      <meta name="apple-mobile-web-app-title" content={title} />
      <meta name="robots" content="index, follow" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="HandheldFriendly" content="no" />
      <meta name="apple-mobile-web-app-capable" content="no" />
      <meta name="theme-color" content={defaultColor} />
      {/* MS_APP settings */}
      <meta name="msapplication-TileColor" content={defaultColor} />
      {/* DC settings */}
      <meta name="DC.title" content={title} />
      <meta name="DC.creator" content={`${author} (${authorUrl})`} />
      <meta name="DC.creator.name" content={author} />
      <meta name="DC.subject" content={keywords} />
      <meta name="DC.description" content={description} />
      <meta name="DC.publisher" content={author} />
      <meta name="DC.publisher.url" content={baseURL} />
      <meta name="DC.contributor" content={appName} />
      <meta name="DC.date" content="2018-06-01" />
      <meta name="DC.language" content={lang} />
      <meta name="DC.coverage" content="World" />
      {/* OG/Twitter settings */}
      <meta property="og:type" name="og:type" content="website" />
      <meta property="og:url" content={baseURL} />
      <meta property="og:title" name="og:title" content={title} />
      <meta property="og:description" name="og:description" content={description} />
      <meta property="og:image" name="og:image" content={ogImage} />
      <meta property="og:image:height" content={ogImageHeight} />
      <meta property="og:image:width" content={ogImageWidth} />

      <meta property="twitter:card" name="twitter:card" content="summary" />
      <meta property="twitter:image" name="twitter:image" content={ogImage} />
      <meta property="twitter:title" name="twitter:title" content={title} />
      <meta property="twitter:description" name="twitter:description" content={description} />
      <meta property="twitter:site" name="twitter:site" content={twitterUsername} />
      <meta property="twitter:creator" name="twitter:creator" content={author} />
      <link rel="manifest" href="/manifest.json" />

      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap"
        rel="stylesheet"
      />
      <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap" rel="stylesheet" />
      {children}
    </Helmet>
  );
};
export default memo(HelmetHeadComponent);
