/* eslint-disable security/detect-object-injection */
/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/prefer-default-export */
import React, { HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, ApolloProvider } from '@fjedi/graphql-react-components';
import YoutubePlayer from 'react-player/youtube';
import HLSPlayer, { HlsPlayerProps } from 'react-hls-player';
import orderBy from 'lodash/sortBy';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { CSSProperties } from 'styled-components';
import { Playlist as PlaylistType, MediaItem as GeneratedMediaItemType } from 'src/graphql/generated';
import logger from 'src/helpers/logger';
import Weather from 'src/components/routes/private/template-editor/widgets/weather/weather';
import Clock from 'src/components/routes/private/template-editor/widgets/clock';
import DateTime from 'src/components/routes/private/template-editor/widgets/datetime';
import Heading from 'src/components/routes/private/template-editor/widgets/events/heading';
import EventsWidget, { EventsWidgetProps } from 'src/components/routes/private/template-editor/widgets/events/events';
import Flights from 'src/components/routes/private/template-editor/widgets/flights';
import WayfindingWidget, {
  WayfindingWidgetProps,
} from 'src/components/routes/private/template-editor/widgets/events/wayfinding';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace window {
  // eslint-disable-next-line no-undef
  export const timerId: NodeJS.Timeout | number;
  // eslint-disable-next-line no-undef, no-unused-vars
  let timeoutId: NodeJS.Timeout | number;
}

type MediaItem = GeneratedMediaItemType & { playlist: PlaylistType & { items: MediaItem[] } };

type StyleProps = CSSProperties & HTMLAttributes<HTMLElement>['style'] & Record<string, any>;

export interface ParseDataType {
  defaultMediaItem: MediaItem;
  mediaItem: MediaItem;
}

interface PlayProps {
  rootElement?: HTMLDivElement;
  ratio?: string;
  isVert?: boolean;
}

export class Playlist {
  rootElement: HTMLElement | null;
  isPlaying: boolean;
  isVert: boolean;
  apolloClient: ApolloClient;
  defaultMediaItem?: MediaItem;
  listMedia?: MediaItem[];
  ratio?: string;

  constructor({ apolloClient }: { apolloClient: ApolloClient }) {
    this.isPlaying = false;
    this.isVert = false;
    this.apolloClient = apolloClient;
    this.rootElement = null;
  }

  async applyStyles(el: HTMLElement, styles: StyleProps) {
    Object.keys(styles).forEach(prop => {
      const value =
        typeof styles[prop as keyof StyleProps] === 'string'
          ? (styles[prop as keyof StyleProps] as string)
          : `${styles[prop as keyof StyleProps]}px`;

      el.style.setProperty(prop, value);
    });
  }

  async createMediaElement(asset: MediaItem, loopVideo = false, fullscreen = true, style: StyleProps = {}) {
    let mediaEl;
    if (!asset) {
      //
    }
    const { type, id } = asset;
    const mediaFile = asset;
    const positionStyles = pick(style, ['top', 'left', 'position', 'overflow']);
    const initialStyles = omit(style, Object.keys(positionStyles));

    switch (type.toLowerCase()) {
      case 'hls': {
        mediaEl = document.createElement('div');
        const width = '100%';
        const height = '100%';
        ReactDOM.render(
          React.createElement(HLSPlayer, {
            src: asset.url ?? '',
            width,
            height,
            autoPlay: false,
            controls: true,
            style: {
              width,
              height,
            },
          } as HlsPlayerProps),
          mediaEl,
        );
        break;
      }

      case 'youtube': {
        mediaEl = document.createElement('div');
        const width = '100%';
        const height = '100%';
        ReactDOM.render(
          React.createElement(YoutubePlayer, {
            url: asset.props?.originalURL || asset.url,
            width,
            height,
            // style: { width, height },
          }),
          mediaEl,
        );
        break;
      }

      case 'datetime': {
        mediaEl = document.createElement('div');
        const props = {
          item: asset,
          style,
        };
        ReactDOM.render(React.createElement(DateTime, props), mediaEl);
        //
        break;
      }

      case 'clock': {
        mediaEl = document.createElement('div');
        const props = {
          item: asset,
          style,
        };
        ReactDOM.render(React.createElement(Clock, props), mediaEl);
        break;
      }

      case 'video':
        mediaEl = document.createElement('video');
        mediaEl.src = mediaFile.url as string;
        mediaEl.autoplay = true;
        if (loopVideo) mediaEl.loop = true;
        mediaEl.muted = true;
        mediaEl.id = id;
        mediaEl.setAttribute('type', `video/${asset.url?.split('.').pop() || ''}`);
        if (!fullscreen) {
          mediaEl.setAttribute('style', 'object-fit: cover;');
        }
        break;

      case 'image':
        mediaEl = document.createElement('img');
        mediaEl.id = id;
        mediaEl.src = mediaFile.url as string;
        if (!fullscreen) {
          mediaEl.setAttribute('style', 'width: 100%; height: 100%; object-fit: cover;');
        }
        break;

      case 'iframe':
        mediaEl = document.createElement('iframe');
        mediaEl.scrolling = 'no';
        mediaEl.id = id;
        mediaEl.src = asset.url as string;
        mediaEl.frameBorder = '0';
        break;

      case 'weather': {
        mediaEl = document.createElement('div');
        mediaEl.id = id;
        const props = {
          item: asset,
          style: {},
        };
        ReactDOM.render(
          React.createElement(
            ApolloProvider,
            {
              client: this.apolloClient,
            } as Parameters<typeof ApolloProvider>[0],
            React.createElement(Weather, props),
          ),
          mediaEl,
        );
        break;
      }

      case 'flights': {
        mediaEl = document.createElement('div');
        mediaEl.id = id;

        const props = {
          ...asset,
          style: {
            display: 'flex',
            justifyContent: 'center',
          },
        };

        ReactDOM.render(
          React.createElement(
            ApolloProvider,
            {
              client: this.apolloClient,
            } as Parameters<typeof ApolloProvider>[0],
            React.createElement(Flights, props),
          ),
          mediaEl,
        );
        break;
      }

      case 'location_event_logo':
        if (mediaFile.url) {
          mediaEl = document.createElement('img');
          mediaEl.id = id;
          mediaEl.src = mediaFile.url;

          if (!fullscreen) {
            mediaEl.setAttribute('style', 'width: 100%; height: 100%; object-fit: cover;');
          }
        } else {
          mediaEl = document.createElement('div');

          const props = {
            data: asset,
            style: initialStyles,
          };

          ReactDOM.render(React.createElement(Heading, props), mediaEl);
        }
        break;

      case 'location_header': {
        mediaEl = document.createElement('div');

        const props = {
          data: asset,
          style: initialStyles,
        };

        ReactDOM.render(React.createElement(Heading, props), mediaEl);
        break;
      }

      case 'location_events': {
        mediaEl = document.createElement('div');

        const props = { ...(asset.props as Omit<EventsWidgetProps, 'style'>), style: initialStyles };

        ReactDOM.render(React.createElement(EventsWidget, props), mediaEl);
        break;
      }

      case 'location_wayfinding': {
        mediaEl = document.createElement('div');

        const props = { ...(asset as Omit<WayfindingWidgetProps, 'style'>), style: initialStyles };

        ReactDOM.render(React.createElement(WayfindingWidget, props), mediaEl);
        break;
      }

      default:
        mediaEl = document.createElement('h1');
        mediaEl.innerText = asset.title ?? '';
    }
    const parentDiv = document.createElement('div');
    // parentDiv.setAttribute('class', type);
    this.applyStyles(parentDiv, {
      width: '100%',
      height: '100%',
      'max-width': '100%',
      'max-height': '100%',
    });
    if (fullscreen) {
      this.applyStyles(mediaEl, {
        width: '100%',
        height: '100%',
        'object-fit': this.isVert ? 'contain' : 'cover',
      });
    } else {
      const blackList = ['position', 'left', 'top', 'right', 'bottom'];
      if (mediaEl.tagName === 'H1') blackList.push('width', 'height');
      this.applyStyles(
        mediaEl,
        Object.keys(style).reduce((obj, key) => {
          if (blackList.indexOf(key) >= 0) {
            return obj;
          }

          return {
            ...obj,
            [key]: style[key as keyof StyleProps],
          };
        }, {}),
      );
    }
    parentDiv.setAttribute('id', `container-${id}`);
    parentDiv.appendChild(mediaEl);
    mediaEl = null;
    return parentDiv;
  }

  parse(data: ParseDataType | ParseDataType[]) {
    const listDownload = [];
    if (!data) {
      return;
    }
    const { defaultMediaItem } = data as ParseDataType;

    if (defaultMediaItem) {
      this.defaultMediaItem = defaultMediaItem;
      listDownload.push(defaultMediaItem);
    }

    function handlerListMedia(listMedia: ParseDataType[]) {
      if (listMedia.length > 0) {
        listMedia.forEach(async items => {
          const { mediaItem } = items;
          if (!!mediaItem && (mediaItem.type.toLowerCase() === 'video' || mediaItem.type.toLowerCase() === 'image')) {
            listDownload.push(mediaItem);
          }
        });
      }
    }

    handlerListMedia(data as ParseDataType[]);
    this.listMedia = listDownload;
  }

  async play(props: PlayProps = {}) {
    if (this.isPlaying) {
      await this.stop();
    }
    if (!this.listMedia?.length) {
      return;
    }
    const { rootElement, ratio, isVert } = props;
    if (rootElement) {
      this.rootElement = rootElement;
    }
    if (ratio) {
      this.ratio = ratio;
    }
    if (isVert) {
      this.isVert = isVert;
    }

    if (window.timerId) {
      clearInterval(window.timerId as number);
    }

    this.isPlaying = true;

    this.playMedia();
  }

  async stop() {
    if (window.timerId) clearInterval(window.timerId as number);
    if (window.timeoutId) clearTimeout(window.timeoutId as number);

    if (this.rootElement) {
      (this.rootElement as HTMLElement).innerHTML = '';
      (this.rootElement as HTMLElement).dataset.id = 'default';
    }

    this.isPlaying = false;
  }

  playMedia() {
    if (!Array.isArray(this.listMedia)) {
      throw new TypeError('Invalid "listMedia" array passed to "playMedia" function');
    }

    const playbackList: MediaItem[] = [];

    orderBy(this.listMedia, 'sequence', 'asc').forEach(mediaItem => {
      if (mediaItem.playlist) {
        if (Array.isArray(mediaItem.playlist.items) && mediaItem.playlist.items.length > 0) {
          const playlistMedia = orderBy(mediaItem.playlist.items, 'sequence', 'asc');
          playbackList.push(...playlistMedia);
        }
      } else {
        playbackList.push(mediaItem);
      }
    });
    // const isLoop = listMedia.length === 1;
    const isLoop = false;
    //
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    async function appendMedia(asset: MediaItem) {
      if (!asset) {
        that.rootElement!.innerHTML = '';
        return;
      }

      const { type, id } = asset;
      const duration = asset.duration === 0 ? 20 : asset.duration;

      that.rootElement!.innerHTML = '';

      let mediaEl: HTMLElement | null = await that.createMediaElement(asset, isLoop);

      that.rootElement!.appendChild(mediaEl);

      mediaEl = null;

      if (type.toLowerCase() === 'video') {
        const videoElement = document.getElementById(id);
        videoElement?.addEventListener('ended', () => {
          changeMedia();
        });
      }

      if (type.toLowerCase() === 'image') {
        if (window.timeoutId) clearTimeout(window.timeoutId as number);

        if (!isLoop) {
          window.timeoutId = setTimeout(changeMedia, duration * 1000);
        }
      }
    }
    // this.rootElement.dataset.id = 1;
    appendMedia(playbackList[0]).catch(logger);
    let currentIndex = 1;
    function changeMedia() {
      if (window.timeoutId) clearTimeout(window.timeoutId as number);
      if (currentIndex >= playbackList.length) currentIndex = 0; // Зацикливаем воспроизведение.
      appendMedia(playbackList[currentIndex]).catch(logger);
      currentIndex += 1;
    }
  }
}
