import isEqualWith from 'lodash/isEqualWith';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import camelCase from 'lodash/camelCase';
import toUpper from 'lodash/toUpper';
import React, { CSSProperties } from 'react';
import { Company, MediaItem, PlaylistItem, Project, UserRoleRole, Viewer } from 'src/graphql/generated';

export function timeout(ms: number) {
  return new Promise(resolve => {
    setTimeout(() => resolve(undefined), ms);
  });
}

export function rawMarkup(__html: string) {
  return {
    __html,
  };
}

export function compareIds(id1: unknown, id2: unknown) {
  return String(id1) === String(id2);
}

export function compareValues(a: unknown, b: unknown): boolean {
  if (!a && !b) {
    return true;
  }
  if ((!a && b) || (a && !b)) {
    return false;
  }
  return isEqualWith(a, b, () =>
    uniq(Object.keys(a as Object).concat(Object.keys(b as Object))).every(field => {
      const value = get(a, field);
      const newValue = get(b, field);
      if (typeof value !== 'object' || !value) {
        return isEqual(value, newValue);
      }
      return compareValues(value, newValue);
    }),
  );
}

export function getBase64FromFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function isEmpty(v: string | Array<unknown> | Object) {
  if (Array.isArray(v)) {
    return v.length === 0;
  }
  if (!v) {
    return true;
  }
  if (typeof v === 'object') {
    return Object.keys(v).length === 0;
  }
  return false;
}

export function formatPropForReact(css: any): CSSProperties {
  if (!css) {
    return {};
  }
  const s: CSSProperties = {};
  Object.keys(css).forEach(cssProp => {
    const field = camelCase(cssProp) as keyof CSSProperties;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line security/detect-object-injection
    s[field] = css[cssProp];
  });
  return s;
}

export function getRole(params: { viewer: Viewer | null; viewerRole: UserRoleRole | null }) {
  const { viewer, viewerRole } = params;
  let role: UserRoleRole | null = null;
  const adminInCompanies: Company[] = [];
  const adminInProjects: Project[] = [];

  (viewer?.roles ?? []).forEach(r => {
    if (!r.company.id) {
      return;
    }
    if (r.project?.id) {
      if (r.role === UserRoleRole.Admin) {
        adminInProjects.push(r.project);
        role = UserRoleRole.Admin;
      }
      return;
    }
    if (r.role !== UserRoleRole.Admin) {
      return;
    }
    adminInCompanies.push(r.company);
    if (!role) {
      role = viewerRole === UserRoleRole.Admin ? viewerRole : r.role || UserRoleRole.User;
    }
  });

  return {
    role: viewerRole === UserRoleRole.Admin ? viewerRole : role || UserRoleRole.User,
    adminInCompanies,
    adminInProjects,
  };
}

export const stopEventPropagation = (
  event?: Partial<Event> | Partial<React.MouseEvent<Element, MouseEvent>>,
): false => {
  if (event && 'preventDefault' in event) {
    event.preventDefault!();
  }
  if (event && 'stopPropagation' in event) {
    event.stopPropagation!();
  }

  return false;
};

export function getMediaItemsDuration(items: Array<Pick<MediaItem, 'duration'> | Pick<PlaylistItem, 'duration'>>) {
  if (Array.isArray(items)) {
    return items.reduce((duration: number, item: Pick<MediaItem, 'duration'> | Pick<PlaylistItem, 'duration'>) => {
      if (item?.duration && typeof item.duration === 'number') {
        return duration + item.duration;
      }

      return duration;
    }, 0);
  }

  return 0;
}

export function pascalCase(str: string) {
  return camelCase(str).replace(/^(.)/, toUpper);
}

export function kebabize(s: string) {
  return s.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
}

export function isHTMLElementVisible(ele: HTMLElement, container: HTMLElement) {
  const { bottom, height, top } = ele.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
}
