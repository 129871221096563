import React, { memo, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getRole } from 'src/functions';
import { API_URL } from 'src/constants';
//
import { useTranslation } from 'react-i18next';
// Inner components
import { RiCalendarCheckFill, RiHome3Line, RiVipCrown2Line } from 'react-icons/ri';
import { RestrictedArea, ViewerContext, ViewerRoleContext, Link, useLocation } from '@fjedi/react-router-helpers';
import Helmet from 'src/components/ui-kit/helmet-head';
import { Layout, Menu as AntMenu } from 'antd';
import HeaderNav from './header';

const { Content: AntContent } = Layout;

const SideMenu = styled.div`
  .ant-menu {
    &.ant-menu-dark {
      background: none;
    }

    > li {
      margin-top: 0;
    }
  }
`;

const Content = styled(AntContent)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 40px 40px 0 40px;
  position: relative;

  > .ant-card {
    flex-grow: 1;
    border-radius: 10px;
  }
`;

const StyledLayout = styled(Layout)`
  height: 100%;
  width: 100%;
  max-width: calc(1300px + 4rem);
  margin-left: auto;
  margin-right: auto;

  &.ant-layout {
    padding: 2rem;
  }

  .ant-layout-content {
    padding: 0;
  }
  .ant-layout {
    + div {
      margin-left: 2rem;
    }
  }

  &.ant-layout,
  .ant-layout {
    /* background-color: inherit; */
  }
  > .ant-layout {
    /* background-color: inherit; */
    overflow-x: initial !important;
  }
`;

const Header = styled.div`
  background: #f0f2f5;
`;
const Wrapper = styled.div`
  height: 100vh;
  flex: 1 0 auto;
`;
const Body = styled.div`
  height: 1px;
  flex: 1 0 auto;
  background-color: #f0f2f5;
`;

const Menu = styled(AntMenu)`
  &.ant-menu,
  &.ant-menu.ant-menu-dark {
    margin: 2px 0 0;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);

    .ant-menu-item {
      font-size: 1rem;
      padding: 0.75rem 0.625rem;
      font-weight: 600;
      width: auto;
      margin: 0 1.875rem;
      line-height: 1.5;

      &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: transparent;
        transition: background-color 0.2s;
        pointer-events: none;
      }

      span,
      svg,
      a {
        display: inline-block;
      }

      &,
      > span,
      > a {
        color: rgba(0, 0, 0, 0.4);
      }

      svg,
      .icon {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        vertical-align: middle;
      }

      &:hover,
      &.ant-menu-item-selected {
        background-color: transparent;
        &:after {
          background-color: #6a75ca;
        }
        &,
        .icon,
        > span,
        > a {
          color: #000;
        }
      }
    }
  }
`;
const MenuItem = styled(AntMenu.Item)``;

const menuItems = [
  {
    id: 'home',
    icon: RiHome3Line,
    name: 'Home',
    route: '/schedules',
    allowedRoles: ['USER', 'MODERATOR', 'ADMIN'],
  },
  {
    id: 'media-library',
    icon: RiHome3Line,
    name: 'Media',
    route: '/media-library',
    allowedRoles: ['USER', 'MODERATOR', 'ADMIN'],
  },
  {
    id: 'events',
    icon: RiCalendarCheckFill,
    name: 'Events',
    route: '/events',
    allowedRoles: ['USER', 'MODERATOR', 'ADMIN'],
    restrictedRoles: ['NON_COMPANY_USER'],
  },
  {
    id: 'admin',
    icon: RiVipCrown2Line,
    name: 'Admin',
    route: '/admin',
    allowedRoles: ['ADMIN', 'MODERATOR'],
    restrictedRoles: ['NON_COMPANY_USER'],
  },
  // {
  //   id: 'support',
  //   icon: RiCustomerService2Fill,
  //   name: 'Support',
  //   route: '/support',
  //   allowedRoles: ['USER', 'MODERATOR', 'ADMIN'],
  //   restrictedRoles: ['NON_COMPANY_USER'],
  // },
  {
    id: 'login',
    icon: 'user',
    name: 'Login',
    route: '/login',
    allowedRoles: ['ANONYMOUS'],
  },
  {
    id: 'signup',
    icon: 'user',
    name: 'Signup',
    route: '/signup',
    allowedRoles: ['ANONYMOUS'],
  },
];

const MainContainer = ({ children }) => {
  const viewer = useContext(ViewerContext);
  const viewerRole = useContext(ViewerRoleContext);
  const { role } = getRole({ viewer, viewerRole });
  const { t } = useTranslation();
  const location = useLocation();
  // Определяем выбранный пункт меню на основе свойств Router'а
  const rootPath = `/${location.pathname.split('/')[1] ? location.pathname.split('/')[1] : ''}`;
  const activeMenuIndex = menuItems.findIndex(i => i.route === rootPath);

  return (
    <Fragment>
      <Helmet />
      <Wrapper>
        <Header>
          <HeaderNav />
          <SideMenu id="side-menu">
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={[menuItems[0].id]}
              selectedKeys={[menuItems[activeMenuIndex === -1 ? 0 : activeMenuIndex].id]}>
              {menuItems.map(item => (
                <RestrictedArea
                  areaType="block"
                  key={item.id}
                  currentRole={role}
                  allowedRoles={item.allowedRoles}
                  restrictedRoles={item.restrictedRoles}>
                  <MenuItem title={t(item.name)}>
                    <span>{t(item.name)}</span>
                    <Link to={item.route} />
                  </MenuItem>
                </RestrictedArea>
              ))}
            </Menu>
          </SideMenu>
        </Header>
        <Body>
          <StyledLayout hasSider id="main" className="main-container">
            <Layout>
              <Content id="layout-content">{children}</Content>
            </Layout>
          </StyledLayout>
        </Body>
      </Wrapper>
    </Fragment>
  );
};

MainContainer.propTypes = { children: PropTypes.node.isRequired };
MainContainer.defaultProps = {};

export default memo(MainContainer);
