import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const MulticastContainer = styled.div`
  background-color: #333;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  > h4 {
    color: #fefefe;
    font-size: 2.4rem;
    text-align: center;
    max-width: 90%;
    width: 50rem;
  }
`;

export const Multicast = memo(({ style }) => {
  const { t } = useTranslation();
  //
  return (
    <MulticastContainer style={style}>
      <h4>{t("Multicast stream can't be played in admin panel due to technical restrictions")}</h4>
    </MulticastContainer>
  );
});
Multicast.displayName = 'Multicast';

export default Multicast;
