import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, branch, renderNothing, onlyUpdateForKeys, getContext } from 'recompose';
import get from 'lodash/get';
import { withTranslation } from 'react-i18next';
import { RestrictedArea } from '@fjedi/react-router-helpers';
//
import { graphql } from '@fjedi/graphql-react-components';
import viewerQuery from 'src/graphql/queries/viewer.graphql';

// Components
import { Dropdown, Menu } from 'antd';
import AntAvatar from 'src/components/ui-kit/avatar';
import { UserOutlined, LogoutOutlined, PlusCircleOutlined } from 'src/components/ui-kit/icons';
import Link from 'src/components/ui-kit/buttons/link';
import LogoutButton from 'src/components/ui-kit/buttons/logout';

// CSS
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  // margin-left: 1.5rem;

  .avatar {
    //margin: 20px 8px 20px 0;
    //color: #1890ff;
    // background: hsla(0, 0%, 100%, 0.85);
    vertical-align: middle;
    background: #fff;
  }
`;
const Avatar = styled(AntAvatar)``;
const DropdownLabel = styled.span`
  transition: all 0.5s;
  cursor: pointer;
  // padding: 0 12px;
  display: inline-block;
  height: 100%;
  position: relative;

  @media screen and (max-width: ${props => props.theme.responsiveBreakpoints.mobile}) {
    padding-right: 0;
  }
`;
const ProfileDropdown = styled(Menu)`
  .ant-dropdown-menu-item {
    //min-width: px2rem(200px);
    font-size: 0.9rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    i,
    a {
      display: inline-block;
    }

    a {
      margin-left: -0.25rem;
    }
  }
`;

const Name = styled.span`
  margin: 0 0.5rem;
  font-size: 0.9rem;
  display: inline-block;
  vertical-align: middle;
`;

const ProfileWidget = ({ t, name, avatar }) => (
  <Container>
    <Dropdown
      placement="bottomRight"
      overlay={
        <ProfileDropdown>
          {/* <Menu.Item>
            <PlusCircleOutlined />
            <Link to="/company" title={t('Company')} target="_self">
              {t('Company')}
            </Link>
          </Menu.Item> */}
          <Menu.Item>
            <LogoutOutlined />
            <LogoutButton />
          </Menu.Item>
        </ProfileDropdown>
      }>
      <DropdownLabel>
        <Name>{name}</Name>
        <Avatar icon={<UserOutlined />} className="avatar" src={avatar} />
      </DropdownLabel>
    </Dropdown>
  </Container>
);
ProfileWidget.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  widgetMode: PropTypes.bool,
};
ProfileWidget.defaultProps = { widgetMode: false, avatar: undefined };

export default compose(
  graphql(viewerQuery, {
    options: {
      fetchPolicy: 'cache-only', // Do we need to make network query here?
      errorPolicy: 'all',
      variables: {},
    },
  }),
  // Hide component if no data provided
  branch(({ data }) => !data || !data.viewer, renderNothing),
  withTranslation(),
  withProps(({ data }) => ({
    name: get(data, 'viewer.fullName', ''),
    avatar: get(data, 'viewer.avatar.url'),
  })),
  onlyUpdateForKeys(['name', 'avatar']),
)(ProfileWidget);
