import React, { memo, useState, useMemo, useEffect, Fragment, createElement } from 'react';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import * as Types from '../types/main';
//
const defaultStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
//
export interface TextProps {
  data: Types.TemplateAreaType;
  style?: { [key: string]: string | number };
  className?: string;
}

export const Text: React.FC<TextProps> = memo(props => {
  const { data, style } = props;
  const { title = '' } = data || {};
  const strings = title?.replace(/\n/g, '[[[linebreak]]]').split('[[[linebreak]]]');
  const textContent = strings?.map((s, sIndex) => (
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={sIndex}>
      {s}
      {sIndex !== strings.length - 1 ? createElement('br') : null}
    </Fragment>
  ));
  const textStyle = useMemo(() => ['fontSize', 'color'], []);
  const [blacklistStyle, setBlacklistStyle] = useState(['paddingTop']);

  useEffect(() => {
    if (!style?.backgroundEnabled) {
      setBlacklistStyle([...blacklistStyle, 'backgroundColor']);
    }
  }, [style]);

  const filteredWrapStyle = omit(style, blacklistStyle);
  const wrapStyle = merge(filteredWrapStyle, defaultStyle);
  const h1Style = pick(style, textStyle);
  return (
    <div id={`container-${data.id}`} style={wrapStyle}>
      <h1 style={h1Style}>{textContent}</h1>
    </div>
  );
});
Text.displayName = 'Text';

export default Text;
