/* eslint-disable security/detect-object-injection */
/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/prefer-default-export */
import orderBy from 'lodash/sortBy';
import merge from 'lodash/merge';

import { Playlist } from './playlist';

export class Template extends Playlist {
  constructor(props) {
    super(props);
    const { ratio } = props;
    this.rootElement = '';
    this.isPlaying = false;
    this.isVert = false;
    this.ratio = ratio ?? '16:9';
  }

  async applyStyles(el, styles) {
    Object.keys(styles).forEach(prop => {
      const value = typeof styles[prop] === 'string' ? styles[prop] : `${styles[prop]}px`;
      el.style.setProperty(prop, value);
    });
  }

  parse(data) {
    const listDownload = [];
    const template = data;

    if (typeof template !== 'undefined' && template) {
      listDownload.push(template);
    }

    this.listMedia = listDownload;
  }

  playMedia() {
    if (!Array.isArray(this.listMedia)) {
      throw new TypeError('Invalid "listMedia" array passed to "playMedia" function');
    }

    const playbackList = [];

    orderBy(this.listMedia, 'sequence', 'asc').forEach(mediaItem => {
      if (mediaItem.playlist) {
        if (Array.isArray(mediaItem.playlist.items) && mediaItem.playlist.items.length > 0) {
          const playlistMedia = orderBy(mediaItem.playlist.items, 'sequence', 'asc');
          playbackList.push(...playlistMedia);
        }
      } else {
        playbackList.push(mediaItem);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    async function appendMedia(asset) {
      if (!asset) {
        that.rootElement.innerHTML = '';
        return;
      }

      that.rootElement.innerHTML = '';

      const { bodyStyle, areas } = asset;
      const defaultStyle = {
        'min-height': '100%',
        width: '100%',
        height: '100%',
        position: 'relative',
        color: '#fff',
      };

      const divWrapper = document.createElement('div');
      divWrapper.setAttribute('className', 'template-wrapper');

      const applyStyle = merge(defaultStyle, bodyStyle);

      that.applyStyles(divWrapper, applyStyle);

      orderBy(areas, 'sequence').forEach(async area => {
        let styleArea = area.style[that.ratio];

        if (that.isVert) {
          const [rw, rh] = that.ratio.split(':');
          const vertRatio = `${rh}:${rw}`;
          styleArea = area.style[vertRatio];
        }

        const mediaEl = await that.createMediaElement(
          area.item ?? { title: area.title, type: 'TEXT', id: area.id },
          true,
          false,
          styleArea,
        );

        const areaStyles = {
          ...(styleArea ?? {}),
          display: 'flex',
          'align-items': 'center',
          'justify-content': 'center',
          'box-sizing': 'border-box',
          // border: '2px solid transparent',
        };

        that.applyStyles(mediaEl, areaStyles);
        divWrapper.appendChild(mediaEl);
      });

      that.rootElement.append(divWrapper);
    }

    appendMedia(playbackList[0]);
  }
}
