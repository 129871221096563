import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, Navigate } from '@fjedi/react-router-helpers';
import { ContentCard } from 'src/components/ui-kit/admin-layout/content-card';
import ChangePasswordTab from './change-password';
import MainTab from './main';

const Content = styled(ContentCard)`
  height: 100%;
  max-height: calc(100vh - 134px);
  max-width: 914px;
  min-width: 100%;
  overflow-y: hidden;

  > img {
    max-height: 100%;
  }

  .rbc-time-view {
    border: 0;
  }
  .rbc-time-header {
    display: none;
  }
  .rbc-time-header-content {
    border: 0;
  }
  .rbc-time-content {
    .rbc-today {
      background-color: transparent;
    }
    > * + * > * {
      border-left: 0;
    }
  }

  > .ant-card-head {
    //margin-top: 0.5rem;
  }
`;

const UserProfileRoot = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const tabList = useMemo(
    () => [
      {
        key: 'main',
        tab: t('General'),
      },
      {
        key: 'change-password',
        tab: t('Change password'),
      },
    ],
    [],
  );

  return (
    <Content tabList={tabList} tabURLNavigation>
      <Routes key={location.key}>
        <Route path="main" element={<MainTab />} />
        <Route path="change-password" element={<ChangePasswordTab />} />
        <Route path="/" element={<Navigate replace to="main" />} />
      </Routes>
    </Content>
  );
};

UserProfileRoot.propTypes = {};

export default memo(UserProfileRoot);
