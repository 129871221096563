import React, { memo, useCallback, useContext } from 'react';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import { ViewerContext, ViewerRoleContext } from '@fjedi/react-router-helpers';
// GraphQL
import { Mutation } from '@fjedi/graphql-react-components';
// GraphQL Queries
import updateUserProfileMutation from 'src/graphql/mutations/update-user-profile.graphql';

// Layout
import { Divider, Tag } from 'antd';
import { Row, Col } from 'src/components/ui-kit/grid';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input, MaskedInput } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';
import Scrollbar from 'src/components/ui-kit/scrollbar';

const UserProfileMainPage = () => {
  const viewer = useContext(ViewerContext);
  const viewerRole = useContext(ViewerRoleContext);
  const isAdmin = viewerRole !== 'ADMIN';
  const { firstName, lastName, middleName, phoneNumber, email } = viewer;
  //
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = useCallback(mutation => input => {
    //
    const pn = typeof input.phoneNumber === 'string' && input.phoneNumber ? input.phoneNumber.replace(/-/g, '') : null;
    //
    mutation({
      variables: {
        id: viewer.id,
        input: { ...input, phoneNumber: pn },
      },
    });
  });

  return (
    <Mutation mutation={updateUserProfileMutation}>
      {(mutation, { loading }) => (
        <Scrollbar>
          <Row>
            <Col md={{ span: 8, offset: 8 }}>
              <Divider style={{ margin: '0.5rem 0 0' }}>
                <Tag color="red">{t(capitalize(viewerRole))}</Tag>
              </Divider>
              <Form
                layout="vertical"
                onFinish={onSubmit(mutation)}
                initialValues={{
                  email,
                  firstName,
                  lastName,
                  middleName,
                  phoneNumber: phoneNumber ? `+${phoneNumber}` : '',
                }}>
                <FormItem
                  label={t('Email')}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('Enter your email address'),
                    },
                  ]}>
                  <Input disabled={!isAdmin} />
                </FormItem>
                <FormItem
                  label={t('First name')}
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: t('Enter your name'),
                    },
                  ]}>
                  <Input />
                </FormItem>
                <FormItem label={t('Last name')} name="lastName">
                  <Input />
                </FormItem>
                <FormItem label={t('Middle name')}>
                  <Input />
                </FormItem>
                <FormItem label={t('Phone')} name="phoneNumber">
                  <MaskedInput mask="+1-111-111-1111" placeholder="+79998887766" />
                </FormItem>
                <FormItem>
                  <Button block loading={loading} type="primary" htmlType="submit">
                    {t('Save')}
                  </Button>
                </FormItem>
              </Form>
            </Col>
          </Row>
        </Scrollbar>
      )}
    </Mutation>
  );
};

UserProfileMainPage.propTypes = {};
UserProfileMainPage.defaultProps = {};

export default memo(UserProfileMainPage);
