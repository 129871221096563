import React, { FC, memo, useMemo, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewerContext } from '@fjedi/react-router-helpers';
import { stringify as stringifyQueryParams } from 'qs';
import { formatDate } from 'src/helpers/time';
// GraphQL Queries
import {
  Device,
  GetDevicesQueryVariables,
  RemoveDeviceDocument,
  useGetDevicesLazyQuery,
  Viewer,
} from 'src/graphql/generated';
//
import Table, { TableProps, filterDropdown, filterIcon } from 'src/components/ui-kit/table/table';
import { Col, Row } from 'src/components/ui-kit/grid';
import { ProjectSelector } from 'src/components/common/project-selector';
import LinkButton from 'src/components/ui-kit/buttons/link-button';

import { API_URL } from 'src/constants';

const DevicesPage: FC = () => {
  const { t } = useTranslation();
  const viewer = useContext(ViewerContext) as Viewer;
  const [projectId, setProjectId] = useState<Viewer['primaryProjectId'] | null>(viewer.primaryProjectId);
  const variables = useMemo<GetDevicesQueryVariables>(
    () => ({ filter: { projectId: projectId ? [projectId] : [] } }),
    [projectId],
  );

  const columns = useMemo<TableProps<Device, GetDevicesQueryVariables>['columns']>(
    () => [
      {
        title: t('Name'),
        dataIndex: 'name',
        sorterKey: 'name',
        sorter: true,
        filterDropdown,
        filterIcon,
      },
      {
        title: t('Vendor'),
        dataIndex: 'manufacturer',
        sorterKey: 'manufacturer',
        filters: [
          { text: 'Samsung', value: 'tizen' },
          { text: 'Google Chrome', value: 'chrome' },
          { text: 'LG', value: 'web0s' },
          { text: 'RaspberryPi', value: 'arm' },
        ],
        filterMultiple: false,
      },
      {
        title: t('Model'),
        dataIndex: 'model',
        sorterKey: 'model',
        filterDropdown,
        filterIcon,
      },
      {
        title: t('OS'),
        dataIndex: 'os',
        sorterKey: 'os',
        filters: ['arm', 'webOs', 'chrome', 'tizen'].map(v => ({ text: v, value: v })),
        filterMultiple: false,
        render: (_, record) => {
          return [record.os || '', record.osVersion || ''].join(' ').trim() || 'Unknown';
        },
      },
      {
        title: t('Firmware version'),
        dataIndex: 'firmwareVersion',
        sorterKey: 'firmwareVersion',
        filterDropdown,
        filterIcon,
      },
      {
        title: t('App version'),
        dataIndex: 'appVersion',
        sorterKey: 'appVersion',
        filterDropdown,
        filterIcon,
      },
      {
        title: t('Resolution'),
        dataIndex: 'resolution',
        sorterKey: 'resolution',
        filterDropdown,
        filterIcon,
        render: (_, record) => {
          const { width, height } = record.resolution;

          return (
            <div>
              {width}
              {` x `}
              {height}
              <div>{record.ratio}</div>
            </div>
          );
        },
      },
      {
        title: t('Status'),
        dataIndex: 'isOnline',
        sorterKey: 'isOnline',
        filters: [
          { text: 'Online', value: true },
          { text: 'Offline', value: false },
        ],
        filterMultiple: false,
        render: (_, row) => (
          <span style={{ color: row.isOnline ? 'green' : 'red' }}>{row.isOnline ? t('Online') : t('Offline')}</span>
        ),
      },
      {
        title: t('Network (LAN/WiFi)'),
        dataIndex: 'network',
        sorterKey: 'network',
        filterDropdown,
        filterIcon,
        render: (_: unknown, row) => {
          const lanIP = row.props.network?.lan?.ip;
          const wifiIP = row.props.network?.wifi?.ip;
          return (
            <div>
              {!lanIP && !wifiIP && <div>No data</div>}
              {!!lanIP && (
                <div>
                  <span>LAN:</span>
                  {lanIP}
                </div>
              )}
              {!!wifiIP && (
                <div>
                  <span>WiFi:</span>
                  {wifiIP}
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: t('Last change'),
        dataIndex: 'updatedAt',
        sorterKey: 'updatedAt',
        width: 180,
        sorter: true,

        render(_, row) {
          return formatDate(row.updatedAt, 'DD.MM.YYYY HH:mm');
        },
      },
      {
        title: t('Date'),
        dataIndex: 'createdAt',
        sorterKey: 'createdAt',
        width: 180,
        sorter: true,
        render(_, row) {
          return formatDate(row.createdAt, 'DD.MM.YYYY HH:mm');
        },
      },
    ],
    [t],
  );
  const getDevicesQuery = useGetDevicesLazyQuery({ errorPolicy: 'all', fetchPolicy: 'cache-and-network' });

  const [exportCSVURL, setExportCSVURL] = useState<string>(`${API_URL}/devices/export`);

  const onTableChange = useCallback<Required<TableProps<Device, GetDevicesQueryVariables>>['onChange']>(
    (_, filter, sort) => {
      setExportCSVURL(`${API_URL}/devices/export?${stringifyQueryParams({ filter, sort })}`);
    },
    [],
  );

  return (
    <>
      <Row wrap={false}>
        <Col flex="auto">
          <ProjectSelector style={{ width: '300px', marginBottom: '1rem' }} value={projectId} onChange={setProjectId} />
        </Col>
        <Col flex="none">
          <LinkButton type="primary" to={exportCSVURL} target="_blank">
            {t('Export CSV')}
          </LinkButton>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 24 }}>
          <Table
            pageSize={9}
            dataType="Device"
            query={getDevicesQuery}
            subscriptionQueries={
              [
                // deviceCreatedSubscription,
                // deviceChangedSubscription,
                // deviceRemovedSubscription,
              ]
            }
            removeRowMutationDoc={RemoveDeviceDocument}
            removalConfirmationMessage={`${t('Remove')}?`}
            variables={variables}
            rowKey="id"
            columns={columns}
            onChange={onTableChange}
          />
        </Col>
      </Row>
    </>
  );
};

export default memo(DevicesPage);
