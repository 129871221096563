import React, { createContext } from 'react';
import { UpdateTimeslotMutationFn, CreateTimeslotMutationFn } from 'src/graphql/generated';
import { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import { ScheduleItem } from '../schedules.d';

export type CalendarContextType = {
  currentDate: Date;
  createTimeslot: CreateTimeslotMutationFn;
  updateTimeslot: UpdateTimeslotMutationFn;
  onEventResize: (_event: EventInteractionArgs<ScheduleItem>) => void;
};

export const CalendarContext = createContext<CalendarContextType>({} as CalendarContextType);
