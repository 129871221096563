import React from 'react';
import ReactPlayer from 'react-player/youtube';

const YouTubePlayer = ({ item, style }) => {
  const { width, height } = style;
  return (
    <ReactPlayer
      url={item.url}
      width={width}
      height={height}
      style={{ width, height, pointerEvents: 'none' }}
      loop
      playing
    />
  );
};

export default YouTubePlayer;
