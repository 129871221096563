import React, { FC, memo } from 'react';
import { Route, Routes } from '@fjedi/react-router-helpers';

import LocationsPage from './locations';
import WayfindingPage from './wayfinding';

export const EventsPage: FC = () => {
  return (
    <Routes>
      <Route path="wayfinding" element={<WayfindingPage />} />
      <Route path="*" element={<LocationsPage />} />
    </Routes>
  );
};

export default memo(EventsPage);
