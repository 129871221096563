import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import time, { formatDate } from '../../utils/time';
import * as Types from '../../types/main';

interface EventsComponentProps {
  data: Types.EventType;
  widgetProps: { [k: string]: string };
}

const Container = styled.div<{ eventTimeColor: string }>`
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-template-rows: repeat(2, 5rem);
  gap: 0 1.25rem;
  align-items: center;

  & > img {
    grid-column: 1;
    grid-row: 1 / span 2;
    max-height: 10rem;
    width: 10rem;
    justify-self: end;

    text-align: center;
    line-height: 10rem;
    /* background-color: #313a46; */
    /* color: #f1f3fa; */
    font-family: 'Lato';
    font-weight: normal;
    font-size: 2rem;
  }

  & > h2 {
    color: ${({ color }) => color};
    font-size: inherit;
  }

  & > h2,
  h3 {
    margin: 0px;
    padding: 0px;
    margin-block: 0px;
    margin-inline: 0px;
    padding-inline: 0px;
  }

  & > section {
    align-self: start;
    width: 100%;
    color: ${({ eventTimeColor }) => eventTimeColor};

    display: flex;
    align-items: center;

    font-size: 0.7em;

    & > h3 {
      margin-left: 0.75rem;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
    }

    & > .event-time {
      display: flex;
      padding: 0;
      margin: 0;
    }
  }

  &:nth-of-type(2) {
    & > img {
      max-height: 8rem;
      max-width: 8rem;

      line-height: 8rem;
    }
  }
`;

const EventsComponent: React.FC<EventsComponentProps> = memo(props => {
  const { data, widgetProps } = props;
  const { eventTime, eventTimeColor, eventLocationColor = '#c4c4c4', eventTitleColor = '#fff' } = widgetProps || {};
  const timeStart = useMemo(() => formatDate(time(data?.start).local(), 'HH:mm'), [data]);
  const timeEnd = useMemo(() => formatDate(time(data?.end).local(), 'HH:mm'), [data]);
  const logo = get(data, 'logo.url', null);
  const eventTimeComponent = useMemo(
    () =>
      eventTime && (
        <p className="event-time">
          {timeStart}-{timeEnd}
        </p>
      ),
    [eventTime, timeStart, timeEnd],
  );
  //
  return (
    <Container eventTimeColor={eventTimeColor}>
      <img style={{ visibility: logo ? 'visible' : 'hidden' }} src={data?.logo?.url || ''} alt="Logo" />
      <h2 style={{ color: eventTitleColor }}>{data?.title}</h2>
      <section>
        {eventTimeComponent}
        <h3 style={{ color: eventLocationColor }}>{data?.location?.name}</h3>
      </section>
    </Container>
  );
});

EventsComponent.displayName = 'EventsComponent';
export default EventsComponent;
