import React, { useRef, useEffect, useState, useCallback } from 'react';

// Example
// ----------------
// const [seconds, setSeconds] = useState(0);
// useTimeout(() => { setSeconds(seconds + 1) }, 5000);
export const useTimeout = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay === null) {
      return () => null;
    }
    const id = setTimeout(tick, delay);
    return () => clearTimeout(id);
  }, [delay]);
};

// Example
// ----------------
// const [seconds, setSeconds] = useState(0);
// useInterval(() => { setSeconds(seconds + 1) }, 5000);
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay === null) {
      return () => null;
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

// Example
// --------------------------------
// const [value, setValue] = useState(0);
// const lastValue = usePrevious(value);
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

// Example
// --------------------------------
// const clickRef = useRef();
// useClickInside(clickRef, onClickInside);
export const useClickInside = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

// Example
// --------------------------------
// const clickRef = useRef();
// useClickOutside(clickRef, onClickInside);
export const useClickOutside = (ref, callback) => {
  useEffect(() => {
    const handleClick = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);
};

// Example
// --------------------------------
// useFetch('https://fjedi.com/api', {});
export const useFetch = (url, options) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(url, options);
        const json = await res.json();
        setResponse(json);
      } catch (e) {
        setError(e);
      }
    };
    fetchData();
  });

  return { response, error };
};

export const useComponentDidMount = onMountHandler => {
  useEffect(() => {
    onMountHandler();
  }, []);
};

export const useComponentWillUnmount = onUnmountHandler => {
  useEffect(
    () => () => {
      onUnmountHandler();
    },
    [],
  );
};

// Example
// const { x, y } = useMousePosition();
export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  const updateMousePosition = ev => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);

    return () => window.removeEventListener('mousemove', updateMousePosition);
  }, []);

  return mousePosition;
};
