/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-confusing-arrow */
import React, { memo, useState, useCallback } from 'react';
import { TooltipProps } from 'antd';
import styled from 'styled-components';
import Tooltip from 'src/components/ui-kit/tooltip';
import Button, { ButtonProps } from 'src/components/ui-kit/buttons';
import ModalPopup, { ModalPopupProps } from 'src/components/ui-kit/modal-popup/index';

export const ButtonModal = styled(Button)`
  &.ant-btn-thumbnail-add {
    //font-size: 0.75rem;
    //font-weight: 600;
    //position: relative;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //flex-direction: column;
    //border-radius: 0.5rem;
    //color: rgba(0, 0, 0, 1);
    //border: 2px dashed #bdbdbd;
    //background-color: transparent;
    //box-shadow: none;
    //height: 8.75rem;
    //width: 100%;
    //padding: 0;
    //text-shadow: none;

    &[ant-click-animating-without-extra-node='true']:after {
      position: relative;
      opacity: 0;
    }

    &:hover,
    &:focus {
      background-color: transparent;
      color: rgba(0, 0, 0, 0.7);
      border-color: #727cf5;
    }

    svg {
      font-size: 1.5rem;
      color: #6a75ca;
      position: absolute;
      transform: translateY(-30%);
    }

    > span {
      margin-left: 0 !important;
      position: absolute;
      transform: translateY(80%);
    }
  }
`;

export type ButtonModalProps = Omit<ModalPopupProps, 'onCancel'> & {
  beforeOpen?: () => void;
  beforeClose?: () => void;
  buttonType?: ButtonProps['type'];
  buttonSize?: ButtonProps['size'];
  buttonText?: string | null;
  buttonIcon?: React.ReactElement | null;
  buttonDisabled?: boolean;
  tooltip?: TooltipProps;
  destroyContentOnRemount?: boolean;
};

export const ButtonModalComponent: React.FC<ButtonModalProps> = props => {
  const {
    buttonText,
    buttonType = 'primary',
    buttonSize,
    buttonIcon,
    buttonDisabled = false,
    children,
    beforeOpen,
    beforeClose,
    tooltip: tooltipProps,
    width = 651,
    isVisible: outerControlVisibilityStatus,
    destroyContentOnRemount = false,
    ...modalProps
  } = props;

  const [isVisible, toggleModalVisibility] = useState(false);

  const onOpen = useCallback<Required<ButtonProps>['onClick']>(
    e => {
      e.stopPropagation();
      if (typeof beforeOpen === 'function') {
        beforeOpen();
      }

      toggleModalVisibility(true);
    },
    [beforeOpen],
  );

  const onCancel = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      if (typeof beforeClose === 'function') {
        beforeClose();
      }

      toggleModalVisibility(false);
    },
    [beforeClose],
  );

  const visible = typeof outerControlVisibilityStatus === 'boolean' ? outerControlVisibilityStatus : isVisible;
  //
  return (
    <>
      <Tooltip {...tooltipProps}>
        <Button type={buttonType} onClick={onOpen} size={buttonSize} icon={buttonIcon} disabled={buttonDisabled}>
          {buttonText}
        </Button>
      </Tooltip>
      <ModalPopup isVisible={visible} onCancel={onCancel} width={width} {...modalProps}>
        {destroyContentOnRemount && !visible ? null : children}
      </ModalPopup>
    </>
  );
};

export default memo(ButtonModalComponent);
