/* eslint-disable import/no-cycle */
import React, { memo, useContext } from 'react';
import assign from 'lodash/assign';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { getStyleObjectFromString } from '../utils/string';
import * as Types from '../types/main';
//
import { SettingsContext } from '../context';
import { PlaylistComponent } from './playlist';
import { MediaItemComponent } from './media-item';
import { Text } from './text';

interface TemplateAreaComponentProps {
  data: Types.TemplateAreaType;
}

const blacklistStyle = ['backgroundEnabled', 'backgroundOpacity'];

const getRatio = (orientation: number = 0, ratio: string = '16:9') => {
  if (orientation === 90 || orientation === 270) {
    const [rw, rh] = ratio.split(':');
    return `${rh}:${rw}`;
  }
  return ratio;
};

export const TemplateAreaComponent: React.FC<TemplateAreaComponentProps> = memo(props => {
  const { data } = props;
  const settings = useContext(SettingsContext);
  const { orientation, ratio } = settings || {};
  const { item, playlist, style = {} } = data;
  const styleArea = style[getRatio(orientation, ratio)] || style['16:9'];
  const filteredWrapStyle = omit(styleArea, blacklistStyle);
  const pickedStyle = pick(styleArea, blacklistStyle);
  const styleObject = getStyleObjectFromString(filteredWrapStyle);
  const assignedStyles = assign({}, pickedStyle, styleObject);

  if (playlist) {
    return <PlaylistComponent data={playlist} style={assignedStyles} />;
  }

  if (item) {
    return <MediaItemComponent data={item} style={assignedStyles} loopVideo />;
  }

  return <Text data={data} style={assignedStyles} />;
});
TemplateAreaComponent.displayName = 'TemplateAreaComponent';

export default TemplateAreaComponent;
