/* eslint-disable react/prop-types */
import type { MutationFunction } from '@fjedi/graphql-react-components';
import { Context, createContext } from 'react';
import type { Event } from './events';

export interface EventsContextValue {
  createEvent: MutationFunction;
  removeEvent: MutationFunction;
  updateEvent: MutationFunction;
  editEvent: (_event: Event) => () => void;
  warn: (_text: string) => void;
  isLoading: boolean;
}

export type EventsContextType = Context<EventsContextValue>;

export const EventsContext: EventsContextType = createContext({} as EventsContextValue);
const { Provider: EventsContextProvider } = EventsContext;

export default EventsContextProvider;
