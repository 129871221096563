import React, { FC, memo, useContext, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ViewerContext } from '@fjedi/react-router-helpers';
import { logger, useApolloError } from '@fjedi/graphql-react-components';
//
import { Form } from 'antd';
import { RiFolderAddLine } from 'react-icons/ri';
import { MediaFolderInput, MediaFolderType, Viewer, useCreateMediaFolderMutation } from 'src/graphql/generated';
import { Input } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';
import ButtonModalPopupComponent, { ButtonModalProps } from 'src/components/ui-kit/modal-popup/button-modal';
import { updateAfterFolderCreated } from 'src/components/ui-kit/media-item/helpers';

const FooterModal = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.875rem;

  .ant-btn {
    width: 8.75rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
`;

export type FoldersFooterProps = ButtonModalProps & { contentType: MediaFolderType };

const FoldersFooter: FC<FoldersFooterProps> = props => {
  const { contentType, buttonType, buttonIcon, buttonText, tooltip } = props;
  const { t } = useTranslation();
  const onError = useApolloError();
  const viewer = useContext(ViewerContext) as Viewer;
  const projectId = viewer?.primaryProjectId!;
  const [isCreateMediaFolderModalVisible, setCreateMediaFolderModalVisibility] = useState(false);
  const openCreateMediaFolderModal = useCallback(() => setCreateMediaFolderModalVisibility(true), []);
  const closeCreateMediaFolderModal = useCallback(() => setCreateMediaFolderModalVisibility(false), []);
  //
  const [form] = Form.useForm();
  const { resetFields } = form;
  //
  const onMediaFolderCreated = useCallback(() => {
    resetFields();
    closeCreateMediaFolderModal();
  }, [resetFields, closeCreateMediaFolderModal]);
  const [createMediaFolder, { loading }] = useCreateMediaFolderMutation({
    update: updateAfterFolderCreated,
    onCompleted: onMediaFolderCreated,
    onError,
  });
  const onMediaFolderSubmit = useCallback(
    (input: MediaFolderInput) => {
      createMediaFolder({
        variables: {
          input: { ...input, type: contentType, projectId },
        },
      }).catch(logger);
    },
    [projectId, contentType, createMediaFolder],
  );
  //
  return (
    <ButtonModalPopupComponent
      isVisible={isCreateMediaFolderModalVisible}
      beforeOpen={openCreateMediaFolderModal}
      beforeClose={closeCreateMediaFolderModal}
      buttonType={buttonType || 'link'}
      buttonText={typeof buttonText !== 'undefined' ? buttonText : t('Create media folder')}
      tooltip={tooltip}
      title={t('Create new media folder')}
      width={480}
      buttonIcon={typeof buttonIcon !== 'undefined' ? buttonIcon : <RiFolderAddLine />}>
      <Form layout="vertical" form={form} onFinish={onMediaFolderSubmit}>
        <Form.Item label={t('Name')} name="name" rules={[{ required: true, message: t('Please fill this field') }]}>
          <Input placeholder={t('Enter media folder name')} />
        </Form.Item>
        <FooterModal>
          <Button type="default" htmlType="button" onClick={closeCreateMediaFolderModal}>
            {t('Cancel')}
          </Button>
          <Button type="primary" loading={loading} htmlType="submit">
            {t('Add')}
          </Button>
        </FooterModal>
      </Form>
    </ButtonModalPopupComponent>
  );
};

export default memo(FoldersFooter);
