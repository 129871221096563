import React, { FC, memo, useCallback, createContext, useState } from 'react';
import throttle from 'lodash/throttle';
import get from 'lodash/get';
import logger from 'src/helpers/logger';
import time from 'src/helpers/time';
import styled from 'styled-components';
import type { DataRow } from '@fjedi/graphql-react-components';
import { Form, type FormInstance } from 'src/components/ui-kit/form';
import type { TableColumn } from 'src/components/ui-kit/table/table';
//
const TableFormContext = createContext<FormInstance | null>(null);

const StyledBodyCell = styled.td`
  position: relative;

  > div,
  > input .ant-col,
  .ant-form-item-control,
  .ant-form-item-children,
  .ant-form-item-children > span,
  .ant-form-item-children > div {
    width: 100%;
    //display: block;

    &,
    &:focus,
    &:hover {
      background-color: transparent;
    }

    .ant-time-picker-input,
    .ant-input-number,
    .ant-input {
      border-top: 0 !important;
      border-left: 0 !important;
      border-right: 0;
      box-shadow: none;
      background-color: transparent;
      padding: 4px;
    }
  }
`;

const EditableValue = styled.div`
  min-height: 40px;
  width: 100%;
`;

export type EditableRowProps = { index: number };

export const EditableRow: FC<EditableRowProps> = memo(({ index, ...props }) => {
  const [form] = Form.useForm();
  const onFieldsChange = useCallback(
    (changedFields, allFields) => {
      //
      logger('TableRow:onFieldsChange', {
        props,
        changedFields,
        allFields,
      });
    },
    [props],
  );
  const onValuesChange = useCallback(
    (changedValues, allValues) => {
      //
      logger('TableRow:onValuesChange', {
        props,
        changedValues,
        allValues,
      });
      const input = {};
      Object.keys(changedValues).forEach(field => {
        // eslint-disable-next-line security/detect-object-injection
        const value = changedValues[field];
        if (!value && typeof value !== 'boolean' && (typeof value !== 'number' || Number.isNaN(value))) {
          // eslint-disable-next-line security/detect-object-injection
          input[field] = null;
        } else if (time.isDayjs(value)) {
          // eslint-disable-next-line security/detect-object-injection
          input[field] = value.toISOString();
        } else {
          // eslint-disable-next-line security/detect-object-injection
          input[field] = value;
        }
      });
      //
      Object.keys(input).forEach(field => {
        //
        const onChangeField = throttle(
          get(
            props.children.find(cell => cell.key === field),
            'props.additionalProps.onChangeField',
            () => logger('Field changed without valid handler', { level: 'error', field }),
          ),
          500,
          { trailing: true, leading: false },
        );
        onChangeField.cancel();
        // eslint-disable-next-line security/detect-object-injection
        onChangeField(props['data-row-key'], { [field]: input[field] });
      });
    },
    [props],
  );

  return (
    <TableFormContext.Provider value={form}>
      <Form
        component={false}
        form={form}
        layout="inline"
        name="table_form"
        onFieldsChange={onFieldsChange}
        onValuesChange={onValuesChange}>
        <tr {...props} />
      </Form>
    </TableFormContext.Provider>
  );
});

export const EditableCell: FC<TableColumn & { children?: React.ReactNode; record: DataRow }> = memo(props => {
  const {
    inputAlwaysVisible,
    editable,
    dataIndex,
    title,
    record,
    // handleSave,
    input: Component,
    inputProps = {},
    children,
    render,
    onChangeField,
    ...restProps
  } = props;
  const [editing, toggleEdit] = useState(false);
  const isEditableCell = typeof editable === 'function' ? editable(record) : editable;

  if (get(record, 'id') !== 'add-new-row' && isEditableCell) {
    if (inputAlwaysVisible || editing) {
      const {
        // eslint-disable-next-line security/detect-object-injection
        initialValue = record[dataIndex],
        getValueFromEvent,
        valuePropName,
        ...componentProps
      } = (typeof inputProps === 'function' ? inputProps(record) : inputProps) || {};
      return (
        <StyledBodyCell {...restProps}>
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            initialValue={initialValue}
            valuePropName={valuePropName || 'value'}
            getValueFromEvent={getValueFromEvent}>
            <Component record={record} onPressEnter={toggleEdit} onBlur={toggleEdit} {...componentProps} />
          </Form.Item>
        </StyledBodyCell>
      );
    }
    return (
      <StyledBodyCell {...restProps}>
        <EditableValue onClick={toggleEdit}>{children}</EditableValue>
      </StyledBodyCell>
    );
  }
  return <StyledBodyCell {...restProps}>{children}</StyledBodyCell>;
});
