import React from 'react';
import tinycolor from 'tinycolor2';
import { colorGrays } from 'src/components/ui-kit/theme/index';

// Darkening the color
// eslint-disable-next-line
const darkenColor = (color = '#ffffff', amount = 7.5) => tinycolor(color).darken(amount).toString();

// Lighten the color
// eslint-disable-next-line
const lightenColor = (color = '#ffffff', amount = 7.5) => tinycolor(color).lighten(amount).toString();

// The transparency of the color
// eslint-disable-next-line
const setOpacity = (color, alpha = 0.5) => tinycolor(color).setAlpha(alpha).toRgbString();

// Color contrast
const colorContrast = color => {
  const { r, g, b } = tinycolor(color).toRgb();
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 150 ? colorGrays.gray900 : colorGrays.gray200;
};

export { darkenColor, lightenColor, setOpacity, colorContrast };
