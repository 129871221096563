import React, { FC, memo, useState, useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import { ViewerContext } from '@fjedi/react-router-helpers';
import { updateAfterMutation, useApolloError } from '@fjedi/graphql-react-components';
import { RiImageAddLine, RiUpload2Line, RiLayoutMasonryLine, RiFilmLine, RiPlayList2Line } from 'react-icons/ri';
import { Form, Progress as AntProgress } from 'antd';
import {
  CreateMediaItemMutationVariables,
  CreatePlaylistInput,
  CreatePlaylistMutationVariables,
  CreateTemplateInput,
  CreateTemplateMutationVariables,
  MediaFolderType,
  MediaItemType,
  useCreateMediaItemMutation,
  useCreatePlaylistMutation,
  useCreateTemplateMutation,
  Viewer,
} from 'src/graphql/generated';
import logger from 'src/helpers/logger';
import { Input } from 'src/components/ui-kit/input';
import FileUploader, { FileUploaderList } from 'src/components/ui-kit/file-uploader';
import Button from 'src/components/ui-kit/buttons';
import ButtonModalPopupComponent from 'src/components/ui-kit/modal-popup/button-modal';
import Checkbox from 'src/components/ui-kit/checkbox';
import { stopEventPropagation } from 'src/functions';
import type { MediaFolderProfile } from './media-item.d';

export const FooterModal = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.875rem;

  .ant-btn {
    width: 8.75rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
`;

export const Progress = styled(AntProgress)`
  display: flex;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  line-height: 0.375rem;
  > .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
  }
  .ant-progress-text {
    position: absolute;
    right: 0;
    top: -1.375rem;
    text-align: right;
    .anticon {
      font-size: 1rem !important;
    }
  }
`;

export const Uploading = styled.div`
  text-align: left;
`;

export const AddButtonIcon: FC<{ contentType: MediaFolderType | 'csv' }> = ({ contentType }) => {
  let buttonIcon;
  switch ((contentType || 'image').toLowerCase()) {
    case 'image':
      buttonIcon = <RiImageAddLine />;
      break;
    case 'video':
      buttonIcon = <RiFilmLine />;
      break;
    case 'template':
      buttonIcon = <RiLayoutMasonryLine />;
      break;
    case 'playlist':
      buttonIcon = <RiPlayList2Line />;
      break;
    case 'csv':
      buttonIcon = <RiUpload2Line />;
      break;
    default:
      buttonIcon = <RiImageAddLine />;
  }

  return buttonIcon;
};

const VIDEO_FILE_TYPES = '.3g2,.3gp,.avi,.flv,.m3u8,.ts,.m2ts,.mts,.mov,.mkv,.mp4,.mpeg,.mpd,.mxf,.pgv,.webm,.wmv';

const IMAGE_FILE_TYPES = '.jpg,.jpeg,.png,.gif,.svg,.webp';

export type AddMediaButtonProps = {
  contentType: MediaFolderType;
  folder?: MediaFolderProfile;
  // buttonSize?: string;
  buttonText?: string;
  buttonType?: string;
  buttonIcon?: React.ReactElement;
  // tooltip?: TooltipProps;
};

const AddMediaItemButton: FC<AddMediaButtonProps> = props => {
  const viewer = useContext(ViewerContext) as Viewer;
  const { t } = useTranslation();
  const onError = useApolloError();
  //
  const [form] = Form.useForm();
  //
  const { contentType, folder, buttonText, buttonType = 'link', buttonIcon } = props;
  const type = (contentType || 'image').toLowerCase();
  const title = folder
    ? t(`Add new ${type} to {{folderName}} folder`, { folderName: folder.name })
    : t(`Add new ${type}`);
  //
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const onFileUploadProgress = useCallback((ev: ProgressEvent) => {
    const progress = Math.min(parseInt(`${(ev.loaded / ev.total) * 100}`, 10) || 0, 100);
    setFileUploadProgress(progress);
  }, []);
  //
  const [visibleModal, setModalVisibility] = useState(false);
  const openModal = useCallback(() => {
    setModalVisibility(true);
    setFileUploadProgress(0);
  }, []);
  const closeModal = useCallback(() => {
    setModalVisibility(false);
    setSubmitDisabled(true);
  }, []);
  //
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const onUploaderChanged = useCallback((list: FileUploaderList) => {
    setSubmitDisabled(!(list && list.length > 0));
  }, []);
  //
  const onMediaItemCreated = useCallback(() => {
    form.resetFields();
    closeModal();
  }, [form, closeModal]);
  //
  const [createMediaItem, { loading: creatingMediaItem }] = useCreateMediaItemMutation({
    onCompleted: onMediaItemCreated,
    update: updateAfterMutation('MediaItem', 'getMediaItems'),
    onError,
  });
  const [createTemplate, { loading: creatingTemplate }] = useCreateTemplateMutation({
    onCompleted: onMediaItemCreated,
    update: updateAfterMutation('Template', 'getTemplates'),
    onError,
  });
  const [createPlaylist, { loading: creatingPlaylist }] = useCreatePlaylistMutation({
    onCompleted: onMediaItemCreated,
    update: updateAfterMutation('Playlist', 'getPlaylists'),
    onError,
  });
  //
  const onSubmit = useCallback(
    (input: any) => {
      logger('Components.onSubmit', input);
      const variables: {
        input: Pick<
          CreatePlaylistInput | CreateTemplateInput | CreateMediaItemMutationVariables['input'],
          'projectId' | 'folderId'
        >;
      } = {
        input: { projectId: viewer.primaryProjectId!, folderId: folder?.id },
      };
      if (contentType === 'TEMPLATE') {
        const templateVars: CreateTemplateMutationVariables = {
          ...variables,
          input: { ...variables.input, title: input.title },
        };
        createTemplate({ variables: templateVars }).catch(logger);
        return;
      }
      if (contentType === 'PLAYLIST') {
        const playlistVars: CreatePlaylistMutationVariables = {
          ...variables,
          input: { ...variables.input, title: input.title },
        };
        createPlaylist({ variables: playlistVars }).catch(logger);
        return;
      }
      const mediaItemVars: CreateMediaItemMutationVariables = {
        ...variables,
        input: {
          ...variables.input,
          type: contentType as unknown as MediaItemType,
          files: input.files,
          keepRawFiles: input.keepRawFiles,
        },
      };
      //
      createMediaItem({
        variables: mediaItemVars,
        context: {
          fetchOptions: {
            onProgress: input.files ? onFileUploadProgress : undefined,
            onAbortPossible() {
              logger('onAbortPossible');
            },
          },
        },
      }).catch(logger);
    },
    [
      createMediaItem,
      createPlaylist,
      createTemplate,
      contentType,
      viewer?.primaryProjectId,
      folder?.id,
      onFileUploadProgress,
    ],
  );
  const renderProgressBar = useMemo(() => {
    if (typeof fileUploadProgress === 'number' && fileUploadProgress > 0) {
      return (
        <>
          <span className="anticon">
            <RiUpload2Line />
          </span>
          <div>
            <Uploading>{t('Uploading...')}</Uploading>
            <Progress
              strokeColor="#6473C4"
              strokeWidth={4}
              trailColor="rgba(100, 115, 196, 0.3)"
              percent={fileUploadProgress}
            />
            <div className="ant-upload-desc">{t('Please wait ~10 min and don’t reload the page...')}</div>
          </div>
        </>
      );
    }
    if (type === 'video') {
      return (
        <>
          <span className="anticon">
            <RiFilmLine />
          </span>
          <div className="ant-upload-title">{t('Drag & drop or click and browse your video file')}</div>
          <div className="ant-upload-desc">{t('MP4 recommended, up to 250mb each')}</div>
        </>
      );
    }
    return (
      <>
        <span className="anticon">
          <RiUpload2Line />
        </span>
        <div className="ant-upload-text">{t(`Upload new ${type}s`)}</div>
      </>
    );
  }, [t, type, fileUploadProgress]);

  //
  if (type === 'template' || type === 'playlist') {
    return (
      <ButtonModalPopupComponent
        isVisible={visibleModal}
        beforeOpen={openModal}
        beforeClose={closeModal}
        buttonType={buttonType}
        buttonText={buttonText}
        tooltip={{
          title: t(`Add ${type}`),
          align: { offset: [0, 5] },
        }}
        title={title}
        width={480}
        zIndex={600}
        buttonIcon={typeof buttonIcon === 'undefined' ? <AddButtonIcon contentType={contentType} /> : buttonIcon}>
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Form.Item
            label={t(`${capitalize(type)} name`)}
            name="title"
            rules={[
              {
                required: true,
                message: t(`Enter ${type} name`),
              },
            ]}>
            <Input onClick={stopEventPropagation} placeholder={t(`Enter ${type} name`)} />
          </Form.Item>
          <FooterModal>
            <Button type="default" htmlType="button" onClick={closeModal}>
              {t('Cancel')}
            </Button>
            <Button type="primary" htmlType="submit" loading={creatingPlaylist || creatingTemplate}>
              {t('Create')}
            </Button>
          </FooterModal>
        </Form>
      </ButtonModalPopupComponent>
    );
  }
  const acceptFileTypes = type === 'video' ? VIDEO_FILE_TYPES : IMAGE_FILE_TYPES;
  //
  return (
    <ButtonModalPopupComponent
      destroyContentOnRemount
      isVisible={visibleModal}
      beforeOpen={openModal}
      beforeClose={closeModal}
      buttonType={buttonType}
      buttonText={buttonText}
      tooltip={{
        title: t(`Add ${type}`),
        align: { offset: [0, 5] },
      }}
      title={title}
      width={480}
      zIndex={600}
      buttonIcon={typeof buttonIcon === 'undefined' ? <AddButtonIcon contentType={contentType} /> : buttonIcon}>
      <Form layout="vertical" form={form} initialValues={{ keepRawFiles: false }} onFinish={onSubmit}>
        <Form.Item name="files">
          <FileUploader
            listType="text"
            className="upload-image upload-image_minimal"
            multiple
            accept={acceptFileTypes}
            onChange={onUploaderChanged}
            onRemove={onUploaderChanged}>
            <div>{renderProgressBar}</div>
          </FileUploader>
        </Form.Item>
        {type === 'image' && (
          <Form.Item name="keepRawFiles" valuePropName="checked">
            <Checkbox>{t('Do not compress')}</Checkbox>
          </Form.Item>
        )}
        <FooterModal>
          <Button type="default" htmlType="button" onClick={closeModal}>
            {t('Cancel')}
          </Button>
          <Button type="primary" htmlType="submit" loading={creatingMediaItem} disabled={submitDisabled}>
            {t('Upload')}
          </Button>
        </FooterModal>
      </Form>
    </ButtonModalPopupComponent>
  );
};

export default memo(AddMediaItemButton) as typeof AddMediaItemButton;
