import React, { FC, useState, useCallback, useContext, useEffect, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewerContext, ViewerRoleContext, RestrictedArea, useNavigate, useParams } from '@fjedi/react-router-helpers';
import compact from 'lodash/compact';
import { getRole, stopEventPropagation } from 'src/functions';
import { updateAfterMutation, useApolloError, logger } from '@fjedi/graphql-react-components';
import styled from 'styled-components';
import { Divider, Empty } from 'antd';
import Drawer from 'src/components/ui-kit/drawer';
import { Row, Col } from 'src/components/ui-kit/grid';
import { Form, FormItem, parseFormData } from 'src/components/ui-kit/form';
import { Input, TextArea } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';
import RoleSelector from 'src/components/common/role-selector';
import { ProjectSelector } from 'src/components/common/project-selector';
import CompanySelector from 'src/components/common/company-selector';
import Spinner from 'src/components/ui-kit/spinner';
import Scrollbar from 'src/components/ui-kit/scrollbar';
import Popconfirm, { PopconfirmProps } from 'src/components/ui-kit/popconfirm';
import SubmitButton from 'src/components/ui-kit/buttons/submit';
import {
  useCreateUserMutation,
  useGetUserQuery,
  useRemoveUserRoleMutation,
  UserRoleRole,
  useUpdateUserMutation,
  Viewer,
} from 'src/graphql/generated';
import Roles from './roles';

const Container = styled(Drawer)`
  max-width: 100%;
  margin: auto;

  .ant-drawer-content-wrapper {
    width: 800px !important;
    max-width: 80%;
  }

  .ant-drawer-body {
    padding: 1rem;
  }

  > div > form > div {
    &:first-child {
      flex-grow: 1;
      margin-right: 1rem;
    }
  }

  > div > form > section {
    &:not(:first-of-type) {
      margin-top: 2rem;
    }
  }
`;
const UserProfileTitle = styled.div`
  display: flex;

  > span:first-child {
    flex-grow: 1;
  }
`;

const PaddingBottom = styled.div`
  padding-bottom: 0.5rem;
`;

export type UserProfileCardProps = {
  onSubmit?: () => void;
};

//
const UserProfileCard: FC<UserProfileCardProps> = ({ onSubmit }) => {
  const { userId } = useParams();
  const { t } = useTranslation();
  const onError = useApolloError();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { resetFields, setFieldsValue } = form;
  const onProfileCardClose = useCallback(() => {
    resetFields();
    navigate(-1);
  }, [resetFields, navigate]);
  //
  const viewer = useContext(ViewerContext) as Viewer;
  const viewerRole = useContext(ViewerRoleContext) as UserRoleRole;
  const { adminInCompanies, adminInProjects } = getRole({ viewer, viewerRole });
  const isGlobalAdmin = viewer?.role === 'ADMIN';
  const isAdmin = viewerRole === 'ADMIN';
  //
  const companyQueryVars = useMemo(() => {
    if (viewerRole === 'ADMIN') {
      return {};
    }
    const companyIds = compact([...adminInCompanies.map(c => c.id), ...adminInProjects.map(p => p?.company?.id)]);
    const filter = { id: companyIds };

    return { filter };
    //
  }, [viewerRole, adminInCompanies, adminInProjects]);
  //
  const [selectedCompany, setSelectedCompany] = useState<Viewer['primaryCompanyId']>(null);
  const onValuesChange = useCallback(
    (changedValues: Partial<Viewer>) => {
      logger('UserRoles.onValuesChange', {
        changedValues,
      });
      if (changedValues.primaryCompanyId) {
        setSelectedCompany(changedValues.primaryCompanyId);
        if (selectedCompany) {
          setFieldsValue({ primaryProjectId: null });
        }
      }
    },
    [setFieldsValue, selectedCompany],
  );
  //
  const { data: queryResponse, loading } = useGetUserQuery({
    variables: { id: userId! },
    skip: !userId || userId === 'new',
    fetchPolicy: 'cache-and-network',
  });
  const data = useMemo(() => queryResponse?.getUser, [queryResponse?.getUser]);
  //
  const [createUser, { loading: creating }] = useCreateUserMutation({
    update: updateAfterMutation('User', 'getUsers'),
    onError,
    onCompleted(res) {
      navigate(`/admin/users/${res?.createUser?.id}`);
    },
  });
  const [updateUser, { loading: updating }] = useUpdateUserMutation({
    onError,
    onCompleted() {
      if (typeof onSubmit === 'function') {
        resetFields();
        onSubmit();
      }
    },
  });
  const updatingOrCreating = creating || updating;

  const [removeUser, { loading: removing }] = useRemoveUserRoleMutation({
    onError,
    onCompleted(_res) {
      onProfileCardClose();
    },
    update: updateAfterMutation('User', 'getUsers'),
    variables: {
      id: userId!,
    },
  });

  // useEffect(() => {
  //   resetFields();
  // }, [userId, resetFields]);

  useEffect(() => {
    if (data) {
      setFieldsValue({ ...data?.profile, role: data?.role });
    } else {
      setFieldsValue({ role: 'USER' });
    }
  }, [setFieldsValue, data]);
  //
  const onFinishForm = useCallback(
    (formData: any) => {
      const onlyChangedFields = true;
      const input = parseFormData(form, formData, onlyChangedFields);
      console.log('onFinishForm', { input });
      //
      logger('Create/Update user submit', {
        formData,
        input,
      });

      if (userId && userId !== 'new') {
        updateUser({ variables: { id: userId, input } }).catch(logger);
        return;
      }
      createUser({ variables: { input } }).catch(logger);
    },
    [form, userId, createUser, updateUser],
  );
  //
  return (
    <Container placement="right" size="large" closable={false} onClose={onProfileCardClose} open={!!userId}>
      <Scrollbar>
        {!loading && !data && userId !== 'new' && <Empty description={t('Failed to fetch user data')} />}
        {!(!loading && !data && userId !== 'new') && (
          <Spinner spinning={loading}>
            <>
              <UserProfileTitle>
                <span>{data ? t('User info') : t('Create new user')}</span>
                {!!data && (
                  <RestrictedArea areaType="block" allowedRoles={['ADMIN']}>
                    <Popconfirm
                      placement="bottomLeft"
                      title={`${t('Remove')}?`}
                      onClick={stopEventPropagation}
                      onConfirm={removeUser as unknown as PopconfirmProps['onConfirm']}>
                      <Button loading={removing} danger>
                        {t('Remove')}
                      </Button>
                    </Popconfirm>
                  </RestrictedArea>
                )}
              </UserProfileTitle>

              <Form form={form} onFinish={onFinishForm} onValuesChange={onValuesChange} layout="vertical">
                <Row>
                  <Col md={11}>
                    <Divider>{t('Main info')}</Divider>
                    <FormItem
                      style={{ display: !isGlobalAdmin ? 'none' : undefined }}
                      label={t('Global role')}
                      name="role"
                      rules={[{ required: true, message: t('Please fill this field') }]}>
                      <RoleSelector disabled={!isGlobalAdmin} />
                    </FormItem>
                    <FormItem
                      label={t('Email')}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: t('Please fill this field'),
                        },
                      ]}>
                      <Input type="email" disabled={!isAdmin} />
                    </FormItem>
                    <FormItem
                      label={t('First name')}
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: t('Please fill this field'),
                        },
                      ]}>
                      <Input />
                    </FormItem>
                    <FormItem label={t('Last name')} name="lastName">
                      <Input />
                    </FormItem>
                    {userId === 'new' && (
                      <>
                        <FormItem
                          name="primaryCompanyId"
                          label={t('Company')}
                          rules={[{ required: !isGlobalAdmin, message: t('Please fill this field') }]}>
                          <CompanySelector queryVariables={companyQueryVars} />
                        </FormItem>
                        <FormItem
                          name="primaryProjectId"
                          label={t('Project')}
                          dependencies={['primaryCompanyId']}
                          rules={[{ required: !isGlobalAdmin, message: t('Please fill this field') }]}>
                          <ProjectSelector disabled={!selectedCompany} companyId={selectedCompany} />
                        </FormItem>
                      </>
                    )}
                  </Col>
                  <Col md={{ span: 11, offset: 2 }}>
                    <Divider>{t('Additional info')}</Divider>

                    <FormItem label={t('Comments')} name="comments">
                      <TextArea rows={8} placeholder="" />
                    </FormItem>
                  </Col>
                </Row>
                <Divider />
                <PaddingBottom>
                  <SubmitButton loading={updatingOrCreating}>{data ? t('Save') : t('Create')}</SubmitButton>
                </PaddingBottom>
              </Form>
            </>
            {!!data && <Roles userId={data.id} />}
          </Spinner>
        )}
      </Scrollbar>
    </Container>
  );
};

UserProfileCard.displayName = 'UserProfileCard';

export default memo(UserProfileCard);
