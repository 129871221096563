import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import time from 'src/helpers/time';
import { CLOUDINARY_CLOUDNAME } from 'src/constants';
import {
  GetScheduleQuery,
  Schedule as ScheduleType,
  ScreenOrientation,
  ScreenRatio,
  useGetFullScheduleTreeQuery,
  useGetScreenRatiosQuery,
  Timeslot as TimeslotType,
  MediaItem,
} from 'src/graphql/generated';
import ModalPopup from 'src/components/ui-kit/modal-popup';
import Spinner from 'src/components/ui-kit/spinner';
import { colorTheme } from 'src/components/ui-kit/theme';

import playImage from 'static/images/play.png';

import { weekdays } from 'player/constants';
import { DEFAULT_RATIO } from 'src/components/routes/private/template-editor/constants';
import { getContent } from 'player/scheduler';
import useInterval from 'player/hooks/use-interval';
import SchedulePlayer from 'player/index';
import ScheduleInfoPlate from './schedule-info-plate';

export const Card = styled.div.attrs({
  className: 'screen-card',
})`
  position: relative;
  cursor: pointer;
  border-radius: 0.625rem;
  overflow: hidden;
  background-color: #000000;
  box-shadow: none;
  min-height: 8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const VideoRatio = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 0.625rem;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const PlayIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 6px;
`;

const PlayButton = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  overflow: hidden;
  border-radius: 0.625rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${colorTheme.light};
  backdrop-filter: brightness(65%) blur(0.125rem);
  background-clip: padding-box;

  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;

const PlayerContainer = styled.div`
  margin: 0 auto;
  overflow: hidden;
`;

export type PlayerProps = {
  loading: boolean;
  previewScale: number;
  size: { baseWidth?: number; baseHeight?: number };
  children?: React.ReactNode;
};

const Player = styled.div<PlayerProps>`
  width: ${({ size }) => (size && size.baseWidth ? `${size.baseWidth}px` : '64rem')};
  height: ${({ size }) => (size && size.baseHeight ? `${size.baseHeight}px` : '36rem')};
  transform: scale(${({ previewScale }) => `${previewScale}`}) translateX(-50%);
  transform-origin: left top;
  position: absolute;
  left: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background: ${({ loading }) => (loading ? '#ffffff' : 'rgba(0, 0, 0, 1)')};
` as unknown as React.FC<PlayerProps>;

export type SchedulePreviewThumbnailProps = {
  scheduleId: string;
  preview?: GetScheduleQuery['getSchedule']['defaultMediaItem'] | null;
  screenRatio?: ScheduleType['screenRatio'];
};

const SchedulePreviewThumbnail: FC<SchedulePreviewThumbnailProps> = p => {
  const { t } = useTranslation();
  const { scheduleId, preview, screenRatio, ...props } = p;

  const [isModalVisible, setModalVisible] = useState(false);
  const [everydaySchedule, setEverydaySchedule] = useState<TimeslotType[]>([]);
  const [weekdaySchedule, setWeekdaySchedule] = useState({});
  const [activeTimeslot, setActiveTimeslot] = useState<TimeslotType | null>(null);
  const [defaultMediaItem, setDefaultMediaItem] = useState<MediaItem | null>(null);
  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const { data: screenRatiosData, loading: loadingScreenRatios } = useGetScreenRatiosQuery({ skip: !isModalVisible });
  const { data: scheduleTreeData, loading: loadingScheduleTree } = useGetFullScheduleTreeQuery({
    variables: { scheduleId, weekOfYear: time().isoWeek() },
    fetchPolicy: 'cache-and-network',
  });
  const loading = loadingScheduleTree || loadingScreenRatios;
  const screenRatios = screenRatiosData?.getScreenRatios;
  const size = useMemo<ScreenRatio | null>(
    () => screenRatios?.find(({ id }) => id === screenRatio) ?? null,
    [screenRatios, screenRatio],
  );
  const modalSize = useMemo<{ width: string; height: string; scale: number }>(() => {
    const width = 72;
    const height = 45;
    const modalPadding = '6.5rem';
    if (!size) {
      return { width: `${width}rem`, height: `${height}rem`, scale: 1 };
    }
    const { baseWidth, baseHeight } = size;
    const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    if (size.orientation === ScreenOrientation.Landscape) {
      const s = baseWidth / baseHeight;
      const onScreenWidth = (width - 4) * fontSize;
      return {
        width: `${width}rem`,
        height: `calc(${width / s}rem + ${modalPadding})`,
        scale: onScreenWidth / baseWidth,
      };
    }
    const s = baseHeight / baseWidth;
    const onScreenHeight = (height - 4) * fontSize;
    return {
      width: `calc(${height / s}rem + ${modalPadding})`,
      height: `calc(${height}rem + ${modalPadding})`,
      scale: onScreenHeight / baseHeight,
    };
  }, [size]);
  const scheduleTree = scheduleTreeData?.getFullScheduleTree;

  const isVideo = (preview && preview.type) === 'VIDEO';
  let previewSrc = preview ? preview.url : null;
  if (isVideo) {
    previewSrc = `${preview!.projectId}/${preview!.fileId}`;
  }
  const openPreview = useCallback(() => setModalVisible(true), []);

  function setContent() {
    if (!isModalVisible) {
      return;
    }

    getContent(
      { locationId: '1' },
      () => everydaySchedule,
      () => weekdaySchedule,
      Math.abs(time().utcOffset()),
    )
      .then(timeslot => {
        if (timeslot?.id === activeTimeslot?.id) {
          return;
        }
        setActiveTimeslot(timeslot);
      })
      .catch(console.error);
  }

  useEffect(() => {
    const { timeslots, defaultMediaItem: scheduleDefaultMediaItem } = scheduleTree || {};
    if (scheduleDefaultMediaItem) {
      setDefaultMediaItem(scheduleDefaultMediaItem as Omit<MediaItem, 'folder'>);
    }

    const everydayTimeslots = filter(timeslots, timeslot => timeslot.type === 'DAY');
    const weekdayTimeslots = filter(timeslots, timeslot => timeslot.type === 'WEEKDAY' || timeslot.type === 'DATE');
    const groupByWeekday = groupBy([...weekdayTimeslots], deviceSchedule => {
      return weekdays[time(deviceSchedule.start).tz(time.tz.guess()).isoWeekday()];
    });
    setEverydaySchedule(everydayTimeslots);
    setWeekdaySchedule(groupByWeekday);
  }, [scheduleTree]);

  useEffect(() => {
    setContent();
  }, [isModalVisible]);

  useInterval(
    () => {
      setContent();
    },
    isModalVisible ? 1000 : null,
  );

  return (
    <Card {...props}>
      {!!previewSrc && !isVideo && (
        <VideoRatio>
          <Image src={previewSrc} />
        </VideoRatio>
      )}

      <PlayButton onClick={openPreview}>
        <PlayIcon src={playImage} />
        <span>{t('Click to start preview')}</span>
      </PlayButton>
      <ModalPopup
        destroyOnClose
        isVisible={isModalVisible}
        onCancel={closeModal}
        title={t('Preview')}
        width={modalSize.width}
        height={modalSize.height}>
        <PlayerContainer>
          <ScheduleInfoPlate
            name={scheduleTree?.name}
            timeslotType={activeTimeslot?.type}
            defaultTimeslot={!!defaultMediaItem}
          />
          <Player size={size!} loading={loading} previewScale={modalSize.scale}>
            <Spinner spinning={loading} />
            <SchedulePlayer
              deviceName="Browser"
              deviceType="chrome"
              orientation={0}
              invertVideoRotation={false}
              locationId="1"
              absTzOffset={Math.abs(time().utcOffset())}
              resolution={{ width: 1920, height: 1080 }}
              ratio={screenRatio ?? DEFAULT_RATIO}
              activeTimeslot={activeTimeslot}
              defaultMediaItem={defaultMediaItem}
            />
          </Player>
        </PlayerContainer>
      </ModalPopup>
    </Card>
  );
};

SchedulePreviewThumbnail.displayName = 'SchedulePreviewThumbnail';

export default memo(SchedulePreviewThumbnail);
