import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery, logger } from '@fjedi/graphql-react-components';
import { IDType } from 'src/store/prop-types';
import getTimezonesQuery from 'src/graphql/queries/get-timezones.graphql';
import Select, { Option } from 'src/components/ui-kit/select';

export const TimezoneSelectorElement = styled(Select)`
  min-width: 19rem;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    //border-radius: 0.5rem;
    //height: 2.25rem;
  }

  .ant-select-selector {
    &:before {
      content: '';
      /* width: 20px; */
      /* height: 20px; */
      display: block;
      margin-right: 10px;
    }
  }
`;

export const TimezoneSelector = memo(props => {
  const { value: initialValue, style, className, disabled, onBlur, queryVariables, defaultValue } = props;
  const [selectedTimezone, setTimezoneId] = useState(initialValue);
  const { t } = useTranslation();
  //
  useEffect(() => {
    if (initialValue !== selectedTimezone) {
      setTimezoneId(initialValue);
    }
  }, [initialValue]);
  //
  const onSearch = useCallback(v => logger({ message: 'Search by timezone key', value: v }), []);
  // ToDo: Do we need to filter on server-side?
  const filterOption = useCallback(
    (input, option) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    [],
  );
  //
  const onChange = useCallback(
    v => {
      if (typeof props.onChange === 'function') {
        props.onChange(v);
      } else {
        setTimezoneId(v);
      }
    },
    [selectedTimezone],
  );
  const { data: queryResult } = useQuery(getTimezonesQuery, { variables: queryVariables });
  const timezones = queryResult?.getTimezones || {};
  //
  return (
    <TimezoneSelectorElement
      disabled={disabled}
      style={style}
      className={className}
      value={selectedTimezone}
      onBlur={onBlur}
      onChange={onChange}
      onSearch={onSearch}
      showSearch
      filterOption={filterOption}
      placeholder={t('Choose timezone')}>
      {[]
        .concat(
          defaultValue ? [defaultValue] : [],
          Object.keys(timezones).map(value => ({ value, label: timezones[value] })),
        )
        .map(({ value, label }) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
    </TimezoneSelectorElement>
  );
});

TimezoneSelector.propTypes = {
  value: IDType,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
};
TimezoneSelector.defaultProps = {
  value: '',
  onChange: undefined,
  onBlur: undefined,
  disabled: false,
  defaultValue: null,
};

export default TimezoneSelector;
