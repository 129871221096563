/* eslint-disable security/detect-object-injection */
import React, { useCallback, useEffect, useMemo, useState, NamedExoticComponent } from 'react';
import {
  getDataFromResponse,
  logger,
  LazyQueryResultTuple,
  useSubscribeToMore,
  OperationVariables,
  DocumentNode,
  SubscribeToMoreProps,
  Mutation,
  updateAfterMutation,
  getListKeyFromDataType,
} from '@fjedi/graphql-react-components';
import { Table as AntTable, TablePaginationConfig, TableProps as AntTableProps } from 'antd';
import type {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  ColumnType,
  SortOrder,
} from 'antd/lib/table/interface';
// CSS styles
import styled, { createGlobalStyle } from 'styled-components';
import { Pagination, Sort, SortDirection } from 'src/graphql/generated';
import FilterDropdown from 'src/components/ui-kit/table/filter-dropdown';
import { SearchOutlined } from 'src/components/ui-kit/icons';
import { isEmpty, stopEventPropagation } from 'src/functions';
import DeleteButton from 'src/components/ui-kit/buttons/delete';
import { Popconfirm } from 'src/components/ui-kit/popconfirm';
import { EditableRow, EditableCell } from './editable-row';

export interface TableQueryVariables extends OperationVariables {
  filter?: Record<string, unknown> | null;
  sort?: Sort | null;
  pagination?: Pagination | null;
}

export type StringKeyOf<T extends NonNullable<unknown> = NonNullable<unknown>> = Extract<keyof T, string>;

export type TableRow = { id: string };

export type TableData<T = TableRow> = {
  rows: T[];
  count: number;
  pageInfo: {
    current: number;
    total: number;
  };
};

export interface TableColumn<T extends TableRow = TableRow>
  extends Omit<ColumnType<T>, 'render' | 'columnKey' | 'title' | 'onHeaderCell'> {
  title: string;
  render?: (_columnValue: string, _row: T) => React.ReactNode;
  columnKey?: string;
  sorterKey?: string;
  editable?: boolean | ((_row: T) => boolean);
  removable?: boolean | ((_row: T) => boolean);
  onChangeField?: (_id: string, _input: Record<string, unknown>) => void;
  inputAlwaysVisible?: boolean;
  input?: NamedExoticComponent<any>;
  inputProps?: (_row: T) => Record<string, unknown>;
  convertFilterValueToQueryFilter?: (_field: FilterValue) => FilterValue;
}

export type TableProps<T extends TableRow, V extends TableQueryVariables> = Omit<
  AntTableProps<T>,
  'columns' | 'rowKey' | 'onChange'
> & {
  dataType: string;
  query: LazyQueryResultTuple<any, V>;
  variables?: V;
  subscriptionQueries?: DocumentNode[];
  fixedHeader?: boolean;
  scrollBreakpoint?: string;
  pageSize?: number;
  columns: TableColumn<T>[];
  removeRowMutationDoc?: DocumentNode;
  removalConfirmationMessage?: string;
  rowKey: StringKeyOf<T>;
  onChange?: (
    _paginationValue: TablePaginationConfig,
    _rawFilters: TableQueryVariables['filter'],
    _sorter: TableQueryVariables['sort'],
    _extra: TableCurrentDataSource<T>,
  ) => void;
};

const Table = styled(AntTable)`
  table > .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  table > .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
  table > .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  table > .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: transparent;
  }
  table > .ant-table-tbody > tr > td,
  table > .ant-table-thead > tr > th {
  }
  table > .ant-table-tbody > tr > td,
  table > .ant-table tfoot > tr > td {
    padding: 8px 16px;
  }

  .ant-table-bordered .ant-table-body > table,
  .ant-table-bordered .ant-table-fixed-left table,
  .ant-table-bordered .ant-table-fixed-right table,
  .ant-table-bordered .ant-table-header > table {
    border-top: 0;
    border-left: 0;
    border-right: 0;

    thead th:last-child {
      border-right: 0;
    }
    tbody tr td {
      border-top: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      background-color: transparent;
    }
  }
  .ant-table-thead > tr > th.ant-table-column-sort {
    background-color: transparent;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    &,
    &:focus,
    &:hover {
      background-color: transparent;
    }
  }
  .ant-table-thead > tr > th.ant-table-column-sort,
  .ant-table-thead > tr > th {
    &,
    &:focus,
    &:hover {
      color: #66bbff;
      font-family: 'Nunito', sans-serif;
      font-weight: 600;
    }
  }

  th {
    text-align: left;
  }

  .editable-row-operations {
    text-align: right;
  }

  .ant-table-placeholder {
    border: 0;
  }

  .ant-table-thead > tr > th {
    background: transparent;
  }

  //.ant-table-tbody > tr:last-of-type > td {
  //  border-color: transparent;
  //}

  @include mq(tablet) {
    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
      font-size: 13px;
    }

    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
      font-size: 12px;
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  .ant-table-filter-dropdown {
    border-radius: 0.625rem;
    background-color: #ffffff;
  }
`;

export function filterDropdown(props: object) {
  return <FilterDropdown {...props} />;
}
export function filterIcon(filtered: boolean) {
  return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />;
}

function extendCellRender<T extends TableRow>(column: TableColumn<T>, index: number, columnsCount: number) {
  const { render, width } = column;
  //
  return {
    ...column,
    render(value: string, row: T) {
      let children: React.ReactNode = false;
      if (typeof render === 'function') {
        children = render(value, row);
      } else {
        children = value;
      }
      const obj: any = {
        children,
        props: {
          width,
        },
      };
      if (row.id === 'add-new-row') {
        obj.props.colSpan = index === 0 ? columnsCount : 0;
      }
      return obj;
    },
  };
}

function setAdditionalProps<T extends TableRow>(
  column: TableColumn<T>,
  index: number,
  columnsCount: number,
  props?: { sortOrder?: SortOrder; key?: string; onCell?: TableColumn<T>['onCell'] },
) {
  return {
    ...extendCellRender(column, index, columnsCount),
    // ...column,
    ...(props ?? {}),
  };
}

function TableTemplate<T extends TableRow, V extends TableQueryVariables>(props: TableProps<T, V>) {
  const {
    pageSize: initialPageSize = 15,
    columns,
    query,
    variables,
    rowKey = 'id',
    dataType,
    bordered,
    className,
    fixedHeader,
    scrollBreakpoint,
    subscriptionQueries,
    removeRowMutationDoc,
    removalConfirmationMessage,
    onChange: onParentChange,
  } = props;
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const [sort, setSort] = useState<Sort>({
    direction: SortDirection.Desc,
    fields: ['createdAt'],
  });
  const [filter, setFilter] = useState<TableQueryVariables['filter']>();
  const onChange = useCallback(
    (
      paginationValue: TablePaginationConfig,
      rawFilters: Record<string, FilterValue | null>,
      sorter: SorterResult<T> | SorterResult<T>[],
      extra: TableCurrentDataSource<T>,
    ) => {
      logger('Параметры таблицы', {
        paginationValue,
        rawFilters,
        sorter,
        extra,
      });
      const { order, field: sortByField } = Array.isArray(sorter) ? sorter[0] : sorter;
      setPage(paginationValue.current ?? 1);
      setPageSize(paginationValue.pageSize ?? initialPageSize);
      const direction = !order || order === 'ascend' ? SortDirection.Asc : SortDirection.Desc;
      const sortFields = [sortByField || 'createdAt'] as string[];
      const sortParams = {
        fields: sortFields,
        direction,
      };
      setSort(sortParams);
      const filters: TableQueryVariables['filter'] = {};
      Object.keys(rawFilters).forEach(field => {
        const value = rawFilters[field];
        if (!value || isEmpty(value)) {
          return;
        }
        const column = columns.find(c => c.dataIndex === field);
        if (typeof column?.convertFilterValueToQueryFilter === 'function') {
          filters[field] = column.convertFilterValueToQueryFilter(value);
        } else {
          filters[field] = Array.isArray(value) && value.length === 1 ? value[0] : value;
        }
      });
      setFilter(filters);
      if (typeof onParentChange === 'function') {
        onParentChange(paginationValue, filters, sortParams, extra);
      }
    },
    [initialPageSize, columns],
  );
  const [fetchData, { data: queryResult, loading, subscribeToMore }] = query;
  const subscriptionProps = useMemo(
    () => ({
      subscriptionId: dataType,
      dataType,
      subscriptionQueries,
      subscribeToMore,
      variables,
    }),
    [dataType, variables, subscriptionQueries, subscribeToMore],
  );
  useSubscribeToMore(subscriptionProps as SubscribeToMoreProps);
  useEffect(() => {
    const offset = (page === 1 ? 0 : ((page || 1) - 1) * pageSize) || 0;
    logger('Table.emitLazyQuery', { offset, pageSize });

    fetchData({
      variables: {
        ...(variables ?? {}),
        ...({
          sort,
          filter: {
            ...(variables?.filter ?? {}),
            ...(filter ?? {}),
          },
          pagination: {
            offset,
            limit: pageSize,
          },
        } as V),
      },
    }).catch(logger);
  }, [page, pageSize, sort, fetchData, variables, filter]);

  const { rows, count, pageInfo } = useMemo(
    () => getDataFromResponse(dataType, { withGetPrefix: true })(queryResult) as TableData<T>,
    [dataType, queryResult],
  );

  const pagination = useMemo<TablePaginationConfig>(
    () => ({
      ...pageInfo,
      total: count,
      hideOnSinglePage: true,
      pageSize,
    }),
    [pageSize, count, pageInfo],
  );

  const onCell = useCallback(
    (col: TableColumn<T>) => (record: T) => ({
      record,
      ...col,
    }),
    [],
  );

  const extendedColumns = useMemo<TableProps<T, V>['columns']>(() => {
    const removableColumn = columns.find(column => typeof column.removable !== 'undefined');
    const noRemovableRows =
      !Array.isArray(rows) ||
      rows.length === 0 ||
      rows.every(row => {
        if (!removableColumn) {
          return false;
        }
        if (typeof removableColumn.removable === 'function') {
          return !removableColumn.removable(row);
        }
        return !removableColumn.removable;
      });
    const appendRemoveColumn = !noRemovableRows && removeRowMutationDoc;
    const columnsCount = columns.length + (appendRemoveColumn ? 1 : 0);
    const sortingDirection: SortOrder = sort.direction === SortDirection.Asc ? 'ascend' : 'descend';

    const c = columns.map((col, index) =>
      setAdditionalProps<T>(col, index, columnsCount, {
        onCell: col.onCell || (col.editable ? onCell(col) : undefined),
        key: col.columnKey || col.sorterKey,
        sortOrder: col.sorterKey && sort.fields?.includes(col.sorterKey) ? sortingDirection : undefined,
      }),
    );
    if (!appendRemoveColumn) {
      return c;
    }
    return c.concat([
      {
        title: '',
        dataIndex: 'remove-row',
        render(_, row: T) {
          let isRemovable = true;
          if (typeof removableColumn?.removable === 'function') {
            isRemovable = removableColumn.removable(row);
          } else if (typeof removableColumn?.removable === 'boolean') {
            isRemovable = removableColumn.removable;
          }
          if (!isRemovable) {
            return '';
          }
          const update = updateAfterMutation(dataType, getListKeyFromDataType(dataType, { withGetPrefix: true }));

          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            <Mutation update={update} variables={{ id: row[rowKey] }} mutation={removeRowMutationDoc}>
              {(mutation, { loading: removing }) => (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                <Popconfirm title={removalConfirmationMessage} onConfirm={mutation} onClick={stopEventPropagation}>
                  <DeleteButton loading={removing} />
                </Popconfirm>
              )}
            </Mutation>
          );
        },
        width: 60,
      },
    ]);
  }, [
    rows,
    removeRowMutationDoc,
    rowKey,
    columns,
    dataType,
    removalConfirmationMessage,
    onCell,
    sort.direction,
    sort.fields,
  ]);

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    [],
  );

  if (!columns?.length) {
    return null;
  }

  return (
    <>
      <GlobalStyle />
      <Table
        showHeader={columns.some(c => c.title)}
        bordered={bordered}
        onChange={onChange}
        className={className}
        rowKey={rowKey}
        scroll={{ y: fixedHeader ? '500px' : undefined, x: scrollBreakpoint }}
        loading={loading}
        pagination={pagination}
        columns={extendedColumns}
        components={components}
        dataSource={rows}
      />
    </>
  );
}

export default TableTemplate;
