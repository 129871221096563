import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'src/helpers/logger';
import { Tooltip } from 'antd';
import Button from 'src/components/ui-kit/buttons';
import { CopyIcon } from 'src/components/ui-kit/icons';
import type { MediaFolderProfile } from 'src/components/ui-kit/media-item/media-item';

export type PasteButtonProps = {
  folder: MediaFolderProfile;
  hasClipboardData?: boolean;
  loading?: boolean;
  onPaste: (_f: MediaFolderProfile) => void;
};

const PasteButton: FC<PasteButtonProps> = ({ loading, folder, hasClipboardData, onPaste }) => {
  const { t } = useTranslation();

  const onClick = useCallback(
    (_event?: React.MouseEvent) => {
      _event?.preventDefault();
      _event?.stopPropagation();

      logger('PasteButton.onClick', { folder, onPaste, hasClipboardData });

      if (hasClipboardData && typeof onPaste === 'function') {
        onPaste(folder);
      }
    },
    [hasClipboardData, folder, onPaste],
  );

  return (
    <Tooltip title={t('Paste')} placement="left">
      <Button
        type="link"
        size="small"
        loading={loading}
        onClick={onClick}
        icon={<CopyIcon showDot={hasClipboardData} />}
      />
    </Tooltip>
  );
};

export default memo(PasteButton) as typeof PasteButton;
