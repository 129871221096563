import React, { memo, useMemo, FC } from 'react';
import styled from 'styled-components';
import { RiLayoutMasonryLine, RiPlayList2Line, RiTimeLine } from 'react-icons/ri';
import { Link } from '@fjedi/react-router-helpers';
import { UsageType, UsedItem } from 'src/graphql/generated';

const UsageItem = styled.div`
  border-radius: 10px;
  overflow: hidden;
  margin-top: 0.625rem;
  bottom: 0;
  display: flex;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  padding: 0 8px 0 8px;
  height: 50px;
  line-height: 50px;
  background: rgba(0, 0, 0, 0.9);
  cursor: pointer;
`;

const UsageItemIconWrapper = styled.div`
  margin-right: 0.675em;
  align-items: center;
  display: inline-flex;
  color: rgba(255, 255, 255, 1);
`;

const ItemTitle = styled.span`
  color: rgba(255, 255, 255, 1);
`;

const MediaItemUsageListItem: FC<{ usage: UsedItem }> = props => {
  const { usage } = props;

  const icon = useMemo(() => {
    switch (usage.type) {
      case UsageType.Playlist:
        return <RiPlayList2Line />;
      case UsageType.DayTimeslot:
      case UsageType.DateTimeslot:
      case UsageType.WeekdayTimeslot:
      case UsageType.EventsTimeslot:
        return <RiTimeLine />;
      default:
        return <RiLayoutMasonryLine />;
    }
  }, [usage.type]);

  const link = useMemo(() => {
    switch (usage.type) {
      case 'PLAYLIST':
        return `/playlist/${usage.id}`;
      case UsageType.DayTimeslot:
        return `/schedules/${usage.id}/day`;
      case UsageType.DateTimeslot:
        return `/schedules/${usage.id}/calendar`;
      case UsageType.WeekdayTimeslot:
        return `/schedules/${usage.id}/week`;
      case UsageType.EventsTimeslot:
        return `/schedules/${usage.id}/events`;
      default:
        return `/template/${usage.id}`;
    }
  }, [usage]);
  //
  return (
    <UsageItem key={usage.id} as={Link} to={link}>
      <UsageItemIconWrapper>{icon}</UsageItemIconWrapper>
      <ItemTitle>{usage.title}</ItemTitle>
    </UsageItem>
  );
};

MediaItemUsageListItem.displayName = 'MediaItemUsageListItem';

export default memo(MediaItemUsageListItem);
