import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import logger from 'src/helpers/logger';
import styled from 'styled-components';
//
import { Upload } from 'antd';
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';
//
const Dragger = styled(Upload.Dragger)`
  display: inline-block;
  max-width: 100%;
  border-radius: 0.25rem !important;

  & + & {
    margin-left: 1.8rem;
  }

  &.ant-upload.ant-upload-drag {
    border: 2px dashed ${colorGrays.gray300};

    .ant-upload {
      padding: 2rem 0;
    }

    &:hover {
      border-color: ${colorTheme.primary};
    }
  }

  &.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: ${colorTheme.secondary};
  }

  > .ant-upload.ant-upload-drag {
    width: 400px;
    max-width: 100%;
    height: 220px;
    border-radius: 3px;
    border: dashed 1px #edd92e;
    background-color: #fbfbfb;
    display: inline-block;
  }
`;

const FileUploadDragger = ({ children, ...props }) => <Dragger {...props}>{children}</Dragger>;
FileUploadDragger.propTypes = {
  children: PropTypes.func,
};
FileUploadDragger.defaultProps = {
  children: null,
};

export default compose(pure)(FileUploadDragger);
