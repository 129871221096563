/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import { formatDate, time, TimeInstance } from 'src/helpers/time';
import logger from 'src/helpers/logger';
import { EventLocation as RawEventLocation } from 'src/graphql/generated';
import type { DurationInstance, Event, EventLocation } from './events';
import { EventTimeFields } from './events';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export function getDateRangeFromEvent({ start, end }: Pick<Event, 'start' | 'end'>, dateFormat?: string): string[] {
  const format = dateFormat || DEFAULT_DATE_FORMAT;
  const startDate = time(start).utc();
  const endDate = time(end).utc();
  const eventRange = [];

  let diff = endDate.diff(startDate, 'd');
  do {
    const range = endDate.date(endDate.date() - diff);
    eventRange.push(formatDate(range, format));
    diff -= 1;
  } while (diff > 0);

  return eventRange;
}

export function sortEventsByDate(eventA: EventTimeFields, eventB: EventTimeFields): number {
  const eventAOffset =
    typeof eventA.offsetForShowingBefore === 'string'
      ? parseInt(eventA.offsetForShowingBefore, 10)
      : eventA.offsetForShowingBefore ?? 0;
  const eventBOffset =
    typeof eventB.offsetForShowingBefore === 'string'
      ? parseInt(eventB.offsetForShowingBefore, 10)
      : eventB.offsetForShowingBefore ?? 0;

  const eventAStart = time(eventA.start);
  const eventBStart = time(eventB.start);

  const startA = eventAStart.set('h', eventAStart.hour() - eventAOffset).unix() ?? 0;
  const startB = eventBStart.set('h', eventBStart.hour() - eventBOffset).unix() ?? 0;

  return startA - startB;
}

export function getEventDurationTime(
  event: Pick<Event, 'start' | 'end' | 'offsetForShowingBefore' | 'offsetForShowingAfter'>,
): DurationInstance {
  const eventOffsetStart =
    typeof event.offsetForShowingBefore === 'string'
      ? parseInt(event.offsetForShowingBefore, 10)
      : event.offsetForShowingBefore ?? 0;
  const eventOffsetEnd =
    typeof event.offsetForShowingAfter === 'string'
      ? parseInt(event.offsetForShowingAfter, 10)
      : event.offsetForShowingAfter ?? 0;

  const eventStart = time(event.start);
  const eventEnd = time(event.end);

  const from = eventStart.set('h', eventStart.hour() - eventOffsetStart);
  const to = eventEnd.set('h', eventEnd.hour() + eventOffsetEnd);

  return { from, to };
}

export function getEventLocationOccupiedTime(location?: {
  events?: Pick<Event, 'start' | 'end' | 'offsetForShowingBefore' | 'offsetForShowingAfter'>[] | null;
}): DurationInstance[] {
  const events = location?.events ?? [];
  const occupiedTime = events.map(event => getEventDurationTime(event));

  return occupiedTime;
}

export function validateEventDuration(
  eventData: Pick<Event, 'start' | 'end' | 'offsetForShowingBefore' | 'offsetForShowingAfter'>,
  occupiedTime: DurationInstance[],
): boolean {
  if (!occupiedTime.length) return true;

  const { from: start, to: end } = getEventDurationTime(eventData);
  const isEventDurationInvalid = occupiedTime.some(({ from, to }) => {
    const isBeginningInvalid = start.isBetween(from, to) || start.isSame(from);
    const isEndingInvalid = end.isBetween(from, to) || end.isSame(to);

    return isBeginningInvalid || isEndingInvalid;
  });

  return !isEventDurationInvalid;
}

export function getMaxValueForOffset(startDate: TimeInstance, timeStart: TimeInstance, timeEnd: TimeInstance) {
  const date = startDate.clone();

  const dayStart = date.startOf('day');
  const dayEnd = date.endOf('day');

  const fullStart = date.hour(timeStart.hour()).minute(timeStart.minute());
  const fullEnd = date.hour(timeEnd.hour()).minute(timeEnd.minute());

  const maxOffsets = {
    before: Math.abs(dayStart.diff(fullStart, 'hours')),
    after: dayEnd.diff(fullEnd, 'hours'),
  };

  return maxOffsets;
}
