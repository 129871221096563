import styled from 'styled-components';
import { Tabs as AntTabs } from 'antd';
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';

export const Tabs = styled(AntTabs)`
  &.ant-tabs,
  &.ant-tabs > .ant-tabs-bar,
  &.ant-tabs > .ant-tabs-bar > .ant-tabs-nav-wrap,
  &.ant-tabs > .ant-tabs-bar > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll {
    overflow: visible;
  }

  // This is added due to tabs-shaking BUG. It should be removed if it will be fixed in ant-design's CSS
  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none !important;
  }

  &.ant-tabs {
    > .ant-tabs-nav:before {
      border-bottom: 2px solid ${colorGrays.gray200};
    }
    &.ant-tabs-card {
      > .ant-tabs-nav:before {
        border-bottom: 1px solid ${colorGrays.gray300};
      }
    }
  }

  // This is REQUIRED to allow VirtualScrollbar component work properly inside Tab
  .ant-tabs-content-holder {
    display: flex;
    flex-direction: column;

    > .ant-tabs-content {
      flex-grow: 1;
    }
  }

  .ant-tabs-content {
    text-align: left;
  }
  .ant-tabs-tab {
    &,
    &.ant-tabs-tab-active {
      font-family: ${props => props.theme['font-family']};
      font-size: 0.875rem;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: ${colorGrays.gray700};
      padding: 0.75rem 1.25rem;
      margin-right: 0;
    }
  }

  .ant-tabs-tab:hover {
    color: ${colorGrays.gray800};
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    color: ${colorGrays.gray800};

    &:hover {
      color: ${colorGrays.gray800};
    }
  }

  .ant-tabs-tab.ant-tabs-tab-disabled {
    color: ${colorGrays.gray500};
  }

  .ant-tabs-ink-bar {
    background-color: ${colorTheme.primary} !important;
  }

  &.ant-tabs-card.ant-tabs-top {
    .ant-tabs-nav-list {
      flex-grow: 1;
    }
    .ant-tabs-tab {
      border: 1px solid #6a75ca;
      flex-grow: 1;
      justify-content: center;
      padding: 0;
      min-height: ${({ size }) => (size === 'small' ? '35px' : '40px')};

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:nth-last-child(2) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &:not(:first-child) {
        margin-left: -1px;
      }

      .anticon-bars {
        margin-right: 0;
      }
    }

    .ant-tabs-tab-btn {
      color: #6a75ca;
      display: flex;
      transition: color 0.2s;

      svg {
        font-size: 24px;
      }
    }

    > .ant-tabs-nav {
      margin-left: 1.875rem;
      margin-right: 1.875rem;

      &:before {
        display: none;
      }

      .ant-tabs-tab {
        &:not(:last-of-type) {
          margin-right: 0;
        }

        &.ant-tabs-tab-active {
          background-color: #6a75ca;
          border-color: #6a75ca;

          .ant-tabs-tab-btn {
            color: #ffffff;
          }
        }

        &.ant-tabs-tab-disabled {
          .ant-tabs-tab-btn {
            color: rgba(106, 117, 202, 0.2);
          }
        }
      }
    }
  }
`;

export const TabPane = styled(AntTabs.TabPane)``;
