/* eslint-disable security/detect-object-injection */
import capitalize from 'lodash/capitalize';
import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiUploadCloud2Line } from 'react-icons/ri';
import styled from 'styled-components';
import { updateAfterMutation, useApolloError, useSubscribeToMore } from '@fjedi/graphql-react-components';
import { useLocation, useParams } from '@fjedi/react-router-helpers';
import last from 'lodash/last';
import { BreadcrumbContext } from 'src/components/ui-kit/breadcrumb';
import {
  Device,
  GetEventsQueryVariables,
  GetTimeslotsQueryVariables,
  MediaFolderItem,
  TimeslotType,
  useCopyTimeslotMutation,
  useGetEventsQuery,
  useGetScheduleQuery,
  useGetTimeslotsQuery,
  usePushScheduleToDevicesMutation,
} from 'src/graphql/generated';
//
import logger from 'src/helpers/logger';
import { formatDate, isSameDayForAnotherTimezone, time, TimeInstance } from 'src/helpers/time';
//
import eventChangedSubscription from 'src/graphql/subscriptions/event-changed.graphql';
import eventCreatedSubscription from 'src/graphql/subscriptions/event-created.graphql';
import eventRemovedSubscription from 'src/graphql/subscriptions/event-removed.graphql';
import timeslotChangedSubscription from 'src/graphql/subscriptions/timeslot-changed.graphql';
import timeslotCreatedSubscription from 'src/graphql/subscriptions/timeslot-created.graphql';
import timeslotRemovedSubscription from 'src/graphql/subscriptions/timeslot-removed.graphql';
//
import DeviceSelector from 'src/components/common/device-selector';
import ContentCard, { TabExtraActionsContainer } from 'src/components/ui-kit/admin-layout/content-card';
import RightSider from 'src/components/ui-kit/admin-layout/right-sider';
import RightBlock from 'src/components/ui-kit/aside/aside-media-manager';
import DatePicker, { DatePickerProps } from 'src/components/ui-kit/datepicker';
import { notificationsContext, NotificationType, NOTIFICATION_TYPES } from 'src/components/ui-kit/notifications';
import Popconfirm from 'src/components/ui-kit/popconfirm';
import Scrollbar from 'src/components/ui-kit/scrollbar';
import Tooltip from 'src/components/ui-kit/tooltip';
import Zoom from 'src/components/ui-kit/zoom';
import { stopEventPropagation } from 'src/functions';
import { DEFAULT_DATE_FORMAT, getDateRangeFromEvent } from 'src/components/routes/private/events/helpers';
import { ScheduleItem } from '../schedules.d';
import Button from './button';
import Calendar from './calendar';
import DefaultMediaItem from './default-media-item';
import PasteButton from './paste-timeslot-button';
import WeekdaySelector from './weekday-selector';

const TimeslotTypes = {
  day: 'DAY',
  week: 'WEEKDAY',
  calendar: 'DATE',
  events: 'EVENTS',
};

const Content = styled(ContentCard)`
  height: 100%;
  max-height: calc(100vh - 134px);
  max-width: 914px;
  min-width: 100%;
  overflow-y: hidden;

  > img {
    max-height: 100%;
  }

  .rbc-time-view {
    border: 0;
  }
  .rbc-time-header {
    display: none;
  }
  .rbc-time-header-content {
    border: 0;
  }
  .rbc-time-content {
    .rbc-today {
      background-color: transparent;

      .rbc-event {
        padding: 0.875rem 0 0;

        .rbc-event-label {
          padding: 0 1.0625rem 0;
        }

        .rbc-event-content {
          padding: 0 0.3125rem 0.75rem 1.0625rem;
          display: flex;
          flex-direction: column;
        }
      }
    }

    > * + * > * {
      border-left: 0;
    }
  }

  > .ant-card-head {
    //margin-top: 0.5rem;
  }

  &.ant-card .ant-card-body {
    padding-right: 0;

    & > div {
      margin-left: 0;
      padding-right: 0;
      padding-bottom: 1.5rem;
      width: calc(100% - 0.25rem);

      & > div:first-of-type {
        padding-right: 0;
        margin-bottom: 0 !important;
      }
    }
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Dot = styled.div<{ isCurrentMonth: boolean; isSelectedDate: boolean }>`
  position: absolute;
  bottom: 2px;
  width: 3px;
  height: 3px;
  background-color: ${props => {
    if (props.isCurrentMonth) {
      return props.isSelectedDate ? '#ffffff' : '#6473c4';
    }

    return props.isSelectedDate ? '#ffffff' : 'rgba(0, 0, 0, 0.25)';
  }};
  border-radius: 50%;
`;

const calendarViews = ['day', 'week', 'calendar', 'events'];
const tabList = [
  {
    key: 'day',
    tab: 'Daily',
  },
  {
    key: 'week',
    tab: 'Weekly',
  },
  {
    key: 'calendar',
    tab: 'Calendar',
  },
  {
    key: 'events',
    tab: 'Events',
  },
  {
    key: 'default-media-item',
    tab: 'Default',
  },
];

const MAX_ZOOM = 24 as const;
const MIN_ZOOM = 1 as const;

const ScheduleEditorPage: React.FC = () => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const location = useLocation();
  const { scheduleId } = useParams<{ scheduleId: string }>();
  //
  const { data: scheduleRes } = useGetScheduleQuery({
    variables: { id: scheduleId! },
    skip: !scheduleId,
    fetchPolicy: 'cache-and-network',
  });
  //
  const schedule = scheduleRes?.getSchedule;
  const parentBreadcrumbNameMap = useContext(BreadcrumbContext);
  const breadcrumbNameMap = useMemo(
    () => ({
      ...parentBreadcrumbNameMap,
      '/schedules': t('Schedule editor'),
      [`/schedules/${scheduleId}`]: schedule?.name,
    }),
    [parentBreadcrumbNameMap, scheduleId, schedule?.name, t],
  );
  //
  const pathnameEnd = last(location.pathname.split('/')) as keyof typeof TimeslotTypes | 'default-media-item';
  const tabURL = pathnameEnd === scheduleId ? 'day' : pathnameEnd;
  const calendarView = tabURL !== 'default-media-item' && TimeslotTypes[tabURL] ? tabURL : null;
  const timeslotType = calendarView ? (TimeslotTypes[calendarView!]! as TimeslotType) : null;
  //
  const [draggedItem, setDraggedItem] = useState<MediaFolderItem | null>(null);
  const [weekday, setWeekday] = useState(time().weekday());
  const [selectedDate, setSelectedDate] = useState(time());
  const onDateSelect = useCallback<Required<DatePickerProps>['onChange']>(date => {
    logger('Schedule date selected', { date });
    if (date) {
      setSelectedDate(date);
    }
  }, []);
  const [device, setDevice] = useState<Device | null>(null);
  const onDeviceSelect = useCallback(
    (deviceId: string, deviceInstance: Device) => {
      logger('Device selected', { deviceId, deviceInstance });
      setDevice(deviceInstance);
    },
    [setDevice],
  );
  //
  const timeslotsQueryVariables = useMemo<GetTimeslotsQueryVariables>(
    () => ({ filter: { scheduleId: scheduleId!, type: timeslotType as TimeslotType } }),
    [scheduleId, timeslotType],
  );
  const { data: timeslotsRes, subscribeToMore } = useGetTimeslotsQuery({
    variables: timeslotsQueryVariables,
    skip: !scheduleId || !timeslotType,
    fetchPolicy: 'cache-and-network',
  });
  const timeslotSubscriptionProps = useMemo(
    () => ({
      dataType: 'Timeslot',
      subscriptionId: `TimeslotsForSchedule:${scheduleId}`,
      subscriptionQueries: [
        //
        timeslotCreatedSubscription,
        timeslotChangedSubscription,
        timeslotRemovedSubscription,
      ],
      subscribeToMore,
      variables: timeslotsQueryVariables,
    }),
    [scheduleId, timeslotsQueryVariables, subscribeToMore],
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useSubscribeToMore(timeslotSubscriptionProps);
  //
  const eventsQueryVariables = useMemo<GetEventsQueryVariables>(
    () => ({
      filter: {
        locationId: device?.locationId ? [device?.locationId] : undefined,
        overlapDateRange: {
          from: time(selectedDate).startOf('day').toISOString(),
          to: time(selectedDate).endOf('day').toISOString(),
        },
      },
    }),
    [device, selectedDate],
  );
  const skipEventsQuery = timeslotType !== 'EVENTS' || !device?.locationId || !selectedDate;
  const { data: eventsRes } = useGetEventsQuery({
    variables: eventsQueryVariables,
    skip: skipEventsQuery,
    fetchPolicy: 'cache-and-network',
  });
  const eventsSubscriptionProps = useMemo(
    () => ({
      skip: skipEventsQuery,
      dataType: 'Event',
      subscriptionId: `EventsForSchedule:${scheduleId}`,
      subscriptionQueries: [
        //
        eventCreatedSubscription,
        eventChangedSubscription,
        eventRemovedSubscription,
      ],
      subscribeToMore,
      variables: eventsQueryVariables,
    }),
    [eventsQueryVariables, scheduleId, subscribeToMore, skipEventsQuery],
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useSubscribeToMore(eventsSubscriptionProps);
  //
  const onDropFromOutside = useCallback(() => {
    logger('onDropFromOutside.Timeslot started to drag');
    setDraggedItem(null);
  }, []);
  const onItemDragStart = useCallback(
    (item: MediaFolderItem) => () => {
      logger('TimeslotItem started to drag', {
        item,
      });
      setDraggedItem(item);
    },
    [],
  );

  const [calendarZoom, setCalendarZoom] = useState<number>(MIN_ZOOM);
  const handleZoomChange = useCallback((zoom: number) => {
    let targetZoom = MAX_ZOOM + 1 - zoom;

    if (targetZoom > MAX_ZOOM) {
      targetZoom = MAX_ZOOM;
    }

    if (targetZoom < MIN_ZOOM) {
      targetZoom = MIN_ZOOM;
    }

    setCalendarZoom(targetZoom);
  }, []);

  const [pushScheduleToDevices, { loading: pushingSchedule }] = usePushScheduleToDevicesMutation({
    variables: {
      scheduleId: scheduleId!,
    },
    onError,
  });
  const { notify } = useContext(notificationsContext);
  const handlePushConfirm = useCallback(
    () =>
      pushScheduleToDevices().then(({ data }) => {
        if (!data?.pushScheduleToDevices) {
          return;
        }
        const { error, status } = data.pushScheduleToDevices;
        const notificationText = capitalize(error ?? status ?? '');
        if (!notificationText) {
          return;
        }
        const notificationType =
          status === 'FAILURE' ? NOTIFICATION_TYPES.ERROR : ((status ?? '').toLowerCase() as NotificationType);
        notify(notificationText, {
          type: notificationType,
        });
      }, logger),
    [pushScheduleToDevices, notify],
  );

  const Actions = useMemo(
    () => (
      <TabExtraActionsContainer>
        {tabURL !== 'default-media-item' && (
          <Zoom min={MIN_ZOOM} max={MAX_ZOOM} initial={calendarZoom} onChange={handleZoomChange} />
        )}
        <Popconfirm
          placement="bottomRight"
          title={`${t('Push updates to all related devices')}?`}
          onClick={stopEventPropagation}
          onConfirm={handlePushConfirm}
          okText={t('Yes')}
          cancelText={t('No')}>
          <Tooltip title={t('Push updates')} align={{ offset: [0, 2.5] }}>
            <Button loading={pushingSchedule}>
              <RiUploadCloud2Line />
            </Button>
          </Tooltip>
        </Popconfirm>
      </TabExtraActionsContainer>
    ),
    [tabURL, calendarZoom, handleZoomChange, t, pushingSchedule, handlePushConfirm],
  );
  //
  const currentDate = useMemo(() => {
    if (timeslotType === 'DATE') {
      return selectedDate;
    }
    if (timeslotType === 'WEEKDAY') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return time.tz().weekday(weekday);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return time.tz();
  }, [timeslotType, weekday, selectedDate]);

  const [hasClipboardTimeslot, setHasClipboardTimeslot] = useState<boolean>(false);

  const events = useMemo(() => {
    const timeslots = timeslotsRes?.getTimeslots || [];
    const year = time().year();
    const dayOfYear = time().dayOfYear();
    //
    return timeslots
      .filter(timeslot => {
        if (timeslot.type !== timeslotType) {
          return false;
        }
        if (timeslotType === 'EVENTS') {
          return timeslot.deviceId && device?.id && timeslot.deviceId === device?.id;
        }
        if (timeslotType !== 'WEEKDAY') {
          return true;
        }
        return (
          time(
            timeslot.start,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.timezone,
          ).weekday() === weekday
        );
      })
      .map(timeslot => {
        let s;
        let e;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const tz = window.timezone;

        switch (timeslotType) {
          case 'DAY':
            s = timeslot.isAllDay ? time().startOf('day') : time(timeslot.start, tz).year(year).dayOfYear(dayOfYear);
            e = timeslot.isAllDay ? time().endOf('day') : time(timeslot.end, tz).year(year).dayOfYear(dayOfYear);
            break;
          case 'WEEKDAY':
            s = time(timeslot.start, tz).year(year).dayOfYear(dayOfYear).weekday(weekday);
            e = time(timeslot.end, tz).year(year).dayOfYear(dayOfYear).weekday(weekday);
            break;
          default:
            s = time(timeslot.start, tz);
            e = time(timeslot.end, tz);
        }
        // const multiDayTimeslotToIntraDayStartTime = s.date();
        // const multiDayTimeslotToIntraDayEndTime = e.date();
        // const sameDayStart = isSameDayForAnotherTimezone(s);
        // const sameDayEnd = isSameDayForAnotherTimezone(e);
        const now = time(selectedDate).isBetween(timeslot.start, timeslot.end) ? time(selectedDate) : time();
        const start = !timeslot.isMultiDay ? s : now.hour(s.hour()).minute(s.minute()).second(s.second());
        const end = !timeslot.isMultiDay ? e : now.hour(e.hour()).minute(e.minute()).second(e.second());
        //
        return {
          id: timeslot.id,
          start: start.toDate(),
          end: end.toDate(),
          resizable: timeslotType !== 'EVENTS',
          resource: {
            ...timeslot,
            zoom: calendarZoom,
            onCopy: (success: boolean) => setHasClipboardTimeslot(success),
          },
        };
      })
      .sort((a, b) => {
        return a.start.getTime() - b.start.getTime();
      });
  }, [timeslotsRes?.getTimeslots, timeslotType, weekday, device?.id, selectedDate, calendarZoom]);
  console.log('Timeslots.Events', events);
  const daysWithContent = useMemo(() => (events ?? []).flatMap(e => getDateRangeFromEvent(e.resource)), [events]);
  const renderDate = useCallback(
    (current: TimeInstance, today: TimeInstance) => {
      const isSelectedDate = formatDate(current, DEFAULT_DATE_FORMAT) === formatDate(selectedDate, DEFAULT_DATE_FORMAT);
      const hasContent = daysWithContent.includes(formatDate(current, DEFAULT_DATE_FORMAT));
      const isCurrentMonth = formatDate(current, 'YY-MM') === formatDate(today, 'YY-MM');

      return (
        <DateContainer className="ant-picker-cell-inner">
          {formatDate(current, 'DD')}
          {hasContent && <Dot isCurrentMonth={isCurrentMonth} isSelectedDate={isSelectedDate} />}
        </DateContainer>
      );
    },
    [daysWithContent, selectedDate],
  );

  const [pasteButtonLoaderStatus, togglePasteButtonLoader] = useState<boolean>(false);
  const [copyTimeslot] = useCopyTimeslotMutation({
    update: updateAfterMutation('Timeslot', 'getTimeslots'),
    onError(e) {
      onError(e);
      setHasClipboardTimeslot(false);
      togglePasteButtonLoader(false);
    },
    onCompleted() {
      onDropFromOutside();
    },
    onQueryUpdated() {
      togglePasteButtonLoader(false);
    },
  });

  const onPasteTimeslot = useCallback(() => {
    try {
      togglePasteButtonLoader(true);
      navigator?.clipboard
        ?.readText()
        // eslint-disable-next-line consistent-return
        .then(text => {
          let timeslot;
          try {
            timeslot = JSON.parse(text);
          } catch (_err) {
            setHasClipboardTimeslot(false);
            return Promise.resolve();
          }
          if (!timeslot || timeslot.type !== 'timeslot') {
            setHasClipboardTimeslot(false);
            return Promise.resolve();
          }

          const defaultTimeslotPeriod = 2;
          let startMoment = currentDate.startOf('day');
          let endMoment = startMoment.add(defaultTimeslotPeriod, 'hours');
          const projectBasedOffset = time().tz().utcOffset();
          const timeslotData = { scheduleId: scheduleId!, id: timeslot.data[0].timeslotId, type: timeslotType! };

          if (events.length === 0) {
            return copyTimeslot({
              variables: {
                input: {
                  ...timeslotData,
                  end: endMoment.add(-projectBasedOffset, 'minute').utc(true),
                  start: startMoment.add(-projectBasedOffset, 'minute').utc(true),
                },
              },
            }).then(res => logger('Timeslot has been copied successfully', res));
          }
          const endDay = currentDate.startOf('day').add(1, 'days');

          const slots = Array(1440).fill(defaultTimeslotPeriod);
          slots.some(() => {
            endMoment = startMoment.add(defaultTimeslotPeriod, 'hours');
            const existTimeslot = events.find(
              ({ start, end }) =>
                startMoment.isSame(start, 'day') &&
                (startMoment.isBetween(start, end, undefined, '[]') ||
                  endMoment.isBetween(start, end, undefined, '[]')),
            );
            if (!existTimeslot) {
              return true;
            }
            startMoment = startMoment.add(1, 'minute');
            return false;
          });
          if (endMoment.isAfter(endDay)) {
            return Promise.resolve();
          }
          const utcStart = startMoment.add(-projectBasedOffset, 'minute').utc(true);
          const utcEnd = endMoment.add(-projectBasedOffset, 'minute').subtract(1, 'second').utc(true);

          return copyTimeslot({
            variables: {
              input: {
                ...timeslotData,
                end: utcEnd,
                start: utcStart,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              copyTimeslot: {
                __typename: 'Timeslot',
                items: [],
                ...timeslotData,
                id: `${Date.now()}`,
                end: utcEnd,
                start: utcStart,
                isAllDay: utcEnd.diff(utcStart, 'seconds') >= 86395 && utcEnd.diff(utcStart, 'seconds') < 86402,
                isMultiDay: utcEnd.diff(utcStart, 'seconds') >= 86402,
              },
            },
          }).then(res => logger('Timeslot has been copied successfully', res));
        })
        .catch(err => {
          logger('Something went wrong', err);
        })
        .finally(() => {
          // maybe user didn't grant access to read from clipboard
          setHasClipboardTimeslot(false);
          togglePasteButtonLoader(false);
        });
    } catch (err) {
      logger(err as Error);
      togglePasteButtonLoader(false);
    }
  }, [currentDate, events, scheduleId, timeslotType, copyTimeslot, togglePasteButtonLoader]);

  //
  const tabBarExtraContent = useMemo(
    () => (
      <TabExtraActionsContainer>
        {(tabURL === 'day' || tabURL === 'calendar' || tabURL === 'week') && (
          <PasteButton
            loading={pasteButtonLoaderStatus}
            hasMargin={tabURL === 'calendar' || tabURL === 'week'}
            hasClipboardTimeslot={hasClipboardTimeslot}
            onPaste={onPasteTimeslot}
          />
        )}
        {tabURL === 'events' && (
          <div className="device-picker">
            <DeviceSelector
              onChange={onDeviceSelect}
              value={device?.id}
              queryVariables={{ filter: { scheduleId: [scheduleId!] } }}
            />
          </div>
        )}
        {(tabURL === 'calendar' || tabURL === 'events') && (
          <div className="datepicker">
            <DatePicker dateRender={renderDate} allowClear={false} value={selectedDate} onChange={onDateSelect} />
          </div>
        )}
        {tabURL === 'week' && <WeekdaySelector onSelect={setWeekday} value={weekday} />}
      </TabExtraActionsContainer>
    ),
    [
      tabURL,
      hasClipboardTimeslot,
      onPasteTimeslot,
      onDeviceSelect,
      device?.id,
      scheduleId,
      renderDate,
      selectedDate,
      onDateSelect,
      weekday,
      pasteButtonLoaderStatus,
    ],
  );

  return (
    <BreadcrumbContext.Provider value={breadcrumbNameMap}>
      <Content
        showBreadcrumbs
        showBackButton
        tabList={tabList}
        tabURLNavigation
        tabBarExtraContent={tabBarExtraContent}
        extra={Actions}>
        {calendarViews.includes(calendarView!) && (
          <Scrollbar>
            <Calendar
              events={events as ScheduleItem[]}
              timeslotsRes={timeslotsRes}
              eventsRes={eventsRes}
              scheduleId={scheduleId!}
              timeslotType={timeslotType!}
              zoom={calendarZoom}
              draggedItem={draggedItem}
              selectedDate={selectedDate}
              weekday={weekday}
              onDropFromOutside={onDropFromOutside}
              deviceId={device?.id}
            />
          </Scrollbar>
        )}
        {tabURL === 'default-media-item' && <DefaultMediaItem draggedItem={draggedItem} />}
      </Content>
      <RightSider>
        <RightBlock onDragStart={onItemDragStart} />
      </RightSider>
    </BreadcrumbContext.Provider>
  );
};

ScheduleEditorPage.displayName = 'ScheduleEditorPage';

export default memo(ScheduleEditorPage);
