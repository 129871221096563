import React, { FC, memo, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { RiAddLine } from 'react-icons/ri';
import type { CardProps } from 'antd';
import uniq from 'lodash/uniq';
import { useApolloError, updateAfterMutation, useMutation, ApolloError } from '@fjedi/graphql-react-components';
import updateEventLocationMutation from 'src/graphql/mutations/update-event-location.graphql';
import createEventMutation from 'src/graphql/mutations/create-event.graphql';
import removeEventMutation from 'src/graphql/mutations/remove-event.graphql';
import updateEventMutation from 'src/graphql/mutations/update-event.graphql';
import RightSider from 'src/components/ui-kit/admin-layout/right-sider';
import ContentCard from 'src/components/ui-kit/admin-layout/content-card';
import { colorTheme } from 'src/components/ui-kit/theme';
import { DropResult, DragDropContext } from 'src/components/ui-kit/drag-n-drop';
import { FixedScrollbar as Scrollbar } from 'src/components/ui-kit/scrollbar';
//
import time, { TimeInstance } from 'src/helpers/time';
import {
  CreateEventLocationInput,
  UpdateEventLocationInput,
  useCreateEventLocationMutation,
  useGetEventLocationsQuery,
  useRemoveEventLocationMutation,
} from 'src/graphql/generated';
import type { ContentCardProps, EventLocation, Event, EventTransitionData, Decision } from './events';
import WayfindingLink from './wayfinding/link-button';
import LocationCard from './location-card';
import AddLocationModal from './add-location-modal';
import AsideCalendar from './aside-calendar';
import EventTransitionModal from './event-transition-modal';
import EventsContextProvider, { EventsContextValue } from './events-context';
import {
  DEFAULT_DATE_FORMAT,
  getDateRangeFromEvent,
  getEventDurationTime,
  getEventLocationOccupiedTime,
  sortEventsByDate,
  validateEventDuration,
} from './helpers';
import LocationModalContextProvider, { LocationModalContextValue } from './location-modal-context';
import EventModalContextProvider, { EventModalContextValue } from './event-modal-context';
import AddEventModal from './add-event-modal';
import ImportEventsFromFileModal from './import-events-from-file-modal';

const Container = styled(ContentCard as FC<CardProps & ContentCardProps>)`
  .ant-card-head-title > div:first-child {
    padding: 0;

    .ant-typography {
      color: ${colorTheme.dark};
    }
  }

  .ant-card-extra > .ant-btn {
    color: ${colorTheme.dark};
  }
`;

const LocationsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-auto-flow: dense;
  gap: 1.25rem 1.25rem;
  margin-right: 1.25rem;
  align-items: stretch;
`;

export const LocationsPage: FC = memo(() => {
  const onError = useApolloError();
  const [selectedDate, setSelectedDate] = useState<TimeInstance>(time());
  const [nonEmptyDates, setNonEmptyDates] = useState<string[]>([]);
  const [startAt, setStartAt] = useState<{ from: TimeInstance; to: TimeInstance }>({
    from: selectedDate.date(selectedDate.date() - 31),
    to: selectedDate.endOf('month'),
  });
  const [eventTransitionData, setEventTransitionData] = useState<EventTransitionData>();
  const [isEventTransitionModalVisible, setIsEventTransitionModalVisible] = useState(false);

  const { t } = useTranslation();

  const {
    data: locationsRes,
    refetch: reloadLocations,
    loading: locationsLoading,
  } = useGetEventLocationsQuery({
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      filter: {},
      eventsFilter: {
        startAt,
      },
    },
  });

  const locations = useMemo(
    () =>
      (locationsRes?.getEventLocations?.rows ?? []).map(location => ({
        ...location,
        occupiedTime: getEventLocationOccupiedTime(location),
      })),
    [locationsRes],
  );

  const [createLocation] = useCreateEventLocationMutation({
    update: updateAfterMutation('EventLocation', 'getEventLocations'),
  });

  const [removeLocation] = useRemoveEventLocationMutation({
    update: updateAfterMutation('EventLocation', 'getEventLocations'),
  });

  const [updateLocation] = useMutation(updateEventLocationMutation, {});

  const [createEvent, { loading: isEventCreating }] = useMutation(createEventMutation, {
    onCompleted: reloadLocations,
    update: updateAfterMutation('Event', 'getEvents'),
  });

  const [removeEvent] = useMutation(removeEventMutation, {
    onCompleted: reloadLocations,
    update: updateAfterMutation('Event', 'getEvents'),
  });

  const [updateEvent, { loading: isEventUpdating }] = useMutation(updateEventMutation, {
    update: updateAfterMutation('Event', 'getEvents'),
  });

  const addLocation = useCallback(
    (input: CreateEventLocationInput) => createLocation({ variables: { input } }),
    [createLocation],
  );

  const deleteLocation = useCallback(
    (locationId: string) => {
      removeLocation({ variables: { id: locationId } });
    },
    [removeLocation],
  );

  const editLocation = useCallback(
    (locationId: string) => (input: UpdateEventLocationInput) =>
      updateLocation({ variables: { id: locationId, input } }),
    [updateLocation],
  );

  const [locationModalContextValue, setLocationModalContextValue] = useState<LocationModalContextValue>({
    isVisible: false,
    location: undefined,
    onSubmit: addLocation,
  });

  const openAddLocationModal = useCallback(
    () => setLocationModalContextValue({ isVisible: true, location: undefined, onSubmit: addLocation }),
    [addLocation],
  );

  const openEditLocationModal = useCallback(
    (location: Pick<EventLocation, 'id' | 'name' | 'devices' | 'operaFunctionSpaceCode'>) => () => {
      const { id, name, devices, operaFunctionSpaceCode } = location;

      setLocationModalContextValue({
        isVisible: true,
        location: { id, name, operaFunctionSpaceCode, devices },
        onSubmit: editLocation(id),
      });
    },
    [editLocation],
  );

  const closeLocationModal = useCallback(
    () => setLocationModalContextValue({ isVisible: false, onSubmit: addLocation, location: undefined }),
    [addLocation],
  );

  const [eventModalContextValue, setEventModalContextValue] = useState<EventModalContextValue>({
    isVisible: false,
    event: undefined,
    location: { id: '', occupiedTime: [] },
  });

  const openAddEventModal = useCallback(
    (locationId: string, occupiedTime: EventLocation['occupiedTime']) => () =>
      setEventModalContextValue({ isVisible: true, event: undefined, location: { id: locationId, occupiedTime } }),
    [],
  );

  const openEditEventModal = useCallback(
    (event: Event) => () => {
      const {
        id,
        location: eventLocation,
        title,
        description,
        logoId,
        logoUrl,
        logoTitle,
        start,
        end,
        offsetForShowingBefore,
        offsetForShowingAfter,
        templateId,
      } = event;

      const { id: locationId, occupiedTime } = eventLocation as EventLocation;

      const location = {
        id: locationId,
        // Remove segment occupied by current event if exists
        occupiedTime: occupiedTime.filter(segment => {
          const { from: eventStart, to: eventEnd } = getEventDurationTime({
            start,
            end,
            offsetForShowingAfter,
            offsetForShowingBefore,
          });

          return !(segment.from.isSame(eventStart) && segment.to.isSame(eventEnd));
        }),
      };

      setEventModalContextValue({
        isVisible: true,
        location,
        event: {
          id,
          title,
          description,
          logoId,
          logoUrl,
          logoTitle,
          start,
          end,
          offsetForShowingBefore,
          offsetForShowingAfter,
          templateId,
        },
      });
    },
    [],
  );

  const closeEventModal = useCallback(
    () => setEventModalContextValue({ isVisible: false, event: undefined, location: { id: '', occupiedTime: [] } }),
    [],
  );

  const warn = useCallback((text: string) => onError({ message: text } as unknown as ApolloError), [onError]);

  const eventsContextDefaultValue: EventsContextValue = useMemo(
    () => ({
      createEvent,
      removeEvent,
      updateEvent,
      editEvent: openEditEventModal,
      warn,
      isLoading: locationsLoading || isEventCreating || isEventUpdating,
    }),
    [
      createEvent,
      removeEvent,
      updateEvent,
      openEditEventModal,
      warn,
      locationsLoading,
      isEventCreating,
      isEventUpdating,
    ],
  );

  const onDragEnd = useCallback(
    ({ destination, source, draggableId }: DropResult) => {
      if (destination) {
        const eventId = draggableId;
        const [destinationId] = destination.droppableId.split('.');
        const [sourceId] = source.droppableId.split('.');

        if (destinationId !== sourceId) {
          const draggedEvent = locations
            .find(location => location.id === sourceId)
            ?.events?.find(event => event.id === eventId) as Event;

          setEventTransitionData({ destinationId, sourceId, draggedEvent, eventId });
          setIsEventTransitionModalVisible(true);
        }
      }
    },
    [locations],
  );

  const handleTransition = useCallback(
    (decision: Decision) => {
      if (eventTransitionData) {
        const { destinationId, sourceId, draggedEvent, eventId } = eventTransitionData;
        const { title, description, start, end, logoId } = draggedEvent;
        const sourceLocation = locations.find(({ id }) => id === sourceId);
        const targetLocation = locations.find(({ id }) => id === destinationId);
        const movedEvent = sourceLocation?.events?.find(({ id }) => id === eventId);

        if (sourceLocation && targetLocation && movedEvent) {
          const isEventDurationValid = validateEventDuration(movedEvent, targetLocation.occupiedTime);
          switch (decision) {
            case 'cancel':
              break;
            case 'move':
              if (isEventDurationValid)
                updateEvent({ variables: { id: eventId, input: { locationId: destinationId } } });
              else warn("Moved events' time is already occupied in target location!");

              break;
            case 'copy':
              if (isEventDurationValid) {
                createEvent({
                  variables: {
                    input: {
                      title: `${title} (${t('copy')})`,
                      description,
                      start,
                      end,
                      logoId,
                      locationId: destinationId,
                    },
                  },
                });
              } else warn("Copied events' time is already occupied in target location!");
              break;
            default:
          }
        }
      }

      setIsEventTransitionModalVisible(false);
      setEventTransitionData(undefined);
    },
    [eventTransitionData, locations, updateEvent, warn, createEvent, t],
  );

  const header = useMemo<ContentCardProps['header']>(
    () => ({
      title: selectedDate?.format('ddd, DD MMM YYYY'),
      actions: [
        {
          icon: <RiAddLine />,
          tooltipText: t('Add location'),
          onClick: openAddLocationModal,
        },
        {
          render(actionIndex) {
            return <ImportEventsFromFileModal key={actionIndex} />;
          },
        },
      ],
    }),
    [selectedDate, t, openAddLocationModal],
  );

  const handleDateChange = useCallback(
    (date: TimeInstance) =>
      setSelectedDate(prevDate => {
        if (prevDate.diff(date, 'month'))
          setStartAt({
            from: date.date(date.date() - 31),
            to: date.endOf('month'),
          });

        return date;
      }),
    [],
  );

  const locationsCount = useMemo(() => locationsRes?.getEventLocations?.count, [locationsRes]);

  useLayoutEffect(() => {
    if (!locationsCount) return;

    const events = locations.flatMap(({ events: locationEvents }) => locationEvents ?? []);

    if (!events.length) return;

    const dates = events.flatMap(event => getDateRangeFromEvent(event));
    const uniqDates = dates.length ? uniq(dates) : [];

    setNonEmptyDates(uniqDates);
  }, [locations, locationsCount]);

  return (
    <EventsContextProvider value={eventsContextDefaultValue}>
      <LocationModalContextProvider value={locationModalContextValue}>
        <EventModalContextProvider value={eventModalContextValue}>
          <Container header={header}>
            <DragDropContext onDragEnd={onDragEnd}>
              <RightSider>
                <AsideCalendar
                  selectedDate={selectedDate ?? time()}
                  nonEmptyDates={nonEmptyDates}
                  onSelect={handleDateChange}>
                  <WayfindingLink />
                </AsideCalendar>
              </RightSider>
              <Scrollbar>
                <LocationsContainer>
                  {locations.map(
                    ({ name, operaFunctionSpaceCode, id, events: locationEvents, devices, occupiedTime }) => {
                      const todayEvents =
                        locationEvents
                          ?.filter(event =>
                            getDateRangeFromEvent(event).includes(selectedDate.format(DEFAULT_DATE_FORMAT)),
                          )
                          .sort(sortEventsByDate) ?? [];

                      const locationDevices = devices?.map(({ id: deviceId }) => deviceId) ?? [];

                      return (
                        <LocationCard
                          key={id}
                          title={name}
                          id={id}
                          events={todayEvents}
                          devices={locationDevices}
                          occupiedTime={occupiedTime}
                          operaFunctionSpaceCode={operaFunctionSpaceCode}
                          onRemove={deleteLocation}
                          onEditClick={openEditLocationModal}
                          onAddEventClick={openAddEventModal}
                        />
                      );
                    },
                  )}
                </LocationsContainer>
              </Scrollbar>
            </DragDropContext>
          </Container>
          <EventTransitionModal isVisible={isEventTransitionModalVisible} handleTransition={handleTransition} />
          <AddLocationModal onClose={closeLocationModal} />
          <AddEventModal onClose={closeEventModal} />
        </EventModalContextProvider>
      </LocationModalContextProvider>
    </EventsContextProvider>
  );
});

export default LocationsPage;
