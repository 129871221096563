import { logger, useApolloError } from '@fjedi/graphql-react-components';
import { Col, Row } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import first from 'lodash/first';
import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowUpSLine, RiFilterLine, RiSearchLine } from 'react-icons/ri';
import styled from 'styled-components';
// GraphQL Queries
import {
  Device,
  DeviceCommand,
  DeviceCommandInput,
  UpdateDeviceMutationVariables,
  useDeviceChangedSubscription,
  useGetDeviceQuery,
  useGetEventLocationsQuery,
  UserRoleRole,
  useSendDeviceCommandMutation,
  useUpdateDeviceMutation,
} from 'src/graphql/generated';
import getDeviceLogsQuery from 'src/graphql/queries/get-device-logs.graphql';
//
import startIcon from 'static/images/device-empty.svg';
//
import { formatDate } from 'src/helpers/time';
//
import HelpIndicator from 'src/components/common/help-indicator';
import DeviceFWOutOfDateTooltip from 'src/components/routes/private/devices/device-fw-outofdate-tooltip';
import { DEFAULT_RATIO, DIMENSIONS, Ratio } from 'src/components/routes/private/template-editor/constants';
import { Divider } from 'src/components/ui-kit/aside';
import Button from 'src/components/ui-kit/buttons';
import { Collapse, Panel } from 'src/components/ui-kit/collapse';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input, InputNumber, PasswordInput } from 'src/components/ui-kit/input';
import ButtonModalPopupComponent from 'src/components/ui-kit/modal-popup/button-modal';
import { Scrollbar } from 'src/components/ui-kit/scrollbar';
import Select, { Option } from 'src/components/ui-kit/select';
import Spinner from 'src/components/ui-kit/spinner';
import { Switch } from 'src/components/ui-kit/switch';
import Table from 'src/components/ui-kit/table';
import { Tabs as CustomTabs } from 'src/components/ui-kit/tabs';
import { colorTheme } from 'src/components/ui-kit/theme';
import { GhostEditableTitle, Link as CustomLink } from 'src/components/ui-kit/typography';
import { Item, ItemDesc, ItemTitle } from 'src/components/ui-kit/info-item';
import DimensionsModal, { AlertIcon } from './dimensions-modal';
import FirmwareVersionModal from './firmware-version-modal';
import DeviceScreenshot from './device-screenshot';
import { ViewerRoleContext } from '@fjedi/react-router-helpers';

const Tabs = styled(CustomTabs)`
  .ant-tabs-nav-list .ant-tabs-tab {
    width: 50%;
  }
`;

const DeviceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 20rem;
  height: 100%;
  position: relative;
  border-radius: 0.625rem;
  box-shadow: 0 2px 0.875rem rgba(0, 0, 0, 0.08);
`;

const Title = styled(GhostEditableTitle)`
  &.ant-typography.ant-typography {
    font-weight: 600;
    font-size: 1.125rem;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 0;

    &.ant-typography-edit-content {
      textarea.ant-input {
        text-align: center;
      }
    }

    & > span.ghost-editable-title-text {
      margin-right: -1.5rem;
    }
  }
`;

const Header = styled.div`
  padding: 1.25rem 1.875rem;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
`;

const Search = styled(Input)`
  &.ant-input-affix-wrapper {
    height: 2.25rem;
    min-height: 2.25rem;
    width: auto;
    display: flex;
    margin: 0 1.875rem 1.25rem;
  }
`;

const FooterModal = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.875rem;

  .ant-btn {
    width: 8.75rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
`;

const Body = styled.div<{ addPadding: boolean }>`
  padding: ${({ addPadding }) => (addPadding ? '0.6875rem 0.25rem 0.6875rem 0' : 0)};
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Link = styled(CustomLink)`
  color: rgba(0, 0, 0, 0.4) !important;

  &:hover {
    color: ${colorTheme.primary} !important;
  }
`;

const FixScrollbarChild = styled.div<{ addPadding?: boolean }>`
  padding: ${({ addPadding }) => (addPadding ? '0 1.655rem 0.5rem 1.875rem' : 0)};
`;

const Start = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  justify-content: center;
`;

const StartIcon = styled.img`
  width: 15rem;
  margin-bottom: 0.5rem;
`;

const StartTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
`;

const StartDesc = styled.div`
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.2);
`;

const FormItemWithTooltip = styled(FormItem)`
  &
    > .ant-form-item-control
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > svg.icon.help-indicator {
    position: absolute;
    top: -1.25rem;
    right: 0.25rem;
  }

  label,
  .form-item-tooltip {
    width: 100%;
  }
  .form-item-tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;

    > span:first-child {
      flex-grow: 1;
    }

    svg.icon.help-indicator {
      font-size: 1rem;
    }
  }
`;

const SwitchContainer = styled.label<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 2.375rem;

  border: 1px solid #bdbdbd;
  border-radius: 0.375rem;

  padding: 0 1rem;

  cursor: ${props => (props.disabled === true ? 'not-allowed' : 'default')};

  color: ${props => (props.disabled === true ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.85)')};
  background-color: ${props => (props.disabled === true ? '#f5f5f5' : '#ffffff')};

  &:hover {
    border-color: #a3a3a3;
  }
`;

const TABS = {
  INFO: 'INFO',
  ACTIONS: 'ACTIONS',
};

const deviceCommandActionMap: Partial<Record<DeviceCommand, string>> = {
  [DeviceCommand.Reboot]: 'Reboot',
  [DeviceCommand.Reload]: 'Reload app',
  [DeviceCommand.ShowName]: 'Show name',
  [DeviceCommand.InjectDevtool]: 'Debug',
};

export type DeviceInfoProps = {
  // eslint-disable-next-line react/require-default-props
  deviceId?: string;
  onChange: (_input: UpdateDeviceMutationVariables['input']) => void;
  // eslint-disable-next-line react/require-default-props
  filterValue: string;
  onFilterChange: (_event: React.ChangeEvent<HTMLInputElement>) => void;
  isOutdated?: boolean;
};

const DeviceInfo: FC<DeviceInfoProps> = props => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const { onChange, filterValue, onFilterChange, deviceId, isOutdated = false } = props;
  const viewerRole = useContext(ViewerRoleContext) as UserRoleRole;
  const { data, loading } = useGetDeviceQuery({ skip: !deviceId, variables: { id: deviceId! } });
  useDeviceChangedSubscription({ variables: { filter: { id: deviceId ? [deviceId] : undefined } }, skip: !deviceId });
  const {
    id,
    name,
    vendor,
    model,
    appVersion,
    firmwareVersion,
    serialNumber,
    isOnline,
    updatedAt,
    schedule,
    locationId,
    props: deviceProps,
  } = data?.getDevice ?? {};
  const deviceRatio = data?.getDevice?.ratio as Ratio;
  const { network, resolution: deviceResolution, orientation } = deviceProps ?? {};
  const { mac, ip } = network?.lan ?? {};
  const { mac: wlanMac, ip: wlanIP } = network?.wifi ?? {};
  const { ip: vpnIP } = network?.vpn ?? {};

  const [activeTab, setActiveTab] = useState(TABS.INFO);
  const [deviceCommand, setDeviceCommand] = useState<DeviceCommandInput['command'] | null>(null);

  const onChangeProp = useCallback(
    (propName: keyof Device['props']) => (value: string | number) => {
      onChange({ props: { [propName]: value } });
    },
    [onChange],
  );

  const date = formatDate(updatedAt, 'DD MMMM YYYY, HH:mm');
  //
  const locationsQuery = useGetEventLocationsQuery({ variables: { filter: {} } });
  const locations = locationsQuery.data?.getEventLocations?.rows ?? [];
  //
  const [sendDeviceCommand, { loading: sendingDeviceCommand }] = useSendDeviceCommandMutation({
    onError,
  });
  const [updateDevice, { loading: deviceUpdating }] = useUpdateDeviceMutation({ onError });

  const onDeviceCommand = useCallback(
    (command: DeviceCommand, payload?: DeviceCommandInput['payload']) => {
      setDeviceCommand(command);
      return sendDeviceCommand({ variables: { deviceId: id!, input: { command, payload } } }).then(() => {
        setDeviceCommand(null);
      });
    },
    [id, setDeviceCommand, sendDeviceCommand],
  );
  //
  const [overscanForm] = Form.useForm();
  const [overscanModalStatus, setOverscanModalStatus] = useState(false);
  const openOverscanModal = useCallback(() => setOverscanModalStatus(true), []);
  const closeOverscanModal = useCallback(() => setOverscanModalStatus(false), []);
  const setOverscanSettings = useCallback(
    (fieldValues: Device['props']['overscan']) => {
      if (!id) {
        return;
      }
      updateDevice({
        variables: {
          id,
          input: {
            props: {
              overscan: fieldValues,
            },
          },
        },
      })
        .then(() => closeOverscanModal())
        .catch(logger);
    },
    [updateDevice, id, closeOverscanModal],
  );
  const [deviceLogsModalStatus, setDeviceLogsModalStatus] = useState(false);
  const openDeviceLogs = useCallback(() => setDeviceLogsModalStatus(true), []);
  const closeDeviceLogs = useCallback(() => setDeviceLogsModalStatus(false), []);

  const [deviceFirmwareModalStatus, setDeviceFirmwareModalStatus] = useState(false);
  const openDeviceFirmwareModal = useCallback(() => setDeviceFirmwareModalStatus(true), []);
  const closeDeviceFirmwareModal = useCallback(() => setDeviceFirmwareModalStatus(false), []);
  const onDeviceFirmwareSelect = useCallback(
    (version?: string) => {
      onDeviceCommand(DeviceCommand.UpdateFirmware, { version }).then(() => closeDeviceFirmwareModal(), logger);
    },
    [closeDeviceFirmwareModal, onDeviceCommand],
  );
  //
  const [wifiSettingsForm] = Form.useForm();
  const wifiSettingsInitialValues = useMemo(
    () => ({
      ssid: '',
      password: '',
      countryCode: '',
      hiddenNetwork: false,
      ...(deviceProps?.wifiSettings || {}),
    }),
    [deviceProps?.wifiSettings],
  );
  const [wifiSettingsModalStatus, setWiFiModalStatus] = useState(false);
  const [hiddenNetwork, setHiddenNetwork] = useState(false);
  const onHiddenNetworkChange = useCallback(
    (isChecked: boolean) => {
      setHiddenNetwork(isChecked);
      wifiSettingsForm.setFieldsValue({ hiddenNetwork: isChecked });
    },
    [wifiSettingsForm, setHiddenNetwork],
  );
  const openWiFiModal = useCallback(() => setWiFiModalStatus(true), []);
  const closeWiFiModal = useCallback(() => setWiFiModalStatus(false), []);
  const setWiFiSettings = useCallback(
    (wifiSettings: Device['props']['wifiSettings']) => {
      if (!id) {
        return;
      }
      updateDevice({
        variables: {
          id,
          input: {
            props: {
              wifiSettings,
            },
          },
        },
      })
        .then(() => closeWiFiModal(), logger)
        .catch(logger);
    },
    [id, updateDevice, closeWiFiModal],
  );

  const [checkEnabled, setCheckEnabled] = useState(false);

  const onCheckboxChange = useCallback((isChecked: boolean) => {
    setCheckEnabled(isChecked ?? false);
  }, []);

  const onDeviceLocationChange = useCallback(
    (newLocationId: string) => {
      if (!id) {
        return;
      }
      updateDevice({ variables: { id, input: { locationId: newLocationId } } }).catch(logger);
      logger('Device.Location selected', { newLocationId });
    },
    [id, updateDevice],
  );

  const onDeviceLocationClear = useCallback(() => {
    if (!id) {
      return;
    }
    updateDevice({ variables: { id, input: { locationId: null } } }).catch(logger);
    logger('Device.Location cleared');
  }, [id, updateDevice]);

  const onTabChange = useCallback((key: string) => setActiveTab(key), []);

  const dimensionsFormInitialValues = useMemo(() => {
    const selectedDimension =
      deviceResolution?.width && deviceResolution?.height
        ? `${deviceResolution?.width}:${deviceResolution?.height}`
        : first(DIMENSIONS[deviceRatio ?? DEFAULT_RATIO])!.value;
    return {
      selectedDimension,
      ratio: deviceRatio,
    };
  }, [deviceResolution, deviceRatio]);

  const [dimensionsForm] = Form.useForm<{ selectedDimension: string; ratio: string }>();

  const [isDimensionsModalVisible, setIsDimensionsModalVisible] = useState(false);

  const openDimensionsModal = useCallback(() => {
    dimensionsForm.resetFields();
    setIsDimensionsModalVisible(true);
  }, [dimensionsForm]);

  const closeDimensionsModal = useCallback(() => {
    setIsDimensionsModalVisible(false);
  }, []);

  const handleDimensionFormFinish = useCallback(
    (formData: unknown) => {
      const { selectedDimension, ratio } = formData as { selectedDimension: string; ratio: Ratio };
      const [width, height] = (selectedDimension as string).split(':').map(d => parseInt(d, 10));
      const resolution = { width, height };

      logger('Dimensions form submitted', { resolution, ratio });

      if (!id) {
        return;
      }
      updateDevice({ variables: { id, input: { props: { resolution, ratio } } } }).then(
        () => closeDimensionsModal(),
        logger,
      );
    },
    [closeDimensionsModal, id, updateDevice],
  );

  const handleDimensionSelect = useCallback(
    (selectedDimension: string, ratio?: string) => {
      dimensionsForm.setFieldsValue({ selectedDimension, ratio });
      logger('Dimension selected: ', { selectedDimension, ratio });
    },
    [dimensionsForm],
  );

  const tabs = useMemo(
    () => [
      { key: TABS.INFO, label: t('Info'), children: '' },
      { key: TABS.ACTIONS, label: t('Actions'), children: '' },
    ],
    [t],
  );

  const [normalizedFirmwareVersion = null, normalizedAppVersion = null] = useMemo(() => {
    if (vendor === 'RaspberryPi') {
      return [appVersion, null];
    }

    if (vendor === 'Google Chrome') {
      return [null, appVersion];
    }

    if (vendor === 'Samsung') {
      return [firmwareVersion?.split('-')?.pop(), appVersion];
    }

    return [firmwareVersion, appVersion];
  }, [vendor, appVersion, firmwareVersion]);

  return (
    <DeviceInfoContainer>
      <Header>
        <Title level={5} value={t('Device')} isEditable={false} style={{ justifyContent: 'center' }} />
      </Header>
      <Tabs activeKey={activeTab} type="card" onChange={onTabChange} items={tabs} destroyInactiveTabPane />
      <Search
        allowClear
        onChange={onFilterChange}
        value={filterValue}
        placeholder={t('Search')}
        prefix={<RiSearchLine />}
        suffix={<RiFilterLine />}
      />
      <Divider />
      <Body addPadding={!!deviceId && activeTab !== TABS.ACTIONS}>
        <Scrollbar>
          <FixScrollbarChild addPadding={!!deviceId && activeTab !== TABS.ACTIONS}>
            {!deviceId && (
              <Start>
                <StartIcon src={startIcon} alt="" />
                <StartTitle>{t('Select a device')}</StartTitle>
                <StartDesc>{t('Click on the device in a schedule')}</StartDesc>
              </Start>
            )}
            <Spinner spinning={loading} style={{ minHeight: '160px' }}>
              {!!data?.getDevice && activeTab === TABS.INFO && (
                <>
                  <Item>
                    <ItemTitle>{t('Name')}</ItemTitle>
                    <ItemDesc>{name}</ItemDesc>
                  </Item>
                  {schedule && (
                    <Item>
                      <ItemTitle>{t('Schedule')}</ItemTitle>
                      <ItemDesc style={{ textAlign: 'right' }}>
                        <Link href={`/schedules/${schedule.id}`}>{schedule.name}</Link>
                      </ItemDesc>
                    </Item>
                  )}
                  <Item>
                    <ItemTitle>{t('Vendor')}</ItemTitle>
                    <ItemDesc>{vendor}</ItemDesc>
                  </Item>
                  {vendor !== 'Google Chrome' && (
                    <Item>
                      <ItemTitle>{t('Model')}</ItemTitle>
                      <ItemDesc>{model}</ItemDesc>
                    </Item>
                  )}
                  {normalizedAppVersion && (
                    <Item>
                      <ItemTitle>{t('App version')}</ItemTitle>
                      {isOutdated && <DeviceFWOutOfDateTooltip vendor={vendor} />}
                      <ItemDesc>{normalizedAppVersion}</ItemDesc>
                    </Item>
                  )}
                  {normalizedFirmwareVersion && (
                    <Item>
                      <ItemTitle>{t('Firmware version')}</ItemTitle>
                      {vendor === 'RaspberryPi' && isOutdated && <DeviceFWOutOfDateTooltip vendor={vendor} />}
                      <ItemDesc>{normalizedFirmwareVersion}</ItemDesc>
                    </Item>
                  )}
                  <Item>
                    <ItemTitle>{t('Ratio')}</ItemTitle>
                    <ItemDesc>{deviceRatio}</ItemDesc>
                  </Item>
                  {data?.getDevice?.props?.timezone && (
                    <Item>
                      <ItemTitle>{t('Timezone')}</ItemTitle>
                      <ItemDesc>{data?.getDevice?.props?.timezone}</ItemDesc>
                    </Item>
                  )}
                  <Item>
                    <ItemTitle>{t('Last change')}</ItemTitle>
                    <ItemDesc>{date}</ItemDesc>
                  </Item>
                  {data?.getDevice?.props?.serialNumber && (
                    <Item>
                      <ItemTitle>{t('Serial number')}</ItemTitle>
                      <ItemDesc>{serialNumber}</ItemDesc>
                    </Item>
                  )}
                  {!!ip && (
                    <Item>
                      <ItemTitle>{t('Ethernet IP')}</ItemTitle>
                      <ItemDesc>{ip}</ItemDesc>
                    </Item>
                  )}
                  {!!mac && (
                    <Item>
                      <ItemTitle>{t('Ethernet MAC')}</ItemTitle>
                      <ItemDesc>{mac}</ItemDesc>
                    </Item>
                  )}
                  {!!wlanIP && (
                    <Item>
                      <ItemTitle>{t('WiFi IP')}</ItemTitle>
                      <ItemDesc>{wlanIP}</ItemDesc>
                    </Item>
                  )}
                  {!!wlanMac && (
                    <Item>
                      <ItemTitle>{t('Wi-Fi MAC')}</ItemTitle>
                      <ItemDesc>{wlanMac}</ItemDesc>
                    </Item>
                  )}
                  {!!vpnIP && (
                    <Item>
                      <ItemTitle>{t('VPN IP')}</ItemTitle>
                      <ItemDesc>{vpnIP}</ItemDesc>
                    </Item>
                  )}
                  <Item>
                    <ItemTitle>{t('Status')}</ItemTitle>
                    <ItemDesc style={{ color: isOnline ? 'green' : 'red' }}>
                      {t(isOnline ? 'Online' : 'Offline')}
                    </ItemDesc>
                  </Item>
                </>
              )}

              {!!data && activeTab === TABS.ACTIONS && (
                <Collapse
                  accordion
                  bordered={false}
                  expandIconPosition="right"
                  defaultActiveKey={['1']}
                  expandIcon={RiArrowUpSLine}>
                  <Panel header={t('Screen')} key="1">
                    <Item>
                      <ItemTitle>{t('Rotate')}</ItemTitle>
                      <ItemDesc>
                        <Select
                          style={{ width: '5rem' }}
                          value={data?.getDevice?.props?.orientation || 0}
                          onChange={onChangeProp('orientation')}>
                          {[0, 90, 180, 270].map(val => (
                            <Option key={val} value={val}>
                              {val}
                            </Option>
                          ))}
                        </Select>
                      </ItemDesc>
                    </Item>

                    {vendor === 'Samsung' && (
                      <Item>
                        <ItemTitle>{t('Invert video rotation')}</ItemTitle>
                        <ItemDesc>
                          <Switch
                            onChange={onChangeProp('invertVideoRotation') as unknown as SwitchChangeEventHandler}
                            checked={data?.getDevice?.props?.invertVideoRotation}
                          />
                        </ItemDesc>
                      </Item>
                    )}

                    {/* // TODO [API]: add translation keys */}
                    {vendor === 'RaspberryPi' && (
                      <>
                        <Item>
                          <ItemTitle>{t('Dimensions')}</ItemTitle>
                          <ItemDesc>
                            <ButtonModalPopupComponent
                              isVisible={isDimensionsModalVisible}
                              beforeOpen={openDimensionsModal}
                              beforeClose={closeDimensionsModal}
                              buttonType="primary"
                              buttonText={t('Change')}
                              title={t('Dimensions settings')}
                              width={512}
                              zIndex={600}
                              buttonIcon={null}>
                              <Form
                                name="dimesions-form"
                                form={dimensionsForm}
                                layout="vertical"
                                onFinish={handleDimensionFormFinish}
                                initialValues={dimensionsFormInitialValues}>
                                <FormItem name="selectedDimension">
                                  <DimensionsModal
                                    deviceResolution={deviceResolution}
                                    onSelect={handleDimensionSelect}
                                  />
                                </FormItem>
                                <FooterModal>
                                  <Button type="default" htmlType="button" onClick={closeDimensionsModal}>
                                    {t('Cancel')}
                                  </Button>
                                  <Button type="primary" htmlType="submit" loading={deviceUpdating}>
                                    {t('Reboot')}
                                  </Button>
                                </FooterModal>
                              </Form>
                            </ButtonModalPopupComponent>
                          </ItemDesc>
                        </Item>
                        <Item>
                          <ItemTitle>{t('Overscan config')}</ItemTitle>
                          <ItemDesc>
                            <ButtonModalPopupComponent
                              isVisible={overscanModalStatus}
                              beforeOpen={openOverscanModal}
                              beforeClose={closeOverscanModal}
                              buttonType="primary"
                              buttonText={t('Change')}
                              title={t('Overscan configuration')}
                              width={480}
                              zIndex={600}
                              buttonIcon={null}>
                              <Form
                                form={overscanForm}
                                layout="vertical"
                                onFinish={setOverscanSettings}
                                initialValues={{
                                  horizontal: -8,
                                  vertical: -24,
                                  enabled: false,
                                  ...(data?.getDevice?.props?.overscan || {}),
                                }}>
                                <FormItem>
                                  <p style={{ marginBottom: '0.5rem' }}>
                                    {t(
                                      // eslint-disable-next-line max-len
                                      'Use positive numbers if the picture goes off screen, and negative if there is too much border. Default values are horizontal "-8" and vertical "-24". To apply changes click "Reboot"',
                                    )}
                                  </p>
                                  <AlertIcon color={colorTheme.warning} />
                                  <em>{t('Be carefull, device will be immediately rebooted!')}</em>
                                </FormItem>
                                <FormItem
                                  label={t('Ovescan configuration')}
                                  name="enabled"
                                  // valuePropName="checked"
                                  rules={[{ required: true, message: t('Please fill this field') }]}>
                                  <SwitchContainer disabled={false}>
                                    <div>{checkEnabled ? t('Enabled') : t('Disabled')}</div>
                                    <Switch onChange={onCheckboxChange} checked={checkEnabled ?? false} />
                                  </SwitchContainer>
                                </FormItem>
                                <Row gutter={20}>
                                  <Col span={12}>
                                    <FormItem
                                      dependencies={['enabled']}
                                      label={t('Horizontal')}
                                      name="horizontal"
                                      rules={[
                                        {
                                          required: true,
                                          message: t('Please fill this field'),
                                        },
                                      ]}>
                                      <InputNumber style={{ width: '100%' }} disabled={!checkEnabled} />
                                    </FormItem>
                                  </Col>
                                  <Col span={12}>
                                    <FormItem
                                      dependencies={['enabled']}
                                      label={t('Vertical')}
                                      name="vertical"
                                      rules={[
                                        {
                                          required: true,
                                          message: t('Please fill this field'),
                                        },
                                      ]}>
                                      <InputNumber style={{ width: '100%' }} disabled={!checkEnabled} />
                                    </FormItem>
                                  </Col>
                                </Row>
                                <FooterModal>
                                  <Button type="default" htmlType="button" onClick={closeOverscanModal}>
                                    {t('Cancel')}
                                  </Button>
                                  <Button type="primary" htmlType="submit" loading={deviceUpdating}>
                                    {t('Reboot')}
                                  </Button>
                                </FooterModal>
                              </Form>
                            </ButtonModalPopupComponent>
                          </ItemDesc>
                        </Item>
                      </>
                    )}
                    <DeviceScreenshot
                      id={id}
                      deviceName={name}
                      scheduleName={schedule?.name}
                      onDeviceCommand={onDeviceCommand}
                      orientation={orientation}
                    />
                  </Panel>
                  <Panel header={t('Wayfinding')} key="2">
                    <Item>
                      <ItemTitle>{t('Location')}</ItemTitle>
                      <ItemDesc>
                        <Select
                          style={{ width: '9rem' }}
                          disabled={locationsQuery.loading || deviceUpdating}
                          loading={locationsQuery.loading || deviceUpdating}
                          value={locationId}
                          onChange={onDeviceLocationChange}
                          allowClear
                          onClear={onDeviceLocationClear}>
                          {locations.map(location => (
                            <Option key={location.id} value={location.id}>
                              {location.name}
                            </Option>
                          ))}
                        </Select>
                      </ItemDesc>
                    </Item>
                  </Panel>
                  <Panel header={t('Device')} key="3">
                    <Item>
                      <ItemTitle>{t('Send command')}</ItemTitle>
                      <ItemDesc>
                        <Select
                          style={{ width: '9rem' }}
                          value={deviceCommand}
                          onChange={onDeviceCommand}
                          loading={sendingDeviceCommand}>
                          {[
                            DeviceCommand.Reboot,
                            DeviceCommand.Reload,
                            DeviceCommand.ShowName,
                            ...(viewerRole === UserRoleRole.Admin ? [DeviceCommand.InjectDevtool] : []),
                          ].map(val => (
                            <Option key={val} value={val}>
                              {deviceCommandActionMap[val] ?? val}
                            </Option>
                          ))}
                        </Select>
                      </ItemDesc>
                    </Item>
                    <Item>
                      <ItemTitle>{t('Firmware')}</ItemTitle>
                      <ItemDesc>
                        <ButtonModalPopupComponent
                          destroyContentOnRemount
                          isVisible={deviceFirmwareModalStatus}
                          beforeOpen={openDeviceFirmwareModal}
                          beforeClose={closeDeviceFirmwareModal}
                          buttonType="primary"
                          buttonText={t('Upgrade')}
                          title={t('Upgrading firmware version')}
                          width={500}
                          zIndex={600}
                          buttonIcon={null}>
                          <FirmwareVersionModal
                            isLoading={sendingDeviceCommand}
                            onFirmwareSelect={onDeviceFirmwareSelect}
                            onClose={closeDeviceFirmwareModal}
                            currentVersion={appVersion ?? 'unknown'}
                            vendor={vendor}
                          />
                        </ButtonModalPopupComponent>
                      </ItemDesc>
                    </Item>
                    {vendor === 'RaspberryPi' && (
                      <Item>
                        <ItemTitle>{t('WiFi settings')}</ItemTitle>
                        <ItemDesc>
                          <ButtonModalPopupComponent
                            isVisible={wifiSettingsModalStatus}
                            beforeOpen={openWiFiModal}
                            beforeClose={closeWiFiModal}
                            buttonType="primary"
                            buttonText={t('Change')}
                            title={t('WiFi settings')}
                            width={480}
                            zIndex={600}
                            buttonIcon={null}>
                            <Form
                              form={wifiSettingsForm}
                              layout="vertical"
                              onFinish={setWiFiSettings}
                              initialValues={wifiSettingsInitialValues}>
                              <p style={{ marginBottom: '0.5rem' }}>
                                {t('To apply Wi-Fi settings click "Reboot" button.')}
                              </p>
                              <AlertIcon color={colorTheme.warning} />
                              <em style={{ display: 'inline-block', marginBottom: '1.25rem' }}>
                                Be careful, device will be immediately rebooted!
                              </em>
                              <Row gutter={20}>
                                <Col span={16}>
                                  <FormItem
                                    label={t('SSID')}
                                    name="ssid"
                                    rules={[
                                      {
                                        required: true,
                                        message: t('Please fill this field'),
                                      },
                                    ]}>
                                    <Input />
                                  </FormItem>
                                </Col>
                                <Col span={8}>
                                  <FormItem
                                    valuePropName="checked"
                                    label={t('Hidden network')}
                                    name="hiddenNetwork"
                                    labelCol={{ style: { height: '1.625rem' } }}>
                                    <SwitchContainer disabled={false}>
                                      <div style={{ color: colorTheme.secondary }}>
                                        {hiddenNetwork ? t('Enabled') : t('Disabled')}
                                      </div>
                                      <Switch onChange={onHiddenNetworkChange} checked={hiddenNetwork ?? false} />
                                    </SwitchContainer>
                                  </FormItem>
                                </Col>
                              </Row>
                              <FormItemWithTooltip
                                label={
                                  <div className="form-item-tooltip">
                                    <span>{t('Password')}</span>
                                    <HelpIndicator
                                      text={t('Leave the "Password" field blank if you are using a public Wi-Fi')}
                                    />
                                  </div>
                                }
                                name="password">
                                <PasswordInput />
                              </FormItemWithTooltip>
                              <FormItem
                                label={t('Country')}
                                name="countryCode"
                                rules={[
                                  {
                                    required: true,
                                    message: t('Please choose country'),
                                  },
                                ]}>
                                <Select size="small">
                                  <Option value="RU">Russia</Option>
                                  <Option value="GB">United Kingdom</Option>
                                  <Option value="US">USA</Option>
                                </Select>
                              </FormItem>
                              <FooterModal>
                                <Button type="default" htmlType="button" onClick={closeWiFiModal}>
                                  {t('Cancel')}
                                </Button>
                                <Button type="primary" htmlType="submit" loading={deviceUpdating}>
                                  {t('Reboot')}
                                </Button>
                              </FooterModal>
                            </Form>
                          </ButtonModalPopupComponent>
                        </ItemDesc>
                      </Item>
                    )}
                  </Panel>
                  <Panel header={t('Debug')} key="4">
                    <Item>
                      <ItemTitle>{t('Debug mode')}</ItemTitle>
                      <ItemDesc>
                        <Switch
                          onChange={onChangeProp('debug') as unknown as SwitchChangeEventHandler}
                          checked={data?.getDevice?.props?.debug}
                        />
                      </ItemDesc>
                    </Item>

                    <Item>
                      <ItemTitle>{t('Logs')}</ItemTitle>
                      <ItemDesc>
                        <ButtonModalPopupComponent
                          isVisible={deviceLogsModalStatus}
                          beforeOpen={openDeviceLogs}
                          beforeClose={closeDeviceLogs}
                          buttonType="primary"
                          buttonText={t('Show')}
                          title={t('History of device activity')}
                          width={680}
                          zIndex={600}
                          buttonIcon={null}>
                          <Table
                            pagination
                            pageSize={10}
                            dataType="DeviceLog"
                            query={getDeviceLogsQuery}
                            subscriptionQueries={[]}
                            variables={{ filter: { deviceId: id } }}
                            rowKey="id"
                            columns={[
                              {
                                title: t('Event'),
                                dataIndex: 'event',
                                columnKey: 'event',
                              },
                              {
                                title: t('Date'),
                                dataIndex: 'createdAt',
                                sorterKey: 'createdAt',
                                width: 180,
                                sorter: (a: number, b: number) => a - b,
                                render(createdAt: Date) {
                                  return formatDate(createdAt, 'DD.MM.YYYY HH:mm');
                                },
                              },
                            ]}
                          />
                        </ButtonModalPopupComponent>
                      </ItemDesc>
                    </Item>
                  </Panel>
                </Collapse>
              )}
            </Spinner>
          </FixScrollbarChild>
        </Scrollbar>
      </Body>
    </DeviceInfoContainer>
  );
};

DeviceInfo.displayName = 'DeviceInfo';

export default memo(DeviceInfo);
