/* eslint-disable no-bitwise */
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import throttle from 'lodash/throttle';
import tinycolor from 'tinycolor2';

import logger from 'src/helpers/logger';
import time from 'src/helpers/time';

import { colorTheme } from 'src/components/ui-kit/theme';

import { notificationsContext, NotificationsContextProvider, NOTIFICATION_TYPES } from './context';
import Notification from './notification';
import type { NotificationInstance, NotificationType, NotificationsContextType } from './context';

const adjustAlertColors = Object.values(NOTIFICATION_TYPES).map(type => {
  const color = colorTheme[type === NOTIFICATION_TYPES.ERROR ? 'danger' : (type as keyof typeof colorTheme)];
  const semiTransparentColor = tinycolor(color).setAlpha(0.5).toHslString();

  logger('a', { color, semiTransparentColor, type });

  return css`
    &.ant-alert-${type} {
      background-color: ${semiTransparentColor};
      border-color: ${color};
    }
  `;
});

const positionAndSize = (() => {
  const headerHeight = 3.75;
  const navbarHeight = 3.125;
  const offsetTop = `${headerHeight + navbarHeight}rem`;

  return css`
    top: ${offsetTop};
    max-height: calc(100vh - ${offsetTop});
  `;
})();

const Tray = styled.aside`
  position: fixed;
  ${positionAndSize};
  left: 30vw;
  width: 40vw;
  padding: 0.625rem 0;
  overflow: hidden scroll;
  z-index: 1000;

  &::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  flex-direction: column;

  & > .ant-alert {
    border-radius: 0.625rem;
    margin: 0 0 0.625rem;
    font-weight: bold;

    &:first-of-type {
      margin-top: auto;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .ant-alert-close-icon > span {
      color: ${colorTheme.dark};
    }

    backdrop-filter: blur(0.625rem);

    ${adjustAlertColors}
  }
`;

export const NotificationsContext: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationInstance[]>([]);

  const generateKey = useCallback(() => {
    const isValid = (hexKey: string) =>
      notifications.length ? !notifications.some(({ key }) => key === hexKey) : true;

    const regenerate = (hexKey: string) => (parseInt(hexKey, 16) << 1).toString(16);

    let hex: string;
    const now = time();
    const ms = now.millisecond();
    const unix = now.unix();
    const operand = unix * 1000 + ms;
    const bit32 = operand >>> 1;
    hex = bit32.toString(16);

    logger('hex', { hex, operand, bit32 });

    while (!isValid(hex)) {
      logger('hex invalid', { hex });
      hex = regenerate(hex);
    }

    return hex;
  }, [notifications]);

  const close = useCallback(
    (key: string) => {
      const target = notifications.find(({ key: notifKey }) => notifKey === key);
      logger('NotificationsContext.close()', { key, target });

      if (target) {
        clearTimeout(target.timeout);
        setNotifications(notifs => notifs.filter(({ key: notifKey }) => notifKey !== target.key));
      }
    },
    [notifications],
  );

  const notify: NotificationsContextType['notify'] = useCallback(
    (message, options) => {
      const type = options?.type ?? NOTIFICATION_TYPES.INFO;
      const key = generateKey();
      const timeout = setTimeout(() => {
        setNotifications(notifs => notifs.filter(({ key: notifKey }) => notifKey !== key));
      }, 15000);

      logger('NotificationsContext.notify()', { message, options, type, key });
      setNotifications(notifs => [...notifs, { message, type, key, timeout }]);
    },
    [generateKey],
  );

  const notificationsContextValue: NotificationsContextType = useMemo(
    () => ({
      notifications,
      notify: throttle(notify, 100, { leading: false }),
    }),
    [notifications, notify],
  );

  const handleCloseButtonClick = useCallback((key: string) => () => close(key), [close]);

  return (
    <NotificationsContextProvider value={notificationsContextValue}>
      {children}
      <Tray>
        {(notifications ?? []).map(({ message, key, type }) => (
          <Notification
            key={`notification-${key}`}
            type={type}
            message={message}
            onCloseClick={handleCloseButtonClick(key)}
          />
        ))}
      </Tray>
    </NotificationsContextProvider>
  );
};

export default memo(NotificationsContext) as typeof NotificationsContext;
export { notificationsContext, NOTIFICATION_TYPES };
export type { NotificationInstance, NotificationType };
