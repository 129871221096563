import React from 'react';
import styled from 'styled-components';
import * as ReactContextMenu from 'react-contextmenu';
import { colorTheme } from 'src/components/ui-kit/theme';

export const ContextMenu = styled(ReactContextMenu.ContextMenu)`
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4eaf2;
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  transition: opacity 250ms ease !important;

  &.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
  }

  .react-contextmenu-item--divider {
    border-bottom: 1px solid #e4eaf2;
    cursor: inherit;
    margin-bottom: 3px;
    padding: 4px 0;
  }
`;
export const ContextMenuItem = styled(ReactContextMenu.MenuItem)`
  color: #6c757d;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;

  &.react-contextmenu-item--active,
  &.react-contextmenu-item--selected {
    color: #fff;
    background-color: ${colorTheme.primary};
    border-color: ${colorTheme.primary};
    text-decoration: none;
  }

  &.react-contextmenu-item--disabled,
  &.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: #878a8c;
    cursor: default;
  }

  &.react-contextmenu-submenu {
    padding: 0;
    > .react-contextmenu-item {
    }
    .react-contextmenu-item:after {
      content: '▶';
      display: inline-block;
      position: absolute;
      right: 7px;
    }
  }
`;
export const ContextMenuTrigger = styled(ReactContextMenu.ContextMenuTrigger)``;
