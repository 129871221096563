import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'src/helpers/logger';
import Tooltip from 'src/components/ui-kit/tooltip';
import { CopyIcon } from 'src/components/ui-kit/icons';
import Button from './button';

export type PasteButtonProps = {
  hasMargin: boolean;
  hasClipboardTimeslot: boolean;
  onPaste: any;
  loading?: boolean;
};

const PasteButton: React.FC<PasteButtonProps> = ({ loading, hasMargin, hasClipboardTimeslot, onPaste }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    try {
      navigator?.clipboard?.readText().then(
        text => {
          let timeslot = null;

          if (text) {
            try {
              timeslot = JSON.parse(text);
            } catch (_err) {
              timeslot = null;
            }
          }

          const isTimeslot = timeslot?.type?.toLowerCase() === 'timeslot';

          setIsDisabled(isTimeslot);
        },
        () => setIsDisabled(false),
      );
    } catch (err) {
      logger(err as Error);
    }
  });

  useEffect(() => {
    setIsDisabled(hasClipboardTimeslot);
  }, [hasClipboardTimeslot]);

  return (
    <Tooltip title={t('Paste')} placement="left">
      <Button loading={loading} onClick={onPaste} style={{ marginRight: hasMargin ? '0.75rem' : 0 }}>
        <CopyIcon showDot={isDisabled} />
      </Button>
    </Tooltip>
  );
};

export default memo(PasteButton);
