import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Route, Routes, Navigate, NotFound } from '@fjedi/react-router-helpers';
import { ContentCard } from 'src/components/ui-kit/admin-layout/content-card';
import Scrollbar from 'src/components/ui-kit/scrollbar';
import { UserRoleRole } from 'src/graphql/generated';
import UsersTab from './users';
import CompaniesTab from './companies';
import ProjectsTab from './projects';
import DevicesTab from './devices';
import QueryLogsTab from './query-logs';

const Content = styled(ContentCard)`
  height: 100%;
  max-height: calc(100vh - 134px);
  max-width: 914px;
  min-width: 100%;
  overflow-y: hidden;

  > img {
    max-height: 100%;
  }

  .rbc-time-view {
    border: 0;
  }
  .rbc-time-header {
    display: none;
  }
  .rbc-time-header-content {
    border: 0;
  }
  .rbc-time-content {
    .rbc-today {
      background-color: transparent;
    }
    > * + * > * {
      border-left: 0;
    }
  }

  > .ant-card-head {
    //margin-top: 0.5rem;
  }
`;

const AdminRoot = () => {
  const { t } = useTranslation();
  const tabList = useMemo(
    () => [
      {
        key: 'users',
        tab: t('Users'),
      },
      {
        key: 'companies',
        tab: t('Companies'),
        allowedRoles: [UserRoleRole.Admin],
      },
      {
        key: 'projects',
        tab: t('Projects'),
      },
      {
        key: 'devices',
        tab: t('Devices'),
      },
      {
        key: 'query-logs',
        tab: t('Logs'),
        allowedRoles: [UserRoleRole.Admin],
      },
    ],
    [t],
  );

  return (
    <Content tabList={tabList} tabURLNavigation>
      <Scrollbar>
        <Routes>
          <Route path="users/:userId" element={<UsersTab />} />
          <Route path="users" element={<UsersTab />} />
          <Route path="companies/:companyId" element={<CompaniesTab />} />
          <Route path="companies" element={<CompaniesTab />} />
          <Route path="projects/:projectId" element={<ProjectsTab />} />
          <Route path="projects" element={<ProjectsTab />} />
          <Route path="devices/:deviceId" element={<DevicesTab />} />
          <Route path="devices" element={<DevicesTab />} />
          <Route path="query-logs" element={<QueryLogsTab />} />
          <Route path="/" element={<Navigate replace to="users" />} />
          <Route element={<NotFound />} />
        </Routes>
      </Scrollbar>
    </Content>
  );
};

AdminRoot.propTypes = {};

export default memo(AdminRoot);
