import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import time from 'src/helpers/time';
import { formatPropForReact } from 'src/functions';
import { useQuery } from '@fjedi/graphql-react-components';
import getWeatherQuery from 'src/graphql/queries/get-weather.graphql';
import { Image } from 'antd';
import getWeatherIcon from './icons';

export const WeatherContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > h4 {
    color: #fefefe;
    font-size: 2.4rem;
    text-align: center;
    max-width: 90%;
    width: 50rem;
  }
`;

const WeatherItemContainer = styled.div`
  text-align: center;
`;

const WeatherDateContainer = styled.div``;
const WeatherTemperatureContainer = styled.div``;

const WeatherIcon = styled(Image)`
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const Weather = memo(({ item, style }) => {
  const location = useMemo(
    () => item?.props?.location ?? { lat: 59.9310584, lng: 30.3609097 },
    [item?.props?.location],
  );
  const api = useMemo(() => item?.props?.api ?? { days: 7 }, [item?.props?.api]);
  const icons = useMemo(() => item?.props?.icons ?? { size: 108, set: 'OPEN_WEATHER_LIGHT' }, [item?.props?.icons]);
  const dateLabel = useMemo(() => item?.props.dateLabel ?? 'DD MMM', [item?.props?.dateLabel]);
  const scale = useMemo(() => item?.props.temperature ?? 'C', [item?.props?.temperature]);

  const { data: weatherData } = useQuery(getWeatherQuery, {
    variables: {
      location: {
        lat: location.lat,
        lng: location.lng,
      },
      days: api.days,
      refreshInterval: api.refreshInterval,
    },
    skip: !location,
  });

  const iconLink = useMemo(() => icon => getWeatherIcon(icons.set, icon), [icons?.set]);
  const containerStyle = useMemo(() => formatPropForReact(style), [style]);
  const iconStyle = useMemo(() => formatPropForReact({ width: icons?.size, height: icons?.size }), [icons?.size]);

  const formatDate = useCallback(dayDt => time(dayDt * 1000).format(dateLabel), [dateLabel]);
  const convertTemperature = useCallback(temp => (scale === 'C' ? temp : (temp * 9) / 5 + 32), [scale]);

  return (
    <WeatherContainer style={containerStyle}>
      {weatherData?.getWeather?.daily &&
        weatherData.getWeather.daily.map(day => (
          <WeatherItemContainer key={day.dt}>
            <WeatherDateContainer>{formatDate(day.dt)}</WeatherDateContainer>
            <WeatherIcon preview={false} style={iconStyle} alt="Weather" src={iconLink(day.weather[0].icon)} />
            <WeatherTemperatureContainer>
              {`${Math.floor(convertTemperature(day.temp.day))} `}
              <char>&deg;</char>
              {`${scale}`}
            </WeatherTemperatureContainer>
          </WeatherItemContainer>
        ))}
    </WeatherContainer>
  );
});
Weather.displayName = 'Weather';

Weather.propTypes = {
  item: PropTypes.shape({
    props: PropTypes.shape({
      temperature: PropTypes.string,
      dateLabel: PropTypes.string,
      api: PropTypes.shape({ days: PropTypes.number }),
      icons: PropTypes.shape({ size: PropTypes.number, set: PropTypes.string }),
      location: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    }),
  }),
  style: PropTypes.shape({}),
};
Weather.defaultProps = {
  item: undefined,
  style: undefined,
};

export default Weather;
