/* eslint-disable security/detect-object-injection */
import {
  SubscriptionResult,
  MutationResult,
  ApolloCache,
  getListKeyFromDataType,
  DocumentNode,
  DataRow,
} from '@fjedi/graphql-react-components';
import camelCase from 'lodash/camelCase';

export type Params = {
  containerDataType: string;
  foreignKey: string;
  containerFieldName: string;
  dataTypes: string[];
  queryDoc: DocumentNode;
  queryFragmentDoc: DocumentNode;
  queryVariables: unknown;
};
export function updateInnerListOfCachedObject(params: Params) {
  const { containerDataType, dataTypes, foreignKey, containerFieldName, queryDoc, queryFragmentDoc, queryVariables } =
    params;

  return (cache: ApolloCache<unknown>, result: SubscriptionResult | MutationResult) => {
    const createdRow = dataTypes
      .map(dataType => {
        const mutationResultField = `create${dataType}`;
        const subscriptionResultField = `${camelCase(dataType)}Created`;
        return result?.data?.[mutationResultField] || result?.data?.[subscriptionResultField];
      })
      .find(r => !!r);
    const updatedRow = dataTypes
      .map(dataType => {
        const mutationResultField = `update${dataType}`;
        const subscriptionResultField = `${camelCase(dataType)}Changed`;
        return result?.data?.[mutationResultField] || result?.data?.[subscriptionResultField];
      })
      .find(r => !!r);
    const removedRow = dataTypes
      .map(dataType => {
        const mutationResultField = `remove${dataType}`;
        const subscriptionResultField = `${camelCase(dataType)}Removed`;
        return result?.data?.[mutationResultField] || result?.data?.[subscriptionResultField];
      })
      .find(r => !!r);

    const row = createdRow || updatedRow || removedRow;
    if (!row) {
      return;
    }

    cache.updateFragment(
      {
        id: `${containerDataType}:${row[foreignKey]}`,
        fragment: queryFragmentDoc,
        fragmentName: containerDataType,
      },
      existContainer => {
        if (!existContainer) {
          return existContainer;
        }
        const { [containerFieldName]: items } = existContainer;
        if (createdRow || updatedRow) {
          if (items.some((r: DataRow) => row.id === r.id)) {
            return existContainer;
          }
          return {
            ...existContainer,
            [containerFieldName]: items.concat([row]),
          };
        }
        if (removedRow) {
          return {
            ...existContainer,
            [containerFieldName]: items.filter((r: DataRow) => row.id !== r.id),
          };
        }
        //
        return existContainer;
      },
    );

    cache.updateQuery(
      {
        query: queryDoc,
        variables: queryVariables,
      },
      d => {
        const listKey = getListKeyFromDataType(containerDataType, {
          withGetPrefix: true,
        });
        if (!d?.[listKey]?.rows) {
          console.warn(`[updateInnerListOfCachedObject] Failed to find cached list for ${containerDataType}`);
          return d;
        }
        const { rows, ...bypassFields } = d[listKey];
        return {
          ...d,
          [listKey]: {
            ...bypassFields,
            rows: rows.map((r: { [k: string]: DataRow[] }) => {
              const { [containerFieldName]: items } = r;
              const isPrevContainerOfTheRow = r.id !== row[foreignKey] && items.some(item => item.id === row.id);
              if (isPrevContainerOfTheRow) {
                return { ...r, [containerFieldName]: items.filter(i => i.id !== row.id) };
              }
              return r;
            }),
          },
        };
      },
    );
  };
}
