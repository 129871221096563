import React, { memo } from 'react';
// Routing via React Router
import { NotFound, Route, Routes } from '@fjedi/react-router-helpers';
import DevicePage from './device';

const DevicesRoot = memo(() => {
  return (
    <Routes>
      <Route path="/devices/:deviceId" component={DevicePage} />
      <Route component={NotFound} />
    </Routes>
  );
});

DevicesRoot.propTypes = {};
DevicesRoot.defaultProps = {};
DevicesRoot.displayName = 'DevicesRoot';

export default DevicesRoot;
