// React
import React, { FC, memo, useMemo } from 'react';
// Routing via React Router
import { useTranslation } from 'react-i18next';
// Child React components.
import { Navigate, Route, Routes } from '@fjedi/react-router-helpers';
import { setHTMLTitle } from 'src/components/service-routes';
import { FontLoader } from 'src/components/common/font-selector';
// Main layout and navigation
import AdminLayout from 'src/components/ui-kit/admin-layout';
// User routes
import ProfilePage from 'src/components/routes/private/profile';
import AdminRoot from 'src/components/routes/private/admin';
import CompanyPage from 'src/components/routes/private/company';
import DevicesPage from 'src/components/routes/private/devices';
import EventsPage from 'src/components/routes/private/events';
import SchedulesPage from 'src/components/routes/private/schedules';
import MediaLibraryPage from 'src/components/routes/private/media-library';
import TemplateEditorPage from 'src/components/routes/private/template-editor';
import PlaylistEditorPage from 'src/components/routes/private/playlist-editor';
import { BreadcrumbContext } from 'src/components/ui-kit/breadcrumb';

const PrivateRoot: FC = () => {
  const { t } = useTranslation();
  const breadcrumbNameMap = useMemo(
    () => ({
      '/': t('Home'),
      '/devices': t('Devices'),
      '/schedules': t('Schedules'),
      '/template': t('Templates'),
      '/playlist': t('Playlists'),
      '/media-library': t('Media'),
    }),
    [t],
  );
  return (
    <FontLoader>
      <AdminLayout>
        {setHTMLTitle(t('Main page'))}
        <BreadcrumbContext.Provider value={breadcrumbNameMap}>
          <Routes>
            <Route path="profile/*" element={<ProfilePage />} />
            <Route path="company/*" element={<CompanyPage />} />

            <Route path="media-library" element={<Navigate replace to="/media-library/image" />} />
            <Route path="media-library/:contentType" element={<MediaLibraryPage />} />
            <Route path="media-library" element={<MediaLibraryPage />} />

            <Route path="schedule" element={<Navigate replace to="/schedules" />} />
            <Route path="schedules/*" element={<SchedulesPage />} />

            <Route path="template" element={<Navigate replace to="/" />} />
            <Route path="template/:templateId" element={<TemplateEditorPage />} />

            <Route path="playlist" element={<Navigate replace to="/" />} />
            <Route path="playlist/:playlistId" element={<PlaylistEditorPage />} />

            <Route path="devices" element={<Navigate replace to="/" />} />
            <Route path="devices/:deviceId*" element={<DevicesPage />} />

            <Route path="events/*" element={<EventsPage />} />

            <Route path="admin/*" element={<AdminRoot />} />

            <Route path="*" element={<Navigate replace to="/schedules" />} />
          </Routes>
        </BreadcrumbContext.Provider>
      </AdminLayout>
    </FontLoader>
  );
};

PrivateRoot.displayName = 'PrivateRoot';

export default memo(PrivateRoot);
