import React, { memo, useCallback } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { DatePicker as AntDatePicker, DatePickerProps } from 'antd';
import { logger } from '@fjedi/graphql-react-components';
import { time, formatDate, TimeInstance, DateValue, Dayjs } from 'src/helpers/time';

export type { DatePickerProps } from 'antd';

export const Container = styled(AntDatePicker)``;

const GlobalStyle = createGlobalStyle`
  .ant-picker {
    display: flex;
    padding: 0 1rem;
    min-height: 2rem;
    //border-radius: 0.375rem;

    &.ant-picker-focused {
      box-shadow: none;
    }
  }

  .ant-picker-dropdown  {
    .ant-picker-panel-container {
      border-radius: 1.25rem;
      overflow: hidden;
      vertical-align: top;
      background: #fff;
      box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
      transition: margin .3s;

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    .ant-picker-cell {
      padding: 3px 0;
    }
    .ant-picker-cell.ant-picker-cell-in-view {
      color: #000000d9;
    }
    .ant-picker-cell > .ant-picker-cell-inner,
    .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today > .ant-picker-cell-inner {
      border-radius: 50%;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      line-height: 1;
      font-weight: 700;

      &:before {
        border-radius: 50%;
      }
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background-color: #6473C4;
    }
  }
`;

const DatePicker: React.FC<DatePickerProps> = ({ value, onChange, format, dateRender, ...props }) => {
  const onDateChange = useCallback(
    (date: Dayjs | null, dateString: string) => {
      logger('DatePicker.onChange', { date, dateString });
      if (typeof onChange === 'function') {
        const timeWithTimezoneOffset = time(date as unknown as DateValue);
        onChange(timeWithTimezoneOffset, formatDate(timeWithTimezoneOffset, format as string));
      }
    },
    [onChange, format],
  );
  const onRenderDate = useCallback(
    (current: Dayjs, today: Dayjs) => {
      if (typeof dateRender === 'function') {
        return dateRender(current as unknown as TimeInstance, today as unknown as TimeInstance);
      }
      return <div className="ant-picker-cell-inner">{current.date()}</div>;
    },
    [dateRender],
  );
  return (
    <>
      <GlobalStyle />
      <Container {...props} value={value} format={format} onChange={onDateChange} dateRender={onRenderDate} />
    </>
  );
};

export default memo(DatePicker);
