import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { Svg } from 'src/components/ui-kit/icons';
import { ClockProps, useClockProps, WidgetProps } from 'src/components/routes/private/template-editor/widgets/utils';

const deg = 6;

export interface AnalogClockProps extends ClockProps {
  hh: number;
  mm: number;
  ss: number;
}

const ClockContainer = styled.div<ClockProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: ${({ clockSize }) => clockSize}px;
  height: ${({ clockSize }) => clockSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${'' /* background: url(${backgroundImage}); */}
  background-size: cover;
  border: 4px;
  /* box-shadow: 0em -1.2em 1.2em rgba(255, 255, 255, 0.06), inset 0em -1.2em 1.2em rgba(255, 255, 255, 0.06),
    0em 1.2em 1.2em rgba(0, 0, 0, 0.3), inset 0em 1.2em 1.2em rgba(0, 0, 0, 0.3); */
  border-radius: 50%;

  .dial {
  }
`;

const HrLine = styled.div<AnalogClockProps>`
  position: absolute;
  width: ${({ clockSize }) => 0.42 * clockSize}px;
  height: ${({ clockSize }) => 0.42 * clockSize}px;
  display: flex;
  justify-content: center;
  border-radius: 50%;

  transform: rotateZ(${({ hh, mm }) => hh + mm / 12}deg);

  &:before {
    content: '';
    position: absolute;
    width: ${({ clockSize }) => 0.02 * clockSize}px;
    height: ${({ clockSize }) => 0.21 * clockSize}px;
    z-index: 10;
    /* z-index least */
    border-radius: ${({ clockSize }) => 0.01 * clockSize}px;
    background: ${({ color }) => color};
  }
`;
const MmLine = styled.div<AnalogClockProps>`
  position: absolute;
  width: ${({ clockSize }) => 0.5 * clockSize}px;
  height: ${({ clockSize }) => 0.5 * clockSize}px;
  display: flex;
  justify-content: center;
  border-radius: 50%;

  transform: rotateZ(${({ mm }) => mm}deg);

  &:before {
    content: '';
    position: absolute;
    width: ${({ clockSize }) => 0.01 * clockSize}px;
    height: ${({ clockSize }) => 0.27 * clockSize}px;
    background: ${({ color }) => color};
    z-index: 11;
    /* z-index more than hour hand */
    border-radius: ${({ clockSize }) => 0.005 * clockSize}px;
  }
`;
const SecLine = styled.div<AnalogClockProps>`
  position: absolute;
  width: ${({ clockSize }) => 0.61 * clockSize}px;
  height: ${({ clockSize }) => 0.61 * clockSize}px;
  display: flex;
  justify-content: center;
  border-radius: 50%;

  transform: rotateZ(${({ ss }) => ss}deg);

  &:before {
    background: #f81460;
    content: '';
    position: absolute;
    width: 2px;
    height: 150px;
    z-index: 12;
    /* z-index more than hour minute hand */
    border-radius: 3px;
  }
`;

const Dial: FC<{ color?: string }> = ({ color = '#000000' }) => (
  <Svg color={color} size={{ width: 533.3, height: 533.3 }} viewBox="0 0 400 400" className="dial">
    <path
      strokeWidth={10}
      stroke={color}
      d="M202.4 31.1c-1.8.9-4.4 4.7-4.4 6.4 0 1.5 3.9.1 4.3-1.5.4-1.5 1.4-2 3.6-2 6.5 0 4.7 7.9-3.4 14.5-6.6 5.5-6.1 6.5 3.5 6.5 6.4 0 8-.3 8-1.5 0-1.1-1.2-1.5-5.2-1.5h-5.2l4.1-4.3c6.6-6.8 7.8-10.7 4.6-14.6-2.1-2.5-7-3.5-9.9-2zM186 33c0 1.1.7 2 1.5 2 1.2 0 1.5 1.8 1.5 10.5 0 9.8.1 10.5 2 10.5s2-.7 2-12.5V31h-3.5c-2.8 0-3.5.4-3.5 2zm-71 20.5c0 1.9 6.7 12.7 7.3 11.8.3-.5-.8-3.1-2.5-5.8-3.5-5.7-4.8-7.4-4.8-6zm166.2 5.4c-3.4 6.1-3.7 7.1-2.4 7.1.7 0 7.2-11.1 7.2-12.4 0-2-2 .2-4.8 5.3zM53 115.8c0 1.3 11 7.3 12.1 6.6.6-.3-1.5-2.1-4.8-4-6-3.5-7.3-3.9-7.3-2.6zm288 2.9c-3.5 2-5.9 3.8-5.3 4 .6.2 3.5-1 6.4-2.8 6.2-3.6 6.4-3.7 5.8-4.3-.3-.3-3.4 1.1-6.9 3.1zM35.3 188c-4.6 1.9-5.9 9.5-2.1 13 2.4 2.2 5.8 2.6 8.9 1 1.6-.9 1.9-.7 1.9 1 0 3.7-1.9 6-4.9 6-1.6 0-3.1-.7-3.5-1.5-.7-1.9-3.6-2-3.6-.2 0 4.3 9.3 6.8 12.6 3.5 3.6-3.5 3.7-17.9.2-21.4-1.8-1.8-6.8-2.6-9.5-1.4zm7.6 5.2c1.7 4.6-1.3 7.8-5.9 6.3-1.5-.5-2-1.5-2-3.9 0-4 .5-4.6 4.2-4.6 2 0 3.1.6 3.7 2.2zm316-5.1c-1.4.5-3.1 2.1-3.7 3.4-1 2.2-.9 2.5.8 2.5 1 0 2.3-.7 2.8-1.6.6-1 1.9-1.4 3.8-1.2 2.3.2 3 .8 3.2 3.1.3 2.3-.1 2.8-2.7 3.3-3.9.8-4 3-.2 3.7 3.1.6 4.6 3.3 3.5 6.1-.8 2.1-7.2 2.2-8 .1-.7-1.7-4.4-2.1-4.4-.5 0 1.7 3.8 4.9 6.5 5.6 3.6.9 8.2-1.3 9.3-4.4 1.1-3.2-.1-7-2.3-7.8-1.4-.6-1.3-.9.4-2.6 5.6-5.1-1.4-12.6-9-9.7zM334.5 278c-.6 1 12.3 8.5 13.2 7.6.8-.8 1-.6-6.4-4.9-3.4-2-6.5-3.2-6.8-2.7zm-275.8 3.6c-3.2 2-5.6 3.8-5.3 4.1.6.7 12.6-5.9 12.6-6.9 0-1.4-1.3-.8-7.3 2.8zm219 53c-.5.5 1.2 3.8 6.3 12.4.4.8 1.1 1.3 1.4 1 .2-.3-1.2-3.6-3.3-7.3-2-3.7-4-6.4-4.4-6.1zm-159.4 6.3c-3.5 6.3-3.8 7.1-2.5 7.1.9 0 7.2-10.4 7.2-11.9 0-2.5-1.7-.8-4.7 4.8zm78.7 4.4c-3.8 2-5.1 5.4-4.8 12.9.4 7.3 2 10.1 6.5 11.3 7.9 1.9 13.5-8.2 7.7-13.9-1.8-1.9-7.5-2.1-9.2-.4-1.8 1.8-2.4.4-1.4-3.3.6-2.7 1.4-3.5 3.6-3.7 1.6-.2 3.3.1 3.9.7 1.3 1.3 4.7 1.5 4.7.3 0-1.5-3.3-4-6-4.6-1.4-.4-3.6 0-5 .7zm7.3 13.8c.5.9.7 2.8.3 4.3-.6 2.4-1.1 2.7-4.4 2.4-3.2-.3-3.7-.7-4-3.1-.2-1.6.2-3.5.9-4.3 1.5-1.9 5.9-1.4 7.2.7z"
    />
  </Svg>
);

const AnalogClock: FC<WidgetProps> = ({ item, style }) => {
  const { clockSize, hh, mm, ss } = useClockProps(item, style, deg);

  return (
    <ClockContainer clockSize={clockSize}>
      <Dial color={style?.color ?? '#000000'} />
      <HrLine clockSize={clockSize} hh={hh} mm={mm} ss={ss} color={style?.color || '#0075fa'} />
      <MmLine clockSize={clockSize} hh={hh} mm={mm} ss={ss} color={style?.color || '#c4c4c4'} />
      <SecLine clockSize={clockSize} hh={hh} mm={mm} ss={ss} />
    </ClockContainer>
  );
};
AnalogClock.displayName = 'AnalogClock';

export default memo(AnalogClock);
