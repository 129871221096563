import React, { FC, memo } from 'react';
import { useRemoveMediaFolderMutation } from 'src/graphql/generated';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { RiDeleteBinLine } from 'react-icons/ri';
import Button from 'src/components/ui-kit/buttons';
import Popconfirm, { PopconfirmProps } from 'src/components/ui-kit/popconfirm';
import { stopEventPropagation } from 'src/functions';
import type { MediaFolderProfile } from 'src/components/ui-kit/media-item/media-item.d';
import { updateAfterMutation } from '@fjedi/graphql-react-components';

const MediaFolderActions: FC<{ folder: MediaFolderProfile }> = ({ folder }) => {
  const { t } = useTranslation();
  const [remove, { loading }] = useRemoveMediaFolderMutation({
    update: updateAfterMutation('MediaFolder', 'getMediaFolders'),
    variables: {
      id: folder.id,
    },
  });
  return (
    <Popconfirm
      placement="topRight"
      title={`${t('Remove')}?`}
      onClick={stopEventPropagation}
      onConfirm={remove as PopconfirmProps['onConfirm']}
      okText={t('Yes')}
      cancelText={t('No')}>
      <Tooltip title={t('Remove')} placement="bottom" align={{ offset: [0, -5] }}>
        <Button type="link" size="small" loading={loading}>
          <RiDeleteBinLine />
        </Button>
      </Tooltip>
    </Popconfirm>
  );
};

export default memo(MediaFolderActions);
