import React from 'react';
import styled from 'styled-components';
import { Checkbox as AntCheckbox } from 'antd';

export const CheckboxGroup = styled(AntCheckbox.Group)``;

export const Checkbox = styled(AntCheckbox)`
  &.ant-checkbox-wrapper {
    display: inline-flex;
    align-items: center;
    font-weight: 600;

    .ant-checkbox {
      top: 0;
      &:after {
        border-radius: 0.25rem;
      }

      .ant-checkbox-inner {
        border-radius: 0.25rem;

        &:after {
          top: 45%;
          width: 5px;
          height: 10px;
        }
      }
    }
    p {
      margin: 0 0 0 0.5rem;
    }
  }
`;

export default Checkbox;
