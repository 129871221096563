import React, { FC, memo, useMemo } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { colorTheme } from 'src/components/ui-kit/theme';

import { TEMPLATE_TYPES } from '../../constants';

interface LogoProps {
  data: Record<string, any>;
  style: CSSProperties;
}

const HeadingContainer = styled.h1`
  background: ${colorTheme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 2.5rem;
  color: ${colorTheme.light};
`;

const Heading: FC<LogoProps> = ({ data, style: widgetStyle }) => {
  const style = useMemo(() => {
    if (data.type === TEMPLATE_TYPES.LOCATION_HEADER) {
      return { ...widgetStyle, backgroundColor: 'transparent' };
    }

    return widgetStyle;
  }, [data, widgetStyle]);

  return <HeadingContainer style={style}>{data.title as string}</HeadingContainer>;
};

export default memo(Heading) as typeof Heading;
