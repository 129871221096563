import React from 'react';
import styled from 'styled-components';
import Button from 'src/components/ui-kit/buttons';

const StyledSubmitButton = styled(Button).attrs(props => ({
  size: 'large',
}))`
  &.ant-btn {
    margin: 1.75rem auto 0;
    display: ${props => (props.isHidden ? 'none' : 'block')};
    //padding: 0 1.75rem;
  }
`;

export default function SubmitButton(props) {
  return <StyledSubmitButton htmlType="submit" type="primary" {...props} />;
}
