import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Calendar as AntCalendar, CalendarProps as AntCalendarProps } from 'antd';
import { logger } from '@fjedi/graphql-react-components';

export const Container = styled(AntCalendar)`
  .ant-picker-cell {
    padding: 3px 0;
  }
  .ant-picker-cell.ant-picker-cell-in-view {
    color: #000000d9;
  }
  .ant-picker-cell > .ant-picker-cell-inner,
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today > .ant-picker-cell-inner {
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    line-height: 1;
    font-weight: 700;

    &:before {
      border-radius: 50%;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #6473c4;
  }
`;

export interface CalendarProps<T = unknown> extends AntCalendarProps<T> {}

export const Calendar: React.FC<CalendarProps> = memo(p => {
  const { onChange, ...props } = p;
  const onDateChange = useCallback(
    date => {
      logger('Calendar.onChange', { date });
      if (typeof onChange === 'function') {
        // Currently it will return browser-based timezone,
        // conversion to real TZ - WIP
        // const timeWithTimezoneOffset = time(date);
        // onChange(timeWithTimezoneOffset);
        onChange(date);
      }
    },
    [onChange],
  );
  // @ts-ignore
  return <Container {...props} onChange={onDateChange} />;
});

(Calendar as React.FC).propTypes = { format: PropTypes.string, onChange: PropTypes.func };
(Calendar as React.FC).defaultProps = { format: undefined, onChange: undefined };

export default Calendar;
