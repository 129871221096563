/* eslint-disable security/detect-object-injection, no-use-before-define, class-methods-use-this, import/prefer-default-export */
import React from 'react';
import ReactDOM from 'react-dom';
import { each as eachPromise } from 'bluebird';
import { ApolloProvider, ApolloClient } from '@fjedi/graphql-react-components';
import YoutubePlayer from 'react-player/youtube';
import HLSPlayer from 'react-hls-player';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/sortBy';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { TimeConstructor } from 'src/helpers/time';
import logger from 'src/helpers/logger';
import { kebabize } from 'src/functions';
import { Timeslot, TimeslotItem, MediaItem, PlaylistItem, TemplateArea } from 'src/graphql/generated';

import Clock from 'src/components/routes/private/template-editor/widgets/clock';
import DateTime from 'src/components/routes/private/template-editor/widgets/datetime';
import Weather from 'src/components/routes/private/template-editor/widgets/weather/weather';
import Heading from 'src/components/routes/private/template-editor/widgets/events/heading';
import EventsWidget from 'src/components/routes/private/template-editor/widgets/events/events';
import WayfindingWidget from 'src/components/routes/private/template-editor/widgets/events/wayfinding';
import Flights from 'src/components/routes/private/template-editor/widgets/flights';

const weekdays = new Map([
  [1, 'Monday'],
  [2, 'Tuesday'],
  [3, 'Wednesday'],
  [4, 'Thursday'],
  [5, 'Friday'],
  [6, 'Saturday'],
  [7, 'Sunday'],
]);

export type ScheduleProps = {
  time: TimeConstructor;
  apolloClient: ApolloClient;
  rootElement: HTMLElement;
  ratio: string;
  isVert?: boolean;
};

export class Schedule {
  rootElement: ScheduleProps['rootElement'];
  apolloClient: ScheduleProps['apolloClient'];
  ratio: ScheduleProps['ratio'];
  time: ScheduleProps['time'];
  isVert: boolean;
  isPlaying: boolean;
  defaultMediaItem?: MediaItem;
  dayContent?: Timeslot[];
  listTimeslots?: {
    listDownload: MediaItem[];
    everydayTimeslots: Timeslot[];
    groupByWeekday: { [k: string]: Timeslot[] };
    defaultMediaItem: MediaItem;
  };

  constructor(props: ScheduleProps) {
    const { rootElement, ratio, time, apolloClient } = props;
    this.rootElement = rootElement;
    this.ratio = ratio || '16:9';
    this.time = time;
    this.isPlaying = false;
    this.isVert = false;
    this.apolloClient = apolloClient;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.timeout = {};
  }

  async applyStyles(el: HTMLElement, styles: { [k: string]: number | string }) {
    Object.keys(styles).forEach(prop => {
      const value = typeof styles[prop] === 'string' ? styles[prop] : `${styles[prop]}px`;
      if (typeof value !== 'string') {
        return;
      }
      el?.style.setProperty(kebabize(prop), `${value}`);
    });
  }

  parse(data: { timeslots: Timeslot[]; defaultMediaItem: MediaItem }) {
    const listDownload = [];
    const { timeslots, defaultMediaItem } = data;

    if (defaultMediaItem) {
      this.defaultMediaItem = defaultMediaItem;
      listDownload.push(defaultMediaItem);
    }

    const everydayTimeslots = filter(timeslots, timeslot => timeslot.type === 'DAY') || [];
    const weekdayTimeslots = filter(timeslots, timeslot => timeslot.type === 'WEEKDAY' || timeslot.type === 'DATE');
    const groupByWeekday = groupBy(weekdayTimeslots, deviceSchedule =>
      weekdays.get(
        this.time(
          deviceSchedule.start,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.timezone,
        ).weekday(),
      ),
    );

    function handlerListMedia(listMedia: MediaItem[] | TimeslotItem[] | PlaylistItem[]) {
      if (listMedia.length > 0) {
        listMedia.forEach(async items => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { mediaItem, template, playlist } = items;

          if (!!mediaItem && (mediaItem.type.toLowerCase() === 'video' || mediaItem.type.toLowerCase() === 'image')) {
            listDownload.push(mediaItem);
          }

          if (typeof template !== 'undefined' && template) {
            template.areas!.forEach((f: TemplateArea) => {
              if (
                !!f.item &&
                [
                  'video',
                  'image',
                  'location_event_logo',
                  'location_header',
                  'location_events',
                  'location_wayfinding',
                ].includes(f.item.type.toLowerCase())
              ) {
                listDownload.push(f.item);
              }
            });
          }

          if (typeof playlist !== 'undefined' && playlist) {
            const playlistListMedia = playlist.items;

            if (typeof playlistListMedia !== 'undefined') handlerListMedia(playlistListMedia!);
          }
        });
      }
    }

    timeslots.forEach(timeslot => {
      handlerListMedia(timeslot.items!);
    });

    this.listTimeslots = { listDownload, everydayTimeslots, groupByWeekday, defaultMediaItem };
  }

  async createDayContent(dayOfWeek: string) {
    const dayTimeslot = this.listTimeslots?.groupByWeekday[dayOfWeek] || [];
    const everydayTimeslot = this.listTimeslots?.everydayTimeslots || [];

    dayTimeslot.push(...everydayTimeslot);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const tz = window.timezone;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    logger('Timezone', window.timezone);

    const currentTime = this.time();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.dayContent = (dayTimeslot || []).filter(timeslot => {
      const tss = this.time(timeslot.start, tz).utc(true);
      const timeslotStartHour = tss.hour();
      const timeslotStartMinute = tss.minute();
      const timeslotStart = currentTime.clone().hour(timeslotStartHour).minute(timeslotStartMinute).second(0);
      const tse = this.time(timeslot.end, tz).utc(true);
      const timeslotEndHour = tse.hour();
      const timeslotEndMinute = tse.minute();
      const timeslotEnd = currentTime.clone().hour(timeslotEndHour).minute(timeslotEndMinute).second(0);
      return currentTime.isBetween(timeslotStart, timeslotEnd);
    });
  }

  async canPlayNextTimeslot(id?: string) {
    const elId = this.rootElement.dataset.id;

    return !elId || elId !== id;
  }

  async play(dayOfWeek: string, props: ScheduleProps) {
    logger('Starting schedule preview...', {
      props,
    });
    if (this.isPlaying) {
      this.stop();
    }

    const { rootElement, ratio, isVert } = props;

    if (rootElement) {
      this.rootElement = rootElement;
    }

    if (ratio) {
      this.ratio = ratio;
    }

    if (isVert) {
      this.isVert = isVert;
    }
    //
    await this.createDayContent(dayOfWeek);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.timerId) clearInterval(window.timerId);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    this.isPlaying = true;

    async function initInterval() {
      if (!!that.dayContent && that.dayContent.length > 0) {
        const content: Timeslot | undefined | null = ['DATE', 'WEEKDAY', 'DAY'].reduce(
          (result: Timeslot | undefined | null, periodType: string) => {
            if (result) {
              return result;
            }

            return that.dayContent?.find(f => f.type === periodType);
          },
          null,
        );

        const canPlay = await that.canPlayNextTimeslot(content?.id);

        if (canPlay) {
          await that.playMedia(content!.items ?? [], content!.id);
        }
      } else if (that.defaultMediaItem) {
        that.rootElement.innerHTML = '';
        that.rootElement.dataset.id = 'default';

        const mediaElement = await that.createMediaElement(that.defaultMediaItem);

        that.rootElement.appendChild(mediaElement);
      } else {
        that.rootElement.innerHTML = 'Nothing to Preview';
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.timerId) clearInterval(window.timerId);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.timerId = setInterval(initInterval, 60000);
    }

    await initInterval();
  }
  stop() {
    logger('Stopping schedule preview...');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.timerId) clearInterval(window.timerId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.timeoutId) clearTimeout(window.timeoutId);

    if (this.rootElement) {
      this.rootElement.innerHTML = '';
      this.rootElement.dataset.id = 'default';
    }

    this.isPlaying = false;
  }
  async createMediaElement(asset: MediaItem, loopVideo = false, fullscreen = true, style = {}) {
    let mediaEl;

    if (!asset) {
      //
    }

    const { type, id } = asset;
    const mediaFile = asset;

    const positionStyles = pick(style, ['top', 'left', 'position', 'overflow']);
    const initialStyles = omit(style, Object.keys(positionStyles));

    switch (type.toLowerCase()) {
      case 'hls': {
        mediaEl = document.createElement('div');

        const width = '100%';
        const height = '100%';

        ReactDOM.render(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          React.createElement(HLSPlayer, {
            src: asset.url!,
            width,
            height,
            autoPlay: false,
            controls: true,
            style: { width, height },
          }),
          mediaEl,
        );
        break;
      }

      case 'youtube': {
        mediaEl = document.createElement('div');

        const width = '100%';
        const height = '100%';

        ReactDOM.render(
          React.createElement(YoutubePlayer, {
            url: asset.props?.originalURL || asset.url,
            width,
            height,
            // style: { width, height },
          }),
          mediaEl,
        );
        break;
      }

      case 'datetime': {
        mediaEl = document.createElement('div');

        const props = {
          item: asset,
          style,
        };

        ReactDOM.render(React.createElement(DateTime, props), mediaEl);
        //
        break;
      }

      case 'clock': {
        mediaEl = document.createElement('div');

        const props = {
          item: asset,
          style,
        };

        ReactDOM.render(React.createElement(Clock, props), mediaEl);
        break;
      }

      case 'video':
        mediaEl = document.createElement('video');
        mediaEl.src = mediaFile.url!;
        mediaEl.autoplay = true;

        if (loopVideo) mediaEl.loop = true;

        mediaEl.muted = true;
        mediaEl.id = id;
        mediaEl.setAttribute('type', `video/${asset.url!.split('.').pop() || ''}`);

        if (!fullscreen) {
          mediaEl.setAttribute('style', 'object-fit: cover;');
        }
        break;

      case 'image':
        mediaEl = document.createElement('img');
        mediaEl.id = id;
        mediaEl.src = mediaFile.url!;

        if (!fullscreen) {
          mediaEl.setAttribute('style', 'width: 100%; height: 100%; object-fit: cover;');
        }
        break;

      case 'iframe':
        mediaEl = document.createElement('iframe');
        mediaEl.scrolling = 'no';
        mediaEl.id = id;
        mediaEl.src = asset.url!;
        mediaEl.frameBorder = '0';
        break;

      case 'weather': {
        mediaEl = document.createElement('div');
        mediaEl.id = id;

        const props = {
          item: asset,
          style: {
            display: 'flex',
            justifyContent: 'center',
          },
        };

        ReactDOM.render(
          React.createElement(
            ApolloProvider,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            {
              client: this.apolloClient,
            },
            React.createElement(Weather, props),
          ),
          mediaEl,
        );
        break;
      }

      case 'flights': {
        mediaEl = document.createElement('div');
        mediaEl.id = id;

        const props = {
          ...asset,
          style: {
            display: 'flex',
            justifyContent: 'center',
          },
        };

        ReactDOM.render(
          React.createElement(
            ApolloProvider,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            {
              client: this.apolloClient,
            },
            React.createElement(Flights, props),
          ),
          mediaEl,
        );
        break;
      }

      case 'location_event_logo':
        if (mediaFile.url) {
          mediaEl = document.createElement('img');
          mediaEl.id = id;
          mediaEl.src = mediaFile.url;

          if (!fullscreen) {
            mediaEl.setAttribute('style', 'width: 100%; height: 100%; object-fit: cover;');
          }
        } else {
          mediaEl = document.createElement('div');

          const props = {
            data: asset,
            style: initialStyles,
          };

          ReactDOM.render(React.createElement(Heading, props), mediaEl);
        }
        break;

      case 'location_header': {
        mediaEl = document.createElement('div');

        const props = {
          data: asset,
          style: initialStyles,
        };

        ReactDOM.render(React.createElement(Heading, props), mediaEl);
        break;
      }

      case 'location_events': {
        mediaEl = document.createElement('div');

        const props = { ...asset.props, style: initialStyles };

        ReactDOM.render(React.createElement(EventsWidget, props), mediaEl);
        break;
      }

      case 'location_wayfinding': {
        mediaEl = document.createElement('div');

        const props = { ...asset, style: initialStyles };

        ReactDOM.render(React.createElement(WayfindingWidget, props), mediaEl);
        break;
      }

      default:
        mediaEl = document.createElement('h1');
        mediaEl.innerText = asset.title!;
    }

    const parentDiv = document.createElement('div');
    // parentDiv.setAttribute('class', type);
    await this.applyStyles(parentDiv, {
      width: '100%',
      height: '100%',
      'max-width': '100%',
      'max-height': '100%',
    });

    if (fullscreen) {
      await this.applyStyles(mediaEl, {
        width: '100%',
        height: '100%',
        'object-fit': this.isVert ? 'contain' : 'cover',
      });
    } else {
      const blackList = ['position', 'left', 'top', 'right', 'bottom', 'backgroundColor'];

      if (mediaEl.tagName === 'H1') blackList.push('width', 'height');

      const filteredStyle = omit(style, blackList);

      await this.applyStyles(mediaEl, filteredStyle);
    }
    parentDiv.setAttribute('id', `container-${id}`);
    parentDiv.appendChild(mediaEl);
    mediaEl = null;

    return parentDiv;
  }

  async playMedia(
    l: Array<TimeslotItem | MediaItem | PlaylistItem>,
    timeslotID: Timeslot['id'],
    { isPlaylist = false } = {},
  ) {
    if (!Array.isArray(l)) {
      throw new TypeError('Invalid "listMedia" array passed to "playMedia" function');
    }

    const listMedia: Array<PlaylistItem | MediaItem> = [];

    orderBy(l, 'sequence', 'asc').forEach(mediaItem => {
      const playlist = (mediaItem as TimeslotItem).playlist!;
      if (playlist) {
        if (Array.isArray(playlist!.items) && playlist.items.length > 0) {
          const playlistMedia = orderBy(playlist!.items, 'sequence', 'asc');

          listMedia.push(...playlistMedia);
        }
      } else {
        listMedia.push(mediaItem as PlaylistItem | MediaItem);
      }
    });

    const isLoop = listMedia.length === 1;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    async function appendMedia(asset: TimeslotItem | PlaylistItem) {
      if (!asset) {
        that.rootElement.innerHTML = '';
        return;
      }

      if (asset.mediaItem) {
        const {
          mediaItem,
          mediaItem: { type, id },
        } = asset;
        const duration = asset.duration === 0 ? 20 : asset.duration;

        if (!isPlaylist) that.rootElement.innerHTML = '';

        let mediaEl = await that.createMediaElement(mediaItem, isLoop);

        if (isPlaylist) {
          const wrapMediaEl = document.createElement('div');
          await that.applyStyles(wrapMediaEl, (asset as any).style);
          wrapMediaEl.appendChild(mediaEl);
          mediaEl = wrapMediaEl;
        }

        that.rootElement.appendChild(mediaEl);

        if (type.toLowerCase() === 'video') {
          const videoElement = document.getElementById(id)!;
          videoElement.addEventListener('ended', () => {
            changeMedia();
          });
        }

        if (type.toLowerCase() === 'image') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (window.timeout[timeslotID]) clearTimeout(window.timeout[timeslotID]);
          if (!isLoop) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.timeout[timeslotID] = setTimeout(changeMedia, duration * 1000);
          }
        }
      }

      if (asset.template) {
        that.rootElement.innerHTML = '';

        const {
          duration,
          template: { bodyStyle, areas },
        } = asset;
        const defaultStyle = {
          'min-height': '100%',
          width: '100%',
          height: '100%',
          position: 'relative',
          color: '#fff',
          'background-color': '#e5e5e5',
        };
        const divWrapper = document.createElement('div');
        const applyStyle = merge(defaultStyle, bodyStyle);

        await that.applyStyles(divWrapper, applyStyle);

        const orderedAreas = orderBy(areas, 'sequence');
        await eachPromise(orderedAreas, async (area: TemplateArea) => {
          let styleArea = area.style[that.ratio];

          if (that.isVert) {
            const [rw, rh] = that.ratio.split(':');
            const vertRatio = `${rh}:${rw}`;
            styleArea = area.style[vertRatio];
          }

          let mediaEl;

          if (area.item) {
            mediaEl = await that.createMediaElement(
              area.item || { title: area.title, type: 'TEXT', id: area.id },
              true,
              false,
              styleArea,
            );
          } else if (area.playlist) {
            const playlistItems = area.playlist!.items!.map((item: PlaylistItem) =>
              merge({}, item, { style: styleArea }),
            );
            mediaEl = document.createElement('div');

            await that.playMedia(playlistItems, `playlist-${area.id}`, { isPlaylist: true });
          } else {
            mediaEl = await that.createMediaElement(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              { title: area.title, type: 'TEXT', id: area.id },
              true,
              false,
              styleArea,
            );
          }

          const areaStyles = {
            ...(styleArea || {}),
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'box-sizing': 'border-box',
            'font-size': '64px',
            // border: '2px solid transparent',
          };
          const blacklistStyle = [];
          if (!styleArea.backgroundEnable) {
            blacklistStyle.push('backgroundColor');
          }
          const filteredAreaStyles = omit(areaStyles, blacklistStyle);

          await that.applyStyles(mediaEl, filteredAreaStyles);
          divWrapper?.appendChild(mediaEl);
          // mediaEl.remove();
        });

        that.rootElement.append(divWrapper);
        if (!isLoop) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.timeout[timeslotID] = setTimeout(changeMedia, duration * 1000);
        }
      }
    }

    if (!isPlaylist) this.rootElement.dataset.id = timeslotID;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await appendMedia(listMedia[0]);

    let currentIndex = 1;
    async function changeMedia() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.timeout[timeslotID]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        clearTimeout(window.timeout[timeslotID]);
      }
      if (currentIndex >= listMedia.length) currentIndex = 0; // Зацикливаем воспроизведение.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await appendMedia(listMedia[currentIndex]);

      currentIndex += 1;
    }
  }
}
