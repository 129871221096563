import React, { memo, createContext, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from '@fjedi/react-router-helpers';
import styled from 'styled-components';
//
import { Tabs, TabPane } from 'src/components/ui-kit/tabs';
import Card from 'src/components/ui-kit/card';

export const TabNavigationContext = createContext({
  tabPosition: 'left',
  tabs: [],
});

const TabsWrapper = styled(Card)`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 4rem;

  @media screen and (max-width: ${props => props.theme.responsiveBreakpoints.mobile}) {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;

const TabsNavigation = () => {
  const { tabs } = useContext(TabNavigationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const onTabChange = useCallback(tab => navigate(tab, { replace: true }));
  //
  if (!Array.isArray(tabs) || tabs.length === 0) {
    return null;
  }
  //
  return (
    <TabsWrapper>
      <Tabs activeKey={location.pathname} onChange={onTabChange}>
        {tabs.map(({ title, link, component: Component }) => (
          <TabPane tab={title} key={link}>
            <Component />
          </TabPane>
        ))}
      </Tabs>
    </TabsWrapper>
  );
};

TabsNavigation.propTypes = {
  // tabs: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     link: PropTypes.string.isRequired,
  //     title: PropTypes.string.isRequired,
  //     component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  //   }),
  // ).isRequired,
  // tabsPosition: PropTypes.oneOf(['center', 'left', 'right']), NOT IMPLEMENTED!!!
};

TabsNavigation.defaultProps = {
  // tabsPosition: 'left',
};

export default memo(TabsNavigation);
