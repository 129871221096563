/* eslint-disable security/detect-object-injection */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IDType } from 'src/store/prop-types';
import { compose, withProps, withHandlers, withStateHandlers } from 'recompose';
import logger from 'src/helpers/logger';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { isEmpty, stopEventPropagation } from 'src/functions';
import { withTranslation } from 'react-i18next';
//
import {
  useQuery,
  Mutation,
  updateAfterMutation,
  getDataFromResponse,
  useSubscribeToMore,
  getListKeyFromDataType,
} from '@fjedi/graphql-react-components';
//
import { RiAddBoxLine } from 'react-icons/ri';
// import InfiniteScroll from 'react-infinite-scroller';
import { Table as AntTable } from 'antd';
import Popconfirm from 'src/components/ui-kit/popconfirm';
import Spinner from 'src/components/ui-kit/spinner';
import DeleteButton from 'src/components/ui-kit/buttons/delete';
// CSS styles
import styled, { createGlobalStyle } from 'styled-components';
//
import { EditableRow, EditableCell } from './editable-row';

const StyledHeaderCell = styled.th`
  color: #66bbff;
`;

const Table = styled(AntTable)`
  table > .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  table > .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
  table > .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  table > .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: transparent;
  }
  table > .ant-table-tbody > tr > td,
  table > .ant-table-thead > tr > th {
  }
  table > .ant-table-tbody > tr > td,
  table > .ant-table tfoot > tr > td {
    padding: 8px 16px;
  }

  .ant-table-bordered .ant-table-body > table,
  .ant-table-bordered .ant-table-fixed-left table,
  .ant-table-bordered .ant-table-fixed-right table,
  .ant-table-bordered .ant-table-header > table {
    border-top: 0;
    border-left: 0;
    border-right: 0;

    thead th:last-child {
      border-right: 0;
    }
    tbody tr td {
      border-top: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      background-color: transparent;
    }
  }
  .ant-table-thead > tr > th.ant-table-column-sort {
    background-color: transparent;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    &,
    &:focus,
    &:hover {
      background-color: transparent;
    }
  }
  .ant-table-thead > tr > th.ant-table-column-sort,
  .ant-table-thead > tr > th {
    &,
    &:focus,
    &:hover {
      color: #66bbff;
      font-family: 'Nunito', sans-serif;
      font-weight: 600;
    }
  }

  th {
    text-align: left;
  }

  .editable-row-operations {
    text-align: right;
  }

  .ant-table-placeholder {
    border: 0;
  }

  .ant-table-thead > tr > th {
    background: transparent;
  }

  //.ant-table-tbody > tr:last-of-type > td {
  //  border-color: transparent;
  //}

  @include mq(tablet) {
    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
      font-size: 13px;
    }

    .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
      font-size: 12px;
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  .ant-table-filter-dropdown {
    border-radius: 0.625rem;
    background-color: #ffffff;
  }
`;

const AddRowButton = styled.button`
  display: flex;
  align-items: baseline;

  > .ant-btn {
    margin-right: 0.75rem;
  }
`;

const AddRowIcon = styled(RiAddBoxLine)`
  &,
  &.anticon.anticon-plus {
    display: inline-flex;
    align-items: center;
    color: #fff;
    background-color: #6bf;
    border: 1px solid #6bf;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    padding-right: 8px;
    padding-left: 8px;
    line-height: 1.499;
    position: relative;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    font-size: 14px;
    border-radius: 0;
    margin: 0 0.75rem 0 0;
  }
`;

// Set colSpans for add-new-row
function extendCellRender(column, index, columnsCount) {
  const { render, style, width } = column;
  //
  return {
    ...column,
    render(value, row) {
      let children = false;
      if (typeof row.render === 'function') {
        children = row.render();
      } else if (typeof render === 'function') {
        children = render(value, row);
      } else {
        children = value;
      }
      const obj = {
        children,
        props: {
          style,
          width,
        },
      };
      if (row.id === 'add-new-row') {
        obj.props.colSpan = index === 0 ? columnsCount : 0;
      }
      return obj;
    },
  };
}

function setAdditionalProps(column, index, columnsCount, props = {}) {
  return {
    ...extendCellRender(column, index, columnsCount),
    // ...column,
    ...props,
  };
}

const TableTemplate = props => {
  const {
    rowKey,
    dataType,
    queryContext,
    bordered,
    pagination,
    className,
    getColumns,
    scrollBreakpoint,
    query,
    variables,
    onChange,
    fixedHeader,
    pollInterval,
    fetchPolicy,
    // Editable table props
    components,
    // Add new row
    addRowMutation,
    renderAddRowButton,
    //
    subscriptionQueries,
    expandedRowRender,
    defaultExpandAllRows,
    expandedRowKeys,
    onExpandedRowsChange,
    expandRowByClick,
    //
    loadMore,
    scrollPagination,
  } = props;
  console.log('Variables', variables);
  const {
    data: queryResult,
    loading,
    subscribeToMore,
  } = useQuery(query, {
    variables,
    fetchPolicy,
    pollInterval,
    context: queryContext,
  });
  const subscriptionProps = useMemo(
    () => ({
      dataType,
      subscriptionQueries,
      subscribeToMore,
      variables,
    }),
    [dataType, variables, subscriptionQueries, subscribeToMore],
  );
  useSubscribeToMore(subscriptionProps);
  //
  const { rows, count, pageInfo } = getDataFromResponse(dataType, { withGetPrefix: true })(queryResult);
  const columns = getColumns(rows);

  return (
    <>
      <GlobalStyle />
      <Table
        showHeader={columns.some(column => column.title)}
        bordered={bordered}
        onChange={onChange}
        className={className}
        rowKey={rowKey}
        scroll={{ y: fixedHeader ? '500px' : undefined, x: scrollBreakpoint }}
        loading={loading}
        pagination={
          !scrollPagination &&
          pagination && {
            ...pagination,
            ...pageInfo,
            total: count,
          }
        }
        columns={columns}
        onExpandedRowsChange={onExpandedRowsChange}
        expandRowByClick={expandRowByClick}
        expandedRowRender={expandedRowRender}
        expandedRowKeys={expandedRowKeys}
        defaultExpandAllRows={defaultExpandAllRows}
        components={components}
        dataSource={rows.concat(addRowMutation ? [{ id: 'add-new-row', render: renderAddRowButton }] : [])}
      />
    </>
  );
};

TableTemplate.propTypes = {
  dataType: PropTypes.string.isRequired,
  loadMore: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
  renderAddRowButton: PropTypes.func.isRequired,
  expandedRowRender: PropTypes.func,
  expandRowByClick: PropTypes.bool,
  onExpandedRowsChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  addRowMutation: PropTypes.object,
  rowKey: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  onSave: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  variables: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  queryContext: PropTypes.object,
  pagination: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      total: PropTypes.number,
    }),
    PropTypes.bool,
  ]).isRequired,
  scrollPagination: PropTypes.bool,
  scrollBreakpoint: PropTypes.number,
  fixedHeader: PropTypes.bool,
  defaultExpandAllRows: PropTypes.bool,
  expandedRowKeys: PropTypes.arrayOf(IDType),
  bordered: PropTypes.bool,
  sorter: PropTypes.shape({
    columnKey: PropTypes.string,
    sorterKey: PropTypes.string,
    order: PropTypes.oneOf(['ascend', 'descend']),
  }),
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
  filters: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  subscriptionQueries: PropTypes.array,
  pollInterval: PropTypes.number,
  fetchPolicy: PropTypes.string,
};

TableTemplate.defaultProps = {
  fetchPolicy: 'cache-and-network',
  className: '',
  pollInterval: undefined,
  scrollBreakpoint: undefined,
  variables: undefined,
  queryContext: undefined,
  components: undefined,
  addRowMutation: undefined,
  fixedHeader: false,
  defaultExpandAllRows: false,
  expandedRowKeys: undefined,
  bordered: true,
  expandRowByClick: true,
  filters: undefined,
  sorter: undefined,
  onSave: undefined,
  rowKey: 'id',
  subscriptionQueries: [],
  expandedRowRender: undefined,
  scrollPagination: false,
};

export default compose(
  withTranslation(),
  withStateHandlers(
    props => {
      let pagination = props.pagination || props.scrollPagination || false;
      const { pageSize } = props;
      switch (pagination) {
        case false:
          pagination = false;
          break;
        case true:
          pagination = {
            current: 1,
            pageSize: pageSize || 15,
          };
          break;
        default:
          pagination = {
            current: 1,
            pageSize: pageSize || 15,
            ...pagination,
          };
      }

      return {
        pagination,
        expandedRowKeys: [],
      };
    },
    {
      onChange: () => (pagination, rawFilters, sorter) => {
        logger('Параметры таблицы', {
          pagination,
          rawFilters,
          sorter,
        });
        const filters = {};
        Object.keys(rawFilters).forEach(field => {
          //
          const value = rawFilters[field];
          if (isEmpty(value)) {
            return;
          }
          //
          filters[field] = value;
        });
        return {
          pagination,
          filters,
          sorter: !sorter?.order ? undefined : sorter,
        };
      },
      onExpandedRowsChange: () => expandedRowKeys => ({
        expandedRowKeys,
      }),
    },
  ),
  withHandlers({
    onCell:
      ({ onSave }) =>
      col =>
      record => ({
        record,
        handleSave: onSave,
        ...col,
      }),
  }),
  withProps(props => {
    const { pagination, filters: rawFilters, variables, sorter = {} } = props;
    const filters = {};
    if (rawFilters) {
      Object.keys(rawFilters).forEach(field => {
        //
        const value = rawFilters[field];
        if (isEmpty(value)) {
          return;
        }
        //
        filters[field] = value;
      });
    }

    //
    const vars = {
      ...variables,
      filter: {
        ...get(variables, 'filter', {}),
        ...filters,
      },
      sort: {
        fields: [sorter.columnKey || 'createdAt'],
        direction: sorter.order === 'ascend' ? 'ASC' : 'DESC',
      },
      pagination: {
        offset: (pagination.current === 1 ? 0 : (pagination.current - 1) * pagination.pageSize) || 0,
        limit: pagination.pageSize,
      },
    };
    console.log('Vars', vars);
    return {
      variables: vars,
      components: {
        header: {
          cell: StyledHeaderCell,
        },
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      },
    };
  }),
  withHandlers({
    onCompleted: () => result => {
      //
      logger('[MUTATION] onCompleted', result);
    },
  }),
  withHandlers({
    renderAddRowButton: p => () => {
      const { t, dataType, onCompleted, addRowMutation, addRowVariables } = p;
      //
      return (
        <Mutation
          update={updateAfterMutation(dataType, getListKeyFromDataType(dataType, { withGetPrefix: true }))}
          onCompleted={onCompleted}
          variables={addRowVariables}
          mutation={addRowMutation}>
          {(mutation, { loading }) => (
            <Spinner spinning={loading}>
              <AddRowButton onClick={mutation}>
                <AddRowIcon type="plus" />
                <span>{t('Add')}</span>
              </AddRowButton>
            </Spinner>
          )}
        </Mutation>
      );
    },
    renderRemoveRowButton: p => (_, row) => {
      const { removalConfirmationMessage, onCompleted, removeRowMutation, rowKey, dataType } = p;
      const id = row[rowKey || 'id'];
      const removableColumn = p.columns.find(column => typeof column.removable !== 'undefined');
      let isRemovable = true;
      if (id === 'add-new-row') {
        isRemovable = false;
      } else if (typeof removableColumn?.removable === 'function') {
        isRemovable = removableColumn.removable(row);
      } else if (typeof removableColumn?.removable === 'boolean') {
        isRemovable = removableColumn.removable;
      }

      //
      if (!isRemovable) {
        return '';
      }
      //
      return (
        <Mutation
          update={updateAfterMutation(dataType, getListKeyFromDataType(dataType, { withGetPrefix: true }))}
          onCompleted={onCompleted}
          variables={{ id }}
          mutation={removeRowMutation}>
          {(mutation, { loading }) => (
            <Popconfirm title={removalConfirmationMessage} onConfirm={mutation} onClick={stopEventPropagation}>
              <DeleteButton loading={loading} />
            </Popconfirm>
          )}
        </Mutation>
      );
    },
  }),
  withHandlers({
    getColumns:
      ({ columns, sorter = {}, onCell, removeRowMutation, renderRemoveRowButton }) =>
      rows => {
        const removableColumn = columns.find(column => typeof column.removable !== 'undefined');
        const noRemovableRows =
          !Array.isArray(rows) ||
          rows.length === 0 ||
          rows.every(row => {
            if (!removableColumn) {
              return false;
            }
            if (typeof removableColumn.removable === 'function') {
              return !removableColumn.removable(row);
            }
            return !removableColumn.removable;
          });
        const appendRemoveColumn = !noRemovableRows && removeRowMutation;
        const columnsCount = columns.length + (appendRemoveColumn ? 1 : 0);
        return columns
          .concat(
            appendRemoveColumn
              ? [
                  {
                    title: '',
                    dataIndex: 'remove-row',
                    render: renderRemoveRowButton,
                    width: 60,
                    style: {
                      textAlign: 'center',
                      verticalAlign: 'middle',
                    },
                  },
                ]
              : [],
          )
          .map((col, index) =>
            setAdditionalProps(col, index, columnsCount, {
              onCell: col.onCell || (col.editable ? onCell(col) : undefined),
              key: col.columnKey,
              sortOrder: sorter.columnKey === col.sorterKey && sorter.order,
            }),
          );
      },
    loadMore: props => queryResult => currentPage => {
      const { variables, dataType } = props;
      const { fetchMore, loading, data } = queryResult;
      const { rows, count } = getDataFromResponse(dataType, { withGetPrefix: true })(data);
      logger('Variables', {
        variables,
        currentPage,
      });
      const { pagination } = variables;
      if (loading || rows.length >= count) {
        return null;
      }
      return fetchMore({
        variables: {
          ...variables,
          pagination: {
            ...pagination,
            offset: rows.length,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const oldRows = getDataFromResponse(dataType, { withGetPrefix: true })(prev);
          const newRows = getDataFromResponse(dataType, { withGetPrefix: true })(fetchMoreResult);
          const queryName = `get${dataType}s`;
          logger('updateQuery', { prev, oldRows, newRows, dataType, queryName });

          return {
            ...prev,
            [queryName]: {
              ...prev[queryName],
              rows: uniqBy([...oldRows.rows, ...newRows.rows], props.rowKey || 'id'),
            },
          };
        },
      });
    },
  }),
  memo,
)(TableTemplate);
