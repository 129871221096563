import React, { FC, memo, useState, useCallback, useMemo, ChangeEventHandler } from 'react';
import styled from 'styled-components';
import last from 'lodash/last';
import { useLocation } from '@fjedi/react-router-helpers';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { RiFilterLine, RiSearchLine, RiShieldKeyholeLine } from 'react-icons/ri';
import { MediaFolderType } from 'src/graphql/generated';
import { ICONS, MediaFolderTypeList } from 'src/components/ui-kit/thumbnail/media-thumbnail';
import { Input } from 'src/components/ui-kit/input';
import { pascalCase } from 'src/functions';
import * as Components from './tabs/components';
import { Tabs } from '../tabs';
import { MediaItemListProps } from '../media-item/media-item-list';

const AsideWrapper = styled.div`
  background-color: #ffffff;
  width: 20rem;
  height: 100%;
  position: relative;
  border-radius: 0.625rem;
  padding-bottom: 3.0625rem;
  box-shadow: 0 0.125rem 0.875rem rgba(0, 0, 0, 0.08);
  overflow-y: hidden;

  display: flex;
  flex-direction: column;

  .ant-tabs {
    flex-grow: 1;
    overflow: hidden;
    flex-direction: column;

    .ant-tabs-content-holder {
      flex-grow: 1;
      overflow: hidden;
    }

    .ant-tabs-content {
      height: 100%;
    }

    .ant-tabs-tabpane {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .ant-collapse {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .ant-spin-nested-loading + .ant-spin-nested-loading {
    margin-top: 0.625rem;
  }

  .ant-picker-calendar {
    /* padding: 0.5rem 1.875rem 0.625rem; */
  }
`;

const Header = styled.div`
  padding: 1.25rem 1.875rem 0.625rem;
  margin-bottom: 0.5rem;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.2;
`;

const Search = styled(Input)`
  &.ant-input-affix-wrapper {
    height: 2.25rem;
    min-height: 2.25rem;
    width: auto;
    display: flex;
    margin: 0 1.875rem 1.25rem;
  }
`;

const Divider = styled.div`
  height: 0.5rem;
  min-height: 0.5rem;
  margin-top: -0.5rem;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    top: -0.5rem;
  }
`;

const FooterComponent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  box-shadow: 0px -0.125rem 1.5rem rgba(0, 0, 0, 0.08);

  .ant-btn {
    height: 3.125rem;
    font-size: 0.875rem;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    + .ant-btn {
      &:after {
        content: '';
        position: absolute;
        left: -0.0625rem;
        width: 0.0625rem;
        height: 1.625rem;
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }
`;

const TabBody = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-grow: 1;
  overflow: hidden;
`;

const Warning = styled.div`
  background-color: rgba(250, 250, 250, 0.8);
  color: #000000;
  font-weight: 600;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
  z-index: 2;
  user-select: none;
`;

const WarningIcon = styled.div`
  font-size: 2.25rem;
  display: flex;
  margin-bottom: 0.5rem;
`;

export type AsideMediaManagerProps = {
  onDragStart?: MediaItemListProps['onDragStart'];
  onDragEnd?: MediaItemListProps['onDragEnd'];
};

const AsideMediaManager: FC<AsideMediaManagerProps> = ({ onDragStart, onDragEnd }) => {
  const { t } = useTranslation();
  //
  const [filterValue, setFilterValue] = useState('');
  const onFilterChange = useCallback<ChangeEventHandler<HTMLInputElement>>(event => {
    setFilterValue(event.target.value);
  }, []);
  const [mediaTabId, setMediaTab] = useState<MediaFolderTypeList>(MediaFolderType.Image);
  const onChangeTab = useCallback((tabId: string) => {
    setMediaTab(tabId as MediaFolderTypeList);
  }, []);
  //
  const { pathname } = useLocation();
  const tabURL = last(pathname.split('/'));
  // We should show overlay to disable drag-n-drop
  // if user opens 'Default content' tab on Schedule page
  // and active media-manager's tab is TEMPLATE or PLAYLIST
  const tabIsDisabled =
    ((mediaTabId === 'TEMPLATE' || mediaTabId === 'PLAYLIST') && tabURL === 'default-media-item') ||
    (mediaTabId === 'PLAYLIST' && pathname.startsWith('/playlist'));
  const tabIsDisabledAlert = useMemo(() => {
    if (!tabIsDisabled) {
      return null;
    }
    let text =
      mediaTabId === 'TEMPLATE'
        ? 'You cannot use a template as default content'
        : 'You cannot use a playlist as default content';
    if (pathname.startsWith('/playlist')) {
      text = 'You cannot add a playlist to another one';
    }

    return (
      <TabBody>
        <Warning>
          <WarningIcon>
            <RiShieldKeyholeLine />
          </WarningIcon>
          {t(text)}
        </Warning>
      </TabBody>
    );
  }, [t, mediaTabId, tabIsDisabled, pathname]);

  const createTab = useCallback(
    (tabKey: MediaFolderTypeList) => ({
      label: <Tooltip title={t(`${pascalCase(tabKey)}s`)}>{ICONS[tabKey]}</Tooltip>,
      key: tabKey,
      children: (
        <>
          <Search
            allowClear
            value={filterValue}
            onChange={onFilterChange}
            placeholder={t('Search')}
            prefix={<RiSearchLine />}
            suffix={<RiFilterLine />}
          />
          <Divider />
          <Components.List
            filterValue={filterValue}
            contentType={tabKey}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          />
          {tabIsDisabledAlert}
        </>
      ),
    }),
    [t, filterValue, onFilterChange, onDragStart, onDragEnd, tabIsDisabledAlert],
  );

  const tabKeys = useMemo<Array<MediaFolderTypeList>>(() => Object.keys(ICONS) as Array<MediaFolderTypeList>, []);

  const tabs = useMemo(() => tabKeys.map(tabKey => createTab(tabKey)), [tabKeys, createTab]);

  return (
    <AsideWrapper>
      <Header>
        <Title>{t('Library')}</Title>
      </Header>

      <Tabs activeKey={mediaTabId} type="card" onChange={onChangeTab} items={tabs} destroyInactiveTabPane />

      <FooterComponent>
        <Components.Footer contentType={mediaTabId} />
      </FooterComponent>
    </AsideWrapper>
  );
};

export default memo(AsideMediaManager);
