import React, { useMemo, useCallback, useContext, useState, useEffect, ReactElement } from 'react';
import { SubscriptionResult, MutationResult, ApolloCache, MutationHookOptions } from '@fjedi/graphql-react-components';
import { ViewerContext } from '@fjedi/react-router-helpers';
import { updateInnerListOfCachedObject } from 'src/helpers/apollo-cache-helpers';
import {
  GetSchedulesDocument,
  GetSchedulesQuery,
  GetSchedulesQueryResult,
  GetSchedulesQueryVariables,
  ScheduleFragmentDoc,
  useDeviceChangedSubscription,
  useDeviceCreatedSubscription,
  useDeviceRemovedSubscription,
  useGetSchedulesQuery,
  Viewer,
} from 'src/graphql/generated';
import logger from 'src/helpers/logger';
import { SpinnerComponent } from 'src/components/ui-kit/scroll-pagination';

export type ScrollPaginationData = Pick<GetSchedulesQueryResult, 'error' | 'loading'> & {
  schedules: GetSchedulesQuery['getSchedules']['rows'];
  onScrollFrame: (_ev: { top: number }) => unknown;
  page: number;
  loadingNextPage: boolean;
  nextPageLoader: ReactElement;
  variables: GetSchedulesQueryVariables;
  update: MutationHookOptions['update'];
};

export function useGetSchedulesWithScrollPagination(options?: {
  filterValue?: string;
  foldersPerPage?: number;
}): ScrollPaginationData {
  const viewer = useContext(ViewerContext) as Viewer;
  const projectId = viewer?.primaryProjectId;
  const schedulesPerPage = options?.foldersPerPage ?? 5;
  const filterValue = options?.filterValue ?? '';
  const [page, setPage] = useState<number>(0);
  const [loadingNextPage, setLoadingNextPage] = useState<boolean>(false);

  const variables = useMemo<GetSchedulesQueryVariables>(
    () => ({
      filter: { projectId: projectId ? [projectId] : undefined, deviceName: filterValue ?? undefined },
      pagination: {
        limit: schedulesPerPage,
        offset: 0,
      },
    }),
    [projectId, filterValue, schedulesPerPage],
  );
  const queryRes = useGetSchedulesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables,
    context: {
      debounceKey: 'schedules-list',
      debounceTimeout: 400,
    },
  });
  const {
    data: schedulesRes,
    error: schedulesError,
    loading: schedulesLoading,
    fetchMore: fetchMoreSchedules,
  } = queryRes;
  useEffect(() => {
    if (!page) {
      return;
    }
    const v = {
      pagination: {
        offset: page * schedulesPerPage,
        limit: schedulesPerPage,
      },
    };
    setLoadingNextPage(true);
    fetchMoreSchedules({
      variables: v,
    })
      .catch(logger)
      .finally(() => setLoadingNextPage(false));
  }, [page, schedulesPerPage, fetchMoreSchedules]);
  const schedules = schedulesRes?.getSchedules?.rows || [];
  const hasNextPage = schedulesRes?.getSchedules?.pageInfo?.current < schedulesRes?.getSchedules?.pageInfo?.total;
  const onScrollFrame = useCallback(
    ({ top }: { top: number }) => {
      if (top < 0.8 || schedulesLoading || !hasNextPage) {
        return;
      }
      setPage(schedulesRes?.getSchedules?.pageInfo?.current ?? page + 1);
    },
    [schedulesRes, schedulesLoading, page],
  );

  //
  const update = useSubscribeToScheduleDevices(variables);

  return {
    error: schedulesError,
    loading: !schedules?.length && schedulesLoading,
    loadingNextPage,
    nextPageLoader: <SpinnerComponent loading={loadingNextPage} hasNextPage={hasNextPage} />,
    schedules,
    onScrollFrame,
    page,
    variables,
    update,
  };
}

export function useSubscribeToScheduleDevices(
  queryVariables: GetSchedulesQueryVariables,
): MutationHookOptions['update'] {
  const viewer = useContext(ViewerContext) as Viewer;
  const projectId = viewer?.primaryProjectId;

  //
  const variables = useMemo(() => ({ filter: { projectId: [projectId!] } }), [projectId]);

  const updateCachedSchedules = useCallback(
    (cache: ApolloCache<unknown>, result: SubscriptionResult | MutationResult) => {
      updateInnerListOfCachedObject({
        containerDataType: 'Schedule',
        dataTypes: ['Device'],
        foreignKey: 'scheduleId',
        containerFieldName: 'devices',
        queryDoc: GetSchedulesDocument,
        queryFragmentDoc: ScheduleFragmentDoc,
        queryVariables,
      })(cache, result);
    },
    [queryVariables],
  );

  useDeviceCreatedSubscription({
    variables,
    skip: !projectId,
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedSchedules(client.cache, subscriptionData);
    },
  });
  useDeviceChangedSubscription({
    variables,
    skip: !projectId,
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedSchedules(client.cache, subscriptionData);
    },
  });
  useDeviceRemovedSubscription({
    variables,
    skip: !projectId,
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedSchedules(client.cache, subscriptionData);
    },
  });

  return updateCachedSchedules as unknown as MutationHookOptions['update'];
}
