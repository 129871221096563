/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable security/detect-object-injection */
import openWeatherLightIcons from './openweatherlight';
import openWeatherDarkIcons from './openweatherdark';
import simpleBlackIcons from './simple-black';
import simpleWhiteIcons from './simple-white';

export enum WeatherIconsSet {
  OpenWeatherDark = 'OPEN_WEATHER_DARK',
  OpenWeatherLight = 'OPEN_WEATHER_LIGHT',
  SimpleWhite = 'SIMPLE_WHITE',
  SimpleBlack = 'SIMPLE_BLACK',
}

export type IconType =
  | '01d'
  | '01n'
  | '02d'
  | '02n'
  | '03d'
  | '03n'
  | '04d'
  | '04n'
  | '09d'
  | '09n'
  | '10d'
  | '10n'
  | '11d'
  | '11n'
  | '13d'
  | '13n'
  | '50d'
  | '50n';

const DEFAULT_ICON = '01n';

function getWeatherIcon(setType: WeatherIconsSet, icon: IconType) {
  switch (setType) {
    case WeatherIconsSet.OpenWeatherLight:
      return openWeatherLightIcons[icon] || openWeatherLightIcons[DEFAULT_ICON];
    case WeatherIconsSet.OpenWeatherDark:
      return openWeatherDarkIcons[icon] || openWeatherDarkIcons[DEFAULT_ICON];
    case WeatherIconsSet.SimpleWhite:
      return simpleWhiteIcons[icon] || simpleWhiteIcons[DEFAULT_ICON];
    case WeatherIconsSet.SimpleBlack:
      return simpleBlackIcons[icon] || simpleBlackIcons[DEFAULT_ICON];
    default:
      return openWeatherLightIcons[icon] || openWeatherLightIcons[DEFAULT_ICON];
  }
}

export default getWeatherIcon;
