/* eslint-disable react/require-default-props */
import React, { FC, memo, useCallback, useContext, useMemo, useState, MouseEvent } from 'react';
import { useNavigate } from '@fjedi/react-router-helpers';
import orderBy from 'lodash/orderBy';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from 'graphql';
import { useSubscribeToMore, SubscribeToMoreProps } from '@fjedi/graphql-react-components';
import { Empty } from 'antd';
import logger from 'src/helpers/logger';
import {
  MediaFolderType,
  MediaItem,
  MediaItemType,
  useGetMediaItemsQuery,
  useGetPlaylistsQuery,
  useGetTemplatesQuery,
  Template,
  MediaFolderItem,
  UsedItem,
} from 'src/graphql/generated';
import playlistCreatedSubscription from 'src/graphql/subscriptions/playlist-created.graphql';
import playlistChangedSubscription from 'src/graphql/subscriptions/playlist-changed.graphql';
import playlistRemovedSubscription from 'src/graphql/subscriptions/playlist-removed.graphql';
import templateCreatedSubscription from 'src/graphql/subscriptions/template-created.graphql';
import templateChangedSubscription from 'src/graphql/subscriptions/template-changed.graphql';
import templateRemovedSubscription from 'src/graphql/subscriptions/template-removed.graphql';
import mediaItemCreatedSubscription from 'src/graphql/subscriptions/media-item-created.graphql';
import mediaItemChangedSubscription from 'src/graphql/subscriptions/media-item-changed.graphql';
import mediaItemRemovedSubscription from 'src/graphql/subscriptions/media-item-removed.graphql';
import Spinner from 'src/components/ui-kit/spinner';
import MediaThumbnail, { isImage } from 'src/components/ui-kit/thumbnail/media-thumbnail';
import MediaItemUsageContext, { MediaItemUsageContextProps } from 'src/components/ui-kit/thumbnail/context';
import { TemplateContext } from 'src/components/routes/private/template-editor/context';
import { PlaylistContext } from 'src/components/routes/private/playlist-editor/context';
import MediaItemUsageDialog from './media-item-usage-dialog';
import type { MediaFolderItemProfile, MediaFolderProfile } from './media-item.d';

export type MediaItemListProps = {
  items?: MediaFolderItemProfile[];
  loading?: boolean;
  onClick?: (_item: MediaFolderItem, _e: MouseEvent<Element, MouseEvent>) => void;
  onDragStart?: (_item: MediaFolderItem) => void;
  onDragEnd?: (_item: MediaFolderItem) => void;
  isEditable?: boolean;
  isRemovable?: boolean;
  contentType: MediaFolderType;
  filterValue?: string;
  folder?: MediaFolderProfile;
  renderItem?: (_item: MediaFolderItem, _i: number, _folder: MediaFolderProfile | null) => React.ReactElement;
};

const MediaItemList: FC<MediaItemListProps> = props => {
  const {
    items: outsideItems,
    loading: outsideLoading,
    filterValue,
    onClick,
    onDragStart,
    onDragEnd,
    folder,
    isEditable = true,
    isRemovable = true,
    renderItem,
    contentType,
  } = props;
  const { t } = useTranslation();
  //
  const templateFromContext = useContext(TemplateContext);
  const playlistFromContext = useContext(PlaylistContext);
  const [isUsageErrorVisible, setIsUsageErrorVisible] = useState(false);
  const [mediaUsages, setMediaUsages] = useState<UsedItem[]>([]);
  const context = useMemo<MediaItemUsageContextProps>(
    () => ({
      isUsageErrorVisible,
      mediaUsages,
      setIsUsageErrorVisible,
      setMediaUsages,
    }),
    [isUsageErrorVisible, mediaUsages, setIsUsageErrorVisible, setMediaUsages],
  );
  const isMediaItem = !contentType || contentType === 'VIDEO' || isImage(contentType as unknown as MediaItemType);
  const dataType: 'MediaItem' | 'Playlist' | 'Template' = isMediaItem
    ? 'MediaItem'
    : (capitalize(contentType) as 'Playlist' | 'Template');
  //
  const skipQuery = typeof outsideItems !== 'undefined';
  const variables = useMemo(() => {
    const commonVars = { filter: { folderId: folder ? [folder.id] : undefined, title: filterValue || undefined } };
    if (contentType === 'PLAYLIST' && playlistFromContext) {
      return { ...commonVars, filter: { ...commonVars.filter, avoidRecursion: true } };
    }
    if (contentType === 'TEMPLATE' && templateFromContext) {
      return { ...commonVars, filter: { ...commonVars.filter, avoidRecursion: true } };
    }
    return commonVars;
  }, [filterValue, folder, contentType, playlistFromContext, templateFromContext]);
  const mediaItemsQuery = useGetMediaItemsQuery({
    skip: skipQuery || dataType !== 'MediaItem',
    fetchPolicy: 'cache-and-network',
    variables,
  });
  const playlistsQuery = useGetPlaylistsQuery({
    skip: skipQuery || dataType !== 'Playlist',
    fetchPolicy: 'cache-and-network',
    variables,
  });
  const templatesQuery = useGetTemplatesQuery({
    skip: skipQuery || dataType !== 'Template',
    fetchPolicy: 'cache-and-network',
    variables,
  });

  const loading =
    outsideLoading || mediaItemsQuery.loading || playlistsQuery.loading || templatesQuery.loading || false;
  const rows: MediaFolderItemProfile[] =
    outsideItems ||
    mediaItemsQuery.data?.getMediaItems?.rows ||
    playlistsQuery.data?.getPlaylists?.rows ||
    templatesQuery.data?.getTemplates?.rows ||
    [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subscriptionQueries = useMemo<DocumentNode[]>(() => [], [contentType]);
  let subscribeToMore: SubscribeToMoreProps['subscribeToMore'] | null = null;
  if (isMediaItem) {
    subscribeToMore = mediaItemsQuery.subscribeToMore as unknown as SubscribeToMoreProps['subscribeToMore'];
    subscriptionQueries.push(mediaItemCreatedSubscription, mediaItemChangedSubscription, mediaItemRemovedSubscription);
  } else if (contentType === 'PLAYLIST') {
    subscribeToMore = playlistsQuery.subscribeToMore as unknown as SubscribeToMoreProps['subscribeToMore'];
    subscriptionQueries.push(playlistCreatedSubscription, playlistChangedSubscription, playlistRemovedSubscription);
  } else if (contentType === 'TEMPLATE') {
    subscribeToMore = templatesQuery.subscribeToMore as unknown as SubscribeToMoreProps['subscribeToMore'];
    subscriptionQueries.push(templateCreatedSubscription, templateChangedSubscription, templateRemovedSubscription);
  }
  const subscriptionProps = useMemo(
    () =>
      skipQuery
        ? ({} as SubscribeToMoreProps)
        : ({
            dataType,
            subscriptionId: `${dataType}-list`,
            subscriptionQueries,
            subscribeToMore,
            variables,
          } as SubscribeToMoreProps),
    [skipQuery, variables, dataType, subscribeToMore, subscriptionQueries],
  );
  useSubscribeToMore(subscriptionProps);
  //
  const navigate = useNavigate();
  const onItemClick = useCallback(
    (item: MediaFolderItem) => (event: MouseEvent<Element, MouseEvent>) => {
      const { currentTarget } = event;

      logger('Components.List.onItemClick', {
        contentType,
        event,
        item,
        nodeName: currentTarget.nodeName,
      });

      if (typeof onClick === 'function') {
        onClick(item, event);
      } else if (
        ['H5', 'IMG', 'DIV'].includes(currentTarget.nodeName) &&
        ['PLAYLIST', 'TEMPLATE'].includes(contentType)
      ) {
        navigate(`/${contentType.toLowerCase()}/${item.id}`);
      }
    },
    [contentType, navigate, onClick],
  );

  const hideUsageErrorDialog = useMemo(
    () => () => {
      setIsUsageErrorVisible(false);
      setMediaUsages([]);
    },
    [setIsUsageErrorVisible, setMediaUsages],
  );
  const sortedItems = useMemo(() => orderBy(rows, 'createdAt', 'desc'), [rows]);

  if (sortedItems.length === 0 && !loading) {
    return (
      <Empty
        style={{ width: '100%' }}
        description={<span>{t(`No ${contentType.toLowerCase()}s${filterValue ? ' found' : ''}`)}</span>}
      />
    );
  }
  if (sortedItems.length === 0 && loading) {
    return (
      <Spinner
        spinning
        style={{
          display: 'block',
          marginTop: '2rem',
          marginBottom: '2rem',
          width: '100%',
        }}
      />
    );
  }
  //
  return (
    <MediaItemUsageContext.Provider value={context}>
      {sortedItems.map((item, itemIndex) => {
        if (typeof renderItem === 'function') {
          const f = folder || ('folder' in item && item.folder ? item.folder : null);
          return renderItem(item as MediaFolderItem, itemIndex, f);
        }
        let isDefault = false;
        // eslint-disable-next-line no-underscore-dangle
        if (item.__typename === 'Template') {
          isDefault = (item as Template).isDefault ?? false;
        }
        return (
          <MediaThumbnail
            key={item.id}
            data={item as MediaFolderItem}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onClick={onItemClick(item as MediaFolderItem)}
            showPreview={typeof onClick !== 'function'}
            stub={contentType !== 'PLAYLIST' && contentType !== 'TEMPLATE' && !(item as MediaItem).url}
            isEditable={isEditable && !isDefault}
            isRemovable={isRemovable && !isDefault}
          />
        );
      })}

      <MediaItemUsageDialog onCancel={hideUsageErrorDialog} />
    </MediaItemUsageContext.Provider>
  );
};

MediaItemList.displayName = 'MediaItemList';

export default memo(MediaItemList);
