import React, { memo, useCallback, useContext, useMemo } from 'react';
import { ViewerContext } from '@fjedi/react-router-helpers';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Modal as AntModal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import {
  RiCloseLine,
  RiFileAddLine,
  RiFilmLine,
  RiImageAddLine,
  RiLinksLine,
  RiCalendarEventLine,
  RiNavigationLine,
  RiPlayList2Line,
  RiMapPinLine,
} from 'react-icons/ri';
import { AiOutlineMedium, AiOutlineYoutube } from 'react-icons/ai';
import { MdLiveTv, MdFlight } from 'react-icons/md';
import { TiWeatherPartlySunny } from 'react-icons/ti';
import { WiTime4 } from 'react-icons/wi';
import { getMediaItemsDuration } from 'src/functions';
import { uuid } from 'src/helpers/uuid';
import logger from 'src/helpers/logger';
import time from 'src/helpers/time';
import AntDrawer from 'src/components/ui-kit/drawer';
import Button from 'src/components/ui-kit/buttons';
import Scrollbar from 'src/components/ui-kit/scrollbar';
import TooltipComponent from 'src/components/ui-kit/tooltip';
import { colorTheme } from 'src/components/ui-kit/theme';
import { DateTimeWidgetIcon, HLSWidgetIcon, LogoWidgetIcon } from 'src/components/ui-kit/icons';
import { EditorContext, EditorMenuContext } from './context';
import { MediaItemSelector } from './media-item-selector';
import { TEMPLATE_TYPES, SINGLE_INSTANCE_TEMPLATE_TYPES } from './constants';

const Drawer = styled(AntDrawer)`
  z-index: 499;
  .ant-drawer-content {
    border-radius: 0.5rem;
    box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.08);
  }
`;

const Menu = styled.div``;

const StyledScrollbar = styled(Scrollbar)`
  height: 16rem !important;
`;

const MenuHeader = styled.div`
  text-align: center;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const MenuTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
`;

const MenuSubTitle = styled.div`
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.2);
`;

const MenuContent = styled.div`
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  .ant-btn {
    /* font-size: 0.75rem; */
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 30%;

    + .ant-btn {
      margin-top: 0.25rem;
    }
  }
`;

const MenuBtnMuted = styled(Button)`
  &.ant-btn {
    color: rgba(0, 0, 0, 0.4);
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.3);
    }
    &:active {
      color: rgba(0, 0, 0, 0.5);
    }
  }
`;

const MenuFooter = styled.div`
  box-shadow: 0px -0.125rem 1.5rem rgb(0 0 0 / 8%);

  .ant-btn {
    height: 3.125rem;
    font-size: 0.875rem;
    width: 100%;
  }
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  color: #6a75ca;
  display: none;
`;

const DisabledBtnWrapper = styled.div`
  position: relative;

  button {
    pointer-events: none;
  }

  &:hover ${StyledInfoCircleOutlined} {
    display: block;
  }
`;

const DisabledTemplateBtn = ({ children }) => (
  <DisabledBtnWrapper>
    {children}
    <TooltipComponent
      placement="left"
      title="You can add only one video or playlist, or HLS, or multicast on the template">
      <StyledInfoCircleOutlined />
    </TooltipComponent>
  </DisabledBtnWrapper>
);

DisabledTemplateBtn.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};
DisabledTemplateBtn.defaultProps = {
  children: undefined,
};

export const AddTemplateItemMenu = memo(() => {
  const viewer = useContext(ViewerContext);
  const { onCreate, unsavedChanges } = useContext(EditorContext);
  const { onMenuClose, onMenuOpen, menuStatus } = useContext(EditorMenuContext);
  const { t } = useTranslation();
  //
  const createTemplateArea = useCallback(
    areaType => mediaItemOrPlaylist => {
      onMenuClose();

      const itemCommonProps = {
        id: uuid(),
        __typename: 'MediaItem',
        projectId: viewer?.primaryProjectId,
      };
      let item = null;
      let itemId = null;
      let playlist = null;
      let playlistId = null;
      let title = null;
      const timeConditions = null;
      const style = { position: 'absolute', overflow: 'hidden', top: 0, left: 0, width: 400, height: 300 };
      //
      switch (areaType) {
        case TEMPLATE_TYPES.TEXT:
          title = 'New text area';
          style.color = '#ffffff';
          style['font-size'] = 64;

          break;

        case TEMPLATE_TYPES.IFRAME:
          item = {
            title: 'New iframe',
            type: TEMPLATE_TYPES.IFRAME,
            url: '',
          };

          title = 'New iframe';
          break;

        case TEMPLATE_TYPES.YOUTUBE:
          item = {
            title: 'YouTube',
            type: TEMPLATE_TYPES.YOUTUBE,
            url: '',
          };

          title = 'YouTube';
          break;

        case TEMPLATE_TYPES.HLS:
          item = {
            title: '',
            type: TEMPLATE_TYPES.HLS,
            url: '',
          };

          title = TEMPLATE_TYPES.HLS;
          break;

        case TEMPLATE_TYPES.MULTICAST:
          item = {
            title: 'Multicast',
            type: TEMPLATE_TYPES.MULTICAST,
            url: '',
          };

          title = 'Multicast';
          break;

        case TEMPLATE_TYPES.WEATHER:
          item = {
            title: 'Weather',
            type: TEMPLATE_TYPES.WEATHER,
            url: '',
            props: {
              api: { days: 7 },
              icons: { size: 108, set: 'OPEN_WEATHER_LIGHT' },
              dateLabel: 'DD MMM',
              location: { lat: 51.509865, lng: -0.118092 },
            },
          };

          style.color = '#ffffff';
          style.width = 800;
          style.height = 300;
          title = 'Weather';
          break;

        case TEMPLATE_TYPES.DATETIME:
          item = {
            title: 'Date & Time',
            type: TEMPLATE_TYPES.DATETIME,
            url: '',
            props: {
              format: 'DD.MM.YYYY',
              timezone: '',
            },
          };

          style.color = '#ffffff';
          style['font-size'] = 64;
          title = 'Date & Time';
          break;

        case TEMPLATE_TYPES.CLOCK:
          title = 'Analog Clock';
          item = {
            title: 'Analog Clock',
            type: TEMPLATE_TYPES.CLOCK,
            url: '',
            props: {
              timezone: '',
              skin: 'Circle',
            },
          };

          style.color = '#ffffff';
          break;

        case TEMPLATE_TYPES.PLAYLIST:
          playlist = { ...mediaItemOrPlaylist, duration: getMediaItemsDuration(mediaItemOrPlaylist.items) };
          playlistId = mediaItemOrPlaylist?.id;
          title = mediaItemOrPlaylist.title ?? 'Playlist';

          break;

        case TEMPLATE_TYPES.LOCATION_EVENT_LOGO:
          item = {
            title: 'Sample Logo',
            type: TEMPLATE_TYPES.LOCATION_EVENT_LOGO,
            url: '',
          };

          style.width = 240;
          style.height = 240;
          style.backgroundEnabled = true;
          style['background-color'] = colorTheme.primary;
          style['border-radius'] = '10%';
          title = 'Event Logo';
          break;

        case TEMPLATE_TYPES.LOCATION_HEADER:
          item = {
            title: 'Sample location name',
            type: TEMPLATE_TYPES.LOCATION_HEADER,
          };

          title = 'Location header';
          style.color = '#ffffff';
          style['font-size'] = 64;
          style['text-align'] = 'center';
          style['background-color'] = 'transparent';
          style.width = 1000;
          break;

        case TEMPLATE_TYPES.LOCATION_EVENTS:
          item = {
            title: 'Events/Meetings',
            type: TEMPLATE_TYPES.LOCATION_EVENTS,
            props: {
              eventTime: true,
              nextEvent: true,
              separatorColor: '#ffffff',
              separatorSize: 1,
              eventTitleColor: '#ffffff',
              eventTimeColor: '#ffffff',
              eventLocationColor: '#ffffff',
            },
          };

          style.width = 800;
          style.height = 400;
          style['text-align'] = 'left';
          style['font-size'] = 64;
          title = 'Events / Meetings';
          break;

        case TEMPLATE_TYPES.LOCATION_WAYFINDING:
          item = {
            title: 'Wayfinding',
            type: TEMPLATE_TYPES.LOCATION_WAYFINDING,
            props: {
              visibleRows: 4,
              eventLogo: true,
              eventTime: true,
              eventLocation: true,
              separatorSize: 1,
              separatorColor: '#ffffff',
              eventTitleColor: '#ffffff',
              eventTimeColor: '#ffffff',
              eventLocationColor: '#ffffff',
              paginationEnabled: true,
              pagination: {
                interval: 15,
                fontFamily: 'Lato',
                fontSize: 48,
                fontColor: '#ffffff',
                horizontalAlignment: 'center',
                verticalAlignment: 'bottom',
              },
            },
          };

          title = 'Wayfinding';
          style.width = 1280;
          style.height = 915;
          style['text-align'] = 'left';
          style['font-size'] = 64;
          break;

        case TEMPLATE_TYPES.FLIGHTS:
          item = {
            title: 'Flights',
            type: TEMPLATE_TYPES.FLIGHTS,
            props: {
              airport: 'DXB',
              direction: 'ARRIVALS',
              visibleRows: 7,
              mainColor: '#ffffff',
              headerColor: '#808080',
              separatorSize: 0,
              separatorColor: '#ffffff',
              paginationEnabled: false,
              pagination: {
                interval: 15,
                fontFamily: 'Lato',
                fontSize: 48,
                fontColor: '#ffffff',
                horizontalAlignment: 'center',
                verticalAlignment: 'bottom',
              },
            },
          };

          title = 'Flights';
          style.width = 1200;
          style.height = 600;
          style['text-align'] = 'left';
          style['font-size'] = 42;
          break;

        default:
          item = mediaItemOrPlaylist;
          itemId = mediaItemOrPlaylist?.id;
          title = mediaItemOrPlaylist.title ?? 'New item';
      }

      const area = {
        __typename: 'TemplateArea',
        // createdAt: time().toISOString(),
        updatedAt: time().toISOString(),
        id: uuid(1),
        item: item && { ...itemCommonProps, ...item },
        itemId,
        playlist,
        playlistId,
        timeConditions,
        title,
        style: {
          '16:9': style,
        },
      };
      //
      if (area.playlist && (area.playlist.duration === 0 || area.playlist.items.length === 0)) {
        AntModal.warning({
          title: t('Warning!'),
          content: t('You can not add empty playlist to template!'),
        });
        return;
      }
      //
      logger('createTemplateArea', { area });
      onCreate(area);
    },
    [onMenuClose, viewer?.primaryProjectId, onCreate, t],
  );

  const canAddSingleInstanceTmpl = useMemo(
    () => !unsavedChanges?.areas?.some(a => SINGLE_INSTANCE_TEMPLATE_TYPES.includes(a.item?.type) && !a.deletedAt),
    [unsavedChanges],
  );

  const videoBtn = (
    <MediaItemSelector
      onSelectItem={createTemplateArea(TEMPLATE_TYPES.VIDEO)}
      contentType={TEMPLATE_TYPES.VIDEO}
      title={t('Choose video')}
      buttonText={t('Add Video')}
      buttonIcon={<RiFilmLine />}
      buttonDisabled={!canAddSingleInstanceTmpl}
    />
  );
  const playlistBtn = (
    <MediaItemSelector
      onSelectItem={createTemplateArea(TEMPLATE_TYPES.PLAYLIST)}
      contentType={TEMPLATE_TYPES.PLAYLIST}
      title={t('Choose playlist')}
      buttonText={t('Add Playlist')}
      buttonIcon={<RiPlayList2Line />}
      buttonDisabled={!canAddSingleInstanceTmpl}
    />
  );
  const hslBtn = (
    <Button
      type="link"
      icon={<HLSWidgetIcon color={colorTheme.primary} size="1.5rem" />}
      onClick={createTemplateArea(TEMPLATE_TYPES.HLS)}
      disabled={!canAddSingleInstanceTmpl}>
      {t('Add HLS')}
    </Button>
  );

  const multicastBtn = (
    <Button
      type="link"
      icon={<MdLiveTv />}
      onClick={createTemplateArea(TEMPLATE_TYPES.MULTICAST)}
      disabled={!canAddSingleInstanceTmpl}>
      {t('Add Multicast')}
    </Button>
  );

  return (
    <>
      <Button type="link" icon={<RiFileAddLine />} onClick={onMenuOpen}>
        {t('Add item')}
      </Button>
      <Drawer
        placement="bottom"
        closable={false}
        onClose={onMenuClose}
        open={menuStatus}
        getContainer={false}
        height="auto">
        <Menu>
          <MenuHeader>
            <MenuTitle>{t('Add items')}</MenuTitle>
            <MenuSubTitle>{t('Click the button below')}</MenuSubTitle>
          </MenuHeader>
          <StyledScrollbar>
            <MenuContent>
              <Button type="link" icon={<AiOutlineMedium />} onClick={createTemplateArea(TEMPLATE_TYPES.TEXT)}>
                {t('Add Text')}
              </Button>
              <MediaItemSelector
                onSelectItem={createTemplateArea(TEMPLATE_TYPES.IMAGE)}
                contentType={TEMPLATE_TYPES.IMAGE}
                title={t('Choose image')}
                buttonText={t('Add Image')}
                buttonIcon={<RiImageAddLine />}
              />
              {canAddSingleInstanceTmpl ? (
                <>
                  {videoBtn}
                  {playlistBtn}
                </>
              ) : (
                <>
                  <DisabledTemplateBtn>{videoBtn}</DisabledTemplateBtn>
                  <DisabledTemplateBtn>{playlistBtn}</DisabledTemplateBtn>
                </>
              )}
              <Button type="link" icon={<RiLinksLine />} onClick={createTemplateArea(TEMPLATE_TYPES.IFRAME)}>
                {t('Add Iframe')}
              </Button>
              <Button type="link" icon={<WiTime4 />} onClick={createTemplateArea(TEMPLATE_TYPES.CLOCK)}>
                {t('Add Analog clock')}
              </Button>
              <Button
                type="link"
                icon={<DateTimeWidgetIcon color={colorTheme.primary} />}
                onClick={createTemplateArea(TEMPLATE_TYPES.DATETIME)}>
                {t('Add Date & Time')}
              </Button>
              <Button type="link" icon={<TiWeatherPartlySunny />} onClick={createTemplateArea(TEMPLATE_TYPES.WEATHER)}>
                {t('Add Weather')}
              </Button>
              <Button type="link" icon={<AiOutlineYoutube />} onClick={createTemplateArea(TEMPLATE_TYPES.YOUTUBE)}>
                {t('Add YouTube')}
              </Button>

              {canAddSingleInstanceTmpl ? (
                <>
                  {hslBtn}
                  {multicastBtn}
                </>
              ) : (
                <>
                  <DisabledTemplateBtn>{hslBtn}</DisabledTemplateBtn>
                  <DisabledTemplateBtn>{multicastBtn}</DisabledTemplateBtn>
                </>
              )}

              <Button
                type="link"
                icon={<LogoWidgetIcon color={colorTheme.primary} />}
                onClick={createTemplateArea(TEMPLATE_TYPES.LOCATION_EVENT_LOGO)}>
                {t('Add Event Logo')}
              </Button>
              <Button type="link" icon={<RiMapPinLine />} onClick={createTemplateArea(TEMPLATE_TYPES.LOCATION_HEADER)}>
                {t('Add Location Header')}
              </Button>
              <Button
                type="link"
                icon={<RiCalendarEventLine style={{ top: '-0.1rem' }} />}
                onClick={createTemplateArea(TEMPLATE_TYPES.LOCATION_EVENTS)}>
                {t('Add Events / Meetings')}
              </Button>
              <Button
                type="link"
                icon={<RiNavigationLine />}
                onClick={createTemplateArea(TEMPLATE_TYPES.LOCATION_WAYFINDING)}>
                {t('Add Wayfinding')}
              </Button>
              <Button type="link" icon={<MdFlight />} onClick={createTemplateArea(TEMPLATE_TYPES.FLIGHTS)}>
                {t('Add Flights')}
              </Button>
            </MenuContent>
          </StyledScrollbar>
          <MenuFooter>
            <MenuBtnMuted type="link" icon={<RiCloseLine />} onClick={onMenuClose}>
              {t('Close')}
            </MenuBtnMuted>
          </MenuFooter>
        </Menu>
      </Drawer>
    </>
  );
});
AddTemplateItemMenu.displayName = 'AddTemplateItemMenu';

export default AddTemplateItemMenu;
