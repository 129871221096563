import React, { FC, memo } from 'react';
import { Alert } from 'antd';

import { NotificationType } from './context';

interface NotificationProps {
  onCloseClick(): void;
  type: NotificationType;
  message: string;
}

const Notification: FC<NotificationProps> = ({ message, type, onCloseClick }) => (
  <Alert message={message} type={type} afterClose={onCloseClick} closable />
);

export default memo(Notification) as typeof Notification;
