import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import Select, { Option } from 'src/components/ui-kit/select';
import capitalize from 'lodash/capitalize';
import time, { formatDate } from 'src/helpers/time';

const WeekdaySelectorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const WeekdaySelect = styled(Select)`
  &.ant-select {
    width: 7.5rem;
    &.ant-select-single {
      .ant-select-selector {
        height: 1.875rem;
      }
    }
  }
  .ant-select-arrow {
    color: #6473c4;
  }
`;

export type WeekdaySelectorProps = { value?: number; onSelect: (_n: number) => void };

const WeekdaySelector: React.FC<WeekdaySelectorProps> = ({ value, onSelect }) => {
  const weekdays = [0, 1, 2, 3, 4, 5, 6];
  const onChange = useCallback((event: number) => onSelect(event), [onSelect]);
  return (
    <WeekdaySelectorContainer>
      <WeekdaySelect onChange={onChange} defaultValue={value}>
        {weekdays.map(weekday => (
          <Option key={weekday} value={weekday}>
            {capitalize(formatDate(time().weekday(weekday), 'dddd'))}
          </Option>
        ))}
      </WeekdaySelect>
    </WeekdaySelectorContainer>
  );
};
WeekdaySelector.displayName = 'WeekdaySelector';

export default memo(WeekdaySelector);
