import React, { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from '@fjedi/react-router-helpers';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'src/helpers/time';
import { Device } from 'src/graphql/generated';
import { RiDeleteBinLine } from 'react-icons/ri';
//
import { useMutation, logger, updateAfterMutation, useApolloError } from '@fjedi/graphql-react-components';
import updateDeviceMutation from 'src/graphql/mutations/update-device.graphql';
import removeDeviceMutation from 'src/graphql/mutations/remove-device.graphql';
import CustomButton from 'src/components/ui-kit/buttons';
import Tooltip from 'src/components/ui-kit/tooltip';
import Popconfirm from 'src/components/ui-kit/popconfirm';
//
import { GhostEditableTitle } from 'src/components/ui-kit/typography';
import Spinner from 'src/components/ui-kit/spinner';
import { colorTheme } from 'src/components/ui-kit/theme';
import { stopEventPropagation } from 'src/functions';
import DeviceFWOutOfDateTooltip from 'src/components/routes/private/devices/device-fw-outofdate-tooltip';

export type DeviceCardProps = { isSelected?: boolean; onClick?: (_i: unknown) => void };

export const Card = styled.div.attrs<DeviceCardProps>(({ isSelected }) => {
  return {
    className: `screen-card ${isSelected ? 'screen-card_selected' : ''}`,
  };
})<DeviceCardProps>`
  position: relative;
  cursor: pointer;
  border-radius: 0.625rem;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: ${props => (props.isSelected ? `0 0 0.25rem 0.25rem ${colorTheme.primary}` : 'unset')};
  height: 100%;
  min-height: 8rem;

  display: flex;
  flex-direction: column;

  &:hover .device-thumbnail-footer {
    .ant-typography.ant-typography > .ant-typography-edit {
      color: rgba(255, 255, 255, 0.4);
    }
  }
`;

export const Footer = styled.div.attrs({ className: 'device-thumbnail-footer' })`
  padding: 0 0.625rem 0 0.75rem;
  min-height: 2.5rem;
  display: flex;
  flex-grow: 0;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  color: rgba(255, 255, 255, 0.4);

  .ant-btn {
    color: inherit;
    width: auto;

    &:focus,
    &:active,
    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

export const Text = styled.div`
  overflow: hidden;
  flex-grow: 1;
`;

export const Title = styled(GhostEditableTitle)`
  flex-grow: 1;

  &.ant-typography.ant-typography {
    color: #ffffff;
    font-weight: 600;
    line-height: 1.2;

    > .ant-typography-edit {
      color: transparent;
      font-size: 1.125rem;

      &:focus,
      &:hover {
        color: #fff;
      }
    }

    & > span.ghost-editable-title-text {
      flex-basis: 85%;
      margin-right: auto;
    }

    & > div.ant-typography-edit.ant-typography-edit {
      flex-basis: 15%;
    }
  }
`;

export const Desc = styled.div`
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.4);
  font-weight: 600;
`;

const Header = styled.div`
  padding: 1.375rem 0.75rem;
  flex-grow: 1;
`;

const Name = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  color: rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.2s;

  ${Card}:hover &,
  ${Card}.screen-card_selected & {
    color: rgba(0, 0, 0, 1);
  }
`;

const HeaderText = styled.div`
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.2s;

  ${Card}:hover &,
  ${Card}.screen-card_selected & {
    color: rgba(0, 0, 0, 1);
  }
`;

export const Button = styled(CustomButton).attrs({
  type: 'link',
})`
  &.ant-btn {
    color: rgba(255, 255, 255, 0);
    display: inline-flex;
    padding: 3px;
    height: auto;

    //> .ant-btn-icon > svg,
    &.ant-btn-icon-only > .ant-btn-icon > svg,
    svg {
      font-size: 1.125rem;
    }
  }

  ${Card}:hover &,
  ${Card}.screen-card_selected & {
    &.ant-btn-icon-only,
    &.ant-btn {
      color: rgba(255, 255, 255, 0.4) !important;
    }
  }

  ${Card} & {
    &:hover {
      &.ant-btn {
        color: rgba(255, 255, 255, 0.84) !important;
      }
    }
  }
`;

const Info = styled.div`
  position: absolute;
  top: 0.375rem;
  right: 0.375rem;
  width: calc(100% - 0.75rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > .outdated-icon {
    margin-right: auto;
    font-size: 1rem;
    height: 1rem;
    padding: 0 0 0 0.3125rem;
  }
`;

export const Orientation = styled.div`
  background: #f7f8fc;
  border-radius: 20px;
  padding: 2px 0.3125rem 2px 0.3125rem;
  font-size: 0.625rem;
  line-height: 1;
  min-width: 2.125rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '\\00B0';
    display: block;
  }
`;

const Status = styled(Orientation)`
  margin-left: 0.25rem;
  min-width: 3.125rem;

  &:after {
    content: '';
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    margin-left: 0.125rem;
    background: #e64646;
    border-radius: 50%;
  }

  &[data-status='true'] {
    &:after {
      background: #27ae60;
    }
  }
`;

export type DeviceThumbnailProps = {
  loading?: boolean;
  isSelected?: boolean;
  isRemovable?: boolean;
  isOutdated?: boolean;
  data: Device;
  isLink?: boolean;
  onClick?: (_i: unknown) => void;
};

export const DeviceThumbnail: FC<DeviceThumbnailProps> = props => {
  const {
    loading = false,
    isSelected = false,
    isRemovable = false,
    isOutdated = false,
    data,
    isLink = false,
    onClick,
  } = props;
  const {
    id,
    name,
    vendor,
    isOnline,
    updatedAt,
    props: { orientation },
  } = data;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onError = useApolloError();
  //
  const [updateDevice] = useMutation(updateDeviceMutation, {
    onError,
  });
  const onDeviceNameChange = useCallback(
    (deviceName: string) => {
      updateDevice({
        variables: {
          id,
          input: {
            name: deviceName,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateSchedule: {
            id,
            __typename: 'Device',
            name: deviceName,
          },
        },
      }).catch(logger);
    },
    [id, updateDevice],
  );

  const [removeDevice, { loading: removing }] = useMutation(removeDeviceMutation, {
    update: updateAfterMutation('Device', 'getDevices'),
    onError,
  });
  const onDeviceRemove = useCallback(() => {
    removeDevice({ variables: { id } }).catch(logger);
  }, [id, removeDevice]);
  const openLink = useCallback(() => {
    if (isLink) {
      navigate(`/devices/${id}`);
    }
  }, [isLink, navigate, id]);
  //
  return (
    <Spinner spinning={loading || removing}>
      <Card isSelected={isSelected} onClick={onClick}>
        <Header>
          <Name>{vendor}</Name>
          <HeaderText>{formatDate(updatedAt, 'DD MMMM YYYY, HH:mm')}</HeaderText>
          <Info>
            {isOutdated && <DeviceFWOutOfDateTooltip vendor={vendor} />}
            {orientation > 0 && <Orientation>{orientation}</Orientation>}
            <Status data-status={isOnline}>{isOnline ? t('Online') : t('Offline')}</Status>
          </Info>
        </Header>
        <Footer>
          <Text onClick={openLink}>
            <Title level={5} value={name || ''} autoSize={{ minRows: 1, maxRows: 2 }} onChange={onDeviceNameChange} />
          </Text>
          {isRemovable && (
            <Popconfirm
              placement="topRight"
              title={`${t('Remove')}?`}
              onClick={stopEventPropagation}
              onConfirm={onDeviceRemove}
              okText={t('Yes')}
              cancelText={t('No')}>
              <Tooltip
                title={t('Remove')}
                placement="bottom"
                align={{
                  offset: [0, -2.5],
                }}>
                <Button icon={<RiDeleteBinLine />} />
              </Tooltip>
            </Popconfirm>
          )}
        </Footer>
      </Card>
    </Spinner>
  );
};

export default memo(DeviceThumbnail);
