import React, { memo, useCallback } from 'react';
//
import { useTranslation } from 'react-i18next';
// GraphQL
import { Mutation } from '@fjedi/graphql-react-components';
// GraphQL Queries
import changePasswordMutation from 'src/graphql/mutations/change-password.graphql';

// Layout
import { Modal } from 'antd';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { PasswordInput } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';
import { Row, Col } from 'src/components/ui-kit/grid';
import Scrollbar from 'src/components/ui-kit/scrollbar';

const UserProfileChangePasswordPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const checkPassword = useCallback(
    (rule, value) => {
      if (value && value !== form.getFieldValue('newPassword')) {
        return Promise.reject(t('Entered passwords must match'));
      }
      return Promise.resolve();
    },
    [form, t],
  );
  const onCompleted = useCallback(() => {
    Modal.info({
      content: t('Password successfully changed'),
    });
    form.resetFields();
  }, [form, t]);
  const onSubmit = useCallback(
    mutation => variables => {
      mutation({
        variables,
      });
    },
    [],
  );

  return (
    <Mutation onCompleted={onCompleted} mutation={changePasswordMutation}>
      {(mutation, { loading }) => (
        <Scrollbar>
          <Form form={form} layout="vertical" onFinish={onSubmit(mutation)}>
            <Row>
              <Col md={{ span: 8, offset: 8 }}>
                <FormItem
                  label={t('Old password')}
                  name="oldPassword"
                  rules={[
                    {
                      required: true,
                      message: t('Type your current password'),
                    },
                  ]}>
                  <PasswordInput placeholder="" autoComplete="current-password" />
                </FormItem>
                <FormItem
                  label={t('New password')}
                  name="newPassword"
                  rules={[
                    { required: true, message: t('Type new password') },
                    {
                      min: 8,
                      message: t('Minimal length of the password - {{length}} symbols', {
                        length: 8,
                      }),
                    },
                    {
                      max: 30,
                      message: t('Maximal length of the password - {{length}} symbols', {
                        length: 30,
                      }),
                    },
                  ]}>
                  <PasswordInput placeholder="" autoComplete="new-password" />
                </FormItem>
                <FormItem
                  label={t('Repeat password')}
                  name="passwordConfirmation"
                  rules={[{ required: true, message: t('Repeat new password') }, { validator: checkPassword }]}>
                  <PasswordInput placeholder="" autoComplete="new-password" />
                </FormItem>
                <Button block loading={loading} type="primary" htmlType="submit">
                  {t('Save')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Scrollbar>
      )}
    </Mutation>
  );
};

UserProfileChangePasswordPage.propTypes = {};
UserProfileChangePasswordPage.defaultProps = {};

export default memo(UserProfileChangePasswordPage);
