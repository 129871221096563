import { CSSProperties } from 'react';
import styled from 'styled-components';

interface IconProps {
  style?: CSSProperties;
  color?: CSSProperties['color'];
  colorOnHover?: CSSProperties['color'];
  size?:
    | {
        width: CSSProperties['width'];
        height: CSSProperties['height'];
      }
    | string;
  className?: string;
}

const Svg = styled.svg.attrs({ xmlns: 'http://www.w3.org/2000/svg' })<IconProps>`
  display: inline-block;
  position: relative;
  width: ${({ size, width }) => {
    if (size) {
      return typeof size === 'string' ? size : size?.width ?? '1.5rem';
    }

    return width ?? '1em';
  }};
  height: ${({ size, height }) => {
    if (size) {
      return typeof size === 'string' ? size : size?.height ?? '1.5rem';
    }

    return height ?? '1em';
  }};
  line-height: ${({ size, height }) => {
    if (size) {
      return typeof size === 'string' ? size : size?.height ?? '1.5rem';
    }
    return height ?? '1em';
  }};
  vertical-align: middle;

  path {
    fill: ${({ color }) => color ?? 'currentColor'};
    transition: fill 0.5s;
  }

  &:hover {
    path {
      fill: ${({ colorOnHover }) => colorOnHover ?? 'currentColor'};
    }
  }
`;

export default Svg;
