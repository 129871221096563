import React, { FC, memo, useState, useCallback, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { updateAfterMutation, useApolloError } from '@fjedi/graphql-react-components';
import { RiUpload2Line } from 'react-icons/ri';
import { Form } from 'antd';
import type { UploadFile } from 'antd/lib/upload/interface';
import { useImportEventsMutation, Viewer } from 'src/graphql/generated';
import logger from 'src/helpers/logger';
import FileUploader, { FileUploaderList } from 'src/components/ui-kit/file-uploader';
import Button from 'src/components/ui-kit/buttons';
import ButtonModalPopupComponent from 'src/components/ui-kit/modal-popup/button-modal';
import { AddButtonIcon, FooterModal, Progress, Uploading } from 'src/components/ui-kit/media-item/add-media-item';
import { ViewerContext } from '@fjedi/react-router-helpers';
import { API_URL } from 'src/constants';

const ImportEventsFromFileButton: FC = () => {
  const { t } = useTranslation();
  const { primaryProjectId: projectId } = useContext(ViewerContext) as Viewer;
  const onError = useApolloError();
  const [form] = Form.useForm();

  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const onFileUploadProgress = useCallback((ev: ProgressEvent) => {
    const progress = Math.min(parseInt(`${(ev.loaded / ev.total) * 100}`, 10) || 0, 100);
    setFileUploadProgress(progress);
  }, []);
  //
  const [visibleModal, setModalVisibility] = useState(false);
  const openModal = useCallback(() => {
    setModalVisibility(true);
    setFileUploadProgress(0);
  }, []);
  const closeModal = useCallback(() => {
    setModalVisibility(false);
    setSubmitDisabled(true);
  }, []);
  //
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const onUploaderChanged = useCallback((list: FileUploaderList) => {
    setSubmitDisabled(!(list && list.length > 0));
  }, []);
  //
  const onMediaItemCreated = useCallback(() => {
    form.resetFields();
    closeModal();
  }, [form, closeModal]);
  //
  const [importEvents, { loading: creatingMediaItem }] = useImportEventsMutation({
    onCompleted: onMediaItemCreated,
    update: updateAfterMutation('MediaItem', 'getMediaItems'),
    onError,
  });
  const onSubmit = useCallback(
    ({ files }: { files: UploadFile[] }) => {
      logger('Components.onSubmit', { files, projectId });
      if (!projectId) {
        return;
      }
      //
      importEvents({
        variables: { input: { projectId, file: files[0] } },
        context: {
          fetchOptions: {
            onProgress: files ? onFileUploadProgress : undefined,
            onAbortPossible() {
              logger('onAbortPossible');
            },
          },
        },
      }).catch(logger);
    },
    [importEvents, onFileUploadProgress, projectId],
  );
  const renderProgressBar = useMemo(() => {
    if (typeof fileUploadProgress === 'number' && fileUploadProgress > 0) {
      return (
        <>
          <span className="anticon">
            <RiUpload2Line />
          </span>
          <div>
            <Uploading>{t('Uploading...')}</Uploading>
            <Progress
              strokeColor="#6473C4"
              strokeWidth={4}
              trailColor="rgba(100, 115, 196, 0.3)"
              percent={fileUploadProgress}
            />
            <div className="ant-upload-desc">{t('Please wait and don’t reload the page...')}</div>
          </div>
        </>
      );
    }

    return (
      <>
        <span className="anticon">
          <RiUpload2Line />
        </span>
        <div className="ant-upload-text">{t(`Import new CSV file`)}</div>
      </>
    );
  }, [t, fileUploadProgress]);

  if (new URL(API_URL).hostname.includes('hoteza')) {
    return null;
  }

  return (
    <ButtonModalPopupComponent
      destroyContentOnRemount
      isVisible={visibleModal}
      beforeOpen={openModal}
      beforeClose={closeModal}
      buttonType="text"
      tooltip={{
        title: t('Import new CSV file'),
        align: { offset: [0, 5] },
      }}
      title={t('Import new CSV file')}
      width={480}
      zIndex={600}
      buttonIcon={<AddButtonIcon contentType="csv" />}>
      <Form layout="vertical" form={form} initialValues={{ keepRawFiles: false }} onFinish={onSubmit}>
        <Form.Item name="files">
          <FileUploader
            listType="text"
            className="upload-image upload-image_minimal"
            multiple
            accept=".csv"
            onChange={onUploaderChanged}
            onRemove={onUploaderChanged}>
            <div>{renderProgressBar}</div>
          </FileUploader>
        </Form.Item>

        <FooterModal>
          <Button type="default" htmlType="button" onClick={closeModal}>
            {t('Cancel')}
          </Button>
          <Button type="primary" htmlType="submit" loading={creatingMediaItem} disabled={submitDisabled}>
            {t('Import')}
          </Button>
        </FooterModal>
      </Form>
    </ButtonModalPopupComponent>
  );
};

export default memo(ImportEventsFromFileButton) as typeof ImportEventsFromFileButton;
