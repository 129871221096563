import React, { FC, useState, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { RiAlignLeft, RiAlignCenter, RiAlignRight, RiAlignJustify } from 'react-icons/ri';

import { TabPane, Tabs as CustomTabs } from 'src/components/ui-kit/tabs';

import { FormItem } from './template-area-settings-input';

const ALIGNMENTS = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  JUSTIFY: 'justify',
} as const;

type Alignment = typeof ALIGNMENTS[keyof typeof ALIGNMENTS];

interface HorizontalAlignmentSettingInputProps {
  onChange: (_alignment: Alignment) => void;
}

const Tabs = CustomTabs as FC<Parameters<typeof CustomTabs>[0]>;

const HorizontalAlignmentSettingInput: FC<HorizontalAlignmentSettingInputProps> = ({ onChange }) => {
  const [selectedAlignment, setSelectedAlignment] = useState<Alignment>(ALIGNMENTS.LEFT);

  const { t } = useTranslation();

  const handleChange = useCallback(
    (alignment: Alignment) => {
      setSelectedAlignment(alignment);
      onChange(alignment);
    },
    [onChange],
  );

  return (
    <FormItem label={t('Horizontal alignment')}>
      <Tabs activeKey={selectedAlignment} type="card" onChange={handleChange}>
        <TabPane key={ALIGNMENTS.LEFT} tab={<RiAlignLeft />} />
        <TabPane key={ALIGNMENTS.CENTER} tab={<RiAlignCenter />} />
        <TabPane key={ALIGNMENTS.RIGHT} tab={<RiAlignRight />} />
        <TabPane key={ALIGNMENTS.JUSTIFY} tab={<RiAlignJustify />} />
      </Tabs>
    </FormItem>
  );
};

export default memo(HorizontalAlignmentSettingInput);
