import React, { FC, memo, useMemo } from 'react';
import styled, { CSSProperties } from 'styled-components';
import pick from 'lodash/pick';

import { colorTheme } from 'src/components/ui-kit/theme';

export interface EventsWidgetProps {
  eventTime: boolean;
  nextEvent: boolean;
  logoUrl: string;
  lineStyle: string;
  separatorSize: number;
  separatorColor: CSSProperties['color'];
  eventTitleColor: CSSProperties['color'];
  eventTimeColor: CSSProperties['color'];
  eventLocationColor: CSSProperties['color'];
  style: CSSProperties;
}

type ContainerProps = Pick<EventsWidgetProps, 'separatorColor' | 'separatorSize'>;

interface EventProps {
  align: CSSProperties['textAlign'];
  eventTitleColor: CSSProperties['color'];
  eventTimeColor: CSSProperties['color'];
  eventLocationColor: CSSProperties['color'];
}

const Container = styled.ul<ContainerProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1.625rem 1fr;
  grid-auto-flow: row;
  align-items: center;

  list-style: none;
  min-height: 100%;

  & > hr {
    grid-row: 2;
    width: 80%;
    border-width: ${({ separatorSize }) => separatorSize}px;
    border-color: ${({ separatorColor }) => separatorColor};
    background-color: ${({ separatorColor }) => separatorColor};
  }
`;

const NoLogo = styled.p.attrs({ className: 'event-logo' })``;

const Event = styled.li<EventProps>`
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-template-rows: repeat(2, 5rem);
  gap: 0 1.25rem;
  align-items: center;

  & > img,
  ${NoLogo} {
    grid-column: 1;
    grid-row: 1 / span 2;
    max-height: 10rem;
    height: 10rem;
    width: 10rem;
    justify-self: end;

    padding: 3rem 1rem 0;
    text-align: center;
    line-height: 2rem;
    background-color: ${colorTheme.primary};
    border-radius: 10%;
    color: ${colorTheme.light};
    font-weight: 700;
    font-size: 2rem;
    font-family: 'Lato';
  }

  & > h2 {
    font-weight: inherit;
    color: ${({ eventTitleColor }) => eventTitleColor};
    font-size: inherit;
  }

  & > section {
    align-self: start;
    width: 100%;

    display: flex;
    align-items: center;
    color: ${({ eventTimeColor }) => eventTimeColor};
    justify-content: ${({ align }) => {
      switch (align) {
        case 'right':
          return 'flex-end';
        case 'center':
          return 'center';
        case 'justify':
          return 'space-between';
        case 'left':
        default:
          return 'flex-start';
      }
    }};

    font-size: 0.7em;

    & > h3 {
      margin-left: 0.75rem;
      color: ${({ eventLocationColor }) => eventLocationColor};
      text-align: ${({ align }) => align};
      font-size: inherit;
      font-weight: inherit;
    }

    & > .event-time {
      color: ${({ eventTimeColor }) => eventTimeColor};
      text-align: ${({ align }) => align};
      display: flex;
      padding: 0;
      margin: 0;
    }
  }

  &:nth-of-type(2) {
    & > img,
    ${NoLogo} {
      grid-column: 1;
      grid-row: 1 / span 2;
      height: 8rem;
      max-height: 8rem;
      width: 8rem;
      justify-self: end;

      padding-top: 2rem;
      text-align: center;
      line-height: 2rem;
      background-color: ${colorTheme.primary};
      border-radius: 10%;
      color: ${colorTheme.light};
      font-weight: 700;
      font-size: 2rem;
      font-family: 'Lato';
    }
  }
`;

const EventsWidget: FC<EventsWidgetProps> = ({
  eventTime: showTime,
  nextEvent: showNext,
  logoUrl,
  eventTitleColor,
  eventTimeColor,
  eventLocationColor,
  ...widgetProps
}) => {
  const eventTime = useMemo(
    () => (
      <p className="event-time">
        <time dateTime="00:00">00:00</time>
        {' - '}
        <time dateTime="23:59">23:59</time>
      </p>
    ),
    [],
  );

  const eventProps = useMemo(
    () => ({
      align: widgetProps.style?.textAlign,
      eventTitleColor,
      eventTimeColor,
      eventLocationColor,
      style: pick(widgetProps.style, ['fontSize', 'fontFamily', 'fontWeight']),
    }),
    [widgetProps.style, eventTitleColor, eventTimeColor, eventLocationColor],
  );

  const nextEvent = useMemo(
    () => (
      <Event {...eventProps}>
        {logoUrl ? <img src={logoUrl} alt="Event Logo" className="event-logo" /> : <NoLogo>Event Logo</NoLogo>}
        <h2>Sample event title 2</h2>
        <section>
          {showTime && eventTime}
          <h3>Location name</h3>
        </section>
      </Event>
    ),
    [eventProps, logoUrl, showTime, eventTime],
  );

  return (
    <Container {...widgetProps}>
      <Event {...eventProps}>
        {logoUrl ? <img src={logoUrl} alt="Event Logo" className="event-logo" /> : <NoLogo>Event Logo</NoLogo>}
        <h2>Sample event title 1</h2>
        <section>
          {showTime && eventTime}
          <h3>Location name</h3>
        </section>
      </Event>
      {showNext && (
        <>
          <hr />
          {nextEvent}
        </>
      )}
    </Container>
  );
};

export default memo(EventsWidget) as typeof EventsWidget;
