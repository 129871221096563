import React, { memo, FC } from 'react';
import styled from 'styled-components';
import { ClockProps, useClockProps, WidgetProps } from 'src/components/routes/private/template-editor/widgets/utils';

export interface CircleClockProps extends ClockProps {
  dashArray: number;
}

const ClockContainer = styled.svg<CircleClockProps>`
  background: transparent;
  margin: 0;
  padding: 0;
  width: ${({ clockSize }) => clockSize}px;
  height: ${({ clockSize }) => clockSize}px;
  display: flex;
  align-items: center;
  justify-content: center;

  .clock-shape {
    fill: transparent;
    stroke-width: calc(${({ clockSize }) => clockSize} * 0.05);
    stroke: rgba(255, 255, 255, 0.1);
    stroke-dasharray: ${({ dashArray }) => dashArray};
    stroke-dashoffset: 0;
    stroke-linecap: round;
    transition: 1s;
  }

  .main-circle {
    stroke: ${({ mainColor }) => mainColor};
    stroke-dashoffset: calc(
      ${({ dashArray }) => dashArray} - (${({ dashArray }) => dashArray} * (${({ loadingSize }) => loadingSize} / 60))
    );
  }
`;

const Content = styled.div<ClockProps & { fontSize: number }>`
  font-family: 'DS-DIGI', sans-serif;
  color: ${({ mainColor }) => mainColor};
  position: absolute;
  top: ${({ clockSize }) => `calc((100% - ${clockSize}px) / 2)`};
  left: ${({ clockSize }) => `calc((100% - ${clockSize}px) / 2)`};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  width: ${({ clockSize }) => clockSize}px;
  height: ${({ clockSize }) => clockSize}px;
  display: flex;
  align-items: center;
  justify-content: center;

  .sec {
    animation: secAnimation 1s infinite;
  }

  @keyframes secAnimation {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const CircleClock: FC<WidgetProps> = ({ item, style }) => {
  const { clockSize, loadingSize, color, date } = useClockProps(item, style);

  return (
    <>
      <ClockContainer clockSize={clockSize} loadingSize={loadingSize} dashArray={876} mainColor={color}>
        <circle className="clock-shape" cx={clockSize / 2} cy={clockSize / 2} r={clockSize / 2 - clockSize * 0.05} />
        <circle
          className="clock-shape main-circle"
          cx={clockSize / 2}
          cy={clockSize / 2}
          r={clockSize / 2 - clockSize * 0.05}
        />
      </ClockContainer>
      <Content mainColor={color} clockSize={clockSize} fontSize={clockSize / 5}>
        <span className="time hour">{date.format('HH')}</span>
        <b className="sec">:</b>
        <span className="time min">{date.format('mm')}</span>
      </Content>
    </>
  );
};

CircleClock.displayName = 'CircleClock';

export default memo(CircleClock);
