/* eslint-disable no-confusing-arrow */
// React
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { pure, compose, withProps, lifecycle } from 'recompose';

//
const ModalPortalComponent = ({ children, el }) => el && createPortal(children, el);

ModalPortalComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  el: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};
ModalPortalComponent.defaultProps = {
  el: null,
};

export default compose(
  withProps({
    // el: !SERVER ? document.createElement('div') : null,
    el: document.createElement('div'),
  }),
  lifecycle({
    componentDidMount() {
      //
      const modalRoot = document.getElementById('root');
      modalRoot.appendChild(this.props.el);
    },
    componentWillUnmount() {
      //
      const modalRoot = document.getElementById('root');
      modalRoot.appendChild(this.props.el);
    },
  }),
  pure,
)(ModalPortalComponent);
