import React, { FC, memo, createRef, RefObject, createContext, useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@fjedi/graphql-react-components';
import {
  ScreenRatio,
  ScreenOrientation,
  useGetTemplateQuery,
  useGetPlaylistQuery,
  useGetScreenRatiosQuery,
} from 'src/graphql/generated';
import { Playlist } from 'src/player/playlist';
import { Template } from 'src/player/template';
import ModalPopup from 'src/components/ui-kit/modal-popup';

const PlayerContainer = styled.div`
  margin: 0 auto;
  overflow: hidden;

  //width: 64rem;
  //height: 36rem;
`;

const Player = styled.div<{ loading?: boolean; size: any; previewScale: number }>`
  width: ${({ size }) => (size ? `${size.baseWidth}px` : '64rem')};
  height: ${({ size }) => (size ? `${size.baseHeight}px` : '36rem')};

  transform: scale(${({ previewScale }) => `${previewScale}`}) translateX(-50%);
  transform-origin: left top;
  position: absolute;
  left: 50%;

  overflow: hidden;

  background: ${({ loading }) => (loading ? '#ffffff' : 'rgba(0, 0, 0, 1)')};
`;

export interface PlayerData {
  typeName: string;
  id: string | null;
  screenRatio: string;
}

export interface MediaPlayerContextProps {
  data: PlayerData & {
    playerRef: RefObject<HTMLDivElement> | null;
    isModalVisible: boolean;
  };
  play(_data: PlayerData): void;
}

export const defaultScreenRatio = {
  id: '16:9',
  label: '16:9 HD Landscape',
  value: 56.25,
  baseWidth: 1920,
  baseHeight: 1080,
} as ScreenRatio;

const defaultData: PlayerData = { typeName: 'MediaItem', screenRatio: defaultScreenRatio.id, id: null };

export const MediaPlayerContext = createContext<MediaPlayerContextProps>({
  data: {
    isModalVisible: false,
    playerRef: null,
    ...defaultData,
  },
  play() {},
});

export const MediaPlayer: FC<{ children?: React.ReactNode }> = props => {
  const { children } = props;
  const { t } = useTranslation();
  const apolloClient = useApolloClient();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState<PlayerData>(defaultData);
  const { screenRatio } = data;

  const playerRef = createRef<HTMLDivElement>();

  const { data: screenRatiosRes } = useGetScreenRatiosQuery({ variables: { filter: {} } });
  const ratios: ScreenRatio[] = useMemo(
    () => screenRatiosRes?.getScreenRatios ?? [],
    [screenRatiosRes?.getScreenRatios],
  );

  const size = useMemo<ScreenRatio | null>(
    () => ratios?.find(({ id }) => id === screenRatio) ?? null,
    [ratios, screenRatio],
  );
  const modalSize = useMemo<{ width: string; height: string; scale: number }>(() => {
    const width = 72;
    const height = 45;
    const modalPadding = '5rem';
    if (!size) {
      return { width: `${width}rem`, height: `${height}rem`, scale: 1 };
    }
    const { baseWidth, baseHeight } = size;
    const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    if (size.orientation === ScreenOrientation.Landscape) {
      const s = baseWidth / baseHeight;
      const onScreenWidth = (width - 4) * fontSize;
      return {
        width: `${width}rem`,
        height: `calc(${width / s}rem + ${modalPadding})`,
        scale: onScreenWidth / baseWidth,
      };
    }
    const s = baseHeight / baseWidth;
    const onScreenHeight = (height - 4) * fontSize;
    return {
      width: `calc(${height / s}rem + ${modalPadding})`,
      height: `calc(${height}rem + ${modalPadding})`,
      scale: onScreenHeight / baseHeight,
    };
  }, [size]);

  const updatedContext = useMemo(
    () => ({
      data: { ...data, isModalVisible, playerRef },
      play: setData,
    }),
    [data, isModalVisible, playerRef],
  );

  const playlist = useMemo(() => {
    if (data.typeName === 'Playlist') return new Playlist({ apolloClient });
    return false;
  }, [data.typeName, apolloClient]);

  const template = useMemo(() => {
    if (data.typeName === 'Template') return new Template({ ratio: data.screenRatio, apolloClient });
    return false;
  }, [data.screenRatio, data.typeName, apolloClient]);

  //* Template preview
  const { data: templateRes } = useGetTemplateQuery({
    variables: { id: data.id! },
    skip: !template,
  });
  const templateData = useMemo(() => templateRes?.getTemplate, [templateRes]);

  //* Playlist preview
  const { data: playlistRes } = useGetPlaylistQuery({
    variables: { id: data.id! },
    skip: !playlist,
  });
  const playlistData = useMemo(() => playlistRes?.getPlaylist, [playlistRes]);

  useEffect(() => {
    if (template && templateData && data.id) {
      setIsModalVisible(true);
      template.parse(templateData);
      template.play({ rootElement: playerRef.current, ratio: data.screenRatio });
    }

    if (playlist && playlistData && data.id) {
      setIsModalVisible(true);
      playlist.parse(playlistData.items);
      playlist.play({ rootElement: playerRef.current, ratio: data.screenRatio });
    }
  }, [data.id, data.screenRatio, playerRef.current, playlist, playlistData, template, templateData]);

  const closeModal = useCallback(() => {
    if (playlist) {
      playlist.stop();
    }

    if (template) {
      template.stop();
    }

    setData(defaultData);
    setIsModalVisible(false);
  }, [playlist, template]);

  return (
    <MediaPlayerContext.Provider value={updatedContext}>
      {children}
      <ModalPopup
        isVisible={isModalVisible}
        onCancel={closeModal}
        title={t('Preview')}
        width={modalSize.width}
        height={modalSize.height}>
        <PlayerContainer>
          <Player ref={playerRef} size={size} previewScale={modalSize.scale} />
        </PlayerContainer>
      </ModalPopup>
    </MediaPlayerContext.Provider>
  );
};

export default memo(MediaPlayer) as typeof MediaPlayer;
