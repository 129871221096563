// import { theme as defaultTheme } from 'package.json';
// React
import React, { useState, useEffect, StrictMode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { fallbackLang } from 'kit/lib/intl';
import { useCookies } from 'react-cookie';
import { time, setGlobalTimezone } from 'src/helpers/time';
import logger from 'src/helpers/logger';
//
import { useViewerQuery } from 'src/graphql/generated';
//
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'src/components/ui-kit/global-styles';
import { theme } from 'config/theme';

// Routing via React Router
import {
  Routes,
  Route,
  Navigate,
  ViewerContext,
  ViewerRoleProvider,
  AuthModalProvider,
} from '@fjedi/react-router-helpers';
import Spinner from 'src/components/ui-kit/spinner';
//
import { ConfigProvider } from 'antd';
import ru from 'antd/lib/locale/ru_RU';
import ua from 'antd/lib/locale/uk_UA';
import en from 'antd/lib/locale/en_US';

// Routes
import ConfirmActionComponent from 'src/components/routes/public/confirm-action';
import PrivateRoot from 'src/components/routes/private';
import Login from 'src/components/routes/auth/login';
import ForgotPassword from 'src/components/routes/auth/forgot-password';
import ResetPassword from 'src/components/routes/auth/reset-password';
import Hotjar from '@hotjar/browser';

const locales = { ru, ua, en };

const Root = () => {
  const { i18n } = useTranslation();
  const defaultLocale = (i18n.language || fallbackLang) as keyof typeof locales;
  const [lang, setLang] = useState<keyof typeof locales>(defaultLocale);
  const [timezone, setTimezone] = useState('');
  const { data, loading, called } = useViewerQuery({
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    i18n.changeLanguage('en').catch(logger);
    i18n.on('languageChanged', (l: keyof typeof locales) => {
      if (l.length > 2) {
        //
        i18n.changeLanguage(l.substring(0, 2)).catch(logger);
      } else {
        setLang(l);
      }
    });
    return () => {
      i18n.off('languageChanged');
    };
  }, [i18n]);
  //
  const viewer = data?.viewer;
  const viewerId = viewer?.id;

  // Manage project-based timezone
  const projectTimezone = viewer?.primaryProject?.timezone;
  const [, setCookie] = useCookies(['timezone-offset']);
  useEffect(() => {
    if (!called || !projectTimezone) {
      return;
    }
    setGlobalTimezone(projectTimezone);
    setCookie('timezone-offset', -time().tz(projectTimezone).utcOffset(), { path: '/' });
    setTimezone(projectTimezone);
  }, [called, projectTimezone, setCookie, setTimezone]);

  useEffect(() => {
    if (!viewerId || !Hotjar.isReady()) {
      return;
    }

    const { email, fullName, primaryProject } = viewer;

    Hotjar.identify(viewerId, {
      email,
      name: fullName ?? 'Unknown',
      project: primaryProject?.name ?? 'Unknown',
    });
  }, [viewerId, viewer]);

  //
  const ForgotPasswordPage = useMemo(() => (viewerId ? <Navigate replace to="/" /> : <ForgotPassword />), [viewerId]);
  const ResetPasswordPage = useMemo(() => (viewerId ? <Navigate replace to="/" /> : <ResetPassword />), [viewerId]);
  //
  if ((!viewerId && loading) || (viewer?.primaryProject && !timezone)) {
    return (
      <Spinner
        style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        spinning
      />
    );
  }

  return (
    <ConfigProvider theme={theme} locale={locales[lang as keyof typeof locales]}>
      <ThemeProvider theme={theme}>
        <ViewerContext.Provider value={viewer}>
          <ViewerRoleProvider value={viewer?.role}>
            <AuthModalProvider>
              {/* <StrictMode> */}
              <>
                <Routes>
                  <Route path="forgot-password" element={ForgotPasswordPage} />
                  <Route path="reset-password/:token" element={ResetPasswordPage} />
                  <Route path="accept-invitation-to-company" element={<ConfirmActionComponent />} />
                  {/* {!!viewerId && <Redirect exact strict from="login" to="/" />} */}
                  <Route path="*" element={viewerId ? <PrivateRoot /> : <Login />} />
                </Routes>
                <GlobalStyles />
              </>
              {/* </StrictMode> */}
            </AuthModalProvider>
          </ViewerRoleProvider>
        </ViewerContext.Provider>
      </ThemeProvider>
    </ConfigProvider>
  );
};

Root.propTypes = {};
Root.defaultProps = {};

export default Root;
