import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiNavigation2 } from 'react-icons/fi';
import styled from 'styled-components';

import Button from 'src/components/ui-kit/buttons';
import { colorTheme } from 'src/components/ui-kit/theme';

const Title = styled.span`
  grid-row: 1;
  grid-column: 1;

  color: ${colorTheme.dark};
`;

const SubTitle = styled.span`
  grid-row: 2;
  grid-column: 1;

  font-size: 0.75rem;
  color: ${colorTheme.secondary};
`;

export const WayfindingIcon = styled.i`
  grid-column: 2;
  grid-row: 1 / span 2;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  font-size: 1.25rem;

  background-color: ${colorTheme.primary};
  color: hsl(255, 100%, 100%);
`;

const LinkButton = styled(Button)`
  &.ant-btn.ant-btn-primary {
    display: grid;
    grid-template: repeat(2, 50%) / 4.5fr 1fr;

    box-sizing: content-box;
    padding: 0.625rem 0.9375rem;

    background: hsl(255, 100%, 100%);
    border-radius: 0.625rem;

    text-align: left;

    &:hover {
      opacity: 1;

      ${Title} {
        color: ${colorTheme.light};
      }

      ${SubTitle} {
        color: ${colorTheme.light};
        opacity: 0.5;
      }
    }
  }
`;

export const WayfindingLink: FC = () => {
  const { t } = useTranslation();

  return (
    <LinkButton href="/events/wayfinding">
      <Title>{t('Wayfinding')}</Title>
      <SubTitle>{t('Add wayfinding icons')}</SubTitle>
      <WayfindingIcon>
        <FiNavigation2 />
      </WayfindingIcon>
    </LinkButton>
  );
};

export default memo(WayfindingLink);
