/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';
import { Switch as AntSwitch } from 'antd';
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';

export const Switch = styled(AntSwitch)`
  &.ant-switch {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    height: 17px;
    font-size: 1rem;
    min-width: 1.75rem;
    background-color: #ffffff;
    border: 1px solid ${colorGrays.gray300};

    .ant-switch-handle {
      width: 13px;
      height: 13px;
      top: 1px;

      &:before {
        background-color: ${colorGrays.gray300};
        box-shadow: none;
      }

      .anticon-loading {
        font-size: 12px;
      }
    }
    .ant-switch-inner {
      /* font-size: 0.85rem; */
      /* margin-left: 0.625rem; */
      /* line-height: 1rem; */
      /* margin-right: 1.25rem; */
    }

    &:not(.ant-switch-checked) .ant-switch-inner {
      /* margin-right: 0.625rem; */
      /* margin-left: 1.25rem; */
      /* color: ${colorGrays.gray300}; */
    }

    &.ant-switch-checked {
      background-color: ${colorTheme.primary};
      border-color: ${colorTheme.primary};

      .ant-switch-handle {
        left: calc(100% - 13px - 1px);

        &:before {
          background-color: #fff;
        }
      }
    }

    &.ant-switch-disabled {
      background-color: ${colorGrays.gray300};
      border-color: ${colorGrays.gray500};

      .ant-switch-handle:before {
        background-color: ${colorGrays.gray600};
      }
    }
  }
`;
