import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import styled from 'styled-components';
//
import { Avatar as AntAvatar, Badge } from 'antd';

const StyledAvatar = styled(AntAvatar)`
  transition: opacity 0.4s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  &:hover {
    opacity: 0.9;
  }
  &.ant-avatar-square {
    border-radius: 0.25rem;
  }
  &.ant-avatar-sm {
    width: 1.875rem;
    height: 1.875rem;
    &.ant-avatar-icon {
      font-size: 1.125rem;
    }
  }
`;

const Avatar = ({ size, isOnline, className, src, icon, shape, ...props }) => (
  <Badge className={className} dot={isOnline} {...props}>
    <StyledAvatar src={src || undefined} size={size} icon={icon} shape={shape} />
  </Badge>
);

Avatar.propTypes = {
  className: PropTypes.string,
  isOnline: PropTypes.bool,
  size: PropTypes.string,
  src: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  shape: PropTypes.oneOf(['square', 'circle']),
};
Avatar.defaultProps = {
  className: '',
  isOnline: false,
  src: undefined,
  size: 'small',
  icon: 'user',
  shape: 'circle',
};

export default compose(pure)(Avatar);
