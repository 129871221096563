import PropTypes from 'prop-types';

export const IDType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const rolePropType = PropTypes.oneOf([
  'USER',
  'ADMIN',
  'MODERATOR',
  'ANONYMOUS',
  'COMPANY_USER',
  'NON_COMPANY_USER',
]);

export const i18nType = PropTypes.shape({
  changeLanguage: PropTypes.func.isRequired,
  language: PropTypes.string,
});

export const apolloDataType = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  loading: PropTypes.bool.isRequired,
});

export const formType = PropTypes.shape({
  getFieldDecorator: PropTypes.func.isRequired,
  getFieldValue: PropTypes.func.isRequired,
});

export const modalType = PropTypes.shape({
  id: IDType,
});

export const routerMatchType = PropTypes.shape({
  params: PropTypes.shape({}).isRequired,
  url: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
});

export const listType = PropTypes.shape({
  rows: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
});

export const userProfileType = PropTypes.shape({
  id: IDType.isRequired,
});

export const UserLocationType = PropTypes.shape({
  countryCode: IDType,
});

export const CompanyType = PropTypes.shape({
  id: IDType,
});

export const ProjectType = PropTypes.shape({
  id: IDType,
});

export const DeviceType = PropTypes.shape({
  id: IDType,
  isOnline: PropTypes.bool,
});

export const MediaItemType = PropTypes.shape({
  id: IDType,
});

export const TemplateType = PropTypes.shape({
  id: IDType,
});

export const PlaylistType = PropTypes.shape({
  id: IDType,
});

export const ViewerType = PropTypes.shape({
  id: IDType.isRequired,
  primaryCompany: CompanyType.isRequired,
  primaryProject: ProjectType,
});

export const FileType = PropTypes.shape({
  uid: IDType.isRequired,
});
export const TableFilterType = PropTypes.object;
