import React, { memo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { RiAddLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useMutation, updateAfterMutation, useApolloError, logger } from '@fjedi/graphql-react-components';
import createDeviceMutation from 'src/graphql/mutations/create-device.graphql';
import ButtonModalPopupComponent from 'src/components/ui-kit/modal-popup/button-modal';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';

const Add = styled(ButtonModalPopupComponent)``;

const FooterModal = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.875rem;

  .ant-btn {
    width: 8.75rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
`;

export const ThumbnailAdd = ({ scheduleId }) => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const [form] = Form.useForm();
  const { resetFields } = form;
  //
  const [isCreateDeviceModalVisible, setCreateDeviceModalVisibility] = useState(false);
  const openCreateDeviceModal = useCallback(() => setCreateDeviceModalVisibility(true), []);
  const closeCreateDeviceModal = useCallback(() => {
    resetFields();
    setCreateDeviceModalVisibility(false);
  }, [resetFields]);
  //
  const [createDevice, { loading }] = useMutation(createDeviceMutation, {
    update: updateAfterMutation('Device', 'getDevices'),
    onCompleted: closeCreateDeviceModal,
    onError,
  });
  //
  const onDeviceSubmit = useCallback(
    ({ code, ...input }) => {
      createDevice({
        variables: {
          code,
          input: { ...input, scheduleId },
        },
      }).catch(logger);
    },
    [createDevice, scheduleId],
  );

  return (
    <Add
      isVisible={isCreateDeviceModalVisible}
      beforeOpen={openCreateDeviceModal}
      beforeClose={closeCreateDeviceModal}
      title={t('Add new device')}
      buttonType="link"
      buttonSize="small"
      buttonIcon={<RiAddLine />}
      tooltip={{ title: t('Add new device') }}>
      <Form layout="vertical" form={form} onFinish={onDeviceSubmit}>
        <FormItem
          label={t('Registration code')}
          name="code"
          rules={[
            {
              required: true,
              message: t('Please fill this field'),
            },
          ]}>
          <Input placeholder={t('Enter registration code')} />
        </FormItem>
        <FormItem
          label={t('Device Name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('Please fill this field'),
            },
          ]}>
          <Input placeholder={t('Enter device name')} />
        </FormItem>

        <FooterModal>
          <Button type="default" htmlType="button" onClick={closeCreateDeviceModal}>
            {t('Cancel')}
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('Add')}
          </Button>
        </FooterModal>
      </Form>
    </Add>
  );
};

ThumbnailAdd.propTypes = {
  scheduleId: PropTypes.string.isRequired,
};

export default memo(ThumbnailAdd);
