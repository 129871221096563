/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */
import { delay as delayPromise } from 'bluebird';
import React, { FC, memo, useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import styled from 'styled-components';
import { useParams } from '@fjedi/react-router-helpers';
import { useTranslation } from 'react-i18next';
import { updateAfterMutation, useApolloError, useMutation } from '@fjedi/graphql-react-components';
import { Col, Modal, Tooltip } from 'antd';
import { RiAddLine, RiDeleteBinLine } from 'react-icons/ri';
import {
  CopyPlaylistMutationVariables,
  CopyTemplateMutationVariables,
  MediaFolder,
  MediaFolderType,
  MediaFolderItem,
  MediaItem,
  Template,
  useCopyPlaylistMutation,
  useCopyTemplateMutation,
  useRemoveMediaFolderMutation,
  useUpdateMediaFolderMutation,
} from 'src/graphql/generated';
import type { MediaFolderProfile } from 'src/components/ui-kit/media-item/media-item';
import updateMediaItemMutation from 'src/graphql/mutations/update-media-item.graphql';
import updatePlaylistMutation from 'src/graphql/mutations/update-playlist.graphql';
import updateTemplateMutation from 'src/graphql/mutations/update-template.graphql';
import { ContentCard, TabExtraActionsContainer } from 'src/components/ui-kit/admin-layout/content-card';
import RightSider from 'src/components/ui-kit/admin-layout/right-sider';
import AsideMediaInfo from 'src/components/ui-kit/aside/aside-media-info';
import AddMediaItemButton from 'src/components/ui-kit/media-item/add-media-item';
import MediaItemList from 'src/components/ui-kit/media-item/media-item-list';
import { Footer as CreateMediaFolderButton } from 'src/components/ui-kit/aside/tabs/folders';
import Button from 'src/components/ui-kit/buttons';
import { DragDropContext, DraggableItem, DroppableArea, DropResult } from 'src/components/ui-kit/drag-n-drop';
import NoAccess from 'src/components/ui-kit/no-access';
import Popconfirm from 'src/components/ui-kit/popconfirm';
import { FixedScrollbar } from 'src/components/ui-kit/scrollbar';
import { colorTheme } from 'src/components/ui-kit/theme';
import logger from 'src/helpers/logger';
import ThumbnailList from 'src/components/ui-kit/thumbnail/list-thumbnail';
import MediaThumbnail, { TypeContainer } from 'src/components/ui-kit/thumbnail/media-thumbnail';
import { stopEventPropagation } from 'src/functions';
import Spinner from 'src/components/ui-kit/spinner';
import { useGetFoldersWithScrollPagination } from './hooks';
import MediaPlayerContext from './media-player';
import PasteButton from './paste-button';

const tabList = [
  {
    key: 'image',
    tab: 'Images',
  },
  {
    key: 'video',
    tab: 'Videos',
  },
  {
    key: 'template',
    tab: 'Templates',
  },
  {
    key: 'playlist',
    tab: 'Playlists',
  },
];
const DEFAULT_TAB_KEY = tabList[0].key;
// const Search = styled(Input)`
//   &.ant-input-affix-wrapper {
//     height: 2.25rem;
//     min-height: 2.25rem;
//     width: auto;
//     display: flex;
//     margin: 0 1.875rem 1.25rem;
//   }
// `;

const Content = styled(ContentCard)`
  .ant-tabs-extra-content .ant-btn {
    color: ${colorTheme.dark};
  }

  .ant-space > .ant-space-item > .ant-btn {
    color: ${colorTheme.dark};

    &:hover {
      background: ${colorTheme.primary};
      color: hsl(0, 100%, 100%);
    }

    svg {
      font-size: 1.25rem;
    }
  }
`;

const NoAccessContainer = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 50px;
`;

const MediaThumbnailContainer = styled.div<{ isSelected?: boolean }>`
  .thumbnail-card {
    background-color: #fff;
    box-shadow: ${props => (props.isSelected ? `0 0 0.25rem 0.25rem ${colorTheme.primary}` : 'unset')};
  }

  ${TypeContainer} {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const MediaLibraryPage: FC = () => {
  const routeParams = useParams<{ contentType: string }>();
  const contentType = (routeParams.contentType || DEFAULT_TAB_KEY).toUpperCase() as MediaFolderType;
  const { t } = useTranslation();
  const onError = useApolloError();
  //
  const [selectedFolder, setSelectedFolder] = useState<MediaFolderProfile | null>(null);
  const [selectedItem, setSelectedItem] = useState<MediaFolderItem | null>(null);

  const [filterValue, setFilterValue] = useState<string>('');
  const onFilterChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  }, []);
  //
  useEffect(() => {
    setSelectedFolder(null);
    setSelectedItem(null);
    setFilterValue('');
  }, [contentType]);
  //
  const {
    folders,
    error,
    loading,
    nextPageLoader,
    onScrollFrame,
    update: updateFoldersCache,
  } = useGetFoldersWithScrollPagination();
  //
  let dataType: 'MediaItem' | 'Playlist' | 'Template';
  let mutationQuery;
  switch (contentType) {
    case 'TEMPLATE':
      dataType = 'Template';
      mutationQuery = updateTemplateMutation;
      break;
    case 'PLAYLIST':
      dataType = 'Playlist';
      mutationQuery = updatePlaylistMutation;
      break;
    default:
      dataType = 'MediaItem';
      mutationQuery = updateMediaItemMutation;
  }
  const [updateItem] = useMutation(mutationQuery, {
    onError,
    update: updateFoldersCache,
  });
  //
  const onFolderSelect = useCallback((folder: MediaFolderProfile) => setSelectedFolder(folder), []);
  const onItemSelect = useCallback((item: MediaFolderItem | null) => setSelectedItem(item), []);
  //
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result?.destination) {
        return;
      }
      const id = result.draggableId;
      const [folderId] = result.destination.droppableId.split('.');
      //
      updateItem({
        variables: { id, input: { folderId } },
        optimisticResponse: {
          __typename: 'Mutation',
          [`update${dataType}`]: {
            id,
            __typename: dataType,
            folderId,
          },
        },
      }).catch(logger);
    },
    [dataType, updateItem],
  );

  //
  const [removeMediaFolder] = useRemoveMediaFolderMutation({
    onError,
    update: updateAfterMutation('MediaFolder', 'getMediaFolders'),
  });
  const [updateMediaFolder] = useUpdateMediaFolderMutation({
    onError,
  });
  const onRemove = useCallback(
    (folder: MediaFolderProfile) => (event?: React.MouseEvent) => {
      event?.stopPropagation();
      Modal.confirm({
        title: t('Warning'),
        content: t('Are you sure you want to remove that folder? This action is irreversible'),
        onOk() {
          removeMediaFolder({
            variables: { id: folder.id },
          }).catch(logger);
        },
        okText: t('Proceed'),
      });
    },
    [t, removeMediaFolder],
  );
  const onTitleChange = useCallback(
    (folder: MediaFolderProfile) => (name: string) => {
      updateMediaFolder({
        variables: {
          id: folder.id,
          input: {
            name,
          },
        },
        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   updateMediaFolder: {
        //     id: folder.id,
        //     type: folder.type,
        //     items: folder.items,
        //     __typename: 'MediaFolder',
        //     name,
        //   },
        // },
      }).catch(logger);
    },
    [updateMediaFolder],
  );

  const [hasClipboardData, setHasClipboardData] = useState(false);
  const [pasteButtonLoaderId, togglePasteButtonLoader] = useState<MediaFolder['id'] | false>(false);

  const [copyPlaylist] = useCopyPlaylistMutation({
    onError,
    update: updateAfterMutation('Playlist', 'getPlaylists'),
  });

  const [copyTemplate] = useCopyTemplateMutation({
    onError,
    update: updateAfterMutation('Template', 'getTemplates'),
  });

  const onPasteData = useCallback(
    (folder: MediaFolderProfile) => {
      try {
        togglePasteButtonLoader(folder.id);

        navigator?.clipboard
          ?.readText()
          // eslint-disable-next-line consistent-return
          .then(text => {
            if (!text) {
              logger('MediaLibrary.onPasteData', { folder, error: 'NO_CLIPBOARD_DATA_FOUND' });
              togglePasteButtonLoader(false);
              return;
            }
            const data = JSON.parse(text);

            const isPlaylist = data.__typename === 'Playlist' && folder.type === 'PLAYLIST';
            const isTemplate = data.__typename === 'Template' && folder.type === 'TEMPLATE';

            if (!isPlaylist && !isTemplate) {
              togglePasteButtonLoader(false);
              return;
            }
            const mutation = isPlaylist ? copyPlaylist : copyTemplate;
            const variables: CopyTemplateMutationVariables | CopyPlaylistMutationVariables = {
              input: {
                id: data.id,
                folderId: folder.id,
                title: data.title,
                projectId: data.projectId,
              },
            };

            folder.items?.forEach(row => {
              if (data.id === row.id) data.title = `${data.title}-copy`;
            });

            delayPromise(500)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .then(() => mutation({ variables }))
              .catch(logger)
              .finally(() => togglePasteButtonLoader(false));
          })
          .catch(() => togglePasteButtonLoader(false));
      } catch (err) {
        logger(err as Error);
        togglePasteButtonLoader(false);
      }
    },
    [copyPlaylist, copyTemplate, togglePasteButtonLoader],
  );

  const isUserAgentFirefox = useMemo(() => navigator.userAgent.includes('Firefox'), []);

  const renderActions = useCallback(
    // eslint-disable-next-line react/no-unstable-nested-components
    (folder: MediaFolderProfile) => () => (
      <Fragment>
        {['PLAYLIST', 'TEMPLATE'].includes(folder.type) && !isUserAgentFirefox && (
          <PasteButton
            loading={pasteButtonLoaderId === folder.id}
            folder={folder}
            hasClipboardData={hasClipboardData}
            onPaste={onPasteData}
          />
        )}
        {!folder.isDefault && (
          <Popconfirm
            placement="topRight"
            title={`${t('Remove')}?`}
            onClick={stopEventPropagation}
            onConfirm={onRemove(folder)}
            okText={t('Yes')}
            cancelText={t('No')}>
            <Tooltip
              title={t('Remove')}
              placement={folder.type === 'PLAYLIST' || folder.type === 'TEMPLATE' ? 'bottom' : 'left'}>
              <Button type="link" size="small" icon={<RiDeleteBinLine />} />
            </Tooltip>
          </Popconfirm>
        )}
        <AddMediaItemButton buttonType="link" contentType={contentType} buttonIcon={<RiAddLine />} folder={folder} />
      </Fragment>
    ),
    [isUserAgentFirefox, hasClipboardData, onPasteData, t, onRemove, contentType, pasteButtonLoaderId],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isUserAgentFirefox) {
      logger('Firefox does not support Navigator.Clipboard manipulations!');
      setHasClipboardData(false);
      return;
    }

    try {
      navigator?.clipboard?.readText().then(
        text => {
          if (!text) {
            setHasClipboardData(false);
            return;
          }
          try {
            const clipData = JSON.parse(text);
            const { __typename: type } = clipData;

            if (!['Template', 'Playlist'].includes(type)) {
              setHasClipboardData(false);
              return;
            }
            setHasClipboardData(true);
          } catch (e) {
            setHasClipboardData(false);
          }
        },
        () => setHasClipboardData(false),
      );
    } catch (err) {
      logger(err as Error);
      setHasClipboardData(false);
    }
  });

  const onItemClick = useCallback(
    (item: MediaFolderItem, folder: MediaFolderProfile, isSelected: boolean) => () => {
      logger('Components.MediaLibrary.onItemClick', {
        item,
        folder,
      });

      onFolderSelect(folder);
      onItemSelect(isSelected ? null : item);
    },
    [onFolderSelect, onItemSelect],
  );

  const handleItemCopy = useCallback(
    (success?: boolean) => setHasClipboardData(isUserAgentFirefox ? false : !!success),
    [isUserAgentFirefox],
  );

  const renderItem = useCallback(
    // eslint-disable-next-line react/no-unstable-nested-components
    (folder: MediaFolderProfile) => (item: MediaFolderItem, itemIndex: number) => {
      const { id } = item;
      const isStub = !(item as MediaItem).url && (item as Template).preview === undefined && dataType !== 'Playlist';
      const showCopy = ['Template', 'Playlist'].includes(dataType) && !isUserAgentFirefox;
      const isSelected = id === selectedItem?.id;

      return (
        <Col span={6} key={id}>
          <DraggableItem draggableId={id} index={itemIndex}>
            <MediaThumbnailContainer isSelected={isSelected}>
              <MediaThumbnail
                data={item}
                onClick={onItemClick(item, folder, isSelected)}
                showPreview
                showCopy={showCopy}
                onCopy={handleItemCopy}
                stub={isStub}
                isEditable
              />
            </MediaThumbnailContainer>
          </DraggableItem>
        </Col>
      );
    },
    [dataType, isUserAgentFirefox, selectedItem?.id, onItemClick, handleItemCopy],
  );
  //
  const tabBarExtraContent = useMemo(
    () => (
      <TabExtraActionsContainer>
        <CreateMediaFolderButton
          contentType={contentType}
          buttonType="link"
          buttonIcon={<RiAddLine />}
          buttonText={null}
          buttonSize="small"
          tooltip={{ title: t('Create folder') }}
        />
      </TabExtraActionsContainer>
    ),
    [t, contentType],
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const accessIsDenied = !loading && error?.graphQLErrors[0]?.data?.exception?.status === 403;
  //
  return (
    <>
      {accessIsDenied && (
        <NoAccessContainer>
          <NoAccess />
        </NoAccessContainer>
      )}
      {!accessIsDenied && (
        <Content tabList={tabList} tabURLNavigation tabBarExtraContent={tabBarExtraContent}>
          <DragDropContext onDragEnd={onDragEnd}>
            <RightSider>
              <AsideMediaInfo
                selectedItem={selectedItem}
                folder={selectedFolder}
                onFilterChange={onFilterChange}
                filterValue={filterValue}
              />
            </RightSider>
            <MediaPlayerContext>
              <FixedScrollbar scrollbarProps={{ onScrollFrame }}>
                <Spinner spinning={loading} style={{ minHeight: '500px' }}>
                  {folders.map(folder => {
                    const { id, name, items } = folder;
                    return (
                      <DroppableArea
                        key={id}
                        droppableId={`${folder.id}.mediaItemList`}
                        direction="horizontal"
                        isDropDisabled={false}>
                        <ThumbnailList
                          title={name}
                          onTitleChange={onTitleChange(folder)}
                          renderActions={renderActions(folder)}>
                          <MediaItemList
                            contentType={contentType}
                            renderItem={renderItem(folder)}
                            items={items}
                            // onDragStart={onDragStart}
                            // onDragEnd={onDragEnd}
                            // onClick={props.onItemClick}
                            // filterValue={filterValue}
                            folder={folder}
                            isEditable
                          />
                        </ThumbnailList>
                      </DroppableArea>
                    );
                  })}
                </Spinner>
                {nextPageLoader}
              </FixedScrollbar>
            </MediaPlayerContext>
          </DragDropContext>
        </Content>
      )}
    </>
  );
};

MediaLibraryPage.displayName = 'MediaLibraryPage';

export default memo(MediaLibraryPage);
