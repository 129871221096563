import React, {
  FC,
  memo,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from 'kit/lib/hooks';
import useComponentSize from '@rehooks/component-size';
import html2canvas from 'html2canvas';
import { RiSettings5Line, RiCheckLine } from 'react-icons/ri';
import pick from 'lodash/pick';
import merge from 'lodash/merge';
import sortBy from 'lodash/sortBy';
import { useParams, usePrompt } from '@fjedi/react-router-helpers';
import { logger, useSubscription, useApolloError } from '@fjedi/graphql-react-components';
import { compareIds, formatPropForReact } from 'src/functions';
import time from 'src/helpers/time';
// GraphQL Queries
import {
  ScreenRatio,
  UpdateTemplateInput,
  useGetScreenRatiosQuery,
  useGetTemplateQuery,
  useUpdateTemplateMutation,
  TemplateArea as TemplateAreaType,
  Template,
} from 'src/graphql/generated';
import templateChangedSubscription from 'src/graphql/subscriptions/template-changed.graphql';
import { ContentCard } from 'src/components/ui-kit/admin-layout/content-card';
import RightSider from 'src/components/ui-kit/admin-layout/right-sider';
import { BreadcrumbContext } from 'src/components/ui-kit/breadcrumb';
import ScrollbarCustom from 'src/components/ui-kit/scrollbar';
import Zoom from 'src/components/ui-kit/zoom';
import { TemplateContext, EditorContext, EditorSizeContext, EditorContextState } from './context';
import TemplateAreaList from './template-area-list';
import TemplateArea from './template-area';
import { DEFAULT_RATIO } from './constants';

const Content = styled(ContentCard)`
  max-width: 914px;
  min-width: 100%;
  overflow-x: hidden;
  &.ant-card {
    /* flex-grow: 0; */
    .ant-card-head-title {
      flex-direction: row-reverse;
    }

    .ant-card-extra > .ant-btn:nth-of-type(2) {
      margin-right: 0.75rem !important;
    }

    .ant-card-body {
      overflow: hidden;
    }
  }
`;

const EditorContainer = styled.div<{ ratio: number; width: number; height: number }>`
  /* height: 100%; */
  flex-grow: 0 !important;
  height: auto;
  display: flex;
  flex-direction: column;
  //
  /* width: 100%; */
  padding-bottom: ${({ ratio }) => `${ratio}%`};
  /* width: ${({ width }) => `${width}px`}; */
  /* height: ${({ height }) => `${height}px`}; */
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);

  position: relative;
`;
const Canvas = styled.div<{
  ref: MutableRefObject<HTMLDivElement | null>;
  width: number;
  height: number;
  scale: number;
}>`
  background: #e5e5e5;
  /* flex-grow: 1; */
  /* position: relative; */

  position: absolute;
  top: 0;
  /* bottom: 0; */
  left: 0;
  /* right: 0; */
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};

  /* transform: ${({ scale }) => (scale > 1 ? 'scale(1)' : `scale(${scale})`)}; */
  transform: ${({ scale }) => `scale(${scale})`};
  transform-origin: top left;

  color: white;
  font-size: 24px;
  /* text-align: center; */
`;

const GridOverlay = styled.div`
  pointer-events: none;
  position: absolute;
  background: transparent;
  z-index: 1;
`;

export type TemplateEditorPageProps = {};

const TemplateEditorPage: FC<TemplateEditorPageProps> = () => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const { templateId } = useParams<{ templateId: string }>();
  //
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLDivElement>(null);
  const containerSize = useComponentSize(containerRef);
  const canvasSize = useComponentSize(canvasRef);
  //
  const [generatingPreview, setPreviewGenerationStatus] = useState(false);
  const [globalSettingsStatus, setGlobalSettingsStatus] = useState(false);
  const onOpenGlobalSettings = useCallback(() => setGlobalSettingsStatus(true), []);
  const onCloseGlobalSettings = useCallback(() => setGlobalSettingsStatus(false), []);
  //
  const variables = useMemo(() => ({ id: templateId! }), [templateId]);
  const { data: res, loading } = useGetTemplateQuery({
    variables,
    skip: !templateId,
    fetchPolicy: 'cache-and-network',
  });
  useSubscription(templateChangedSubscription, {
    variables: {
      filter: { id: [templateId] },
    },
    skip: !templateId,
  });
  //
  const [unsavedChanges, setUpdates] = useState(res?.getTemplate);
  //
  const [updateTemplate, { loading: templateIsUpdating }] = useUpdateTemplateMutation({
    onError,
    onCompleted(r) {
      const result = r.updateTemplate;
      // Set new updatedAt value to force re-rendering of the Canvas
      setUpdates({
        ...result,
      });
    },
  });
  const onGlobalSettingsChange = useCallback(
    (input: Partial<Template>) => {
      const d = unsavedChanges;
      const timestamp = time().toISOString();
      //
      setUpdates({
        ...merge(d, {
          ...input,
          updatedAt: timestamp,
        }),
      });
    },
    [unsavedChanges],
  );
  //
  const ratioId = unsavedChanges?.screenRatio ?? res?.getTemplate?.screenRatio ?? DEFAULT_RATIO;
  const onSelectRatio = useCallback(
    (screenRatio: ScreenRatio['id']) => {
      onGlobalSettingsChange({
        screenRatio,
      });
    },
    [onGlobalSettingsChange],
  );
  const [selectedArea, setSelectedArea] = useState(null);
  const ref = useRef();
  useClickOutside(ref, setSelectedArea);
  //
  const { data: getRatiosResult, loading: loadingRatios } = useGetScreenRatiosQuery({
    fetchPolicy: 'cache-first',
  });
  const ratios = useMemo(() => getRatiosResult?.getScreenRatios || [], [getRatiosResult]);
  //
  const ratio = useMemo(() => ratios.find(r => r.id === ratioId), [ratios, ratioId]);
  const { baseWidth, baseHeight, value: ratioValue } = ratio ?? {};
  //
  const hasUnsavedChanges = !time(res?.getTemplate?.updatedAt).isSameOrAfter(unsavedChanges?.updatedAt, 'second');
  usePrompt(t('You have unsaved changes, are you sure you want to leave that page?'), hasUnsavedChanges);

  const [gridConfig, setGridConfigUpdates] = useState(() => {
    const initialValue = {
      isGlobal: true,
      templateId,
      global: {
        color: '#FF0000',
        size: 40,
        enabled: false,
        snap: false,
        style: {
          'background-image':
            'linear-gradient(rgba(255, 0, 0, 0.8) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 0, 0, 0.8) 1px, transparent 1px)',
          'background-size': '40px 40px',
        },
      },
      byTemplate: {},
      currentStyle: {},
    };

    try {
      const item = localStorage.getItem('gridConfig');
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const areas = sortBy(unsavedChanges?.areas || [], 'sequence');

  const onCreate = useCallback(
    (area: TemplateAreaType) => {
      const d = unsavedChanges;
      if (!d || !Array.isArray(d?.areas)) {
        return;
      }
      setUpdates({
        ...merge(d, {
          areas: d.areas.concat([area]),
          updatedAt: time().toISOString(),
        }),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unsavedChanges?.updatedAt],
  );
  const onGridSettingsChanged = useCallback(
    input => {
      const newGridConfig = {
        ...merge(gridConfig, {
          ...input,
        }),
      };
      newGridConfig.currentStyle = merge(
        merge({}, gridConfig.global),
        gridConfig.byTemplate[gridConfig.templateId] || {},
      );
      window.localStorage.setItem('gridConfig', JSON.stringify(newGridConfig));
      setGridConfigUpdates(newGridConfig);
    },
    [gridConfig],
  );

  const onChange = useCallback(
    (areaId: string, updates: Partial<TemplateAreaType>) => {
      const d = unsavedChanges;
      if (!d || !Array.isArray(d?.areas)) {
        return;
      }
      const timestamp = time().toISOString();
      setUpdates({
        ...merge(d, {
          areas: d.areas.map(area => {
            if (!compareIds(area.id, areaId)) {
              return area;
            }
            return merge(area, updates, { updatedAt: timestamp });
          }),
          updatedAt: timestamp,
        }),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unsavedChanges?.updatedAt],
  );
  const onRemove = useCallback(
    (areaId: string) => {
      //
      const d = unsavedChanges;
      if (!d || !Array.isArray(d?.areas)) {
        return;
      }
      const timestamp = time().toISOString();
      setUpdates({
        ...merge(d, {
          areas: d.areas.map(a => {
            if (!compareIds(a.id, areaId)) {
              return a;
            }
            return merge(a, {
              updatedAt: timestamp,
              deletedAt: timestamp,
            });
          }),
          updatedAt: timestamp,
        }),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unsavedChanges?.updatedAt],
  );
  const context = useMemo<EditorContextState>(
    //
    () => ({
      template: res?.getTemplate,
      loading: loading || loadingRatios,
      onCreate,
      onChange,
      onRemove,
      onGlobalSettingsChange,
      onGridSettingsChanged,
      unsavedChanges,
      gridConfig,
      ratio,
      ratios,
      //
      selectedArea,
      setSelectedArea,
      //
      lastModified: unsavedChanges?.updatedAt || res?.getTemplate?.updatedAt,
    }),
    [
      res?.getTemplate,
      unsavedChanges,
      loading,
      loadingRatios,
      ratio,
      ratios,
      selectedArea,
      gridConfig,
      onChange,
      onCreate,
      onRemove,
      onGlobalSettingsChange,
      onGridSettingsChanged,
    ],
  );
  //
  const { template } = context;
  //
  const parentBreadcrumbNameMap = useContext(BreadcrumbContext);
  const breadcrumbNameMap = useMemo(
    () => ({
      ...parentBreadcrumbNameMap,
      '/template': t('Template editor'),
      [`/template/${templateId}`]: template?.title,
    }),
    [parentBreadcrumbNameMap, templateId, template?.title, t],
  );
  //
  useEffect(
    () => {
      if (template && !unsavedChanges) {
        setUpdates({ ...merge(unsavedChanges || {}, template) });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [template?.updatedAt],
  );

  useEffect(() => {
    gridConfig.templateId = templateId;
    gridConfig.currentStyle = merge(
      merge({}, gridConfig?.global ?? {}),
      gridConfig?.byTemplate[gridConfig.templateId] ?? {},
    );
  }, [gridConfig, templateId]);

  const initialScale = useMemo(
    () => (containerSize?.width ?? window.innerWidth) / (baseWidth ?? 1),
    [baseWidth, containerSize.width],
  );
  const [scale, setScale] = useState(initialScale);

  const handleZoomChange = useCallback(
    (zoom: number) => {
      const canvasZoomSteps = [1, 1.25, 1.5, 1.75, 2];

      // eslint-disable-next-line security/detect-object-injection
      setScale(initialScale * canvasZoomSteps[zoom]);
    },
    [initialScale],
  );

  const header = useMemo(() => {
    const actions = [
      {
        render(actionIndex: number) {
          return <Zoom onChange={handleZoomChange} min={0} max={4} key={actionIndex} />;
        },
      },
      {
        icon: <RiSettings5Line size="1.5rem" />,
        tooltipText: t('Template settings'),
        confirmationText: `${t('Save')}?`,
        onClick() {
          if (globalSettingsStatus) {
            onCloseGlobalSettings();
          } else {
            onOpenGlobalSettings();
          }
        },
      },
      {
        icon: <RiCheckLine />,
        tooltipText: t('Save'),
        confirmationText: `${t('Save')}?`,
        loading: generatingPreview || templateIsUpdating,
        onConfirm() {
          if (!template) {
            return;
          }
          setSelectedArea(null);
          setPreviewGenerationStatus(true);
          logger('Saving template to DB...', { unsavedChanges });
          //
          setTimeout(() => {
            html2canvas(canvasRef.current!, {
              // foreignObjectRendering: true,
              allowTaint: true,
              logging: process.env.NODE_ENV !== 'production',
              useCORS: true,
              backgroundColor: null, // 'null' is used for transparent background
            })
              .then(
                canvas =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  new Promise(resolve =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    {
                      canvas.toBlob(
                        resolve,
                        // @ts-ignore
                        {
                          mimeType: 'image/png',
                          qualityArgument: 0.9,
                        },
                      );
                    },
                  ),
              )
              .then(preview => {
                const input: UpdateTemplateInput = {
                  ...pick(unsavedChanges, ['title', 'bodyStyle', 'screenRatio']),
                  preview,
                  // @ts-ignore
                  areas: areas?.map(area => ({
                    id: area.id,
                    data: {
                      ...pick(area, ['style', 'title', 'sequence', 'itemId', 'playlistId', 'deletedAt']),
                      item: area?.item
                        ? pick(area?.item, ['id', 'type', 'url', 'title', 'projectId', 'folderId', 'props'])
                        : undefined,
                      playlist: area?.playlist
                        ? pick(area?.playlist, ['id', 'title', 'projectId', 'folderId', 'props'])
                        : undefined,
                    },
                  })),
                };
                return updateTemplate({
                  variables: {
                    id: template.id,
                    input,
                  },
                });
              })
              .catch(logger)
              .then(() => setPreviewGenerationStatus(false));
          }, 500);
        },
      },
    ];

    return {
      title: unsavedChanges?.title,
      actions,
    };
  }, [
    t,
    generatingPreview,
    templateIsUpdating,
    unsavedChanges,
    handleZoomChange,
    globalSettingsStatus,
    onCloseGlobalSettings,
    onOpenGlobalSettings,
    areas,
    updateTemplate,
    template?.id,
  ]);
  //
  const sizeContext = useMemo(
    () => ({
      container: containerSize,
      canvas: canvasSize,
      scale,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [containerSize.height, containerSize.width, canvasSize.height, canvasSize.width, scale],
  );
  //
  const reactFormattedStyle = formatPropForReact(unsavedChanges?.bodyStyle);
  const gridFormattedStyle = formatPropForReact(
    merge(
      { width: canvasSize.width * scale, height: canvasSize.height * scale },
      gridConfig.currentStyle.enabled ? gridConfig.currentStyle.style : {},
    ),
  );
  //
  return (
    <TemplateContext.Provider value={unsavedChanges || context?.template}>
      <EditorContext.Provider value={context}>
        <EditorSizeContext.Provider value={sizeContext}>
          <BreadcrumbContext.Provider value={breadcrumbNameMap}>
            <Content header={header} showBreadcrumbs showBackButton>
              <ScrollbarCustom className="overflowXscroll">
                <EditorContainer ref={containerRef} ratio={ratioValue!} width={baseWidth} height={baseHeight}>
                  <Canvas
                    ref={canvasRef}
                    style={reactFormattedStyle}
                    scale={scale ?? 1}
                    width={baseWidth}
                    height={baseHeight}>
                    {areas?.map(area => (
                      <TemplateArea key={area.id} id={area.id} />
                    ))}
                  </Canvas>
                  {gridConfig?.currentStyle?.enabled ?? false ? <GridOverlay style={gridFormattedStyle} /> : null}
                </EditorContainer>
              </ScrollbarCustom>
            </Content>

            <RightSider>
              <TemplateAreaList
                selectedRatio={ratioId}
                onSelectRatio={onSelectRatio}
                globalSettingsStatus={globalSettingsStatus}
                onCloseGlobalSettings={onCloseGlobalSettings}
              />
            </RightSider>
          </BreadcrumbContext.Provider>
        </EditorSizeContext.Provider>
      </EditorContext.Provider>
    </TemplateContext.Provider>
  );
};

TemplateEditorPage.displayName = 'TemplateEditorPage';

export default memo(TemplateEditorPage);
