/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, cloneElement } from 'react';
import { Droppable, Draggable, DroppableProps, DraggableProps, Direction } from 'react-beautiful-dnd';
import classNames from 'classnames';

export { DragDropContext } from 'react-beautiful-dnd';
export type { DropResult, DragDropContextProps } from 'react-beautiful-dnd';

export type DraggableItemProps = Omit<DraggableProps, 'children'> & {
  placeholder?: string;
  style?: { [k: string]: string | number };
  className?: string;
  children: React.ReactNode;
};

export const DraggableItem: FC<DraggableItemProps> = ({ draggableId, index, children, ...rest }) => (
  <Draggable draggableId={draggableId} index={index}>
    {({ draggableProps, dragHandleProps, innerRef }) => (
      <div className={rest.className} ref={innerRef} {...draggableProps} {...dragHandleProps}>
        {children}
      </div>
    )}
  </Draggable>
);

export type DroppableAreaProps = Omit<DroppableProps, 'children'> & {
  placeholder?: string;
  style?: { [k: string]: string | number };
  className?: string;
  children: React.ReactNode;
};

export const DroppableArea: FC<DroppableAreaProps> = ({
  type,
  droppableId,
  direction,
  isDropDisabled,
  children,
  ...rest
}) => (
  <Droppable type={type} droppableId={droppableId} direction={direction} isDropDisabled={isDropDisabled}>
    {({ droppableProps, innerRef, placeholder }, { isDraggingOver }) => (
      <div
        style={rest.style}
        className={classNames([rest.className, { 'is-dragging-over': isDraggingOver }])}
        ref={innerRef}
        {...droppableProps}>
        {children}
        {placeholder}
      </div>
    )}
  </Droppable>
);

export type DragItemProps = {
  droppableId?: string;
  draggableId: string;
  sequence: number;
  children: React.ReactElement[];
};

export const DragItem: FC<DragItemProps> = ({ droppableId, draggableId, sequence, children, ...props }) => {
  if (droppableId) {
    return (
      <DraggableItem draggableId={draggableId} index={sequence} {...props}>
        {children}
      </DraggableItem>
    );
  }

  return <>{children!.map(child => cloneElement(child, props))}</>;
};

export type DropAreaProps = {
  droppableId?: string | null;
  direction?: Direction;
  children: React.ReactElement;
};

export const DropArea: FC<DropAreaProps> = p => {
  const { droppableId, direction = 'horizontal', children, ...props } = p;
  if (droppableId) {
    return (
      <DroppableArea droppableId={droppableId} direction={direction} {...props}>
        {children}
      </DroppableArea>
    );
  }
  return cloneElement(children, props);
};

// export const useDropArea = (ref, onDrop) => {
//   const [isOver, setTargetValue] = useState(false);
//   const onDragOver = useCallback(() => {
//     setTargetValue(true);
//   }, []);
//   const onDragLeave = useCallback(() => {
//     setTargetValue(false);
//   }, []);
//
//   useEffect(() => {
//     const el = ref.current;
//     el.addEventListener('dragover', onDragOver);
//     el.addEventListener('dragleave', onDragLeave);
//     el.addEventListener('drop', onDrop);
//     return () => {
//       el.removeEventListener(onDragOver);
//       el.removeEventListener(onDragLeave);
//       el.removeEventListener(onDrop);
//     };
//   }, [ref, onDragOver, onDragLeave]);
//
//   return isOver;
// };
