/* eslint-disable react/forbid-prop-types */
import last from 'lodash/last';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'src/components/ui-kit/input';
import {
  MediaFolderItem,
  MediaFolderType,
  MediaItem,
  Playlist,
  UsageType,
  UsedItem,
  useGetUsedInQuery,
} from 'src/graphql/generated';
import styled from 'styled-components';
//
import { Link } from '@fjedi/react-router-helpers';
import { RiArrowRightLine, RiFilterLine, RiSearchLine } from 'react-icons/ri';
import { Skeleton } from 'antd';
import { Scrollbar } from 'src/components/ui-kit/scrollbar';
import { formatDate } from 'src/helpers/time';
import startIcon from 'static/images/undraw_photo.svg';
import Button from '../buttons';
import { Tabs } from '../tabs';
import { MediaFolderProfile } from '../media-item/media-item';

const AsideWrapper = styled.div`
  background-color: #ffffff;
  width: 20rem;
  height: 100%;
  position: relative;
  border-radius: 0.625rem;
  padding-bottom: 3.0625rem;
  box-shadow: 0 0.125rem 0.875rem rgba(0, 0, 0, 0.08);
  overflow-y: hidden;

  display: flex;
  flex-direction: column;

  .ant-tabs {
    flex-grow: 1;
    overflow: hidden;
    flex-direction: column;

    & > .ant-tabs-nav .ant-tabs-nav-list > .ant-tabs-tab {
      flex-basis: 50%;
    }

    .ant-tabs-content-holder {
      flex-grow: 1;
      overflow: hidden;
    }

    .ant-tabs-content {
      height: 100%;
    }

    .ant-tabs-tabpane {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .ant-collapse {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
`;

const Header = styled.div`
  padding: 1.25rem 1.875rem 0.625rem;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 0.5rem;
`;

// const SubTitle = styled.div`
//   font-size: 0.875rem;
//   text-align: center;
//   color: rgba(0, 0, 0, 0.2);
//   margin-bottom: 0.5rem;
// `;

const Search = styled(Input)`
  &.ant-input-affix-wrapper {
    height: 2.25rem;
    min-height: 2.25rem;
    width: auto;
    display: flex;
    margin: 0 1.875rem 1.25rem;
  }
`;

const Divider = styled.div`
  height: 0.5rem;
  min-height: 0.5rem;
  margin-top: -0.5rem;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  pointer-events: none;
  margin-bottom: 1rem;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    top: -0.5rem;
  }
`;

const FooterComponent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  box-shadow: 0px -0.125rem 1.5rem rgba(0, 0, 0, 0.08);

  .ant-btn {
    height: 3.125rem;
    font-size: 0.875rem;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    + .ant-btn {
      &:after {
        content: '';
        position: absolute;
        left: -0.0625rem;
        width: 0.0625rem;
        height: 1.625rem;
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }
`;
export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  box-shadow: 0px -0.125rem 1.5rem rgba(0, 0, 0, 0.08);

  .ant-btn {
    height: 3.125rem;
    font-size: 0.875rem;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    + .ant-btn {
      &:after {
        content: '';
        position: absolute;
        left: -0.0625rem;
        width: 0.0625rem;
        height: 1.625rem;
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }
`;

const StyledScrollbar = styled(Scrollbar)`
  min-height: auto !important;

  .thumbnail-folder {
    margin: 0.625rem 1.875rem;
  }
`;
const Start = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  justify-content: center;
`;

const StartIcon = styled.img`
  width: 15rem;
  margin-bottom: 0.5rem;
`;

const StartTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
`;

const StartDesc = styled.div`
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.2);
`;
const Body = styled(Skeleton)`
  padding: 0.6875rem 1.875rem;
`;
const Item = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6875rem 1.875rem;
  line-height: 1.5;
  border-bottom: 1px solid rgba(20, 22, 32, 0.2);
`;

const ItemTitle = styled.div`
  margin-right: 8px;
`;

const ItemDesc = styled.div`
  color: rgba(0, 0, 0, 0.4);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export type AsideMediaInfoProps = {
  selectedItem?: MediaFolderItem | null;
  folder?: MediaFolderProfile | null;
  onFilterChange: (_event: React.ChangeEvent<HTMLInputElement>) => void;
  filterValue: string;
};

export const AsideMediaInfo: FC<AsideMediaInfoProps> = props => {
  const { t } = useTranslation();
  const { selectedItem, folder, onFilterChange, filterValue } = props;
  const { title, createdAt } = selectedItem || {};
  const { width, height } = ((selectedItem || {}) as MediaItem).props ?? ({} as MediaItem['props']);
  const [itemTab, setItemTab] = useState('COMMON');
  //
  const fileType = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (selectedItem?.__typename !== 'MediaItem') {
      return '';
    }
    const { url } = selectedItem as MediaItem;
    try {
      return last(new URL(url!).pathname.split('.'))?.toUpperCase() ?? '';
    } catch (e) {
      return '';
    }
  }, [selectedItem]);
  //
  const itemsDuration = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (selectedItem?.__typename !== 'Playlist') {
      return 0;
    }
    const { items } = selectedItem as Playlist;
    return items?.map(i => i.duration).reduce((acc, value) => acc + value, 0) ?? 0;
  }, [selectedItem]);
  //
  const { data: queryResult, loading } = useGetUsedInQuery({
    variables: { id: selectedItem?.id as string, type: folder?.type as MediaFolderType },
    skip: !selectedItem?.id || !folder?.type,
    fetchPolicy: 'cache-and-network',
  });
  const usages = useMemo(() => queryResult?.getUsedIn?.rows || [], [queryResult]);

  const getTypeName = useMemo(
    () => (type?: UsageType | null) => {
      if (!type) {
        return t('Schedule');
      }
      switch (type.toLowerCase()) {
        case 'template':
          return t('Template');
        case 'playlist':
          return t('Playlist');
        default:
          return t('Schedule');
      }
    },
    [t],
  );

  const getUsageItemLink = useCallback((item: UsedItem) => {
    switch (item.type) {
      case 'PLAYLIST':
        return `/playlist/${item.id}`;
      case 'DAY_TIMESLOT':
        return `/schedules/${item.id}/day`;
      case 'WEEKDAY_TIMESLOT':
        return `/schedules/${item.id}/week`;
      case 'DATE_TIMESLOT':
        return `/schedules/${item.id}/calendar`;
      case 'EVENTS_TIMESLOT':
        return `/schedules/${item.id}/events`;
      case 'SCHEDULE_DEFAULT_CONTENT':
        return `/schedules/${item.id}/default-media-item`;
      default:
        return `/template/${item.id}`;
    }
  }, []);

  const searchField = useMemo(
    () => (
      <Search
        // enterButton
        allowClear
        value={filterValue}
        onChange={onFilterChange}
        placeholder={t('Search')}
        prefix={<RiSearchLine />}
        suffix={<RiFilterLine />}
      />
    ),
    [filterValue, onFilterChange, t],
  );

  const getItemLink = useMemo(() => {
    if (folder?.type === 'PLAYLIST') {
      return `/playlist/${selectedItem?.id}`;
    }
    return `/template/${selectedItem?.id}`;
  }, [folder?.type, selectedItem]);

  const renderTab = useCallback(
    (tabKey: string) => (
      <>
        {searchField}
        <Divider />
        <StyledScrollbar>
          {!selectedItem && (
            <Start>
              <StartIcon src={startIcon} alt="" />
              <StartTitle>{t('Select an item')}</StartTitle>
              <StartDesc>{t('Click on the item in a folder')}</StartDesc>
            </Start>
          )}

          {!!selectedItem && tabKey === 'USED_IN' && (
            <Body loading={loading} active>
              {selectedItem && usages.length === 0 && (
                <Start>
                  <StartIcon src={startIcon} alt="" />
                  <StartTitle>{t('Item is not used anywhere')}</StartTitle>
                </Start>
              )}
              {usages.map(u => (
                <Item key={u.title}>
                  <ItemTitle>{getTypeName(u.type)}</ItemTitle>
                  <ItemDesc as={Link} to={getUsageItemLink(u)}>
                    {u.title}
                  </ItemDesc>
                </Item>
              ))}
            </Body>
          )}
          {!!selectedItem && tabKey === 'COMMON' && (
            <Body loading={loading}>
              <Item>
                <ItemTitle>{t('Name')}</ItemTitle>
                <ItemDesc>{title}</ItemDesc>
              </Item>
              <Item>
                <ItemTitle>{t('Folder')}</ItemTitle>
                <ItemDesc>{folder?.name}</ItemDesc>
              </Item>
              {fileType && (
                <Item>
                  <ItemTitle>{t('File Type')}</ItemTitle>
                  <ItemDesc>{fileType}</ItemDesc>
                </Item>
              )}
              {width && height && (
                <Item>
                  <ItemTitle>{t('Resolution')}</ItemTitle>
                  <ItemDesc>{`${width}x${height}`}</ItemDesc>
                </Item>
              )}
              {(selectedItem as MediaItem)?.duration > 0 && (
                <Item>
                  <ItemTitle>{t('Duration')}</ItemTitle>
                  <ItemDesc>{`${(selectedItem as MediaItem).duration} ${t('sec')}`}</ItemDesc>
                </Item>
              )}
              {!!(selectedItem as Playlist).items?.length && (
                <>
                  <Item>
                    <ItemTitle>{t('Duration')}</ItemTitle>
                    <ItemDesc>{`${itemsDuration} ${t('sec')}`}</ItemDesc>
                  </Item>
                  <Item>
                    <ItemTitle>{t('Total items')}</ItemTitle>
                    <ItemDesc>{(selectedItem as Playlist).items!.length}</ItemDesc>
                  </Item>
                </>
              )}
              {createdAt && (
                <Item>
                  <ItemTitle>{t('Created at')}</ItemTitle>
                  <ItemDesc>{formatDate(createdAt, 'DD MMMM YYYY, HH:mm')}</ItemDesc>
                </Item>
              )}
            </Body>
          )}
        </StyledScrollbar>
      </>
    ),
    [
      t,
      selectedItem,
      title,
      createdAt,
      width,
      height,
      fileType,
      usages,
      loading,
      getTypeName,
      getUsageItemLink,
      searchField,
      itemsDuration,
      folder?.name,
    ],
  );

  const tabs = useMemo(
    () => [
      { key: 'COMMON', label: t('Info'), children: renderTab('COMMON') },
      { key: 'USED_IN', label: t('Used in'), children: renderTab('USED_IN') },
    ],
    [t, renderTab],
  );

  return (
    <AsideWrapper>
      <Header>
        <Title>{t('Item')}</Title>
      </Header>
      <Tabs activeKey={itemTab} type="card" onChange={setItemTab} destroyInactiveTabPane items={tabs} />
      <FooterComponent>
        {selectedItem && (folder?.type === 'TEMPLATE' || folder?.type === 'PLAYLIST') && (
          <Button type="link">
            <Link to={getItemLink}>{folder.type === 'TEMPLATE' ? t('Template editor') : t('Playlist editor')}</Link>
            <RiArrowRightLine />
          </Button>
        )}
      </FooterComponent>
    </AsideWrapper>
  );
};

export default memo(AsideMediaInfo);
