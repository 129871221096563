/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useState, useCallback, useContext, ReactNode, MouseEvent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ViewerContext } from '@fjedi/react-router-helpers';
import { updateAfterMutation, useApolloError } from '@fjedi/graphql-react-components';
import { Form } from 'antd';
import { RiArrowUpSLine, RiFolderAddLine } from 'react-icons/ri';
import {
  MediaFolderItem,
  MediaFolderType,
  useCreateMediaFolderMutation,
  useCreatePlaylistMutation,
  useCreateTemplateMutation,
  Viewer,
} from 'src/graphql/generated';
import logger from 'src/helpers/logger';
import { Input } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';
import { FixedScrollbar } from 'src/components/ui-kit/scrollbar';
import ButtonModalPopupComponent from 'src/components/ui-kit/modal-popup/button-modal';
import { Collapse, Panel } from 'src/components/ui-kit/collapse';
import { useGetFoldersWithScrollPagination } from 'src/components/routes/private/media-library/hooks';
import MediaItemList, { MediaItemListProps } from 'src/components/ui-kit/media-item/media-item-list';
import AddMediaItemButton, { FooterModal, AddButtonIcon } from 'src/components/ui-kit/media-item/add-media-item';
import Spinner from 'src/components/ui-kit/spinner';
import MediaFolderActions from './media-folder-actions';
import MediaFolderTitle from './media-folder-title';

const ExpandIconComponent: (_panelProps: unknown) => ReactNode = () => <RiArrowUpSLine />;
const NoExpandIconComponent: (_panelProps: unknown) => ReactNode = () => <div />;

export type MediaListProps = MediaItemListProps & {
  onItemClick?: (_item: MediaFolderItem, _event: MouseEvent<Element, MouseEvent>) => void;
  listType?: 'preview' | 'text';
};

export const ListScrollContainer = styled(FixedScrollbar)`
  min-height: calc(100% - 3.25rem);
`;

export const List: FC<MediaListProps> = props => {
  const {
    filterValue,
    contentType,
    onDragStart,
    onDragEnd,
    isEditable = true,
    isRemovable = true,
    renderItem,
    onItemClick,
    ...rest
  } = props;
  const [activePanels, onPanelChange] = useState<string[] | string>([]);
  //
  const { loading, folders, onScrollFrame, onScrollContainerUpdate, nextPageLoader } =
    useGetFoldersWithScrollPagination({
      contentType,
      filterValue,
      foldersPerPage: 4,
    });

  if (filterValue && filterValue.trim()) {
    return (
      <Collapse
        accordion
        activeKey={['search-results']}
        bordered={false}
        expandIconPosition="right"
        expandIcon={NoExpandIconComponent}
        {...rest}>
        <Panel key="search-results" header="Search results">
          <MediaItemList
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onClick={onItemClick}
            filterValue={filterValue}
            isEditable={isEditable}
            isRemovable={isRemovable}
            contentType={contentType}
            // listType="preview"
            renderItem={renderItem}
          />
        </Panel>
      </Collapse>
    );
  }

  return (
    <ListScrollContainer
      className="aside-media-manager-scroll-container"
      scrollbarProps={{ onScrollFrame, onUpdate: onScrollContainerUpdate }}>
      <Spinner spinning={loading} minHeight="400px" style={{ width: '100%', minWidth: '100%' }}>
        {folders.map(folder => (
          <Collapse
            accordion
            activeKey={activePanels}
            key={folder.id}
            bordered={false}
            expandIconPosition="end"
            collapsible="header"
            onChange={onPanelChange}
            expandIcon={ExpandIconComponent}
            {...rest}>
            <Panel
              key={folder.id}
              header={<MediaFolderTitle folder={folder} />}
              extra={
                !folder.name
                  ? null
                  : !folder.isDefault && (
                      <>
                        <MediaFolderActions folder={folder} />
                        <AddMediaItemButton folder={folder} contentType={contentType} />
                      </>
                    )
              }>
              <MediaItemList
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onClick={onItemClick}
                filterValue={filterValue}
                folder={folder}
                isEditable={isEditable}
                isRemovable={isRemovable}
                contentType={contentType}
                renderItem={renderItem}
                items={folder.items}
              />
            </Panel>
          </Collapse>
        ))}
      </Spinner>
      {nextPageLoader}
    </ListScrollContainer>
  );
};

export const Footer: FC<{ contentType: MediaFolderType }> = React.memo(({ contentType }) => {
  const viewer = useContext(ViewerContext) as Viewer;
  const { t } = useTranslation();
  const [visibleModal, setModalVisibility] = useState<string | false>(false);
  const openCreateMediaItemModal = useCallback((modalId: string) => () => setModalVisibility(modalId), []);
  const closeCreateMediaItemModal = useCallback(() => setModalVisibility(false), []);
  //
  const [folderForm] = Form.useForm();
  const [templateForm] = Form.useForm();
  //
  const onMediaItemCreated = useCallback(() => {
    templateForm.resetFields();
    folderForm.resetFields();
    closeCreateMediaItemModal();
  }, [templateForm, folderForm, closeCreateMediaItemModal]);
  //
  const onError = useApolloError();
  const [createFolder, { loading: creatingFolder }] = useCreateMediaFolderMutation({
    onError,
    onCompleted: onMediaItemCreated,
    update: updateAfterMutation('MediaFolder', 'getMediaFolders'),
  });
  const [createTemplate, { loading: creatingTemplate }] = useCreateTemplateMutation({
    onError,
    onCompleted: onMediaItemCreated,
    update: updateAfterMutation('Template', 'getTemplates'),
  });
  const [createPlaylist, { loading: creatingPlaylist }] = useCreatePlaylistMutation({
    onError,
    onCompleted: onMediaItemCreated,
    update: updateAfterMutation('Playlist', 'getPlaylists'),
  });

  const onMediaFolderSubmit = useCallback(
    (input: { name: string }) => {
      logger('Components.onMediaFolderSubmit', input);
      createFolder({
        variables: {
          input: {
            projectId: viewer.primaryProjectId!,
            type: contentType,
            name: input.name,
          },
        },
      }).catch(logger);
    },
    [contentType, createFolder, viewer.primaryProjectId],
  );
  const onTemplateSubmit = useCallback(
    (input: { title: string }) => {
      logger('Components.onTemplateSubmit', input);
      createTemplate({
        variables: {
          input: {
            projectId: viewer.primaryProjectId!,
            title: input.title,
          },
        },
      }).catch(logger);
    },
    [createTemplate, viewer.primaryProjectId],
  );
  const onPlaylistSubmit = useCallback(
    (input: { title: string }) => {
      logger('Components.onPlaylistSubmit', input);
      createPlaylist({
        variables: {
          input: {
            projectId: viewer.primaryProjectId!,
            title: input.title,
          },
        },
      }).catch(logger);
    },
    [createPlaylist, viewer.primaryProjectId],
  );
  const type = (contentType || 'image').toLowerCase();

  return (
    <>
      <ButtonModalPopupComponent
        isVisible={visibleModal === 'CREATE_FOLDER'}
        beforeOpen={openCreateMediaItemModal('CREATE_FOLDER')}
        beforeClose={closeCreateMediaItemModal}
        buttonType="link"
        buttonText={t('Create folder')}
        title={t('Create new folder')}
        width={480}
        zIndex={600}
        buttonIcon={<RiFolderAddLine />}>
        <Form layout="vertical" form={templateForm} onFinish={onMediaFolderSubmit}>
          <Form.Item
            label={t('Folder name')}
            name="name"
            rules={[
              {
                required: true,
                message: t('Enter folder name'),
              },
            ]}>
            <Input placeholder={t('Enter folder name')} />
          </Form.Item>
          <FooterModal>
            <Button type="default" htmlType="button" onClick={closeCreateMediaItemModal}>
              {t('Cancel')}
            </Button>
            <Button type="primary" htmlType="submit" loading={creatingFolder}>
              {t('Create')}
            </Button>
          </FooterModal>
        </Form>
      </ButtonModalPopupComponent>
      {contentType === 'TEMPLATE' && (
        <ButtonModalPopupComponent
          isVisible={visibleModal === 'CREATE_TEMPLATE'}
          beforeOpen={openCreateMediaItemModal('CREATE_TEMPLATE')}
          beforeClose={closeCreateMediaItemModal}
          buttonType="link"
          buttonText={t(`Create ${type}`)}
          title={t(`Create new ${type}`)}
          width={480}
          zIndex={600}
          buttonIcon={<AddButtonIcon contentType={contentType} />}>
          <Form layout="vertical" form={folderForm} onFinish={onTemplateSubmit}>
            <Form.Item
              label={t('Template name')}
              name="title"
              rules={[
                {
                  required: true,
                  message: t('Enter template name'),
                },
              ]}>
              <Input placeholder={t('Enter template name')} />
            </Form.Item>
            <FooterModal>
              <Button type="default" htmlType="button" onClick={closeCreateMediaItemModal}>
                {t('Cancel')}
              </Button>
              <Button type="primary" htmlType="submit" loading={creatingTemplate}>
                {t('Create')}
              </Button>
            </FooterModal>
          </Form>
        </ButtonModalPopupComponent>
      )}
      {contentType === 'PLAYLIST' && (
        <ButtonModalPopupComponent
          isVisible={visibleModal === 'CREATE_PLAYLIST'}
          beforeOpen={openCreateMediaItemModal('CREATE_PLAYLIST')}
          beforeClose={closeCreateMediaItemModal}
          buttonType="link"
          buttonText={t(`Create ${type}`)}
          title={t(`Create new ${type}`)}
          width={480}
          zIndex={600}
          buttonIcon={<AddButtonIcon contentType={contentType} />}>
          <Form layout="vertical" form={folderForm} onFinish={onPlaylistSubmit}>
            <Form.Item
              label={t('Playlist name')}
              name="title"
              rules={[
                {
                  required: true,
                  message: t('Enter folder name'),
                },
              ]}>
              <Input placeholder={t('Enter playlist name')} />
            </Form.Item>
            <FooterModal>
              <Button htmlType="button" onClick={closeCreateMediaItemModal}>
                {t('Cancel')}
              </Button>
              <Button type="primary" htmlType="submit" loading={creatingPlaylist}>
                {t('Create')}
              </Button>
            </FooterModal>
          </Form>
        </ButtonModalPopupComponent>
      )}
      {contentType !== 'TEMPLATE' && contentType !== 'PLAYLIST' && (
        <AddMediaItemButton contentType={contentType} buttonText={t(`Add ${type}`)} />
      )}
    </>
  );
});
