import Tooltip from 'src/components/ui-kit/tooltip';
import { HiExclamationCircle } from 'react-icons/hi';
import { colorTheme } from 'src/components/ui-kit/theme';
import React, { FC, useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Device } from 'src/graphql/generated';

const DeviceFWOutOfDateTooltip: FC<{ vendor: Device['vendor'] }> = ({ vendor }) => {
  const { t } = useTranslation();
  const isOutdatedDescription = useMemo(() => {
    switch (vendor) {
      case 'RaspberryPi':
        return t(
          'The firmware version is out of date. This may affect the correct operation of this device. Please upgrade firmware version via "Actions → Device → Firmware" in the right menu',
        );
      case 'Samsung':
        return t(
          'The firmware version is out of date. This may affect the correct operation of this device. To upgrade firmware version turn device off then turn on',
        );
      case 'LG':
        return t(
          'The firmware version is out of date. This may affect the correct operation of this device. Upgrade firmware version via LG settings',
        );
      default:
        return t(
          'The firmware version is out of date. This may affect the correct operation of this device. Please refresh the page',
        );
    }
  }, [vendor, t]);

  return (
    <Tooltip title={isOutdatedDescription}>
      <div className="outdated-icon">
        <HiExclamationCircle color={colorTheme.warning} />
      </div>
    </Tooltip>
  );
};

export default memo(DeviceFWOutOfDateTooltip);
