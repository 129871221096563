/* eslint-disable no-unused-vars */
import dayjs, { Dayjs, ConfigType, OpUnitType } from 'dayjs';
import localeDataPlugin from 'dayjs/plugin/localeData';
import dayOfYearPlugin from 'dayjs/plugin/dayOfYear';
import isoWeekPlugin from 'dayjs/plugin/isoWeek';
import weekdayPlugin from 'dayjs/plugin/weekday';
import utcPlugin from 'dayjs/plugin/utc';
import tzPlugin from 'dayjs/plugin/timezone';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import objectSupportPlugin from 'dayjs/plugin/objectSupport';
import minMaxPlugin from 'dayjs/plugin/minMax'; // required for react-big-calendar >= 0.36.0

dayjs.extend(utcPlugin);
dayjs.extend(localeDataPlugin);
dayjs.extend(tzPlugin);
dayjs.extend(dayOfYearPlugin);
dayjs.extend(isoWeekPlugin);
dayjs.extend(weekdayPlugin);
dayjs.extend(isBetweenPlugin);
dayjs.extend(isSameOrBeforePlugin);
dayjs.extend(isSameOrAfterPlugin);
dayjs.extend(customParseFormatPlugin);
dayjs.extend(localizedFormatPlugin);
dayjs.extend(minMaxPlugin);
dayjs.extend(objectSupportPlugin);

type ISOUnitType = OpUnitType | 'isoWeek';
export interface TimeInstance extends Dayjs {
  // "isoWeek" plugin's methods
  isoWeekYear(): number;
  isoWeek(): number;
  isoWeek(value: number): TimeInstance;
  isoWeekday(): number;
  isoWeekday(value: number): TimeInstance;
  startOf(unit: ISOUnitType): TimeInstance;
  endOf(unit: ISOUnitType): TimeInstance;
  isSame(date: ConfigType, unit?: ISOUnitType): boolean;
  isBefore(date: ConfigType, unit?: ISOUnitType): boolean;
  isAfter(date: ConfigType, unit?: ISOUnitType): boolean;

  // "UTC" plugin's methods
  utc(keepLocalTime?: boolean): TimeInstance;
  local(): TimeInstance;
  isUTC(): boolean;

  // "Timezone" plugin's methods
  tz(timezone?: string, keepLocalTime?: boolean): TimeInstance;
  offsetName(type?: 'short' | 'long'): string | undefined;
}

export type TimeConstructor = (
  date?: dayjs.ConfigType,
  format?: dayjs.OptionType,
  locale?: string,
  strict?: boolean,
) => TimeInstance;

export type DateValue = Date | Dayjs | string | number;
export type { Dayjs };

export function setGlobalTimezone(timezone: string) {
  dayjs.tz.setDefault(timezone);
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.timezone = timezone;
  }
}

export function isSameDayForAnotherTimezone(date: DateValue, tz?: string) {
  const timezone =
    !tz && typeof window !== 'undefined'
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.timezone
      : tz;
  const origDate = dayjs.tz(date, timezone); // +00:00 time
  // const localDate = dayjs(date); // +03:00 (real-timezone)
  const projectBasedDate = dayjs(date).tz(); // -11:00 project-based timezone

  return (
    projectBasedDate.year() === origDate.year() &&
    projectBasedDate.month() === origDate.month() &&
    projectBasedDate.date() === origDate.date()
  );
}

export function formatDate(date: DateValue, format?: string, keepOriginalTimezone?: boolean): string {
  const dateFormat = typeof format === 'undefined' ? 'D MMMM YYYY, HH:mm' : format;
  if (keepOriginalTimezone) {
    return dayjs(date).format(dateFormat);
  }
  return dayjs(date).tz().format(dateFormat);
}

export const time = (date?: DateValue | undefined, tz?: string): TimeInstance => {
  const format = 'YYYY-MM-DD HH:mm:ss';
  const timezone =
    !tz && typeof window !== 'undefined'
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.timezone
      : tz;
  if (!date) {
    const offset = dayjs().tz(timezone).utcOffset() + new Date().getTimezoneOffset();
    return dayjs().add(offset, 'minute');
  }
  if (tz) {
    return dayjs(dayjs(date).tz().format(format), format);
  }
  return dayjs(date);
};
Object.getOwnPropertyNames(dayjs).forEach(field => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line security/detect-object-injection
  if (typeof dayjs[field] !== 'function') {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line security/detect-object-injection
  time[field] = dayjs[field];
});

export default time as typeof dayjs;
