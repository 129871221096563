/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { FC, CSSProperties, memo, useCallback, useMemo } from 'react';
import { RiArrowLeftLine } from 'react-icons/ri';
import styled, { css } from 'styled-components';
import pick from 'lodash/pick';

import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';

export interface WayfindingWidgetProps {
  id: string;
  props?: {
    visibleRows: number;
    eventLogo: boolean;
    eventTime: boolean;
    eventLocation: boolean;
    separatorSize: number;
    separatorColor: CSSProperties['color'];
    eventTitleColor: CSSProperties['color'];
    eventTimeColor: CSSProperties['color'];
    eventLocationColor: CSSProperties['color'];
    paginationEnabled: boolean;
    pagination: {
      interval: number;
      fontFamily: string;
      fontSize: number;
      fontColor: CSSProperties['color'];
      horizontalAlignment: string;
      verticalAlignment: string;
    };
  };
  style?: CSSProperties;
}

interface ItemProps {
  align: CSSProperties['textAlign'];
  separatorColor: CSSProperties['color'];
  separatorSize: number;
  eventTimeColor: CSSProperties['color'];
  eventLocationColor: CSSProperties['color'];
}

interface ItemTitleProps {
  style?: CSSProperties;
  onlyEvents?: boolean;
}
type PaginationProps = Omit<Required<WayfindingWidgetProps>['props']['pagination'], 'interval'>;

const Container = styled.div<Pick<Required<WayfindingWidgetProps>['props'], 'paginationEnabled'> & PaginationProps>`
  width: 100%;
  height: 100%;
  position: relative;

  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  align-items: ${({ verticalAlignment }) => {
    switch (verticalAlignment) {
      case 'top':
        return 'start';
      case 'bottom':
        return 'end';
      case 'center':
      default:
        return 'center';
    }
  }};
  justify-items: ${({ horizontalAlignment }) => horizontalAlignment};

  & > ul {
    position: absolute;
    width: 100%;
    height: ${({ verticalAlignment, paginationEnabled }) =>
      paginationEnabled && verticalAlignment === 'center' ? 100 : 90}%;

    top: ${({ verticalAlignment, paginationEnabled }) => (paginationEnabled && verticalAlignment === 'top' ? '5%' : 0)};
    left: 0;
  }

  & > p {
    display: flex;
    align-items: center;
    justify-content: center;

    grid-column: ${({ horizontalAlignment }) => {
      switch (horizontalAlignment) {
        case 'left':
          return 1;
        case 'right':
          return 3;
        case 'center':
        default:
          return 2;
      }
    }};

    grid-row: ${({ verticalAlignment }) => {
      switch (verticalAlignment) {
        case 'top':
          return 1;
        case 'center':
          return 2;
        case 'bottom':
        default:
          return 3;
      }
    }};

    font-family: ${({ fontFamily }) => fontFamily ?? 'Lato'};
    font-size: ${({ fontSize }) => fontSize ?? 48}px;
    color: ${({ fontColor }) => fontColor ?? '#ffffff'};
  }
`;

const Item = styled.li<ItemProps>`
  display: grid;
  grid-template-columns: 8rem 1fr 8rem;
  grid-template-rows: repeat(2, 4rem);
  gap: 0 1.25rem;
  padding: 1.25rem;
  align-items: center;

  & > img {
    max-height: 8rem;
    width: 8rem;

    text-align: center;
    line-height: 8rem;
  }

  & > .event-logo {
    grid-column: 1;
    grid-row: 1 / span 2;
  }

  & > .wayfinding-icon {
    grid-column: 3;
    grid-row: 1 / span 2;
    justify-self: center;

    background-color: transparent;

    height: 6.25rem;
    width: 6.25rem;
    line-height: 8rem;
  }

  & > section {
    grid-row: 2;
    align-self: start;

    display: flex;
    align-items: center;
    justify-content: ${({ align }) => {
      switch (align) {
        case 'right':
          return 'flex-end';
        case 'center':
          return 'center';
        case 'justify':
          return 'space-between';
        case 'left':
        default:
          return 'flex-start';
      }
    }};
    color: ${({ eventTimeColor }) => eventTimeColor};

    font-size: 0.7em;

    & > hr {
      width: 0.5rem;
      margin: 0 0.625rem;
    }

    & > h3 {
      margin-left: 0.75rem;
      color: ${({ eventLocationColor }) => eventLocationColor};
      font-size: inherit;
      font-weight: inherit;
    }

    & > .event-time {
      display: flex;
      padding: 0;
      margin: 0;
      color: ${({ eventTimeColor }) => eventTimeColor};
    }
  }

  & + hr {
    width: 80%;
    border-width: ${({ separatorSize }) => separatorSize}px;

    ${({ separatorColor }) => css`
      border-color: ${separatorColor};
      background-color: ${separatorColor};
    `};
  }
`;

const NoLogo = styled.p.attrs({ className: 'event-logo' })`
  height: 8rem;
  width: 8rem;

  text-align: center;
  padding-top: 2rem;
  margin: 0;
  line-height: 2rem;
  background-color: ${colorTheme.primary};
  border-radius: 10%;
  color: ${colorTheme.light};
  font-weight: 700;
  font-size: 2rem;
  font-family: 'Lato';
`;

const ItemTitle = styled.h2<ItemTitleProps>`
  grid-row: ${({ onlyEvents }) => (onlyEvents ? '1 / span 2' : 1)};
`;

const WayfindingWidget: FC<WayfindingWidgetProps> = ({ style, ...item }) => {
  const {
    visibleRows,
    eventLogo,
    eventTime,
    eventLocation,
    separatorColor,
    separatorSize,
    eventTitleColor,
    eventTimeColor,
    eventLocationColor,
    paginationEnabled,
    pagination,
  } = item.props as Required<WayfindingWidgetProps>['props'];

  const onlyEvents = useMemo(() => !eventLocation && !eventTime, [eventLocation, eventTime]);

  const areaStyles: CSSProperties = useMemo(
    () => pick(style, ['font-size', 'font-family', 'font-weight', 'fontSize', 'fontFamily', 'fontWeight']),
    [style],
  );

  const renderEvent = useCallback(
    (i: number, itemKey: string, logoUrl?: string) => (
      <>
        {!!i && <hr key={`${itemKey}-separator`} />}
        <Item
          key={itemKey}
          separatorColor={separatorColor}
          separatorSize={separatorSize}
          eventTimeColor={eventTimeColor}
          eventLocationColor={eventLocationColor}
          align={style?.textAlign}
          style={areaStyles}>
          {eventLogo &&
            (logoUrl ? <img src={logoUrl} alt="Event Logo" className="event-logo" /> : <NoLogo>Event Logo</NoLogo>)}
          <ItemTitle
            onlyEvents={onlyEvents}
            style={{
              ...areaStyles,
              color: eventTitleColor,
              textAlign: style?.textAlign,
              gridColumn: eventLogo ? 2 : '1 / span 2',
            }}>
            Sample event title {i + 1}
          </ItemTitle>
          {!onlyEvents && (
            <section style={{ gridColumn: eventLogo ? 2 : '1 / span 2' }}>
              {eventTime && (
                <p className="event-time">
                  <time dateTime="00:00">00:00</time>
                  {' - '}
                  <time dateTime="23:59">23:59</time>
                </p>
              )}
              {eventLocation && <h3>Sample location name</h3>}
            </section>
          )}
          <RiArrowLeftLine
            style={{
              width: '8rem',
              height: '8 rem',
              color: colorGrays.gray100,
            }}
            className="wayfinding-icon"
          />
        </Item>
      </>
    ),
    [
      areaStyles,
      eventLocation,
      eventLocationColor,
      eventLogo,
      eventTime,
      eventTimeColor,
      eventTitleColor,
      onlyEvents,
      separatorColor,
      separatorSize,
      style?.textAlign,
    ],
  );

  return (
    <Container paginationEnabled={paginationEnabled} {...pagination} style={style}>
      <ul>
        {Array(visibleRows)
          .fill(true)
          .map((_, index) => renderEvent(index, `event-${item.id}-wf-${index}`))}
      </ul>
      {paginationEnabled && <p>Page 1 of 4</p>}
    </Container>
  );
};

export default memo(WayfindingWidget) as typeof WayfindingWidget;
