import React, { memo } from 'react';
import { Navigate, NotFound, Route, Routes, useLocation } from '@fjedi/react-router-helpers';
import SchedulesPage from './list';
import SchedulePage from './schedule-editor';

const SchedulesRoot = memo(() => {
  const location = useLocation();
  return (
    <Routes>
      <Route path=":scheduleId" element={<Navigate replace to={`${location.pathname}/day`} />} />
      <Route path=":scheduleId/*" element={<SchedulePage />} />
      <Route path="/" element={<SchedulesPage />} />
      <Route element={<NotFound />} />
    </Routes>
  );
});

SchedulesRoot.propTypes = {};
SchedulesRoot.defaultProps = {};
SchedulesRoot.displayName = 'SchedulesRoot';

export default SchedulesRoot;
