import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { CheckboxOptionType, RadioChangeEvent } from 'antd';
import { HiExclamationCircle } from 'react-icons/hi';

import { RadioGroup } from 'src/components/ui-kit/radio';
import { colorTheme } from 'src/components/ui-kit/theme';
import { useTranslation } from 'react-i18next';
import { DIMENSIONS, Ratio, Resolution } from 'src/components/routes/private/template-editor/constants';

const Container = styled.div`
  & > pre {
    display: block;
    margin-bottom: 0.5rem;
    font-family: inherit;
  }

  & > em {
    font-weight: 500;
  }
`;

const ResolutionItem = styled.li`
  display: flex;
  flex-direction: column;

  & > span:first-child {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    text-align: center;
  }

  & > .ant-radio-group {
    display: flex;
    flex-direction: column;
    padding: 0 0.375rem;

    & > .ant-radio-button-wrapper {
      height: 1.5rem;
      border-radius: 1.25rem;
      border: 1px solid ${colorTheme.primary};
      padding: 0 0.375rem;

      font-size: 0.85rem;
      white-space: nowrap;
      text-align: center;
      line-height: 1.5rem;

      &:not(:first-of-type) {
        margin-top: 0.625rem;
      }

      &::before {
        visibility: hidden;
      }

      &.ant-radio-button-wrapper-checked {
        background: ${colorTheme.primary};
        color: ${colorTheme.light};
      }
    }
  }
`;

const ResolutionsContainer = styled.ol`
  display: flex;
  list-style: none;
  padding: 0 0.625rem;
  margin-top: 1.5rem;
`;

export const AlertIcon = styled(HiExclamationCircle)`
  width: 20px;
  height: 20px;
  position: relative;
  top: 0.25rem;
`;

export type DimensionsModalProps = {
  deviceResolution?: { width: number; height: number };
  onSelect?(_resolution: Resolution, _ratio?: Ratio): void;
};

const x = ' \u2715 ' as const;

export const DimensionsModal: FC<DimensionsModalProps> = ({ deviceResolution, onSelect }) => {
  const { t } = useTranslation();

  const initialResolution = useMemo(() => {
    if (typeof deviceResolution === 'string') {
      return deviceResolution;
    }

    if (deviceResolution instanceof Object && 'width' in deviceResolution && 'height' in deviceResolution) {
      return `${deviceResolution.width}:${deviceResolution.height}`;
    }

    return '1920:1080';
  }, [deviceResolution]);

  const [selectedResolution, setSelectedResolution] = useState(initialResolution);

  const ratioOptions = useMemo(
    () =>
      Object.entries(DIMENSIONS).map(([ratio, resolutions]) => {
        const options = resolutions.map(({ label: ratioLabel, ...dimension }) => {
          const label = ratioLabel.replace('x', x);

          return {
            ...dimension,
            label,
          };
        });

        return [ratio, options] as [Ratio, CheckboxOptionType[]];
      }),
    [],
  );

  useEffect(() => () => setSelectedResolution(initialResolution), [initialResolution]);

  const handleChange = useCallback(
    (event?: RadioChangeEvent) => {
      const { value, name } = event!.target;
      const ratio = name as Ratio;
      const resolution = value;

      setSelectedResolution(resolution);

      if (typeof onSelect === 'function') {
        onSelect(resolution, ratio);
      }
    },
    [onSelect],
  );

  // TODO [API]: add translation keys
  return (
    <Container>
      <pre>
        {`${t('To apply selected resolution click "Reboot" button.')}\n${t(
          'Default one is',
        )} ${initialResolution.replace(':', x)} (16:9)`}
      </pre>
      <em>
        <AlertIcon color={colorTheme.warning} />
        {t('Be careful, the device will be immediately rebooted!')}
      </em>
      <ResolutionsContainer>
        {ratioOptions.map(([ratio, resolutions], index) => (
          <ResolutionItem key={`${ratio}${-index}`}>
            <span>{ratio}</span>
            <RadioGroup
              optionType="button"
              options={resolutions}
              value={selectedResolution}
              name={ratio}
              onChange={handleChange}
              defaultValue={initialResolution}
            />
          </ResolutionItem>
        ))}
      </ResolutionsContainer>
    </Container>
  );
};

export default memo(DimensionsModal) as typeof DimensionsModal;
