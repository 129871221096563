import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// <Helmet> component for setting the page title/meta tags
import Helmet from 'src/components/ui-kit/helmet-head';
// import brandImage from 'src/components/ui-kit/layout/logo.svg';
import { Logo } from 'src/components/ui-kit/admin-layout/logo';
import formImage from 'src/components/ui-kit/layout/auth-background.jpg';

const Container = styled.div`
  color: #474a54;
  background: ${props => props.theme.authBoxBackground};
  height: 100%;
  width: 100%;
  position: relative;

  @media screen and (orientation: portrait) {
    padding-top: ${props => props.theme.headerHeight};
    padding-bottom: ${props => props.theme.headerHeight};
  }
`;
const Content = styled.div`
  flex-grow: 1;
  display: flex;

  @media screen and (orientation: portrait) {
    position: relative;
    left: 0;
    top: 0;
    transform: initial;
  }

  h2 {
    font-weight: 400;
    font-size: 26px;
  }

  > div,
  > form {
    .ant-btn[type='submit'] {
      margin-top: 2.5rem;
    }

    p {
      margin: 1rem 0 1.2rem;
    }

    > div:not(:last-child) {
      margin-bottom: 1rem;
    }

    .ant-row.ant-form-item {
      &:last-child {
        margin-bottom: 0;
      }
    }
    //input[type='text'],
    //input[type='email'],
    //input[type='password'],
    //input[type='number'] {
    //  transition: border 0.4s;
    //  border-radius: 2px;
    //  display: inline-block;
    //  padding: 9px;
    //  border: 1px solid #ddd;
    //  margin-bottom: 10px;
    //  font-size: 14px;
    //  line-height: 20px;
    //  height: 42px;
    //  vertical-align: middle;
    //}
  }
`;
const Header = styled.header`
  margin-bottom: 50px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 0;
  line-height: 1.4;
`;

const SubTitle = styled.div`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.4);
`;

const Footer = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  padding: 1rem 0 0;
  font-size: 0.875rem;

  button,
  a {
    text-decoration: none;
    color: #fff;
    display: inline-block;
  }
`;

const Main = styled.div`
  padding: 0 1rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 50%;
    min-width: 432px;
    max-width: 720px;
  }
`;

const Body = styled.div`
  width: 400px;
  max-width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    padding: 100px 0;
  }
`;

const Brand = styled.div`
  margin-bottom: 2rem;

  @media screen and (min-height: 800px) {
    position: absolute;
    margin-bottom: 0;
  }
`;

const Form = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

const FormImage = styled.div`
  flex: 1 0 auto;
  background: url(${formImage}) no-repeat;
  background-size: cover;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const AuthBox = props => {
  const { title, subTitle, children, footerChildren } = props;

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <Content>
          <Main>
            <Body>
              <Brand>
                <Logo />
              </Brand>
              <Form>
                <Header>
                  <Title>{title}</Title>
                  {subTitle && <SubTitle>{subTitle}</SubTitle>}
                </Header>
                {children}
                {footerChildren && <Footer>{footerChildren}</Footer>}
              </Form>
            </Body>
          </Main>
          <FormImage />
        </Content>
      </Container>
    </Fragment>
  );
};

AuthBox.propTypes = {
  children: PropTypes.oneOfType([
    //
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
    PropTypes.array,
  ]).isRequired,
  footerChildren: PropTypes.oneOfType([
    //
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
    PropTypes.array,
  ]),
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};
AuthBox.defaultProps = {
  footerChildren: undefined,
  subTitle: '',
};

export default AuthBox;
