import { useCallback, useEffect, useState, CSSProperties, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import parseInt from 'lodash/parseInt';
import { MediaItem } from 'src/graphql/generated';

export type WidgetProps = {
  item: Pick<MediaItem, 'props'>;
  style?: CSSProperties;
};

export type ClockProps = {
  clockSize: number;
  loadingSize?: number;
  color?: string;
  mainColor?: string;
  perimeter?: number;
  hh?: number;
  mm?: number;
  ss?: number;
};

export function useClockProps(
  item: WidgetProps['item'],
  style: WidgetProps['style'],
  deg?: number,
): Required<ClockProps & { date: Dayjs }> {
  const { width, height, color = '#37f' } = style ?? {};
  const timezone = item?.props?.timezone;

  const clockSize = useMemo(() => {
    const w = width || 100;
    const h = height || 100;

    return Math.min(parseInt(`${w}`, 10), parseInt(`${h}`, 10));
  }, [width, height]);

  const perimeter = useMemo(() => clockSize * 3.14, [clockSize]);

  const date = useTimeTickerHook(timezone);

  const hh = date.hour() * 30;
  const mm = date.minute() * (deg ?? 1);
  const ss = date.second() * (deg ?? 1);

  return { date, clockSize, hh, mm, ss, loadingSize: ss, color, perimeter, mainColor: color };
}

export function setWidgetTimezone(timezone?: string) {
  if (timezone) {
    return dayjs.tz(dayjs(), timezone);
  }
  return dayjs();
}

export function useTimeTickerHook(timezone?: string) {
  const [date, setDate] = useState(dayjs());

  const tick = useCallback(() => {
    setDate(setWidgetTimezone(timezone));
  }, [timezone, setDate]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  }, [tick]);

  return date;
}
