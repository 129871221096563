import React, { FC, memo, useCallback } from 'react';
import { Link, useParams } from '@fjedi/react-router-helpers';
import { useTranslation } from 'react-i18next';
import { logger, useApolloError } from '@fjedi/graphql-react-components';
import { ResetPasswordMutationVariables, useResetPasswordMutation, ViewerDocument } from 'src/graphql/generated';
import Button from 'src/components/ui-kit/buttons';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { PasswordInput } from 'src/components/ui-kit/input';
import AuthBox from 'src/components/ui-kit/auth-box';
import { StyledForm } from 'src/components/common/login';

const ResetPasswordPage: FC = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { resetFields } = form;
  //
  const onCompleted = useCallback(() => {
    resetFields();
    window.location.reload();
  }, [resetFields]);
  const onError = useApolloError();
  const [resetPassword, { loading }] = useResetPasswordMutation({
    onError,
    onCompleted,
    update(cache, { data }) {
      if (!data?.resetPassword) {
        return;
      }
      cache.writeQuery({
        query: ViewerDocument,
        data: { viewer: data.resetPassword },
      });
    },
  });
  const onSubmit = useCallback(
    (formData: unknown) => {
      if (!token) {
        return;
      }
      resetPassword({
        variables: {
          ...(formData as Omit<ResetPasswordMutationVariables, 'token'>),
          token,
        },
      }).catch(logger);
    },
    [resetPassword, token],
  );
  const checkPassword = useCallback(
    (rule: unknown, value: string) => {
      if (value && value !== form.getFieldValue('password')) {
        return Promise.reject(t('Entered passwords must match'));
      }
      return Promise.resolve();
    },
    [form, t],
  );

  return (
    <AuthBox title={t('Set password')} footerChildren={<Link to="/">{t('Main page')}</Link>}>
      <StyledForm form={form} onFinish={onSubmit} layout="vertical">
        <FormItem
          name="password"
          label={t('Password')}
          rules={[
            { required: true, message: t('Type new password') },
            {
              min: 8,
              message: t('Minimal length of the password - {{length}} symbols', {
                length: 8,
              }),
            },
            {
              max: 30,
              message: t('Maximal length of the password - {{length}} symbols', {
                length: 30,
              }),
            },
          ]}>
          <PasswordInput placeholder={t('Password')} />
        </FormItem>
        <FormItem
          name="passwordConfirmation"
          label={t('Confirm entered password')}
          rules={[{ required: true, message: t('Repeat new password') }, { validator: checkPassword }]}>
          <PasswordInput placeholder={t('Confirm entered password')} />
        </FormItem>
        <FormItem>
          <Button block loading={loading} type="primary" size="large" htmlType="submit">
            {t('Set new password')}
          </Button>
        </FormItem>
      </StyledForm>
    </AuthBox>
  );
};

ResetPasswordPage.displayName = 'ResetPasswordPage';

export default memo(ResetPasswordPage);
