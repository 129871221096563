import React, { memo } from 'react';
import styled from 'styled-components';

export interface DisplayTextProps {
  children: React.ReactNode;
  center?: boolean;
  styles?: { [key: string]: string | number };
  className?: string;
}

const TextCenter = styled.div`
  top: 50%;
  left: 50%;
  color: #fff;
  display: block;
  position: absolute;
  font-size: 5rem;
  letter-spacing: 0.1em;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const DisplayText: React.FC<DisplayTextProps> = memo(props => {
  return props.center ? (
    <TextCenter className={props.className}>{props.children}</TextCenter>
  ) : (
    <div className={props.className}>{props.children}</div>
  );
});
DisplayText.displayName = 'DisplayText';

export default DisplayText;
