/* eslint-disable react/require-default-props */
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RiZoomInLine, RiZoomOutLine } from 'react-icons/ri';

import Tooltip from 'src/components/ui-kit/tooltip';
import Button from 'src/components/ui-kit/buttons';

const ZoomContainer = styled.div.attrs({ className: 'zoom' })`
  .ant-btn.ant-btn-link.ant-btn-icon-only {
    color: inherit;
    width: auto;

    &:first-of-type {
      margin-right: 0.25rem;
    }
  }
`;

interface ZoomProps {
  min: number;
  max: number;
  initial?: number;
  onChange: (_zoom: number) => void;
}

const Zoom: FC<ZoomProps> = ({ min, max, initial, onChange }) => {
  const { t } = useTranslation();

  const [zoom, setZoom] = useState(initial ?? min);

  const zoomIn = useCallback(() => setZoom(currentZoom => Math.min(currentZoom + 1, max)), [max]);
  const zoomOut = useCallback(() => setZoom(currentZoom => Math.max(currentZoom - 1, min)), [min]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(zoom);
    }
  }, [onChange, zoom]);

  return (
    <Tooltip title={t('Zoom')} align={{ offset: [0, 5] }}>
      <ZoomContainer>
        <Button type="link" disabled={zoom <= min} onClick={zoomOut} icon={<RiZoomOutLine />} />
        <Button type="link" disabled={zoom >= max} onClick={zoomIn} icon={<RiZoomInLine />} />
      </ZoomContainer>
    </Tooltip>
  );
};

export default memo(Zoom);
