import type { ApolloCache, FetchResult } from '@fjedi/graphql-react-components';
import type { CreateMediaFolderMutation } from 'src/graphql/generated';

const CACHE_FIELD = 'getMediaFolders';

export function getCacheRefFromId(id: string) {
  return `MediaFolder:${id}`;
}

export const updateAfterFolderCreated = (
  cache: ApolloCache<unknown>,
  result: FetchResult<CreateMediaFolderMutation>,
) => {
  const folder = result?.data?.createMediaFolder;
  if (!folder?.id) {
    return;
  }
  const cacheId = cache.identify({
    __ref: getCacheRefFromId(folder.id),
  });
  if (!cacheId) {
    return;
  }
  cache.modify({
    fields: {
      [CACHE_FIELD](cachedData, { toReference, storeFieldName }) {
        const { rows, count } = cachedData as { rows: { __ref: string }[]; count: number };
        if (rows.some(r => cacheId === r.__ref)) {
          return cachedData;
        }
        // storeFieldName of the getMediaFolders query will always have filter by "type" field
        // and it must match the type of the folder we have just created
        if (storeFieldName.includes(folder.type)) {
          return {
            count: count + 1,
            rows: [toReference(cacheId)].concat(rows),
          };
        }
        return cachedData;
      },
    },
  });
};
