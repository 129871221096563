import React, { memo, useMemo, useCallback, useState, useEffect, FC, forwardRef, MutableRefObject } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  useQuery,
  useSubscribeToMore,
  getDataFromResponse,
  QueryResult,
  DataRow,
  useApolloError,
} from '@fjedi/graphql-react-components';
import type { DocumentNode, PaginatedList } from '@fjedi/graphql-react-components';
import styled from 'styled-components';
import logger from 'src/helpers/logger';
//
import Spinner from 'src/components/ui-kit/spinner';

interface HeaderProps {
  rawData: {
    rows: unknown[];
    count: number;
    [k: string]: unknown;
  };
  rows: unknown[];
  count: number;
  hasMore: boolean;
}
//
interface ScrollPaginationProp {
  query: DocumentNode;
  subscriptionQueries?: any[];
  subscriptionId?: string;
  dataType: string;
  variables: any;
  renderRow: (row: DataRow, list?: PaginatedList, queryRes?: QueryResult) => React.ReactElement;
  renderHeader?: (data: HeaderProps) => React.ReactElement;
  renderFooter?: () => React.ReactElement;
  dummy?: () => React.ReactElement;
  skip?: boolean;
  style?: React.CSSProperties;
  rowsPerPage?: number;
  noMoreDataComponent?: React.ReactElement;
  scrollThreshold?: string | number;
  scrollableTarget?: string;
  height?: number;
  onCompleted?: (data: QueryResult) => void;
}

const DEFAULT_ROWS_PER_PAGE = 15;
const DEFAULT_SCROLL_THRESHOLD = 0.9;

export const StyledSpinnerContainer = styled.div`
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
`;
const StyledSpinner = styled(Spinner)``;
export const SpinnerComponent = forwardRef<HTMLDivElement, { hasNextPage?: boolean; loading?: boolean }>(
  (props, ref) => (
    <StyledSpinnerContainer
      ref={ref}
      className="next-page-loader"
      style={{
        visibility: props.loading ? 'visible' : 'hidden',
        display: props.hasNextPage ? 'flex' : 'none',
      }}>
      <StyledSpinner />
    </StyledSpinnerContainer>
  ),
);

//
const ScrollPagination: FC<ScrollPaginationProp> = memo(props => {
  const {
    query,
    subscriptionQueries,
    subscriptionId,
    dataType,
    variables,
    renderRow,
    renderHeader,
    renderFooter,
    skip,
    style,
    height,
    rowsPerPage = DEFAULT_ROWS_PER_PAGE,
    noMoreDataComponent,
    scrollableTarget,
    scrollThreshold,
    dummy = () => <div />,
    onCompleted,
  } = props;
  const [page, setPage] = useState(0);
  const onError = useApolloError();
  const queryResult = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...(variables || {}),
      pagination: {
        offset: 0,
        limit: rowsPerPage,
      },
    },
    fetchPolicy: 'cache-and-network',
    onError,
    context: {
      debounceKey: 'scroll-pagination',
      debounceTimeout: 400,
    },
    onCompleted,
  });
  const { data, fetchMore, subscribeToMore, loading } = queryResult;

  const subscriptionProps = useMemo(
    () => ({
      dataType,
      subscriptionQueries: subscriptionQueries ?? [],
      subscribeToMore,
      variables,
      subscriptionId: subscriptionId ?? 'scroll-pagination',
    }),
    [variables, subscribeToMore, subscriptionQueries, dataType, subscriptionId],
  );
  useSubscribeToMore(subscriptionProps);
  const paginatedList: PaginatedList = useMemo(
    () => getDataFromResponse(dataType, { withGetPrefix: true })(data),
    [dataType, data],
  );

  const { rows, count } = paginatedList;
  const hasMore = !data || rows.length < count;
  const headerProps = useMemo(
    () => ({ rows, count, rawData: paginatedList, hasMore }),
    [rows, count, hasMore, paginatedList],
  );
  //
  const getNextPage = useCallback(() => {
    setPage(page + 1);
  }, [page, setPage]);
  //
  useEffect(() => {
    if (!page) {
      return;
    }
    const v = {
      pagination: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
      },
    };
    logger('ScrollPagination.fetchMore', { v, page });
    fetchMore({
      variables: v,
    }).catch(logger);
  }, [page, rowsPerPage, fetchMore]);

  logger('ScrollPagination.render', { rows, hasMore });

  //
  return (
    <>
      {typeof renderHeader === 'function' && renderHeader(headerProps)}
      <InfiniteScroll
        dataLength={rows.length}
        next={getNextPage}
        hasMore={hasMore}
        style={style}
        loader={<SpinnerComponent />}
        scrollThreshold={scrollThreshold}
        scrollableTarget={scrollableTarget}
        height={height}
        endMessage={noMoreDataComponent}>
        {Array.isArray(rows) && rows.length > 0
          ? rows.map((row: DataRow) => renderRow(row, paginatedList, queryResult))
          : !loading && dummy()}
      </InfiniteScroll>
      {typeof renderFooter === 'function' && renderFooter()}
    </>
  );
});

ScrollPagination.propTypes = {
  noMoreDataComponent: PropTypes.element,
  renderRow: PropTypes.func.isRequired,
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func,
  dummy: PropTypes.func,
  skip: PropTypes.bool,
  style: PropTypes.shape({}),
  rowsPerPage: PropTypes.number,
  scrollThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scrollableTarget: PropTypes.string,
  dataType: PropTypes.string.isRequired,
  subscriptionId: PropTypes.string,
  height: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.any.isRequired,
  subscriptionQueries: PropTypes.arrayOf(PropTypes.any),
  onCompleted: PropTypes.func,
};
ScrollPagination.defaultProps = {
  renderHeader: undefined,
  renderFooter: undefined,
  dummy: () => <div />,
  skip: false,
  style: {},
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  scrollThreshold: DEFAULT_SCROLL_THRESHOLD,
  height: undefined,
  scrollableTarget: undefined,
  noMoreDataComponent: undefined,
  subscriptionQueries: [],
  subscriptionId: undefined,
};

export default ScrollPagination;
