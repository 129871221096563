import React, { SetStateAction, Dispatch, createContext } from 'react';
import { UsedItem } from 'src/graphql/generated';

export type MediaItemUsageContextProps = {
  isUsageErrorVisible: boolean;
  mediaUsages: UsedItem[];
  setIsUsageErrorVisible: (_b: boolean) => void;
  setMediaUsages: Dispatch<SetStateAction<UsedItem[]>>;
};

export default createContext<MediaItemUsageContextProps>({} as MediaItemUsageContextProps);
