/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal as AntModal } from 'antd';
import { MediaItemListModal } from 'src/components/ui-kit/media-item/media-item-list-modal';
import { EditorContext } from './context';

export const MediaItemSelector = memo(p => {
  const { title, buttonText, buttonIcon, contentType, onSelectItem, ...props } = p;
  const { t } = useTranslation();
  const { unsavedChanges } = useContext(EditorContext);
  //
  const [modalIsVisible, setModalVisibility] = useState(false);
  const openModal = useCallback(() => {
    // TODO: if (SINGLE_INSTANCE_TEMPLATE_TYPES.includes(contentType)) {
    if (contentType === 'VIDEO') {
      // TODO: unsavedChanges?.areas?.some(a => SINGLE_INSTANCE_TEMPLATE_TYPES.includes(a.item?.type) && !a.deletedAt)
      const templateAlreadyHasVideos = unsavedChanges?.areas?.some(a => a.item?.type === 'VIDEO' && !a.deletedAt);
      if (templateAlreadyHasVideos) {
        AntModal.warning({
          title: t('Warning'),
          content: t('Adding multiple videos to the same template is currently unsupported'),
        });
        return;
      }
    }
    setModalVisibility(true);
  }, [t, contentType, unsavedChanges]);
  const closeModal = useCallback(() => setModalVisibility(false), []);

  const onItemClick = useCallback(
    item => {
      if (typeof onSelectItem === 'function') {
        // Normally this 'if' will never be used
        // but in case if user somehow got video on his canvas but at the same time was able to click on media-item
        // we should show him that alert
        // TODO: if (SINGLE_INSTANCE_TEMPLATE_TYPES.includes(contentType)) {
        if (contentType === 'VIDEO') {
          // TODO: unsavedChanges?.areas?.some(a => SINGLE_INSTANCE_TEMPLATE_TYPES.includes(a.item?.type) && !a.deletedAt)
          const templateAlreadyHasVideos = unsavedChanges?.areas?.some(a => a.item?.type === 'VIDEO' && !a.deletedAt);
          if (templateAlreadyHasVideos) {
            AntModal.warning({
              title: t('Warning'),
              content: t('Adding multiple videos to the same template is currently unsupported'),
            });
            return;
          }
        }
        onSelectItem(item);
      }
      closeModal();
    },
    [t, contentType, onSelectItem, closeModal, unsavedChanges],
  );

  return (
    <MediaItemListModal
      className="logos-scrollbar"
      contentType={contentType}
      onSelectItem={onItemClick}
      onOpen={openModal}
      onClose={closeModal}
      isOpened={modalIsVisible}
      title={title}
      buttonType="link"
      buttonText={buttonText}
      width="34.8rem"
      buttonIcon={buttonIcon}
      {...props}
    />
  );
});

MediaItemSelector.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonIcon: PropTypes.node,
  buttonDisabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};
MediaItemSelector.defaultProps = {
  title: '',
  buttonIcon: undefined,
  buttonDisabled: false,
  children: undefined,
};
MediaItemSelector.displayName = 'MediaItemSelector';

export default MediaItemSelector;
