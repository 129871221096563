import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { colorGrays } from 'src/components/ui-kit/theme';
import ModalPortal from './modal-portal';

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Layout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
`;
const Content = styled.div<{ width: number | string; height?: number | string }>`
  width: ${props => (typeof props.width === 'number' ? `${props.width}px` : props.width)};
  height: ${props => (typeof props.height === 'number' ? `${props.height}px` : props.height)};
  max-width: 100vw;
  min-height: 200px;
  max-height: 100vh;
  background-color: #fff;
  position: relative;
  z-index: 10000;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0 6px 46px rgba(0, 0, 0, 0.2);
`;
const Header = styled.div`
  color: #ffffff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 5rem 0.875rem 1.875rem;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid ${colorGrays.gray300};
`;

const Body = styled.div.attrs({ className: 'modal-popup__body' })`
  padding: 1.875rem 1.875rem;
  max-height: calc(100% - 4rem);
  overflow: hidden;

  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 0.125rem;

    label {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
    }
  }

  .ant-form-item {
    margin-bottom: 16px;
  }
`;

const CloseButton = styled.button`
  padding: 0.125rem 0.625rem;
  font-size: 1.75rem;
  border: 0 solid transparent;
  background-color: transparent;
  position: absolute;
  right: 1.25rem;
  line-height: 1;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    color: #eeeeee;
  }
`;
const ModalPopup = styled.div<{ zIndex: number; visible?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => props.zIndex};

  ${Content} {
    opacity: ${props => (props.visible ? 1 : 0)};
    transform: ${props => (props.visible ? 'translateY(0)' : 'translateY(-10%)')};
    transition: all 0.6s;
  }

  ${Overlay} {
    opacity: ${props => (props.visible ? 1 : 0)};
    transition: opacity 0.6s;
  }
`;

export type ModelContentRow = React.ReactElement | null | false;
export type ModalContent = ModelContentRow | ModelContentRow[];

//
export type ModalPopupProps = {
  onCancel: (_e: React.SyntheticEvent) => void;
  title?: string | React.ReactElement;
  isVisible?: boolean;
  zIndex?: number;
  className?: string;
  width?: string | number;
  height?: string | number;
  closeOnBgClick?: boolean;
  destroyOnClose?: boolean;
  children?: ModalContent;
  style?: { [k: string]: string | number };
};

const ModalPopupComponent: FC<ModalPopupProps> = props => {
  const {
    onCancel,
    title = null,
    className,
    style,
    width = '485px',
    height,
    isVisible = false,
    zIndex = 500,
    children,
    closeOnBgClick = true,
    destroyOnClose = false,
  } = props;

  if (destroyOnClose && !isVisible) {
    return null;
  }

  return (
    <ModalPortal>
      <ModalPopup style={style} className={className} visible={isVisible} zIndex={isVisible ? zIndex : -1}>
        <Overlay className="modal-overlay" />
        <Layout className="modal-layout">
          <ContentBackground onClick={closeOnBgClick ? onCancel : undefined} />
          <Content width={width} height={height} className="modal-content">
            <Header>
              {title}
              {typeof onCancel === 'function' && <CloseButton onClick={onCancel}>&times;</CloseButton>}
            </Header>
            <Body>{children}</Body>
          </Content>
        </Layout>
      </ModalPopup>
    </ModalPortal>
  );
};

export default memo(ModalPopupComponent);
