import React, { FC, memo } from 'react';
import { MediaFolderType } from 'src/graphql/generated';
import { MediaItemListModal } from 'src/components/ui-kit/media-item/media-item-list-modal';

import type { AddLogoModalProps } from './events';

export const AddLogoModal: FC<AddLogoModalProps> = ({ onSelectItem, hasLogo }) => {
  return (
    <MediaItemListModal
      className="logos-scrollbar"
      title={"Select event's logo"}
      contentType={MediaFolderType.Logo}
      onSelectItem={onSelectItem}
      okButtonText={`${hasLogo ? 'Change' : 'Add'} logo`}
    />
  );
};

export default memo(AddLogoModal);
