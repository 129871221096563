import styled from 'styled-components';
import CustomButton from 'src/components/ui-kit/buttons';

const Button = styled(CustomButton).attrs({
  type: 'link',
})`
  &.ant-btn {
    display: inline-flex;
    padding: 2px;
    height: auto;
    color: #000000;

    svg {
      font-size: 1.4rem;
    }

    &:hover {
      color: #6473c4;
    }
    &:disabled {
      opacity: 0.35;
    }
  }
`;

export default Button;
