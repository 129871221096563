import styled from 'styled-components';

export const Item = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  line-height: 1.5;
  border-bottom: 1px solid rgba(20, 22, 32, 0.2);

  & > .outdated-icon {
    margin-right: auto;
    margin-left: 0.25rem;
    font-size: 1rem;

    position: relative;
    top: 0.05rem;
  }

  &:last-child {
    border-bottom-width: 0;
  }
`;

export const ItemDesc = styled.div`
  color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 0 0.625rem;

  .ant-btn.ant-btn-primary {
    min-width: 5rem;
  }
`;

export const ItemTitle = styled.div``;
