import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { colorTheme } from 'src/components/ui-kit/theme';

const SpinnerBorder = styled.div`
  &.ant-spin-dot {
    width: 1em;
    height: 1em;
    border: 0.188rem solid ${colorTheme.primary};
    border-right-color: transparent;
    border-radius: 50%;
    transform: rotate(45deg);
    animation: antRotate 1.4s infinite linear;
  }
`;

export const Spinner = styled(Spin).attrs<{ minHeight?: string }>(props => ({
  indicator: <SpinnerBorder />,
}))<{ minHeight?: string }>`
  &.ant-spin {
    min-height: ${props => (props.minHeight ? props.minHeight : '100%')};

    .ant-spin-dot {
      font-size: 2rem;
    }
    &.ant-spin-spinning {
      display: inline-flex;
    }
    &.ant-spin-sm {
      .ant-spin-dot {
        font-size: 1rem;
      }
    }
    &.ant-spin-lg {
      .ant-spin-dot {
        font-size: 3rem;
      }
    }
    &.ant-spin.ant-spin-show-text > .ant-spin-dot {
      margin-top: -1em;
      margin-left: -0.5em;
    }
    .ant-spin-text {
      color: ${colorTheme.primary};
    }
  }
`;

export default Spinner;
