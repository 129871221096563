import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import WebFontLoader from 'webfontloader';
import logger from 'src/helpers/logger';
import styled from 'styled-components';
import Select, { Option } from 'src/components/ui-kit/select';

export const FontLoaderStatuses = {
  inactive: 'inactive',
  active: 'active',
  loading: 'loading',
};

export const useFontLoader = ({ config, onStatus, onFontStatus }) => {
  useEffect(() => {
    WebFontLoader.load({
      ...config,
      loading: () => onStatus(FontLoaderStatuses.loading),
      active: () => onStatus(FontLoaderStatuses.active),
      inactive: () => onStatus(FontLoaderStatuses.inactive),
      fontloading: (font, variation) => onFontStatus(font, variation, FontLoaderStatuses.loading),
      fontactive: (font, variation) => onFontStatus(font, variation, FontLoaderStatuses.active),
      fontinactive: (font, variation) => onFontStatus(font, variation, FontLoaderStatuses.inactive),
    });
  }, []);
};

export const FONT_FAMILIES = [
  'Lato',
  'Montserrat',
  'Open Sans',
  'Playfair Display',
  'Roboto',
  'Lora',
  'Noto Sans',
  'Oswald',
  'Poppins',
  'Rubik',
  'Merriweather',
  'Nunito',
  'PT Sans',
  'Raleway',
  'Ubuntu',
];

export const FontSelectorElement = styled(Select)`
  /* margin: 0 1.875rem 1.25rem; */

  /* &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.5rem;
    height: 2.25rem;
  }

  .ant-select-selector {
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 10px;
    }
  } */
`;

export const FontLoaderConfigType = {
  google: PropTypes.shape({
    families: PropTypes.arrayOf(PropTypes.string),
  }),
};

export const FontLoader = memo(({ children, config }) => {
  const onStatus = useCallback(logger);

  useFontLoader({ onStatus, onFontStatus: onStatus, config });

  return children;
});

FontLoader.propTypes = { config: PropTypes.shape({ ...FontLoaderConfigType }) };
FontLoader.defaultProps = {
  config: {
    google: {
      families: FONT_FAMILIES,
    },
  },
};
FontLoader.displayName = 'Loader';

export const FontSelector = memo(props => {
  const {
    value,
    style,
    className,
    disabled,
    onBlur,
    //  queryVariables
  } = props;
  const [selectedFont, setFont] = useState(value);
  //
  useEffect(() => {
    if (value !== selectedFont) {
      setFont(value);
    }
  }, [value]);
  //
  const onChange = useCallback(
    v => {
      //
      if (typeof props.onChange === 'function') {
        props.onChange(v);
      } else {
        setFont(v);
      }
    },
    [selectedFont],
  );
  // const { data: queryResult } = useQuery(getFontsQuery, { variables: queryVariables });
  // const fonts = queryResult?.getFonts || [];
  //
  return (
    <FontSelectorElement
      disabled={disabled}
      style={style}
      className={className}
      value={selectedFont}
      onBlur={onBlur}
      onChange={onChange}>
      {FONT_FAMILIES.map(family => (
        <Option key={family} value={family}>
          {family}
        </Option>
      ))}
    </FontSelectorElement>
  );
});

FontSelector.displayName = 'FontSelector';
