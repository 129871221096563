import React, { memo, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'src/helpers/time';
import first from 'lodash/first';
import { ViewerContext } from '@fjedi/react-router-helpers';
import { GetQueryLogsQueryVariables, QueryLog, useGetQueryLogsLazyQuery, Viewer } from 'src/graphql/generated';
//
import Table, { TableProps } from 'src/components/ui-kit/table/table';
import { Row, Col } from 'src/components/ui-kit/grid';
import ProjectSelector from 'src/components/common/project-selector';
import { Text } from 'src/components/ui-kit/typography';

function convertOperationNameLabelToUserAction(label: string) {
  switch (label) {
    case 'Logged in':
      return 'Login';
    case 'Logged out':
      return 'Logout';
    default: {
      const action = first(label.split(' ')) ?? '';
      const instanceName = label.replace(action, '').trim();
      const capitalized = instanceName.charAt(0).toUpperCase() + instanceName.slice(1);
      return `${capitalized} ${action.toLowerCase()}`;
    }
  }
}

const OPERATION_NAMES = new Map([
  ['createTemplate', 'Created template'],
  ['removeTemplate', 'Removed template'],

  ['createEvent', 'Created event'],
  ['updateEvent', 'Updated event'],
  ['removeEvent', 'Removed event'],

  ['createPlaylist', 'Created playlist'],
  ['updatePlaylist', 'Updated playlist'],
  ['removePlaylist', 'Removed playlist'],

  ['copyTimeslot', 'Duplicated schedule timeslot'],
  ['createTimeslot', 'Created schedule timeslot'],
  ['updateTimeslot', 'Changed schedule timeslot'],
  ['removeTimeslot', 'Removed schedule timeslot'],

  ['logIn', 'Logged in'],
  ['logOut', 'Logged out'],

  ['createDevice', 'Created device'],
  ['updateDevice', 'Updated device data'],
  ['removeDevice', 'Removed device'],
  ['sendDeviceCommand', 'Sent command to device'],

  ['createSchedule', 'Created schedule'],
  ['updateSchedule', 'Updated schedule'],
  ['removeSchedule', 'Removed schedule'],
  ['pushScheduleToDevices', 'Pushed schedule to devices'],

  ['createProject', 'Created project'],
  ['updateProject', 'Updated project'],
  ['removeProject', 'Removed project'],

  ['createUser', 'Registered new user'],
  ['updateUser', 'Updated user data'],
  ['removeUser', 'Removed user profile'],
  ['updateUserProfile', 'Updated user profile'],
  ['resetPassword', 'Reset password'],

  ['createMediaFolder', 'Created media folder'],
  ['updateMediaFolder', 'Renamed media folder'],
  ['removeMediaFolder', 'Removed media folder'],

  ['createUserRole', 'Granted user-role'],
  ['updateUserRole', 'Updated user-role'],
  ['removeUserRole', 'Removed user-role'],

  // ['createMediaItem', 'Created media item'],
  ['updateMediaItem', 'Updated media item'],
  ['removeMediaItem', 'Removed media item'],
]);

const QueryLogsPage = () => {
  const { t } = useTranslation();
  const viewer = useContext(ViewerContext) as Viewer;
  const [projectId, setProjectId] = useState<Viewer['primaryProjectId'] | null>(viewer.primaryProjectId);

  const variables = useMemo<GetQueryLogsQueryVariables>(
    () => ({ filter: { projectId: projectId ? [projectId] : [] } }),
    [projectId],
  );
  const getQueryLogsQuery = useGetQueryLogsLazyQuery({
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const columns = useMemo<TableProps<QueryLog, GetQueryLogsQueryVariables>['columns']>(
    () => [
      {
        title: t('User'),
        dataIndex: 'user',
        render(_, row) {
          return row?.user?.fullName;
        },
      },
      {
        title: t('Operation'),
        dataIndex: 'operationName',
        filters: [...OPERATION_NAMES.entries()].map(([value, text]) => ({
          text: t(convertOperationNameLabelToUserAction(text)),
          value,
        })),
        filterSearch: true,
        render(operationName: string) {
          return OPERATION_NAMES.get(operationName)?.toLowerCase() ?? operationName;
        },
      },
      {
        title: t('Variables'),
        dataIndex: 'data',
        render(data: QueryLog['data']) {
          return data ? (
            <Text code copyable ellipsis={{ tooltip: true }} style={{ width: '400px' }}>
              {JSON.stringify(data)}
            </Text>
          ) : (
            'No data'
          );
        },
      },
      {
        title: t('IP'),
        dataIndex: 'ip',
      },

      {
        title: t('Project'),
        dataIndex: 'project',
        render(_, row) {
          return row?.project?.name;
        },
      },
      {
        title: t('Date'),
        dataIndex: 'createdAt',
        sorterKey: 'createdAt',
        width: 180,
        sorter: true,
        render(createdAt) {
          return formatDate(createdAt, 'DD.MM.YYYY HH:mm');
        },
      },
    ],
    [t],
  );

  return (
    <Row>
      <ProjectSelector style={{ width: '300px', marginBottom: '1rem' }} value={projectId} onChange={setProjectId} />
      <Col md={{ span: 24 }}>
        <Table
          pageSize={12}
          dataType="QueryLog"
          query={getQueryLogsQuery}
          variables={variables}
          subscriptionQueries={[]}
          rowKey="id"
          columns={columns}
        />
      </Col>
    </Row>
  );
};

QueryLogsPage.propTypes = {};
QueryLogsPage.defaultProps = {};

export default memo(QueryLogsPage);
