import React, { FC, memo, useCallback } from 'react';
import { RestrictedArea } from '@fjedi/react-router-helpers';
import { useTranslation } from 'react-i18next';
import { updateAfterMutation, useApolloError, logger } from '@fjedi/graphql-react-components';
import {
  CreateCompanyInput,
  RemoveCompanyDocument,
  useCreateCompanyMutation,
  useGetCompaniesLazyQuery,
} from 'src/graphql/generated';
import { formatDate } from 'src/helpers/time';
import Table, { filterDropdown, filterIcon } from 'src/components/ui-kit/table/table';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input } from 'src/components/ui-kit/input';
import { Row, Col } from 'src/components/ui-kit/grid';
import Button from 'src/components/ui-kit/buttons';

const CompaniesPage: FC = () => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const [form] = Form.useForm();
  const { resetFields } = form;
  //
  const onCompleted = useCallback(() => {
    resetFields();
  }, [resetFields]);
  //
  const [createCompany, { loading: creatingCompany }] = useCreateCompanyMutation({
    onCompleted,
    onError,
    update: updateAfterMutation('Company', 'getCompanies'),
  });
  const onSubmit = useCallback(
    (formData: unknown) => {
      createCompany({
        variables: {
          input: formData as CreateCompanyInput,
        },
      }).catch(logger);
    },
    [createCompany],
  );
  const getCompaniesQuery = useGetCompaniesLazyQuery({
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  return (
    <Row>
      <Col md={{ span: 24 }}>
        <RestrictedArea areaType="block" allowedRoles={['ADMIN']}>
          <Form form={form} layout="inline" onFinish={onSubmit}>
            <FormItem name="name" label={t('Name')} rules={[{ required: true, message: t('Please fill this field') }]}>
              <Input />
            </FormItem>
            <FormItem>
              <Button style={{ marginTop: 0 }} type="primary" htmlType="submit" loading={creatingCompany}>
                {t('Create')}
              </Button>
            </FormItem>
          </Form>
        </RestrictedArea>
        <Table
          pageSize={12}
          dataType="Company"
          query={getCompaniesQuery}
          subscriptionQueries={
            [
              // companyCreatedSubscription,
              // companyChangedSubscription,
              // companyRemovedSubscription,
            ]
          }
          removeRowMutationDoc={RemoveCompanyDocument}
          removalConfirmationMessage={`${t('Remove')}?`}
          // variables={{ filter: { companyId: [viewer?.primaryCompanyId] } }}
          rowKey="id"
          columns={[
            {
              title: t('Name'),
              dataIndex: 'name',
              sorterKey: 'name',
              sorter: true,
              filterDropdown,
              filterIcon,
            },
            {
              title: t('Date'),
              dataIndex: 'createdAt',
              sorterKey: 'createdAt',
              width: 180,
              sorter: true,
              render(createdAt) {
                return formatDate(createdAt, 'DD.MM.YYYY HH:mm');
              },
            },
          ]}
        />
      </Col>
    </Row>
  );
};

CompaniesPage.displayName = 'CompaniesPage';

export default memo(CompaniesPage);
