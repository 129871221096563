import React, { FC, memo, useCallback } from 'react';
import { useUpdateMediaFolderMutation } from 'src/graphql/generated';
import { useTranslation } from 'react-i18next';
import { useApolloError, logger } from '@fjedi/graphql-react-components';
import styled from 'styled-components';
import { GhostEditableTitle } from 'src/components/ui-kit/typography';
import type { MediaFolderProfile } from 'src/components/ui-kit/media-item/media-item.d';

const TitleContainer = styled.div`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
`;

const Title = styled(GhostEditableTitle)`
  flex-grow: 1;
  padding-right: 0.25rem;

  &.ant-typography.ant-typography {
    font-size: 16px;
  }

  .ant-typography-edit {
    right: 0.25rem;
    opacity: 0;
    color: rgba(0, 0, 0, 0.2);
    font-size: 1.125rem;

    &:hover {
      color: #6a75ca;
    }
  }
`;

const MediaFolderTitle: FC<{ folder: MediaFolderProfile }> = props => {
  const {
    folder: { isDefault, name, id },
  } = props;
  const { t } = useTranslation();
  const onError = useApolloError();
  //
  const [updateMediaFolder] = useUpdateMediaFolderMutation({
    onError,
  });
  const onMediaFolderChange = useCallback(
    (folderId: string) => (folderName: string) => {
      updateMediaFolder({ variables: { id: folderId, input: { name: folderName } } }).catch(logger);
    },
    [updateMediaFolder],
  );
  //
  return (
    <TitleContainer>
      <Title
        level={5}
        isEditable={!isDefault}
        onChange={onMediaFolderChange(id)}
        value={t(name || 'No folder')}
        autoSize={{ maxRows: 2, minRows: 1 }}
        // hideEllipsisTooltip
      />
    </TitleContainer>
  );
};

export default memo(MediaFolderTitle);
