import React from 'react';
import styled from 'styled-components';
import { colorTheme, colorGrays } from 'src/components/ui-kit/theme';
import { Radio as AntRadio } from 'antd';

const theme = {
  borderColor: colorGrays.gray300,
  borderColorChecked: colorTheme.primary,
  backgroundColorChecked: colorTheme.primary,
  backgroundColorDisabled: colorGrays.gray200,
};

export const RadioGroup = styled(AntRadio.Group)`
  display: inline-flex;
`;

export const RadioButton = styled(AntRadio.Button)`
  &.ant-radio-button-wrapper {
    height: 40px;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    color: #6a75ca;
    border-color: #6a75ca;
    padding-left: 22px;
    padding-right: 22px;
    justify-content: center;
    line-height: 1;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-color: #6a75ca;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:not(:first-child)::before {
      background-color: #6a75ca;
      height: auto;
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      &:hover,
      &:active {
        color: #ffffff;
      }
    }
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #6a75ca;
    background-color: #6a75ca;
    color: #ffffff;
  }

  &.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #6a75ca;
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #6a75ca;
  }

  > span + span {
    display: flex;
  }

  svg {
    font-size: 24px;
  }
`;

export const Radio = styled(AntRadio)`
  &.ant-radio-wrapper {
    display: inline-flex;
    align-items: center;

    .ant-radio {
      top: 0;

      &,
      .ant-radio-input,
      .ant-radio-inner {
        height: 1rem;
        width: 1rem;
      }

      .ant-radio-inner {
        width: 1rem;
        background-color: #fff;
        border-color: ${theme.borderColor};

        &:after {
          width: 0.375rem;
          height: 0.375rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #fff;
        }
      }
      .ant-radio-input:hover + .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner {
        border-color: ${theme.borderColorChecked};
      }

      &-disabled .ant-radio-inner {
        border-color: ${theme.borderColor};
        background-color: ${theme.backgroundColorDisabled};
      }
    }

    .ant-radio-checked {
      .ant-radio-inner {
        border-color: ${theme.borderColorChecked};
        background-color: ${theme.backgroundColorChecked};
      }
    }

    p {
      margin: 0 0 0 0.5rem;
    }
  }
`;

export default Radio;
