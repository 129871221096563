import React, { useEffect } from 'react';
import snakeCase from 'lodash/snakeCase';
// Recompose
import { pure, compose, setDisplayName } from 'recompose';
import { useLocation, useNavigate } from '@fjedi/react-router-helpers';
import logger from 'src/helpers/logger';

// GraphQL
import { useMutation } from '@fjedi/graphql-react-components';
import confirmActionMutation from 'src/graphql/mutations/confirm-action.graphql';

const ConfirmActionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [confirmAction] = useMutation(confirmActionMutation);
  //
  useEffect(() => {
    //
    const params = new URLSearchParams(location.search);
    const action = params.get('action');
    const token = params.get('token');
    //
    confirmAction({
      variables: { input: { action: snakeCase(action).toUpperCase(), token } },
    })
      .catch(e => {
        logger(e);
      })
      .then(() => {
        //
        window.location.href = '/';
      });
  }, [location.search]);

  return null;
};

ConfirmActionComponent.propTypes = {};
ConfirmActionComponent.defaultProps = {};

export default compose(setDisplayName('ConfirmAction'), pure)(ConfirmActionComponent);
