import camelCase from 'lodash/camelCase';
import toUpper from 'lodash/toUpper';
//
const formatStringToCamelCase = (str: string) => {
  const splitted = str.split('-');
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map(word => word[0].toUpperCase() + word.slice(1))
      .join('')
  );
};

export const getStyleObjectFromString = (styles: { [k: string]: string | number }) => {
  const convertStyles = Object.keys(styles).map(cssProp => {
    // @ts-ignore
    const formatCssProp = formatStringToCamelCase(cssProp);
    const value: string = typeof styles[cssProp] === 'string' ? styles[cssProp] : `${styles[cssProp]}px`;
    return { key: formatCssProp, value };
  });
  const objectStyles = convertStyles.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
  return objectStyles;
};

export function formatPropForReact(css) {
  if (!css) {
    return {};
  }
  const s = {};
  Object.keys(css).forEach(cssProp => {
    // eslint-disable-next-line security/detect-object-injection
    s[camelCase(cssProp)] = css[cssProp];
  });
  return s;
}

export function pascalCase(str: string) {
  return camelCase(str).replace(/^(.)/, toUpper);
}
