import React, { memo, useCallback, useState, useEffect, CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { GetCompaniesQueryVariables, useGetCompaniesQuery } from 'src/graphql/generated';
import Select, { Option } from 'src/components/ui-kit/select';

export const CompanySelectorElement = styled(Select)`
  min-width: 10rem;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.5rem;
    height: 2.25rem;
  }

  .ant-select-selector {
    &:before {
      content: '';
      /* width: 20px; */
      /* height: 20px; */
      display: block;
      margin-right: 10px;
    }
  }
`;

export type CompanySelectorProps = {
  value?: string | null;
  companyId?: string | null;
  onChange?(_value: string): void;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  groupByCompany?: boolean;
  queryVariables?: GetCompaniesQueryVariables;
};

const CompanySelector: FC<CompanySelectorProps> = memo(props => {
  const { t } = useTranslation();
  const {
    // Get value provided by parent component (if any)
    value,
    // Handle default react input props to be able to disable selectbox and/or set custom styles
    style,
    className,
    disabled,
    // Allow to pass custom query-vars for filtering or pagination of the data returned from API
    queryVariables,
  } = props;
  const [selectedCompany, setCompanyId] = useState(value);

  // if 'value' provided by parent component has been changed
  // we should update value stored inside inner state of the CompanySelector
  useEffect(() => {
    if (value !== selectedCompany) {
      setCompanyId(value);
    }
  }, [value]);

  // Handle 'onChange' event and bypass it to 'onChange' handler from props (if any)
  // if not parent 'onChange' handler provided, just save new value to the inner state of the component
  const onChange = useCallback(
    (v: string) => {
      //
      if (typeof props.onChange === 'function') {
        props.onChange(v);
      } else {
        setCompanyId(v);
      }
    },
    [selectedCompany],
  );
  //
  const { data: queryResult } = useGetCompaniesQuery({ variables: queryVariables });
  const companies = queryResult?.getCompanies?.rows || [];

  return (
    <CompanySelectorElement
      disabled={disabled}
      style={style}
      className={className}
      value={selectedCompany}
      onChange={onChange}
      placeholder={t('Select a company')}
      showSearch
      filterOption={(input, option) =>
        // @ts-ignore
        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }>
      {companies.map(company => (
        <Option key={company.id} value={company.id}>
          {t(company.name)}
        </Option>
      ))}
    </CompanySelectorElement>
  );
});

CompanySelector.displayName = 'CompanySelector';

export default CompanySelector;
