import React, { useCallback, useContext, Fragment, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, ViewerContext } from '@fjedi/react-router-helpers';
import { useTranslation } from 'react-i18next';
// GraphQL Queries
import { logger, updateAfterMutation, useApolloError } from '@fjedi/graphql-react-components';
import semver from 'semver';
import {
  Device,
  DeviceInput,
  Schedule,
  useGetDeviceFirmwaresQuery,
  usePushScheduleToDevicesMutation,
  useRemoveScheduleMutation,
  useUpdateDeviceMutation,
  useUpdateScheduleMutation,
  Viewer,
} from 'src/graphql/generated';
//
import { Col, Modal, Tooltip } from 'antd';
import { RiArrowRightLine, RiDeleteBinLine, RiUploadCloud2Line } from 'react-icons/ri';
import { ContentCard } from 'src/components/ui-kit/admin-layout/content-card';
import RightSider from 'src/components/ui-kit/admin-layout/right-sider';
import { AddScheduleModal } from 'src/components/ui-kit/aside/tabs/schedules';
import Button from 'src/components/ui-kit/buttons';
import { DragDropContext, DraggableItem, DroppableArea, DropResult } from 'src/components/ui-kit/drag-n-drop';
import NoAccess from 'src/components/ui-kit/no-access';
import Popconfirm from 'src/components/ui-kit/popconfirm';
import { FixedScrollbar } from 'src/components/ui-kit/scrollbar';
import { colorTheme } from 'src/components/ui-kit/theme';
import ThumbnailAdd from 'src/components/ui-kit/thumbnail/add';
import DeviceThumbnail from 'src/components/ui-kit/thumbnail/device-thumbnail';
import ThumbnailList from 'src/components/ui-kit/thumbnail/list-thumbnail';
import ScheduleThumbnail from 'src/components/ui-kit/thumbnail/schedule-preview-thumbnail';

import { stopEventPropagation } from 'src/functions';
import Spinner from 'src/components/ui-kit/spinner';
import { useGetSchedulesWithScrollPagination } from './hooks';
import DeviceInfo from '../devices/device-info';

const AddDeviceCard = styled(ThumbnailAdd)``;
const NoAccessContainer = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 50px;
`;

const Content = styled(ContentCard)`
  .ant-card-extra {
    .ant-btn {
      color: ${colorTheme.secondary};
    }
  }

  &.ant-card > .ant-card-body {
    padding-bottom: 0;
  }

  .ant-space > .ant-space-item > .ant-btn {
    color: ${colorTheme.dark};

    &:hover {
      color: hsl(0, 100%, 100%);
    }
  }
`;

export const OnlineOfflineDeviceCounter = styled.div<{ isOnline: boolean }>`
  display: inline-block;
  margin: 0 0.25rem;

  > span {
    color: ${colorTheme.secondary};
    font-size: 0.75rem;
    margin-right: 0.15rem;
    display: inline-flex;
    align-items: center;

    &:after {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: ${props => (props.isOnline ? colorTheme.success : colorTheme.danger)};
      margin: 0 0.25rem;
    }
  }

  & + & {
    margin-left: 0.5rem;
  }
`;

export const ScheduleListFooter = styled.div`
  text-align: left;
  padding: 0 24px;
  color: ${colorTheme.dark};
  font-weight: bold;

  > span {
    margin-right: 0.35rem;
  }
`;

const SchedulesPage = React.memo(() => {
  const viewer = useContext(ViewerContext) as Viewer;
  const projectId = viewer?.primaryProjectId;
  const { t } = useTranslation();
  const onError = useApolloError();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState<string>('');
  const onFilterChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  }, []);

  const {
    schedules,
    onScrollFrame,
    error,
    loading,
    nextPageLoader,
    update: updateCachedSchedules,
  } = useGetSchedulesWithScrollPagination({ filterValue });

  const { data: firmwaresRes } = useGetDeviceFirmwaresQuery({
    skip: !projectId,
    fetchPolicy: 'cache-and-network',
  });
  const firmwares = useMemo(() => firmwaresRes?.getDeviceFirmwares ?? [], [firmwaresRes]);
  const latestFirmwareVersion = useMemo(
    () => firmwares.reduce((latestFirmware, fw) => (semver.gt(latestFirmware, fw) ? latestFirmware : fw), '0.0.0'),
    [firmwares],
  );
  const isOutdated = useCallback(
    (v: unknown) =>
      typeof v === 'string' && semver.valid(latestFirmwareVersion) && semver.valid(v)
        ? semver.gt(latestFirmwareVersion, v)
        : true,
    [latestFirmwareVersion],
  );

  const [selectedDevice, setSelectedDevice] = useState<Pick<Device, 'id' | 'props' | 'version'> | null>(null);
  //
  const [updateDevice] = useUpdateDeviceMutation({
    onError,
    update: updateCachedSchedules,
  });
  const onDeviceChange = useCallback(
    (input: DeviceInput) => {
      if (!selectedDevice) {
        logger('Failed to change device data as no selectedDevice found');
        return;
      }
      updateDevice({ variables: { id: selectedDevice.id, input } }).catch(logger);
    },
    [updateDevice, selectedDevice],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result?.destination) {
        return;
      }
      const id = result.draggableId;
      const [scheduleId] = result.destination.droppableId.split('.');
      updateDevice({
        variables: { id, input: { scheduleId } },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDevice: {
            id,
            __typename: 'Device',
            scheduleId,
            createdAt: undefined,
            updatedAt: undefined,
          },
        },
      }).catch(logger);
    },
    [updateDevice],
  );
  const header = useMemo(
    () => ({
      title: t('Recently added screens'),
      subtitle: t('Some subtitle'),
      actions: [
        {
          render(actionIndex: number) {
            return <AddScheduleModal key={actionIndex} />;
          },
        },
      ],
    }),
    [t],
  );
  const footer = useMemo(() => {
    const { totalDevices, onlineDevices } = viewer.primaryProject?.stats ?? { totalDevices: 0, onlineDevices: 0 };
    const offlineDevices = totalDevices - onlineDevices;

    return [
      <ScheduleListFooter key={`project-${viewer.primaryProject?.id}-stats`}>
        <span>Number of devices:</span>
        {`${totalDevices} (`}
        <OnlineOfflineDeviceCounter isOnline>
          <span>{t('Online')}</span>
          {onlineDevices}
        </OnlineOfflineDeviceCounter>
        <OnlineOfflineDeviceCounter isOnline={false}>
          <span>{t('Offline')}</span>
          {offlineDevices}
        </OnlineOfflineDeviceCounter>
        )
      </ScheduleListFooter>,
    ];
  }, [t, viewer]);
  //
  const [removeSchedule] = useRemoveScheduleMutation({
    onError,
    update: updateAfterMutation('Schedule', 'getSchedules'),
  });
  const [updateSchedule] = useUpdateScheduleMutation({
    onError,
  });

  const onRemove = useCallback(
    (schedule: Pick<Schedule, 'id'>) => (event?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
      event?.stopPropagation();
      Modal.confirm({
        title: t('Warning'),
        content: t(
          'Are you sure you want to remove that Schedule? All devices associated with the schedule will be removed and this action is irreversible',
        ),
        onOk() {
          removeSchedule({
            variables: { id: schedule.id },
          }).catch(logger);
        },
        okText: t('Proceed'),
      });
    },
    [t, removeSchedule],
  );
  const onTitleChange = useCallback(
    (schedule: Pick<Schedule, 'id' | 'name' | 'screenRatio' | 'devices' | 'createdAt'>) => (name: string) => {
      updateSchedule({
        variables: {
          id: schedule.id,
          input: {
            name,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateSchedule: {
            __typename: 'Schedule',
            ...schedule,
          },
        },
      }).catch(logger);
    },
    [updateSchedule],
  );

  const handleDeviceClick = useCallback(
    (device: Pick<Device, 'id' | 'props' | 'version'>) => () =>
      setSelectedDevice(curr => (curr?.id === device?.id ? null : device)),
    [],
  );

  const goToSchedule = useCallback((scheduleId: string) => () => navigate(`/schedules/${scheduleId}`), [navigate]);

  const [pushSchedule, pushScheduleResponse] = usePushScheduleToDevicesMutation();
  const onPushSchedule = useCallback(
    (scheduleId: string) => () => {
      pushSchedule({
        variables: {
          scheduleId,
        },
      }).catch(logger);
    },
    [pushSchedule],
  );

  const renderActions = useCallback(
    // eslint-disable-next-line react/no-unstable-nested-components
    (schedule: Pick<Schedule, 'id'>, id: string) => () => (
      <Fragment>
        <Popconfirm
          placement="bottomRight"
          title={`${t('Remove')}?`}
          onClick={stopEventPropagation}
          onConfirm={onRemove(schedule)}
          okText={t('Yes')}
          cancelText={t('No')}>
          <Tooltip title={t('Remove')} placement="left">
            <Button type="link" size="small" icon={<RiDeleteBinLine />} />
          </Tooltip>
        </Popconfirm>
        <Popconfirm
          placement="bottomRight"
          title={`${t('Push updates to all related devices')}?`}
          onClick={stopEventPropagation}
          onConfirm={onPushSchedule(id)}
          okText={t('Yes')}
          cancelText={t('No')}>
          <Tooltip title={t('Push updates')} placement="bottom">
            <Button type="link" loading={pushScheduleResponse.loading} icon={<RiUploadCloud2Line />} />
          </Tooltip>
        </Popconfirm>
        <AddDeviceCard scheduleId={id} />
        <Tooltip title={t('Schedule editor')}>
          <Button type="link" size="small" icon={<RiArrowRightLine />} onClick={goToSchedule(id)} />
        </Tooltip>
      </Fragment>
    ),
    [goToSchedule, onRemove, t, pushScheduleResponse, onPushSchedule],
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return error?.graphQLErrors[0]?.data?.exception?.status === 403 ? (
    <NoAccessContainer>
      <NoAccess />
    </NoAccessContainer>
  ) : (
    <Content header={header} footer={footer} showBreadcrumbs>
      <DragDropContext onDragEnd={onDragEnd}>
        <RightSider>
          <DeviceInfo
            deviceId={selectedDevice?.id}
            isOutdated={isOutdated(selectedDevice?.version ?? selectedDevice?.props?.version) ?? false}
            onChange={onDeviceChange}
            filterValue={filterValue}
            onFilterChange={onFilterChange}
          />
        </RightSider>
        <FixedScrollbar scrollbarProps={{ onScrollFrame }}>
          <Spinner spinning={loading} style={{ minHeight: '500px' }}>
            {schedules.map(schedule => {
              const { id, name, defaultMediaItem, screenRatio, devices } = schedule;

              return (
                <DroppableArea key={id} droppableId={`${id}.deviceList`} direction="horizontal" isDropDisabled={false}>
                  <ThumbnailList
                    title={name}
                    ratio={screenRatio}
                    onTitleChange={onTitleChange(schedule)}
                    renderActions={renderActions(schedule, id)}>
                    <Col span={6} key={id}>
                      <ScheduleThumbnail scheduleId={id} screenRatio={screenRatio} preview={defaultMediaItem} />
                    </Col>
                    {(devices || []).map((device, dIndex) => (
                      <Col span={6} key={device.id}>
                        <DraggableItem draggableId={device.id} index={dIndex}>
                          <DeviceThumbnail
                            isSelected={device.id === selectedDevice?.id}
                            data={device}
                            isRemovable
                            onClick={handleDeviceClick(device)}
                          />
                        </DraggableItem>
                      </Col>
                    ))}
                  </ThumbnailList>
                </DroppableArea>
              );
            })}
          </Spinner>
          {nextPageLoader}
        </FixedScrollbar>
      </DragDropContext>
    </Content>
  );
});

SchedulesPage.displayName = 'SchedulesPage';

export default SchedulesPage;
