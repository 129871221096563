import React, { useCallback, useContext, useEffect, useState, useMemo, ReactElement, useRef } from 'react';
import { SubscriptionResult, MutationResult, ApolloCache, MutationHookOptions } from '@fjedi/graphql-react-components';
import { useParams, ViewerContext } from '@fjedi/react-router-helpers';
import { updateInnerListOfCachedObject } from 'src/helpers/apollo-cache-helpers';
import {
  GetMediaFoldersDocument,
  GetMediaFoldersQuery,
  GetMediaFoldersQueryResult,
  GetMediaFoldersQueryVariables,
  MediaFolderFragmentDoc,
  MediaFolderType,
  useGetMediaFoldersQuery,
  useMediaItemChangedSubscription,
  useMediaItemCreatedSubscription,
  useMediaItemRemovedSubscription,
  usePlaylistChangedSubscription,
  usePlaylistCreatedSubscription,
  usePlaylistRemovedSubscription,
  useTemplateCreatedSubscription,
  useTemplateRemovedSubscription,
  Viewer,
} from 'src/graphql/generated';
import logger from 'src/helpers/logger';
import { SpinnerComponent } from 'src/components/ui-kit/scroll-pagination';
import { isHTMLElementVisible } from 'src/functions';
import { ScrollContainerUpdateEvent } from 'src/components/ui-kit/scrollbar';

export type ScrollPaginationData = Pick<GetMediaFoldersQueryResult, 'error' | 'loading'> & {
  folders: GetMediaFoldersQuery['getMediaFolders']['rows'];
  onScrollFrame: (_ev: { top: number }) => unknown;
  onScrollContainerUpdate: (_ev: ScrollContainerUpdateEvent) => void;
  page: number;
  loadingNextPage: boolean;
  nextPageLoader: ReactElement;
  variables: GetMediaFoldersQueryVariables;
  update: MutationHookOptions['update'];
};

export function useGetFoldersWithScrollPagination(options?: {
  contentType?: string;
  filterValue?: string;
  foldersPerPage?: number;
}): ScrollPaginationData {
  const viewer = useContext(ViewerContext) as Viewer;
  const projectId = viewer?.primaryProjectId;
  const routeParams = useParams<{ contentType: string }>();
  const contentType = (options?.contentType || routeParams.contentType || 'image').toUpperCase() as MediaFolderType;
  const foldersPerPage = options?.foldersPerPage ?? 4;
  const [page, setPage] = useState<number>(0);
  const [loadingNextPage, setLoadingNextPage] = useState<boolean>(false);

  const variables = useMemo<GetMediaFoldersQueryVariables>(
    () => ({
      filter: {
        projectId: projectId ? [projectId] : undefined,
        type: [contentType],
      },
      pagination: {
        limit: foldersPerPage,
        offset: 0,
      },
    }),
    [projectId, contentType, foldersPerPage],
  );
  const queryRes = useGetMediaFoldersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables,
    context: {
      debounceKey: 'media-folders',
      debounceTimeout: 400,
    },
  });
  const { data: foldersRes, error: foldersError, loading: foldersLoading, fetchMore: fetchMoreFolders } = queryRes;

  useEffect(() => {
    if (!page) {
      return;
    }
    const v = {
      pagination: {
        offset: page * foldersPerPage,
        limit: foldersPerPage,
      },
    };
    setLoadingNextPage(true);
    fetchMoreFolders({
      variables: v,
    })
      .catch(logger)
      .finally(() => setLoadingNextPage(false));
  }, [page, foldersPerPage, fetchMoreFolders]);

  const folders = foldersRes?.getMediaFolders?.rows || [];
  const hasNextPage = foldersRes?.getMediaFolders?.pageInfo?.current < foldersRes?.getMediaFolders?.pageInfo?.total;

  const onScrollFrame = useCallback(
    ({ top }: { top: number }) => {
      if (
        top < 0.8 ||
        foldersLoading ||
        foldersRes?.getMediaFolders?.pageInfo?.current >= foldersRes?.getMediaFolders?.pageInfo?.total
      ) {
        return;
      }
      setPage(foldersRes?.getMediaFolders?.pageInfo?.current ?? page + 1);
    },
    [foldersRes, foldersLoading, page],
  );

  const nextPageLoaderRef = useRef<HTMLDivElement | null>(null);

  // This function tracks if we should load more pages as current number of rows doesn't fill the container
  const onScrollContainerUpdate = useCallback(
    (_event: ScrollContainerUpdateEvent) => {
      if (
        !nextPageLoaderRef?.current ||
        !nextPageLoaderRef.current?.parentElement?.parentElement ||
        foldersLoading ||
        foldersRes?.getMediaFolders?.pageInfo?.current >= foldersRes?.getMediaFolders?.pageInfo?.total
      ) {
        return;
      }
      if (!isHTMLElementVisible(nextPageLoaderRef?.current, nextPageLoaderRef.current?.parentElement?.parentElement)) {
        return;
      }
      setPage(foldersRes?.getMediaFolders?.pageInfo?.current ?? page + 1);
    },
    [foldersRes, foldersLoading, page],
  );

  const update = useSubscribeToMediaFolderItems(variables);

  return {
    error: foldersError,
    loading: !folders?.length && foldersLoading,
    loadingNextPage,
    nextPageLoader: <SpinnerComponent ref={nextPageLoaderRef} loading={loadingNextPage} hasNextPage={hasNextPage} />,
    folders,
    onScrollFrame,
    onScrollContainerUpdate,
    page,
    variables,
    update,
  };
}

export function useSubscribeToMediaFolderItems(
  queryVariables: GetMediaFoldersQueryVariables,
): MutationHookOptions['update'] {
  const viewer = useContext(ViewerContext) as Viewer;
  const projectId = viewer?.primaryProjectId;
  const routeParams = useParams<{ contentType: string }>();
  const contentType = (routeParams.contentType || 'image').toUpperCase() as MediaFolderType;

  const updateCachedFolders = useCallback(
    (cache: ApolloCache<unknown>, result: SubscriptionResult | MutationResult) => {
      updateInnerListOfCachedObject({
        containerDataType: 'MediaFolder',
        dataTypes: ['MediaItem', 'Playlist', 'Template'],
        foreignKey: 'folderId',
        containerFieldName: 'items',
        queryDoc: GetMediaFoldersDocument,
        queryFragmentDoc: MediaFolderFragmentDoc,
        queryVariables,
      })(cache, result);
    },
    [queryVariables],
  );

  useTemplateCreatedSubscription({
    skip: contentType !== 'TEMPLATE',
    variables: { filter: {} },
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedFolders(client.cache, subscriptionData);
    },
  });
  useTemplateCreatedSubscription({
    skip: contentType !== 'TEMPLATE',
    variables: { filter: {} },
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedFolders(client.cache, subscriptionData);
    },
  });
  useTemplateRemovedSubscription({
    skip: contentType !== 'TEMPLATE',
    variables: { filter: {} },
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedFolders(client.cache, subscriptionData);
    },
  });

  usePlaylistCreatedSubscription({
    skip: contentType !== 'PLAYLIST',
    variables: { filter: {} },
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedFolders(client.cache, subscriptionData);
    },
  });
  usePlaylistChangedSubscription({
    skip: contentType !== 'PLAYLIST',
    variables: { filter: {} },
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedFolders(client.cache, subscriptionData);
    },
  });
  usePlaylistRemovedSubscription({
    skip: contentType !== 'PLAYLIST',
    variables: { filter: {} },
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedFolders(client.cache, subscriptionData);
    },
  });

  useMediaItemCreatedSubscription({
    skip: contentType === 'TEMPLATE' || contentType === 'PLAYLIST',
    variables: { filter: { projectId: projectId ? [projectId] : undefined } },
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedFolders(client.cache, subscriptionData);
    },
  });
  useMediaItemChangedSubscription({
    skip: contentType === 'TEMPLATE' || contentType === 'PLAYLIST',
    variables: { filter: { projectId: projectId ? [projectId] : undefined } },
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedFolders(client.cache, subscriptionData);
    },
  });
  useMediaItemRemovedSubscription({
    skip: contentType === 'TEMPLATE' || contentType === 'PLAYLIST',
    variables: { filter: { projectId: projectId ? [projectId] : undefined } },
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedFolders(client.cache, subscriptionData);
    },
  });

  return updateCachedFolders as unknown as MutationHookOptions['update'];
}
