import React, { memo, FC } from 'react';
import styled from 'styled-components';
import { Card as AntCard, CardProps } from 'antd';
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';

export type { CardProps } from 'antd/lib/card/Card';

const CardComponent = styled(AntCard)`
  &.ant-card {
    color: ${colorGrays.gray700};
    border-radius: 0.25rem;
    border-width: 0;
    line-height: 1.4;
    box-shadow: 0 0 2.1875rem 0 rgba(154, 161, 171, 0.15);

    .ant-card-head-title {
      color: ${colorGrays.gray700};
    }

    .ant-card-body {
      padding: 1.5rem;
    }

    &.bg-primary,
    &.bg-secondary,
    &.bg-success,
    &.bg-info,
    &.bg-warning,
    &.bg-danger,
    &.bg-dark {
      color: #ffffff;
      background-color: ${colorTheme.primary};

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: #ffffff;
      }
    }

    &.bg-primary {
      background-color: ${colorTheme.primary};
    }

    &.bg-secondary {
      background-color: ${colorTheme.secondary};
    }

    &.bg-success {
      background-color: ${colorTheme.success};
    }

    &.bg-info {
      background-color: ${colorTheme.info};
    }

    &.bg-warning {
      background-color: ${colorTheme.warning};
    }

    &.bg-danger {
      background-color: ${colorTheme.danger};
    }

    &.bg-light {
      background-color: ${colorTheme.light};
    }

    &.bg-dark {
      background-color: ${colorTheme.dark};
    }
  }

  &.cover-left,
  &.cover-right {
    display: flex;

    .ant-card-cover {
      flex: 0 0 12.5rem;
    }

    .ant-card-body {
      flex: 1;
    }
  }

  &.cover-right {
    flex-direction: row-reverse;
  }

  & {
    .ant-card-head {
      border-color: ${colorGrays.gray200};
    }

    .ant-card-body {
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .ant-card-cover {
      margin: 0;
      img {
        border-radius: 0.25rem;
      }
    }

    &.ant-card-small {
      > .ant-card-head,
      > .ant-card-body {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    .card-link {
      + .card-link {
        margin-left: 1.5rem;
      }
    }

    &.ant-card-hoverable:hover {
      box-shadow: 0 0 2.1875rem 0 rgba(154, 161, 171, 0.5);
    }
  }

  &.ant-card-contain-tabs {
    .ant-card-head {
      padding: 0 1.5rem;
    }
    .ant-card-body {
      > .ant-card {
        margin: calc(-1.5rem + 4px) -1.5rem;
        width: calc(100% + 3rem);
        max-width: calc(100% + 3rem);
      }
    }
  }
  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    color: ${colorGrays.gray700};
    padding: 0.75rem 1.25rem;
    margin-right: 0;

    &:hover,
    &.ant-tabs-tab-active {
      color: ${colorGrays.gray800};
    }
  }
  .ant-tabs-ink-bar {
    background-color: ${colorTheme.primary};
  }
`;

const Card: FC<CardProps> = ({ children, ...props }) => <CardComponent {...props}>{children}</CardComponent>;

export default memo(Card);
