import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { Space, Row } from 'antd';
//
import { GhostEditableTitle, GhostEditableTitleContainer } from 'src/components/ui-kit/typography';
import { Orientation } from 'src/components/ui-kit/thumbnail/device-thumbnail';

const CardHeader = styled.div`
  min-height: 3.25rem;
  padding: 0.5rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Orientation} {
    background-color: #666;
    color: #fff;
    font-size: 0.7rem;
    margin-right: 0.35rem;

    &:after {
      display: none;
    }
  }

  .ant-btn {
    margin-right: 0.5rem;

    &:not(.ant-btn-icon-only) {
      min-width: 7.5rem;
    }

    &.ant-btn-icon-only {
      padding: 0;
      min-width: 2rem;
      margin-right: 0.25rem;
      color: #c4c4c4;

      &:hover {
        background-color: #6473c4;
        color: #ffffff;
      }

      &:last-child {
        margin-right: 0;
      }

      svg {
        font-size: 1.25rem;
      }
    }
  }
`;
const Card = styled.div`
  background-color: #ffffff;
  max-width: 100%;
  /* min-height: 13.375rem; */
  position: relative;
  border-radius: 0.625rem;
  box-shadow: 0 0.125rem 0.875rem rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1.25rem;
  transition: box-shadow 0.2s;

  &:hover,
  &:focus {
    box-shadow: 0 0.125rem 0.875rem rgba(0, 0, 0, 0.08);
  }

  .ant-typography-edit {
    color: rgba(0, 0, 0, 0.4);
  }

  > ${CardHeader} > ${GhostEditableTitleContainer}.ant-typography.ant-typography {
    margin-top: 0;
  }
`;

const Title = styled(GhostEditableTitle)`
  flex-grow: 1;

  &.ant-typography.ant-typography {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
`;
const CardActions = styled.div.attrs({ className: 'card-actions' })`
  display: flex;
  align-items: center;

  & > .ant-space {
    display: flex;
    align-items: stretch;

    & > .ant-space-item {
      display: flex;
      align-items: center;

      & > .ant-btn {
        height: 2rem;
        padding: 0.3125rem;
      }
    }
  }
`;
const CardBody = styled.div`
  padding: 0 1rem 1.25rem;

  .ant-row {
    margin-top: -1rem;
  }
  .ant-col {
    margin-top: 1rem;
  }
  .thumbnail-card {
    max-width: 100%;
    min-width: 100%;
  }
`;

export type ListThumbnailProps = {
  title: string;
  ratio?: string;
  onTitleChange: (_t: string) => void;
  renderActions?: () => React.ReactElement;
  children: React.ReactNode;
};

const ListThumbnail: FC<ListThumbnailProps> = ({ title, ratio, onTitleChange, children, renderActions }) => (
  <Card>
    <CardHeader>
      {ratio && <Orientation>{ratio}</Orientation>}
      <Title level={5} value={title} onChange={onTitleChange} />
      <CardActions>
        <Space>{typeof renderActions === 'function' ? renderActions() : renderActions}</Space>
      </CardActions>
    </CardHeader>
    <CardBody>
      <Row gutter={16}>{children}</Row>
    </CardBody>
  </Card>
);

export default memo(ListThumbnail);
