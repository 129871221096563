import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
// Recompose
import { pure, compose, branch, renderNothing, getContext, setDisplayName } from 'recompose';
import { formType, ViewerType, rolePropType } from 'src/store/prop-types';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import logger from 'src/helpers/logger';
import { useTranslation } from 'react-i18next';

// GraphQL
import { Mutation } from '@fjedi/graphql-react-components';

// Layout
import { Modal, Divider, Tag } from 'antd';
import { Row, Col } from 'src/components/ui-kit/grid';
import { Paragraph } from 'src/components/ui-kit/typography';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input, MaskedInput } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';

const CompanyMainPage = props => {
  const { viewer, viewerRole } = props;
  const isAdmin = viewerRole !== 'ADMIN';
  const { firstName, lastName, middleName, phoneNumber, email } = viewer;
  //
  const { t } = useTranslation();
  const onSubmit = useCallback(mutation => input => {
    //
    logger(input);
    //
    mutation({
      variables: { id: viewer.id, input },
    });
  });

  return (
    <Row>
      <Col md={{ span: 8, offset: 8 }}>
        <Paragraph centered>Here will be company profile</Paragraph>
      </Col>
    </Row>
  );
};

CompanyMainPage.propTypes = {
  viewer: ViewerType.isRequired,
  viewerRole: rolePropType.isRequired,
};
CompanyMainPage.defaultProps = {};

export default compose(
  setDisplayName('CompanyMainTab'),
  getContext({
    // Получаем из контекста об авторизованном пользователе,
    viewer: ViewerType,
    viewerRole: rolePropType,
  }),
  // Hide component if no data provided
  branch(({ viewer }) => !viewer, renderNothing),
  pure,
)(CompanyMainPage);
