import { useMemo } from 'react';

export const TEMPLATE_TYPES = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  IFRAME: 'IFRAME',
  DATETIME: 'DATETIME',
  YOUTUBE: 'YOUTUBE',
  HLS: 'HLS',
  MULTICAST: 'MULTICAST',
  WEATHER: 'WEATHER',
  CLOCK: 'CLOCK',
  PLAYLIST: 'PLAYLIST',
  LOCATION_EVENT_LOGO: 'LOCATION_EVENT_LOGO',
  LOCATION_HEADER: 'LOCATION_HEADER',
  LOCATION_EVENTS: 'LOCATION_EVENTS',
  LOCATION_WAYFINDING: 'LOCATION_WAYFINDING',
  FLIGHTS: 'FLIGHTS',
} as const;

export const SINGLE_INSTANCE_TEMPLATE_TYPES = [
  TEMPLATE_TYPES.VIDEO,
  TEMPLATE_TYPES.MULTICAST,
  TEMPLATE_TYPES.PLAYLIST,
  TEMPLATE_TYPES.HLS,
] as const;

export type Resolution = string | { width: number; height: number };
export type Ratio = '16:9' | '8:5' | '4:3' | '3:2';
export type RatioOptions = Record<Ratio, Array<{ label: string; value: string }>>;

export const DEFAULT_RATIO = '16:9' as Ratio;
export const DIMENSIONS: RatioOptions = {
  '16:9': [
    { label: '1920x1080', value: '1920:1080' },
    { label: '1600x900', value: '1600:900' },
    { label: '1280x720', value: '1280:720' },
    { label: '960x540', value: '960:540' },
    { label: '848x480', value: '848:480' },
    { label: '640x360', value: '640:360' },
  ],
  '8:5': [
    { label: '1920x1200', value: '1920:1200' },
    { label: '1536x960', value: '1536:960' },
    { label: '1440x900', value: '1440:900' },
    { label: '640x240', value: '640:240' },
  ],
  '4:3': [
    { label: '1600x1200', value: '1600:1200' },
    { label: '1400x1050', value: '1400:1050' },
    { label: '1152x864', value: '1152:864' },
    { label: '1024x768', value: '1024:768' },
    { label: '800x600', value: '800:600' },
    { label: '640x480', value: '640:480' },
  ],
  '3:2': [{ label: '1536x1024', value: '1536:1024' }],
};

export const DEFAULT_AREA_STYLE = {
  position: 'absolute',
  overflow: 'hidden',
  top: 0,
  left: 0,
  width: 200,
  height: 200,
} as const;
