import React, { FC, memo, useCallback, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useNavigate } from '@fjedi/react-router-helpers';

import Button from '.';

const BackButton: FC = () => {
  const [isCardRendered, setIsCardRendered] = useState(false);
  const navigate = useNavigate();

  const handleClick = useCallback(() => navigate(-1), [navigate]);

  useLayoutEffect(
    () => setIsCardRendered(!!document.getElementsByClassName('ant-card-head-title').length),
    [setIsCardRendered],
  );

  return isCardRendered
    ? // && history.length > 3
      ReactDOM.createPortal(
        <Button type="link" icon={<RiArrowLeftSLine />} onClick={handleClick} />,
        document.getElementsByClassName('ant-card-head-title')[0],
      )
    : null;
};

export default memo(BackButton) as typeof BackButton;
