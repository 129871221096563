import React, { createContext } from 'react';
import { GetTemplateQuery, ScreenRatio } from 'src/graphql/generated';

interface ComponentSize {
  width: number;
  height: number;
}

export const TemplateContext = createContext<GetTemplateQuery['getTemplate'] | undefined>(undefined);

export type EditorContextState = { ratio?: ScreenRatio; template?: GetTemplateQuery['getTemplate'] };
export const EditorContext = createContext<EditorContextState>({} as EditorContextState);

export const EditorMenuContext = createContext(null);

export const EditorSizeContext = createContext<{ container: ComponentSize; canvas: ComponentSize; scale: number }>({
  container: { width: 0, height: 0 },
  canvas: { width: 0, height: 0 },
  scale: 1,
});
