import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TimePicker, TimeRangePickerProps } from 'antd';
import { RiCloseLine, RiSettings5Line } from 'react-icons/ri';
import { arrayMove } from 'src/helpers/array-move';
import time, { TimeInstance } from 'src/helpers/time';
// GraphQL
import { UpdateTimeslotMutation, useRemoveTimeslotMutation } from 'src/graphql/generated';
import { updateAfterMutation, logger, useApolloError } from '@fjedi/graphql-react-components';
//
import { DragDropContext, DroppableArea, DraggableItem, DropResult } from 'src/components/ui-kit/drag-n-drop';
import DrawerCustom from 'src/components/ui-kit/drawer';
import Scrollbar from 'src/components/ui-kit/scrollbar';
import Popconfirm from 'src/components/ui-kit/popconfirm';
import MediaThumbnail from 'src/components/ui-kit/thumbnail/media-thumbnail';
import Tooltip from 'src/components/ui-kit/tooltip';
import { CopyIcon } from 'src/components/ui-kit/icons';
import CustomButton from 'src/components/ui-kit/buttons';
import { colorGrays } from 'src/components/ui-kit/theme';
import type { EventProps } from 'react-big-calendar';
import { stopEventPropagation } from 'src/functions';
import TimeslotItemDetails from './calendar-timeslot-details';
import type { ScheduleItem } from '../schedules.d';
import { CalendarContext } from './context';
import { EVENT_TIMESLOT_COULD_NOT_BE_EDITED_DIRECTLY } from './helpers';

const Divider = styled.div`
  height: 0.5rem;
  min-height: 0.5rem;
  margin-top: -0.5rem;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    top: -0.5rem;
  }
`;

const Button = styled(CustomButton).attrs({
  type: 'link',
})`
  &.ant-btn.ant-btn-icon-only {
    width: auto;
    height: auto;
    display: inline-flex;
    padding: 0.125rem;
  }
`;

export const EventButtons = styled.div<{ isResizable: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: ${props => (props.isResizable ? '-0.875rem' : 0)};
  padding: 0.625rem 0.8125rem 0.15rem;
  z-index: 200;

  .ant-btn {
    color: rgba(255, 255, 255, 0.8);
    background-color: transparent;
    border: 0;
    margin-left: 0.25rem;

    svg {
      font-size: 1.15rem;
    }

    &.remove {
      padding: 0;

      svg {
        font-size: 1.375rem;
      }
    }

    &:hover {
      &.ant-btn {
        color: rgba(255, 255, 255, 0.85);
      }
    }

    &:focus {
      &.ant-btn {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
`;

const EventItems = styled.div.attrs({ className: 'event-items-container' })<{ isTight: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 2.5rem);
  max-width: 100%;
  padding-bottom: ${({ isTight }) => (isTight ? '0.125rem' : 0)};

  & > .media-thumbnail-container {
    position: relative;
    flex-shrink: 0;

    height: 100%;
    max-height: 10rem;
    min-height: 3rem;
    margin-bottom: 0.625rem;
    margin-right: 0.75rem;

    border-radius: 10px;
    overflow: hidden;

    & > .ant-spin-nested-loading,
    & > .tooltip-container {
      height: 100%;
      max-height: 100%;
      min-height: 3px;

      .thumbnail-card,
      .ant-spin-container {
        height: 100%;
      }
    }
  }
`;

const TimeslotDetailsHeader = styled.div<{ withAllDayButton?: boolean }>`
  height: ${props => (props.withAllDayButton ? '7rem' : '3.8rem')};
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 2rem 0;

  .ant-picker {
    display: flex;
    align-items: center;
    padding: 0.625rem;
  }

  .ant-picker-input {
    white-space: nowrap;
    flex: 0;
    input {
      min-width: 2.375rem;
      width: 2.375rem;
      font-weight: 600;
    }
    &:first-child:before {
      content: 'Start time:';
      padding-right: 0.5rem;
    }
    ~ .ant-picker-input {
      &:before {
        content: 'End time';
        padding-right: 0.5rem;
      }
    }
  }

  .ant-picker-range-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    .anticon {
      display: none;
    }
  }

  .ant-picker-separator {
    display: block;
    height: 1.75rem;
    width: 0.0625rem;
    background-color: rgba(0, 0, 0, 0.12);
  }
  .ant-picker-range .ant-picker-clear {
    right: 1rem;
  }
`;

const GlobalStyle = createGlobalStyle`
  .ant-picker-panel-container {
    border-radius: 0.625rem;

    .ant-btn {
      border-radius: 0.25rem;

    }
  }
`;

const Drawer = styled(DrawerCustom)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;

const FixScrollbar = styled.div`
  overflow: hidden;
  flex-grow: 1;
`;

const OverflowTooltip = styled.p`
  color: ${colorGrays.gray500};
  margin: 0.5rem 0 0.875rem;

  .settings-icon {
    position: relative;
    top: 0.15rem;
    margin: 0 0.25rem;
  }
`;

const OverflowCounter = styled.h1`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  min-height: 3rem;
  max-height: 100%;
  max-width: 100%;
  z-index: 10;

  background-color: hsla(0, 0%, 0%, 0.5);

  font-weight: bold;
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
  color: #fff;
`;

const SetToAllDayButton = styled(CustomButton).attrs({ type: 'primary' })`
  display: block;
`;

const TimeslotComponent: React.FC<EventProps<ScheduleItem>> = ({ event }) => {
  const { currentDate, updateTimeslot, onEventResize } = useContext(CalendarContext);
  const id = event?.resource.id;

  const isAllDayTimeslot = useMemo(() => {
    const { isAllDay } = event?.resource ?? {};
    return isAllDay ?? false;
  }, [event]);

  const isEventTimeslot = useMemo(() => {
    const { eventId } = event?.resource ?? {};
    return !!eventId ?? false;
  }, [event]);

  const items = useMemo(() => sortBy(event?.resource?.items || [], 'sequence'), [event?.resource?.items]);

  const zoom = useMemo(() => event?.resource?.zoom ?? true, [event?.resource?.zoom]);
  const containerId = useMemo(() => {
    if (id) {
      return `${id}-items-container`;
    }

    return undefined;
  }, [id]);

  const [visibleItemsCount, setVisibleItemsCount] = useState(items.length);
  const [isTight, setIsTight] = useState(false);

  const updateVisibleItemsCount = useCallback(() => {
    let visibleCount = items.length;
    const mediaItemDimensions = { width: 252, height: 170, minHeight: 50 };
    const container = containerId && document.getElementById(containerId);

    if (container) {
      const { clientHeight, clientWidth } = container;
      const horizontal = (clientWidth + 12) / mediaItemDimensions.width;
      const vertical = (clientHeight + 10) / mediaItemDimensions.height;

      const isHorizontalCritical = clientHeight < mediaItemDimensions.minHeight;

      setIsTight(vertical < 1);

      const hCapacity = Math.floor(horizontal);
      const vCapacity = isHorizontalCritical ? 0 : Math.floor(vertical) || 1;

      if (clientHeight) {
        visibleCount = hCapacity * vCapacity;
      }
    }

    setVisibleItemsCount(visibleCount);
  }, [containerId, items]);

  const allItemsShown = useMemo(() => items.length <= visibleItemsCount, [items.length, visibleItemsCount]);
  const overflowCount = useMemo(
    () => (allItemsShown ? 0 : items.length - visibleItemsCount + 1),
    [allItemsShown, items.length, visibleItemsCount],
  );

  useEffect(() => {
    const resizeWatcher = () => updateVisibleItemsCount();
    window.addEventListener('resize', resizeWatcher);

    if (event && zoom) {
      updateVisibleItemsCount();
    }

    return () => {
      window.removeEventListener('resize', resizeWatcher);
    };
  }, [event, items, updateVisibleItemsCount, zoom]);

  const { t } = useTranslation();
  const onError = useApolloError();

  const [isDetailsVisible, setDetailsVisibility] = useState(false);

  const onDetailsOpen = useCallback(() => setDetailsVisibility(true), []);
  const onDetailsClose = useCallback(() => setDetailsVisibility(false), []);

  const [removeTimeslot, removeResult] = useRemoveTimeslotMutation({
    update: updateAfterMutation('Timeslot', 'getTimeslots'),
    onError,
  });
  const onRemoveTimeslot = useCallback(() => {
    removeTimeslot({
      variables: { id },
    }).catch(logger);
  }, [removeTimeslot, id]);

  const onChangePeriod = useCallback(
    (period: [TimeInstance, TimeInstance]) => {
      const [start, end] = period;

      onEventResize({ event, start, end, id });

      updateVisibleItemsCount();
    },
    [id, event, onEventResize, updateVisibleItemsCount],
  );

  const onItemRemove = useCallback(
    (itemId: string) => () => {
      updateTimeslot({
        variables: {
          id,
          input: {
            removedItems: [itemId],
          },
        },
      }).catch(logger);
    },
    [id, updateTimeslot],
  );
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result) {
        return;
      }
      const { destination, source } = result;
      if (source.droppableId !== destination?.droppableId) {
        return;
      }
      const updatedItems = arrayMove(items, source.index - 1, destination.index - 1).map((item, itemIndex) => ({
        id: item.id,
        sequence: itemIndex + 1,
      }));
      const optimisticResponse = {
        __typename: 'Mutation',
        updateTimeslot: {
          id,
          __typename: 'Timeslot',
          items: updatedItems.map(i => ({ __typename: 'TimeslotItem', ...i })),
        },
      } as UpdateTimeslotMutation;
      //
      updateTimeslot({
        variables: {
          id,
          input: {
            updatedItems,
          },
        },
        optimisticResponse,
      })
        .catch(logger)
        .then(updateVisibleItemsCount);
    },
    [items, updateTimeslot, id, updateVisibleItemsCount],
  );

  const data = useMemo(
    () => ({
      type: 'timeslot',
      data: items,
    }),
    [items],
  );

  // eslint-disable-next-line no-underscore-dangle, react/prop-types
  const isRemovable = useMemo(() => event.resource.type !== 'EVENTS', [event.resource.type]);

  const visibleItems = useMemo(() => items.slice(0, visibleItemsCount), [items, visibleItemsCount]);
  const overflowTooltipText = useMemo(
    () => (
      <OverflowTooltip>
        {t('Click')}
        <RiSettings5Line className="settings-icon" />
        {t('icon to see all media items and adjust their duration')}
      </OverflowTooltip>
    ),
    [t],
  );

  const handleCopy = useCallback(
    (_: string, success: boolean) => {
      try {
        navigator?.clipboard?.writeText(JSON.stringify(data)).then(() => {
          if (typeof event?.resource?.onCopy === 'function') {
            event?.resource?.onCopy(success);
          }
        });
      } catch (err) {
        logger(err as Error);
      }
    },
    [data, event?.resource],
  );

  const setAllDayStatus = useCallback(() => {
    const start = time(currentDate).startOf('day').add(1, 'second');
    const end = time(currentDate).endOf('day').subtract(1, 'second');

    onEventResize({ event, start, end, id });
  }, [id, event, currentDate, onEventResize]);
  //
  return (
    <>
      <EventButtons isResizable={event.resizable}>
        {isRemovable && (
          <Button type="link" onClick={setAllDayStatus} disabled={isAllDayTimeslot}>
            <span style={{ color: isAllDayTimeslot ? '#6A75CA' : '#ffffff' }}>{t('All day')}</span>
          </Button>
        )}
        {isRemovable && (
          <Tooltip title={t('Copy')}>
            <CopyToClipboard text={JSON.stringify(data)} onCopy={handleCopy}>
              <Button icon={<CopyIcon size="1.125rem" />} />
            </CopyToClipboard>
          </Tooltip>
        )}
        <Tooltip title={t('Timeslot settings')}>
          <Button type="link" onClick={onDetailsOpen} icon={<RiSettings5Line />} />
        </Tooltip>
        {isRemovable && (
          <Popconfirm
            placement="bottomRight"
            title={`${t('Remove')}?`}
            onClick={stopEventPropagation}
            onConfirm={onRemoveTimeslot}
            okText={t('Yes')}
            cancelText={t('No')}>
            <Tooltip title={t('Remove timeslot')}>
              <Button type="link" loading={removeResult.loading} className="remove" icon={<RiCloseLine />} />
            </Tooltip>
          </Popconfirm>
        )}
      </EventButtons>
      {overflowTooltipText}
      <EventItems id={containerId} isTight={isTight}>
        {visibleItems.map((item, index) => {
          const { mediaItem, template, playlist } = item;

          if (!mediaItem && !template && !playlist) {
            return null;
          }

          const hasOverflowCounter = index === visibleItemsCount - 1 && !allItemsShown;

          return (
            <div key={item.id} className="media-thumbnail-container">
              {hasOverflowCounter ? (
                <>
                  <OverflowCounter onClick={onDetailsOpen}>{`+${overflowCount}`}</OverflowCounter>
                  <MediaThumbnail
                    className="overflow-indicator"
                    key={item.id}
                    data={mediaItem ?? playlist ?? template!}
                    showMediaTypeIcon
                    showFooter={false}
                    showPreview={false}
                    showCopy={false}
                    showControls={false}
                    isEditable={false}
                    isRemovable={false}
                  />
                </>
              ) : (
                <MediaThumbnail
                  data={mediaItem || playlist || template!}
                  showMediaTypeIcon
                  isEditable={false}
                  onRemove={onItemRemove(item.id)}
                />
              )}
              ;
            </div>
          );
        })}
      </EventItems>
      <Drawer width={360} placement="right" closable={false} onClose={onDetailsClose} visible={isDetailsVisible}>
        <GlobalStyle />
        <TimeslotDetailsHeader withAllDayButton={!isEventTimeslot}>
          <Tooltip
            title={t(EVENT_TIMESLOT_COULD_NOT_BE_EDITED_DIRECTLY)}
            trigger="hover"
            placement="bottomLeft"
            open={isEventTimeslot ? undefined : false}>
            <TimePicker.RangePicker
              inputReadOnly
              bordered={false}
              disabled={isEventTimeslot}
              format="HH:mm"
              onChange={onChangePeriod as unknown as TimeRangePickerProps['onChange']}
              value={[time(event.start), time(event.end)]}
            />
          </Tooltip>
          {!isEventTimeslot && (
            <SetToAllDayButton onClick={setAllDayStatus} disabled={isAllDayTimeslot}>
              {t('Set to all day')}
            </SetToAllDayButton>
          )}
        </TimeslotDetailsHeader>
        <Divider />
        <DragDropContext onDragEnd={onDragEnd}>
          <FixScrollbar>
            <Scrollbar>
              <DroppableArea droppableId="timeslot-items">
                {items.map(item => {
                  const { mediaItem, template, playlist, sequence } = item;
                  if (!mediaItem && !template && !playlist) {
                    return null;
                  }
                  return (
                    <DraggableItem key={item.id} draggableId={item.id} index={sequence}>
                      <TimeslotItemDetails key={item.id} itemId={item.id} onRemove={onItemRemove(item.id)} />
                    </DraggableItem>
                  );
                })}
              </DroppableArea>
            </Scrollbar>
          </FixScrollbar>
        </DragDropContext>
      </Drawer>
    </>
  );
};
TimeslotComponent.displayName = 'TimeslotComponent';

export default memo(TimeslotComponent);
