import React, { memo, FC } from 'react';
import { formatDate } from 'src/helpers/time';
import { useTimeTickerHook, WidgetProps } from 'src/components/routes/private/template-editor/widgets/utils';

const h1Style = {
  color: 'inherit',
  fontSize: 'inherit',
};

const DateTime: FC<WidgetProps> = ({ item }) => {
  const timezone = item?.props?.timezone;
  const format = item?.props?.format;
  const date = useTimeTickerHook(timezone);

  return <h1 style={h1Style}>{formatDate(date, format, true)}</h1>;
};

DateTime.displayName = 'DateTime';

export default memo(DateTime);
