/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
//
import { RiAddLine, RiDeleteBinLine, RiSubtractLine } from 'react-icons/ri';
import { Button as AntButton, Image as AntImage, Tooltip } from 'antd';
import Popconfirm from 'src/components/ui-kit/popconfirm';
import Spinner from 'src/components/ui-kit/spinner';
import Link from 'src/components/ui-kit/buttons/link';
//
import backgroundImage from 'src/components/ui-kit/thumbnail/photos.svg';
import cardHover from 'src/components/ui-kit/thumbnail/card-hover.svg';
import { stopEventPropagation } from 'src/functions';

export const CardHoverButton = styled(Link)`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  position: absolute;
  background: #6a75ca url(${cardHover}) no-repeat center;
  background-size: 1.125rem;
  opacity: 0;
  transition: opacity 0.2s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Card = styled.div.attrs({
  className: 'thumbnail-card',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border-radius: 1.25rem;
  overflow: hidden;
  background-color: #000000;
  min-width: 240px;
  opacity: ${props => (props.isSelected ? 1 : 0.2)};
  transition: opacity 0.2s;

  &:before {
    display: block;
    content: '';
    padding-top: 67%;
  }

  // &:after {
  //   content: '';
  //   height: 40px;
  //   width: 40px;
  //   border-radius: 50%;
  //   position: absolute;
  //   background: #6a75ca url(${cardHover}) no-repeat center;
  //   pointer-events: none;
  //   opacity: 0;
  //   transition: opacity 0.2s;
  // }

  &:hover {
    ${CardHoverButton} {
      opacity: 1;
    }
  }

  + .thumbnail__type-gradient {
    margin-top: 10px;
  }

  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .ant-image {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    /* display: block;
    position: absolute;
    cursor: pointer;
    height: 100%;
    width: 100%; */
  }

  .ant-image-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Image = styled(AntImage)``;

export const Body = styled.div.attrs({
  className: 'thumbnail-body',
})`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 0.625rem 0 0.875rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);

  ${Card}.thumbnail__type-gradient & {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 40%, #000000 100%);
  }

  & > .thumbnail-actions {
    position: static;
    display: flex;
    align-items: center;
    padding-bottom: 0.0625rem;

    & > .ant-btn {
      width: auto;
      padding: 0.125rem;
    }
  }

  & > .thumbnail-actions,
  & > .thumbnail-text {
    .ant-typography > .ant-typography-edit,
    .ant-btn {
      color: transparent;

      &:hover {
        color: #fff;
      }
    }
  }
`;

export const Text = styled.div.attrs({ className: 'thumbnail-text' })`
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > h5.ant-typography.ant-typography.ant-typography.ant-typography {
    //width: 100%;
    //min-width: 100%;
    max-width: 99%;
    margin-top: 0.1rem;
  }
`;

export const Title = styled.div`
  font-size: 0.875rem;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Desc = styled.div`
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.4);
  font-weight: 600;
`;

export const Button = styled(AntButton).attrs({
  type: 'link',
})`
  &.ant-btn {
    color: rgba(255, 255, 255, 0.4);
    display: inline-flex;
    padding: 0.1875rem;
    height: auto;

    svg {
      font-size: 1.125rem;
    }

    &.ant-btn__zoom {
      padding-left: 0;
      padding-right: 0;
      svg {
        font-size: 1.625rem;
      }
    }
  }

  ${Card} & {
    &:hover {
      &.ant-btn {
        color: rgba(255, 255, 255, 0.85);
      }
    }
    &:focus {
      &.ant-btn {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
`;

export const Thumbnail = p => {
  const {
    title,
    desc,
    src,
    href,
    gradient,
    showPreview,
    loading,
    isSelected,
    onRemove,
    onZoomUp,
    onZoomDown,
    ...props
  } = p;
  const { t } = useTranslation();
  //
  const typeClass = gradient ? 'thumbnail__type-gradient' : '';

  return (
    <Spinner spinning={loading}>
      <Card className={typeClass} isSelected={isSelected} {...props}>
        <Image src={src} preview={showPreview} />
        <Body>
          <Text>
            {title && <Title>{title}</Title>}
            {desc && <Desc>{desc}</Desc>}
          </Text>
          {onZoomUp && (
            <Button onClick={onZoomUp}>
              <RiSubtractLine />
            </Button>
          )}
          {onZoomDown && (
            <Button onClick={onZoomDown}>
              <RiAddLine />
            </Button>
          )}
          {typeof onRemove === 'function' && (
            <Popconfirm
              placement="topRight"
              title={`${t('Remove')}?`}
              onClick={stopEventPropagation}
              onConfirm={onRemove}
              okText={t('Yes')}
              cancelText={t('No')}>
              <Tooltip title={t('Remove')} placement="right">
                <Button>
                  <RiDeleteBinLine />
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
        </Body>
        {href && <CardHoverButton to={href} />}
      </Card>
    </Spinner>
  );
};

Thumbnail.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  href: PropTypes.string,
  gradient: PropTypes.bool,
  type: PropTypes.string,
  showPreview: PropTypes.bool,
  isSelected: PropTypes.bool,
  onRemove: PropTypes.func,
  onZoomUp: PropTypes.func,
  onZoomDown: PropTypes.func,
  loading: PropTypes.bool,
};
Thumbnail.defaultProps = {
  title: '',
  desc: '',
  src: backgroundImage,
  href: '',
  gradient: false,
  type: '',
  showPreview: false,
  isSelected: true,
  onRemove: undefined,
  onZoomUp: undefined,
  onZoomDown: undefined,
  loading: false,
};

export default Thumbnail;
