import React, { FC, useMemo } from 'react';
import { WidgetProps } from 'src/components/routes/private/template-editor/widgets/utils';
import CircleClock from './circle';
import AnalogClock from './analog';
import SlimClock from './slim';

const Clock: FC<WidgetProps> = ({ item, style }) => {
  const skin = item?.props?.skin;
  const content = useMemo(() => {
    switch (skin) {
      case 'Analog':
        return <AnalogClock item={item} style={style} />;
      case 'Slim':
        return <SlimClock item={item} style={style} />;
      default:
        return <CircleClock item={item} style={style} />;
    }
  }, [item, style, skin]);

  return content;
};

export default Clock;
