import React, { useMemo } from 'react';

import omit from 'lodash/omit';
import CircleClock from './circle';
import AnalogClock from './analog';
import SlimClock from './slim';

const blacklistStyle = ['backgroundColor'];

const Clock = ({ item, style }) => {
  const skin = item && item.props ? item.props.skin : null;
  const clockStyle = omit(style, blacklistStyle);
  const content = useMemo(() => {
    switch (skin) {
      case 'Analog':
        return <AnalogClock item={item} style={clockStyle} />;
      case 'Slim':
        return <SlimClock item={item} style={clockStyle} />;
      default:
        return <CircleClock item={item} style={clockStyle} />;
    }
  }, [item, style]);

  return content;
};

export default Clock;
