import React, { FC, memo, useCallback } from 'react';
import { MediaFolderItem, MediaFolderType, Template as TemplateType } from 'src/graphql/generated';
import { MediaItemListModal } from 'src/components/ui-kit/media-item/media-item-list-modal';

import type { AddTemplateModalProps } from './events';

export const AddTemplateModal: FC<AddTemplateModalProps> = ({ onSelect, hasTemplate }) => {
  const handleTemplateSelect = useCallback(
    (item: MediaFolderItem) => {
      if (typeof onSelect === 'function') {
        onSelect(item as TemplateType);
      }
    },
    [onSelect],
  );

  return (
    <MediaItemListModal
      title={"Select event's template"}
      className="templates-scrollbar"
      contentType={MediaFolderType.Template}
      onSelectItem={handleTemplateSelect}
      okButtonText={`${hasTemplate ? 'Change' : 'Add'} template`}
    />
  );
};

export default memo(AddTemplateModal) as typeof AddTemplateModal;
