export const DEFAULT_TIMESLOT_ITEM_DURATION = 60000;
export const MIN_TIMESLOT_ITEM_DURATION = 3000;

export const weekdays: { [k: number]: string } = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};
