import * as Types from '../types/main';

export function getNextItem<T extends Types.TimeslotItemType | Types.PlaylistItemType>(
  listMedia: Array<T>,
  onPlaybackEnd?: () => void,
): () => T {
  let currentIndex = 1;
  return () => {
    const nextItem = listMedia[currentIndex];
    if (!nextItem) {
      if (typeof onPlaybackEnd === 'function') {
        onPlaybackEnd();
      }
      currentIndex = 1;
      return listMedia[0];
    }
    currentIndex += 1;
    return nextItem;
  };
}

export default getNextItem;
