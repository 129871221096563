import React from 'react';
import { Cloudinary } from 'cloudinary-core';
import { CLOUDINARY_CLOUDNAME } from 'src/constants';

const cl = new Cloudinary({
  cloud_name: CLOUDINARY_CLOUDNAME,
  secure: true,
});

export function getRemoteMediaURL(id, params) {
  return cl.url(id, params);
}

export { Image, Video, Transformation } from 'cloudinary-react';
