import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from '@fjedi/react-router-helpers';
import { useTranslation } from 'react-i18next';
import { CLOUDINARY_CLOUDNAME } from 'src/constants';
//
import { time, formatDate } from 'src/helpers/time';
// GraphQL Queries
import {
  updateAfterMutation,
  useSubscription,
  useApolloClient,
  Query,
  useQuery,
  useMutation,
  useApolloError,
  logger,
} from '@fjedi/graphql-react-components';
import getDeviceQuery from 'src/graphql/queries/get-device.graphql';
import getDevicesQuery from 'src/graphql/queries/get-devices.graphql';
import getFullScheduleTreeQuery from 'src/graphql/queries/get-full-schedule-tree.graphql';
import getScreenRatiosQuery from 'src/graphql/queries/get-screen-ratios.graphql';
import updateDeviceMutation from 'src/graphql/mutations/update-device.graphql';
import removeDeviceMutation from 'src/graphql/mutations/remove-device.graphql';
import deviceChangedSubscription from 'src/graphql/subscriptions/device-changed.graphql';
//
import { RiArrowLeftSLine, RiDeleteBinLine } from 'react-icons/ri';
import { ContentCard } from 'src/components/ui-kit/admin-layout/content-card';
import RightSider from 'src/components/ui-kit/admin-layout/right-sider';
import DeviceThumbnail from 'src/components/ui-kit/thumbnail/device-thumbnail';
import { Video as RemoteVideo } from 'src/components/ui-kit/remote-media';

import defaultPreview from 'static/images/default-preview.jpg';
import playImage from 'static/images/play.png';
import { Schedule } from 'src/player/schedule';
import { Scrollbar, StyledScrollbar, FixScrollbar } from 'src/components/ui-kit/scrollbar';
import DeviceInfo from './device-info';

const MainContainer = styled.div`
  height: ${({ h }) => h}px;
  display: flex;
  flex-direction: column;

  ${FixScrollbar} {
    height: 100%;
  }

  ${StyledScrollbar} {
    > div {
      display: flex;
      flex-direction: column;
    }
  }
`;

const Body = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 1);
  /* flex-grow: 1; */
  border-radius: 20px;
  overflow: hidden;
  min-height: 14rem;
`;

const BodyThumbnail = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 11rem;
`;

const PlayerContainer = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  width: ${({ w, h, v }) => (v ? h : w)}px;
  height: ${({ w, h, v }) => (v ? w : h)}px;
`;

const Player = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: ${({ size, v }) => size && `${v ? size.baseHeight : size.baseWidth}px`};
  height: ${({ size, v }) => size && `${v ? size.baseWidth : size.baseHeight}px`};
  transform: scale(${({ previewScale }) => `${previewScale}`});
  transform-origin: left top;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;

const PlayButton = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;

const PlayIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const ThumbnailList = styled.div`
  display: flex;
  margin-top: 0.875rem;
  margin-bottom: 1.5rem;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  flex-wrap: wrap;
`;

const ThumbnailCol = styled.div`
  padding: 0.625rem;
  max-width: 60%;
  flex: 0 0 60%;

  @media screen and (min-width: 768px) {
    max-width: 37%;
    flex: 0 0 37%;
  }

  @media screen and (min-width: 992px) {
    max-width: 23%;
    flex: 0 0 23%;
  }
`;

const OCCUPIED_HEIGHT = 450;

const DevicePage = () => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const apolloClient = useApolloClient();
  const schedule = useMemo(
    () =>
      new Schedule({
        time,
        apolloClient,
      }),
    [apolloClient],
  );
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const onDeviceRemoved = useCallback(() => {
    logger('Device has been successfully removed');
    navigate(-1);
  }, [navigate]);

  const { data: deviceRes } = useQuery(getDeviceQuery, {
    variables: { id: deviceId },
    skip: !deviceId,
  });

  useSubscription(deviceChangedSubscription, {
    variables: {
      filter: { id: [deviceId] },
    },
    skip: !deviceId,
  });
  //
  const [removeDevice] = useMutation(removeDeviceMutation, {
    onError,
    onCompleted: onDeviceRemoved,
    variables: { id: deviceId },
    update: updateAfterMutation('Device', 'getDevices'),
  });
  const [updateDevice] = useMutation(updateDeviceMutation, {
    onError,
  });
  const onChange = useCallback(
    input => {
      //
      updateDevice({
        variables: {
          id: deviceId,
          input,
        },
      }).catch(logger);
    },
    [updateDevice, deviceId],
  );
  //
  const device = deviceRes?.getDevice;

  const { data: getScreenRatiosRes } = useQuery(getScreenRatiosQuery, {
    skip: !device,
  });
  const { data: scheduleRes, loading } = useQuery(getFullScheduleTreeQuery, {
    variables: { scheduleId: device?.schedule.id, weekOfYear: time().isoWeek() },
    skip: !device,
  });
  const playerRef = useRef();
  const [isPlaying, setPlaying] = useState(false);
  useEffect(() => {
    schedule?.stop();
    return () => {
      schedule?.stop();
    };
  }, [schedule]);

  const isVert = useMemo(() => {
    if (device) {
      return device.props.orientation === 90 || device.props.orientation === 270;
    }
    return false;
  }, [device]);

  const onPlayPreview = useCallback(() => {
    if (!schedule.isPlaying) {
      schedule?.parse(scheduleRes.getFullScheduleTree);
      schedule?.play(time().format('dddd'), { rootElement: playerRef.current, ratio: device.ratio, isVert });
    } else {
      schedule?.stop();
    }

    setPlaying(schedule.isPlaying);
  }, [device, scheduleRes, isVert, schedule]);

  if (!device) {
    return null;
  }

  const {
    ratio,
    schedule: { projectId, defaultMediaItem },
  } = device;
  const [rw, rh] = ratio.split(':');
  const unit = Math.floor((window.innerHeight - OCCUPIED_HEIGHT) / (isVert ? rw : rh));

  const iw = rw * unit;
  const ih = rh * unit;

  let size = { baseWidth: iw, baseHeight: ih };
  let scale = 1;
  if (getScreenRatiosRes && getScreenRatiosRes.getScreenRatios) {
    size = getScreenRatiosRes.getScreenRatios.find(({ id }) => id === device.ratio);
    scale = iw / size.baseWidth;
  }

  const isVideo = (defaultMediaItem && defaultMediaItem.type) === 'VIDEO';
  let previewSrc = defaultMediaItem ? defaultMediaItem.url : defaultPreview;
  if (isVideo) {
    previewSrc = `${projectId}/${defaultMediaItem.fileId}`;
  }

  return (
    <ContentCard
      showBreadcrumbs
      header={{
        title: device?.name || device?.id,
        subtitle: formatDate(device?.updatedAt),
        actions: [
          {
            shape: 'circle',
            type: 'light',
            icon: <RiArrowLeftSLine />,
            onClick() {
              navigate(-1);
            },
          },
          {
            shape: 'circle',
            type: 'light',
            icon: <RiDeleteBinLine />,
            confirmationText: t('Are you sure you want to remove that {{instanceType}}?', { instanceType: 'device' }),
            onConfirm: removeDevice,
          },
        ],
      }}>
      <RightSider>
        <DeviceInfo data={device} onChange={onChange} />
      </RightSider>
      <MainContainer h={ih}>
        <Scrollbar>
          <Body>
            <PlayerContainer w={iw} h={ih} v={isVert}>
              {!isPlaying && (
                <>
                  {isVideo && (
                    <RemoteVideo
                      style={{ width: iw, height: ih }}
                      cloudName={CLOUDINARY_CLOUDNAME}
                      publicId={previewSrc}
                    />
                  )}
                  {!isVideo && <Image src={previewSrc} />}
                </>
              )}
              <Player ref={playerRef} size={size} previewScale={scale} v={isVert} />
            </PlayerContainer>
            <PlayButton onClick={onPlayPreview}>{!isPlaying && <PlayIcon src={playImage} />}</PlayButton>
          </Body>
          <BodyThumbnail>
            <Scrollbar>
              <ThumbnailList>
                <Query
                  skip={!device}
                  query={getDevicesQuery}
                  variables={{ filter: { scheduleId: [device?.scheduleId] } }}>
                  {({ data: getDevices }) => {
                    const devices = getDevices?.getDevices?.rows || [];
                    return devices.map(r => (
                      <ThumbnailCol key={r.id}>
                        <DeviceThumbnail data={r} isRemovable isLink />
                      </ThumbnailCol>
                    ));
                  }}
                </Query>
              </ThumbnailList>
            </Scrollbar>
          </BodyThumbnail>
        </Scrollbar>
      </MainContainer>
    </ContentCard>
  );
};

DevicePage.propTypes = {};
DevicePage.defaultProps = {};
DevicePage.displayName = 'DevicePage';

export default React.memo(DevicePage);
