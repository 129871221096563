import { createContext } from 'react';

export const NOTIFICATION_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
} as const;

export type NotificationType = typeof NOTIFICATION_TYPES[keyof typeof NOTIFICATION_TYPES];
type Timeout = ReturnType<typeof setTimeout>;

export interface NotificationOptions {
  type: NotificationType;
}

export interface NotificationInstance extends Required<NotificationOptions> {
  message: string;
  key: string;
  timeout: Timeout;
}

export interface NotificationsContextType {
  notifications: NotificationInstance[];
  notify(_message: string, _options?: NotificationOptions): void;
}

export const notificationsContext = createContext<NotificationsContextType>({} as NotificationsContextType);
const { Provider } = notificationsContext;

export { Provider as NotificationsContextProvider };
