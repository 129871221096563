import React, { FC, memo, useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import type { SelectProps } from 'antd';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input } from 'src/components/ui-kit/input';
import CustomSelect, { Option } from 'src/components/ui-kit/select';
import Modal from 'src/components/ui-kit/modal-popup';
import Button from 'src/components/ui-kit/buttons';
import logger from 'src/helpers/logger';
import { CreateEventLocationInput, UpdateEventLocationInput, useGetDevicesQuery } from 'src/graphql/generated';
import type { ModalProps, Screen, EventLocation } from './events';
import { LocationModalContext } from './location-modal-context';

const Select: FC<SelectProps<string>> = CustomSelect;

const FormFooter = styled.footer`
  display: flex;
  justify-content: center;
  padding-top: 0.875rem;

  .ant-btn {
    width: 8.75rem;
    margin: 0 0.625rem 0;
  }
`;

export const AddLocationModal: FC<ModalProps> = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { isVisible, location, onSubmit } = useContext(LocationModalContext);

  const { t } = useTranslation();

  const { data, loading } = useGetDevicesQuery({
    variables: {
      filter: {},
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const screens: Screen[] = useMemo(() => data?.getDevices?.rows ?? [], [data]);

  const initialValues = useMemo<CreateEventLocationInput | UpdateEventLocationInput>(
    () =>
      location
        ? {
            name: location.name,
            devices: location.devices,
            operaFunctionSpaceCode: location.operaFunctionSpaceCode,
          }
        : { name: '', operaFunctionSpaceCode: null, devices: [] },
    [location],
  );

  const [form] = Form.useForm<{ name: string; devices: string[] }>();
  const { resetFields } = form;

  const onCancel = useCallback(() => {
    resetFields();
    onClose();
  }, [onClose, resetFields]);

  const addOrUpdateLocation = useCallback(
    (values: unknown) => {
      setIsLoading(true);

      onSubmit(values as EventLocation)
        .then(res => {
          if (res && !res.errors) {
            logger('Successfully created/updated location: ', { res });
            onCancel();
          }

          logger('Error creating/updating location: ', res?.errors);
        })
        .catch(logger)
        .finally(() => setIsLoading(false));
    },
    [onSubmit, onCancel],
  );

  useLayoutEffect(() => {
    resetFields();
  }, [location, resetFields]);

  return (
    <Modal
      isVisible={isVisible}
      onCancel={onCancel}
      title={t(`${location ? 'Edit' : 'Add'} location`)}
      width={440}
      closeOnBgClick>
      <Form
        layout="vertical"
        form={form}
        name={`${location ? 'edit' : 'add'}-${location ? location.id : 'location'}-form`}
        onFinish={addOrUpdateLocation}
        initialValues={initialValues}>
        <FormItem
          id={location ? `${location.id}-name` : 'location-name'}
          label={t('Name')}
          name="name"
          rules={[{ required: true, message: t('Please fill this field') }]}>
          <Input placeholder={t('Enter location name')} />
        </FormItem>
        <FormItem
          id={location ? `${location.id}-opera-function-space-code` : 'location-opera-function-space-code'}
          label={t('Opera function space code')}
          name="operaFunctionSpaceCode">
          <Input placeholder={t('Enter space code')} />
        </FormItem>
        <FormItem id={location ? `${location.id}-devices` : 'location-devices'} label={t('Devices')} name="devices">
          <Select mode="multiple" allowClear placeholder={t('Select devices')} loading={loading}>
            {screens.map(({ id, name }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormFooter>
          <Button type="default" htmlType="button" onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t(`${location ? 'Save' : 'Add'}`)}
          </Button>
        </FormFooter>
      </Form>
    </Modal>
  );
};

export default memo(AddLocationModal);
