import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react';
import { RestrictedArea, ViewerContext, ViewerRoleContext, ViewerRoleProvider } from '@fjedi/react-router-helpers';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'src/helpers/time';
import { getRole } from 'src/functions';
// GraphQL Queries
import { logger, updateAfterMutation, useApolloError } from '@fjedi/graphql-react-components';
import {
  Company,
  CreateProjectInput,
  GetProjectsQueryVariables,
  Project,
  RemoveProjectDocument,
  UpdateProjectInput,
  useCreateProjectMutation,
  useGetProjectsLazyQuery,
  UserRoleRole,
  useUpdateProjectMutation,
  Viewer,
} from 'src/graphql/generated';
//
import Table, { TableProps, filterDropdown, filterIcon } from 'src/components/ui-kit/table/table';
import { TimezoneSelector } from 'src/components/common/timezone-selector';
import CompanySelector from 'src/components/common/company-selector';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input } from 'src/components/ui-kit/input';
import { Col, Row } from 'src/components/ui-kit/grid';
import Button from 'src/components/ui-kit/buttons';

const ProjectsPage: FC = () => {
  const viewer = useContext(ViewerContext) as Viewer;
  const viewerRole = useContext(ViewerRoleContext) as UserRoleRole;
  const { t } = useTranslation();
  const onError = useApolloError();
  const [form] = Form.useForm();
  const { resetFields } = form;
  const [_selectedCompany, setSelectedCompany] = useState<Company['id'] | null>(null);
  const { role, adminInCompanies, adminInProjects } = getRole({ viewer, viewerRole });
  const companyQueryVars = useMemo(
    () => (viewerRole !== UserRoleRole.Admin ? { filter: { id: adminInCompanies.map(c => c.id) } } : {}),
    [viewerRole, adminInCompanies],
  );
  //
  const onValuesChange = useCallback((changedValues: { companyId: string }) => {
    if (changedValues.companyId) {
      setSelectedCompany(changedValues.companyId);
    }
  }, []);
  //
  const onCompleted = useCallback(() => {
    resetFields();
  }, [resetFields]);
  //
  const [createProject, { loading: creatingProject }] = useCreateProjectMutation({
    onCompleted,
    onError,
    update: updateAfterMutation('Project', 'getProjects'),
  });

  const onSubmit = useCallback(
    (formData: unknown) => {
      createProject({
        variables: {
          input: formData as CreateProjectInput,
        },
      }).catch(logger);
    },
    [createProject],
  );
  const [updateProject] = useUpdateProjectMutation({
    onError,
  });
  const onChangeField = useCallback(
    (projectId: string, input: UpdateProjectInput) => {
      updateProject({ variables: { id: projectId, input } }).catch(logger);
    },
    [updateProject],
  );
  const getProjectsQuery = useGetProjectsLazyQuery({
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const columns = useMemo<TableProps<Project, GetProjectsQueryVariables>['columns']>(
    () => [
      {
        title: t('Company'),
        dataIndex: 'company',
        columnKey: 'companyId',
        filterDropdown,
        filterIcon,
        removable(record: Project) {
          return (
            viewerRole === UserRoleRole.Admin ||
            adminInCompanies.some(c => c.id === record.company.id) ||
            adminInProjects.some(p => p.id === record.id)
          );
        },
        render(_, row) {
          return row.company?.name || '';
        },
      },
      {
        title: t('Name'),
        dataIndex: 'name',
        columnKey: 'name',
        sorter: true,
        filterDropdown,
        filterIcon,
      },
      {
        title: t('Devices'),
        dataIndex: 'stats',
        render(_, row) {
          return row.stats.totalDevices || 0;
        },
      },
      {
        title: t('Timezone'),
        dataIndex: 'timezone',
        width: 200,
        onChangeField,
        editable(record) {
          return (
            viewerRole === UserRoleRole.Admin ||
            adminInCompanies.some(c => c.id === record.company.id) ||
            adminInProjects.some(p => p.id === record.id)
          );
        },
        inputAlwaysVisible: true,
        input: TimezoneSelector,
        inputProps(record) {
          return {
            initialValue: record?.timezone,
          };
        },
      },
      {
        title: t('Date'),
        dataIndex: 'createdAt',
        sorterKey: 'createdAt',
        width: 180,
        sorter: true,
        render(createdAt) {
          return formatDate(createdAt, 'DD.MM.YYYY HH:mm');
        },
      },
    ],
    [adminInCompanies, adminInProjects, onChangeField, t, viewerRole],
  );

  return (
    <Row>
      <Col md={{ span: 24 }}>
        <ViewerRoleProvider value={role}>
          <RestrictedArea areaType="block" allowedRoles={['ADMIN']}>
            <Form
              form={form}
              layout="inline"
              onFinish={onSubmit}
              onValuesChange={onValuesChange}
              initialValues={{ companyId: adminInCompanies?.[0]?.id }}>
              <FormItem
                name="companyId"
                label={t('Company')}
                rules={[{ required: true, message: t('Please fill this field') }]}>
                <CompanySelector queryVariables={companyQueryVars} />
              </FormItem>
              <FormItem
                name="name"
                label={t('Name')}
                rules={[{ required: true, message: t('Please fill this field') }]}>
                <Input />
              </FormItem>
              <FormItem
                name="timezone"
                label={t('Timezone')}
                rules={[{ required: true, message: t('Please select timezone of the project') }]}>
                <TimezoneSelector />
              </FormItem>
              <FormItem>
                <Button style={{ marginTop: 0 }} type="primary" htmlType="submit" loading={creatingProject}>
                  {t('Create')}
                </Button>
              </FormItem>
            </Form>
          </RestrictedArea>
        </ViewerRoleProvider>
        <Table
          rowKey="id"
          columns={columns}
          pageSize={9}
          dataType="Project"
          query={getProjectsQuery}
          subscriptionQueries={
            [
              // projectCreatedSubscription,
              // projectChangedSubscription,
              // projectRemovedSubscription,
            ]
          }
          removeRowMutationDoc={RemoveProjectDocument}
          removalConfirmationMessage={`${t('Remove')}?`}
          // variables={{ filter: { companyId: [viewer.primaryCompanyId!] } }}
        />
      </Col>
    </Row>
  );
};

export default memo(ProjectsPage);
