import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { logger, updateAfterMutation, useApolloError } from '@fjedi/graphql-react-components';
import { Form } from 'antd';
import { RiAddLine } from 'react-icons/ri';
import {
  GetScreenRatiosQueryVariables,
  ScheduleInput,
  ScreenOrientation,
  useCreateScheduleMutation,
} from 'src/graphql/generated';
import { Input } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';
import ButtonModalPopupComponent from 'src/components/ui-kit/modal-popup/button-modal';
import { ScreenRatioSelector } from 'src/components/common/screen-ratio-selector';

const FooterModal = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.875rem;

  .ant-btn {
    width: 8.75rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const AddScheduleModal = React.memo(() => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const [isCreateScheduleModalVisible, setCreateScheduleModalVisibility] = useState(false);
  const openCreateScheduleModal = useCallback(() => setCreateScheduleModalVisibility(true), []);
  const closeCreateScheduleModal = useCallback(() => setCreateScheduleModalVisibility(false), []);
  //
  const [form] = Form.useForm();
  const { resetFields } = form;
  //
  const onScheduleCreated = useCallback(() => {
    resetFields();
    closeCreateScheduleModal();
  }, [resetFields, closeCreateScheduleModal]);
  const [createSchedule, { loading }] = useCreateScheduleMutation({
    update: updateAfterMutation('Schedule', 'getSchedules'),
    onCompleted: onScheduleCreated,
    onError,
  });
  const initialValues = useMemo(() => ({ name: '', screenRatio: '16:9' }), []);
  const onScheduleSubmit = useCallback(
    (input: ScheduleInput) => {
      createSchedule({
        variables: {
          input,
        },
      }).catch(logger);
    },
    [createSchedule],
  );
  //
  return (
    <ButtonModalPopupComponent
      isVisible={isCreateScheduleModalVisible}
      beforeOpen={openCreateScheduleModal}
      beforeClose={closeCreateScheduleModal}
      buttonType="link"
      tooltip={{ title: t('Create new schedule'), align: { offset: [0, 10] } }}
      title={t('Create new schedule')}
      width={480}
      buttonIcon={<RiAddLine />}>
      <Form layout="vertical" form={form} initialValues={initialValues} onFinish={onScheduleSubmit}>
        <Form.Item label={t('Name')} name="name" rules={[{ required: true, message: t('Please fill this field') }]}>
          <Input placeholder={t('Enter schedule name')} />
        </Form.Item>
        <Form.Item
          label={t('Dimensions')}
          name="screenRatio"
          rules={[{ required: true, message: t('Please select screen ratio') }]}>
          <ScreenRatioSelector groupLabel={false} />
        </Form.Item>
        <FooterModal>
          <Button type="default" htmlType="button" onClick={closeCreateScheduleModal}>
            {t('Cancel')}
          </Button>
          <Button type="primary" loading={loading} htmlType="submit">
            {t('Add')}
          </Button>
        </FooterModal>
      </Form>
    </ButtonModalPopupComponent>
  );
});
