import React from 'react';
import styled from 'styled-components';
import { Space as AntSpace } from 'antd';

export const Space = styled(AntSpace)`
  &.ant-space.ant-space-vertical {
    width: 100%;
  }
`;

export default Space;
