import React, { memo } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Select as AntSelect, SelectProps } from 'antd';
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';
import searchBgIcon from 'src/components/ui-kit/layout/map.svg';
import { darkenColor } from '../utilities';

export type { SelectProps } from 'antd';

const theme = {
  color: '#000000',
  borderColor: 'rgba(0, 0, 0, 0.2)',
  borderColorFocus: darkenColor(colorGrays.gray300, 10),
};

const GlobalStyle = createGlobalStyle`
  div.ant-select-dropdown {
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
    border-width: 0;
    padding: 0;

    .ant-select-item-option {
      color: #6c757d;
      font-size: 14px;
      font-weight: 400;
      transition: color 0.2s;
      padding: .375rem 1.5rem;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      color: #272e37;
      text-decoration: none;
      background-color: #f8f9fa;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: #ffffff;
      font-weight: normal;
      background-color: #6A75CA;
    }

    .ant-select-item-group {
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

const Container = styled(AntSelect)`
  &.ant-select {
    color: ${theme.color};

    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: ${theme.borderColorFocus};
    }

    .ant-select-selector {
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: 1.5;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
    &.ant-select-sm {
      &.ant-select-single .ant-select-selector {
        height: 2rem;
      }
    }
  }
  }
  & {
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 0.5rem;
      height: 2rem;
      display: flex;
      align-items: center;
      border-color: ${theme.borderColor};

      .ant-select-selection-search-input {
        height: 100%;
      }
    }
    &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-color: ${theme.borderColorFocus};
        box-shadow: none;
      }
    }

    &.ant-select-multiple {
      .ant-select-selector {
        border-radius: 0.25rem;
        padding: 4px 4px 4px 8px;
      }
      .ant-select-selection-item {
        color: #ffffff;
        height: 22px;
        margin: 3px 6px 3px 0;
        border-radius: 0.25rem;
        align-items: center;
        border: 0 solid transparent;
        background-color: ${colorTheme.primary};

        .anticon {
          color: #ffffff;
          padding: 1px;
        }
      }

      &.ant-select-focused {
        box-shadow: none;
      }
    }

    &.ant-select-show-search {
      &.ant-select-single .ant-select-selector {
        //height: 40px;
        background-color: #f7f8fc;
        border-color: #ffffff;
        color: #000000;

        &:before {
          content: '';
          width: 20px;
          height: 20px;
          display: block;
          margin-right: 10px;
          background: url(${searchBgIcon}) no-repeat;
        }

        .ant-select-selection-search-input {
          height: 100%;
        }

        .ant-select-selection-search {
          left: 42px;
        }

        .ant-select-selection-item {
          height: 100%;
          line-height: 1.5;
          display: flex;
          align-items: center;
        }
      }
      &:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #ffffff;
      }
      &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) {
        .ant-select-selector {
          border-color: #ffffff;
        }
      }
    }
  }
` as typeof AntSelect;

const Select: React.FC<SelectProps> = ({ children, ...props }) => (
  <>
    <GlobalStyle />
    <Container {...props}>{children}</Container>
  </>
);

export default memo(Select);

export const Option = styled(AntSelect.Option)``;
export const OptGroup = styled(AntSelect.OptGroup)``;
