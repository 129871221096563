import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
// GraphQL
import { Mutation } from '@fjedi/graphql-react-components';
//
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import viewerQuery from 'src/graphql/queries/viewer.graphql';
// GraphQL Mutations
import logoutMutation from 'src/graphql/mutations/log-out.graphql';
//
// CSS

const StyledButton = styled(Button)`
  background-color: inherit;

  &.ant-btn,
  &.ant-btn:hover,
  &.ant-btn:focus {
    //padding: 0 8px;
    background: none;
    box-shadow: none;
    border: 0;
  }

  &.ant-btn.ant-btn-loading {
    opacity: 0.8;
    pointer-events: none;
  }

  &.ant-btn.ant-btn-loading:before {
    display: none;
  }
`;

const LogoutButton = ({ updateAfterMutation, onCompleted, onError, className, style, children, t }) => (
  <Mutation mutation={logoutMutation} update={updateAfterMutation} onCompleted={onCompleted} onError={onError}>
    {(mutation, { loading }) => (
      <StyledButton loading={loading} onClick={mutation} style={style} className={className}>
        {children || t('Log out')}
      </StyledButton>
    )}
  </Mutation>
);

LogoutButton.propTypes = {
  t: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  updateAfterMutation: PropTypes.func.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

LogoutButton.defaultProps = {
  className: '',
  children: '',
  style: {},
};

export default compose(
  withTranslation(),
  withHandlers({
    updateAfterMutation:
      () =>
      (cache, { data: { logOut } }) => {
        const commonProps = {
          query: viewerQuery,
          variables: {},
        };
        //
        const { viewer } = cache.readQuery(commonProps);
        if (viewer) {
          //
          cache.writeQuery({
            ...commonProps,
            data: {
              viewer: null,
            },
          });
          window.location.href = '/';
        }
      },
    onCompleted: () => () => {
      //
      if (window.OneSignal && typeof window.OneSignal.sendTags === 'function') {
        //
        window.OneSignal.sendTags({
          user_id: 0,
          email: '',
          company_id: '',
          first_name: '',
          last_name: '',
          real_name: '',
        });
      }
    },
    onError:
      ({ t }) =>
      error => {
        Modal.error({
          title: t('Error'),
          content: error.message,
        });
      },
  }),
  pure,
)(LogoutButton);
