import openWeatherLightIcons from './openweatherlight';
import openWeatherDarkIcons from './openweatherdark';
import simpleBlackIcons from './simple-black';
import simpleWhiteIcons from './simple-white';

type WeatherIconsSetType = 'OPEN_WEATHER_DARK' | 'OPEN_WEATHER_LIGHT' | 'SIMPLE_WHITE' | 'SIMPLE_BLACK';
export type IconType =
  | '01d'
  | '01n'
  | '02d'
  | '02n'
  | '03d'
  | '03n'
  | '04d'
  | '04n'
  | '09d'
  | '09n'
  | '10d'
  | '10n'
  | '11d'
  | '11n'
  | '13d'
  | '13n'
  | '50d'
  | '50n';

const DEFAULT_ICON = '01n';

function getWeatherIcon(setType: WeatherIconsSetType, icon: IconType) {
  switch (setType) {
    case 'OPEN_WEATHER_LIGHT':
      return openWeatherLightIcons[icon] || openWeatherLightIcons[DEFAULT_ICON];
    case 'OPEN_WEATHER_DARK':
      return openWeatherDarkIcons[icon] || openWeatherDarkIcons[DEFAULT_ICON];
    case 'SIMPLE_WHITE':
      return simpleWhiteIcons[icon] || simpleWhiteIcons[DEFAULT_ICON];
    case 'SIMPLE_BLACK':
      return simpleBlackIcons[icon] || simpleBlackIcons[DEFAULT_ICON];
    default:
      return openWeatherLightIcons[icon] || openWeatherLightIcons[DEFAULT_ICON];
  }
}

export default getWeatherIcon;
