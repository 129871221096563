import React, { FC, MouseEvent, memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logger from 'src/helpers/logger';
import { stopEventPropagation } from 'src/functions';
import { useApolloClient, useApolloError } from '@fjedi/graphql-react-components';
import { TimeslotItem, TimeslotItemFragmentDoc, useUpdateTimeslotMutation } from 'src/graphql/generated';
import {
  RiDeleteBinLine,
  RiSubtractLine,
  RiAddLine,
  RiImageLine,
  RiPlayList2Line,
  RiLayoutMasonryLine,
  RiFilmLine,
} from 'react-icons/ri';
import { Button as AntButton, Tooltip } from 'antd';
import Popconfirm from 'src/components/ui-kit/popconfirm';
import { InputNumber } from 'src/components/ui-kit/input';
import MediaThumbnail from 'src/components/ui-kit/thumbnail/media-thumbnail';

const Button = styled(AntButton).attrs({
  type: 'link',
})`
  &.ant-btn {
    display: inline-flex;
    padding: 2px;
    height: auto;

    svg {
      font-size: 1.4rem;
    }
  }
`;

//
const DetailsCard = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  &:last-child {
    margin-bottom: 1rem;
  }
`;
const DetailsHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 3rem 0 3rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const DetailsIcon = styled.div`
  color: rgba(0, 0, 0, 0.2);
  font-size: 20px;
  display: flex;
  margin-right: 10px;
`;

const DetailsTitle = styled.h4`
  margin-bottom: 0;
  margin-right: auto;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const DetailsBody = styled.div`
  padding: 10px 3rem 4px;
`;

const DetailsFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;

  ${Button} {
    svg {
      font-size: 26px;
    }
  }
`;
const Time = styled.div`
  min-width: 5rem;
  text-align: center;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimeValue = styled(InputNumber)`
  padding-right: 0.25em;

  &.ant-input-number {
    border: 0;
    max-width: 2.5rem;

    .ant-input-number-input {
      padding: 0.25rem;
      text-align: center;
    }
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const TIMESLOT_DEFAULT_ITEM_DURATION = 60;

function calculateDuration(item: TimeslotItem | null) {
  const timeslotDuration = item?.duration || TIMESLOT_DEFAULT_ITEM_DURATION;
  if (item?.mediaItem) {
    return timeslotDuration;
  }

  if (item?.playlist) {
    return item?.playlist?.items?.map(i => i.duration).reduce((acc, value) => acc + value, 0) ?? timeslotDuration;
  }

  // if (item?.template) {
  //   return (
  //     item.template.areas?.reduce((templateDuration, area) => {
  //       if (area.item) {
  //         return templateDuration + area.item.duration ?? 0;
  //       }
  //
  //       if (area.playlist) {
  //         const playlistDuration =
  //           area.playlist.items?.reduce((durationA, { duration: durationB }) => durationA + durationB, 0) ?? 0;
  //
  //         return templateDuration + playlistDuration;
  //       }
  //
  //       return templateDuration;
  //     }, 0) || timeslotDuration
  //   );
  // }

  return timeslotDuration;
}

export type TimeslotItemDetailsProps = {
  itemId: string;
  onRemove?: () => void;
};

const TimeslotItemDetails: FC<TimeslotItemDetailsProps> = ({ itemId, onRemove }) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const item = client.readFragment<TimeslotItem>({
    id: `TimeslotItem:${itemId}`, // The value of the to-do item's unique identifier
    fragment: TimeslotItemFragmentDoc,
    fragmentName: 'TimeslotItem',
  });
  const { timeslotId } = item ?? {};
  const data = item?.mediaItem ?? item?.playlist ?? item?.template ?? null;

  const [duration, setDuration] = useState(() => calculateDuration(item));
  const subtractDuration = useCallback(
    (event: MouseEvent) => {
      stopEventPropagation(event);
      setDuration(duration - 1);
    },
    [duration],
  );
  const addDuration = useCallback(
    (event: MouseEvent) => {
      stopEventPropagation(event);
      setDuration(duration + 1);
    },
    [duration],
  );
  //
  const onError = useApolloError();
  const [updateTimeslot] = useUpdateTimeslotMutation({ onError });
  const onDurationUpdate = useCallback(() => {
    if (!timeslotId) {
      return;
    }
    updateTimeslot({
      variables: {
        id: timeslotId,
        input: { updatedItems: [{ id: itemId, duration }] },
      },
    }).catch(logger);
  }, [itemId, timeslotId, duration, updateTimeslot]);

  const dataType = useMemo(
    () => (data && 'type' in data ? data.type : (!!item?.playlist && 'PLAYLIST') || (!!item?.template && 'TEMPLATE')),
    [data, item?.playlist, item?.template],
  );
  //
  const detailsIcon = useMemo(() => {
    switch (dataType) {
      case 'PLAYLIST':
        return <RiPlayList2Line />;
      case 'TEMPLATE':
        return <RiLayoutMasonryLine />;
      case 'VIDEO':
        return <RiFilmLine />;
      case 'IMAGE':
      default:
        return <RiImageLine />;
    }
  }, [dataType]);

  if (!data) {
    return null;
  }

  return (
    <DetailsCard>
      <DetailsHeader>
        <DetailsIcon>{detailsIcon}</DetailsIcon>
        <DetailsTitle>{data?.title}</DetailsTitle>
        <Popconfirm
          placement="bottomRight"
          title={`${t('Remove')}?`}
          onClick={stopEventPropagation}
          onConfirm={onRemove}
          okText={t('Yes')}
          cancelText={t('No')}>
          <Tooltip title={t('Remove')} placement="left">
            <Button type="link" size="small" icon={<RiDeleteBinLine />} className="remove" />
          </Tooltip>
        </Popconfirm>
      </DetailsHeader>
      <DetailsBody>
        <MediaThumbnail data={data} isEditable={false} isRemovable={false} width={260} />
      </DetailsBody>
      <DetailsFooter>
        <Button onClick={subtractDuration} disabled={dataType === 'VIDEO'}>
          <RiSubtractLine />
        </Button>
        <Time>
          <TimeValue onBlur={onDurationUpdate} onChange={setDuration} value={duration} min={1} max={999} />
          <span>{t('sec')}</span>
        </Time>
        <Button onClick={addDuration} disabled={dataType === 'VIDEO'}>
          <RiAddLine />
        </Button>
      </DetailsFooter>
    </DetailsCard>
  );
};

TimeslotItemDetails.displayName = 'TimeslotItemDetails';

export default memo(TimeslotItemDetails);
