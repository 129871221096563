import { Context, createContext } from 'react';
import { LocationModalData } from './events';

export interface LocationModalContextValue extends LocationModalData {}
export type LocationModalContextType = Context<LocationModalContextValue>;

export const LocationModalContext: LocationModalContextType = createContext({} as LocationModalContextValue);

const { Provider: LocationModalContextProvider } = LocationModalContext;

export default LocationModalContextProvider;
