const rotateImage = async (srcBase64?: string, degrees = 90) => {
  if (!srcBase64) {
    return;
  }

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  const image = new Image();

  image.src = srcBase64;
  await image.decode();

  canvas.width = degrees % 180 === 0 ? image.width : image.height;
  canvas.height = degrees % 180 === 0 ? image.height : image.width;

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((-degrees * Math.PI) / 180);
  ctx.drawImage(image, image.width / -2, image.height / -2);

  // eslint-disable-next-line consistent-return
  return canvas.toDataURL();
};

export default rotateImage;
