import React, { memo, FC, useState, useMemo, useEffect, useCallback, CSSProperties } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import Select, { Option, OptGroup } from 'src/components/ui-kit/select';
import { GetProjectsQueryVariables, Project, useGetProjectsQuery } from 'src/graphql/generated';

export const ProjectSelectorElement = styled(Select)`
  min-width: 10rem;
`;

export type ProjectSelectorProps = {
  value?: string | null;
  companyId?: string | null;
  onChange?(_value: string): void;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  groupByCompany?: boolean;
  queryVariables?: GetProjectsQueryVariables;
};

export const ProjectSelector: FC<ProjectSelectorProps> = props => {
  const { value, groupByCompany, companyId, disabled, className, style, queryVariables } = props;
  const [selectedProject, setProjectId] = useState(value);
  const { t } = useTranslation();
  //
  useEffect(() => {
    if (value !== selectedProject) {
      setProjectId(value);
    }
  }, [value]);
  //
  const onChange = useCallback(
    (v: Project['id']) => {
      //
      if (typeof props.onChange === 'function') {
        props.onChange(v);
      } else {
        setProjectId(v);
      }
    },
    [selectedProject],
  );
  // ToDo: Do we need to filter on server-side?
  const filterOption = useCallback(
    (input: string, option: any) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    [],
  );
  const variables = useMemo(() => {
    if (companyId) {
      return { ...(queryVariables ?? {}), filter: { ...(queryVariables?.filter ?? {}), companyId: [companyId] } };
    }
    return queryVariables ?? {};
  }, [companyId, queryVariables]);
  const { data: queryResult, loading } = useGetProjectsQuery({ variables });
  const projects = queryResult?.getProjects?.rows || [];
  const groupedByCompany = groupBy(projects, 'companyId') || {};

  const options = groupByCompany
    ? Object.keys(groupedByCompany).map(cId => {
        // eslint-disable-next-line security/detect-object-injection
        const ps = groupedByCompany[cId];
        const companyName = ps[0]?.company?.name;
        return (
          <OptGroup key={cId} label={companyName}>
            {ps.map(project => (
              <Option key={project.id} value={project.id}>
                {project.name}
              </Option>
            ))}
          </OptGroup>
        );
      })
    : projects.map(project => (
        <Option key={project.id} value={project.id}>
          {project.name}
        </Option>
      ));
  //
  return (
    <ProjectSelectorElement
      style={style}
      className={className}
      value={selectedProject}
      disabled={disabled}
      loading={!queryResult && loading}
      showSearch
      placeholder={t('Select project')}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={filterOption}>
      {options}
    </ProjectSelectorElement>
  );
};

ProjectSelector.defaultProps = { value: '', onChange: undefined, groupByCompany: false, disabled: false };
ProjectSelector.displayName = 'ProjectSelector';

export default memo(ProjectSelector);
