/* eslint-disable react/prop-types */
import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

import { AsideWrapper, Divider, Header, SubTitle, Title } from 'src/components/ui-kit/aside';
import { Calendar as AntCalendar, CalendarProps } from 'src/components/ui-kit/calendar';
import Button from 'src/components/ui-kit/buttons';
import { colorTheme } from 'src/components/ui-kit/theme';

import { formatDate, TimeInstance } from 'src/helpers/time';

import { AsideCalendarProps } from './events.d';
import { DEFAULT_DATE_FORMAT } from './helpers';

const Content = styled.div`
  padding: 1.25rem;
`;

const CustomCalendar = AntCalendar as FC<CalendarProps<TimeInstance>>;

const Calendar = styled(CustomCalendar)`
  .ant-picker-panel {
    border: 0;
    margin-top: 0.625rem;

    td.ant-picker-cell {
      & > .ant-picker-cell-inner.ant-picker-calendar-date {
        display: inline-flex;
        justify-content: center;

        & > .ant-picker-calendar-date-content {
          display: flex;
          align-items: center;
          justify-content: center;

          position: absolute;
          bottom: 0.125rem;
          width: 100%;
          height: 0.125rem;
        }
      }

      &.ant-picker-cell-selected
        > .ant-picker-cell-inner.ant-picker-calendar-date
        > .ant-picker-calendar-date-content
        > .dot {
        background: hsl(255, 100%, 100%);
      }
    }
  }
`;

const CalendarHeader = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.125rem;
`;

const Dot = styled.span`
  display: block;
  position: absolute;
  width: 0.125rem;
  height: 0.125rem;
  background: ${colorTheme.primary};
  border-radius: 50%;
`;

const ArrowButton = styled(Button)`
  color: ${colorTheme.dark} !important;
`;

export const AsideCalendar: FC<AsideCalendarProps> = ({ onSelect, selectedDate, nonEmptyDates, children }) => {
  const { t } = useTranslation();

  const renderHeader = useCallback<NonNullable<CalendarProps<TimeInstance>['headerRender']>>(
    ({ value, onChange }) => {
      const handleMonthChange = (month: number) => () => onChange(value.month(month));

      return (
        <CalendarHeader>
          <ArrowButton
            type="link"
            size="large"
            icon={<RiArrowLeftSLine />}
            onClick={handleMonthChange(value.month() - 1)}
          />
          {selectedDate.format('MMM YYYY')}
          <ArrowButton
            type="link"
            size="large"
            icon={<RiArrowRightSLine />}
            onClick={handleMonthChange(value.month() + 1)}
          />
        </CalendarHeader>
      );
    },
    [selectedDate],
  );

  const renderDateCell = useCallback<NonNullable<CalendarProps<TimeInstance>['dateCellRender']>>(
    date => nonEmptyDates?.includes(formatDate(date, DEFAULT_DATE_FORMAT)) && <Dot className="dot" />,
    [nonEmptyDates],
  );

  return (
    <AsideWrapper>
      <Header>
        <Title>{t('Calendar')}</Title>
        <SubTitle />
      </Header>
      <Divider />
      <Content>
        <Calendar
          fullscreen={false}
          headerRender={renderHeader}
          dateCellRender={renderDateCell}
          value={selectedDate}
          onChange={onSelect}
        />
        {children}
      </Content>
    </AsideWrapper>
  );
};

export default memo(AsideCalendar) as typeof AsideCalendar;
