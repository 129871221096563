import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { colorTheme, bodyColor, linkColor, colorGrays } from 'src/components/ui-kit/theme';
import { darkenColor } from 'src/components/ui-kit/utilities';

const theme = {
  color: colorGrays.gray700,
  colorFocus: darkenColor(colorGrays.gray700, 15),
  borderColor: colorGrays.gray300,
  borderColorFocus: darkenColor(colorGrays.gray300, 10),
  placeholder: colorGrays.gray500,
};

export const Title = styled(Typography.Title)`
  &.ant-typography {
    margin: 10px 0;
    line-height: 1.1;
    color: ${bodyColor};
  }
  h1&.ant-typography {
    font-size: 2.25rem;
  }
  h2&.ant-typography {
    font-size: 1.875rem;
  }
  h3&.ant-typography {
    font-size: 1.5rem;
  }
  h4&.ant-typography {
    font-size: 1.125rem;
  }
`;
export const Text = styled(Typography.Text)`
  &.ant-typography {
    color: ${bodyColor};

    &.ant-typography {
      &-secondary {
        color: ${colorTheme.secondary};
      }
      &-muted {
        color: ${colorGrays.gray600};
      }
      &-primary {
        color: ${colorTheme.primary};
      }
      &-success {
        color: ${colorTheme.success};
      }
      &-info {
        color: ${colorTheme.info};
      }
      &-warning {
        color: ${colorTheme.warning};
      }
      &-danger {
        color: ${colorTheme.danger};
      }
      &-dark {
        color: ${colorTheme.dark};
      }
      &-white {
        color: #ffffff;
      }
      &-black-50 {
        color: rgba(0, 0, 0, 0.5);
      }
      &-white-50 {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    mark {
      background-color: #fcf8e3;
      padding: 0.25rem;
    }

    .ant-input {
      color: ${theme.color};
      background-color: #ffffff;
      padding: 0.4375rem 1rem;
      min-height: 38px;
      border-color: ${theme.borderColor};
      border-radius: 0.25rem;

      &:hover,
      &:focus {
        color: ${theme.colorFocus};
        border-color: ${theme.borderColorFocus};
        box-shadow: none;
      }
    }
  }
`;
export const Link = styled(Typography.Link)`
  &.ant-typography {
    color: ${linkColor};

    &.ant-typography {
      &-secondary {
        color: ${colorTheme.secondary};
        &:hover,
        &:focus {
          color: ${darkenColor(colorTheme.secondary, 15)};
        }
      }
      &-muted {
        color: ${colorGrays.gray600};
        &:hover,
        &:focus {
          color: ${darkenColor(colorGrays.gray600, 15)};
        }
      }
      &-primary {
        color: ${colorTheme.primary};
        &:hover,
        &:focus {
          color: ${darkenColor(colorTheme.primary, 15)};
        }
      }
      &-success {
        color: ${colorTheme.success};
        &:hover,
        &:focus {
          color: ${darkenColor(colorTheme.success, 15)};
        }
      }
      &-info {
        color: ${colorTheme.info};
        &:hover,
        &:focus {
          color: ${darkenColor(colorTheme.info, 15)};
        }
      }
      &-warning {
        color: ${colorTheme.warning};
        &:hover,
        &:focus {
          color: ${darkenColor(colorTheme.warning, 15)};
        }
      }
      &-danger {
        color: ${colorTheme.danger};
        &:hover,
        &:focus {
          color: ${darkenColor(colorTheme.danger, 15)};
        }
      }
      &-dark {
        color: ${colorTheme.dark};
        &:hover,
        &:focus {
          color: ${darkenColor(colorTheme.dark, 15)};
        }
      }
      &-white {
        color: #ffffff;
        &:hover,
        &:focus {
          color: ${darkenColor('#ffffff', 15)};
        }
      }
      &-black-50 {
        color: rgba(0, 0, 0, 0.5);
        &:hover,
        &:focus {
          color: rgba(0, 0, 0, 0.7);
        }
      }
      &-white-50 {
        color: rgba(255, 255, 255, 0.5);
        &:hover,
        &:focus {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
`;
export const Paragraph = styled<any>(Typography.Paragraph)`
  &.ant-typography {
    margin-bottom: 1.5em;
    text-align: ${props => (props.centered ? 'center' : 'left')};
  }
`;

export const Ul = styled.ul`
  &.list-unstyled {
    list-style: none;
    padding-left: 0;
  }

  &.list-inline {
    list-style: none;
    padding-left: 0;
    > li {
      display: inline-block;
      margin-right: 6px;
    }
  }
`;

export const GhostEditableTitleContainer = styled(Typography.Title)`
  &.ant-typography.ant-typography.ant-typography.ant-typography {
    display: flex;
    align-items: center;
    margin: 0.25rem 0 0;
    // width: 100%;

    & > .ant-typography-edit {
      // flex-basis: 20%;
      // margin-right: auto;
    }

    & > span.ghost-editable-title-text {
      display: inline-block;

      // flex-basis: 80%;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 1.5rem;
    }

    &.ant-typography-edit-content {
      left: 0;

      textarea.ant-input {
        padding: 0 0 0;
        border: 0;
        //height: auto !important;
        //min-height: initial !important;
        //max-height: initial !important;
        box-shadow: none;
        transition: none;

        font-weight: inherit;
        font-size: inherit;
        color: inherit;
        background-color: inherit;
        background: inherit;
        line-height: inherit;
      }

      .ant-typography-edit-content-confirm {
        bottom: 50%;
        transform: translateY(50%);
      }
    }
  }
`;

export type GhostEditableTitleProps = {
  onChange?: any;
  onClick?: any;
  maxLength?: number;
  level?: 1 | 2 | 3 | 4 | 5;
  autoSize?: { minRows: number; maxRows: number };
  className?: string;
  style?: CSSProperties;
  value?: string;
  isEditable?: boolean;
  tooltip?: string;
  // hideEllipsisTooltip?: boolean;
};

export const GhostEditableTitle: FC<GhostEditableTitleProps> = props => {
  const { t } = useTranslation();
  const {
    onChange,
    onClick,
    maxLength = 64,
    level = 5,
    autoSize = { minRows: 1, maxRows: 1 },
    className = '',
    style,
    value: initialValue = '',
    isEditable = true,
    // This option is needed due to bug when Title placed inside Collapsible panel
    // hideEllipsisTooltip = false,
  } = props;
  const [value, setValue] = useState(initialValue);
  // eslint-disable-next-line react/destructuring-assignment
  const tooltip = props.tooltip || t('Edit');
  //
  const onClickHandler = useCallback(
    (e?: any) => {
      if (typeof e?.stopPropagation === 'function' && e.target !== e.currentTarget) {
        e.stopPropagation();
      }
      if (typeof onClick === 'function') {
        onClick(e);
      }
    },
    [onClick],
  );
  //
  const editableTitleProps = useMemo(
    () => ({
      // icon: ReactNode,
      // tooltip: boolean | ReactNode,
      tooltip,
      // editing: true,
      maxLength,
      autoSize,
      // onStart(v: string) {
      //   console.log('Title.onEditStart', { v });
      //   setValue(v);
      // },
      text: value,
      onChange(v: string) {
        if (!v?.trim?.()) {
          return;
        }
        setValue(v);
        if (typeof onChange === 'function') {
          onChange(v);
        }
      },
    }),
    [tooltip, maxLength, onChange, autoSize, value],
  );
  //
  useEffect(
    () => {
      if (initialValue !== value) {
        setValue(initialValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValue],
  );
  //
  return (
    <GhostEditableTitleContainer
      onClick={onClickHandler}
      title={value}
      level={level}
      editable={isEditable ? editableTitleProps : false}
      className={className}
      style={style}>
      <span className="ghost-editable-title-text">{value}</span>
    </GhostEditableTitleContainer>
  );
};
