import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { CardProps, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { updateAfterMutation, useApolloError, useQuery } from '@fjedi/graphql-react-components';
import type { Device } from 'src/graphql/generated';
import getDevicesQuery from 'src/graphql/queries/get-devices.graphql';
import logger from 'src/helpers/logger';
import RightSider from 'src/components/ui-kit/admin-layout/right-sider';
import ContentCard from 'src/components/ui-kit/admin-layout/content-card';
import { FixedScrollbar as Scrollbar } from 'src/components/ui-kit/scrollbar';
import CustomSelect, { Option } from 'src/components/ui-kit/select';
import { colorTheme } from 'src/components/ui-kit/theme';
import BackButton from 'src/components/ui-kit/buttons/back-button';
import { DragDropContext, DroppableArea, DropResult } from 'src/components/ui-kit/drag-n-drop';
import searchBgIcon from 'static/images/proportions.svg';
import {
  useCreateDeviceLocationMutation,
  useGetDeviceLocationsQuery,
  useGetEventLocationsQuery,
  useUpdateDeviceLocationMutation,
} from 'src/graphql/generated';
import type { ContentCardProps } from '../events';
import { DeviceLocation, IconProps, WayfindingItem } from './wayfinding.d';

import AsideIconsManager from './aside-icons-manager';
import { WayfindingItemCard } from './wayfinding-item-card';

const Container = styled(ContentCard as FC<CardProps & ContentCardProps>)`
  .ant-card-head-title {
    flex-direction: row-reverse;

    & > div:first-child {
      padding: 0;
      margin-right: auto;

      .ant-typography {
        color: ${colorTheme.dark};
      }
    }

    & > .ant-btn {
      color: ${colorTheme.dark};
    }
  }

  .ant-card-extra {
    padding-top: 0.5rem !important;
  }
`;

const Select = styled(CustomSelect as FC<SelectProps<string>>)`
  &.ant-select > .ant-select-selector {
    height: 2.25rem !important;
    width: 15rem !important;
    justify-content: center;

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 10px;
      background: url(${searchBgIcon}) no-repeat;
    }

    & > .ant-select-selection-placeholder,
    & > .ant-select-selection-item {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: auto;
    }
  }
`;

const WayfindingItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.5rem;

  & > div {
    flex-basis: calc(25% - 1.25rem);
    margin: 0 1.25rem 1.25rem 0 !important;
  }

  .droppable-wayfinding-item {
    &.is-dragging-over > .wayfinding-location-container {
      box-shadow: 0 0 0.25rem 0.25rem ${colorTheme.primary};
    }
  }
`;

export const WayfindingPage: FC = () => {
  const [selectedScreen, setSelectedScreen] = useState<string>();

  const { t } = useTranslation();

  const { data: devicesRes, loading: devicesLoading } = useQuery(getDevicesQuery, { variables: { filter: {} } });
  const devices: Device[] = useMemo(() => devicesRes?.getDevices?.rows ?? [], [devicesRes]);

  const { data: deviceLocationsRes, loading: deviceLocationsLoading } = useGetDeviceLocationsQuery({
    variables: { filter: { deviceId: selectedScreen } },
  });
  const deviceLocations = useMemo(() => deviceLocationsRes?.getDeviceLocations?.rows ?? [], [deviceLocationsRes]);

  const { data: eventLocationsRes, loading: eventLocationsLoading } = useGetEventLocationsQuery({
    variables: { filter: {} },
  });
  const eventLocations = useMemo(() => eventLocationsRes?.getEventLocations?.rows ?? [], [eventLocationsRes]);

  const onError = useApolloError();
  const [createDeviceLocation, { loading: deviceLocationCreating }] = useCreateDeviceLocationMutation({
    update: updateAfterMutation('DeviceLocation', 'getDeviceLocations'),
    onError,
  });
  const [updateDeviceLocation, { loading: deviceLocationUpdating }] = useUpdateDeviceLocationMutation({
    update: updateAfterMutation('DeviceLocation', 'getDeviceLocations'),
    onError,
  });

  const isLoading = useMemo(
    () =>
      devicesLoading ||
      eventLocationsLoading ||
      deviceLocationsLoading ||
      deviceLocationCreating ||
      deviceLocationUpdating,
    [devicesLoading, eventLocationsLoading, deviceLocationsLoading, deviceLocationCreating, deviceLocationUpdating],
  );

  const handleScreenChange = useCallback((device: string) => {
    setSelectedScreen(device);
    logger('Device selected: ', { device });
  }, []);

  const header = useMemo(
    () => ({
      title: t('Manage Wayfinding'),
      actions: [
        {
          render() {
            return (
              <Select
                placeholder={t('Select a device')}
                onChange={handleScreenChange}
                loading={isLoading}
                disabled={isLoading}>
                {devices.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            );
          },
        },
      ],
    }),
    [t, handleScreenChange, isLoading, devices],
  );

  const wayfindingItems: WayfindingItem[] = useMemo(() => {
    if (!selectedScreen) {
      return eventLocations;
    }

    return eventLocations.map(eventLocation => {
      const { id, name } = eventLocation;
      const correspondingDeviceLocation = deviceLocations.find(
        ({ destinationLocationId }) => destinationLocationId === id,
      );
      const wayfindingItem = { id, name };

      if (!correspondingDeviceLocation) {
        return wayfindingItem;
      }

      const { id: deviceLocationId, icon, isVisible } = correspondingDeviceLocation;

      return { ...wayfindingItem, deviceLocationId, icon, isVisible } as WayfindingItem;
    });
  }, [eventLocations, deviceLocations, selectedScreen, deviceLocationUpdating]);

  const onDragEnd = useCallback(
    (event: DropResult) => {
      const { destination, draggableId } = event;

      if (
        selectedScreen &&
        destination &&
        destination.droppableId &&
        draggableId &&
        destination.droppableId.split('.')[0] !== 'list'
      ) {
        const targetEventLocationId = destination.droppableId.split('.')[0];
        const iconId = draggableId.split('.')[0];
        const correspondingDeviceLocation = deviceLocations.find(
          ({ destinationLocationId }) => destinationLocationId === targetEventLocationId,
        );

        logger('Icon dropped', { iconId, targetEventLocationId, correspondingDeviceLocation });

        if (correspondingDeviceLocation) {
          updateDeviceLocation({ variables: { id: correspondingDeviceLocation.id, input: { iconId } } });
        } else {
          const destinationLocationId = targetEventLocationId;
          const deviceId = selectedScreen;

          createDeviceLocation({ variables: { input: { destinationLocationId, deviceId, iconId, isVisible: true } } });
        }
      }
    },
    [deviceLocations, selectedScreen, createDeviceLocation, updateDeviceLocation],
  );

  const handleWayfindingItemVisibilityChange = useCallback(
    (deviceLocationId: string | null, eventLocationId: string, isVisible: boolean) => {
      if (deviceLocationId) updateDeviceLocation({ variables: { id: deviceLocationId, input: { isVisible } } });
      else {
        const destinationLocationId = eventLocationId;
        const deviceId = selectedScreen;

        createDeviceLocation({ variables: { input: { destinationLocationId, deviceId, isVisible } } });
      }
    },
    [createDeviceLocation, updateDeviceLocation, selectedScreen],
  );

  const handleWayfindingItemRemoveClick = useCallback(
    (deviceLocationId: string) => {
      updateDeviceLocation({ variables: { id: deviceLocationId, input: { iconId: null } } });
    },
    [updateDeviceLocation],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container header={header}>
        <Scrollbar>
          <WayfindingItemsContainer>
            {wayfindingItems.map(({ id, name, icon, deviceLocationId, isVisible }) => (
              <DroppableArea
                key={id}
                type="REORDER"
                placeholder=""
                droppableId={`${id}.wayfindingItem`}
                direction="horizontal"
                isDropDisabled={!selectedScreen}
                className="droppable-wayfinding-item">
                <WayfindingItemCard
                  locationData={{ id, name }}
                  iconData={icon as IconProps}
                  deviceLocationId={deviceLocationId}
                  isVisible={isVisible}
                  onVisibilityChange={handleWayfindingItemVisibilityChange}
                  onRemove={handleWayfindingItemRemoveClick}
                  showVisibilityIndicator={!!selectedScreen}
                  loading={isLoading}
                />
              </DroppableArea>
            ))}
          </WayfindingItemsContainer>
        </Scrollbar>
        <RightSider>
          <AsideIconsManager />
        </RightSider>
      </Container>
      <BackButton />
    </DragDropContext>
  );
};

export default memo(WayfindingPage);
