import React, { memo, useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logger from 'src/helpers/logger';
import { Device, GetDevicesQueryVariables, useGetDevicesQuery } from 'src/graphql/generated';

import Select, { Option } from 'src/components/ui-kit/select';
import searchBgIcon from 'static/images/proportions.svg';

export const DeviceSelectorElement = styled(Select)`
  min-width: 10rem;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    //border-radius: 0.5rem;
    //height: 2rem;
  }

  .ant-select-selector {
    &:before {
      content: '';
      display: block;
      margin-right: 10px;
    }
  }

  &.ant-select-show-search {
    &.ant-select-single .ant-select-selector {
      background-color: #ffffff;
      border-color: #d9d9d9;

      &:before {
        background: url(${searchBgIcon}) no-repeat;
      }
    }
    &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #d9d9d9;
    }

    &.ant-select .ant-select-selector > .ant-select-selection-item {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: auto;
      max-width: 12rem;
    }
  }
`;

export type DeviceSelectorProps = {
  value?: string;
  className?: string;
  style?: { [k: string]: string | number };
  disabled?: boolean;
  queryVariables?: GetDevicesQueryVariables;
  onChange?: (_v: string, _d: Device) => void;
};

const DeviceSelector: React.FC<DeviceSelectorProps> = props => {
  const { t } = useTranslation();
  const {
    // Get value provided by parent component (if any)
    value,
    // Handle default react input props to be able to disable selectbox and/or set custom styles
    style,
    className,
    disabled,
    // Allow to pass custom query-vars for filtering or pagination of the data returned from API
    queryVariables,
  } = props;
  const [selectedDevice, setDeviceId] = useState(value);
  //
  const { data: queryResult } = useGetDevicesQuery({ variables: queryVariables, fetchPolicy: 'cache-and-network' });
  const devices = queryResult?.getDevices?.rows || [];

  // if 'value' provided by parent component has been changed
  // we should update value stored inside inner state of the DeviceSelector
  useEffect(() => {
    if (value !== selectedDevice) {
      setDeviceId(value);
    }
  }, [value]);

  // Handle 'onChange' event and bypass it to 'onChange' handler from props (if any)
  // if not parent 'onChange' handler provided, just save new value to the inner state of the component
  const onChange = useCallback(
    v => {
      logger('DeviceSelector.onChange', v);
      //
      if (typeof props.onChange === 'function') {
        const device = devices.find(d => d.id === v) as Device;
        props.onChange(v, device);
      } else {
        setDeviceId(v);
      }
    },
    [selectedDevice, props.onChange, devices],
  );

  return (
    <DeviceSelectorElement
      disabled={disabled}
      style={style}
      className={className}
      value={selectedDevice}
      onChange={onChange}
      placeholder={t('Select a device')}
      showSearch
      filterOption={(input: string, option) =>
        (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }>
      {devices.map(device => (
        <Option key={device.id} value={device.id}>
          {t(device.name!)}
        </Option>
      ))}
    </DeviceSelectorElement>
  );
};

DeviceSelector.displayName = 'DeviceSelector';

export default memo(DeviceSelector);
