import React, { CSSProperties, FC, memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flight, FlightsDirection, FlightStatus, useGetFlightsQuery } from 'src/graphql/generated';
import { formatDate } from 'src/helpers/time';
import { pascalCase } from 'src/functions';

export interface FlightsWidgetProps {
  id: string;
  props?: {
    airport: string;
    direction: FlightsDirection;
    mainColor: CSSProperties['color'];
    headerColor: CSSProperties['color'];
    visibleRows: number;
    rowHeight: number;
    separatorSize: number;
    separatorColor: CSSProperties['color'];
    paginationEnabled: boolean;
    pagination: {
      interval: number;
      margin: number;
      fontFamily: string;
      fontSize: number;
      fontColor: CSSProperties['color'];
      horizontalAlignment: string;
      verticalAlignment: string;
    };
  };
  style: CSSProperties;
}

export type FlightsTableProps = Pick<
  Required<FlightsWidgetProps>['props'],
  'headerColor' | 'mainColor' | 'separatorSize' | 'separatorColor' | 'rowHeight'
>;

const Container = styled.div<Required<FlightsWidgetProps>['props']['pagination']>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ verticalAlignment }) => {
    if (verticalAlignment === 'top') {
      return 'column';
    }
    return 'column-reverse';
  }};
  align-items: stretch;
  justify-content: center;

  & > p.pagination {
    margin: ${({ margin, verticalAlignment }) => (verticalAlignment === 'top' ? `0 0 ${margin}px` : `${margin}px 0 0`)};
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: ${({ fontSize }) => fontSize}px;
    color: ${({ fontColor }) => fontColor};
    text-align: ${({ horizontalAlignment }) => horizontalAlignment};
  }
`;

const FlightsTable = styled.table<FlightsTableProps>`
  border-collapse: collapse;

  & th,
  & td {
    height: ${({ rowHeight }) => rowHeight}px;
    min-height: ${({ rowHeight }) => rowHeight}px;
    max-height: ${({ rowHeight }) => rowHeight}px;
    padding: 0 1.875rem;
    text-align: center;
    vertical-align: center;
  }

  & > thead > tr > th {
    color: ${({ headerColor }) => headerColor};
  }

  & > tbody > tr {
    & > td {
      color: ${({ mainColor }) => mainColor};
    }

    &:not(:last-of-type) > td {
      border-bottom-width: ${({ separatorSize }) => separatorSize}px;
      border-bottom-color: ${({ separatorColor }) => separatorColor};
      border-bottom-style: ${({ separatorSize }) => (separatorSize > 0 ? 'solid' : 'none')};
    }
  }
`;

// const sampleData = {
//   time: '11:45',
//   flight: 'DY1234',
//   destination: 'Toronto (YYZ)',
//   gate: 'A',
//   status: 'On time 11:45',
// };

const FlightsWidget: FC<FlightsWidgetProps> = ({ style, ...item }) => {
  const {
    // airport,
    // arrivalOrDeparture,
    mainColor,
    headerColor,
    visibleRows,
    rowHeight,
    separatorSize,
    separatorColor,
    paginationEnabled,
    pagination,
    direction,
    airport,
  } = item.props as Required<FlightsWidgetProps>['props'];
  //
  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(1);
  }, [airport, direction]);
  //
  const limit = visibleRows;
  const offset = page > 1 ? (page - 1) * visibleRows : 0;
  const { data } = useGetFlightsQuery({
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      filter: {
        airportCode: airport,
        direction,
      },
    },
  });
  const sliceStart = offset ? offset - 1 : offset ?? 0;
  const sliceEnd = sliceStart + limit;
  const flights = (data?.getFlights?.rows ?? []).slice(sliceStart, sliceEnd);
  const totalFlights = data?.getFlights?.count ?? 0;
  const pages = Math.ceil(totalFlights / visibleRows);

  const nextPage = useCallback(() => {
    if (page >= pages) {
      setPage(1);
    } else {
      setPage(page + 1);
    }
  }, [page, pages, setPage]);
  useEffect(() => {
    if (!pagination?.interval) {
      return () => null;
    }
    const paginationInterval = setInterval(() => {
      nextPage();
    }, pagination.interval * 1000);
    return () => clearInterval(paginationInterval);
  }, [pagination?.interval, nextPage]);

  const getFlightStatusString = useCallback((flight: Flight) => {
    const { status, dateUtc, actualDateUtc, estimatedDateUtc } = flight;
    if (status === FlightStatus.Scheduled) {
      return `On time ${formatDate(estimatedDateUtc || actualDateUtc || dateUtc, 'HH:mm')}`;
    }
    if (status === FlightStatus.Delayed) {
      const delayTime = formatDate(estimatedDateUtc || actualDateUtc || dateUtc, 'HH:mm');
      return <span style={{ color: 'red' }}>{`Delayed ${delayTime}`}</span>;
    }
    return pascalCase(status);
  }, []);

  return (
    <Container {...pagination} style={style}>
      {paginationEnabled && <p className="pagination">{`Page ${page} of ${pages}`}</p>}
      <FlightsTable
        rowHeight={rowHeight}
        separatorSize={separatorSize}
        separatorColor={separatorColor}
        headerColor={headerColor}
        mainColor={mainColor}>
        <thead>
          <tr>
            <th>Time</th>
            <th>Flight</th>
            <th>Destination</th>
            <th>Gate</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {flights.map(row => {
            const { id, dateUtc, flight, city, airportCode, gate } = row;
            return (
              <tr key={id}>
                <td>{formatDate(dateUtc, 'HH:mm')}</td>
                <td>{flight}</td>
                <td>{city ? `${city} (${airportCode})` : airportCode}</td>
                <td>{gate}</td>
                <td>{getFlightStatusString(row)}</td>
              </tr>
            );
          })}
        </tbody>
      </FlightsTable>
    </Container>
  );
};

export default memo(FlightsWidget);
