import get from 'lodash/get';
import last from 'lodash/last';
import { HOST, APP_VERSION, SENTRY_ENV, SENTRY_DSN } from 'src/constants';

const beforeSend = event => {
  const lastBreadcrumb = last(get(event, 'breadcrumbs', []));
  const category = get(lastBreadcrumb, 'category', '');
  const statusCode = String(get(lastBreadcrumb, 'data.status_code', ''));
  if (category === 'xhr' && statusCode === '400') {
    return null;
  }
  return event;
};

export default function initSentry(sentry, params = {}) {
  const {
    release = `admin@${APP_VERSION}`,
    enabled = true,
    dsn = SENTRY_DSN,
    environment = SENTRY_ENV,
    serverName = HOST,
    maxBreadcrumbs = 25,
    sendDefaultPii = true,
    attachStacktrace = true,
    debug = false,
  } = params;

  sentry.init({
    release,
    dsn,
    enabled,
    environment,
    debug,
    serverName,
    sendDefaultPii,
    attachStacktrace,
    maxBreadcrumbs,
    beforeSend,
  });
  window.Sentry = sentry;
}

export async function sendErrorToSentry(error, args = {}) {
  if (!SENTRY_DSN) {
    return;
  }
  //
  const clientIP = get(args, 'clientIP') || get(args, 'user.ip') || get(args, 'state.clientIP');
  //
  const request = get(args, 'request', {});
  //
  const body = (request.body && { ...request.body }) || {};
  // Remove private data from our logs
  if (body.password) body.password = '[Filtered]';
  if (body.key) body.key = '[Filtered]';
  if (body.token) body.token = '[Filtered]';

  //
  Sentry.captureException(
    error,
    {
      req: {
        ...request,
        body,
      },
      user: {
        ip_address: clientIP,
      },
    },
    (sendErr, eventId) => {
      if (sendErr) {
        // do not log whole 'sentry' error to stdout, as it could be very huge
        console.error('Failed to send captured exception to Sentry', error);
      } else {
        console.info(`Captured exception (ID: ${eventId}) and send to Sentry successfully`);
      }
    },
  );
}
