import React, { memo, FC } from 'react';
import { createGlobalStyle } from 'styled-components';
import Tooltip from 'antd/es/tooltip';
import type { TooltipProps } from 'antd/es/tooltip';

const GlobalStyle = createGlobalStyle`
  .ant-tooltip {
    .ant-tooltip-inner {
      font-size: 0.8125rem;
      border-radius: 0.1875rem;
      padding: 0.375rem 0.875rem;
      box-shadow: none;
    }

    .ant-tooltip-arrow {
      width: 16px;
      height: 16px;
    }

    .ant-tooltip-arrow-content {
      width: 12px;
      height: 12px;
    }

    &.ant-tooltip-placement-top,
    &.ant-tooltip-placement-topLeft,
    &.ant-tooltip-placement-topRight {
      .ant-tooltip-arrow {
        bottom: 0;
      }
      .ant-tooltip-arrow-content {
        transform: translateY(-9px) rotate(45deg);
      }
    }

    &.ant-tooltip-placement-right,
    &.ant-tooltip-placement-rightTop,
    &.ant-tooltip-placement-rightBottom {
      .ant-tooltip-arrow {
        left: 100%;
      }
      .ant-tooltip-arrow-content {
        transform: translateX(9px) rotate(45deg);
      }
    }

    &.ant-tooltip-placement-bottom,
    &.ant-tooltip-placement-bottomLeft,
    &.ant-tooltip-placement-bottomRight {
      .ant-tooltip-arrow {
        top: 0;
      }
      .ant-tooltip-arrow-content {
        transform: translateY(9px) rotate(225deg);
      }
    }

    &.ant-tooltip-placement-left,
    &.ant-tooltip-placement-leftTop,
    &.ant-tooltip-placement-leftBottom {
      .ant-tooltip-arrow {
        right: 0;
      }
      .ant-tooltip-arrow-content {
        transform: translateX(-9px) rotate(-45deg);
      }
    }
  }
`;

type TooltipComponentProps = TooltipProps & {
  className?: string;
};
//
export const TooltipComponent: FC<TooltipComponentProps> = ({ children, ...props }) => {
  return (
    <>
      <GlobalStyle />
      <Tooltip autoAdjustOverflow arrowPointAtCenter {...props}>
        {children}
      </Tooltip>
    </>
  );
};

export default memo(TooltipComponent) as typeof TooltipComponent;
