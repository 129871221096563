import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import capitalize from 'lodash/capitalize';
//
import { useTranslation } from 'react-i18next';
import { formatDate } from 'src/helpers/time';
import logger from 'src/helpers/logger';
import { getRole } from 'src/functions';
import {
  RestrictedArea,
  useNavigate,
  ViewerContext,
  ViewerRoleContext,
  ViewerRoleProvider,
} from '@fjedi/react-router-helpers';
// GraphQL Queries
import {
  GetUsersQueryVariables,
  RemoveUserDocument,
  useGetUsersLazyQuery,
  User,
  UserProfileInput,
  UserRoleRole,
  useUpdateUserMutation,
  Viewer,
} from 'src/graphql/generated';
import { updateAfterMutation, useApolloError } from '@fjedi/graphql-react-components';
//
import { Space } from 'src/components/ui-kit/space';
import Table, { TableProps, filterDropdown, filterIcon } from 'src/components/ui-kit/table/table';
import Button from 'src/components/ui-kit/buttons';
import LinkButton from 'src/components/ui-kit/buttons/link-button';
import { Col, Row } from 'src/components/ui-kit/grid';
import RoleSelector from 'src/components/common/role-selector';
import UserProfileCard from './user-profile-card';

const UsersPage: FC = () => {
  const viewer = useContext(ViewerContext) as Viewer;
  const viewerRole = useContext(ViewerRoleContext) as UserRoleRole;
  const { role } = getRole({ viewer, viewerRole });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onError = useApolloError();
  //
  const [updateUser] = useUpdateUserMutation({
    update: updateAfterMutation('User', 'getUsers'),
    onError,
  });
  //
  const onChangeField = useCallback(
    (userId: string, input: UserProfileInput) => {
      updateUser({ variables: { id: userId, input } }).catch(logger);
    },
    [updateUser],
  );
  const openLink = useCallback(
    (link: string) => () => {
      navigate(link);
    },
    [navigate],
  );
  const columns = useMemo(() => {
    const cols: TableProps<User, GetUsersQueryVariables>['columns'] = [
      {
        title: t('Name'),
        dataIndex: 'fullName',
        sorterKey: 'firstName',
        sorter: true,
        filterDropdown,
        filterIcon,
        removable() {
          return viewerRole === UserRoleRole.Admin;
        },
        render(_, { profile }) {
          return profile?.fullName;
        },
      },
      {
        title: t('Email'),
        dataIndex: 'email',
        filterDropdown,
        filterIcon,
        render(_, { profile }) {
          return profile?.email;
        },
      },
    ];

    if ([UserRoleRole.Admin, UserRoleRole.Moderator].includes(viewerRole)) {
      cols.push({
        title: t('Role'),
        dataIndex: 'role',
        width: 200,
        onChangeField,
        editable() {
          return viewerRole === 'ADMIN';
        },
        inputAlwaysVisible: true,
        input: RoleSelector,
        filters: [
          { text: t('Admin'), value: UserRoleRole.Admin },
          { text: t('User'), value: UserRoleRole.User },
        ],
        filterMultiple: false,
        inputProps(record) {
          return {
            initialValue: record?.role,
          };
        },
        render(r) {
          return t(capitalize(r));
        },
      });
    }

    cols.push(
      {
        title: t('Date'),
        dataIndex: 'createdAt',
        sorterKey: 'createdAt',
        width: 180,
        sorter: true,
        render(createdAt) {
          return formatDate(createdAt, 'DD.MM.YYYY HH:mm');
        },
      },
      {
        title: '',
        dataIndex: 'operation',
        width: 110,
        render: (text, record) => (
          <div className="editable-row-operations">
            <LinkButton to={record.id}>{t('Open')}</LinkButton>
          </div>
        ),
      },
    );

    return cols;
  }, [viewerRole, t, onChangeField]);

  const getUsersQuery = useGetUsersLazyQuery({
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  return (
    <ViewerRoleProvider value={role}>
      <Row>
        <Col md={{ span: 24 }}>
          <UserProfileCard />
          <Space direction="vertical">
            <RestrictedArea areaType="block" allowedRoles={['ADMIN']}>
              <Button onClick={openLink(`new`)}>{t('Create')}</Button>
            </RestrictedArea>

            <Table
              pageSize={9}
              dataType="User"
              query={getUsersQuery}
              subscriptionQueries={
                [
                  // userCreatedSubscription,
                  // userChangedSubscription,
                  // userRemovedSubscription,
                ]
              }
              removeRowMutationDoc={RemoveUserDocument}
              removalConfirmationMessage={`${t('Remove')}?`}
              // variables={{ filter: { companyId: [companyId] } }}
              rowKey="id"
              columns={columns}
            />
          </Space>
        </Col>
      </Row>
    </ViewerRoleProvider>
  );
};

UsersPage.displayName = 'UsersPage';

export default memo(UsersPage);
