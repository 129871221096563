/* eslint-disable react/no-danger */
// React
import React, { memo } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Popconfirm as AntPopconfirm, PopconfirmProps as AntPopconfirmProps } from 'antd';
import { colorGrays, colorTheme } from '../theme';
import { darkenColor } from '../utilities';

export const Container = styled(AntPopconfirm)``;

const GlobalStyle = createGlobalStyle`
  .ant-popover {
    .ant-popover-inner {
      border-radius: 0.25rem;
      color: #212529;
      border: 1px solid ${colorGrays.gray300};
      box-shadow: none;
    }

    .ant-popover-arrow-content {
      padding: .5rem .75rem;
    }

    .ant-popover-arrow {
      width: 12px;
      height: 12px;
      border-width: 1px;
      background-color: transparent;
    }

    &.ant-popover-placement-top,
    &.ant-popover-placement-topLeft,
    &.ant-popover-placement-topRight {
      > .ant-popover-content > .ant-popover-arrow {
      border-color: ${colorGrays.gray300};
      border-left: transparent;
      border-top: transparent;
      bottom: 3px;
      box-shadow: none;
      }
    }

    &.ant-popover-placement-right,
    &.ant-popover-placement-rightTop,
    &.ant-popover-placement-rightBottom {
      > .ant-popover-content > .ant-popover-arrow {
      border-color: ${colorGrays.gray300};
      border-right: transparent;
      border-top: transparent;
      left: 4px;
      box-shadow: none;
      }
    }

    &.ant-popover-placement-bottom,
    &.ant-popover-placement-bottomLeft,
    &.ant-popover-placement-bottomRight {
      > .ant-popover-content > .ant-popover-arrow {
      border-color: ${colorGrays.gray300};
      border-right: transparent;
      border-bottom: transparent;
      top: 4px;
      box-shadow: none;
      }
    }

    &.ant-popover-placement-left,
    &.ant-popover-placement-leftTop,
    &.ant-popover-placement-leftBottom {
      > .ant-popover-content > .ant-popover-arrow {
      border-color: ${colorGrays.gray300};
      border-left: transparent;
      border-bottom: transparent;
      right: 4px;
      box-shadow: none;
      }
    }

    .ant-btn {
      border-radius: 0.25rem;
      background-color: #ffffff;
      color: ${colorTheme.secondary};
      border-color: ${colorTheme.secondary};

      &:hover,
      &:focus {
        color: #ffffff;
        background-color: ${darkenColor(colorTheme.secondary)};
        border-color: ${darkenColor(colorTheme.secondary, 10)};
      }

    }

    .ant-btn-primary {
      color: #ffffff;
      background-color: ${colorTheme.primary};
      border-color: ${colorTheme.primary};

       &:hover,
       &:focus {
          background-color: ${darkenColor(colorTheme.primary)};
          border-color: ${darkenColor(colorTheme.primary, 10)};
       }
    }
  }
`;

type OnCancel = Required<AntPopconfirmProps>['onCancel'];

export type PopconfirmProps = AntPopconfirmProps & {
  onClick: React.MouseEventHandler;
  onCancel?(..._args: Parameters<OnCancel> | any[]): ReturnType<OnCancel> | false;
};
//
export const Popconfirm: React.FC<PopconfirmProps> = ({ children, ...props }) => (
  <>
    <GlobalStyle />
    <Container {...props}>{children}</Container>
  </>
);

export default memo(Popconfirm) as typeof Popconfirm;
