import React, { Fragment, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { formType, ViewerType, IDType } from 'src/store/prop-types';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { formatDate } from 'src/helpers/time';
import logger from 'src/helpers/logger';
// Recompose
import { pure, compose, setDisplayName, getContext } from 'recompose';
//
import { useTranslation } from 'react-i18next';
// GraphQL Queries
import { useMutation, Mutation, updateAfterMutation } from '@fjedi/graphql-react-components';
import getUserRolesQuery from 'src/graphql/queries/get-user-roles.graphql';
import createUserRoleMutation from 'src/graphql/mutations/create-user-role.graphql';
import updateUserRoleMutation from 'src/graphql/mutations/update-user-role.graphql';
import removeUserRoleMutation from 'src/graphql/mutations/remove-user-role.graphql';
//
import userRoleCreatedSubscription from 'src/graphql/subscriptions/user-role-created.graphql';
import userRoleChangedSubscription from 'src/graphql/subscriptions/user-role-changed.graphql';
import userRoleRemovedSubscription from 'src/graphql/subscriptions/user-role-removed.graphql';
//
import Table from 'src/components/ui-kit/table';
import { Form, FormItem } from 'src/components/ui-kit/form';
import Select, { Option } from 'src/components/ui-kit/select';
import { Input } from 'src/components/ui-kit/input';
import LinkButton from 'src/components/ui-kit/buttons/link-button';
import Button from 'src/components/ui-kit/buttons';

const Container = styled.div``;

const RoleSelector = props => {
  const { t } = useTranslation();
  const { onPressEnter, children, ...p } = props;

  return (
    <Select style={{ width: '200px' }} {...p}>
      <Option value="ADMIN">{t('Admin')}</Option>
      <Option value="MODERATOR">{t('Moderator')}</Option>
    </Select>
  );
};

const CompanyUsersTab = ({ viewer }) => {
  const { t } = useTranslation();
  const [updateUserRole] = useMutation(updateUserRoleMutation);
  const [form] = Form.useForm();
  const { resetFields } = form;
  //
  const onCompleted = useCallback(() => {
    resetFields();
  });
  const onSubmit = useCallback(mutation => input => {
    const companyId = viewer?.primaryCompany?.id;
    if (!companyId) {
      return;
    }
    mutation({
      variables: {
        input: { ...input, companyId },
      },
    });
  });
  const onChangeField = useCallback((id, input) => {
    //
    logger('CompanyUsersTable.onChangeField', {
      input,
    });
    if (Object.keys(input).length > 0) {
      updateUserRole({
        variables: { id, input },
      }).catch(logger);
    }
  });

  return (
    <Fragment>
      <Container>
        <Mutation
          mutation={createUserRoleMutation}
          onCompleted={onCompleted}
          update={updateAfterMutation('UserRole', 'getUserRoles')}>
          {(addUser, { loading }) => (
            <Form
              form={form}
              layout="inline"
              onFinish={onSubmit(addUser)}
              initialValues={{
                role: 'ADMIN',
              }}>
              <FormItem name="role" label={t('Role')}>
                <RoleSelector />
              </FormItem>
              <FormItem name="email" label="Email" rules={[{ required: true, message: t('Type email') }]}>
                <Input type="email" />
              </FormItem>
              <FormItem>
                <Button style={{ marginTop: 0 }} type="primary" htmlType="submit" loading={loading}>
                  {t('Invite')}
                </Button>
              </FormItem>
            </Form>
          )}
        </Mutation>
      </Container>
      <Container style={{ marginTop: '1rem' }}>
        <Table
          pagination
          pageSize={5}
          dataType="UserRole"
          query={getUserRolesQuery}
          subscriptionQueries={[userRoleCreatedSubscription, userRoleChangedSubscription, userRoleRemovedSubscription]}
          removeRowMutation={removeUserRoleMutation}
          removalConfirmationMessage={`${t('Remove')}?`}
          variables={{}}
          rowKey="id"
          columns={[
            {
              title: t('Name'),
              dataIndex: 'name',
              render(_, { profile }) {
                return profile?.fullName;
              },
            },
            {
              title: t('Email'),
              dataIndex: 'email',
              render(_, { profile }) {
                return profile?.email;
              },
            },
            {
              title: t('Role'),
              dataIndex: 'role',
              width: 200,
              onChangeField,
              editable(record) {
                return record?.role !== 'OWNER';
              },
              inputAlwaysVisible: true,
              input: RoleSelector,
              inputProps(record) {
                return {
                  initialValue: record?.role,
                };
              },
              render(role) {
                return t(capitalize(role));
              },
            },
            {
              title: t('Status'),
              dataIndex: 'status',
              width: 180,
              render(status) {
                return t(capitalize(status));
              },
            },
            {
              title: t('Date'),
              dataIndex: 'createdAt',
              sorterKey: 'createdAt',
              width: 180,
              sorter: (a, b) => a - b,
              render(createdAt) {
                return formatDate(createdAt, 'DD.MM.YYYY HH:mm');
              },
            },
            {
              title: '',
              dataIndex: 'operation',
              render: (text, record) => (
                <div className="editable-row-operations">
                  <LinkButton to={`/clients/${record.id}`}>Подробнее</LinkButton>
                </div>
              ),
            },
          ]}
        />
      </Container>
    </Fragment>
  );
};

CompanyUsersTab.propTypes = { viewer: ViewerType.isRequired };
CompanyUsersTab.defaultProps = {};

export default compose(
  setDisplayName('CompanyUsersTab'),
  getContext({
    viewer: ViewerType,
  }),
  pure,
)(CompanyUsersTab);
