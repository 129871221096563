import React, { memo, useState, useCallback, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useApolloError } from '@fjedi/graphql-react-components';
import AuthBox from 'src/components/ui-kit/auth-box';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input } from 'src/components/ui-kit/input';
import Link from 'src/components/ui-kit/buttons/link';
import Button from 'src/components/ui-kit/buttons';
import { RequestPasswordResetMutationVariables, useRequestPasswordResetMutation } from 'src/graphql/generated';
import { StyledForm } from 'src/components/common/login';

const Help = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

const RequestSentContainer = styled.div`
  text-align: center;
`;
//
const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { resetFields } = form;
  const [requestStatus, setRequestStatus] = useState(false);
  const onError = useApolloError();

  const [requestPasswordReset, { loading }] = useRequestPasswordResetMutation({
    onError,
    onCompleted() {
      resetFields();
      setRequestStatus(true);
    },
  });

  const onSubmit = useCallback(
    (formData: unknown) => {
      requestPasswordReset({
        variables: formData as RequestPasswordResetMutationVariables,
      }).catch(onError);
    },
    [onError, requestPasswordReset],
  );

  return (
    <AuthBox title={t('Password reset')}>
      {requestStatus && (
        <RequestSentContainer>
          {t("We've sent to you an email that contains a link for setting new password")}
        </RequestSentContainer>
      )}
      {!requestStatus && (
        <StyledForm form={form} onFinish={onSubmit} layout="vertical">
          <FormItem name="email" label="Email" rules={[{ required: true, message: t('Please fill this field') }]}>
            <Input type="email" placeholder="Email" />
          </FormItem>
          <FormItem>
            <Button block loading={loading} type="primary" size="large" htmlType="submit">
              {t('Reset password')}
            </Button>
            <Help>
              <Link to="/login">{`${t('Already registered')}?`}</Link>
            </Help>
          </FormItem>
        </StyledForm>
      )}
    </AuthBox>
  );
};

ForgotPasswordPage.displayName = 'ForgotPasswordPage';

export default memo(ForgotPasswordPage);
