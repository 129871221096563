import React from 'react';
import styled from 'styled-components';
//
import { Drawer as AntDrawer } from 'antd';

const StyledDrawer = styled(AntDrawer)`
  .ant-drawer-body {
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const Drawer = props => <StyledDrawer {...props} />;

Drawer.propTypes = {};
Drawer.defaultProps = {};

export default React.memo(Drawer);
