import React, { useMemo, memo, FC, useCallback } from 'react';
import styled from 'styled-components';
import { Button, Form as AntForm, FormInstance, FormItemProps } from 'antd';
import { useTranslation } from 'react-i18next';
import time from 'src/helpers/time';
import TableFileUploader from 'src/components/ui-kit/file-uploader/extended';
import ImageModalPopup from 'src/components/ui-kit/modal-popup/image';

export type { FormInstance } from 'antd';

export function parseFormData<T extends Record<string, unknown>>(
  form: FormInstance<T>,
  formData: T,
  onlyChanged = false,
) {
  const input: Record<string, unknown> = {};
  //
  Object.keys(formData).forEach(field => {
    if (onlyChanged && !form.isFieldTouched(field)) {
      return;
    }
    // eslint-disable-next-line security/detect-object-injection
    const value = formData[field];
    if (field === 'location' && value && typeof value === 'object' && 'value' in value) {
      const { latitude, longitude } = value.value as { latitude: number; longitude: number };
      input.coordinates = [latitude, longitude];
    } else if (time.isDayjs(value)) {
      input.startTime = value.toISOString();
    } else {
      // eslint-disable-next-line security/detect-object-injection
      input[field] = value;
    }
  });

  return input;
}

export const Form = styled(AntForm)`
  &.ant-form {
    font-size: 0.875rem;
  }
  &.ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;
  }
`;
export const FormItem = styled(AntForm.Item)`
  &.ant-form-item {
    margin-bottom: 1.125rem;
    font-size: 0.875rem;

    .ant-form-item-label {
      line-height: 1.5rem;
      margin-bottom: 2px;

      label {
        font-weight: 600;
        font-size: 0.75rem;
        text-transform: uppercase;
      }
    }
  }
  &.ant-form-item-has-error {
    .ant-input,
    .ant-input-affix-wrapper {
      border-color: #e74c3c;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const MediaFileContainer = styled.div`
  position: relative;

  > div {
    &:first-of-type {
      text-align: left;
      flex-grow: 1;
      max-height: 200px;

      > img {
        display: inline-block;
        max-height: 200px;
        object-fit: contain;
      }
    }
  }

  > .ant-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.25rem;
  }
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ImageFormItem: FC<FormItemProps> = memo<FormItemProps>(({ value, onChange, style, className }) => {
  const { t } = useTranslation();
  const url = useMemo(() => (value && value.name ? URL.createObjectURL(value) : value), [value]);
  const resetValue = useCallback(() => {
    if (typeof onChange === 'function') {
      onChange(null);
    }
  }, [onChange]);

  return (
    <MediaFileContainer {...{ style, className }}>
      {!!value && <Button icon="close" shape="circle" danger onClick={resetValue} />}
      {!value && (
        <TableFileUploader onChange={onChange}>
          <Button type="primary">{t('Select')}</Button>
        </TableFileUploader>
      )}
      <ImageModalPopup src={url} />
    </MediaFileContainer>
  );
});
