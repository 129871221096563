/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export type TODO_ANY = any;

export enum TimeslotType {
  Events = 'EVENTS',
  Date = 'DATE',
  Weekday = 'WEEKDAY',
  Day = 'DAY',
}
export type DeviceType = 'arm' | 'tizen' | 'web0s' | 'chrome';
export type OrientationType = 0 | 90 | 180 | 270;

export type FlightStatusType = 'CANCELED' | 'DELAYED' | 'LANDED' | 'SCHEDULED';
export type FlightsDirectionType = 'ARRIVALS' | 'DEPARTURES';

export enum FLIGHTS_DIRECTION {
  Arrivals = 'ARRIVALS',
  Departures = 'DEPARTURES',
}

export enum MediaItemType {
  Clock = 'CLOCK',
  Datetime = 'DATETIME',
  Flights = 'FLIGHTS',
  Hls = 'HLS',
  Icon = 'ICON',
  Iframe = 'IFRAME',
  Image = 'IMAGE',
  LocationEvents = 'LOCATION_EVENTS',
  LocationEventLogo = 'LOCATION_EVENT_LOGO',
  LocationHeader = 'LOCATION_HEADER',
  LocationWayfinding = 'LOCATION_WAYFINDING',
  Logo = 'LOGO',
  Multicast = 'MULTICAST',
  Video = 'VIDEO',
  Weather = 'WEATHER',
  Youtube = 'YOUTUBE',
}

export enum MediaFolderType {
  Icon = 'ICON',
  Image = 'IMAGE',
  Logo = 'LOGO',
  Playlist = 'PLAYLIST',
  Template = 'TEMPLATE',
  Video = 'VIDEO',
}

export type FileType = {
  filename?: string | null;
  id: string;
  mime?: string | null;
  origin?: string | null;
  path: string;
  size?: number | null;
  url: string;
  userId?: string | null;
};

export type MediaFolder = {
  createdAt?: Date;
  id: string;
  isDefault?: boolean | null;
  items?: MediaItem[] | null;
  name: string;
  playlists?: PlaylistType[] | null;
  templates?: TemplateType[] | null;
  type: MediaFolderType;
  updatedAt?: Date;
};

export type MediaItem = {
  id: string;
  companyId?: string;
  projectId: string;
  folderId?: string | null;
  folder?: MediaFolder | null;
  type: MediaItemType;
  url?: string | null;
  title?: string | null;
  props?: TODO_ANY;
  file?: FileType | null;
  createdAt?: Date;
  updatedAt?: Date;
  duration: number;
};

export type TemplateAreaType = {
  id: string;
  title?: string | null;
  refreshInterval?: number | null;
  style?: { [k: string]: { [key: string]: string } };
  item?: MediaItem | null;
  playlist?: PlaylistType | null;
  createdAt?: Date;
  updatedAt?: Date;
  sequence: any;
};

export type TemplateType = {
  id: string;
  companyId?: string;
  title?: string | null;
  areas?: TemplateAreaType[] | null;
  bodyStyle?: { [k: string]: string | number } | null;
  createdAt?: Date;
  updatedAt?: Date;
  folderId: string;
  projectId: string;
};

export type PlaylistItemType = {
  id: string;
  duration: number;
  sequence: number;
  mediaItemId?: string | null;
  mediaItem?: MediaItem | null;
  playlistId?: string | null;
  createdAt?: Date;
  template?: TemplateType | null;
  updatedAt?: Date;
};

export type PlaylistType = {
  id: string;
  companyId?: string;
  projectId: string;
  title?: string | null;
  createdAt?: Date;
  updatedAt?: Date;
  folderId: string;
  items?: PlaylistItemType[] | null;
};

export type TimeslotItemType = {
  id: string;
  duration: number;
  sequence: number;
  mediaItem?: MediaItem | null;
  playlist?: PlaylistType | null;
  template?: TemplateType | null;
  createdAt?: Date;
  updatedAt?: Date;
};

export type Timeslot = {
  end?: Date;
  id: string;
  start?: Date;
  deviceId?: string | null;
  createdAt?: Date;
  updatedAt?: Date;
  scheduleId?: string;
  items?: TimeslotItemType[] | null;
  type: TimeslotType;
};

export type MediaFileType = {
  id: string;
  url: string;
  type: 'video' | 'image';
  path: string;
  status: string;
  extension: string;
};

export type ResolutionType = {
  width: number;
  height: number;
};

export type SettingsType = {
  deviceName: string;
  deviceType: DeviceType;
  orientation: OrientationType;
  invertVideoRotation: boolean;
  locationId: string | null;
  absTzOffset: number | null;
  resolution: ResolutionType;
  ratio: string;
};

export type FlightType = {
  actualDateLocal: Date;
  actualDateUtc: Date;
  airline: string;
  airportCode: string;
  city: string;
  dateLocal: Date;
  dateUtc: Date;
  estimatedDateLocal: Date;
  estimatedDateUtc: Date;
  flight: string;
  gate: string;
  id: string;
  status: FlightStatusType;
  terminal: string;
};

export type EventType = {
  id: string;
  title: string;
  description: string;
  logo: MediaItem;
  start: Date;
  end: Date;
  location: {
    name: string;
  };
};

export type EventLocationType = {
  id: string;
  name: string;
  events: Array<EventType>;
};

export type DeviceLocationType = {
  id: string;
  destinationLocation: EventLocationType;
  isVisible: boolean;
  icon: MediaItem;
};

export type ScheduleType = {
  Monday?: Timeslot[];
  Tuesday?: Timeslot[];
  Wednesday?: Timeslot[];
  Thursday?: Timeslot[];
  Friday?: Timeslot[];
  Saturday?: Timeslot[];
  Sunday?: Timeslot[];
};
