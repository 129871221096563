import get from 'lodash/get';
// React
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { pure, compose, withHandlers, withStateHandlers, setDisplayName } from 'recompose';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from 'src/components/ui-kit/icons';
import { Input } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';
//
const Container = styled.div`
  padding: 1rem;
`;

const Flex = styled.div`
  display: flex;
`;
//
const TableFilterDropdown = props => {
  const { onChange, value, onFilterSubmit, onFilterReset } = props;
  const { t } = useTranslation();
  return (
    <Container>
      <Input
        value={value}
        onChange={onChange}
        onPressEnter={onFilterSubmit}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
        autoFocus
      />
      <Flex>
        <Button
          type="primary"
          onClick={onFilterSubmit}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          {t('Find')}
        </Button>
        <Button onClick={onFilterReset} size="small" style={{ width: 90 }}>
          {t('Reset')}
        </Button>
      </Flex>
    </Container>
  );
};

TableFilterDropdown.propTypes = {
  onFilterReset: PropTypes.func.isRequired,
  onFilterSubmit: PropTypes.func.isRequired,
};
TableFilterDropdown.defaultProps = {};

export default compose(
  setDisplayName('TableFilterDropdown'),
  withStateHandlers(
    () => ({
      value: '',
    }),
    {
      onChange: () => event => {
        const value = [get(event, 'target.value', '')];
        return {
          value,
        };
      },
    },
  ),
  withHandlers({
    onFilterReset:
      ({ clearFilters, onChange }) =>
      () => {
        clearFilters();
        onChange();
      },
    onFilterSubmit:
      ({ setSelectedKeys, value, confirm }) =>
      () => {
        setSelectedKeys(value);
        confirm();
      },
  }),
  pure,
)(TableFilterDropdown);
