import React, { memo, FC } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Input as AntInput, InputNumber as AntInputNumber, InputProps } from 'antd';
import AntMaskedInput from 'antd-mask-input';
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';
import { darkenColor } from 'src/components/ui-kit/utilities';

const theme = {
  color: colorTheme.dark,
  colorFocus: darkenColor(colorGrays.gray700, 15),
  borderColor: colorGrays.gray500,
  borderColorFocus: darkenColor(colorGrays.gray500, 10),
  placeholder: colorGrays.gray500,
};

export const MaskedInput = styled(AntMaskedInput)`
  .ant-input,
  &.ant-input {
    background-color: #ffffff;
    font-size: 0.875rem;
    padding: 0 1rem;
    min-height: 2rem;
    border-radius: 0.3125rem;

    &:hover,
    &:focus {
      border-color: ${props => props.theme['primary-color']};
    }

    input:-webkit-autofill,
    &:-webkit-autofill {
      &,
      &:hover,
      &:focus,
      &:active {
        color: #2699fb !important;
        background: #ffffff !important;
        -webkit-box-shadow: 0 0 0 1000px white inset !important;
        -webkit-text-fill-color: #555555 !important;
      }
    }

    ::placeholder {
      color: #bce0fd;
    }
  }
  .ant-input-group-addon {
    background-color: ${props => props.theme['primary-color']};
    border-color: ${props => props.theme['primary-color']};
    padding: 0;
    color: #fff;
    font-size: 1rem;
  }
`;

export type { InputProps, InputNumberProps } from 'antd';

export const Input = styled(AntInput)`
  .ant-input,
  &.ant-input {
    color: ${theme.color};
    background-color: #ffffff;
    padding: 0 1rem;
    min-height: 2rem;
    //border-color: ${theme.borderColor};
    border-radius: 0.375rem;

    &:hover,
    &:focus {
      color: ${theme.colorFocus};
      border-color: ${theme.borderColorFocus};
      box-shadow: none;
    }

    input:-webkit-autofill,
    &:-webkit-autofill {
      &,
      &:hover,
      &:focus,
      &:active {
        color: #2699fb !important;
        background: #ffffff !important;
        -webkit-box-shadow: 0 0 0 1000px white inset !important;
        -webkit-text-fill-color: #555555 !important;
      }
    }

    ::placeholder {
      color: ${theme.placeholder};
    }

    &-disabled {
      &:hover,
      &:focus {
        color: rgba(0, 0, 0, 0.25);
      }
    }

    &-sm {
      min-height: 2rem;
    }

    &-lg {
      min-height: 3rem;
    }

    &-affix {
      &-wrapper {
        padding: 0;
        border-radius: 0.375rem;
        height: 2.375rem;
        box-shadow: none;
        color: ${theme.color};

        &:hover,
        &:focus,
        &-focused {
          border-color: ${theme.borderColorFocus};
        }

        .ant-input {
          min-height: auto;
        }
        &-sm {
          height: 32px;
        }
        &-lg {
          height: 48px;
        }
      }
    }
    &-prefix,
    &-suffix {
      display: flex;
      align-items: center;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      margin-right: 0;
      font-size: 18px;
      span {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        margin-left: -0.625rem;
        margin-right: -0.625rem;
        display: flex;
        align-items: center;
        align-self: stretch;
      }
    }
  }

  .ant-input-group-addon {
    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
`;

const Label = styled.div`
  padding: 0 1rem;
`;

export const InputPrefixLabel: FC<{ children: React.ReactNode }> = ({ children, ...props }) => (
  <Label {...props}>{children}</Label>
);

export const TextArea = styled(AntInput.TextArea)`
  &.ant-input {
    color: ${theme.color};
    border-radius: 0.375rem;

    &:hover,
    &:focus {
      color: ${theme.colorFocus};
      border-color: ${theme.borderColorFocus};
      box-shadow: none;
    }
  }
`;

export const InputNumber = styled(AntInputNumber)`
  &.ant-input-number {
    border-radius: 0.375rem;

    &:hover,
    &:focus {
      border-color: ${theme.borderColorFocus};
    }

    &-focused {
      box-shadow: none;
      border-color: ${theme.borderColorFocus};
    }
  }
  .ant-input-number-input {
    height: 2.375rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .ant-input-number-handler-wrap {
    border-radius: 0 0.375rem 0.375rem 0;
  }
`;

export const Search = styled(AntInput.Search)`
  &.ant-input-search {
    padding: 0;
    border-radius: 0.375rem;
    box-shadow: none;
    height: 2.375rem;

    &:hover,
    &:focus {
      border-color: ${theme.borderColorFocus};
      > .ant-input {
        color: ${theme.colorFocus};
      }
    }

    .ant-input-prefix,
    .ant-input-suffix {
      display: flex;
      span {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        display: flex;
        align-items: center;
      }
    }

    > .ant-input {
      color: ${theme.color};
      border: 0;
      background-color: transparent;
      padding: 0 0 0 1rem;
      border-radius: 0.375rem;
    }
  }

  &.ant-input-affix-wrapper-focused {
    border-color: ${theme.borderColorFocus};
  }

  .ant-input-group {
    > .ant-input-affix-wrapper:not(:last-child) {
      border-top-left-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem;
      border-color: ${theme.borderColor};
    }
    .ant-input-group-addon:last-child .ant-input-search-button {
      color: ${theme.color};
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem;
      background-color: #ffffff;
      border-color: ${theme.borderColor};
      box-shadow: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-left-width: 0;

      .anticon {
        line-height: 0;
      }
    }
  }
`;
export const PasswordInput = styled(AntInput.Password)`
  &.ant-input-password {
    background-color: #ffffff;
    padding: 0;
    border-radius: 0.375rem;
    box-shadow: none;

    &:hover,
    &:focus {
      border-color: ${theme.borderColorFocus};
      > .ant-input {
        color: ${theme.colorFocus};
      }
    }

    .ant-input-prefix,
    .ant-input-suffix {
      display: flex;
      span {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        display: flex;
        align-items: center;
      }
    }

    > .ant-input {
      color: ${theme.color};
      border: 0;
      background-color: transparent;
      outline: none;
      padding: 0 1rem;
      min-height: 2rem;
      border-radius: 0.375rem;

      input:-webkit-autofill,
      &:-webkit-autofill {
        &,
        &:hover,
        &:focus,
        &:active {
          color: #2699fb !important;
          background: #ffffff !important;
          -webkit-box-shadow: 0 0 0 1000px white inset !important;
          -webkit-text-fill-color: #555555 !important;
        }
      }
    }
  }
  &.ant-input-affix-wrapper-focused {
    border-color: ${theme.borderColorFocus};
  }

  .ant-input-group-addon {
    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
`;

export type ColorPaletteProps = {
  disabled?: boolean;
};

const ColorPalette = styled.div<ColorPaletteProps>`
  position: relative;
  display: flex;
  align-items: center;
  color: ${props => (props.disabled === true ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.85)')};

  ${Input}.ant-input {
    padding-left: calc(100% - 3rem);
    background-color: ${props => (props.disabled === true ? '#f5f5f5' : '#ffffff')};
    color: ${props => (props.disabled === true ? 'rgba(0, 0, 0, 0.25)' : '#000000')};
  }
`;

const ColorValue = styled.div`
  position: absolute;
  left: 1px;
  top: 1px;
  bottom: 1px;
  right: 4rem;

  ${Input}.ant-input {
    height: 100%;
    min-height: auto;
    padding-left: 1rem;
    border-color: transparent;
    box-shadow: none;
    &:hover,
    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }
`;

export interface ColorInputProps extends Pick<InputProps, 'value' | 'onChange'> {
  isDisabled?: boolean;
}

export const ColorInput = memo<ColorInputProps>(props => {
  const { isDisabled = false, value, onChange } = props;
  const { t } = useTranslation();

  return (
    <ColorPalette disabled={isDisabled}>
      <Input type="color" value={value} onChange={onChange} placeholder={t('Select color')} disabled={isDisabled} />
      <ColorValue>
        <Input value={value} onChange={onChange} disabled={isDisabled} />
      </ColorValue>
    </ColorPalette>
  );
});
