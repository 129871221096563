/* eslint-disable security/detect-object-injection */
import { Col, Form, Row } from 'antd';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { RiArrowLeftSLine, RiArrowUpSLine } from 'react-icons/ri';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { compareIds } from 'src/functions';

import { GOOGLE_PLACES_API_KEY } from 'src/constants';

import startIcon from 'static/images/undraw_photo.svg';

import { arrayMove } from 'src/helpers/array-move';
import logger from 'src/helpers/logger';
//
import AirportSelector from 'src/components/common/airport-selector';
import { FontSelector } from 'src/components/common/font-selector';
import { ScreenRatioSelector } from 'src/components/common/screen-ratio-selector';
import { TimezoneSelector } from 'src/components/common/timezone-selector';
import { Title } from 'src/components/ui-kit/aside';
import Button from 'src/components/ui-kit/buttons';
import { Collapse, Panel } from 'src/components/ui-kit/collapse';
import { ContextMenu, ContextMenuItem } from 'src/components/ui-kit/context-menu';
import { DragDropContext, DraggableItem, DroppableArea } from 'src/components/ui-kit/drag-n-drop';
import Drawer from 'src/components/ui-kit/drawer';
import { ColorInput, Input, InputNumber } from 'src/components/ui-kit/input';
import ScrollbarCustom from 'src/components/ui-kit/scrollbar';
import Select, { Option } from 'src/components/ui-kit/select';
import { Switch } from 'src/components/ui-kit/switch';
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';
import { TEMPLATE_TYPES } from './constants';
import { EditorContext, EditorMenuContext, EditorSizeContext } from './context';
import { collapsePanel, getRatioStyle } from './helpers';
import HorizontalAlignmentSettingInput from './horizontal-alignment-setting-input';
import { AddTemplateItemMenu } from './template-area-list-add-item-menu';
import TemplateAreaListItem from './template-area-list-item';
import { FormItem, SettingsInput } from './template-area-settings-input';
import { WeatherIconsSet } from './widgets/weather/icons';

const AsideWrapper = styled.div`
  background-color: #ffffff;
  width: 20rem;
  height: 100%;
  position: relative;
  border-radius: 0.625rem;

  box-shadow: 0 0.125rem 0.875rem rgba(0, 0, 0, 0.08);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  ${FormItem} {
    input,
    .ant-select-selector {
      max-height: 2.25rem;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs > .ant-tabs-nav {
      margin: 0;

      & > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
        & > .ant-tabs-tab {
          border-color: ${colorGrays.gray500};
          color: ${colorGrays.gray500};
          background-color: #fff;

          &:first-of-type {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          &:nth-last-child(2) {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }

          & > .ant-tabs-tab-btn {
            color: ${colorGrays.gray500};
          }

          &.ant-tabs-tab-active {
            & > .ant-tabs-tab-btn {
              color: ${colorTheme.dark};
            }
          }
        }
      }
    }
  }
`;

const SettingsInputGroup = styled.div`
  display: flex;
  justify-content: space-between;

  & > .ant-col.ant-col-12 {
    max-width: calc(50% - 0.625rem);
  }
`;

const Header = styled.div`
  padding: 1.25rem 1.875rem 1.25rem;
`;

const TitleText = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.2;
  padding: 0 1.875rem;
  min-height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// const SubTitle = styled.div`
//   font-size: 0.875rem;
//   text-align: center;
//   color: rgba(0, 0, 0, 0.2);
//   margin-bottom: 0.5rem;
// `;

const Divider = styled.div`
  height: 0.5rem;
  min-height: 0.5rem;
  margin-top: -0.5rem;
  position: relative;
  z-index: 2;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    top: -0.5rem;
  }
`;

const FOOTER_HEIGHT = '3.125rem';
const ABSOLUTE_POSITIONING_MAX_NEGATIVE_OFFSET = -2000;

const FooterComponent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  box-shadow: 0px -0.125rem 1.5rem rgba(0, 0, 0, 0.08);

  .ant-btn {
    height: ${FOOTER_HEIGHT};
    font-size: 0.875rem;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
`;

const Scrollbar = styled(ScrollbarCustom)`
  min-height: auto !important;
`;

const Start = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  justify-content: center;
`;

const StartIcon = styled.img`
  width: 15rem;
  margin-bottom: 0.5rem;
`;

const StartTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
`;

const StartDesc = styled.div`
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.2);
`;

const TemplateListContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  padding-bottom: 3.0625rem;
  z-index: 1;
`;

const StyledTimezone = styled(TimezoneSelector)`
  min-width: unset;
`;

const SwitchablePanel = styled(Panel)`
  .ant-collapse-header {
    .ant-collapse-header-text {
      display: flex;
      width: 90%;
    }
  }
`;

const SwitchablePanelHeader = styled.div.attrs({ className: 'switchable-panel-header' })`
  display: flex;
  align-items: center;
  width: 90%;

  & > span {
    flex-grow: 1;
  }

  & > button > * {
    pointer-events: none;
  }
`;

const Timezone = memo(timezoneProps => {
  const { t } = useTranslation();
  return (
    <StyledTimezone
      {...timezoneProps}
      defaultValue={{
        value: '',
        label: t('Device Timezone'),
      }}
    />
  );
});

export const SwitchContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  height: 2.25rem;
  border-radius: 0.375rem;
  padding: 0 1rem;
  cursor: ${props => (props.disabled === true ? 'not-allowed' : 'default')};
  color: ${props => (props.disabled === true ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.85)')};
  background-color: ${props => (props.disabled === true ? '#f5f5f5' : '#ffffff')};

  & > .ant-col.ant-col-24 {
    flex-shrink: 1;

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }

    & > .ant-row {
      margin: 0;
    }
  }

  & > .snap {
    flex-grow: 1;
  }
`;

const PlacesAutocompleteContainer = styled.div`
  position: relative;
`;

const PlacesAutocompleteDropdown = styled.div`
  overflow: hidden;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
`;

const PlacesAutocompleteItem = styled.div`
  cursor: pointer;
  padding: 0.375rem 1.25rem;
  color: ${props => (props.active === true ? '#ffffff' : 'rgba(0, 0, 0, 0.85)')};
  background-color: ${props => (props.active === true ? '#6a75ca' : '#ffffff')};
  transition: background-color 0.2s;
`;

function stripCSSUnits(units = 'px', defaultValue = 0) {
  return v => {
    if (v && typeof v !== 'undefined') {
      const parsedValue = `${v}`.replace(units, '');
      //
      return (typeof defaultValue === 'number' ? parseInt(parsedValue, 10) : parsedValue) || defaultValue;
    }
    return defaultValue;
  };
}

function appendCSSUnits(units = 'px', mode = 'suffix', defaultValue = 0) {
  return v => {
    const value = v ? `${mode === 'prefix' ? units : ''}${v}${mode === 'suffix' ? units : ''}` : defaultValue;
    if (typeof defaultValue === 'number') {
      return parseInt(`${value}`, 10) || defaultValue;
    }
    return value;
  };
}

function getUpdatedGridSizeSettings(gridConfig, size, scale) {
  const updated = {};

  updated.byTemplate = {
    [gridConfig.templateId]: {
      size,
      style: {
        'background-size': `${size * scale}px ${size * scale}px`,
      },
    },
  };
  return updated;
}

export const TemplateAreaList = ({ onSelectRatio, globalSettingsStatus, onCloseGlobalSettings }) => {
  const { t } = useTranslation();
  const {
    ratio,
    unsavedChanges,
    onChange,
    onGlobalSettingsChange,
    onGridSettingsChanged,
    onRemove,
    selectedArea,
    setSelectedArea,
    gridConfig,
  } = useContext(EditorContext);
  const { canvas, scale } = useContext(EditorSizeContext);
  const [settingsArea, setSettingsArea] = useState(false);
  const areaId = settingsArea?.id;
  const ratioId = ratio?.id;
  const baseHeight = ratio?.baseHeight || 0;
  const baseWidth = ratio?.baseWidth || 0;
  const area = useMemo(
    () => unsavedChanges?.areas?.find(a => compareIds(a?.id, areaId)) ?? null,
    [areaId, unsavedChanges?.areas],
  );
  const areaStyle = getRatioStyle(area, ratioId);
  const opacity = areaStyle?.backgroundOpacity ?? 0;
  const hex = areaStyle?.backgroundHex ?? '#000000';
  const areas = sortBy(unsavedChanges?.areas ?? [], 'sequence', '')
    .reverse()
    .map((a, aIndex) => ({
      ...a,
      sequence: aIndex + 1,
    }));
  //

  const onBackgroundColorChange = useCallback(
    e => {
      onGlobalSettingsChange({
        bodyStyle: {
          'background-color': e.target.value,
        },
      });
    },
    [onGlobalSettingsChange],
  );

  const onGridEnabledChanged = useCallback(
    e => {
      const enabled = e;
      const snap = !!(enabled && gridConfig?.currentStyle?.snap);
      const updated = {};
      updated.byTemplate = {
        [gridConfig.templateId]: {
          enabled,
          snap,
        },
      };
      onGridSettingsChanged(updated);
    },
    [onGridSettingsChanged, gridConfig],
  );

  const onGridSnapChanged = useCallback(
    e => {
      const snap = e;
      const updated = {};
      updated.byTemplate = {
        [gridConfig.templateId]: {
          snap,
        },
      };
      onGridSettingsChanged(updated);
    },
    [onGridSettingsChanged, gridConfig],
  );

  const onGridSizeChanged = useCallback(
    size => {
      const updated = getUpdatedGridSizeSettings(gridConfig, size, scale);

      onGridSettingsChanged(updated);
    },
    [onGridSettingsChanged, gridConfig, scale],
  );

  const onGridTransparencyChanged = useCallback(
    transparency => {
      const gridOpacity = (100 - transparency) / 100;

      const updated = {};
      updated.byTemplate = {
        [gridConfig.templateId]: {
          opacity: gridOpacity,
          style: {
            opacity: gridOpacity,
          },
        },
      };

      onGridSettingsChanged(updated);
    },
    [gridConfig, onGridSettingsChanged],
  );

  useEffect(() => {
    const { size } = gridConfig?.currentStyle ?? {};
    const updated = getUpdatedGridSizeSettings(gridConfig, size, scale);
    onGridSettingsChanged(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridConfig.templateId, scale]);

  useEffect(() => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const rgbFromHex = result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
    if (!rgbFromHex) {
      return;
    }
    const opacityRgba = (100 - opacity) / 100;
    const rgba = `rgba(${rgbFromHex.r}, ${rgbFromHex.g}, ${rgbFromHex.b}, ${opacityRgba})`;
    onChange(areaId, {
      style: {
        [ratioId]: {
          backgroundColor: rgba,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opacity, hex]);

  const onGridBackgroundInputChanged = useCallback(
    e => {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(e.target.value);
      const rgbFromHex = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      const updated = {};
      if (!rgbFromHex) {
        updated.byTemplate = {
          [gridConfig.templateId]: {
            color: e.target.value,
          },
        };
        onGridSettingsChanged(updated);
        return;
      }
      const rgba = `rgba(${rgbFromHex.r}, ${rgbFromHex.g}, ${rgbFromHex.b}, 0.5)`;
      updated.byTemplate = {
        [gridConfig.templateId]: {
          color: e.target.value,
          style: {
            'background-image': `linear-gradient(${rgba} 1px, transparent 1px),
  linear-gradient(90deg, ${rgba} 1px, transparent 1px)`,
          },
        },
      };

      onGridSettingsChanged(updated);
    },
    [gridConfig.templateId, onGridSettingsChanged],
  );
  //
  const onSettingsOpen = useCallback(
    a => () => {
      setSettingsArea(a);
      setSelectedArea(a?.id || a);
    },
    [setSettingsArea, setSelectedArea],
  );
  const onSettingsClose = useCallback(() => {
    setSettingsArea(false);
    setSelectedArea(null);
  }, [setSettingsArea, setSelectedArea]);
  //
  const [menuStatus, setMenuStatus] = useState(false);
  const onMenuOpen = useCallback(() => {
    setMenuStatus(true);
  }, []);
  const onMenuClose = useCallback(() => {
    setMenuStatus(false);
  }, []);
  const onItemRemove = useCallback(
    item => event => {
      logger('TemplateArea.onRemove', {
        event,
        item,
      });
      event.stopPropagation();
      onRemove(item.id);
    },
    [onRemove],
  );
  const onDragEnd = useCallback(
    result => {
      if (!result) {
        return;
      }
      const { destination, source } = result;
      if (source?.droppableId !== destination?.droppableId) {
        return;
      }
      const areaList = arrayMove(areas, source.index - 1, destination.index - 1);
      areaList.reverse().forEach((a, aIndex) => {
        onChange(a.id, { sequence: aIndex + 1 });
      });
    },
    [onChange, areas],
  );

  const onLayoutChange = useCallback(
    val => {
      const { top, left } = areaStyle;
      const width = parseInt(areaStyle?.width, 10);
      const height = parseInt(areaStyle?.height, 10);
      //
      logger('onLayoutChange', {
        val,
        areaId,
        ratioId,
        top,
        left,
        width,
        height,
      });
      switch (val) {
        case 'left':
          onChange(areaId, {
            style: {
              [ratioId]: {
                left: 0,
                right: width,
              },
            },
          });
          break;
        case 'right':
          onChange(areaId, {
            style: {
              [ratioId]: {
                left: canvas.width - width,
                right: 0,
              },
            },
          });
          break;
        case 'leftRight': {
          const offset = canvas.width / 2 - width / 2;
          onChange(areaId, {
            style: {
              [ratioId]: {
                left: offset,
                right: offset,
              },
            },
          });
          break;
        }
        case 'top':
          onChange(areaId, {
            style: {
              [ratioId]: {
                top: 0,
                bottom: canvas.height - height,
              },
            },
          });
          break;
        case 'bottom':
          onChange(areaId, {
            style: {
              [ratioId]: {
                bottom: 0,
                top: canvas.height - height,
              },
            },
          });
          break;
        case 'center': {
          const offset = canvas.height / 2 - height / 2;
          onChange(areaId, {
            style: {
              [ratioId]: {
                top: offset,
                bottom: offset,
              },
            },
          });
          break;
        }
        default:
          logger('Unknown value passed to positioning handler', { val });
      }
    },
    [areaId, areaStyle, ratioId, canvas.height, canvas.width, onChange],
  );

  const handleSwitchablePanelHeaderClick = useCallback(event => collapsePanel(event), []);

  const toggleLayoutGridEnabled = useCallback(
    () => onGridEnabledChanged(!gridConfig?.currentStyle?.enabled),
    [onGridEnabledChanged, gridConfig?.currentStyle?.enabled],
  );

  const layoutGridPanelHeaderRef = useRef();
  const layoutGridPanelHeader = useMemo(() => {
    const isLayoutGridEnabled = gridConfig?.currentStyle?.enabled;
    const isExpanded = layoutGridPanelHeaderRef?.current?.offsetParent?.ariaExpanded === 'true';
    const shouldCollapse = !isLayoutGridEnabled && isExpanded;

    if (shouldCollapse) {
      layoutGridPanelHeaderRef.current.offsetParent.click();
    }

    return (
      <SwitchablePanelHeader onClick={handleSwitchablePanelHeaderClick}>
        <span>{t('Layout grid')}</span>
        <Switch checked={gridConfig?.currentStyle?.enabled} onChange={toggleLayoutGridEnabled} />
      </SwitchablePanelHeader>
    );
  }, [gridConfig?.currentStyle?.enabled, handleSwitchablePanelHeaderClick, t, toggleLayoutGridEnabled]);

  //
  const ref = useRef();
  // const onClickOutside = useCallback(() => {
  //   onMenuClose();
  //   onCloseGlobalSettings();
  // }, [onMenuClose, onCloseGlobalSettings]);
  // useClickOutside(ref, onClickOutside);

  useEffect(() => {
    if (settingsArea && !compareIds(selectedArea, settingsArea?.id)) {
      setSettingsArea(unsavedChanges?.areas?.find(a => compareIds(a.id, selectedArea)));
    }
  }, [selectedArea, settingsArea, unsavedChanges]);
  //
  const context = useMemo(
    //
    () => ({
      onMenuClose,
      onMenuOpen,
      menuStatus,
    }),
    [onMenuClose, onMenuOpen, menuStatus],
  );
  //
  const areaType = useMemo(() => {
    if (settingsArea?.playlist) {
      return TEMPLATE_TYPES.PLAYLIST;
    }

    return settingsArea?.item?.type ?? TEMPLATE_TYPES.TEXT;
  }, [settingsArea?.item?.type, settingsArea?.playlist]);
  const areaTopPosition = parseInt(areaStyle?.top, 10);
  const areaLeftPosition = parseInt(areaStyle?.left, 10);
  const areaHeight = parseInt(areaStyle?.height, 10);
  const areaWidth = parseInt(areaStyle?.width, 10);

  const toggleBackgroundEnabled = useCallback(
    () =>
      onChange(areaId, {
        style: {
          [ratioId]: {
            backgroundEnabled: !area?.style[ratioId]?.backgroundEnabled,
          },
        },
      }),
    [onChange, areaId, ratioId, area?.style],
  );

  const backgroundPanelHeaderRef = useRef();
  const backgroundPanelHeader = useMemo(() => {
    const isBackgroundEnabled = !!area?.style[ratioId]?.backgroundEnabled;
    const isExpanded = backgroundPanelHeaderRef?.current?.offsetParent?.ariaExpanded === 'true' ?? false;
    const shouldCollapse = !isBackgroundEnabled && isExpanded;

    if (shouldCollapse) {
      backgroundPanelHeaderRef.current.offsetParent.click();
    }

    return (
      <SwitchablePanelHeader onClick={handleSwitchablePanelHeaderClick}>
        <span>{t('Background')}</span>
        <Switch checked={area?.style[ratioId]?.backgroundEnabled} onChange={toggleBackgroundEnabled} />
      </SwitchablePanelHeader>
    );
  }, [handleSwitchablePanelHeaderClick, t, area?.style, ratioId, toggleBackgroundEnabled]);

  const toggleSeparator = useCallback(
    () =>
      onChange(areaId, {
        item: {
          props: {
            separatorEnabled: !area?.item?.props?.separatorEnabled,
            separatorSize: !area?.item?.props?.separatorEnabled ? 1 : 0,
          },
        },
      }),
    [area?.item?.props?.separatorEnabled, areaId, onChange],
  );

  const separatorPanelHeaderRef = useRef();
  const separatorPanelHeader = useMemo(() => {
    const isSeparatorEnabled = area?.item?.props?.separatorEnabled;
    const isExpanded = separatorPanelHeaderRef?.current?.offsetParent?.ariaExpanded === 'true';
    const shouldCollapse = !isSeparatorEnabled && isExpanded;

    if (shouldCollapse) {
      separatorPanelHeaderRef.current.offsetParent.click();
    }

    return (
      <SwitchablePanelHeader ref={separatorPanelHeaderRef} onClick={handleSwitchablePanelHeaderClick}>
        <span>{t('Separator')}</span>
        <Switch checked={area?.item?.props?.separatorEnabled} onChange={toggleSeparator} />
      </SwitchablePanelHeader>
    );
  }, [area?.item?.props?.separatorEnabled, handleSwitchablePanelHeaderClick, t, toggleSeparator]);

  const togglePagination = useCallback(
    () => onChange(areaId, { item: { props: { paginationEnabled: !area?.item?.props?.paginationEnabled } } }),
    [area?.item?.props?.paginationEnabled, areaId, onChange],
  );

  const paginationPanelHeaderRef = useRef();
  const paginationPanelHeader = useMemo(() => {
    const isPaginationEnabled = area?.item?.props?.paginationEnabled;
    const isExpanded = paginationPanelHeaderRef?.current?.offsetParent?.ariaExpanded === 'true';
    const shouldCollapse = !isPaginationEnabled && isExpanded;

    if (shouldCollapse) {
      paginationPanelHeaderRef.current.offsetParent.click();
    }

    return (
      <SwitchablePanelHeader ref={paginationPanelHeaderRef} onClick={handleSwitchablePanelHeaderClick}>
        <span>{t('Pagination')}</span>
        <Switch checked={area?.item?.props?.paginationEnabled} onChange={togglePagination} />
      </SwitchablePanelHeader>
    );
  }, [area?.item?.props?.paginationEnabled, handleSwitchablePanelHeaderClick, t, togglePagination]);

  const onHorizontalAlignmentChange = useCallback(
    alignment =>
      onChange(areaId, {
        style: {
          [ratioId]: {
            textAlign: alignment,
            'text-align': alignment,
          },
        },
      }),
    [areaId, onChange, ratioId],
  );

  const [weatherLocationName, setWeatherLocationName] = useState({ address: 'London' });
  const handleLocationChange = useCallback(address => setWeatherLocationName({ address }), [setWeatherLocationName]);

  const handleLocationSelect = useCallback(
    address => {
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(location => {
          onChange(areaId, {
            item: {
              props: {
                location: {
                  name: address,
                  lat: Math.floor(location.lat * 1000) / 1000,
                  lng: Math.floor(location.lng * 1000) / 1000,
                },
              },
            },
          });
        })
        .catch(logger);
      setWeatherLocationName({ address });
    },
    [areaId, onChange],
  );

  const headerTitle = useMemo(() => {
    let title = 'Item list';

    if (area) {
      title = capitalize((area.item?.type ?? areaType ?? area.title ?? 'Item').replace(/_/g, ' '));
      title += ' settings';
    }

    return title;
  }, [area, areaType]);

  return (
    <EditorMenuContext.Provider value={context}>
      <AsideWrapper ref={ref}>
        <Helmet>
          <script src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places`} async />
        </Helmet>
        <Drawer
          width="100%"
          placement="right"
          closable={false}
          visible={globalSettingsStatus}
          style={{ position: 'absolute' }}
          getContainer={false}>
          <TitleText>{t('Template settings')}</TitleText>
          <Divider />
          <Scrollbar>
            <Form layout="vertical">
              <Collapse
                accordion
                bordered={false}
                expandIconPosition="right"
                style={{ marginBottom: '3.125rem' }}
                defaultActiveKey={['1']}
                expandIcon={RiArrowUpSLine}>
                <Panel header={t('Background')} key="1">
                  <FormItem label={t('Color')} style={{ marginBottom: 0 }}>
                    <ColorInput
                      value={unsavedChanges?.bodyStyle?.['background-color'] || '#c4c4c4'}
                      onChange={onBackgroundColorChange}
                      defaultValue="#c4c4c4"
                    />
                  </FormItem>
                </Panel>
                <Panel header={t('Dimensions')} key="2">
                  <ScreenRatioSelector value={ratio?.id} onChange={onSelectRatio} style={{ width: '100%' }} />
                </Panel>
                <SwitchablePanel
                  header={layoutGridPanelHeader}
                  key="3"
                  collapsible={!gridConfig?.currentStyle?.enabled && 'disabled'}>
                  <Row gutter={24}>
                    <Col span={12}>
                      <FormItem label={t('Size')}>
                        <InputNumber
                          type="number"
                          min={20}
                          max={600}
                          value={gridConfig?.currentStyle?.size ?? gridConfig?.global?.size ?? 40}
                          defaultValue={40}
                          onChange={onGridSizeChanged}
                          placeholder={t('Set grid size')}
                          disabled={!gridConfig?.currentStyle?.enabled}
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem label={t('Transparency (%)')}>
                        <InputNumber
                          type="number"
                          placeholder={t('Set grid transparency')}
                          min={0}
                          max={100}
                          value={100 - Math.round(100 * (gridConfig?.currentStyle?.opacity ?? 0.4))}
                          defaultValue={60}
                          onChange={onGridTransparencyChanged}
                          disabled={!gridConfig?.currentStyle?.enabled}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <FormItem label={t('Color')}>
                    <ColorInput
                      isDisabled={!gridConfig?.currentStyle?.enabled}
                      value={gridConfig?.currentStyle?.color ?? gridConfig?.global?.color ?? '#FF0000'}
                      onChange={onGridBackgroundInputChanged}
                      defaultValue="#ff0000"
                    />
                  </FormItem>
                  <FormItem label={t('Sticky Borders')}>
                    <SwitchContainer disabled={!gridConfig?.currentStyle?.enabled}>
                      <div>{gridConfig?.currentStyle?.snap ? t('Enabled') : t('Disabled')}</div>
                      <Switch
                        onChange={onGridSnapChanged}
                        checked={gridConfig?.currentStyle?.snap ?? false}
                        disabled={!gridConfig?.currentStyle?.enabled}
                      />
                    </SwitchContainer>
                  </FormItem>
                </SwitchablePanel>
              </Collapse>
            </Form>
          </Scrollbar>
          <FooterComponent>
            <Button onClick={onCloseGlobalSettings} type="link" icon={<RiArrowLeftSLine />}>
              {t('Item list')}
            </Button>
          </FooterComponent>
        </Drawer>
        <Header>
          <Title type="title" level={5}>
            {headerTitle}
          </Title>
          {/* <SubTitle>{t('Add items in this template')}</SubTitle> */}
        </Header>
        <Divider />
        <TemplateListContainer>
          <Scrollbar>
            <DragDropContext onDragEnd={onDragEnd}>
              {!areas?.length && (
                <Start>
                  <StartIcon src={startIcon} alt="" />
                  <StartTitle>{t('Add items')}</StartTitle>
                  <StartDesc>{t('Click the button below')}</StartDesc>
                </Start>
              )}
              <DroppableArea droppableId="template-areas">
                {areas?.map(a => (
                  <DraggableItem key={a.id} draggableId={a.id} index={a.sequence}>
                    <ContextMenu id={a.id}>
                      <ContextMenuItem onClick={onSettingsOpen(a)}>{t('Open settings')}</ContextMenuItem>
                      <ContextMenuItem divider />
                      <ContextMenuItem onClick={onItemRemove(a)}>{t('Remove')}</ContextMenuItem>
                    </ContextMenu>
                    <TemplateAreaListItem data={a} onRemove={onItemRemove(a)} onSettingsOpen={onSettingsOpen(a)} />
                  </DraggableItem>
                ))}
              </DroppableArea>
            </DragDropContext>
          </Scrollbar>

          <FooterComponent>
            <AddTemplateItemMenu />
          </FooterComponent>

          <Drawer
            width="100%"
            placement="right"
            closable={false}
            onClose={onSettingsClose}
            visible={!!settingsArea}
            style={{ position: 'absolute' }}
            getContainer={false}>
            <Scrollbar>
              <Collapse
                accordion
                bordered={false}
                expandIconPosition="right"
                style={{ marginBottom: '3.125rem' }}
                defaultActiveKey={['1']}
                expandIcon={RiArrowUpSLine}>
                <Panel header={t('Settings')} key="1">
                  <Form layout="vertical">
                    <SettingsInput
                      label={areaType === TEMPLATE_TYPES.TEXT ? t('Text') : t('Title')}
                      htmlType={areaType === TEMPLATE_TYPES.TEXT ? 'textarea' : undefined}
                      field="title"
                      placeholder={areaType === TEMPLATE_TYPES.TEXT ? t('Enter your text') : ''}
                      defaultValue=""
                    />

                    {areaType === TEMPLATE_TYPES.PLAYLIST && (
                      <SettingsInput
                        label={t('Duration (sec)')}
                        gridColumns={24}
                        htmlType="number"
                        field="playlist.duration"
                        disabled
                      />
                    )}

                    {areaType === TEMPLATE_TYPES.VIDEO && (
                      <SettingsInput
                        label={t('Duration (sec)')}
                        gridColumns={24}
                        htmlType="number"
                        field="item.duration"
                        disabled
                      />
                    )}

                    {areaType === TEMPLATE_TYPES.FLIGHTS && (
                      <>
                        <SettingsInput
                          label={t('Airport')}
                          field="item.props.airport"
                          htmlType="select"
                          gridColumns={48}
                          defaultValue={area?.item?.props?.airport ?? 'DXB'}
                          component={AirportSelector}
                        />
                        <SettingsInput
                          label={t('Arrival / Departure')}
                          field="item.props.direction"
                          htmlType="select"
                          gridColumns={48}
                          defaultValue="Arrival">
                          <Option value="ARRIVALS">Arrival</Option>
                          <Option value="DEPARTURES">Departure</Option>
                        </SettingsInput>
                      </>
                    )}

                    {TEMPLATE_TYPES.LOCATION_WAYFINDING === areaType && (
                      <SettingsInput
                        label={t('Visible rows')}
                        htmlType="number"
                        field="item.props.visibleRows"
                        placeholder={t('Enter number between 1 and 10')}
                        defaultValue={4}
                        min={1}
                        max={10}
                      />
                    )}
                    {TEMPLATE_TYPES.FLIGHTS === areaType && (
                      <SettingsInputGroup>
                        <SettingsInput
                          label={t('Visible rows')}
                          htmlType="number"
                          field="item.props.visibleRows"
                          placeholder={t('Enter number between 1 and 15')}
                          defaultValue={7}
                          min={1}
                          max={15}
                        />
                        <SettingsInput
                          label={t('Row height (px)')}
                          htmlType="number"
                          field="item.props.rowHeight"
                          defaultValue={72}
                          min={40}
                          max={300}
                        />
                      </SettingsInputGroup>
                    )}

                    {areaType === TEMPLATE_TYPES.LOCATION_WAYFINDING && (
                      <FormItem label={t('Logo')}>
                        <SwitchContainer>
                          <span>{area?.item?.props?.eventLogo ? t('Enabled') : t('Disabled')}</span>
                          <SettingsInput
                            gridColumns={24}
                            htmlType="switch"
                            field="item.props.eventLogo"
                            validateTrigger={['onChange']}
                            checked={area?.item?.props?.eventLogo ?? false}
                          />
                        </SwitchContainer>
                      </FormItem>
                    )}

                    {[TEMPLATE_TYPES.LOCATION_EVENTS, TEMPLATE_TYPES.LOCATION_WAYFINDING].includes(areaType) && (
                      <FormItem label={t('Time')}>
                        <SwitchContainer>
                          <span>{area?.item?.props?.eventTime ? t('Enabled') : t('Disabled')}</span>
                          <SettingsInput
                            gridColumns={24}
                            htmlType="switch"
                            field="item.props.eventTime"
                            validateTrigger={['onChange']}
                            checked={area?.item?.props?.eventTime ?? false}
                          />
                        </SwitchContainer>
                      </FormItem>
                    )}

                    {areaType === TEMPLATE_TYPES.LOCATION_WAYFINDING && (
                      <FormItem label={t('Location')}>
                        <SwitchContainer>
                          <span>{area?.item?.props?.eventLocation ? t('Enabled') : t('Disabled')}</span>
                          <SettingsInput
                            gridColumns={24}
                            htmlType="switch"
                            field="item.props.eventLocation"
                            validateTrigger={['onChange']}
                            checked={area?.item?.props?.eventLocation ?? false}
                          />
                        </SwitchContainer>
                      </FormItem>
                    )}

                    {areaType === TEMPLATE_TYPES.LOCATION_EVENTS && (
                      <FormItem label={t('Next Event')}>
                        <SwitchContainer>
                          <span>{area?.item?.props?.nextEvent ? t('Enabled') : t('Disabled')}</span>
                          <SettingsInput
                            gridColumns={24}
                            htmlType="switch"
                            field="item.props.nextEvent"
                            validateTrigger={['onChange']}
                            checked={area?.item?.props?.nextEvent ?? false}
                          />
                        </SwitchContainer>
                      </FormItem>
                    )}

                    {areaType === TEMPLATE_TYPES.DATETIME && (
                      <SettingsInput
                        label={t('Format')}
                        htmlType="select"
                        gridColumns={24}
                        options={[
                          {
                            value: 'DD.MM.YYYY',
                            label: t('04.09.2021'),
                          },
                          {
                            value: 'DD.MM.YYYY HH:mm',
                            label: '04.09.2021 14:21',
                          },
                          {
                            value: 'DD.MM.YYYY HH:mm:ss',
                            label: '04.09.2021 14:21:18',
                          },
                          {
                            value: 'DD.MM.YYYY h:mm A',
                            label: '04.09.2021 2:21 PM',
                          },
                          {
                            value: 'LL',
                            label: t('September 4, 2021'),
                          },
                          {
                            value: 'LLL',
                            label: 'September 4, 2021 8:30 PM',
                          },
                          {
                            value: 'dddd, LL',
                            label: t('Thursday, September 4, 2021'),
                          },
                          {
                            value: 'LLLL',
                            label: 'Thursday, September 4, 2021 8:30 PM',
                          },
                          {
                            value: 'HH:mm',
                            label: '14:21',
                          },
                          {
                            value: 'HH:mm:ss',
                            label: '14:21:18',
                          },
                          {
                            value: 'h:mm A',
                            label: '2:21 PM',
                          },
                        ]}
                        field="item.props.format"
                        defaultValue="DD.MM.YYYY"
                      />
                    )}

                    {areaType === TEMPLATE_TYPES.WEATHER && (
                      <>
                        <SettingsInputGroup>
                          <SettingsInput
                            field="item.props.api.days"
                            label={t('Days')}
                            defaultValue={7}
                            htmlType="number"
                            max={7}
                            min={1}
                          />
                          <SettingsInput
                            label={t('Refresh interval')}
                            field="item.props.api.refresh"
                            defaultValue={600}
                            htmlType="number"
                            max={1200}
                            min={30}
                          />
                        </SettingsInputGroup>

                        <SettingsInput
                          label={t('Icon set')}
                          field="item.props.icons.set"
                          htmlType="select"
                          defaultValue={WeatherIconsSet.OpenWeatherLight}
                          options={[
                            {
                              value: WeatherIconsSet.OpenWeatherLight,
                              label: t('Open weather map (light)'),
                            },
                            {
                              value: WeatherIconsSet.OpenWeatherDark,
                              label: t('Open weather map (dark)'),
                            },
                            {
                              value: WeatherIconsSet.SimpleWhite,
                              label: t('Simple (white)'),
                            },
                            {
                              value: WeatherIconsSet.SimpleBlack,
                              label: t('Simple (black)'),
                            },
                          ]}
                          gridColumns={24}
                        />

                        <SettingsInputGroup>
                          <SettingsInput
                            label={t('Icon size')}
                            field="item.props.icons.size"
                            defaultValue={108}
                            htmlType="number"
                            max={512}
                            min={32}
                          />
                          <SettingsInput
                            field="item.props.temperature"
                            label={t('Temperature')}
                            htmlType="select"
                            defaultValue="C"
                            size="small"
                            options={[
                              {
                                value: 'C',
                                label: t('°C'),
                              },
                              {
                                value: 'F',
                                label: t('°F'),
                              },
                            ]}
                          />
                        </SettingsInputGroup>

                        <SettingsInput
                          label={t('Date label')}
                          field="item.props.dateLabel"
                          htmlType="select"
                          defaultValue="d DD"
                          options={[
                            {
                              value: 'ddd DD',
                              label: t('Week and date'),
                            },
                            {
                              value: 'DD MMM',
                              label: t('Date and month'),
                            },
                          ]}
                          gridColumns={24}
                        />
                      </>
                    )}

                    {[
                      TEMPLATE_TYPES.TEXT,
                      TEMPLATE_TYPES.DATETIME,
                      TEMPLATE_TYPES.WEATHER,
                      TEMPLATE_TYPES.LOCATION_HEADER,
                      TEMPLATE_TYPES.LOCATION_EVENTS,
                      TEMPLATE_TYPES.LOCATION_WAYFINDING,
                      TEMPLATE_TYPES.FLIGHTS,
                    ].includes(areaType) && (
                      <>
                        {![TEMPLATE_TYPES.WEATHER, TEMPLATE_TYPES.FLIGHTS].includes(areaType) && (
                          <HorizontalAlignmentSettingInput onChange={onHorizontalAlignmentChange} />
                        )}

                        <SettingsInput
                          label={t('Font family')}
                          field="style.font-family"
                          htmlType="select"
                          defaultValue="Lato"
                          component={FontSelector}
                          gridColumns={24}
                        />

                        <SettingsInputGroup>
                          <SettingsInput
                            label={t('Font weight')}
                            field="style.font-weight"
                            htmlType="select"
                            defaultValue="400">
                            <Option value="300">Light</Option>
                            <Option value="400">Normal</Option>
                            <Option value="700">Bold</Option>
                          </SettingsInput>
                          <SettingsInput
                            label={t('Font size')}
                            field="style.font-size"
                            formatter={appendCSSUnits('px', 'suffix')}
                            parser={stripCSSUnits('px', 32)}
                            min={1}
                            defaultValue={areaType === TEMPLATE_TYPES.WEATHER ? 24 : 64}
                            htmlType="number"
                          />
                        </SettingsInputGroup>
                      </>
                    )}

                    {[TEMPLATE_TYPES.LOCATION_EVENTS, TEMPLATE_TYPES.LOCATION_WAYFINDING].includes(areaType) && (
                      <>
                        <SettingsInput
                          label={t('Title color')}
                          htmlType="color"
                          field="item.props.eventTitleColor"
                          validateTrigger={['onChange']}
                          gridColumns={24}
                          defaultValue="#ffffff"
                        />
                        <SettingsInputGroup>
                          <SettingsInput
                            label={t('Time color')}
                            htmlType="color"
                            field="item.props.eventTimeColor"
                            validateTrigger={['onChange']}
                            defaultValue="#ffffff"
                          />
                          <SettingsInput
                            label={t('Location color')}
                            htmlType="color"
                            field="item.props.eventLocationColor"
                            validateTrigger={['onChange']}
                            defaultValue="#ffffff"
                          />
                        </SettingsInputGroup>
                      </>
                    )}

                    {areaType === TEMPLATE_TYPES.FLIGHTS && (
                      <>
                        <SettingsInput
                          label={t('Main color')}
                          field="item.props.mainColor"
                          validateTrigger={['onChange', 'onBlur']}
                          htmlType="color"
                          defaultValue="#ffffff"
                          gridColumns={48}
                        />
                        <SettingsInput
                          label={t('Header color')}
                          field="item.props.headerColor"
                          validateTrigger={['onChange', 'onBlur']}
                          htmlType="color"
                          defaultValue="#ffffff"
                          gridColumns={48}
                        />
                      </>
                    )}

                    {[
                      TEMPLATE_TYPES.TEXT,
                      TEMPLATE_TYPES.DATETIME,
                      TEMPLATE_TYPES.WEATHER,
                      TEMPLATE_TYPES.LOCATION_HEADER,
                    ].includes(areaType) && (
                      <SettingsInput
                        label={t('Color')}
                        field="style.color"
                        validateTrigger={['onChange', 'onBlur']}
                        htmlType="color"
                        defaultValue="#ffffff"
                        gridColumns={48}
                      />
                    )}

                    {[
                      TEMPLATE_TYPES.IFRAME,
                      TEMPLATE_TYPES.YOUTUBE,
                      TEMPLATE_TYPES.HLS,
                      TEMPLATE_TYPES.MULTICAST,
                    ].includes(areaType) && (
                      <SettingsInput
                        label={t('URL')}
                        placeholder="Insert link here"
                        field="item.url"
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            // eslint-disable-next-line security/detect-unsafe-regex
                            pattern:
                              '/^https?://(?=.{1,254}(?::|$))(?:(?!d|-)(?![a-z0-9-]{1,62}-(?:.|:|$))[a-z0-9-]{1,63}\b(?!.$).?)+(:d+)?(.*)+$/i',
                            message: t('Please enter the correct url'),
                          },
                        ]}
                        defaultValue=""
                      />
                    )}

                    {areaType === TEMPLATE_TYPES.CLOCK && (
                      <>
                        <SettingsInput
                          htmlType="select"
                          label={t('Skin')}
                          options={[
                            {
                              value: 'Circle',
                              label: t('Circle'),
                            },
                            {
                              value: 'Slim',
                              label: t('Slim'),
                            },
                            {
                              value: 'Analog',
                              label: t('Analog'),
                            },
                          ]}
                          field="item.props.skin"
                          gridColumns={24}
                          defaultValue="Circle"
                        />
                        <SettingsInput
                          field="style.color"
                          label={t('Color')}
                          validateTrigger={['onChange', 'onBlur']}
                          htmlType="color"
                          gridColumns={24}
                          defaultValue="#ffffff"
                        />
                      </>
                    )}

                    {[TEMPLATE_TYPES.CLOCK, TEMPLATE_TYPES.DATETIME].includes(areaType) && (
                      <SettingsInput
                        label={t('Timezone')}
                        field="item.props.timezone"
                        htmlType="select"
                        component={Timezone}
                        gridColumns={24}
                      />
                    )}
                  </Form>
                </Panel>

                {[TEMPLATE_TYPES.LOCATION_WAYFINDING, TEMPLATE_TYPES.LOCATION_EVENTS, TEMPLATE_TYPES.FLIGHTS].includes(
                  areaType,
                ) && (
                  <SwitchablePanel
                    header={separatorPanelHeader}
                    key="2"
                    collapsible={!area?.item?.props?.separatorEnabled && 'disabled'}>
                    <Form layout="vertical">
                      <SettingsInput
                        label={t('Separator color')}
                        validateTrigger={['onChange']}
                        field="item.props.separatorColor"
                        htmlType="color"
                        gridColumns={48}
                        disabled={!area?.item?.props?.separatorEnabled}
                        defaultValue="#ffffff"
                      />
                      <SettingsInput
                        label={t('Separator size')}
                        validateTrigger={['onBlur']}
                        field="item.props.separatorSize"
                        htmlType="number"
                        gridColumns={48}
                        min={0}
                        disabled={!area?.item?.props?.separatorEnabled}
                        defaultValue="#ffffff"
                      />
                    </Form>
                  </SwitchablePanel>
                )}

                {[TEMPLATE_TYPES.LOCATION_WAYFINDING, TEMPLATE_TYPES.FLIGHTS].includes(areaType) && (
                  <SwitchablePanel
                    header={paginationPanelHeader}
                    key="3"
                    collapsible={area?.item?.props?.paginationEnabled ? true : 'disabled'}>
                    <Form layout="vertical">
                      <SettingsInput
                        label={t('Page flip interval (sec)')}
                        htmlType="number"
                        field="item.props.pagination.interval"
                        defaultValue={15}
                        validateTrigger={['onChange']}
                        min={15}
                        disabled={!area?.item?.props?.paginationEnabled}
                        gridColumns={24}
                      />
                      <SettingsInput
                        label={t('Font family')}
                        htmlType="select"
                        component={FontSelector}
                        field="item.props.pagination.fontFamily"
                        defaultValue="Lato"
                        validateTrigger={['onChange']}
                        disabled={!area?.item?.props?.paginationEnabled}
                        gridColumns={24}
                      />
                      <SettingsInputGroup>
                        <SettingsInput
                          label={t('Font size')}
                          field="item.props.pagination.fontSize"
                          formatter={appendCSSUnits('px', 'suffix')}
                          parser={stripCSSUnits('px', 32)}
                          htmlType="number"
                          min={12}
                          max={64}
                          defaultValue={24}
                          disabled={!area?.item?.props?.paginationEnabled}
                          validateTrigger={['onChange']}
                        />
                        <SettingsInput
                          label={t('Font color')}
                          field="item.props.pagination.fontColor"
                          htmlType="color"
                          disabled={!area?.item?.props?.paginationEnabled}
                          validateTrigger={['onChange']}
                          defaultValue="#ffffff"
                        />
                      </SettingsInputGroup>
                      <SettingsInputGroup>
                        <SettingsInput
                          label={t('Horizontal alignment')}
                          htmlType="select"
                          options={[
                            { label: t('Left'), value: 'left' },
                            { label: t('Right'), value: 'right' },
                            ...(area?.item?.props?.pagination?.verticalAlignment === 'center'
                              ? []
                              : [{ label: t('Center'), value: 'center' }]),
                          ]}
                          field="item.props.pagination.horizontalAlignment"
                          validateTrigger={['onChange']}
                          defaultValue="center"
                          disabled={!area?.item?.props?.paginationEnabled}
                        />
                        <SettingsInput
                          label={t('Vertical alignment')}
                          htmlType="select"
                          options={[
                            { label: t('Top'), value: 'top' },
                            { label: t('Bottom'), value: 'bottom' },
                          ]}
                          field="item.props.pagination.verticalAlignment"
                          validateTrigger={['onChange']}
                          defaultValue="bottom"
                          disabled={!area?.item?.props?.paginationEnabled}
                        />
                      </SettingsInputGroup>
                      {TEMPLATE_TYPES.FLIGHTS === areaType && (
                        <SettingsInput
                          label={t('Margin from table (px)')}
                          htmlType="number"
                          field="item.props.pagination.margin"
                          defaultValue={15}
                          validateTrigger={['onChange']}
                          min={15}
                          max={300}
                          disabled={!area?.item?.props?.paginationEnabled}
                          gridColumns={24}
                        />
                      )}
                    </Form>
                  </SwitchablePanel>
                )}

                {areaType === TEMPLATE_TYPES.TEXT && (
                  <SwitchablePanel
                    header={backgroundPanelHeader}
                    collapsible={!area?.style[ratioId]?.backgroundEnabled && 'disabled'}
                    key="4">
                    <Form layout="vertical">
                      <Row gutter={24}>
                        <SettingsInput
                          label={t('Color')}
                          validateTrigger={['onChange', 'onBlur']}
                          field="style.backgroundHex"
                          htmlType="color"
                          gridColumns={24}
                          defaultValue="#000"
                        />
                        <SettingsInput
                          label={t('Transparency (%)')}
                          field="style.backgroundOpacity"
                          htmlType="number"
                          defaultValue={0}
                          min={0}
                          max={100}
                          formatter={appendCSSUnits('%', 'suffix')}
                          parser={stripCSSUnits('%', 0)}
                        />
                        <SettingsInput
                          label={t('Corner radius (px)')}
                          field="style.borderRadius"
                          htmlType="number"
                          min={0}
                          defaultValue={0}
                          formatter={appendCSSUnits('px', 'suffix')}
                          parser={stripCSSUnits('px', 0)}
                        />
                      </Row>
                    </Form>
                  </SwitchablePanel>
                )}

                {areaType === TEMPLATE_TYPES.WEATHER && (
                  <Panel header={t('Weather location')} key="5">
                    <Form layout="vertical">
                      <Row style={{ display: 'block' }}>
                        <FormItem label={t('Location')}>
                          <PlacesAutocomplete
                            value={weatherLocationName.address}
                            onChange={handleLocationChange}
                            onSelect={handleLocationSelect}>
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <PlacesAutocompleteContainer key="places-input">
                                <Input
                                  {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                  })}
                                />
                                <PlacesAutocompleteDropdown
                                  className="autocomplete-dropdown-container"
                                  key="places-suggestions">
                                  {loading && <PlacesAutocompleteItem>Loading...</PlacesAutocompleteItem>}
                                  {suggestions.map(suggestion => {
                                    const { active } = suggestion;
                                    // inline style for demonstration purpose
                                    return (
                                      <PlacesAutocompleteItem
                                        key={suggestion.placeId}
                                        {...getSuggestionItemProps(suggestion, { active })}>
                                        <span>{suggestion.description}</span>
                                      </PlacesAutocompleteItem>
                                    );
                                  })}
                                </PlacesAutocompleteDropdown>
                              </PlacesAutocompleteContainer>
                            )}
                          </PlacesAutocomplete>
                        </FormItem>
                      </Row>
                      <Row style={{ display: 'block' }}>
                        <SettingsInput
                          gridColumns={1}
                          field="item.props.location.lat"
                          label={t('Lat')}
                          htmlType="number"
                          defaultValue="51.509865"
                          placeholder="51.509865"
                          disabled
                        />
                      </Row>
                      <Row style={{ display: 'block' }}>
                        <SettingsInput
                          gridColumns={1}
                          field="item.props.location.lng"
                          htmlType="number"
                          label={t('Lng')}
                          defaultValue="-0.118092"
                          placeholder="-0.118092"
                          disabled
                        />
                      </Row>
                    </Form>
                  </Panel>
                )}

                <Panel header="Position and size" key="6">
                  <Form layout="vertical">
                    <Row gutter={24}>
                      <SettingsInput
                        field="style.top"
                        label={t('Top (px)')}
                        htmlType="number"
                        min={ABSOLUTE_POSITIONING_MAX_NEGATIVE_OFFSET}
                        max={baseHeight - areaHeight}
                        formatter={appendCSSUnits('px', 'suffix')}
                        parser={stripCSSUnits('px', 0)}
                        defaultValue={0}
                      />
                      <SettingsInput
                        field="style.left"
                        label={t('Left (px)')}
                        htmlType="number"
                        min={ABSOLUTE_POSITIONING_MAX_NEGATIVE_OFFSET}
                        max={baseWidth - areaWidth}
                        formatter={appendCSSUnits('px', 'suffix')}
                        parser={stripCSSUnits('px', 0)}
                        defaultValue={0}
                      />
                      <SettingsInput
                        field="style.width"
                        label={t('Width (px)')}
                        htmlType="number"
                        min={ABSOLUTE_POSITIONING_MAX_NEGATIVE_OFFSET}
                        max={baseWidth - areaLeftPosition}
                        formatter={appendCSSUnits('px', 'suffix')}
                        parser={stripCSSUnits('px', 0)}
                        defaultValue={320}
                      />
                      <SettingsInput
                        field="style.height"
                        label={t('Height (px)')}
                        htmlType="number"
                        min={ABSOLUTE_POSITIONING_MAX_NEGATIVE_OFFSET}
                        max={baseHeight - areaTopPosition}
                        formatter={appendCSSUnits('px', 'suffix')}
                        parser={stripCSSUnits('px', 0)}
                        defaultValue={320}
                      />
                    </Row>

                    {areaType === TEMPLATE_TYPES.IMAGE && (
                      <Row style={{ display: 'block' }}>
                        <SettingsInput
                          gridColumns={1}
                          field="style.object-fit"
                          label={t('Fit')}
                          htmlType="select"
                          defaultValue="cover"
                          value="cover"
                          options={[
                            {
                              value: 'cover',
                              label: t('Cover'),
                            },
                            {
                              value: 'fill',
                              label: t('Fill'),
                            },
                            {
                              value: 'contain',
                              label: t('Contain'),
                            },
                            {
                              value: 'none',
                              label: t('None'),
                            },
                          ]}
                        />
                      </Row>
                    )}
                  </Form>
                </Panel>

                <Panel header="Constraints" key="7">
                  <Form layout="vertical">
                    <FormItem style={{ marginBottom: '0.625rem' }}>
                      <Select onSelect={onLayoutChange} showSearch defaultValue="top" className="select__arrow-v">
                        <Option value="top">{t('Top')}</Option>
                        <Option value="center">{t('Center')}</Option>
                        <Option value="bottom">{t('Bottom')}</Option>
                      </Select>
                    </FormItem>
                    <FormItem>
                      <Select onSelect={onLayoutChange} showSearch defaultValue="left" className="select__arrow-h">
                        <Option value="left">{t('Left')}</Option>
                        <Option value="leftRight">{`${t('Left')} & ${t('Right')}`}</Option>
                        <Option value="right">{t('Right')}</Option>
                      </Select>
                    </FormItem>
                  </Form>
                </Panel>
              </Collapse>
            </Scrollbar>
            <FooterComponent>
              <Button onClick={onSettingsClose} type="link" icon={<RiArrowLeftSLine />}>
                {t('Item list')}
              </Button>
            </FooterComponent>
          </Drawer>
        </TemplateListContainer>
      </AsideWrapper>
    </EditorMenuContext.Provider>
  );
};

TemplateAreaList.propTypes = {
  selectedRatio: PropTypes.string,
  onSelectRatio: PropTypes.func.isRequired,
  globalSettingsStatus: PropTypes.bool,
  onCloseGlobalSettings: PropTypes.func,
};

TemplateAreaList.defaultProps = {
  selectedRatio: '16:9',
  onCloseGlobalSettings: undefined,
  globalSettingsStatus: false,
};

TemplateAreaList.displayName = 'TemplateAreaList';

export default memo(TemplateAreaList);
