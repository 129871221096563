/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { Form, Col } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import setWith from 'lodash/setWith';
import { compareIds } from 'src/functions';
import logger from 'src/helpers/logger';
import { Input, InputNumber, ColorInput, TextArea } from 'src/components/ui-kit/input';
import Select from 'src/components/ui-kit/select';
import { Switch } from 'src/components/ui-kit/switch';

import arrowsV from 'static/images/arrows-v.svg';
import arrowsH from 'static/images/arrows-h.svg';
//
import { EditorContext } from './context';
import { getRatioStyle } from './helpers';

export const FormItem = styled(Form.Item)`
  margin-bottom: 0.5rem;

  .ant-form-item-control-input {
    margin-top: 0.5rem;
  }

  .ant-form-item-label {
    padding-bottom: 0.125rem;
    margin-bottom: -0.5rem;
  }

  .ant-form-item-label > label,
  label {
    font-size: 0.75rem;
    font-weight: 600;
  }

  .ant-input-number {
    width: 100%;
  }
  .ant-input-number-input {
    height: 2.125rem;
  }

  .ant-form-item-has-error {
    .ant-input {
      border-color: #e74c3c;
    }
  }

  .ant-select-show-search {
    &.ant-select-single {
      .ant-select-selector {
        height: 2.25rem;
        background-color: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
      &:not(.ant-select-disabled):hover .ant-select-selector,
      &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-color: rgba(0, 0, 0, 0.2);
      }
    }
    &:not(.select__arrow-v),
    &:not(.select__arrow-h) {
      .ant-select-selector {
        padding-left: 1rem;
        padding-right: 1rem;
        &:before {
          display: none;
        }
        .ant-select-selection-search {
          left: 1rem;
        }
      }
    }
    &.select__arrow-v .ant-select-selector {
      &:before {
        display: block;
        background-image: url(${arrowsV});
      }
    }
    &.select__arrow-h .ant-select-selector {
      &:before {
        display: block;
        background-image: url(${arrowsH});
      }
    }
  }

  .ant-input {
    height: 2.25rem;
    min-height: 2.25rem;
    color: #000000;
  }

  .ant-input,
  .ant-select-selector {
    max-height: 2.25rem;
  }
`;

function someOfParentsHasClass(element, classname) {
  if (element.className?.split(' ').indexOf(classname) >= 0) return true;
  return element.parentNode && someOfParentsHasClass(element.parentNode, classname);
}

export const SettingsInput = memo(p => {
  const {
    label,
    name,
    field,
    value: _pv,
    defaultValue,
    htmlType,
    rules,
    validateTrigger,
    getValueFromEvent,
    parser,
    formatter,
    component,
    gridColumns,
    checked,
    ...props
  } = p;
  const { ratio, onChange, unsavedChanges, selectedArea: areaId } = useContext(EditorContext);
  const ratioId = ratio?.id;
  const area = unsavedChanges?.areas?.find(a => compareIds(a.id, areaId));
  //
  const [prop, propField] = field.split('.');

  const initialValue = useMemo(() => {
    if (prop === 'style') return getRatioStyle(area, ratioId, propField) || defaultValue;

    return get(area, field, defaultValue ?? htmlType === 'number' ? 0 : '') || defaultValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area?.updatedAt, ratioId, field, prop, propField, defaultValue]);

  const [value, setInputValue] = useState(typeof parser === 'function' ? parser(initialValue) : initialValue);

  const onInputChange = useCallback(
    event => {
      const v = typeof event?.target?.value !== 'undefined' ? event?.target?.value : event;

      logger('SettingsInput.onInputChange', { event });
      setInputValue(v);

      if (!!validateTrigger && validateTrigger.includes('onChange')) {
        if (prop === 'style') {
          onChange(areaId, { style: { [ratioId]: { [propField]: v } } });
        } else {
          onChange(areaId, setWith({}, field, v, Object));
        }
      }
    },
    [setInputValue, areaId, field, onChange, prop, propField, ratioId, validateTrigger],
  );

  const onInputBlur = useCallback(
    event => {
      const hasError = someOfParentsHasClass(event.target, 'ant-form-item-has-error');
      if (hasError) {
        return;
      }
      let v = typeof formatter === 'function' ? formatter(value) : value;
      //
      if (htmlType === 'number') {
        if (typeof props.min === 'number') {
          v = Math.max(props.min, v);
        }
        if (typeof props.max === 'number') {
          v = Math.min(props.max, v);
        }
      }

      if (!validateTrigger || validateTrigger?.includes('onBlur') || !validateTrigger?.includes('onChange')) {
        if (prop === 'style') {
          onChange(areaId, { style: { [ratioId]: { [propField]: v } } });
        } else {
          onChange(areaId, setWith({}, field, v, Object));
        }
      }
    },
    [
      props.max,
      props.min,
      formatter,
      htmlType,
      onChange,
      areaId,
      field,
      prop,
      propField,
      ratioId,
      value,
      validateTrigger,
    ],
  );

  const onKeyPress = useCallback(
    event => {
      if (event.key === 'Enter') {
        onInputBlur(event);
      }
    },
    [onInputBlur],
  );
  //
  const InputComponent = useMemo(() => {
    if (component) {
      return component;
    }
    switch (htmlType) {
      case 'color':
        return ColorInput;
      case 'number':
        return InputNumber;
      case 'textarea':
        return TextArea;
      case 'select':
        return Select;
      case 'switch':
        return Switch;
      default:
        return Input;
    }
  }, [component, htmlType]);
  //
  useEffect(() => {
    let v = typeof parser === 'function' ? parser(initialValue) : initialValue;
    //
    if (htmlType) {
      if (typeof props.min === 'number') {
        v = Math.max(props.min, v);
      }
      if (typeof props.max === 'number') {
        v = Math.min(props.max, v);
      }

      setInputValue(v);
    }
  }, [htmlType, parser, initialValue, props.min, props.max]);

  return (
    <Col span={gridColumns || ['text', 'textarea'].includes(htmlType) ? 24 : 12}>
      <FormItem
        label={label}
        name={name}
        rules={rules}
        validateTrigger={validateTrigger}
        getValueFromEvent={getValueFromEvent}
        initialValue={value}>
        <InputComponent
          {...props}
          onKeyPress={onKeyPress}
          onChange={onInputChange}
          onBlur={onInputBlur}
          type={htmlType}
          value={value}
          checked={value ?? false}
          defaultChecked={checked}
        />
      </FormItem>
    </Col>
  );
});
SettingsInput.propTypes = {
  htmlType: PropTypes.string,
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.array,
  validateTrigger: PropTypes.array,
  min: PropTypes.number,
  max: PropTypes.number,
};
SettingsInput.defaultProps = {
  htmlType: 'text',
  label: undefined,
  name: undefined,
  validateTrigger: undefined,
  rules: undefined,
  min: undefined,
  max: undefined,
};
SettingsInput.displayName = 'SettingsInput';

export default SettingsInput;
